import { useDispatch, useSelector } from 'react-redux';
import { recommendWGiftData } from '@src/@slice/recommendWGiftSlice';

export const useRecommendWGift = () => {
	const wGiftData = useSelector(recommendWGiftData);
	const dispatch = useDispatch();

	return {
		wGiftData,
		dispatch
	}
}