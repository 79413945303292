import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import { setMealType } from '@src/@slice/recommendHallSlice';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';

const mealTypeItems = ['뷔페', '코스', '한상차림', '상관없음'];
const MealType = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {hallData, dispatch} = useRecommendHall();
	useEffect(() => {
		setValidate(hallData.meal.types.length > 0);
	}, [hallData.meal.types]);
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === '' || hallData.weddingRegions.length === 0 ||
			(hallData.expectedPerson.min === null && hallData.expectedPerson.max === null)
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	const goNext = () => {
		navigate('/recommend/hall/cost');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`선호하는 식사 형태를
						모두 선택해 주세요.`}
						type="중복 선택 가능"
					/>
					<div className="common-wrap">
						<div style={{height: 20}} />
						<ul className="selectable-list-wrap items-3">
							{mealTypeItems.map(type => (
							<li key={`meal-type-item-${type}`}>
								<div 
									className={hallData.meal.types.indexOf(type) > -1 ? 'on' : ''}
									onClick={() => dispatch(setMealType({type: type}))}
								>
									{type}
								</div>
							</li>
							))}
						</ul>
					</div>
				</div>
				<div>
					<LinkWegazine
						title="식사 종류에 대해 잘 모르겠다면?"
						link="https://www.weddingbook.com/wegazine/c64522e6-6eac-11ec-b0a5-0aea5320e7f2?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MealType;