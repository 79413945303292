import { useEffect } from 'react';
import StepComplete from '../../../components/common/stepComplete/StepComplete';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import { UserContext } from './../../../App';
import { useDispatch } from 'react-redux';
import { setIsComplete } from '@src/@slice/bookingHallSlice';

const Complete = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsComplete({bool: true}));
	}, []);
	const linkChat = () => {
		window.location.href = 'h2mwbell://chat';
	}
	return (
		<div>
			{/* <AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} /> */}
			<StepComplete 
				title={`짝짝짝!
				웨딩홀 견적 요청이
				접수되었습니다.`}
				text={`신청해 주신 웨딩홀 견적 요청은
				확인 후 웨딩북 앱 내 1:1 문의 채팅창으로 안내드릴 예정이니 조금만 기다려주세요.`}
			/>
			<UserContext.Consumer>
				{user => (
					<>
					{user.me && <button className="btn btn-red btn-fixed-circle" onClick={linkChat}>채팅 확인</button>}
					</>
				)}
			</UserContext.Consumer>
			
		</div>
	);
};

export default Complete;