import './wrapTitle.scss';

// Props
interface SignInUpTitleProps {
	title?: React.ReactNode;
	children?: React.ReactNode;
}

// 로그인/회원가입 타이틀 컴포넌트
const WrapTitle = ({title, children}: SignInUpTitleProps) => {
	return (
		<div className="sign-title-wrap">
			{children === undefined ? title : children}
		</div>
	);
};

export default WrapTitle;