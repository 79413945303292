import {Route, Routes} from 'react-router-dom';
import SpecialPage from '@src/pages/umoment/SpecialPage';
import Signup from '@src/pages/umoment/Signup';
const UmomentRoute = () => {
	return (
		<Routes>
			<Route path="/landing/special-hall" element={<SpecialPage/>}/>
			<Route path="/signup" element={<Signup/>}/>
		</Routes>
	);
};

export default UmomentRoute;