import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { recommendHallData, setWeddingType } from '@src/@slice/recommendHallSlice';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';

const hallTypes = ['동시', '분리', '상관없음'];
const Type = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {dispatch, hallData} = useRecommendHall();
	useEffect(() => {
		setValidate(hallData.weddingType !== '');
	}, [hallData.weddingType]);
	useEffect(() => {
		if (hallData.hallStyleTypes.length === 0) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	const goNext = () => {
		navigate('/recommend/hall/region');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`선호하는 예식 형태를
						선택해 주세요.`}
						type="중복 선택 불가"
					/>
					<div className="common-wrap">
						<div style={{height: 20}} />
						<ul className="selectable-list-wrap items-3">
							{hallTypes.map(type => (
							<li key={`type-item-${type}`}>
								<div 
									className={hallData.weddingType.indexOf(type) > -1 ? 'on' : ''}
									onClick={() => dispatch(setWeddingType({type: type}))}
								>
									{type}
								</div>
							</li>
							))}
						</ul>
					</div>
				</div>
				<div>
					<LinkWegazine
						title="예식 형태에 대해 잘 모르겠다면?"
						link="https://www.weddingbook.com/wegazine/92141798-863b-11e8-bc23-0ab211c9f708?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Type;