import './package.scss';
import { useState, useRef } from 'react';
import { chunk } from 'lodash';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle'; 
import Slider from 'react-slick';
import { addComma, isExternalUrl } from '../../../utils/index';
import TagList, { ITags } from '../tagList/TagList';

type ItemProps = {
	imgUrl?: string;
	id: string;
	name?: string;
	tags?: ITags;
	address?: string;
	typeName?: string;
	description?: string;
	isItemLocaltion?: number;
	itemId?: string;
	partner?: string;
	link: string;
	tag?: any[];

	rating?: number;
	comment?: number;

	price: string;
	salePrice: string;
};

const PackageItem = ({
	id, 
	imgUrl,
	address,
	name,
	price,
	salePrice,
	link,
	description,
	typeName,
	tags
}: ItemProps) => {
	return (
		<a href={isExternalUrl(link)} className="row-package">
			<div
				className={`row-item-bg ${!imgUrl ? 'no-image' : ''}`}
				style={{ backgroundImage: `url(${`${imgUrl}`})` }}
			/>
			<div className="row-item-info-wrap">
				<span className="item-name">{name}</span>
				<span className="item-sub">{description}</span>
				<div className="item-price-wrap">
					<span className="sale-price">
						{addComma(salePrice)}원
					</span>
					<span className="default-price">
						{addComma(price)}원
					</span>
				</div>
				{tags && (
					<TagList id={id} tags={tags}/>
				)}
			</div>
		</a>
	);
};

type IItem = {
	imgUrl: string;
	title: string;
	description: string;
	isItemLocaltion?: number;
	isRating?: number;
	itemId: string;
	partner?: string;
	tag: ITags;
	rating?: number;
	comment?: number;
	priceHasDropped: string;
	suggestedPrice: string;
	// length?: number;
};

type ITab = {
	item: IItem[];
	tabId: string;
	tabName: string;
	// length?: number;
};

type IDataCuration = {
	buttonLink?: string;
	buttonText?: string;
	isTab?: number;
	tab?: ITab[] | undefined;
	item?: IItem[] | undefined;
	title: ITitle;
};

type PackageProps = {
	id: number;
	data: IDataCuration;
};

export default function Package({ id, data }: PackageProps) {
	const [tabActive, setTabActive] = useState<number>(0);
	const slider = useRef(null);
	
	const SETTING = {
		infinite: true,
		speed: 500,
		centerMode: true,
		centerPadding: '14px'
	};
	return (
		<section className="package-wrap">
			<SectionTitle
				title={data.title}
				link={{
					url: data?.buttonLink,
					name: data?.buttonText,
				}}
			/>
			{data.isTab ? (
				<ul className="tabs-wrap">
					{data.tab?.map((item, idx) => {
						let active = tabActive === idx;
						return (
							<li
								className={`tab-item ${active ? 'on' : ''}`}
								onClick={() => {
									if (slider.current) {
										(slider.current as any).slickGoTo(0);
									}
									setTabActive(idx);
								}}
								key={`vendor-curation-tab-${id}-${idx}`}
							>
								{item.tabName}
							</li>
						);
					})}
				</ul>
			) : <></>}
			<div>
				<Slider {...SETTING} ref={slider}>
					{data.tab &&
					chunk(data.tab[tabActive].item, 3).map((items, idx) => {
						return (
							<div className="slider-group-wrap" key={`package-group-${id}-${idx}`}>
							{items.map(item => {
								// if (!partners) return null;
								// const partner = partners.filter((partner: any) => partner.uuid === item.itemId)[0];
								// if (!partner) return null;
								return (
									<PackageItem
										imgUrl={item.imgUrl}
										name={item.title}
										description={item.description}
										price={item.suggestedPrice}
										salePrice={item.priceHasDropped}
										tags={item.tag}
										link={data?.buttonLink!}
										id={`package-${id}-${item.itemId}`}
										key={`package-${id}-${item.itemId}`}
									/>
								)
							})}
							</div>
						)
					})
					}
					{data.item &&
					chunk(data.item, 3).map((items, idx) => {
						return (
							<div className="slider-group-wrap" key={`package-group-${id}-${idx}`}>
							{items.map(item => {
								return (
									<PackageItem
										imgUrl={item.imgUrl}
										name={item.title}
										description={item.description}
										price={item.suggestedPrice}
										salePrice={item.priceHasDropped}
										tags={item.tag}
										link={data?.buttonLink!}
										id={`package-${id}-${item.itemId}`}
										key={`package-${id}-${item.itemId}`}
									/>
								)
							})}
							</div>
						)
					})
					}
				</Slider>
			</div>
		</section>
	);
}
