import './stepComplete.scss';

interface Props {
	title: string;
	text: string;	
}
const StepComplete = ({title, text}: Props) => {
	return (
		<div className="step-complete-wrap">
			<b className="complete-title">{title}</b>
			<p>{text}</p>
		</div>
	);
};

export default StepComplete;