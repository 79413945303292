import './partnerDetailHeader.scss';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {increment, decrement, historyLen} from '../../../@slice/historySlice';
import { IPartner, postPartnerLike } from '@src/api/partner/partner';
import { createBrowserHistory } from 'history';

const PartnerDetailHeader = ({partner, partnerUuid, partnerName, isLike = 0, headerRef}: {partner: IPartner, partnerUuid: string, partnerName:string, isLike: number, headerRef: React.RefObject<HTMLDivElement>}) => {
	const user = useContext(UserContext);
	const history = createBrowserHistory();
	const navigate = useNavigate();
	const location = useLocation();
	const historyLength = useSelector(historyLen);
	const dispatch = useDispatch();
	const [currentLike, setCurrentLike] = useState(!!isLike);
	useEffect(() => {
		switch (history.action) {
			case 'PUSH':
				dispatch(increment());
				break;
			case 'POP':
				if (historyLength > 0) dispatch(decrement());
				break;
		}
	}, [history]);
	const goBack = () => {
		if (historyLength >= 1 || user.me === null) {
			navigate(-1);
		} else {
			window.location.href = 'h2mwbell://webview/close';
		}
	}
	const toggleLike = () => {
		postPartnerLike(partnerUuid, {
			partnerName: partnerName,
			state: !currentLike ? 1 : 0
		});
		setCurrentLike(!currentLike);
	}
	const sharePartner = () => {
		window.location.href = `h2mwbell://share?title=${partnerName}&msg=https://www.wdgbook.com/page/${partner.profile ? partner.profile : partner.uuid}`
	}
	return (
		<header className="partner-detail-header" ref={headerRef}> 
			<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0, marginLeft: 8}} onClick={goBack}>
				<i className={`icon icon-arrow`}></i>
			</button>
			<div className="partner-detail-header-right">
				<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" onClick={toggleLike}>
					<i className={`icon icon-like ${currentLike ? 'on' : ''}`}></i>
				</button>
				<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" onClick={sharePartner} style={{marginLeft: 4}}>
					<i className={`icon icon-share`}></i>
				</button>
			</div>
		</header>
	);
};

export default PartnerDetailHeader;