
interface IAccordionList {
	openedIds: string[];
	setOpenedIds: (ids: string[]) => void;
	list: {
		id: string;
		title: string;
		content: JSX.Element;
	}[]
}
const AccordionList = ({openedIds, setOpenedIds, list}: IAccordionList) => {
	return (
		<ul className="accordion-list-wrap">
			{list.map(item => (
			<li
				key={`accordion-list-${item.id}`}
			>
				<span 
					className={`list-show-area ${openedIds.indexOf(item.id) > -1 ? 'show' : ''}`}
					onClick={() => {
						let ids: string[] = openedIds.slice();
						if (openedIds.indexOf(item.id) > -1) {
							ids = ids.filter(id => id !== item.id);
						} else {
							ids.push(item.id);
						}
						setOpenedIds(ids);
					}}
				>
					{item.title}
				</span>
				<div className="list-hide-area">
					{item.content}
				</div>
			</li>
			))}
		</ul>
	);
};

export default AccordionList;