import { useState, useEffect, useContext } from 'react';
import PageTitle from '../../../components/common/pageTitle/PageTitle';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import { getWGiftImages, makeRecommendWGiftLink, postRecommendWGift } from '@src/api/recommend/recommend';
import { toggleAiRecommendPartnerIds } from '@src/@slice/recommendWGiftSlice';
import { useDispatch } from 'react-redux';
import { useRecommendWGift } from '../../../utils/hooks/useRecommendWGift';
import { useNavigate } from 'react-router-dom';
import { shuffle } from './../../../utils/index';
import WaitPage from './../../../components/common/waitPage/WaitPage';
import { UserContext } from './../../../App';

interface IImage {
	aiRecommendPartnerId: number;
	aiRecommendPartnerUuid: string;
	id: number;
	imageSize: string;
	imageUrl: string;
	keyword: string;
	name: string;
	ord: number;
	regTsp: string;
	type: number;
}
const SelectGift = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {wGiftData} = useRecommendWGift();
	const [validate, setValidate] = useState(false);
	const [images, setImages] = useState<IImage[]>([]);
	const [showWaitPage, setShowWaitPage] = useState(false);
	const user = useContext(UserContext);
	console.log('user: ');
	console.log(user);
	useEffect(() => {
		(async () => {
			if (images.length) return;
			const {data}: {data: {data: IImage[]}} = await getWGiftImages();
			shuffle(data.data);
			setImages(data.data);
		})();
	}, []);
	useEffect(() => {
		setValidate(wGiftData.aiRecommendPartnerId.length >= 7); // TODO 운영계 7로 바꿔야 함!
	}, [wGiftData.aiRecommendPartnerId]);
	const toggleImage = (id: number) => {
		dispatch(
			toggleAiRecommendPartnerIds({id})
		)
	}
	const makeShareLink = () => {
		if (showWaitPage) return;
		setShowWaitPage(true);
		setTimeout(() => {
			makeRecommendWGiftLink(wGiftData.aiRecommendPartnerId)
			.then((res: any) => {
				console.log('mkae link');
				const data = res.data.data;
				navigate(`/recommend/weddingGift/result?uuid=${data.uuid}`);
				setShowWaitPage(false);
			})
			.catch(err => {
				console.log(err);
				setShowWaitPage(false);
			});
		}, 1000);
		
		// navigate('/recommend/weddingGift/result');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`선호하는 예물 디자인을 
선택해 주세요.`}
						type="최소 7개 선택"
					/>
					<div className="common-wrap" style={{paddingBottom: 60}}>
						<ul className="select-gift-list-wrap">
							{images.map(image => (
								<li 
									className={wGiftData.aiRecommendPartnerId.indexOf(image.id) !== -1 ? 'on' : ''}
									onClick={() => toggleImage(image.id)}
									key={`gift-image-${image.id}`}
								>
									<div className="gift-image" style={{backgroundImage: `url(${image.imageUrl})`}}></div>
									<div className="gift-checked"></div>
								</li>
							))}
						</ul>
						{wGiftData.aiRecommendPartnerId.length > 0 &&
						<span className="select-gift-status">
							{wGiftData.aiRecommendPartnerId.length >= 30 
							? '더 이상 선택할 수 없어요 😭' 
							: `${wGiftData.aiRecommendPartnerId.length}개를 선택했어요! 💍`}
						</span>
						}
					</div>
				</div>
				<div>
					<div className="btn-wrap">
						<button onClick={makeShareLink} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
			{showWaitPage &&
				<WaitPage desc={`${user.me ? (user.me.nick + '님의 ') : ''}취향을 분석하여
				가장 어울리는 업체를 고르고 있습니다.`} />
			}
		</div>
	);
};

export default SelectGift;