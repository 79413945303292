import { useState, useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import axios from '@src/api/axios';
import { BASE_URL, isExternalUrl, HOSTNAME, PORTAL_URL } from '@src/utils';
import topImg from '../../assets/images/mypage/mypage-point-top.png';
import ReactGA from '@src/utils/ga';

const EarnPoint = () => {
    const [list, setList] = useState<any[]>([]);
    console.log(ReactGA);
    useEffect(() => {
        axios.get<{ item: any[] }>(BASE_URL + '/v3/mypage/point/how-to-earn').then((res) => {
            setList(res.data.item);
        });
    }, []);
    return (
        <div>
            <AppHeader type='stack' title='포인트 적립 방법' actionItems={[{ name: 'close' }]} />
            <img src={topImg} alt='적립한 웨딩북 포인트를 결혼준비에 사용해보세요.' style={{ verticalAlign: 'top' }} />
            <div className='btn-wrap items-1' style={{ margin: 0 }}>
                {/* <div>
					<a 
						href={isExternalUrl(`${PORTAL_URL}/app/sdmmall`)}
						className="btn btn-full btn-yellow"
						onClick={() => {
							ReactGA.event({
								category: 'Earn Point',
								action: 'click',
								label: '스드메 결제하러 가기'
							});
						}}
					>
						스드메 결제하러 가기 💸
					</a>
				</div> */}
                <div>
                    <a
                        href={isExternalUrl(`${HOSTNAME}/product/4`)}
                        className='btn btn-full btn-yellow'
                        onClick={() => {
                            ReactGA.event({
                                category: 'Earn Point',
                                action: 'click',
                                label: '스토어 쇼핑하러 가기',
                            });
                        }}
                    >
                        스토어 쇼핑하러 가기 🛒
                    </a>
                </div>
            </div>
            <ul className='earn-point-list-wrap'>
                {list.map((item) => (
                    <li key={`earn-point-list-${item.idx}`}>
                        <a href={isExternalUrl(item.link)}>
                            <div>
                                <span className='list-title'>{item.title}</span>
                                <span className='list-content'>{item.subTitle}</span>
                            </div>
                            <span className='point-amount'>{item.button}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EarnPoint;
