import React, { useEffect, useState } from 'react';
// redux 
import { useSelector, useDispatch } from 'react-redux';
import { UserData } from '@src/@slice/UserSlice';
// router
import { useLocation, useNavigate } from 'react-router-dom';
// Redux
import { SignUp, interestedItems} from '@src/@slice/SignUpSlice';
// Hooks
import { useCustomInput, useSignValidation, useHasContentInput, useSignPhone } from '@src/utils/hooks/useSignInput';
import { useSignUpAllField } from '@src/utils/hooks/useSignUpField';
import { useReferralCodeValidation } from '@src/utils/hooks/useSignValidation';
import { useUserActions } from '@src/utils/hooks/useUserActions';
import useInputEvent from '@src/utils/hooks/useSignInputEvent';
// utils
import { getDiffFromToday } from '@src/utils/date';
// api
import axios, { ServerResponse } from '@src/api/axios';
import { BASE_URL } from '@src/utils';
// Components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';
import DataSection from '@src/components/form/dataSection/DataSection';
import WrapTitle from '@src/components/common/wrap/WrapTitle';
import Checkbox from '@src/components/common/checkbox/Checkbox';
import AreaSelect from '@src/components/common/areaSelect/AreaSelect';
import FullFixedModal from '@src/components/common/fullFixedModal/FullFixedModal';
import SearchWeddingHall from '@src/components/common/searchWeddingHall/SearchWeddingHall';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
// analytics
import { logEvent } from '@src/firebase';


// DatePicker 연결용 Component
interface InputTypeBoxProps {
    type: string;
    value: string | null;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// 추가 정보 입력 페이지 2
const Contents = () => {

    // history
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const type = params.get('type') || 'Signup_Complete_v2';

    // Redux Store
    const ForSignUp = useSelector(SignUp);
    const ForSignUpData = ForSignUp.data;
    const ForUserData = useSelector(UserData);

    // 회원 정보 수정
    const { modifyCustomer, getAdditionalInfo } = useUserActions();
    useEffect(() => {
        axios.get<ServerResponse>(`${BASE_URL}/v3/20240404/customer/inpath`).then((res) => {
            setSignInPathIdList(res.data.item);
        });
        getAdditionalInfo();
    }, [])

    // input Event
    const { signValueSetter } = useInputEvent();

    // 가입경로
    const [signInPathIdList, setSignInPathIdList] = useState<{ type: number; code: string;  name: string}[]>([] as { type: number; code: string;  name: string}[]);

    // uuid 
    const [userUuid, setUserUuid] = useState('');
    useEffect(() => {
        setUserUuid(ForUserData.customerUuid);
    }, [ForUserData]);

    // 버튼 클릭(다음 페이지 이동 트리거)
    const [nextTrigger, setNextTrigger] = useState(false);

    // 수정 처리 시 pause
    const [pause, setPause] = useState(false);

    // 완료 트리거
    const [completeTrigger, setCompleteTrigger] = useState(false);

    // DatePicker 연결용 Component
    const ForwardedInputTypeBox = React.forwardRef<HTMLInputElement, InputTypeBoxProps>((props, ref) => (
        <InputTypeBox ref={ref} {...props} />
    ));

    // 예식일
    const signWDate = useCustomInput({
        val: null,
        formatErrorCheck: (val: string) => {
            const value = val;
            signWDate.setFormatValid(value ? true : false);
        },
        dataErrorCheck: (val: string) => {
            const value = val;
            signWDate.setDataValid(value ? true : false);
        },
    });
    const signWDateFixed = useCustomInput({ val: null }); // 확정 예식일
    const signWDateHope = useCustomInput({ val: null }); // 희망 예식일
    const [wDateUndecided, setWDateUndecided] = useState(false); // 예식일 미정 여부

    // 예식일 미정 여부에 따른 값 설정
    useEffect(() => {
        if(wDateUndecided) {
            signWDate.setValue(signWDateHope.value)
        } else {
            signWDate.setValue(signWDateFixed.value)
        }
    }, [wDateUndecided, signWDateFixed.value, signWDateHope.value]);

    // 예식지역
    const signWRegion = useCustomInput({
        formatErrorCheck: (val: string) => {
            const value = val;
            signWRegion.setFormatValid(value ? true : false);
        },
        dataErrorCheck: (val:string) => {
            const value = val;
            signWRegion.setDataValid(value ? true : false);
        },
    });

    // 예식지역 모달
    const [wRegionModalShow, setWRegionModalShow] = useState(false);
    
    // 웨딩홀
    const signWHall = useCustomInput({
        val: null,
        formatErrorCheck: (val: string) => {
            const value = val;
            signWHall.setFormatValid(value !== null ? true : false);
        },
        dataErrorCheck: (val:string) => {
            const value = val;
            signWHall.setDataValid(value !== null ? true : false);
        },
    });
    const signWHallFixed = useCustomInput({ val: null }); // 확정 웨딩홀
    const [wHallUndecided, setWHallUndecided] = useState(false); // 웨딩홀 미정 여부
    const [wHallModalShow, setWHallModalShow] = useState(false); // 웨딩홀 모달
    
    // 웨딩홀 미정 여부에 따른 값 설정
    useEffect(() => {
        if(wHallUndecided) {
            signWHall.setValue('');
        } else {
            signWHall.setValue(signWHallFixed.value);
        }
    }, [wHallUndecided, signWHallFixed.value]);

    // 배우자 이름
    const signMateName = useHasContentInput();

    // 배우자 전화번호
    const signMateTel = useSignPhone();

    // 가입경로
    const signInPathId = useCustomInput({ val: null });
    const [signInPathId2, setSignInPathId2] = useState('' as string);
    useEffect(() => {
        if (signInPathId2) {
            let option = signInPathIdList.find((item) => item.code == signInPathId2);
            signInPathId.setValue(option);
        }
    }, [signInPathId2, signInPathIdList]);
    
    // 가입경로 모달
    const [signInPathIdModalShow, setSignInPathIdModalShowModalShow] = useState(false);

    // 관심영역
    const signInterestItems = useCustomInput({ val: null });
    useEffect(() => {
        if (signInterestItems.value) {
            if(signInterestItems.value.length === 0) signInterestItems.setValue(null);
        }
    }, [signInterestItems.value]);

    // 플래너
    const signHasPlanner = useCustomInput({ val: null });
    
    // 웨딩북 플래너
    const signWeddingbookPlanner = useCustomInput({ val: null });

    // 친구 초대코드 유효성 확인
    const invitationCodeValidation = useReferralCodeValidation();

    // 친구 초대코드
    const signInvitationCode = useCustomInput({
        val: null, 
        dataCheckMoment: 'custom',
        formatErrorCheck: (val: string) => {
            const value = val;
            signInvitationCode.setResultObject(
                !value ? { result: 'success', type: 'formatValid', label: '', message: '' } :
                    invitationCodeValidation.validate(value)
            );
        },
        dataErrorCheck: async (val: string) => {
            const value = val;
            if (value) {
                const result = await invitationCodeValidation.verifyReferralCode(value);
                signInvitationCode.setResultObject(result);
                if(result.result === 'success') {
                    return true;
                } else {
                    return false;
                }
            } else {
                signInvitationCode.setResultObject({result: 'success', type: 'dataValid', label: '', message: ''});
                return true;
            }
        }
    });

    // 친구 초대코드 입력값 변경
    const handleInvitationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const referralCode = e.target.value ? (e.target.value).toUpperCase() : e.target.value;
        signInvitationCode.setValue(referralCode);
    };

    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([
        signMateName.formatValid,
        signMateTel.formatValid,
        signWDate.formatValid,
        signWRegion.formatValid,
        signWHall.formatValid,
        signInvitationCode.formatValid
    ]);
    const dataValid = useSignValidation([
        signMateName.dataValid,
        signMateTel.dataValid,
        signWDate.dataValid,
        signWRegion.dataValid,
        signWHall.dataValid,
        signInvitationCode.dataValid
    ]);

    useEffect(() => {
        if(signInvitationCode.value === '' || signInvitationCode.value === null) {
            signInvitationCode.setDataValid(true);  
        } else {
            signInvitationCode.setDataValid(false);
        }
    }, [signInvitationCode.value]);

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        setPause(true);
        if (signInvitationCode.value) {
            const result = await signInvitationCode.customDataErrorCheck(signInvitationCode.value);
            if (result) {
                setNextTrigger(true);
            } 
        } else {
            setNextTrigger(true);
        }
    }
    
    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid) {
                goNext();
            } else {
                setNextTrigger(false);
            }
        }
    }, [nextTrigger, pause, formatValid, dataValid]); //다음 버튼 클릭 시 유효성 확인

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        setNextTrigger(false);
        if (pause) {
            signFieldAll.signUpDispatch(true); // 리덕스에 데이터 저장 & 처리 완료 시 completeTrigger true
        }
    }

    // 완료 트리거 -> 회원 정보 수정 동작
    useEffect(() => {
        if (completeTrigger) {
            goComplete();
        }
    }, [completeTrigger]);

    // 완료 페이지(홈)으로 이동
    const goComplete = () => { 
        console.log('goComplete');
        const check = [30, 60, 90, 120, 150, 180];
        let weddingTspDday: number | string;
        let diffWeddingTsp = getDiffFromToday(signWDate.value, 'days');
        if (diffWeddingTsp >= 0) {
            weddingTspDday = 'Over';
        } else {
            diffWeddingTsp = -1 * diffWeddingTsp;
            const diffcheck = check.find((item) => item >= diffWeddingTsp);
            weddingTspDday = diffcheck ? diffcheck : '180~';
        }
        const logData = {
            WeddingTsp: weddingTspDday,
            Dday_type: wDateUndecided ? '희망' : '확정',
            Area: signWRegion.value,
            Weddinghall: signWHall.value,
            Weddinghall_type: wHallUndecided ? '미정' : '확정',
            Needed_Product: ! signInterestItems.value ? '' : signInterestItems.value.sort((a, b) => a.localeCompare(b)).join(','),
            Planner: signHasPlanner.value === '1' ? '있음' : signHasPlanner.value === '0' ? '없음' : '',
            Planner_Wdb: signWeddingbookPlanner.value === true ? '네' : signWeddingbookPlanner.value === false ? '아니요' : '',
            Inpath: signInPathId.value ? signInPathId.value.name : '',
            InviteCode: signInvitationCode.value ? 'Y' : 'N',
        }
        logEvent(type, logData);
        // console.log(logData);
        // localStorage.removeItem('loginComplete');
        window.location.href = `h2mwbell://home?userUuid=${userUuid}`;
    }

    // 마운트 시 리덕스 값 적용 / 언마운트 시 리덕스에 값 저장
    const signFieldAll = useSignUpAllField({
        signFields: {
            'weddingInfo': {
                mateName: signMateName,
                mateTel: signMateTel,
                weddingTsp: {
                    value: signWDate.value ? signWDate.value : null,
                    setValue: (value: string) => {
                        if (ForSignUpData.weddingInfo.weddingTspType === '1') {
                            signWDateHope.setValue(value);
                        } else {
                            signWDateFixed.setValue(value);
                        }
                    },
                },
                weddingTspType: {
                    value: wDateUndecided ? '1' : '2', //미정: 1 확정: 2 
                    setValue: (value: string) => {
                        if (value === '1') {
                            setWDateUndecided(true);
                        } else {
                            setWDateUndecided(false);
                        }
                    },
                },
                region: signWRegion,
                weddinghallName: {
                    value: signWHall.value ? signWHall.value : '',
                    setValue: (value:string) => {
                        signWHallFixed.setValue(value);
                    },
                }
            },
            'extraInfo': {
                interestItems: {
                    value: signInterestItems.value ? signInterestItems.value.join(',') : null,
                    setValue: (value: string) => {
                        if (value) {
                            signInterestItems.setValue(value.indexOf(',') > -1 ? value.split(',') : [value]);
                        }
                    },
                },
                hasPlanner: signHasPlanner,
                weddingbookPlanner: signWeddingbookPlanner,
                inPathId: {
                    value: signInPathId.value ? signInPathId.value.code : null,
                    setValue: (value: string) => {
                        setSignInPathId2(value);
                    },
                },
                invitationCode: signInvitationCode
            }
        },
        startCallBack: () => {
            if (! ForSignUpData.weddingInfo.weddinghallName && ForSignUpData.weddingInfo.weddingTsp) {
                setWHallUndecided(true);
            }
        },
        endCallBack: (x: {
            personalInfo?: { [key: string]: any },
            weddingInfo?: { [key: string]: any },
            extraInfo?: { [key: string]: any },
            agreement?: { [key: string]: any }  
        }) => {
            console.log(x);
            modifyCustomer(x).then((result: { success: boolean, data: any }) => {
                console.log('modifyCustomer', result)
                if (result.success) {
                    setCompleteTrigger(true);
                } else {
                    alert(result.data.msg ? result.data.msg : '에러가 발생했습니다. 잠시 후 다시 시도해주세요');
                }
            });
        }
    });


	return (
		<>
            <div className="contents-wrap">
                <WrapTitle>
                    <h2>예식 정보를<br />입력해주세요.</h2>
                </WrapTitle>
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">예식일</span>
                            </div>
                            <div className="form-content">
                                {/* <DateInput
                                    value={signWeddingDate.value === null ? '' : signWeddingDate.value}
                                    placeholder={'2022-07-01'}
                                    onChange={handleWeddingDateChange}
                                    disabled={weddingDateUndecided}
                                /> */}
                                <DatePicker 
                                    customInput={
                                        <ForwardedInputTypeBox
                                            type={"readonly"}
                                            readOnly={true}
                                            value={signWDateFixed.value === null ? '' : signWDateFixed.value}
                                            placeholder={'2022-07-01'}
                                            disabled={wDateUndecided}
                                            onChange={(e)=> signWDateFixed.setValue(e.target.value)}
                                            />
                                        }
                                    selected={signWDateFixed.value === null ? null : moment(signWDateFixed.value).toDate()}
                                    onChange={(date:Date) => signWDateFixed.setValue(moment(date).format('YYYY-MM-DD'))}
                                    placeholderText="날짜를 선택하세요"
                                    dateFormat="yyyy.MM.dd"
                                    onChangeRaw={e => e.preventDefault()}
                                    onFocus={e => e.target.blur()}
                                    disabledKeyboardNavigation
                                    locale={ko}
                                    disabled={wDateUndecided}
                                />
                                <div className="checkbox-wrap">
                                    <Checkbox value={wDateUndecided.toString()} id={"weddingDateUndecided"}
                                        setValue={() => {
                                            setWDateUndecided(!wDateUndecided);
                                        }}
                                        name={"weddingDateUndecided"}
                                        checked={wDateUndecided}
                                    />
                                    <label htmlFor={`weddingDateUndecided`}>아직 예식일을 결정하지 못했어요</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </DataSection>
                {wDateUndecided && (
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">희망 예식일</span>
                            </div>
                            <div className="form-content">
                                {/* <DateInput
                                    value={signHoepWeddingDate.value === null ? '' : signHoepWeddingDate.value}
                                    placeholder={'2022-07-01'}
                                    onChange={handleHopeWeddingDateChange}
                                    /> */}
                                <DatePicker 
                                    customInput={
                                        <ForwardedInputTypeBox
                                            type={"readonly"}
                                            readOnly={true}
                                            value={signWDateFixed.value === null ? '' : signWDateHope.value}
                                            placeholder={'2022-07-01'}
                                            disabled={! wDateUndecided}
                                            onChange={(e)=> signWDateHope.setValue(e.target.value)}
                                            />
                                        }
                                    selected={signWDateHope.value === null ? null : moment(signWDateHope.value).toDate()}
                                    onChange={(date:Date) => signWDateHope.setValue(moment(date).format('YYYY-MM-DD'))}
                                    placeholderText="날짜를 선택하세요"
                                    dateFormat="yyyy.MM.dd"
                                    onChangeRaw={e => e.preventDefault()}
                                    onFocus={e => e.target.blur()}
                                    disabledKeyboardNavigation
                                    locale={ko}
                                    disabled={! wDateUndecided}
                                />
                            </div>
                        </div>
                    </div>
                </DataSection>)}
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">예식지역</span>
                            </div>
                            <div className="form-content">
                                <input className={`select select-full ${signWRegion.value !== '' ? '' : 'select-gray'}`}
                                    type="text" placeholder="선택하세요"
                                    value={signWRegion.value !== '' ? signWRegion.value.replace(',', ' ') : ''}
                                    readOnly={true}
                                    onClick={() => { setWRegionModalShow(true) }} />
                            </div>
                        </div>
                    </div>
                </DataSection>
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">웨딩홀</span>
                            </div>
                            <div className="form-content">
                                <div className="input-type-box-wrap">
                                    <div className="input-type-box">
                                        <input type={'readonly'} className='input-full input-text'
                                            value={signWHallFixed.value === null ? '' : signWHallFixed.value}
                                            placeholder={'웨딩홀입력'}
                                            onClick={() => {setWHallModalShow(true)}}
                                            disabled={wHallUndecided}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="checkbox-wrap">
                                    <Checkbox value={wHallUndecided.toString()}
                                        id={"weddingVenueUndecided"} setValue={() => {
                                            setWHallUndecided(!wHallUndecided);
                                        }} name={"weddingVenueUndecided"} checked={wHallUndecided} />
                                    <label htmlFor={`weddingVenueUndecided`}>아직 웨딩홀을 결정하지 못했어요</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </DataSection>
                {((ForUserData.mateName === ForSignUpData.weddingInfo.mateName) ||
                    (!ForUserData.mateName && !ForSignUpData.weddingInfo.mateName)) && (
                <>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">배우자 이름</span>
                                </div>
                                <div className="form-content">
                                    <InputTypeBox type="text"
                                        placeholder="김웨딩"
                                        value={signMateName.value}
                                        onChange={signValueSetter(signMateName.setValue)}
                                        disabled={ForUserData.mateName ? true : false}    
                                    />
                                </div>
                            </div>
                        </div>
                    </DataSection>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">배우자 전화번호</span>
                                </div>
                                <div className="form-content">
                                    <InputTypeBox type="text"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        placeholder="휴대전화 번호"
                                        value={signMateTel.value}
                                        onChange={signMateTel.handleChange}
                                        disabled={ForUserData.mateTel ? true : false} 
                                        infoComponent={
                                            ForUserData.mateName ?
                                            <b>
                                                배우자 이름, 연락처가 변경됐을 경우 1:1 채팅으로 문의해 주세요.
                                            </b>     : <></>
                                        }    
                                    />
                                </div>
                            </div>
                        </div>
                    </DataSection>    
                </>
                )} 
                {ForUserData.additionalInformationRegistRequest !== 'N' && (<>
                    <WrapTitle>
                        <h3>
                            추가정보
                            <span>선택사항</span>
                        </h3>
                    </WrapTitle>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">관심품목</span>
                                    <div className="necessary"><span>*</span>중복 선택 가능</div>
                                </div>
                                <div className="form-content">
                                    <ul className="selectable-list-wrap2 items-3">
                                        {
                                            interestedItems.map((item, index) => {
                                                return (
                                                    <li key={index} onClick={() => {
                                                        if (signInterestItems.value === null) {
                                                            signInterestItems.setValue([item]);
                                                        } else if (!signInterestItems.value.includes(item)) {
                                                            signInterestItems.setValue([...signInterestItems.value, item]);
                                                        } else {
                                                            signInterestItems.setValue(signInterestItems.value.filter((i:any) => i !== item));
                                                        }
                                                    }}>
                                                        <div className={`${(signInterestItems.value === null ? '' : signInterestItems.value.includes(item)) ? 'on' : ''}`}>{item}</div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DataSection>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">플래너</span>
                                </div>
                                <div className="form-content">
                                    <ul className="selectable-list-wrap2 items-2">
                                        <li onClick={()=> {signHasPlanner.setValue('1')}}>
                                            <div className={signHasPlanner.value === '1' ? 'on' : ''} >있음</div>
                                        </li>
                                        <li onClick={()=> {signHasPlanner.setValue('0')}}>
                                            <div className={signHasPlanner.value === '0' ? 'on' : ''}>없음</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DataSection>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">웨딩북 플래너와 진행중이신가요?</span>
                                </div>
                                <div className="form-content">
                                    <ul className="selectable-list-wrap2 items-2">
                                        <li onClick={()=> {signWeddingbookPlanner.setValue(true)}}>
                                            <div className={signWeddingbookPlanner.value === true ? 'on' : ''} >네</div>
                                        </li>
                                        <li onClick={()=> {signWeddingbookPlanner.setValue(false)}}>
                                            <div className={signWeddingbookPlanner.value === false ? 'on' : ''}>아니요</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DataSection>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">가입경로</span>
                                </div>
                                <div className="form-content">
                                    <input className={`select select-full ${signInPathId.value !== '' ? '' : 'select-gray'}`}
                                        type="text" placeholder="선택하세요" defaultValue={signInPathId.value ? signInPathId.value.name : ''} readOnly={true}
                                        onClick={() => { setSignInPathIdModalShowModalShow(true) }} />
                                </div>
                            </div>
                        </div>
                    </DataSection>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">친구 초대코드</span>
                                </div>
                                <div className="form-content">
                                    <InputTypeBox value={signInvitationCode.value === null ? '' : signInvitationCode.value}
                                        onChange={handleInvitationCodeChange}
                                        type={"text"}
                                        placeholder="초대코드"
                                        maxLength={6}
                                        resultObject={signInvitationCode.resultObject}
                                        showResultBorder={{ 'error': true, 'success': false }}
                                        showInfoMessage={{ 'error': true, 'success': false }}
                                    />
                                </div>
                            </div>
                        </div>
                    </DataSection>
                </>)}
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button
                        className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                        disabled={! formatValid}
                        onClick={handleNext}>
                        완료
                    </button>
                </div>
            </div>
            <FullFixedModal isShow={wHallModalShow} hideModal={() => { setWHallModalShow(false) }}
            closeButton="text">
                <SearchWeddingHall
                    hideModal={() => setWHallModalShow(false)}
                    onCompleted={(value) => signWHallFixed.setValue(value)} />
            </FullFixedModal>
            <FullFixedModal isShow={wRegionModalShow} hideModal={() => { setWRegionModalShow(false) }}
                closeButton="text" overlayBgColor={'rgba(0, 0, 0, .5)'} title="예식지역">
                <AreaSelect
                    hideModal={() => { setWRegionModalShow(false) }}
                    onCompleted={(obj) => signWRegion.setValue(obj.name)}
                    defaultRegion={(ForSignUpData.weddingInfo.region) ? { name: ForSignUpData.weddingInfo.region.split(',')[0], dname: ForSignUpData.weddingInfo.region } : null} />
            </FullFixedModal>
            <FullFixedModal isShow={signInPathIdModalShow} hideModal={() => { setSignInPathIdModalShowModalShow(false) }}
                closeButton="text"
                overlayBgColor={'rgba(0, 0, 0, .5)'} title="가입경로" autoHeight={true}>
                <ul className="source-wrap">
                    {
                        signInPathIdList.map((item, index) => {
                            return (
                                <li key={index} value={item.code}
                                    className={signInPathId.value === item ? 'on' : ''}
                                    onClick={() => { signInPathId.setValue(item); setSignInPathIdModalShowModalShow(false) }}>
                                        {item.name}
                                    <i className='icon icon-checkmark-brown500'></i>
                                </li>
                            )
                        })
                    }
                </ul>
            </FullFixedModal>
        </>
	);
};

const SignUpAdditional2 = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            accessType='member'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="" />}
            contentsComponent={<Contents />}
        />
    );
};

export default SignUpAdditional2;