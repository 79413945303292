import './weddingbookTV.scss';
import Slider from 'react-slick';
import SectionTitle from '../../common/sectionTitle/SectionTitle';
import { logEvent } from '@src/firebase';
interface WBTVProps {
	id: number;
	page: string;
	pageSub?: string;
	buttonLink: string;
	buttonName: string;
	items: {
		tvThumbnailUrl: string;
		tvTitle: string;
		tvInfo: {
			videoUrl: string;
		}
	}[];
}
function WeddingBookTV({id, page, pageSub, buttonLink, buttonName = '전체보기', items}: WBTVProps) {
	const settings = {
		centerMode: true,
		infinite: true,
		centerPadding: '14px',
		slidesToShow: 1,
		speed: 500,
	};
	return (
		<section className="weddingbook-tv-wrap">
			<SectionTitle 
				title={{
					mainTitle: '웨딩북 TV',
					titleType: 'mainSubOnlyMain'
				}}
				link={{
					url: buttonLink,
					name: buttonName
				}}
			/>
			<Slider {...settings}>
				{items.map((item, idx) => (
				<div key={`rolling-banner-${id}-${idx}`}>
					<a href={`http://www.youtube.com/v/${item.tvInfo.videoUrl}?outgoing=true`} className="tv-item" onClick={() => {
						const obj = {
							page: page,
							pageSub: pageSub,
							component: 'weddingbook_tv',
							behavior: 'click',
							title: item.tvTitle
						};
						if (pageSub === undefined) delete obj.pageSub;
						logEvent('component_behavior', obj);
					}}>
						<div className={`tv-item-bg ${!item.tvThumbnailUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${item.tvThumbnailUrl})`}} />
						<h3 className="tv-item-title">{item.tvTitle}</h3>
					</a>
				</div>
				))}
			</Slider>
		</section>
	);
}

export default WeddingBookTV;