import {useEffect} from 'react';
import CompanyOrder from '@src/components/home/companyOrder/CompanyOrder';
import { useParams } from 'react-router';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import axios from '@src/api/axios';
import { BASE_URL } from '@src/utils';
import { useState } from 'react';

const CategoryList = () => {
	const { id } = useParams<{id: string}>();
	// const [partnerTypes, setPartnerTypes] = useState<any[]>([]);
	const [partnerTypeName, setPartnerTypeName] = useState('');
	useEffect(() => {
		axios.get<{item: any[]}>(`${BASE_URL}/v1/partner/type?type=2`).then(res => {
			const selected = res.data.item.filter(typeData => typeData.type === +id);
			if (selected.length > 0) {
				setPartnerTypeName(selected[0].name);
			} 
		})
	}, []);
	return (
		<div>
			<AppHeader type="stack" scrollHide={false} actionItems={[{name: 'search'}, {name: 'like'}]} />
			<h3 style={{fontSize: 24, padding: '8px 20px'}}>{partnerTypeName}</h3>
			<CompanyOrder 
				id={+id}
				page={'CategoryList'} 
				categoryId={id} 
				showRating={false}
				data={[]}
			/>
		</div>
	);
};

export default CategoryList;