import './notiBox.scss';

interface Props {
	children: React.ReactNode;
}
const NotiBox = ({children}: Props) => {
	return (
		<div className="noti-box-wrap">
			<div>
				{children}
			</div>
		</div>
	);
};

export default NotiBox;