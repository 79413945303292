import './fullFixedModal.scss';
import React from 'react';

// Props
interface Props {
	isShow: boolean;
	hideModal: () => void;
	title?: (string | React.ReactNode);
    closeButton?: (('icon' | 'text') | React.ReactNode);
    children: React.ReactNode,
	overlayBgColor?: string;
	autoHeight?: boolean;
}

// 전체 화면 고정 모달
const FullFixedModal = ({isShow, hideModal, title, children, closeButton = '', overlayBgColor = 'transparent', autoHeight = false}: Props) => {
	return (
		<div className={`full-fixed-modal-wrap ${autoHeight ? 'auto-height' : ''}`} style={{display: isShow ? 'block' : 'none'}}>
			<div className="overlay" style={{backgroundColor: overlayBgColor}} onClick={hideModal}></div>
			<div className="full-fixed-modal">
                <div className="modal-top-wrap">
                    {title && <h2 className="modal-title">{title}</h2>}
					{closeButton !== 'icon' && closeButton !== 'text' && closeButton}
					{(closeButton === 'icon' || closeButton === 'text') &&
						<button className={`btn btn-tight btn-no-padding btn-auto-height btn-transparent`}
							onClick={hideModal}>
							{closeButton === 'icon' ? <i className="icon icon-close-black"></i> : '닫기'}
						</button>
					}
                </div>
				<div className="modal-content">
				    {children}
				</div>
			</div>
		</div>
	);
};

export default FullFixedModal;