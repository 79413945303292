import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import BookingTitle from './../../../components/booking/bookingTitle/BookingTitle';
import NotiText from './../../../components/common/notiText/NotiText';
import SearchPartner from '../../../components/common/searchPartner/SearchPartner';
import RedBoxList from '../../../components/booking/redBoxList/RedBoxList';
import { IPartner } from './../../../api/booking/booking';
import { bookingHallData, setEndPage, setPartnerUuids } from '@src/@slice/bookingHallSlice';
import { useSelector, useDispatch } from 'react-redux';
import axios from '@src/api/axios';
import { BASE_URL, HOSTNAME } from './../../../utils/index';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';

const SelectHall = () => {
	const [selectedPartner, setSelectedPartner] = useState<IPartner[]>([]);
	const navigate = useNavigate();
	const hallData = useSelector(bookingHallData);
	const dispatch = useDispatch();
	useEffect(() => {
		axios.get(`${BASE_URL}/v3/200107/partners?uuids=${hallData.partnerUuids}`).then((res: any) => {
			console.log('get partenrs');
			console.log(res.data.item)
			const partners = res.data.item.map((item: any) => {
				return {
					partnerId: -1, // not use
					partnerName: item.name,
					coverUrl: item.coverUrl,
					partnerUuid: item.uuid
				}
			})
			setSelectedPartner(partners);
		});
	}, []);
	const goNext = () => {
		if (hallData.customer.name !== '' && hallData.customer.tel !== '') {
			dispatch(setEndPage({page: 'visit-date'}));
			navigate('/booking/hall/customer-info');
		} else {
			navigate('/booking/hall/hope-date');
		}
	}
	return (
		<>
		{/* <AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} /> */}
		<div className="">
			<div className="layout-fix-button with-app-header">
				<div style={{paddingTop: 56}}>
					<BookingTitle title={`안내 받고 싶은
	웨딩홀을 선택해 주세요.`}/>
					<SearchPartner selectedPartner={selectedPartner} setSelectedPartner={(partners) => setSelectedPartner(partners)} />
					<NotiText text="웨딩홀 견적 요청은 최대 5개까지 가능합니다." />
					<RedBoxList
						width={70}
						items={selectedPartner.map(partner => ({
							origin: partner.partnerName,
							data: partner.partnerName
						}))}
						removeItem={item => {
							const targetPartner = selectedPartner.filter(p => p.partnerName === item)[0];
							if (!targetPartner) return;
							if (selectedPartner.filter(p => p.partnerUuid === targetPartner.partnerUuid).length) {
								setSelectedPartner(selectedPartner.filter(p => p.partnerUuid !== targetPartner.partnerUuid));
								dispatch(setPartnerUuids({uuid: targetPartner.partnerUuid}));
							}
						}}
					/>
				</div>
			</div>
			{/* wegazine이 아님.. 컴포넌트 이름 변경이 필요할듯? */}
			<div style={{height: 22}}></div>
			<LinkWegazine 
				title="웨딩홀 추천 받고 싶다면?"
				linkText="추천 요청하기"
				link={`${HOSTNAME}/recommend/hall/start`}
			/>
			<div className="btn-wrap">
				<button onClick={goNext} disabled={selectedPartner.length === 0} className={`btn bold btn-large btn-red btn-full ${selectedPartner.length ? '' : 'btn-disabled'}`}>다음</button>
			</div>
		</div>
		</>
	);
};

export default SelectHall;