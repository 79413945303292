import {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import {  HOSTNAME, addComma, isExternalUrl } from '@src/utils';
import { postReservation } from '@src/api/realtimeBooking/realtimeBooking';
import { UserContext } from '@src/App';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';


const LinkPaySuccess = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
    const name = query.get('name');
	const orderId = query.get('orderId');
	const amount = query.get('amount');
	const paymentKey = query.get('paymentKey');
    const isPaymentComplete = !!orderId || !!amount || !!paymentKey;
    const reservationData = JSON.parse(sessionStorage.getItem('reservationData'));
    const customerUuid = sessionStorage.getItem('uuid')
    
    const [loading, setLoading] = useState(true)
    const [reservationId, setReservationId] = useState(null)
  
    const generateOrderId = () => {
        const uuid = uuidv4();
        // const base64UrlId = btoa(uuid);
        // return base64UrlId.slice(0,len);
        return uuid
    };

    const sendReservationInfo = () => {
        const updatedReservationData =  {
            ...reservationData,
            paymentOrderId: isPaymentComplete ? orderId : generateOrderId(),
            paymentKey: isPaymentComplete ? paymentKey : '',
        } 
        postReservation(updatedReservationData, customerUuid)
        .then(res => {
            if(res.data.result === 1){         
                setLoading(false);
                setReservationId(res.data.data?.id);
            }
            sessionStorage.removeItem('hallName')
        })
        .catch(error=> {
            if (error.response) {
                const errorCode = error.response.data?.code;
                if(errorCode === '0231001'){
                    navigate(`/booking/real-time/tosspay/fail?message=상담 일정 마감`)
                }else if(errorCode === '0231002'){
                    navigate(`/booking/real-time/tosspay/fail?message=예약이 정상적으로 전달되지 않았습니다.`)
                }else if(errorCode === '0205001'){
                    navigate(`/booking/real-time/tosspay/fail?message=카드 결제 실패`)
                }
            } else if (error.request) {
                // 요청이 전송되었지만 응답을 받지 못한 경우
                navigate(`/booking/real-time/tosspay/fail?message=예약이 정상적으로 전달되지 않았습니다.`)
            } else {
                // 요청 설정 중에 발생한 다른 오류
                navigate(`/booking/real-time/tosspay/fail?message=오류가 발생했습니다.`)
            }
        })
    }

    useEffect(sendReservationInfo, []);


    const btnClick = (path:string) => {
        sessionStorage.removeItem('reservationData')
        navigate(path)
    }

	return (
        <>
            <AppHeader type="stack" actionItems={[{ name: "close" }]} />
            {!loading &&
            <div className="linkpay-result-page margin-top">
            {isPaymentComplete? 
            <>
            <h2>
                짝짝짝!<br/>
                결제가<br/>
                완료되었습니다.
            </h2>
            <p style={{fontSize:'15px'}}>결제가 정상적으로 처리되었습니다.</p>
            </>
            : 
            <>
             <h2>
                짝짝짝!<br/>
                결제가<br/>
                완료되었습니다.
            </h2>
            <p style={{fontSize:'15px',}}>예약이 정상적으로 완료되었습니다.</p>
            </>
            }
            <div className="result-info-wrap" style={{marginTop:'28px',fontSize:'13px'}}>
                <h3 style={{color: '#505050', marginBottom: '20px'}}>최종 결제 내역</h3>
                <p className='result-info-wrap-flex'>
                    웨딩홀 
                    <span>{name}</span>
                    </p>
                <p className='result-info-wrap-flex'>
                    방문일
                    <span>
                        {dayjs(reservationData.visitDate).format('YYYY.MM.DD(ddd)')} {reservationData.visitTime.slice(0,-3)}
                    </span>
               </p>
                {isPaymentComplete && 
                <p className='result-info-wrap-flex'>
                    상담보증금 
                    <span> {addComma(amount)}원</span>
                </p>}
            </div>
            {!!customerUuid && 
             <div className="noti-btn-wrap" style={{marginBottom:'20px'}} >
             <button className="btn btn-full btn-light-red" 
                onClick={() => {
                    window.location.href = 'h2mwbell://webview/close';
                    // window.location.href = `h2mwbell://webview?url=${HOSTNAME}/home`
                    }}>
                 홈으로
             </button>
             <button 
                className="btn btn-full btn-red bold" 
                onClick={() => btnClick(`/booking/real-time/management/detail/${reservationId}?hidetop=true`)
                }>
                 결제내역보기
             </button>
            </div>
            }
        </div>
        } 
            
        </>

	);
};

export default LinkPaySuccess;