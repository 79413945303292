import { useState, useEffect } from 'react';
import AppHeader from '../../common/appHeader/AppHeader';
import commentImg from '../../../assets/images/icon/ic-comment-white@3x.png'
import rateImg from '../../../assets/images/icon/ic-rate-white@3x.png'
import { BASE_URL } from '../../../utils/index';
import axios from 'axios';
import { HOSTNAME, isExternalUrl } from '@src/utils';
interface IPartner {
	name: string;
	uuid: string;
}
interface IPartnerTagDetail {
	backgroundColorEnd: string;
	backgroundColorStart: string;
	imgUrl: string;
	link: string;
	mainTitle: string;
	order: number;
	partners: IPartner[];
	subTitle: string;
	tag: string;
}
interface Props {
	id: number;
	data: IPartnerTagDetail
}
const PartnerTagDetail = ({id, data}: Props) => {
	const [partners, setPartners] = useState<any[]>([]);
	const [minHeight, setMinHeight] = useState(0);
	useEffect(() => {
		const partnerUuids = data.partners.map(item => item.uuid).join();
		setMinHeight(window.innerHeight - 56);
		axios.get(`${BASE_URL}/v3/200107/partners?uuids=${partnerUuids}`).then((res: any) => {
			setPartners(res.data.item);
		});
	}, [])
	return (
		<div className="partner-tag-detail">
			<AppHeader type="stack" iconColor="white" backgroundColor={data.backgroundColorStart}/>
			<div className="detail-inner" style={{
				minHeight: minHeight,
				background: `linear-gradient(180deg,${data.backgroundColorStart} 56px, ${data.backgroundColorEnd} 100%)`
			}}>
				<h2 className="detail-title">{data.mainTitle}</h2>
				<ul className="partner-list-wrap">
					{partners.map(partner => (
						<li key={`${id}-partner-${partner.uuid}`}>
							<a href={`h2mwbell://partner/${partner.uuid}`}>
								<div className={`item-bg ${partner.isLike ? 'like' : ''} ${!partner.coverUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${partner.coverUrl})`}} />
								<div className="item-info-wrap">
									<span className="item-address">{partner.address}</span>
									<span className="item-name">{partner.name}</span>
									<div className="item-score-wrap">
										<div>
											<img style={{ width: 18, height: 18 }} src={rateImg} alt="평점" />
											<span>{0}</span>
										</div>
										<div>
											<img style={{ width: 18, height: 18 }} src={commentImg} alt="후기 수" />
											<span>{partner.reviewCnt ? partner.reviewCnt : 0}</span>
										</div>
									</div>
									{partner.benefits &&
									<div className="item-tags-wrap">
										{partner.benefits.map((benefit: any) => (
											<div className="badge badge-square badge-brown" key={`partner-tag-detail-${benefit.uuid}`}>{benefit.badge}</div>
										))}
									</div>
									}
								</div>
							</a>
						</li>
					))}
				</ul>
			</div>

		</div>
	);
};

export default PartnerTagDetail;