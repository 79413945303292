import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import * as React from 'react';
import PartnerImageSlider from './../../components/partner/PartnerImageSlider';
import PartnerDetailHeader from './../../components/common/appHeader/PartnerDetailHeader';
import { Link, useParams } from 'react-router-dom';
import { getPartnerDetail, getPartner, getPartnerReview } from '@src/api/partner/partner';
import DetailTitle from '@src/components/partner/detail/DetailTitle';
import DetailText from '@src/components/partner/detail/DetailText';
import DetailBenefit from '@src/components/partner/detail/DetailBenefit';
import DetailProduct from '@src/components/partner/detail/DetailProduct';
import DetailQnA from '@src/components/partner/detail/DetailQnA';
import DetailInfo from '@src/components/partner/detail/DetailInfo';
import DetailImage from '@src/components/partner/detail/DetailImage';
import DetailNote from '@src/components/partner/detail/DetailNote';
import DetailTable from '@src/components/partner/detail/DetailTable';
import DetailVideo from '@src/components/partner/detail/DetailVideo';
import PartnerInquiry from '@src/components/partner/PartnerInquiry';
import { IPartner } from '../../api/partner/partner';
import { getDiffFromNow, getDiffToString } from '@src/utils/date';

import IconReviewNew from '@assets/images/common/community/icon-review-new.png';
import IconHeartOff from '@assets/images/common/community/icon-heart-off.png';
import IconHeartOn from '@assets/images/common/community/icon-heart-on.png';
import IconComment from '@assets/images/common/community/icon-comment.png';
import BtnCommunityMore from '@assets/images/common/community/btn-community-more.png';
import CommunityMoreModal from './../../components/community/CommunityMoreModal';
import { getCommunityList, ICommunityListItem, likeReview } from '@src/api/community/community';
import BottomFixedModal from './../../components/common/bottomFixedModal/BottomFixedModal';
import { addComma, BASE_URL, HOSTNAME, isExternalUrl, SAMSUNG_EVENT_PARTNER_UUID, SAMSUNG_EVENT_WEGAZINE_UUID } from '@src/utils';
import axios from '@src/api/axios';
import QueryString from 'qs';

import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from '@src/components/common/modal/Modal';
import PartnerDetailBottomButtons from '@src/components/partner/PartnerDetailBottomButtons';
import { useSelector } from 'react-redux';
import { callCount as callCountData, increment } from '@src/@slice/globalSlice';
import { UserContext } from '@src/App';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { getVenueInfo } from '@src/api/realtimeBooking/realtimeBooking';

interface ITabParams {
	currentId: number;
	hasProductData: boolean;
	reviewCount: number;
	onClick: (id: number) => void;
	tabRef: React.RefObject<HTMLUListElement>
}
const Tab = ({currentId, hasProductData, reviewCount, onClick, tabRef}: ITabParams) => {
	const clickTab = (id: number) => {
		onClick(id);
		const elInner = document.getElementById('partnerDetailInner');
		if (elInner && (window.scrollY || document.body.scrollTop) > elInner.offsetTop) {
			setTimeout(() => {
				window.scrollTo({
					top: elInner.offsetTop - 55,
					behavior: 'smooth'
				});
			}, 200);
		}
	}
	return (
		<>
		<ul className="partner-detail-tab-wrap" ref={tabRef}>
			{hasProductData &&
			<li 
				className={currentId === 1 ? 'on' : ''}
				onClick={() => clickTab(1)}
			>
				상품
			</li>
			}
			{hasProductData
			? <>
			<li
				className={currentId === 2 ? 'on' : ''}
				onClick={() => clickTab(2)}
			>
				정보
			</li>
			<li 
				className={currentId === 3 ? 'on' : ''}
				onClick={() => clickTab(3)}
			>
				후기 {reviewCount > 0 && reviewCount}
			</li>
			</> 
			: <>
			<li 
				className={currentId === 3 ? 'on' : ''}
				onClick={() => clickTab(3)}
			>
				후기 {reviewCount > 0 && reviewCount}
			</li>
			<li
				className={currentId === 2 ? 'on' : ''}
				onClick={() => clickTab(2)}
			>
				정보
			</li>
			</>
			}
			<li
				className={currentId === 4 ? 'on' : ''}
				onClick={() => clickTab(4)}
			>
				문의
			</li>
		</ul>
		<div style={{height: 55}}></div>
		</>
	);
}
const DetailParser = ({data, idx}: any) => {
	var type = data.type;
	if (type === 'TITLE'){
		return <DetailTitle data={data} idx={idx} />
	} else if (type === 'TEXT'){
		return <DetailText data={data} idx={idx} />
	} else if (type === 'BENEFIT'){
		return <DetailBenefit data={data} idx={idx} />
	} else if (type === 'PRODUCT'){
		return <DetailProduct data={data} idx={idx} />
	} else if (type === 'QNA'){
		return <DetailQnA data={data} idx={idx} />
	} else if (type === 'INFO'){
		return <DetailInfo data={data} idx={idx} />
	} else if (type === 'IMAGE'){
		return <DetailImage data={data} idx={idx} />
	} else if (type === 'NOTE'){
		return <DetailNote data={data} idx={idx} />
	} else if (type === 'TABLE'){
		return <DetailTable data={data} idx={idx} />
	} else if (type === 'VIDEO'){
		return <DetailVideo data={data} idx={idx} />
	}
	return null;
}
let reviewFetchStart = 0;
const reviewFetchSize = 20;
const CommunityReviewList = ({partner, tabId}: {partner: IPartner, tabId: number}) => {
	const hasStore = partner.store.length > 1;
	const [isEndList, setIsEndList] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [communityList, setCommunityList] = useState<any[]>([]);
	const [blindList, setBlindList] = useState<any[]>([]);
	const [communityModalData, setCommunityModalData] = useState<{communityUuid: string|null; customerUuid: string|null}>({
		communityUuid: null,
		customerUuid: null
	});
	const [showAllReview, setShowAllReview] = useState(true);
	const callCount = useSelector(callCountData);

	useEffect(() => {
		reviewFetchStart = 0;
		setIsFetching(true);
		getReviewList('init').then(_ => {
			setIsFetching(false);
		});
	}, [callCount]);
	useEffect(() => {
		reviewFetchStart = 0;
		setIsFetching(true);
		getBlindReviewList();
		getReviewList('init').then(_ => {
			setIsFetching(false);
		});
	}, [showAllReview]);
	const getReviewList = async (callType: 'init'|'add') => {
		const param: any = {
			partnerUuid: partner.uuid,
			blockState: 2,
			fetchStart: reviewFetchStart,
			fetchSize: reviewFetchSize,
		};
		if (hasStore) {
			param.withStore = showAllReview ? 1 : 0
		}
		return getCommunityList(param).then(res => {
			console.log([...communityList, ...res.data.item]);
			switch (callType) {
				case 'init':
					setCommunityList(res.data.item);
					break;
				case 'add':
					setCommunityList([...communityList, ...res.data.item]);
					break;
			}
			reviewFetchStart += reviewFetchSize;
		});
		
	}
	const getBlindReviewList = async () => {
		const param: any = {
			partnerUuid: partner.uuid,
			blockState: 1,
			fetchStart: reviewFetchStart,
			fetchSize: 999
		};
		// if (hasStore) {
		// 	param.withStore = showAllReview ? 1 : 0
		// }
		const {data} = await getCommunityList(param);
		setBlindList(data.item);
	}
	const linkCommunityDetail = (uuid: string) => {
		window.location.href = `h2mwbell://community?rootUuid=${uuid}&communityUuid=&isReview=1`;
	}
	const [currentShowIconUuid, setCurrentShowIconUuid] = useState('');
	const toggleLikeActions = (uuid: string) => {
		if (currentShowIconUuid === uuid) {
			setCurrentShowIconUuid('');
		} else {
			setCurrentShowIconUuid(uuid);
		}
	}
	const likeAction = (communityUuid: string, type: number, state:number) => {
		likeReview(communityUuid, type, state).then(_ => {
			const newList = communityList.map(list => {
				if (list.uuid === communityUuid) {
					list.isLike = state;
					list.likeCnt = list.likeCnt + (state ? 1 : -1)
				}
				return list
			});
			setCommunityList(newList);
			setCurrentShowIconUuid('');
		});
	}
	useEffect(() => {
		function handleClickOutside(e: any) {
			const iconWrap = document.querySelector(`.review-like-cnt[data-uuid="${currentShowIconUuid}"] .like-action-icons-wrap`);
			const elLikeCnt = document.querySelector(`.review-like-cnt[data-uuid="${currentShowIconUuid}"]`)
			if (!elLikeCnt || !iconWrap) return;
			if (!elLikeCnt.contains(e.target) && iconWrap.classList.contains('show')) {
				setCurrentShowIconUuid('');
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [currentShowIconUuid]);
	return (
		<>
		{hasStore &&
		<>
		<p className="with-other-store-wrap">
			<span>전체 {partner.reviewTotalCount}개, 
			현 지점 {partner.store.filter(st => st.uuid === partner.uuid).length > 0 ? partner.store.filter(st => st.uuid === partner.uuid)[0].reviewCount : '-'}개</span>의 후기가 있습니다.
		</p>
		<div className="store-review-controller">
			<button className={`btn btn-no-padding btn-tight btn-auto-height btn-transparent ${showAllReview ? 'on' : ''}`} onClick={() => {
				if (!isFetching) setShowAllReview(true);
			}}>전체 후기</button>
			<span></span>
			<button className={`btn btn-no-padding btn-tight btn-auto-height btn-transparent ${!showAllReview ? 'on' : ''}`} onClick={() => {
				if (!isFetching) setShowAllReview(false);
			}}>현 지점 후기</button>
		</div>
		</>
		}
		{blindList.length > 0 &&
		<div className="has-blind-review-wrap">
			<a href={isExternalUrl(`${HOSTNAME}/partner/${partner.uuid}/blind-reviews`)}>
				<i className="icon icon-notice-small-gray"></i>
				{blindList.length}개의 리뷰가 블라인드 되었습니다.
			</a>
		</div>
		}
		<div className="review-item-list-wrap">
		<InfiniteScroll
			dataLength={communityList.length}
			next={() => getReviewList('add')}
			hasMore={(tabId === 3 && !isFetching) ? true : false}
			loader={null}
			style={{overflow: 'visible'}}
			scrollThreshold={0.9}
		>
			{communityList.map((list: ICommunityListItem, cIdx: number) => (
				<li className="review-item" key={`review-item-${list.id}-${cIdx}`}>
					<div className="review-item-header clearfix">	
						<div className="user-icon" style={{backgroundImage: `url(${list.profileUrl})`}}>
						</div>
						<div className="user-info-wrap">
							<div>
								<span className="user-nick">{list.nick}</span>
								{list.isCertification > 0 &&
								<span className="user-verified">
									인증
									<i className="icon icon-verified" style={{marginTop: -1}}></i>
								</span>	
								}
							</div>
							<span className="user-route small">
								{list.weddingMonth} {list.partnerText ? `/ ${list.partnerText}` : ''}
							</span>
						</div>
						<time className="user-writing-time" dateTime={list.regTsp}>
							{getDiffToString(list.regTsp)}
							{getDiffFromNow(list.regTsp, 'hours') < 23 && 
							<img src={IconReviewNew} alt="New" width={14} />
							}
						</time>
					</div>
					<div onClick={() => linkCommunityDetail(list.uuid)}>
						<div className="review-item-body">
							<p>
								{list.content.substr(0, 100)}
								{list.content.length > 100 &&
								<>
								...
								<span> 더보기</span>
								</>
								}
							</p>
							{list.reviews.length > 0 && 
							<div className="reivew-item-contents-form">
								{list.reviews.map((review: any, RIdx) => (
								<div key={`review-item-contents-form-${RIdx}`}>
									<h3>{review.kname} <span className="icon icon-recommend"></span></h3>
									<p>{review.v}</p>
								</div>
								))}
							</div>
							}
							{(Array.isArray(list.images)) &&
							<ul 
								className={`review-item-images-wrap ${list.images.length === 1 ? 'image-len-1' : ''} ${list.images.length === 2 ? 'image-len-2' : ''}`}
							>
								{list.images.map((image: {url: string}, imgIdx) => (
								<li 
									className="review-item-images" 
									style={{backgroundImage: `url(${image.url})`}}
									key={`review-item-images-${imgIdx}`}
								></li>
								))}
							</ul>
							}
							{list.tags !== null && list.tags.length > 0 &&
							<ul className="review-item-tags-wrap">
								{list.tags.map((tag: any, idx: number) => (
								<li key={`list-${list.uuid}-tag-${tag.id}-${idx}`}>#{tag.name}</li>
								))}
							</ul>
							}
							{list.answer !== null &&
							<div className="review-item-answer-wrap">
								<div className="review-item-answer-top">
									<div className="answer-name">
										<i className="icon"></i>
										{list.answer.authorNick}
									</div>
									<span className="answer-date">{list.answer.regTsp.substr(0, 10)}</span>
								</div>
								<p>{list.answer.content}</p>
							</div>
							}
						</div>
					</div>
					<div className="review-item-footer">
						<div className="review-like-cnt" data-uuid={list.uuid}>
							<div onClick={() => {
								if (list.isLike) {
									likeAction(list.uuid, 1, 0);
								} else {
									toggleLikeActions(list.uuid);
								}
							}}>
							<img src={list.isLike ? IconHeartOn : IconHeartOff} alt="좋아요" width={16} /> <span>{list.likeCnt}</span>
							</div>
							<ul className={`like-action-icons-wrap ${currentShowIconUuid === list.uuid ? 'show' : ''}`}>
								<li onClick={() => likeAction(list.uuid, 1, list.isLike ? 0 : 1)}>
									<div className="like-action-icon type1"></div>
									좋아요
								</li>
								<li onClick={() => likeAction(list.uuid, 2, list.isLike ? 0 : 1)}>
									<div className="like-action-icon type2"></div>
									도움돼요
								</li>
								<li onClick={() => likeAction(list.uuid, 3, list.isLike ? 0 : 1)}>
									<div className="like-action-icon type3"></div>
									예뻐요
								</li>
								<li onClick={() => likeAction(list.uuid, 4, list.isLike ? 0 : 1)}>
									<div className="like-action-icon type4"></div>
									공감해요
								</li>
								<li onClick={() => likeAction(list.uuid, 5, list.isLike ? 0 : 1)}>
									<div className="like-action-icon type5"></div>
									힘내요
								</li>
							</ul>
						</div>
						<div onClick={() => linkCommunityDetail(list.uuid)}>
							<img src={IconComment} alt="댓글" width={16} /> <span>{list.replyCnt}</span>
						</div>
						<button 
							className="btn btn-tight btn-no-padding btn-auto-height btn-transparent"
							onClick={() => {
								setCommunityModalData({
									communityUuid: list.uuid,
									customerUuid: list.customerUuid
								});
							}}
						>
							<img src={BtnCommunityMore} alt="옵션" width={18} />
						</button>
					</div>
				</li>
			))}
		</InfiniteScroll>
		</div>
		
		{communityModalData.communityUuid !== null && communityModalData.customerUuid !== null &&
		<CommunityMoreModal 
			category={2}
			communityUuid={communityModalData.communityUuid}
			customerUuid={communityModalData.customerUuid}
			clearData={() => {
				setCommunityModalData({communityUuid: null, customerUuid: null});
			}}
		/>
		}
		</>
	);
}
const SamsungEventReviewList = () => {
	const user = useContext(UserContext);
	const [communityList, setCommunityList] = useState<any[]>([]);
	const [communityModalData, setCommunityModalData] = useState<{communityUuid: string|null; customerUuid: string|null}>({
		communityUuid: null,
		customerUuid: null
	});
	const linkCommunityDetail = (uuid: string) => {
		// window.location.href = `h2mwbell://community?rootUuid=${uuid}&communityUuid=&isReview=1`;
	}
	const [currentShowIconUuid, setCurrentShowIconUuid] = useState('');
	const toggleLikeActions = (uuid: string) => {
		if (currentShowIconUuid === uuid) {
			setCurrentShowIconUuid('');
		} else {
			setCurrentShowIconUuid(uuid);
		}
	}
	const likeAction = (communityUuid: string, type: number, state:number) => {
		likeReview(communityUuid, type, state).then(_ => {
			const newList = communityList.map(list => {
				if (list.uuid === communityUuid) {
					list.isLike = state;
					list.likeCnt = list.likeCnt + (state ? 1 : -1)
				}
				return list
			});
			setCommunityList(newList);
			setCurrentShowIconUuid('');
		});
	}
	useEffect(() => {
		if (!user.me) return; 
		getPartnerReview({
			category: 6,
			fetchSize: 2000,
			uuid: user.me.uuid,
			wegazineUuid: SAMSUNG_EVENT_WEGAZINE_UUID
		}).then(res => {
			setCommunityList(res.data.item);
		})
	}, [user.me]);
	return (
		<div className="review-item-list-wrap">
			{communityList.map((list, cIdx) => (
			<li className="review-item" key={`review-item-${list.id}-${cIdx}`}>
				<div className="review-item-header clearfix">	
					<div className="user-icon" style={{backgroundImage: `url(${list.profileUrl})`}}>
					</div>
					<div className="user-info-wrap">
						<div>
							<span className="user-nick">{list.nick}</span>
							{list.isCertification > 0 &&
							<span className="user-verified">
								인증
								<i className="icon icon-verified" style={{marginTop: -1}}></i>
							</span>	
							}
						</div>
						<span className="user-route small">
							{list.weddingMonth} {list.partnerText ? `/ ${list.partnerText}` : ''}
						</span>
					</div>
					<time className="user-writing-time" dateTime={list.regTsp}>
						{getDiffToString(list.regTsp)}
						{getDiffFromNow(list.regTsp, 'hours') < 23 && 
						<img src={IconReviewNew} alt="New" width={14} />
						}
					</time>
				</div>
				<div onClick={() => linkCommunityDetail(list.uuid)}>
					<div className="review-item-body">
						<p style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
							{list.content}
						</p>
						
						{(Array.isArray(list.images)) &&
						<ul 
							className={`review-item-images-wrap ${list.images.length === 1 ? 'image-len-1' : ''} ${list.images.length === 2 ? 'image-len-2' : ''}`}
						>
							{list.images.map((image: {url: string}, imgIdx: number) => (
							<li 
								className="review-item-images" 
								style={{backgroundImage: `url(${image.url})`}}
								key={`review-item-images-${imgIdx}`}
							></li>
							))}
						</ul>
						}
						{list.tags !== null && list.tags.length > 0 &&
						<ul className="review-item-tags-wrap">
							{list.tags.map((tag: any, idx: number) => (
							<li key={`list-${list.uuid}-tag-${tag.id}-${idx}`}>#{tag.name}</li>
							))}
						</ul>
						}
						{list.answer !== null &&
						<div className="review-item-answer-wrap">
							<div className="review-item-answer-top">
								<div className="answer-name">
									<i className="icon"></i>
									{list.answer.authorNick}
								</div>
								<span className="answer-date">{list.answer.regTsp.substr(0, 10)}</span>
							</div>
							<p>{list.answer.content}</p>
						</div>
						}
					</div>
				</div>
				<div className="review-item-footer">
					<div className="review-like-cnt" data-uuid={list.uuid}>
						<div onClick={() => {
							if (list.isLike) {
								likeAction(list.uuid, 1, 0);
							} else {
								toggleLikeActions(list.uuid);
							}
						}}>
						<img src={list.isLike ? IconHeartOn : IconHeartOff} alt="좋아요" width={16} /> <span>{list.likeCnt}</span>
						</div>
						<ul className={`like-action-icons-wrap ${currentShowIconUuid === list.uuid ? 'show' : ''}`}>
							<li onClick={() => likeAction(list.uuid, 1, list.isLike ? 0 : 1)}>
								<div className="like-action-icon type1"></div>
								좋아요
							</li>
							<li onClick={() => likeAction(list.uuid, 2, list.isLike ? 0 : 1)}>
								<div className="like-action-icon type2"></div>
								도움돼요
							</li>
							<li onClick={() => likeAction(list.uuid, 3, list.isLike ? 0 : 1)}>
								<div className="like-action-icon type3"></div>
								예뻐요
							</li>
							<li onClick={() => likeAction(list.uuid, 4, list.isLike ? 0 : 1)}>
								<div className="like-action-icon type4"></div>
								공감해요
							</li>
							<li onClick={() => likeAction(list.uuid, 5, list.isLike ? 0 : 1)}>
								<div className="like-action-icon type5"></div>
								힘내요
							</li>
						</ul>
					</div>
					<div onClick={() => linkCommunityDetail(list.uuid)}>
						<img src={IconComment} alt="댓글" width={16} /> <span>{list.replyCnt}</span>
					</div>
					{/* <button 
						className="btn btn-tight btn-no-padding btn-auto-height btn-transparent"
						onClick={() => {
							setCommunityModalData({
								communityUuid: list.uuid,
								customerUuid: list.customerUuid
							});
						}}
					>
						<img src={BtnCommunityMore} alt="옵션" width={18} />
					</button> */}
				</div>
			</li>
			))}

			{/* {communityModalData.communityUuid !== null && communityModalData.customerUuid !== null &&
			<CommunityMoreModal 
				category={2}
				communityUuid={communityModalData.communityUuid}
				customerUuid={communityModalData.customerUuid}
				clearData={() => {
					setCommunityModalData({communityUuid: null, customerUuid: null});
				}}
			/>
			} */}
		</div>
	)
}

const PartnerDetail = () => {
	const params: any = useParams();
	const [tabId, setTabId] = useState(1);
	const [partner, setPartner] = useState<IPartner|null>(null);
	const [product, setProduct] = useState<any>([]);
	const [hasProductData, setHasProductData] = useState(true);
	const [brand, setBrand] = useState<any>([]);
	const [hasBrandData, setHasBrandData] = useState(true);
	const [bottomModalState, setBottomModalState] = useState(true);
	const [successModalState, setSuccessModalState] = useState(false);
    const [useRealtimeBooking, setUseRealtimeBooking] = useState(null);//실시간예약 사용 여부 

	const query = useUrlQueryString();
	useEffect(() => {
		const fetchData = async () => {
			const partnerData = await getPartner(params.uuid);
            setPartner(partnerData.data.item);

            const realtimePartnerData = await getVenueInfo(`${partnerData.data.item.id}`)
            setUseRealtimeBooking(realtimePartnerData.data.data.consultingUsed && realtimePartnerData.data.data.consultingSlotsDisplayed)
            
			const productData = await getPartnerDetail(params.uuid, 1);
			const brandData = await getPartnerDetail(params.uuid, 2);

			setProduct(productData.data.item);
			if (productData.data.item.length === 0) {
				setHasProductData(false);
				setTabId(3);
			}
			setBrand(brandData.data.item);
			if (brandData.data.item.length === 0) {
				setHasBrandData(false);
			}
		}
		fetchData();
		const queryTabId = query.get('tabId');
		if (queryTabId) {
			setTabId(+queryTabId);
		}
	}, []);
	const headerRef = useRef<HTMLDivElement>(null);
	const tabRef = useRef<HTMLUListElement>(null);
	useEffect(() => {
		// scroll event
		const handler = () => {
			const elInner = document.getElementById('partnerDetailInner');
			if (!headerRef.current || !tabRef.current || !elInner) return;
			const elHeader = headerRef.current;
			const elTab = tabRef.current;
			const sy = window.scrollY || window.document.body.scrollTop || window.pageYOffset;
			// 헤더 색상 변환 로직
			if (sy > 252) {
				elHeader.classList.add('white-type');
			} else {
				elHeader.classList.remove('white-type');
			}
			// 탭 위치 고정/해제 로직
			if (sy > elInner.offsetTop - elHeader.clientHeight) {
				elTab.classList.add('fixed');
			} else {
				elTab.classList.remove('fixed');
			}
		}
		window.addEventListener('scroll', handler);
		return () => {
			window.removeEventListener('scroll', handler);
		}
	}, []);
	useEffect(() => {
		if (!headerRef.current || !tabRef.current) return;
		tabRef.current.style.top = headerRef.current.clientHeight + 'px';
	}, [headerRef.current, tabRef.current]);
	
	const purchaseNow = () => {
		if (!partner) return;
		if (partner.b2cMallCount > 0) window.location.href = `h2mwbell://product/mall?partnerUuid=${partner.uuid}`;
		
	}
	return (
		<div className="partner-detail-page">
			<div>
				{partner &&
				<>
				<PartnerDetailHeader partner={partner} partnerUuid={partner.uuid} partnerName={partner.name} isLike={partner.isLike} headerRef={headerRef} />
				<PartnerImageSlider partner={partner} />
				</>
				}
			</div>
			{(partner?.popup && partner.popup.image.content !== '') &&
			<div className="partner-pop-image-content">
				{partner.popup.image.content}
			</div>
			}
			<div className="partner-info-wrap">
				<b className="partner-name">{partner?.name}</b>
				{partner?.tags &&
				<div className="partner-location">
					<i className="icon icon-partner-location"></i>
					{partner.tags.filter(tag => tag.type === 1).length > 0 && partner.tags.filter(tag => tag.type === 1)[0].name} {partner.tags.filter(tag => tag.type === 2).length > 0 && partner.tags.filter(tag => tag.type === 2)[0].name}
				</div>
				}
				{(partner && partner.tags.filter(tag => [3, 4, 5, 15].includes(tag.type)).length > 0) &&
				<ul className="partner-tag-list-wrap">
					{partner?.tags.filter(tag => [3, 4, 5, 15].includes(tag.type)).map(tag => (
					<li key={`partner-tag-list-${tag.name}`}>
						#{tag.type === 5 ? `식대 ${addComma(tag.name)}원` : tag.name}
					</li>
					))}
				</ul>
				}

				{partner && partner.b2cMallCount > 0 &&
				<div className="link-mall-wrap">
					<p><b>웨딩북몰</b>에서 상품을 구매하실 수 있는 업체입니다.</p>
					<button className="btn bold btn-full btn-transparent" onClick={purchaseNow}>
						<i className="icon icon-shopping"></i>
						구매하기
					</button>
				</div>
				}
				
				{partner && partner.benefits.length > 0 &&
				<ul className="partner-benefit-list-wrap">
					{partner.benefits.map((benefit, idx) => (
					<li key={`partner-benefit-${partner.uuid}-${idx}`}>
						<div className="benefit-top">
							<i className="icon" style={{backgroundImage: `url(${benefit.iconUrl})`}}></i>
							<b className="benefit-title">{benefit.title}</b>
						</div>
						<p>{benefit.content}</p>
					</li>
					))}
				</ul>
				}
			</div>
			<div id="partnerDetailInner" className="partner-detail-inner">
				<Tab 
					currentId={tabId}
					hasProductData={hasProductData}
					reviewCount={(partner?.reviewTotalCount && partner?.uuid !== SAMSUNG_EVENT_PARTNER_UUID) ? partner.reviewTotalCount : 0}
					onClick={(id) => setTabId(id)}
					tabRef={tabRef}
				/>
				{(tabId === 1 && partner )&&
				<div className="partner-product-detail-inner">
				{product.map((p: any, idx: number) => {
					return <DetailParser data={p} idx={idx} key={`partner-product-detail-${idx}`} />
				})}
				</div>
				}
				{(tabId === 2 && partner) &&
				<>
				{partner.store.length > 1 &&
				<>
				<h2 className="product-sub-title type4" style={{padding: '24px 12px 0'}}>
					Store Information
					<span>스토어 안내</span>
				</h2>
				<ul className="chain-store-item-wrap clearfix">
				{partner.store.map(st => (
				<li className="chain-store-item" key={`chain-store-item-${st.uuid}`}>
					<a href={`/partner/${st.uuid}`}>
						<h3>{st.name}</h3>
						<p>{st.address}</p>
						<span className="icon icon-chevron-right-18-gray"></span>
					</a>
				</li>
				))}
				</ul>
				</>
				}
				</>
				}
				{(tabId === 2 && brand.length > 0) &&
				<div style={{padding: '0 12px'}}>
				{brand.map((b: any, idx: number) => {
					return <DetailParser data={b} idx={idx} key={`partner-brand-detail-${idx}`}/>
				})}
				</div>
				}
				{(tabId === 2 && partner) &&
				<div className="company-info-contents">
					<b className="company-info-contents-title">업체 정보</b>
					<div>
						<div className="company-info-contents-logo" style={{backgroundImage: `url(${partner?.logoUrl})`}}></div>
						<div className="company-info-">
							<h3>{partner?.name}</h3>
							<p>{partner?.content}</p>
						</div>
					</div>
					<dl className="company-info-contents-list">
						<div>
							<dt>영업시간</dt>
							<dd>{partner?.bizHour ? partner?.bizHour : '-'}</dd>
						</div>
						<div>
							<dt>연락처</dt>
							<dd>
								{partner?.tel 
								? <a href={`tel:${partner?.tel}`}>{partner.tel}</a>
								: '-'
								}
								
							</dd>
						</div>
						<div>
							<dt>주소</dt>
							<dd>
								<span style={{display: 'inline-block', marginRight: 8}}>
									{partner.address ? partner.address : '-'}
								</span>
								{partner.address &&
								<button className="btn btn-auto-width btn-auto-height btn-brown btn-link-map" onClick={() => {
									window.location.href = isExternalUrl(`https://map.kakao.com/link/search/${partner.address}`)
								}}>
									<i className="icon icon-map-mark" />
									지도
								</button>
								}
								
							</dd>
						</div>
						<div>
							<dt>홈페이지</dt>
							<dd>
								{partner?.homepageUrl 
								? <a href={isExternalUrl(partner.homepageUrl)}>{partner.homepageUrl}</a>
								: '-'
								}
								
							</dd>
						</div>
						<div>
							<dt>방문안내</dt>
							<dd>{partner?.parking ? partner?.parking : '-'}</dd>
						</div>
					</dl>
				</div>
				}
				{!!partner &&
				<div style={{display: tabId === 3 ? 'block' : 'none'}}>
				{partner.uuid !== SAMSUNG_EVENT_PARTNER_UUID
				? <CommunityReviewList partner={partner} tabId={tabId} />
				: <SamsungEventReviewList />
				}	
				</div>
				}

				{(tabId === 4 && partner) &&
				<PartnerInquiry partner={partner} />
				}
			</div>
			{partner &&
			<PartnerDetailBottomButtons partner={partner} useRealtimeBooking={useRealtimeBooking}/>
			}
			
			{(partner?.popup && partner.popup.show > 0) &&
			<>
			<BottomFixedModal
				isShow={bottomModalState}
				title={partner.popup.popup.title}
				hasCloseButton={true}
				hideModal={() => {
					setBottomModalState(false);
				}}
			>
				<div className="partner-detail-fixed-modal-inner">
					<p className="popup-content">{partner.popup.popup.content}</p>
					{partner.popup.popup.benefit &&
					<div className="benefit-list-wrap">
					{partner.popup.popup.benefit}
					</div>
					}
					{partner.popup?.popup.linkType === 'WEDDINGHALL' &&
					<p className="hall-notice">🔔 비교 리스트 신청은 방문예약이 진행되거나 웨딩홀에 기록이 남지 않습니다.</p>
					}
					<button 
						className="btn bold btn-full btn-large btn-red btn-modal-action"
						onClick={() => {
							const popupData = partner.popup?.popup;
							if (partner.popup?.popup.linkType === 'LINK' && popupData?.link) {
								window.location.href = isExternalUrl(popupData.link);
							}
							if (partner.popup?.popup.linkType === 'WEDDINGHALL') {
								axios.post(`${BASE_URL}/v3/apply/hallCompare`, QueryString.stringify({partnerUuid: partner.uuid}))
								.then(_ => {
									setBottomModalState(false);
									setSuccessModalState(true);
								}).catch(_ => {
									alert('서버 오류로 인해 신청에 실패했습니다.');
								});
							}
						}}
					>
						{partner.popup.popup.buttonName}
					</button>
				</div>
			</BottomFixedModal>
			<Modal
				isShow={successModalState}
				text={`웨딩홀 리스트 신청이 완료되었습니다.
				확인 후 순차적으로 안내드릴게요!`}
				onClose={() => {
					setSuccessModalState(false);
				}}
			/>
			</>
			}
		</div>
	);
};

export default PartnerDetail;