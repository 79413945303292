import axios from '@src/api/axios';
import SectionTitle from '@src/components/common/sectionTitle/SectionTitle';
import { ILinkPayPaymentReadyResponse, ILinkPayInfoResponse } from '@src/types/linkPay';
import { isWebview, addComma, isExternalUrl, BASE_URL } from '@src/utils';
import { useState, useEffect, useContext, useMemo } from 'react';
import Checkbox from '@src/components/common/checkbox/Checkbox';
import moment from 'moment';
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { loadPaymentWidget, ANONYMOUS } from '@tosspayments/payment-widget-sdk'
import { UserContext } from '@src/App';
import { useNavigate, useParams } from 'react-router-dom';
import { TOSS_CLIENT_KEY } from '@src/utils/toss';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import WebHeader from '@src/components/linkpay/WebHeader';
import DataList from '@src/components/linkpay/DataList';
import { useThrottle } from '@src/utils/hooks/useThrottle';
// import { useQuery } from '@tanstack/react-query';
import RealTimePolicyModal from '@src/pages/booking/realtimeBooking/RealTimePolicyModal';

// 다른데 필요하면 컴포넌트 폴더로 옮겨야함
const LinkPay = () => {
	const user = useContext(UserContext);
	const params = useParams<{uuid: string}>();
	const navigate = useNavigate();
	const [isExpired, setIsExpired] = useState(false);
	const [payData, setPayData] = useState<ILinkPayInfoResponse | null>(null);
	const [agreement, setAgreement] = useState(false);
	const [isShowWidget, setIsShowWidget] = useState(false);
	const [paymentWidget, setPaymentWidget] = useState<any>(null);
	const throttle = useThrottle();

	// 실시간 예약 관련 상태 - start
	const [isShowTerm, setIsShowTerm] = useState(false);
	// 실시간 예약 관련 상태 - end 
	const getPayData = async () => {
        
		const { data } = await axios.get<{data: ILinkPayInfoResponse|string; result: number}>(`${BASE_URL}/web/v3/240326/linkPay/${params.uuid}`);
		if (data.result === 0) {
			if (typeof data.data === 'string') {
				if (data.data.indexOf('만료') > -1) setIsExpired(true);
				alert(data.data);
			}
			return;
		}
		if ((data.data as ILinkPayInfoResponse).vbank || (data.data as ILinkPayInfoResponse).info.paymentState === 1) {
			navigate(`/linkPay/${params.uuid}/info`, {replace: true});
		} else {
			setPayData(data.data as ILinkPayInfoResponse);
		}
		
	}
	const closeWidget = () => {
		setIsShowWidget(false);
	}
	const payWidget = async () => {
		const { data } = await axios.post<{data: ILinkPayPaymentReadyResponse}>(`${BASE_URL}/web/v3/240326/linkPay/${params.uuid}/payment/ready`);
		const { orderId, orderName } = data.data;
		const customerName = payData.info.name ? payData.info.name : '';

		paymentWidget.requestPayment({
			orderId: orderId,
			orderName: orderName,
			customerName: customerName,
			successUrl: `${window.location.origin}/linkPay/payment/success?linkPayUuid=${params.uuid}`,
			failUrl: `${window.location.origin}/linkPay/payment/fail?linkPayUuid=${params.uuid}`
		});
	}
	const readyToPayment = async () => {
		if (!agreement) {
			alert('결제내용 확인 및 이용에 동의 해주세요.');
			return;
		}
		const { data } = await axios.post<{data: ILinkPayPaymentReadyResponse}>(`${BASE_URL}/web/v3/240326/linkPay/${params.uuid}/payment/ready`);
		const { amount, dueDate, orderId, orderName } = data.data;
		const customerName = payData.info.name ? payData.info.name : '';
        const failUrl = payData.info.type === 5 ? `${window.location.origin}/linkPay/payment/fail?linkPayUuid=${params.uuid}&payType=${payData.info.type}` : `${window.location.origin}/linkPay/payment/fail?linkPayUuid=${params.uuid}`
		if (moment(dueDate).isAfter(new Date())) {
			// toss 
			const reqPayData = {
				amount: amount,
				orderId: orderId,
				orderName: orderName,
				customerName: customerName,
				successUrl: `${window.location.origin}/linkPay/payment/success?linkPayUuid=${params.uuid}&payType=${payData.info.type}`,
				failUrl: failUrl,//`${window.location.origin}/linkPay/payment/fail?linkPayUuid=${params.uuid}`,
				dueDate: moment(dueDate).format()
			}
			if (payData.info.pgType === 'widget') {
				const customerKey = user.me ? user.me.uuid : ANONYMOUS; // 웨딩북 uuid or 비회원
				const pw = await loadPaymentWidget(TOSS_CLIENT_KEY, customerKey);
				if (payData.info.type === 5) {
					pw.renderPaymentMethods('#paymentWidget', {value: amount}, {variantKey: 'LINK-PAY-REALTIME'});
				} else {
					pw.renderPaymentMethods('#paymentWidget', {value: amount}, {variantKey: 'LINK_PAY'});
				}
				setPaymentWidget(pw);
				setIsShowWidget(true);
			} else {
				loadTossPayments(TOSS_CLIENT_KEY).then(tossPayments => {
					// const PayMethod = orderName === '계약금' ? '카드' : '가상계좌';
					tossPayments.requestPayment('가상계좌', reqPayData);
				});
			}
		}
	}
	useEffect(() => {
		getPayData();
	}, []);
	var payerInfo = useMemo(() => {
		if (!payData) return [];
		const result = [{key: payData.info?.name, value: payData.info?.tel}];
		if (payData.info.type === 5) {
			result.push({key: payData.info?.mateName, value: payData.info?.mateTel});
		} else {
			result.push({key: '플래너 ' + payData.info?.plannerName, value: payData.info?.plannerTel});
		}
		return result;
	}, [payData])
	return (
		<div className={`linkpay-page-wrap ${isShowWidget ? 'show-payment-widget' : ''}`}>
		{/* {(!isExpired && isWebview()) &&
		<AppHeader type="stack" actionItems={[{name: 'close'}]} />
		} */}
		{/* {(!isExpired && !isWebview()) &&
		payData?.info?.type !== 5 ? <WebHeader /> : <AppHeader type="stack" />
		} */}
        {(!isExpired && !isWebview()) && payData &&
        <>
            {payData?.info?.type !== 5 && <WebHeader />}
        </>
        }
        {/* <AppHeader type="stack" /> */}
		<div className="linkpay-wrap"  style={{marginTop:payData?.info?.type === 5?'56px':''}}>
			{payData && <>
			<section className="linkpay-section">
				<SectionTitle title={{titleType: 'mainSubOnlyMain', mainTitle: '결제정보'}} />
				<p className="linkpay-content">{payData.info.content}</p>
			</section>
			<section className="linkpay-section">
				<SectionTitle title={{titleType: 'mainSubOnlyMain', mainTitle: '결제자 정보'}} />
				<DataList list={payerInfo} />
			</section>
			<section className="linkpay-section">
				<SectionTitle title={{titleType: 'mainSubOnlyMain', mainTitle: '최종 결제 금액'}} />
				<DataList list={[{key: payData.info.typeStr, value: `${addComma(payData.info.price)}원`}]} />
			</section>
			<div style={{padding: '40px 20px 20px'}}>
				<div className="agreement-check-wrap" style={{marginBottom: 20}}>
					<Checkbox 
						id="agreement"
						name="agreement"
						value="agreement"
						setValue={() => setAgreement(!agreement)}
						checked={agreement}
					/>
					<label className="agreement-label" htmlFor="agreement" style={{marginLeft: 0}}>결제내용 확인 및 이용 동의</label>
				</div>
				<div style={{marginTop: -20}}>
					{payData.info.type !== 5 
					? <>
					<a className="link-terms" href={isWebview() ? isExternalUrl(`https://www.wdgbook.com/agreement`) : `https://www.wdgbook.com/agreement`} target="_blank" rel="noreferrer">
						개인정보 및 제 3자 제공 공지
						<i className="icon icon-chevron-right-18-gray700" />
					</a>
					<a className="link-terms" href={isWebview() ? isExternalUrl(`https://weddingbook.notion.site/40131c2cc89b4a0e8e76651dd8dc1dbb`) : `https://weddingbook.notion.site/40131c2cc89b4a0e8e76651dd8dc1dbb`} target="_blank" rel="noreferrer">
						이용 약관 자세히 보기
						<i className="icon icon-chevron-right-18-gray700" />
					</a>
					</>
					: <>
					<span className="link-terms" onClick={() => setIsShowTerm(true)} role="button">
						개인정보 및 제 3자 제공공지
						<i className="icon icon-chevron-right-18-gray700" />
					</span>
					</>
					}
				</div>
				<button 
					className="btn btn-full btn-large btn-red btn-payment"
					onClick={throttle(readyToPayment, 3000)}
				>
					{addComma(payData.info.price)}원 결제하기
				</button>
			</div>
			</>
			}
		</div>
		{isExpired &&
		<div className="linkpay-expired-wrap">
			<p>
				이 결제에 대한 링크는 기한이 만료되었습니다.<br/>
				결제가 필요한 경우  플래너에게 <br/>
				결제 링크를 다시 보내달라고 요청하세요.
			</p>
		</div>
		}
		<div id="paymentWidgetWrap">
			<button className="btn btn-tight btn-no-padding btn-auto-height btn-transparent btn-widget-close" onClick={closeWidget}>
				<i className="icon icon-close-black"></i>
			</button>
			<div id="paymentWidget"></div>
			<div className="btn-wrap">
				<button className="btn bold btn-full btn-large btn-red" onClick={throttle(payWidget, 3000)}>결제하기</button>
			</div>
		</div>
		{isShowTerm &&
			<>
			<RealTimePolicyModal readOnly={true} />
			<button className="btn btn-red" style={{position: 'fixed', left: 20, right: 20, bottom: 20, zIndex: 99999}} onClick={() => setIsShowTerm(false)}>닫기</button>
			</>
		}
		</div>
	);
};

export default LinkPay;