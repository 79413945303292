import {IProgram} from "../../types/interface/Chungdam";

type ProgramItemProps = {
    program: IProgram
}

const ProgramItem = (props: ProgramItemProps) => {
    const {program} = props;

    return (
        <div className={'content-item'}>
            <h3 className={'golden-title'}>{program.miniTitle}</h3>
            <h2 className={'title'}>{program.title}</h2>
            <div className={'image'} style={{backgroundImage: `url('${program.imageUrl}')`}}/>
            <p className={'content'}>{program.description}</p>
            {program.items.map((item, index) => (
                <div key={index}>
                    <div className={'title-with-icon'}>
                        <div className={'icon-wrapper'}>
                            {item.icon && <img alt={item.title} src={item.icon} width={20} height={20}/>}
                        </div>
                        <p className={'item-title'}>{item.title}</p>
                    </div>
                    <p className={'label'}>{item.colorLabel}</p>
                </div>
            ))}
        </div>
    )
}

export default ProgramItem;
