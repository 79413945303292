import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { useNavigate } from 'react-router-dom';

import WebHeader from '@src/components/linkpay/WebHeader';

const LinkPayFail = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const message = query.get('message');
	const linkPayUuid = query.get('linkPayUuid');
    const payType = query.get('payType') || null;

	return (
		<>
		{payType !== '5' && <WebHeader />}
		<div className="linkpay-result-page" style={{marginTop:payType === '5'?'56px':''}}>
			<h2>
				앗.<br/>
				결제에 <br/>
				실패하였습니다.
			</h2>
			<p>
				결제도중 오류가 발생하여 결제가 취소 되었습니다.<br/>
				결제 정보와 결제 수단을 확인 후 다시 결제해주세요.
			</p>
			<div className="result-info-wrap">
				<h3>실패 사유</h3>
				<p>{message}</p>
			</div>
			<button className="btn bold btn-red btn-circle" onClick={() => navigate(`/linkPay/${linkPayUuid}`, {replace: true})}>다시 결제</button>
		</div>
		</>
	);
};

export default LinkPayFail;