import { useState, useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import axios from '@src/api/axios';
import { BASE_URL, isExternalUrl } from '@src/utils';
import useUrlQueryString from '../../utils/hooks/useUrlQueryString';
import CompanyOrder from '@src/components/home/companyOrder/CompanyOrder';
import { PORTAL_URL } from './../../utils/index';
import { logEvent } from '@src/firebase';
import PageTab, {ITab} from './../../components/home/pageTab/PageTab';

interface Props {

}
// const PARTNER_INFO: {[key: string]: {name: string}} = {
// 	'6': {name: '한복'},
// 	'8': {name: '예물'},
// 	'15': {name: '예복'},
// 	'19': {name: '신혼여행'},
// 	'20': {name: '가구'},
// 	'23': {name: '가전'},
// }
const TUTORIAL_CONTENTS = [
	{
		title: '① 업체 선택하고 예약하기',
		contents: `캐시백 태그가 있는 업체에 
		예약하고 계약하세요.`
	},
	{
		title: '② 캐시백 신청하기',
		contents: `스탬프 적립하기 버튼을 눌러 캐시백을 신청하세요.
		스탬프는 자동으로 적립됩니다.`
	},
	{
		title: '③ 스탬프 완성하고 선물 받기',
		contents: `스탬프 3개, 5개를 다 모으면
		선물을 신청하세요. `
	}
]
const TutorialModal = () => {
	const [index, setIndex] = useState(0);
	const postDone = () => {
		axios.post(`${BASE_URL}/web/v3/stamps/tutorial`);
	}
	
	return <div className={`stamp-tutorial-modal-wrap ${index >= 3 ? 'hide' : ''}`}>
		<div className="overlay" />
		<div className="stamp-tutorial-modal">
			<div className={`tutorial-img`}>
				<div className={`img1 ${index === 0 ? 'on' : ''}`}></div>
				<div className={`img2 ${index === 1 ? 'on' : ''}`}></div>
				<div className={`img3 ${index === 2 ? 'on' : ''}`}></div>
			</div>
			<div className="tutorial-bottom">
				{index <= 2 &&
				<div className="tutorial-contents">
					<h3>{TUTORIAL_CONTENTS[index].title}</h3>
					<p>{TUTORIAL_CONTENTS[index].contents}</p>
				</div>
				}
				<div className="tutorial-controller-wrap">
					<button
						className="btn btn-transparent" style={{color: '#a6a6a6'}}
						onClick={() => {
							postDone();
							setIndex(3);
						}}
					>
						건너뛰기
					</button>
					<ol className="tutorial-dots-wrap">
						{[0,1,2].map(idx => (
							<li className={idx === index ? 'on' : ''}></li>
						))}
					</ol>
					<button 
						className="btn btn-transparent bold" style={{color: '#9D7D54'}}
						onClick={() => {
							if (index === 2) {
								window.localStorage.setItem('tutorial', 'done');
							}
							setIndex(index+1);
						}}
					>
						{index === 2 ? '시작하기' : '다음'}
					</button>
				</div>
			</div>
			
		</div>
	</div>
}
interface IData {
	6: any[];
	8: any[];
	15: any[];
	19: any[];
	20: any[];
	23: any[];
}
function StampPartners({}: Props) {
	const query = useUrlQueryString();
	const [data, setData] = useState<IData|null>(null);
	const [partnerType, setPartnerType] = useState<string|null>(null);
	const [stamps, setStamps] = useState([]);
	const [gifts, setGifts] = useState<any[]>([]);
	// const stampCount = query.get('stampCount');
	const tab = useState<ITab[]>([
		{tabId: 0, tabTitle: '전체'},
		{tabId: 19, tabTitle: '신혼여행'},
		{tabId: 15, tabTitle: '예복'},
		{tabId: 8, tabTitle: '예물'},
		{tabId: 6, tabTitle: '한복'},
		{tabId: 20, tabTitle: '가구'},
		{tabId: 23, tabTitle: '가전'}
	])[0];
	const [isShowTutorial, setIsShowTutorial] = useState(false);
	
	const applyPresent = (count: number) => {
		return stamps.length >= count ? true : false;
	}
	const havePresent = (count: number) => {
		return gifts.filter(gift => gift.stampCount === count).length > 0 ? true : false;
	}
	const getPartnerTypeName = (type: string | number | null) => {
		if (type === null) return '';
		switch (+type) {
			case 6:
				return '한복';
			case 8:
				return '예물';
			case 15:
				return '예복';
			case 19:
				return '신혼여행';
			case 20:
				return '가구';
			case 23:
				return '가전';
			default:
				return '';
		}
	}
	const getPartnerData = (id: number) => {
		if (data === null) return [];
		let result:any = [];
		if (id === 0) {	
			[6, 8, 15, 19, 20, 23].map(id => {
				result = [...result, ...data[id as keyof IData].map(d => ({name: d.name, uuid: d.partnerUuid}))];
			});
		} else {
			result = data[id as keyof IData].map(d => ({name: d.name, uuid: d.partnerUuid}));
		}
		return result;
	}
	useEffect(() => {
		(async () => {
			const {data: data6} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=6`);
			const {data: data8} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=8`);
			const {data: data15} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=15`);
			const {data: data19} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=19`);
			const {data: data20} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=20`);
			const {data: data23} = await axios.get<{data: any}>(BASE_URL + `/web/v3/stamps/partners?partnerType=23`);
			const d = {
				6: data6.data,
				8: data8.data,
				15: data15.data,
				19: data19.data,
				20: data20.data,
				23: data23.data
			};
			setData({
				6: data6.data,
				8: data8.data,
				15: data15.data,
				19: data19.data,
				20: data20.data, 
				23: data23.data
			});
		})();

		axios.get<{data: any}>(`${BASE_URL}/web/v3/stamps/me`).then(res => {
			const data = res.data.data;
			if (data.gift) setGifts(data.gift);
			if (data.stamp) setStamps(data.stamp);
		})
		axios.get<{msg:string}>(`${BASE_URL}/web/v3/stamps/tutorial`).then(res => {
			setIsShowTutorial(res.data.msg.toLowerCase() !== 'done');
		});
	}, []);
	useEffect(() => {
		const partnerType = query.get('partnerType');
		if (partnerType !== undefined) {
			// axios.get(BASE_URL + `/web/v3/stamps/partners?partnerType=${partnerType}`).then((res: any) => {
			// 	const d: any[] = res.data.data;
			// 	console.log(d);
			// 	setData(d);
			// });
			setPartnerType(partnerType);

		}
	}, [query]);
	useEffect(() => {
		if (partnerType) {
			logEvent('Stamp_Listpage_View', {Partner_Type: partnerType, Partner_Type_Name: getPartnerTypeName(partnerType)});
		}
	}, [partnerType]);
	return (
		<>
		<AppHeader title={''} type="product" actionItems={[
			// {name: 'search'},
			// {name: 'like'},
			{name: 'close'}
		]} />
		<div className="page page-wedding-stamp-way">
			{isShowTutorial &&
			<TutorialModal />
			}
			<div>
				<h2 className="stamp-title">스탬프 적립하기</h2>
				<ul className="stamp-get-noti">
					{partnerType === '23'
					? <li>스탬프 태그가 있는 업체에 예약하고 계약하세요.</li>
					: <li>캐시백 태그가 있는 업체에 예약하고 계약하세요. </li>
					}
					{partnerType === '23' 
					? <li>[스탬프 적립하기] 버튼을 눌러 계약 인증하면 스탬프는 자동 적립 됩니다.</li>
					: <li>[스탬프 적립하기] 버튼을 눌러 캐시백을 신청하면 스탬프는 자동 적립 됩니다. </li> 
					}
				</ul>
				{/* <h2 className="partner-list-title">미션 참여 업체를 방문하세요.</h2> */}
				<div style={{margin: '0 -20px'}}>
				{partnerType !== null &&
				<PageTab
					tab={tab}
					className="tight"
					currentTabId={+partnerType}
					onChange={tabId => {
						setPartnerType(tabId.toString());
					}}
					key={`page-tab-stamp-partenrs`}
				/>
				}
				
				</div>
				{(data !== null && partnerType !== null) &&
				<div style={{margin: '0 -20px', paddingBottom: 80}}>
				<CompanyOrder 
					id={1}
					page={'Stamp_Listpage'} 
					data={getPartnerData(+partnerType)} 
				/>
				</div>
				}
				<div className="btn-wrap">
					{/* <span>
					{stamps.length < 3 &&
					<>{3 - stamps.length}</>
					}
					{(stamps.length >= 3 && stamps.length < 5 && havePresent(3)) &&
					<>{5 - stamps.length}</>
					}
					개를 더 모으면 선물을 드려요! 🎁
					</span> */}
					{stamps.length < 3 &&
					<span>{3 - stamps.length}개를 더 모으면 선물을 드려요! 🎁</span>
					}
					{(stamps.length >= 3 && stamps.length < 5 && havePresent(3)) &&
					<span>{5 - stamps.length}개를 더 모으면 선물을 드려요! 🎁</span>
					}
					{(stamps.length === 5 && havePresent(3) && havePresent(5)) &&
					<span>선물을 다 받았어요!</span>
					}
					{(stamps.length === 6 && havePresent(3) && havePresent(5)) &&
					<span>스탬프를 다 모았어요!</span>
					}
					{((applyPresent(3) && !havePresent(3)) || (applyPresent(5) && !havePresent(5))) &&
						<span>지금 바로 선물을 신청해보세요 🎁</span>
					}
					<button onClick={() => {
						logEvent('Stamp_Listpage_Action_Save', {});
						window.location.href = isExternalUrl(`${PORTAL_URL}/app/cashback`);
					}} className="btn btn-full btn-large btn-red">
						스탬프 적립하기
					</button>
				</div>
			</div>
		</div>
		</>
	);
}

export default StampPartners;