import { useState, useEffect, useContext } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import { useBookingHomeAppliance } from '../../../utils/hooks/useBookingHomeAppliance';
import { getPartnerList } from '@src/api/booking/homeAppliance';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { IPartner, getPartner } from '@src/api/partner/partner';
import { UserContext } from '@src/App';
import { setInPath } from '@src/@slice/bookingHomeApplianceSlicec';

const SelectBranch = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [selectedUuid, setSelectedeUuid] = useState('');
	const [partner, setPartner] = useState<IPartner|null>(null);
	const [stores, setStores] = useState<any[]>([]);
	const [validate, setValidate] = useState(false);
	const [isApp, setIsApp] = useState(true);
	const {dispatch} = useBookingHomeAppliance();
	const query = useUrlQueryString();

	const goNext = () => {
		navigate(`/booking/homeAppliance/apply?uuid=${selectedUuid}`);
	}
	useEffect(() => {
		setIsApp(user.me ? true : false);
	}, [user]);
	useEffect(() => {
		setValidate(selectedUuid !== '')
	}, [selectedUuid])
	useEffect(() => {
		const uuid = query.get('partnerUuid');
		const inpath = query.get('inpath');
		console.log(uuid);
		if (uuid) {
			getPartner(uuid).then(res => setPartner(res.data.item));
			getPartnerList(uuid).then(res => setStores(res.data.item));
		}
		if (inpath) {
			console.log(inpath)
			dispatch(setInPath({inpath: inpath}));
		}
	}, [query]);
	return (
		<div>
			{!isApp &&
			<AppHeader type="stack" title="웨딩북" actionItems={[]} />
			}
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`방문하고 싶은 지점을
						선택해주세요.`}
					/>
					<div className="common-wrap">
						<ul className="radio-list-wrap">
							{/* {partner &&
							<li>
								<RadioButton 
									id={`partner`}
									name="partnerList"
									value={partner.uuid}
									setValue={() => setSelectedeUuid(partner.uuid)}
									checked={partner.uuid === selectedUuid}
								/>
								<label htmlFor={`partner`}>
									{partner.name}
								</label>
							</li>
							} */}
							{stores.map((store, idx) => (
							<li key={`price-${idx}`}>
								<RadioButton 
									id={`store-${idx}`}
									name="partnerList"
									value={store.uuid}
									setValue={() => setSelectedeUuid(store.uuid)}
									checked={store.uuid === selectedUuid}
								/>
								<label htmlFor={`store-${idx}`}>
									{store.name}
								</label>
							</li>
							))}
						</ul>
					</div>
				</div>
				<div>
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};
 
export default SelectBranch;