import { useEffect, useState } from 'react';
import axios from '@src/api/axios';
// import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { BASE_URL, addComma, isWebview } from '@src/utils';
import { ILinkPayInfoResponse } from '@src/types/linkPay';
import { useParams } from 'react-router-dom';
import WebHeader from '@src/components/linkpay/WebHeader';
import SectionTitle from '@src/components/common/sectionTitle/SectionTitle';
import DataList, { DataListItem } from '@src/components/linkpay/DataList';
import dayjs from 'dayjs';

const LinkPayPayInfo = () => {
	const params = useParams<{uuid: string}>();
	// const query = useUrlQueryString();
	const [infoData, setInfoData] = useState<ILinkPayInfoResponse|null>(null);
	const [list, setList] = useState<DataListItem[]>([]);
	// const [vBankData, setVBankData] = useState<ILinkPayVBankResponse | null>(null);
	const getPayData = async () => {
		const { data } = await axios.get<{data: ILinkPayInfoResponse; result: number}>(`${BASE_URL}/web/v3/240326/linkPay/${params.uuid}`);
		const dObj = data.data;
		setInfoData(dObj);
		if (dObj.info.paymentState === 1) {
			const info = dObj.info;
			setList([
				{key: info.typeStr, value: `${addComma(info.price)}원`, valueClassName: 'bold red500'},
				{key: '결제수단', value: info.methodName},
				{key: '결제일시', value: dayjs(info.paymentTsp).locale('ko').format('YYYY.MM.DD (ddd) HH:mm')}
			]);
		} else if (dObj.hasOwnProperty('vbank')) {
			const vbank = dObj.vbank;
			setList([
				{key: '결제금액', value: `${addComma(vbank.amount)}원`, valueClassName: 'bold red500'},
				{key: '결제수단', value: '가상계좌'},
				{key: '은행명', value: vbank.bankName},
				{key: '계좌번호', value: vbank.accountNum},
				{key: '입금기한', value: vbank.expTspStr, keyClassName: 'bold', valueClassName: 'bold'}
			]);
		}
	}
	useEffect(() => {
		getPayData();
	}, []);
	if (!infoData) return null;
	return (
		<>
		{/* {!isWebview() &&
		infoData?.info?.type !== 5 ? <WebHeader /> : <AppHeader type="stack" />
		} */}
        {!isWebview() && infoData?.info?.type !== 5 && <WebHeader />}
		<div className="linkpay-result-page">
			{infoData.vbank &&
			<>
			<h2>
				짝짝짝!<br/>
				가상 계좌 신청이<br/>
				완료되었습니다.
			</h2>
			<p style={{marginBottom: 40}}>아래 가상계좌로 입금해주시면 정상적으로 결제가 완료됩니다.</p>
			</>
			}
			<div className="linkpay-wrap" style={{margin: '0 -20px'}}>
				<section className="linkpay-section" style={{marginTop:infoData?.info?.type === 5?'56px':''}}>
					<SectionTitle title={{titleType: 'mainSubOnlyMain', mainTitle: '결제내역'}} />
					<p className="linkpay-content">{infoData.info.content}</p>
				</section>
				<section className="linkpay-section">
					<SectionTitle title={{titleType: 'mainSubOnlyMain', mainTitle: '실 결제 금액'}} />
					{list.length > 0 &&
					<DataList list={list} />
					}
				</section>
			</div>
		</div>
		</>
	);
};

export default LinkPayPayInfo;