const Blank = ({height, backgroundColor = 'transparent', margin='', color}: {height: number, backgroundColor?: string, margin?:string; color?: string;}) => {
	return (
		// <div style={{ height: height + 'px' }}></div>
		<div style={{
			height: height + 'px',
			// backgroundColor: backgroundColor,
			margin: margin
		, backgroundColor: (color ? color : 'transparent')}}></div>

	);
};

export default Blank;