import './waitPage.scss';

interface IWaitPage {
	desc: string;
}
const WaitPage = ({desc}: IWaitPage) => {
	return (
		<div className="wait-page-wrap">
			<div className="typing-loader-wrap">
				<div className="typing-loader">
					<div className="dot dot1" />
					<div className="dot dot2" />
					<div className="dot dot3" />
				</div>
			</div>
			<div className="analyze-wait-text">
				<em>
					잠시만<br/>
					기다려주세요.
				</em>
				<p>{desc}</p>
			</div>
		</div>
	);
};

export default WaitPage;