import { useState, useEffect, useRef } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTab, {ITab} from './../../components/home/pageTab/PageTab';
import { useWindowSize } from '@src/utils/hooks/useWindowSize';
import axios from '@src/api/axios';
import { BASE_URL } from '@src/utils';
import Modal from '@src/components/common/modal/Modal';

const STATUS_TITLE: {[key in any]: string} = {
	0: '미사용',
	1: '사용완료',
	2: '기간만료'
}
// const COUPON_TYPE_B2CMALL_AMOUNT = 10
// const COUPON_TYPE_B2CMALL_RATE = 11
// const COUPON_TYPE_SDM_DOWN_PAYMENT = 20     //계약금할인
// const COUPON_TYPE_SDM_MIDDLE_PAYMENT = 21   //중도금할인
// const COUPON_TYPE_WDB_POINT = 30            //포인트쿠폰
// const COUPON_TYPE_LOUNGE_DRINK = 40         //라운지 음료쿠폰
// const COUPON_TYPE_LOUNGE_BOOKING = 42       //방문예약 할인쿠폰
// const COUPON_TYPE_LOUNGE_GIFT = 50          //라운지 기프트 쿠폰

const CouponComponent = ({coupon, showCouponModal}: {coupon: any, showCouponModal: () => void}) => {
	return (
		<li>
			<div className="coupon-info-wrap">
				<b className="coupon-title">{coupon.content}</b>
				<div>
					<span className="coupon-target">{coupon.title}</span>
					<div className={`coupon-exp-date ${coupon.remainingHours > 0 ? 'active' : ''}`}>
						{coupon.expirationTspStr}
						{coupon.remainingHours > 0 &&
						<>
						<span className="bar" />
						<b className="remaining-date">
							{coupon.remainingHours >= 24 
							? `${Math.floor(coupon.remainingHours / 24)} 일 남음`
							: `${coupon.remainingHours} 시간 남음`
							}
						</b>
						</>
						}
					</div>
				</div>
			</div>
			{(coupon.isUse === 1 || coupon.isUse === 2) &&
			<div className="coupon-status-wrap completed">
				<i className={`icon icon-coupon-completed`}></i>
				<span>{STATUS_TITLE[coupon.isUse]}</span>
			</div>
			}
			{(coupon.isUse === 0 && coupon.type === 30) &&
			<div
				className="coupon-status-wrap active"
				onClick={() => showCouponModal()}
			>
				<i className="icon icon-coupon-active"></i>
				<span>사용하기</span>
			</div>
			}
			{(coupon.isUse === 0 && (coupon.type === 10 || coupon.type === 11)) &&
			<div
				className="coupon-status-wrap active"
				onClick={() => showCouponModal()}
			>
				<i className="icon icon-coupon-active"></i>
				<span>사용처 확인</span>
			</div>
			}
			{(coupon.isUse === 0 && (coupon.type === 20 || coupon.type === 21)) &&
			<div
				className="coupon-status-wrap active"
				onClick={() => showCouponModal()}
			>
				<i className="icon icon-coupon-active"></i>
				<span>사용처 확인</span>
			</div>
			}
		</li>
	)
}
interface ICoupon {
	id: number;
	amount: number;
	code: string;
	content: string;
	couponId: number;
	expirationDuration: number|null;
	expirationTsp: string|null;
	expirationTspStr: string;
	expirationType: number;
	isLoungeCoupon: number;
	isUse: number;
	multipleUse: number;
	qrPath: string|null;
	regTsp: string;
	remainingHours: number|null;
	title: string;
	type: number;
	useTsp: string|null;
	userCouponId: number;
}
const CouponList = () => {
	const listWrap = useRef<HTMLUListElement>(null);
	const [tab, setTab] = useState<ITab[]>([
		{tabId: 0, tabTitle: '미사용'},
		{tabId: 1, tabTitle: '전체'}
	]);
	const [currentTabId, setCurrentTabId] = useState(0);
	const [list, setList] = useState<ICoupon[]>([]);
	const [unUseList, setUnUseList] = useState<any[]>([]);

	const [modalState, setModalState] = useState({
		useCouponModal: false,
		wbSdmmall: false,
		wbStore: false
	});
	const [selectedCoupon, setSelectedCoupon] = useState<ICoupon>();
	const showCouponModal = (coupon: ICoupon) => {
		if (coupon.type === 10 || coupon.type === 11) {
			setModalState({...modalState, wbStore: true});
		}
		if (coupon.type === 20 || coupon.type === 21) {
			setModalState({...modalState, wbSdmmall: true});
		}
		if (coupon.type === 30) {
			setSelectedCoupon(coupon);
			setModalState({...modalState, useCouponModal: true});
		}
	}
	const _useCoupon = () => {
		if (selectedCoupon !== undefined) {
			axios.put(BASE_URL + `/v3/mypage/coupon/${selectedCoupon.userCouponId}`).then(_ => {
				getCouponList();
			});
		}
	}
	const getCouponList = () => {
		axios.get<{item: {items: any[]; total: number;}}>(BASE_URL + '/v3/mypage/coupon').then(res => {
			const items = res.data.item.items;
			setList(items);
			setUnUseList(items.filter(item => item.isUse === 0));
		});
	}
	useEffect(() => {
		getCouponList();
	}, []);
	// set list height
	const {height} = useWindowSize();
	useEffect(() => {
		if (listWrap.current && height !== undefined) {
			listWrap.current.style.minHeight = (height - listWrap.current.getBoundingClientRect().top) + 'px';
		}
	}, [height]);

	return (
		<div className="">
			<AppHeader 
				type="stack"
				title="쿠폰함"
			/>
			<div className="page-coupon-list">
				<PageTab 
					isFull={true}
					tab={tab}
					currentTabId={currentTabId}
					onChange={tabId => setCurrentTabId(tabId)}
				/>
				<ul className="coupon-list-wrap" ref={listWrap}>
					{currentTabId === 0 && unUseList.map(coupon => (
					<CouponComponent
						coupon={coupon} 
						showCouponModal={() => {
							showCouponModal(coupon);
						}}
						key={`coupon-${coupon.userCouponId}`}
					/>
					))}
					{currentTabId === 1 && list.map(coupon => (
					<CouponComponent 
						coupon={coupon} 
						showCouponModal={() => {
							showCouponModal(coupon);
						}} 
						key={`coupon-${coupon.userCouponId}`} 
					/>
					))}
				</ul>
				{((currentTabId === 0 && unUseList.length === 0) || (currentTabId === 1 && list.length === 0)) &&
				<p className="empty-notice">
					보유한 쿠폰이 없습니다.
				</p>
				}
			</div>
			<Modal
				isShow={modalState.wbSdmmall}
				text="해당 쿠폰은 웨딩북 스드메몰에서 사용 가능합니다."
				onClose={() => {
					setModalState({...modalState, wbSdmmall: false})
				}}
			/>
			<Modal
				isShow={modalState.wbStore}
				text="해당 쿠폰은 웨딩북 스토어에서 사용 가능합니다."
				onClose={() => {
					setModalState({...modalState, wbStore: false})
				}}
			/>
			<Modal 
				isShow={modalState.useCouponModal && selectedCoupon !== undefined}
				// title="쿠폰"
				text={`${selectedCoupon ? selectedCoupon.content : '쿠폰'}을 사용하시겠습니까?`}
				buttons={[
					{
						name: '취소', 
						action: () => setModalState({...modalState, useCouponModal: false})
					},
					{
						name: '사용하기', 
						className: 'btn-red', 
						action: () => {
							_useCoupon();
							setModalState({...modalState, useCouponModal: false});
						}
					},
				]}

			/>
		</div>
	);
};

export default CouponList;