import { useState, useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTab from './../../components/home/pageTab/PageTab';
import axios from '@src/api/axios';
import { BASE_URL, addComma } from '@src/utils';

let fetch_start = 0;
const FETCH_SIZE = 50;
interface IBenefitSummary {
	
}
const MyBenefits = () => {
	const tab = useState([
		{tabId: 0, tabTitle: '캐시백'},
		{tabId: 1, tabTitle: '스탬프'},
	])[0];
	const [currentTabId, setCurrentTabId] = useState<number|null>(0);
	const [myBenefitSummary, setMyBenefitSummary] = useState<IBenefitSummary>({});
	const [list, setList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isEndList, setIsEndList] = useState(false); // 더보기 마지막 까지 도달 시
	const getBenefitList = async () => {
		if (isLoading) return;
		// setIsLoading(true);
		// axios.get<{item: any[]}>(BASE_URL + '/v3/mypage/point', {params: {
		// 	type: currentTabId,
		// 	fetchStart: fetch_start,
		// 	fetchSize: FETCH_SIZE
		// }}).then(res => {
		// 	if (res.data.item.length === 0) {
		// 		alert('더 이상 내역이 존재하지 않습니다.');
		// 		setIsEndList(true);
		// 	} else {
		// 		setList([...list, ...res.data.item]);
		// 		fetch_start += FETCH_SIZE;
		// 	}
		// 	setIsLoading(false);
		// });
	}
	useEffect(() => {
		// axios.get<{item: IPointSummary}>(BASE_URL + '/v3/mypage/point/summary').then(res => {
		// 	setMyPointSummary(res.data.item);
		// });
	}, []);
	useEffect(() => {
		// 탭 변환 시 마다
		fetch_start = 0;
		setList([]);
		setIsEndList(false);
	}, [currentTabId]);
	useEffect(() => {
		if (list.length === 0) {
			getBenefitList();
		}
	}, [list]);
	return (
		<div className="my-benefit-wrap">
			<AppHeader type="stack" title="내 포인트" actionItems={[
				{name: 'close'}
			]} />
			<div className="my-summary-wrap">
				<span>닉네임 님이 받은 혜택</span>
				<b>{addComma(300000)}P</b>
				<p className="summary-notice">
					내 혜택 금액은 실제 캐시백 또는 스탬프 상품 지급 기준에 따라 변경될 수 있습니다.
				</p>
				<button className="btn btn-full btn-link-get-benefit">포인트 적립하는 방법 보러가기 🤗</button>
			</div>
			<PageTab 
				isFull={true}
				tab={tab}
				currentTabId={currentTabId}
				onChange={(id) => setCurrentTabId(id)}
			/>
			<ul className="my-benefit-list-wrap">
				{list.map(item => (
				<li>
					<b>{addComma(item.point)}P</b>
					<div>
						<span className="benefit-content">{item.content}</span>
						<span className="benefit-date">{item.regTsp.substr(0, 10)}</span>
					</div>
				</li>
				))}
			</ul>
			{!isEndList &&
			<button className="btn btn-transparent btn-show-more" onClick={() => getBenefitList()}>더보기</button>
			}
		</div>
	);
};

export default MyBenefits;

