import { useEffect, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
import { SignUpData } from '@src/@slice/SignUpSlice';
// router
import { useNavigate } from 'react-router-dom';
// hooks
import useInputEvent from '@src/utils/hooks/useSignInputEvent';
import { useCustomInput, useHasContentInput, useSignPhone, useSignValidation } from '@src/utils/hooks/useSignInput';
import { useSignUpAllField } from '@src/utils/hooks/useSignUpField';
import { useNickNameValidation } from '@src/utils/hooks/useSignValidation';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';
import DataSection from '@src/components/form/dataSection/DataSection';
import WrapTitle from '@src/components/common/wrap/WrapTitle';
import Blank from '@src/components/common/blank/Blank';
// analytics
import { logEvent } from '@src/firebase';

// 추가 정보 입력 페이지 1
const Contents = () => {

    // history
    const navigate = useNavigate();

    // redux
    const ForSignUpData = useSelector(SignUpData);

    // input Event
    const { signValueSetter } = useInputEvent();

    // 닉네임 중복 검사
    const { chkIsNickNameUsed } = useNickNameValidation();
    const chkNickname = async (val: string) => {
        const value = val;
        if (value) {
            if (value.length > 0) {
                const result = await chkIsNickNameUsed(value);
                signNickName.setResultObject(result);
            }
        } 
    }

    // Blur 이벤트 핸들러 - 닉네임 중복 검사
    const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
        signNickName.customDataErrorCheck(e.target.value);
    }

    // 다음 단계 이동 트리거
    const [nextTrigger, setNextTrigger] = useState(false);

    // 닉네임
    const signNickName = useCustomInput({
        val: ForSignUpData.personalInfo.nick ? ForSignUpData.personalInfo.nick : '',
        dataErrorCheck: chkNickname,
        dataCheckMoment: 'custom'
    });
    
    // 배우자 이름
    const signMateName = useHasContentInput();

    // 배우자 전화번호
    const signMateTel = useSignPhone();

    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([signNickName.formatValid, signMateName.formatValid, signMateTel.formatValid]);
    const dataValid = useSignValidation([signNickName.dataValid, signMateName.dataValid, signMateTel.dataValid]);

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        setNextTrigger(true); // 다음 단계 트리거
    }

    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid) {
                goNext(); // 다음 단계로 이동
            } 
            setNextTrigger(false); // 트리거 초기화
        }
    }, [nextTrigger, formatValid, dataValid]);

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        logEvent('Signup_NickPartner_v2', {});
        navigate('/sign-additional/2');
    }

    // 마운트 시 리덕스 값 적용 / 언마운트 시 리덕스에 값 저장
    useSignUpAllField({
        signFields: {
            'personalInfo': {
                nick: signNickName,
            },
            'weddingInfo': {
                mateName: signMateName,
                mateTel: signMateTel
            },
        },
    });

	return (
		<>
            <div className="contents-wrap">
                <WrapTitle>
                    <h2>정보를<br />입력해주세요.</h2>
                </WrapTitle>
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">닉네임</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="text"
                                    placeholder="닉네임"
                                    value={signNickName.value}
                                    onChange={signValueSetter(signNickName.setValue)}
                                    onBlur={handleBlurEvent}
                                    maxLength={10}
                                    iconComponent={<>
                                        <i className="limit-length-text">{`${signNickName.value.length === 0 ? '0' : ('0' + Math.min(signNickName.value.length, 10)).slice(-2)} / 10`}</i>
                                    </>}
                                    resultObject={signNickName.resultObject}
                                    showInfoMessage={{ success: true, error: true }}
                                    showResultBorder={{ success: true, error: true }}
                                />
                            </div>
                        </div>
                    </div>
                </DataSection>
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">배우자 이름</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="text"
                                    placeholder="김웨딩"
                                    value={signMateName.value}
                                    onChange={signValueSetter(signMateName.setValue)}
                                />
                            </div>
                        </div>
                    </div>
                </DataSection>
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">배우자 전화번호</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="text"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    placeholder="휴대전화 번호"
                                    value={signMateTel.value}
                                    onChange={signMateTel.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </DataSection>
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button
                        className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                        disabled={! formatValid}
                        onClick={handleNext}>
                        다음
                    </button>
                </div>
            </div>
        </>
	);
};

const SignUpAdditional1 = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            accessType='member'
            fullHeight={true}
            headerComponent={<Blank height={56} />}
            contentsComponent={<Contents />}
        />
    );
}

export default SignUpAdditional1;