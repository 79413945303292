import { useState, useEffect, useContext, useRef } from 'react';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import RedBoxList from './../../../components/booking/redBoxList/RedBoxList';
import { DAYS } from './../../../utils/date';
import BookingTitle from './../../../components/booking/bookingTitle/BookingTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import NotiBox from './../../../components/common/notiBox/notiBox';
import { Link, useNavigate } from 'react-router-dom';
import { bookingHallData, setDates, setAdditionalInquiry } from '@src/@slice/bookingHallSlice';
import { useDispatch, useSelector } from 'react-redux';
import AgreementModal from './../../../components/booking/agreementModal/AgreementModal';
import { UserContext } from '@src/App';
import { postReservation } from '@src/api/booking/booking';
import { bookingHall, bookingHallEndPage } from './../../../@slice/bookingHallSlice';
import { useSwipeable } from 'react-swipeable';
import TextArea from './../../../components/common/textArea/TextArea';
const StepVisitDate = () => {
	const now = dayjs();
	const [selectedDate, setSelectedDate] = useState<Date[]>([]);
	const isComplete = useSelector(bookingHall).isComplete;
	const origin = useSelector(bookingHall).origin;
	const endPage = useSelector(bookingHallEndPage);
	const hallData = useSelector(bookingHallData);
	const dispatch = useDispatch();
	const [validate, setValidate] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isShowModal, setIsShowModal] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const user = useContext(UserContext);
	const calendar = useRef<HTMLInputElement|null>(null);
	const navigate = useNavigate();
	const handlers = useSwipeable({
		onSwipedLeft(_) {
			const btn = calendar.current?.getElementsByClassName('react-calendar__navigation__next-button')[0];
			if (btn) {
				(btn as HTMLButtonElement).click();
			}
		},
		onSwipedRight(_) {
			const btn = calendar.current?.getElementsByClassName('react-calendar__navigation__prev-button')[0];
			if (btn) {
				(btn as HTMLButtonElement).click();
			}
		},
	  });
	useEffect(() => {
		if (isComplete) {
			window.location.href = 'h2mwbell://webview/close';
		}
		const arr = hallData.hopeVisit.dates.map(date => dayjs(date.date).toDate())
		setSelectedDate(arr);
	}, []);
	useEffect(() => {
		const arr = selectedDate.map((date, idx) => {
			return {
				date: dayjs(date).format('YYYY-MM-DD'),
				order: idx + 1
			}
		});
		dispatch(setDates({arr: arr}));
		setValidate(selectedDate.length >= 3)
	}, [selectedDate]);
	const applyService = () => {
		if (loading) return;
		const customerUuid = user.me ? user.me.uuid : null;
		if (agreement) {
			setLoading(true);
			postReservation({
				customerUuid: customerUuid,
				origin: origin,
				...hallData
			}).then(res => {
				setLoading(false);
				navigate('/booking/hall/complete');
			}).catch(_ => {
				setLoading(false);
			});
		}
	}
	const goNext = () => {
		if (endPage === 'visit-date') {
			setIsShowModal(true)
		} else {
			navigate('/booking/hall/customer-info');
		}
	}
	return (
		<>
		{/* <AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} /> */}
		<BookingTitle 
			title={`방문 희망일을 
			알려주세요.`}
		/>
		<DataSection title="웨딩홀 방문 가능 날짜를 선택해 주세요." typeStr="최소 3개 선택">
			<div {...handlers}>
			<Calendar 
				minDate={now.toDate()}
				maxDate={dayjs().add(1, 'month').toDate()}
				showNeighboringMonth={false}
				calendarType="US"
				onClickDay={(date, event) => {
					const dateFormatted = dayjs(date).format('YYYY-MM-DD');
					if (selectedDate.filter(sDate => dayjs(sDate).format('YYYY-MM-DD') === dateFormatted).length) {
						setSelectedDate(selectedDate.filter(sDate => dayjs(sDate).format('YYYY-MM-DD') !== dateFormatted))
					} else {
						setSelectedDate([...selectedDate, date]);
					}
				}}
				tileClassName={({ activeStartDate, date, view }) => {
					return selectedDate.filter(sDate => dayjs(sDate).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')).length ? 'active' : null;
				}}
				minDetail="month"
				tileContent={({ activeStartDate, date, view }) => view === 'month' ? <span>{date.getDate()}</span> : null}
				formatDay={(_, date) => ''}
				inputRef={calendar}
			/>
			</div>
		</DataSection>
		<RedBoxList 
			items={selectedDate.map((date, idx) => ({
				origin: date.toString(),
				data: dayjs(date).locale('kr').format(`${idx+1}순위: MM월 DD일 (${DAYS[date.getDay()]})`)
			}))}
			removeItem={date => {
				const dateFormatted = dayjs(date).format('YYYY-MM-DD');
				if (selectedDate.filter(sDate => dayjs(sDate).format('YYYY-MM-DD') === dateFormatted).length) {
					setSelectedDate(selectedDate.filter(sDate => dayjs(sDate).format('YYYY-MM-DD') !== dateFormatted))
				}
			}}
		/>
		<DataSection title="추가 문의 내용을 적어주세요." typeStr="선택 입력 사항">
			<>
			<TextArea
				className="textarea" 
				style={{height: 90}} 
				placeholder={`예) 0월 0일은 오전 방문만 가능해요. 
				0월 0일은 2시 이후만 가능해요.`}
				defaultValue={hallData.hopeVisit.additionalInquiry}
				value={hallData.hopeVisit.additionalInquiry}
				onChange={e => {
					dispatch(setAdditionalInquiry({key: 'hopeVisit', data: e.target.value}));
				}}
			/>
			</>
		</DataSection>
		<NotiBox>
			<p>방문일로부터 1~2주 전 예약을 받는 웨딩홀의 경우 선택하신 방문 희망일의 예약을 받기 시작하는 날 웨딩북에서 예약을 진행할 예정입니다.</p>
			<p>2주 이내 상담 일정이 마감된 웨딩홀은 방문 희망일로 예약이 어려울 수 있습니다.</p>
			<p>방문 희망일에 웨딩홀의 상담 예약이 마감된 경우 다른 날짜로 임시 예약하여 안내 드릴 예정입니다.</p>
		</NotiBox>
		<div className="btn-wrap">
			{/* <Link to="/booking/hall/customer-info" className="btn bold btn-large btn-full btn-red">다음</Link> */}
			<button 
				disabled={!validate} 
				className={`btn bold btn-large btn-full btn-red ${!validate ? 'btn-disabled' : ''}`} 
				onClick={goNext}
			>
				다음
			</button>
		</div>
		
		<AgreementModal
			agreement={agreement}
			setAgreement={setAgreement}
			isShowModal={isShowModal}
			setIsShowModal={setIsShowModal}
			onApply={applyService}
		/>
		</>
	);
};

export default StepVisitDate;