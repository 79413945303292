import { useState, useRef, useEffect } from 'react';
// router
import { useNavigate } from 'react-router-dom';
// hooks
import { useSignValidation, useAuthInput } from '@src/utils/hooks/useSignInput';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import DataSection from '@src/components/form/dataSection/DataSection';
import AuthCodeInput from '@src/components/common/authCodeInput/AuthCodeInput';
import WrapTitle from '@src/components/common/wrap/WrapTitle';

interface FindIDProps {
    brandName: null | string;
    loginId: string;
    name: string;
    type: string;
}

// 아이디 찾기 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();

    // 다음 단계 이동 트리거
    const [nextTrigger, setNextTrigger] = useState(false);
    
    // 인증번호 입력값
    const signPhoneNumber = useAuthInput();
    
    // 인증번호 입력값 참조
    const authCodeInputRef = useRef(null);
    
    // 찾기 결과값
    const [findIDResult, setFindIDResult] = useState<FindIDProps>({brandName: null, loginId: '', name: '', type: ''});

    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([signPhoneNumber.methodValue.isFilled]);
    const dataValid = useSignValidation([signPhoneNumber.methodValue.verified]);

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        await authCodeInputRef.current.verifyCode().then(res => {
            if (res.data.result === 1) {
                setFindIDResult(res.data.item);
            }
        });
    }
    
    // 인증상태 변경 -> 다음 단계 트리거
    useEffect(() => {
        if (signPhoneNumber.saveValue.verified) {
            setNextTrigger(true);
        } 
    }, [signPhoneNumber.saveValue]);

    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid && findIDResult.loginId !== '') {
                goNext(); // 다음 단계로 이동
            } 
        }
    }, [nextTrigger, formatValid, dataValid, findIDResult]);

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        navigate('/find-id/complete', {
            state: {
            loginId: findIDResult.loginId,
            name: findIDResult.name,
            type: findIDResult.type,
            brandName: findIDResult.brandName
            }
        });
    }

	return (
		<>
            <div className="contents-wrap">
                <WrapTitle>
                    <h2>아이디 찾기</h2>
                </WrapTitle>
                <DataSection title="">
					<div className="form-wrap">
						<div className="form-block">
							<div className="form-title">
								<span className="title">전화번호</span>
							</div>
							<div className="form-content">
								<AuthCodeInput
									value={""}
                                    ref={authCodeInputRef}
                                    type={{type: 'findLoginId'}}
									onVerify={signPhoneNumber.handleVerify}
                                    onDataFilled={signPhoneNumber.handleIsAllFilled}
                                    onMethodFilled={signPhoneNumber.handleIsMethodFilled}
									done={false}
								/>
							</div>
						</div>
						</div>
				</DataSection>
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button
                        className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                        disabled={! formatValid}
                        onClick={handleNext}>
                        다음
                    </button>
                </div>
            </div>
		</>
	);
};

const FindID = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="" />}
            contentsComponent={<Contents />}
        />
    );
}

export default FindID;