import {
	PayloadAction,
	createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";

interface GlobalState {
	callCount: number,
	needLogin: boolean
}

const initialState = { callCount: 0, needLogin: false } as GlobalState

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		increment(state) {
			state.callCount++
		},
		setNeedLogin(state, action: PayloadAction<{bool: boolean}>) {
			state.needLogin = action.payload.bool;
		}
	},
})

export const { increment, setNeedLogin } = globalSlice.actions;
export const callCount = (state: RootState) => state.global.callCount;
export const needLogin = (state: RootState) => state.global.needLogin;
export default globalSlice.reducer;

