import './listItemTags.scss';

type Params = {
	tags: {
		name: string;
		link?: string;
	}[];
}
const ListItemTags = ({tags = []}: Params) => {
	return (
		<div style={{padding: '0 8px', marginTop: 8}}>
			<ul className="list-item-tags-wrap">
				{tags.map(tag => (
					<li>{tag.name}</li>
				))}
			</ul>
		</div>
	);
};

export default ListItemTags;