import { useRef, useEffect } from 'react';
function useTextareaAutoHeight(value: string, maxHeight: number = 100) {
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${(textareaRef.current.scrollHeight > maxHeight ? maxHeight : textareaRef.current.scrollHeight) + 2}px`;
		}
	}, [value]);

	return textareaRef;
}

export default useTextareaAutoHeight;