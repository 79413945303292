import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { UserContext } from '@src/App';
import { Link, useNavigate } from 'react-router-dom';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import EmptyArea from '@src/components/common/emptyArea/EmptyArea';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import { HOSTNAME, isExternalUrl } from '@src/utils';
import dayjs from 'dayjs';
import { getMyReservationList, cancelReservation } from '@src/api/realtimeBooking/realtimeBooking';
import {setDate, setTime} from '@src/@slice/realtimeBookingSlice'
import Modal from '@src/components/common/modal/Modal';


const BookingManagementRealTimeList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useContext(UserContext);

    const [activeTab, setActiveTab] = useState('방문예정');
    const [dateKeys, setDateKeys] = useState([]);
    const [items, setItems] = useState([]) // api로부터 받아온 예약 리스트
    const [list, setList] = useState({}); // 날짜 순으로 재배열한 예약 객체
    const [targetOrderId, setTargetOrderId] = useState(null)
    const [showModal, setShowModal] = useState(false)
    

    useEffect(() => {
        const filteredList = items.filter(item => item.visitStateAlias === activeTab);
        const result = {};

        filteredList.forEach(item => {
            const visitDate = item.visitDate;
            if (!result[visitDate]) {
                result[visitDate] = [];
            }
            result[visitDate].push(item);
        });

        setList(result);
        setDateKeys(Object.keys(result).sort());
    }, [items, activeTab]);

    useEffect(() => {
        if(user.me){
            const params = {
                apiKey: 'UTQJ9IKlPgPzMex7zrNShw%2BWklZNIILWt1UB51w%2Bnx0%3D',
                uuid: user.me?.uuid
            };
            getMyReservationList(params)
            .then(res => setItems(res.data.item))
        }
    }, [user.me]);

    //5일 이내인지 확인 
    const isWithin5Day = (visitDate) => {
        const today = dayjs();
        const visit = dayjs(visitDate);
        return today.diff(visit, 'day') <= -4;
    }

    const clickChangeReservationBtn  = (e, partnerId, date, time, id) => {
        e.preventDefault();
        dispatch(setDate({date:date}))
        dispatch(setTime({time:time}))
        navigate(`/booking/real-time/${partnerId}/edit/visit-date?appHeader=hide&hidetop=true`, {state: {edit:'visit-date', type: 'COMPLETE_RESERVATION', id: id}})
    }

    const clickCancelReservationBtn  = (e, id) => {
        e.preventDefault();
        setShowModal(true)
        setTargetOrderId(id)
    }

    const confirmCancelReservation = (id) =>{
        cancelReservation(id, user.me.uuid)
        .then(() => {
            setShowModal(false)
            // navigate(0)
            setActiveTab('취소')
            window.scrollTo(0, 0);

        })
        .catch(err => {
            alert(`${err.response.data?.code} : ${err.response.data?.msg}`)
            setShowModal(false)
        })
    }

    return (
        <>
            <AppHeader type="stack" actionItems={[{ name: 'close' }]} />
            <div className="page-booking-real-time-list">
                <PageTitle title="실시간예약 신청내역" />
                <div className="tab-menu">
                    {['방문예정', '방문완료', '취소'].map(tab => (
                        <div className="tab-item" key={tab}>
                            <span 
                                className={`tab-text ${activeTab === tab ? 'active' : ''}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </span>
                        </div>
                    ))}
                </div>
                {list && (
                <>
                {dateKeys.length === 0 && activeTab === '방문예정' ? 
                <EmptyArea
                    title="예약하신 웨딩홀이 없습니다."
                    subTitle="마음에 드는 웨딩홀 예약 신청을 해보세요"
                    button={{
                        name: '웨딩홀 검색하기',
                            size: 'small',
                            theme: 'brown',
                            action: () => {
                                window.location.href = isExternalUrl(`${HOSTNAME}/category/2`);
                            }
                        }}
                />
                : 
                <div className="management-all-list-wrap">
                    {dateKeys.map(date =>
                        <div key={`booking-list-${date}`}>
                            <span className="booking-date">{dayjs(date).format('YYYY.MM.DD(ddd)')}</span>
                            <ul className="booking-day-list-wrap">
                                {list[date].map(card => 
                                    <li key={card.id}>
                                        {card.visitState === 'NOT_VISITED' && 
                                        <>
                                            {card.changeableVisit === false && <span className="tag">날짜변경불가</span>}
                                            {card.refundable === false && <span className="tag">환불불가</span>}
                                        </>
                                        }
                                        {card.visitState === 'COMPLETED' &&
                                        <>
                                            <span className="tag brown">방문완료</span>
                                            <span className="tag mint">환불완료</span>
                                        </>
                                        }
                                        {card.visitState === 'CANCELED' &&
                                        <>
                                            {card.paymentState === 'NON_REFUNDABLE_BY_NO_SHOW' ? 
                                            <span className="tag red">노쇼</span>:
                                            <span className="tag red">방문취소</span>
                                            }
                                            <span className="tag">환불불가</span>
                                        </>
                                        }
                                        <Link to={isExternalUrl(`${HOSTNAME}/booking/real-time/management/detail/${card.id}?hidetop=true`)}>
                                            <div className="hall-thumb" style={{ backgroundImage: `url(https://d31gm4xoou1wz2.cloudfront.net/review/d201712/d20171201/EBA215D8-D5BB-414C-AA71-6BE6EF2918F2-14116-00000C1C23572926.jpeg)` }} />
                                            <div>
                                                <span className="hall-name">{card.hallName}</span>
                                                <span className="hall-visit-date">방문일 {card.visitDate.replaceAll('-', '.')}({dayjs(card.visitDate).format('ddd')}) {card.visitTime.slice(0, -3)}</span>
                                                {card.visitState === 'NOT_VISITED' && isWithin5Day(card.visitDate) &&
                                                <div className="booking-action-btn-wrap">
                                                    {card.changeableVisit && 
                                                    <button className="btn" onClick={(e)=>clickChangeReservationBtn(e, card.partnerId, card.visitDate, card.visitTime, card.id)}>날짜변경</button>
                                                    }
                                                    <button className="btn" onClick={(e) => clickCancelReservationBtn(e, card.id)}>방문취소</button>
                                                </div>
                                                }
                                            </div>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                }
            </>
            )}
            </div>
            {showModal && 
            <Modal 
                isShow={showModal} 
                overlay={true} 
                overlayValue={0.5} 
                text={`예약된 일정을 취소하시겠습니까?\n※ 예약 취소시 복구가 불가합니다.`}
                buttons={[{name: '뒤로',action: () => setShowModal(false)},{name: '방문 취소',className:'btn-red', action: () => {
                    confirmCancelReservation(targetOrderId)
                    }}]} />}
        </>
    );
};

export default BookingManagementRealTimeList;
