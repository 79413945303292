import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const Test = () => {
	const [input, setInput] = useState('');
	const onSave = () => {
		localStorage.setItem('test', input);
		Cookies.set('test', input);
		alert(input + ' 저장 했음!');
	}
	const showData = () => {
		const ck = Cookies.get('test');
		const ls = localStorage.getItem('test');

		alert('쿠키 값 : ' + ck);
		alert('로컬스토리지 값 : ' +  ls);
	}
	useEffect(() => {
		showData();
	}, []);
	return (
		<div>
			<input type="text" value={input} onChange={(e) => setInput(e.target.value)} />
			<button onClick={onSave}>저장</button><br/>
			<br/>
			<button onClick={showData}>값 체크</button>
		</div>
	);
};

export default Test;