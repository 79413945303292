import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useDispatch } from 'react-redux';
import icSchedule from '@assets/images/recommend/ic-schedule.png';
import icCashback from '@assets/images/recommend/ic-cashback.png';
import icDiscount from '@assets/images/recommend/ic-discount.png';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';
import Blank from '../../../components/common/blank/Blank';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import { setInPath, setOrigin } from '@src/@slice/recommendHallSlice';
const Start = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const origin = query.get('origin');
	const inpath = query.get('inpath');
	const dispatch = useDispatch();
	const goNext = () => {
		navigate('/recommend/hall/style');
	}
	useEffect(() => {
		if (inpath) {
			dispatch(setInPath({inpath: inpath.toUpperCase()}));
		}
		if (origin) {
			dispatch(setOrigin({origin: origin.substring(0, 10)}));
		}
	}, []);
	return (
		<>
		<AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} />
		<div className="layout-fix-button with-app-header">
			<div>
				<div className="common-wrap">
				<span className="ttnorms-bold brown500" style={{fontSize: 15, lineHeight: '36px'}}>ONLY in weddingbook</span>
				</div>
				<PageTitle title={`맞춤형 웨딩홀
추천 리스트 신청하기`}/>
				<div className="our-service-wrap">
					<p>웨딩북을 통해 웨딩홀을 준비하시면,</p>
					<ul>
						<li>
							<img src={icDiscount} alt="할인" />
							예식 금액 최대 할인
						</li>
						<li>
							<img src={icCashback} alt="캐시백" />
							현금 10만원 캐시백
						</li>
						<li>
							<img src={icSchedule} alt="일정" />
							원하는 웨딩홀 방문 일정 예약
						</li>
					</ul>
					<p>이 모든 서비스를 무료로 받으실 수 있어요!</p>
				</div>
			</div>
			<div>
				<LinkWegazine 
					title="웨딩홀 저렴하게 진행하는 방법은?"
					link="https://www.weddingbook.com/wegazine/f7a63d5f-6e07-11ec-b0a5-0aea5320e7f2?wbTitle=%20"
				/>
				<Blank height={20} />
				<div className="btn-wrap">
					<button onClick={goNext} className={`btn bold btn-large btn-red btn-full`}>나에게 딱 맞는 웨딩홀 찾기</button>
				</div>
			</div>
		</div>
		</>
	);
};

export default Start;