const DetailInfo = ({data, idx}: any) => {
	const content = data.content;
	const contents = content.split('\n');
	return (
		<div className="product-notice-wrap" style={{marginBottom: data.marginBottom}}>
			<h3>{data.title}</h3>
			{(contents !== null && contents.length > 0) &&
			<ul>
				{contents.map((ct: any, cIdx: number) => (
				<li key={`${idx}-${data.type}-${cIdx}`}>{ct}</li>
				))}
			</ul>
			}
		</div>
	);
};

export default DetailInfo;