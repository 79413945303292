import './weddingStamp.scss';
import { useEffect, useState } from 'react';
import { isExternalUrl, BASE_URL } from '@src/utils';
import { HOSTNAME } from './../../../utils/index';
import axios from './../../../api/axios';
import { useSelector } from 'react-redux';
import { callCount as callCountData } from '@src/@slice/globalSlice';
import { logEvent } from '@src/firebase';
import Modal from '@src/components/common/modal/Modal';
import imgEletronicTip from '@src/assets/images/weddingStamp/img-stamp-eletronics-tip@3x.png';
import imgStampTitle from '@src/assets/images/weddingStamp/img-stamp-title@3x.png';

interface IStamp {
	customerId: number;
	partnerId: number;
	partnerName: string;
	partnerType: number;
	partnerTypeName: string;
	regTsp: string;
	stampId: number;
}
interface IGift {
	customerId: number;
	giftId: number;
	giftName: string;
	giftNote: string;
	id: number;
	regTsp: string;
	stampCount: number;
	state: number;
}
interface Props {
	id: number;
	gift: IGift[];
	stamp: IStamp[]
};
const stampPartnerTypes = [
	{name: '신혼여행', key: 'honeymoon', partnerType: 19},
	{name: '예복', key: 'suit', partnerType: 15},
	{name: '예물', key: 'weddingring', partnerType: 8},
	{name: '한복', key: 'hanbok', partnerType: 6},
	{name: '가구', key: 'furniture', partnerType: 20},
	// {name: 'plus', key: 'plus', partnerType: 0},
	{name: '가전', key: 'eletronic', partnerType: 23}
]
function WeddingStamp({id, gift, stamp}: Props) {
	const callCount = useSelector(callCountData);
	const [gifts, setGifts] = useState(gift);
	const [stamps, setStamps] = useState(stamp);
	const [modalState, setModalState] = useState({
		eletronics: false
	})
	const applyPresent = (count: number) => {
		return stamps.length >= count ? true : false;
	}
	const havePresent = (count: number) => {
		return gifts.filter(gift => gift.stampCount === count).length > 0 ? true : false;
	}
	useEffect(() => {
		axios.get<{data: any}>(`${BASE_URL}/web/v3/stamps/me`).then(res => {
			const data = res.data.data;
			if (data.gift) setGifts(data.gift);
			if (data.stamp) setStamps(data.stamp);
		})
	}, [callCount]);
	return (
		<section className="wedding-stamp-wrap">
			<header className="wedding-stamp-header">
				<h2>
					<img src={imgStampTitle} width="196" alt="웨딩 스탬프 모으고 선물받기!" />
				</h2>
				<p>
					아래 품목 스탬프를 눌러 미션을 달성하세요.<br/>
					스탬프 3개, 5개 선물을 받을 수 있어요!
				</p>
			</header>
			<div className="stamp-notice-wrap">
				<p>
				스탬프 이벤트는 <span className="bold">🔹2023년 12월 31일까지🔹</span> 진행될 예정이므로, <br/>
				이벤트 종료일 전까지 신청을 완료해주세요. (이후 별도 신청 불가)
				</p>
			</div>
			<div className="user-stamp-wrap">
				<div className="user-stamp-top">
					<b className="user-stamp-count">
						내 스탬프 <span>{stamps.length}</span>개
					</b>
					{stamps.length < 3 &&
					<p>스탬프 <span>{3 - stamps.length}개</span>를 더 모으면 선물을 받을 수 있어요!</p>
					}
					{(stamps.length >= 3 && stamps.length < 5 && havePresent(3)) &&
					<p>스탬프 <span>{5 - stamps.length}개</span>를 더 모으면 선물을 받을 수 있어요!</p>
					}
					{(stamps.length === 5 && havePresent(3) && havePresent(5)) &&
					<p>선물을 다 받았어요!</p>
					}
					{(stamps.length === 6 && havePresent(3) && havePresent(5)) &&
					<p>스탬프를 다 모았어요!</p>
					}
					{(applyPresent(3) && !havePresent(3)) &&
						<p>스탬프 3개를 모았어요! 선물을 신청해보세요 🤗 </p>
					}
					{(applyPresent(5) && !havePresent(5) && havePresent(3)) &&
						<p>스탬프를 모두 모았어요! 선물을 신청해보세요 🤗  </p>
					}
				</div>
				<ul className="user-stamp-list-wrap">
					{stampPartnerTypes.map(type => {
						const isActive = stamps.filter(stamp => stamp.partnerTypeName === type.name).length > 0;
						// if (type.key === 'plus') {
						// 	return (<li className="stamp-plus"></li>)
						// }
						return (<li onClick={() => {
							logEvent('Stamp_Button_Action_Gift', {Stamp_Count: stamps.length});
							window.location.href = isExternalUrl(`${HOSTNAME}/weddingStamp/partners?partnerType=${type.partnerType}&stampCount=${stamps.length}&appliable=${!isActive ? '1' : '0'}`);
							// if (type.name.trim() === '가전') {
							// 	if (!isActive) {
							// 		// window.location.href = isExternalUrl(`${HOSTNAME}/weddingStamp/eletronics/apply`);
							// 		logEvent('Stamp_Button_Action_Gift', {Stamp_Count: stamps.length});
							// 		window.location.href = isExternalUrl(`${HOSTNAME}/weddingStamp/eletronics/apply`);
							// 	} else {
							// 		setModalState({
							// 			...modalState,
							// 			eletronics: true
							// 		});
							// 	}
							// } else {
							// 	logEvent('Stamp_Button_Action_Gift', {Stamp_Count: stamps.length});
							// 	window.location.href = isExternalUrl(`${HOSTNAME}/weddingStamp/partners?partnerType=${type.partnerType}&stampCount=${stamps.length}&appliable=${!isActive ? '1' : '0'}`);
							// }
						}}>
							<div className={`stamp stamp-${type.key} ${isActive ? 'on' : ''}`}>
							{/* {type.name.trim() === '가전' && !isActive
							? <img src={imgEletronicTip} className="eletronics-tip" width="124" alt="5초만에 가전 스탬프 찍기!" />
							: <></>
							} */}
							</div>
							<span>{type.name}</span>
						</li>)
					})}
				</ul>
				<div
					onClick={() => {
						logEvent('Stamp_Button_Action_Gift', {Stamp_Count: stamps.length});
						window.location.href = isExternalUrl(`${HOSTNAME}/weddingStamp/partners?partnerType=0&stampCount=${stamps.length}`);
					}}
					className="user-stamp-bottom"
				>
					스탬프 적립하기
				</div>
			</div>
			<div className="btn-wrap items-2">
				<div>
				<a href="h2mwbell://wegazine/50b8cd27-bed4-11ec-ab0f-0a8156bf2d4c" className="btn btn-full btn-light-gray">이벤트 안내</a>
				</div>
				<div>
				<a href={isExternalUrl(`${HOSTNAME}/weddingStamp/apply?stampCount=3&ableApply=${applyPresent(3) ? '1' : '0'}`)} className="btn btn-full btn-light-gray">선물 확인하기</a>
				{/* <a href={havePresent(3) ? isExternalUrl(`${HOSTNAME}/weddingStamp/apply/complete`) : isExternalUrl(`${HOSTNAME}/weddingStamp/apply?stampCount=3&ableApply=${applyPresent(3) ? '1' : '0'}`)} className={`btn btn-transparent ${havePresent(3) ? 'btn-have-present' : applyPresent(3) ? 'btn-apply-present' : ''}`}>
					누적 스탬프 3개<br/>
					<span>{havePresent(3) ? '신청완료' : applyPresent(3) ? '선물받기' : '선물 확인하기'} &gt; </span>
				</a>
				<a href={havePresent(5) ? isExternalUrl(`${HOSTNAME}/weddingStamp/apply/complete`) : isExternalUrl(`${HOSTNAME}/weddingStamp/apply?stampCount=5&ableApply=${applyPresent(5) ? '1' : '0'}`)} className={`btn btn-transparent ${havePresent(5) ? 'btn-have-present' : applyPresent(5) ? 'btn-apply-present' : ''}`}>
					누적 스탬프 5개<br/>
					<span>{havePresent(5) ? '신청완료' : applyPresent(5) ? '선물받기' : '선물 확인하기'} &gt; </span>
				</a>
				</div> */}
				</div>
			</div>
			<Modal
				isShow={modalState.eletronics}
				text={'이미 가전 스탬프를 적립하였습니다.'}
				onClose={() => {
					setModalState({
						...modalState,
						eletronics: false
					});
				}}
			/>
		</section>
	);
}
export default WeddingStamp;