import { useEffect, useState,useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {realtimeBooking, setVisitor} from '@src/@slice/realtimeBookingSlice';
import IconCheck from "../../../../assets/images/icon/checkmark_p500.svg";
import { ReactComponent as IconDown } from "../../../../assets/images/icon/ic-chevron-down.svg";
import { ReactComponent as IconUp } from "../../../../assets/images/icon/ic-chevron-up.svg";
import BookingTitle from "@src/components/booking/bookingTitle/BookingTitle";
import { tr } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const RealTimeStepVisitor = forwardRef(({updateIsDisabled}: { updateIsDisabled?: (value: boolean) => void }, ref) => {
    const navigate = useNavigate();
    const data = useSelector(realtimeBooking).data;
    const hallData = useSelector(realtimeBooking).hall;
    // const minVisitor = data.expectedMinPersonnel;
    const dispatch = useDispatch();

    const [selectedVisitorRange, setSelectedVisitorRange] = useState<number>(-1);
    const [showNotice, setShowNotice] = useState(false);
  
    //하객 선택 범위
    const getVisitorRange = (min: number = 100) => {
        const visitorRange = [];
        let current = min;
    
        while (current < 300) {
            let next = current + 50;
            if (next >= 300) {
                visitorRange.push(`${current}명 ~ 300명`);
                break;
            } else {
                visitorRange.push(`${current}명 ~ ${next}명`);
            }
            current = next;
        }
        visitorRange.push('300명 이상');
        return visitorRange;
    }

    const range = getVisitorRange(hallData.minPersonnel);

    const handleButtonClick = () => {
        if(selectedVisitorRange > -1){
            dispatch(setVisitor({visitor: hallData.minPersonnel + (selectedVisitorRange*50)}))
        }            
    }
    
    useEffect(()=>{
        // updateIsDisabled(true);
        if(!!updateIsDisabled){
            if(selectedVisitorRange < 0){
                updateIsDisabled(true);
            }else {
                updateIsDisabled(false);
            }
        }
    },[selectedVisitorRange,updateIsDisabled])


    useEffect(()=>{
        if(data && hallData){
            const selectedIdx = Math.ceil((data.expectedMinPersonnel - hallData.minPersonnel)/50)
            setSelectedVisitorRange(selectedIdx);
        }
    },[hallData?.minPersonnel, data?.expectedMinPersonnel])

    useImperativeHandle(ref, () => ({
        handleButtonClick() {
            if(selectedVisitorRange > -1){
                dispatch(setVisitor({visitor: hallData.minPersonnel + (selectedVisitorRange*50)}))
            }            
        }
    }));

    // useImperativeHandle(ref, handleButtonClick);

    return (
        <div className="page-realtime-visitor">
            <BookingTitle title={`예상하객수를 선택해주세요.`} />
            <div className="visitor-select-wrap">
                {range.map((visitor, idx) => 
                <button 
                    key={`select-box-list-${visitor}`} 
                    className={`select-button ${selectedVisitorRange === idx ? 'selected' : ''} `}
                    onClick={()=>setSelectedVisitorRange(idx)}>
                    {visitor}
                </button>   
                )}
            </div>
            <div className="notice-wrap" style={{marginBottom: showNotice? '80px':''}}>
                <div>
                    <p>
                        <img src={IconCheck} alt=''/>
                        <span className="bold">원하시는 예상하객수가 없으신가요?</span>
                    </p>
                    {showNotice ? 
                    <IconUp className="icon" onClick={() => setShowNotice(!showNotice)}/>
                    : 
                    <IconDown onClick={() => setShowNotice(!showNotice)}/>
                    }
                </div>

                {showNotice && 
                <>
                    <div className="subscribe">
                        <p>예상하객수 선택지는 상담 신청이 가능한 보증인원부터 표시됩니다.</p>
                        <br />
                        <p>
                            <strong>보증인원</strong>
                            <span className="mint-font">*</span> 이란
                            결혼식 당일 식대를 지불하겠다고 약속한 최소
                            하객 수입니다. 예를 들어, 보증인원을
                            300명으로 계약하면 실제 참석 인원이 그보다
                            적더라도 300명에 대한 식대를 지불해야
                            합니다. 이 인원은 웨딩홀마다 다르며, 계약
                            단계에서 협의하여 결정됩니다.
                        </p>
                    </div>
                    <div className="visitor-count">
                        <div>
                            <p>웨딩홀</p>
                            <p>
                                추천 <strong>보증인원</strong>
                                <span className="mint-font">*</span>
                                {" "}
                            </p>
                        </div>
                        <div>
                            <p>{hallData.name}</p>
                            <p>{hallData.sugPersonnel}명</p>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    );
});

export default RealTimeStepVisitor;