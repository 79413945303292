import { BASE_URL } from "@src/utils";
import axios from "../axios";
import { TBadge } from "../type";

export interface ISearchData {
	uuid: string;
	address: string;
	bizName: string;
	name: string | null;
	coverUrl: string | null;
	benefits: TBadge[] | null;
}
export interface ISearchLog {
	id: number;
	content: string;
}

export const changeSearchKeyword = (keyword: string) => {
	return axios.get<{item: {items: ISearchData[], total: number}}>(`${BASE_URL}/web/v3/230531/partner?fetchSize=20&fetchStart=0&keyword=${keyword}`)
}
export const getSearchResult = (keyword: string) => {
	return axios.get<{item: {items: ISearchData[], total: number}}>(`${BASE_URL}/web/v3/230531/partner?fetchSize=100&fetchStart=0&keyword=${keyword}`)
}
export const getSearchLog = () => {
	return axios.get<{item: ISearchLog[]}>(`${BASE_URL}/web/v3/20230531/partner/searchLog`)
}
export const deleteSearchLog = (id: number) => {
	return axios.put(`${BASE_URL}/web/v3/20230531/partner/searchLog/hide/${id}`)
}
export const deleteAllSearchLog = () => {
	return axios.put(`${BASE_URL}/web/v3/20230531/partner/searchLog/hide/all`)
}

// 추천 검색어
export const getRecommendationKeyword = (productId?: string|null) => {
	let url = '/v3/product/keyword';
	console.log(typeof productId)
	if (typeof productId === 'string') url += `?productId=${productId}`
	return axios.get<{item: string[]}>(`${BASE_URL}${url}`);
}