export const PERIOD_ITEMS = ['12월 ~ 2월', '3월 ~ 5월', '6월 ~ 8월', '9월 ~ 11월'] as const;
export const CONCEPT_ITEMS = ['휴양', '관광'] as const;
export const BUDGET_ITEMS = ['300 ~ 500만원', '500 ~ 700만원', '700만원 이상'] as const;

export type THONEYMOON_PLACE_NAME = '하와이' | '몰디브' | '유럽' | '발리' | '푸켓' | '칸쿤' | '코사무이' | '모리셔스' | '괌 & 사이판';

export type TRECOMMEND_CASE_LIST = {
	[key in string]: {
		cause: {
			period: typeof PERIOD_ITEMS[number],
			concept: typeof CONCEPT_ITEMS[number],
			budget: typeof BUDGET_ITEMS[number],
		},
		result: THONEYMOON_PLACE_NAME[]|null
	}
}

export const RECOMMEND_CASE_LIST: TRECOMMEND_CASE_LIST = {
	'case1': {
		cause: {
			period: '12월 ~ 2월',
			concept: '휴양',
			budget: '300 ~ 500만원'
		},
		result: ['괌 & 사이판']
	},
	'case2': {
		cause: {
			period: '12월 ~ 2월',
			concept: '휴양',
			budget: '500 ~ 700만원'
		},
		result: ['푸켓', '괌 & 사이판']
	},
	'case3': {
		cause: {
			period: '12월 ~ 2월',
			concept: '휴양',
			budget: '700만원 이상'
		},
		result: ['칸쿤', '몰디브']
	},
	'case4': {
		cause: {
			period: '12월 ~ 2월',
			concept: '관광',
			budget: '300 ~ 500만원'
		},
		result: ['괌 & 사이판']
	},
	'case5': {
		cause: {
			period: '12월 ~ 2월',
			concept: '관광',
			budget: '500 ~ 700만원'
		},
		result: ['유럽', '괌 & 사이판']
	},
	'case6': {
		cause: {
			period: '12월 ~ 2월',
			concept: '관광',
			budget: '700만원 이상'
		},
		result: ['유럽']
	},
	'case7': {
		cause: {
			period: '3월 ~ 5월',
			concept: '휴양',
			budget: '300 ~ 500만원'
		},
		result: ['괌 & 사이판']
	},
	'case8': {
		cause: {
			period: '3월 ~ 5월',
			concept: '휴양',
			budget: '500 ~ 700만원'
		},
		result: ['하와이']
	},
	'case9': {
		cause: {
			period: '3월 ~ 5월',
			concept: '휴양',
			budget: '700만원 이상'
		},
		result: ['하와이', '몰디브', '칸쿤']
	},
	'case10': {
		cause: {
			period: '3월 ~ 5월',
			concept: '관광',
			budget: '300 ~ 500만원'
		},
		result: ['괌 & 사이판']
	},
	'case11': {
		cause: {
			period: '3월 ~ 5월',
			concept: '관광',
			budget: '500 ~ 700만원'
		},
		result: ['유럽']
	},
	'case12': {
		cause: {
			period: '3월 ~ 5월',
			concept: '관광',
			budget: '700만원 이상'
		},
		result: ['유럽']
	},
	'case13': {
		cause: {
			period: '6월 ~ 8월',
			concept: '휴양',
			budget: '300 ~ 500만원'
		},
		result: ['코사무이']
	},
	'case14': {
		cause: {
			period: '6월 ~ 8월',
			concept: '휴양',
			budget: '500 ~ 700만원'
		},
		result: ['발리', '코사무이']
	},
	'case15': {
		cause: {
			period: '6월 ~ 8월',
			concept: '휴양',
			budget: '700만원 이상'
		},
		result: ['모리셔스']
	},
	'case16': {
		cause: {
			period: '6월 ~ 8월',
			concept: '관광',
			budget: '300 ~ 500만원'
		},
		result: null
	},
	'case17': {
		cause: {
			period: '6월 ~ 8월',
			concept: '관광',
			budget: '500 ~ 700만원'
		},
		result: ['유럽', '발리']
	},
	'case18': {
		cause: {
			period: '6월 ~ 8월',
			concept: '관광',
			budget: '700만원 이상'
		},
		result: ['유럽']
	},
	'case19': {
		cause: {
			period: '9월 ~ 11월',
			concept: '휴양',
			budget: '300 ~ 500만원'
		},
		result: ['코사무이']
	},
	'case20': {
		cause: {
			period: '9월 ~ 11월',
			concept: '휴양',
			budget: '500 ~ 700만원'
		},
		result: ['코사무이']
	},
	'case21': {
		cause: {
			period: '9월 ~ 11월',
			concept: '휴양',
			budget: '700만원 이상'
		},
		result: ['모리셔스']
	},
	'case22': {
		cause: {
			period: '9월 ~ 11월',
			concept: '관광',
			budget: '300 ~ 500만원'
		},
		result: null
	},
	'case23': {
		cause: {
			period: '9월 ~ 11월',
			concept: '관광',
			budget: '500 ~ 700만원'
		},
		result: ['유럽']
	},
	'case24': {
		cause: {
			period: '9월 ~ 11월',
			concept: '관광',
			budget: '700만원 이상'
		},
		result: ['유럽']
	},
}
// 12~2월 / 휴양 / 300~500만원: 괌 & 사이판 
// 12~2월 / 휴양 / 500~700만원: 푸켓, 괌 & 사이판
// 12~2월 / 휴양 / 700만원 이상: 칸쿤, 몰디브
// 12~2월 / 관광 / 300~500만원: 괌 & 사이판
// 12~2월 / 관광 / 500~700만원: 유럽, 괌 & 사이판
// 12~2월 / 관광 / 700만원 이상: 유럽

// 3~5월 / 휴양 / 300~500만원: 괌 & 사이판
// 3~5월 / 휴양 / 500~700만원: 하와이
// 3~5월 / 휴양 / 700만원 이상: 하와이, 몰디브, 칸쿤
// 3~5월 / 관광 / 300~500만원: 괌 & 사이판
// 3~5월 / 관광 / 500~700만원: 유럽
// 3~5월 / 관광 / 700만원 이상: 유럽

// 6~8월 / 휴양 / 300~500만원: 코사무이
// 6~8월 / 휴양 / 500~700만원: 발리, 코사무이
// 6~8월 / 휴양 / 700만원 이상: 모리셔스
// 6~8월 / 관광 / 300~500만원: -
// 6~8월 / 관광 / 500~700만원: 유럽, 발리
// 6~8월 / 관광 / 700만원 이상: 유럽

// 9~11월 / 휴양 / 300~500만원: 코사무이
// 9~11월 / 휴양 / 500~700만원: 코사무이
// 9~11월 / 휴양 / 700만원 이상: 모리셔스
// 9~11월 / 관광 / 300~500만원: -
// 9~11월 / 관광 / 500~700만원: 유럽
// 9~11월 / 관광 / 700만원 이상: 유럽