import React, { useEffect } from 'react';
// router
import { useNavigate, useLocation } from 'react-router-dom';
// hooks
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import WrapTitle from '@src/components/common/wrap/WrapTitle';

// 비밀번호 찾기 결과 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();

    // location
    const location = useLocation();
    const { loginId, email } = location.state || {};

    // 다음 단계 이동 핸들러 
    const handleNext = () => {
        goNext();
    }

    // 다음 단계로 이동
    const goNext = () => {
        navigate('/sign-in/email', { state: { loginId: loginId } }); //이메일 로그인 페이지로 이동 (이메일 전달)
    }

	return (
		<>
            <div className="contents-wrap">
                <WrapTitle title={<>
                    <h2>
                        이메일을 <br />
                        확인해주세요! <br />
                    </h2>
                    <p>
                        입력해주신 이메일 주소로 임시 비밀번호가 발송되었습니다. 로그인 후 비밀번호를 꼭 변경해주세요. <br />
                        마이웨딩 - 톱니바퀴 - 내 계정정보 - 비밀번호 변경
                    </p>
                    <div className="gray-bg-info-box">
                        <>
                            <strong>{email}</strong>
                            <span>으로 임시 비밀번호가 발송되었습니다.</span>
                        </>
                    </div>
                </>} />
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button className={`btn bold btn-large btn-red btn-full`} onClick={handleNext}>완료</button>
                </div>
            </div>
		</>
	);
};

const FindPWResult = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="" />}
            contentsComponent={<Contents />}
        />
    );
}

export default FindPWResult;