import './grayBox.scss'

interface IGrayBox {
	title?: string;
	text: string;
	style?: React.CSSProperties
}
const GrayBox = ({title, text, style}: IGrayBox) => {
	return (
		<div className="gray-box-wrap" style={style}>
			{title &&
			<b>{title}</b>
			}
			<p>{text}</p>
		</div>
	);
};

export default GrayBox;