// user agent 관련 api
import axios from "./axios";
import { BASE_URL } from "@src/utils";
import { TBadge } from "./type";

export interface IRecentlyCompany {
	address: string | null;
	address2: string | null;
	coverUrl: string;
	isLike: number;
	name: string;
	uuid: string;
	region: string;
	benefits: TBadge[];
	tags: {
		0:{name: string},
		1:{name:string;}
	};
}
type TRecentlyShowCompanyParams = {
	partnerType?: number;
	fetchStart?: number;
	fetchSize?: number;
}
export const getRecentlyShowCompany = (params: TRecentlyShowCompanyParams = {}) => {
	const { partnerType, fetchSize = 20, fetchStart = 0 } = params;
	if (partnerType === undefined) {
		return axios.get<{item: IRecentlyCompany[], total: number}>(`${BASE_URL}/v3/component/200205/partner/my/recent?fetchStart=${fetchStart}&fetchSize=${fetchSize}`)
	} else {
		return axios.get<{item: IRecentlyCompany[], total: number}>(`${BASE_URL}/v3/component/200205/partner/my/recent/${partnerType}?fetchStart=${fetchStart}&fetchSize=${fetchSize}`)
	}
}