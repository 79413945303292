import { Fragment } from 'react';

const DetailTable = ({data, idx}: any) => {
	var tables = data.tables;
	return <>
        <h3 className="product-sub-title type2">{data.title}</h3>
        <div className="product-price-system clearfix" style={{marginBottom: data.bottomMargin}}>
            {(tables !== null && tables.length > 0) &&
            <>
            {tables.map((table: any, tIdx: number) => {
                const prices = table.prices;
                return (
                    <Fragment key={`${idx}-table-${tIdx}`}>
                    <div style={{float: 'left'}}>
                        <h4 className="big darkblue">{table.content}</h4>
                        {prices !== null && prices.length > 0 &&
                        <ul>
                            {prices.map((price: any, pIdx: number) => (
                            <li key={`${idx}-table-price-${pIdx}`}>
                                <h5>{price.title}</h5>
                                <dl>
                                    <dt>{table.priceInfo1}</dt>
                                    <dd>{price.price1}</dd>
                                    <dt className="colored">{table.priceInfo2}</dt>
                                    <dd>{price.price2}</dd>
                                </dl>
                            </li>
                            ))}
                        </ul>
                        }
                    </div>
                    </Fragment>
                );
            })}
            </>
            }
        </div>
    </>;
};

export default DetailTable;