import { createContext, useContext, useEffect, useState } from 'react';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import { UserContext } from '@src/App';
import { BASE_URL, autoHypenPhone } from '@src/utils';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
import TextArea from '@src/components/common/textArea/TextArea';
import emojiStrip from 'emoji-strip';
import axios from '@src/api/axios';
import { useNavigate } from 'react-router-dom';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import NotiText from './../../components/common/notiText/NotiText';

const initialData = {
	isComplete: false
}
export const SDMPackageApplyContext = createContext(initialData);
const SDMPackageApply = () => {
	const user = useContext(UserContext);
	const {isComplete} = useContext(SDMPackageApplyContext);
	const navigate = useNavigate();
	const [data, setData] = useState({
		role: '',
		name: '',
		tel: '',
		mateName: '',
		mateTel: '',
		wedDt: '',
		content: ''
	});
	const validate = () => {
		if (data.role === '') return false;
		if (data.name === '') return false;
		if (data.tel === '') return false;
		if (data.mateName === '') return false;
		if (data.mateTel === '') return false;
		if (data.wedDt === '') return false;
		return true;
	}
	const apply = () => {
		if (!user.me) return;
		axios.post(`${BASE_URL}/web/v1/apply/consulting`, {
			customerUuid: user.me.uuid,
			inpath: 'CONTENTS',
			consultingApplyType: 'SDM_PACKAGE_EVENT',
			customer: {
				role: data.role,
				name: data.name,
				tel: data.tel,
				mateName: data.mateName,
				mateTel: data.mateTel,
			},
			wedDt: data.wedDt,
			content: data.content
		}).then(_ => {
			navigate('/sdm-package/apply/complete');
		})
	}
	useEffect(() => {
		if (isComplete) {
			window.location.href = 'h2mwbell://webview/close';
		}
		if (user.me) {
			const uData: any = {};
			if (user.me.sex && data.role === '') {
				uData.role = user.me.sex === 1 ? 'BRIDE' : 'GROOM';
			}
			if (data.name === '') {
				uData.name = user.me.customerName;
			}
			if (user.me.tel) {
				uData.tel = user.me.tel;
			}
			setData({...data, ...uData});
		}
	}, [user.me]);
	return (
		<>
		<AppHeader type="stack" actionItems={[{name: 'close'}]} />
		<div className="page-sdm-page-apply">
			<PageTitle title="고객님의 정보를 
알려주세요." />
			<div style={{padding: '0 20px 40px'}}>
				<p className="sdm-page-apply-sub-title">신청자와 배우자의 정보를 입력해주세요.</p>
				<div className="form-wrap">
					<div className="form-block">
						<div className="form-title">
							<span className="title">본인선택</span>
							{/* <div className="necessary"><span>*</span>필수 입력 사항</div> */}
						</div>
						<div className="form-content">
							<ul className="my-role-list-wrap">
								<li className={data.role === 'BRIDE' ? 'on' : ''} onClick={() => setData({...data, role: 'BRIDE'})}>
									신부
								</li>
								<li className={data.role === 'GROOM' ? 'on' : ''} onClick={() => setData({...data, role: 'GROOM'})}>
									신랑
								</li>
							</ul>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="text"
								className="input-text input-full"
								placeholder="이름" 
								defaultValue={data.name}
								onChange={e => setData({...data, name: e.target.value})}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="tel"
								className="input-text input-full"
								placeholder="01012345678"
								defaultValue={autoHypenPhone(data.tel)}
								value={autoHypenPhone(data.tel)}
								readOnly={user.me !== null}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									setData({...data, tel: tel});
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && data.tel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="text"
								className="input-text input-full"
								placeholder="예비 배우자 이름"
								defaultValue={data.mateName}
								onChange={e => setData({...data, mateName: e.target.value})}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="tel" 
								className="input-text input-full" 
								placeholder="01012345678" 
								defaultValue={autoHypenPhone(data.mateTel)}
								value={autoHypenPhone(data.mateTel)}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									setData({...data, mateTel: tel});
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && data.mateTel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
					<br/>
					<div className="form-block">
						<div className="form-title">
							<span className="title">결혼 예정일</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<DatePicker 
								selected={data.wedDt ? moment(data.wedDt).toDate() : null} 
								customInput={<input 
									type="text"
									className="input-text input-full input-has-line"
									style={{marginBottom: 0}}
								/>}
								onChange={(date:Date) => setData({...data, wedDt: moment(date).format('YYYY-MM-DD')})} 
								placeholderText="날짜를 선택하세요"
								dateFormat="yyyy.MM.dd"
								onChangeRaw={e => e.preventDefault()}
								onFocus={e => e.target.blur()}
								disabledKeyboardNavigation
								locale={ko}
							/>
							<NotiText 
								color="gray"
								style={{paddingLeft: 0, paddingBottom: 40}}
								text={`결혼 예정일이 미정이라면 대략적인 날짜를 선택해 주세요.`}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">문의 또는 상담받고 싶은 내용을 써주세요.</span>
							<div className="necessary">선택 입력 사항</div>
						</div>
						<div className="form-content">
							<TextArea
								className="textarea-has-line" 
								style={{height: 96}} 
								placeholder={`기타 궁금한 점이나 요청사항을 기입해주세요.
								(최대 50자)`}
								defaultValue={data.content}
								value={data.content}
								onChange={e => {
									setData({...data, content: emojiStrip(e.target.value).slice(0, 50)});
								}}
								maxLength={50}
							/>
						</div>
					</div>
					<p className="apply-information-text">정확한 상담 진행을 위해 반드시 실명으로 적어주세요!</p>
				</div>
				<button 
					className={`btn btn-full bold btn-large btn-red ${!validate() ? 'btn-disabled' : ''}`}
					disabled={!validate()}
					onClick={apply}
				>신청하기</button>
			</div>
		</div>
		</>
	);
};

export default SDMPackageApply;