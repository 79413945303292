import { useEffect, useState } from 'react';
import axios from '@src/api/axios';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import QueryString from "qs";
import { BASE_URL, addComma } from '@src/utils';
import { useNavigate } from 'react-router-dom';
import WebHeader from '../../components/linkpay/WebHeader';

const LinkPaySuccess = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const [isLoading, setIsLoading] = useState(true);
	const orderId = query.get('orderId');
	const amount = query.get('amount');
	const paymentKey = query.get('paymentKey');
	const payType = query.get('payType');
	const linkPayUuid = query.get('linkPayUuid');
	

	useEffect(() => {
		const body: any = {
			amount: +amount,
			orderId,
			paymentKey
		}
		if (payType === '5') {
			body.linkPayUuid = linkPayUuid;
			axios.post<{code: string; message: string; successType: number}>(`${BASE_URL}/web/v3/halls/consultings/reservations/linkPay/confirm`, QueryString.stringify(body)).then(res => {
				
			})
			.catch(err => {
				alert('결제에 실패하였습니다' + err.msg);
                navigate(`/linkPay/payment/fail?linkPayUuid=${linkPayUuid}&payType=${payType}&message=결제에 실패하였습니다.`)
			})
			.finally(() => {
				setIsLoading(false);
			});
		} else {
			axios.post<{code: string; message: string; successType: number}>(`${BASE_URL}/web/v3/240326/linkPay/payment/confirm`, QueryString.stringify(body)).then(res => {
				if (res.data.successType === 2) {
					// 가상계좌
					// redirect linkPayVBank page
					if (linkPayUuid) navigate(`/linkPay/${linkPayUuid}/info`, {replace: true});
				}
			}).finally(() => {
				setIsLoading(false);
			});
		}
	}, []);
	return (
		<>
		{payType !== '5' && <WebHeader />}
		{!isLoading &&
		<div className="linkpay-result-page" style={{marginTop:payType === '5'?'56px':''}}>
			<h2>
				짝짝짝!<br/>
				결제가<br/>
				완료되었습니다.
			</h2>
			<p>결제가 정상적으로 처리되었습니다.</p>
			<div className="result-info-wrap">
				<h3>최종 결제 내역</h3>
				<p>계약금 {addComma(amount)}원</p>
			</div>
		</div>
		}
		</>
	);
};

export default LinkPaySuccess;