import React, { useEffect, useState, useRef } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { SignUpData, agreementRequired } from '@src/@slice/SignUpSlice';
// router
import { useNavigate, useLocation } from 'react-router-dom';
// hooks
import useInputEvent from '@src/utils/hooks/useSignInputEvent';
import { useRadioInput, useSignValidation, useAuthInput, useCustomInput} from '@src/utils/hooks/useSignInput';
import { useSignUpAllField } from '@src/utils/hooks/useSignUpField';
import { useUserActions } from '@src/utils/hooks/useUserActions';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';
import DataSection from '@src/components/form/dataSection/DataSection';
import WrapTitle from '@src/components/common/wrap/WrapTitle';
import AuthCodeInput from '@src/components/common/authCodeInput/AuthCodeInput';
import Agreement from './Agreement';
// animation
import { CSSTransition, SwitchTransition } from 'react-transition-group';
// analytics
import { logEvent } from '@src/firebase';

let headerView = true;

// 회원가입 Step2 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();

    // location
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const joinToken = queryParams.get('joinToken') || '';

    useEffect(() => {
        if (joinToken) {
            headerView = false;
        }
    },[joinToken])

    // Redux Store
    const ForSignUpData = useSelector(SignUpData);

    // 유저 액션
    const { registerCustomer } = useUserActions();

    // input Event
    const { signValueSetter } = useInputEvent();

    // 다음 단계 이동 트리거
    const [nextTrigger, setNextTrigger] = useState(false);

    // 약관 동의 데이터
    const [agreementData, setAgreementData] = useState({});

    // 노출 단계
    let initialStep = 1;
    if (ForSignUpData.personalInfo.telVerified) {
        initialStep = 5;
    } else if (ForSignUpData.personalInfo.tel) {
        initialStep = 4;
    } else if (ForSignUpData.personalInfo.name) {
        initialStep = 3;
    } else if (ForSignUpData.personalInfo.sex) {
        initialStep = 2;
    }
    const [signUpStep, setSignUpStep] = useState(initialStep);

    // 애니메이션용 참조 및 노드
    const AnimationTitleText = ({ children }: { children: React.ReactNode }) => <>{children}</>;
    const animationTitleRef = useRef(null);
    const animationPhoneNumberRef = useRef(null);
    const animationNameRef = useRef(null);
    const animationButtonRef = useRef(null);
    const animationSpeed1 = { enter: 300, exit: 300 };
    const animationSpeed2 = { enter: 300, exit: 300 };
    const animationClass1 = 'fadeUp-switch1';
    const animationClass2 = 'fadeUp-switch2';

    // 성별
    const signSex = useRadioInput<'2' | '1'>({ val: ForSignUpData.personalInfo.sex }); // 2 male 1 female

    // 이름
    const signNameRef = useRef(null);
    const signName = useCustomInput({
        val: ForSignUpData.personalInfo.name ? ForSignUpData.personalInfo.name : '',
        formatErrorCheck: (val: string) => {
            signName.setResultObject(val.length >= 2 ? {
                result: 'success',
                type: 'formatValid',
                label: '',
                message: ''
            } : {
                result: 'error',
                type: 'formatValid',
                label: 'Invalid Input',
                message: ''
            });
        },
        dataErrorCheck: (val: string) => {
            signName.setResultObject(val.length >= 2 ? {
                result: 'success',
                type: 'dataValid',
                label: '',
                message: ''
            } : {
                result: 'error',
                type: 'dataValid',
                label: 'Invalid Input',
                message: ''
            });
        },
        dataCheckMoment : 'custom',
    });
    const signNameTimer = useRef<NodeJS.Timeout | null>(null);
    const signNameTriggerHandler = () => {
        if (dataField.personalFieldRef.current.name.length >= 2 && signUpStep === 2) {
            if (document.activeElement === signNameRef.current) {
                signNameRef.current.blur();
                authCodeInputRef.current = null;
            }
            setSignUpStep(3);
        } 
        signName.customDataErrorCheck(signNameRef.current.value);
    }

    // 핸드폰 인증
    const authCodeInputRef = useRef(null);
    const signPhoneNumber = useAuthInput({val : {method : ForSignUpData.personalInfo.tel ? ForSignUpData.personalInfo.tel : '', verified : ForSignUpData.personalInfo.telVerified, isFilled: ForSignUpData.personalInfo.telVerified}});

    // 동의 
    const [modalShow, setModalShow] = useState(false);
    const [agreeStatus, setAgreeStatus] = useState({} as typeof agreementRequired);
    
    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([signName.formatValid, signSex.isSelected, signPhoneNumber.methodValue.isFilled]);
    const dataValid = useSignValidation([signName.dataValid, signSex.isSelected, signPhoneNumber.methodValue.isFilled, signPhoneNumber.methodValue.verified]);

    // 다음 단계로 이동 조건 확인
    const updateSignUpStep = () => {
        if (signUpStep === 1 && signSex.isSelected) {
            setSignUpStep(2);
        } else if (signUpStep === 3 && signPhoneNumber.methodValue.isFilled) {
            setSignUpStep(4);
            
        } else if (signUpStep === 4 && signPhoneNumber.methodValue.verified) {
            logEvent('Signup_PhoneNumber_v2', {});
            setSignUpStep(5);
        }
    };
    useEffect(updateSignUpStep, [signSex.isSelected, signName.dataValid, signPhoneNumber.methodValue.isFilled, signPhoneNumber.methodValue.verified]);

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        authCodeInputRef.current.verifyCode()
        //인증상태 변경 -> 다음 단계 트리거
        .then((result:boolean) => {
            if (result) {
                setNextTrigger(true);
            } else {
                setNextTrigger(false);
            }
        }); // 인증상태 변경
    }

    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid) {
                goNext();
            }
        }
    }, [nextTrigger, formatValid, dataValid]); //다음 버튼 클릭 시 유효성 확인

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        setModalShow(true);
        setNextTrigger(false);
    }

    // 가입 처리 시 pause 
    const [pause, setPause] = useState(false);
    
    // 완료 트리거 핸들러
    const handleComplete = (agreeStatus: { [key: string]: boolean }) => {
        setAgreementData(agreeStatus); // 약관 동의 데이터 저장
    }

    // 약관 동의 데이터 저장 -> 완료 트리거
    useEffect(() => {
        if (Object.keys(agreementData).length > 0 && !pause) {
            logEvent('Signup_Terms_v2', {});
            setPause(true);
            signFieldAll.signUpDispatch(true); // 리덕스에 데이터 저장 & 처리 완료 시 completeTrigger true
        } 
    }, [agreementData, pause]); 

    // 완료 페이지로 이동
    const goComplete = (accessToken: string, refreshToken: string) => {
        console.log('goComplete');
        navigate('/sign-up/complete',
            {
                state: { accessToken: accessToken, refreshToken: refreshToken },
                replace: true
            }
        );
    }

    // 마운트 시 리덕스 값 적용 / 언마운트 시 리덕스에 값 저장 (endCallback -> 회원가입 버튼 클릭 시 동작)      
    const signFieldAll = useSignUpAllField({
        signFields: {
            'personalInfo': {
                name: signName,
                sex: signSex,
                tel: {
                    value: signPhoneNumber.saveValue.method ? signPhoneNumber.saveValue.method : signPhoneNumber.methodValue.method,
                    setValue: () => {},
                },
                telVerified: {
                    value: signPhoneNumber.saveValue.verified ? signPhoneNumber.saveValue.verified : signPhoneNumber.methodValue.verified,
                    setValue: () => {},
                },
            },
            'agreement': {
                'agreement': {
                    value: agreeStatus,
                    setValue: (agree: typeof agreeStatus) => {
                        setAgreeStatus(agree);
                    }
                }
            }
        },
        startCallBack : () => {
            if (ForSignUpData.personalInfo.tel && ForSignUpData.personalInfo.telVerified) {
                signPhoneNumber.setMethodValue({
                    method: ForSignUpData.personalInfo.tel,
                    verified: ForSignUpData.personalInfo.telVerified,
                    isFilled: ForSignUpData.personalInfo.telVerified,
                });
            }
        },
        endCallBack: (x: {
            personalInfo?: { [key: string]: any },
            weddingInfo?: { [key: string]: any },
            extraInfo?: { [key: string]: any },
            agreement?: { [key: string]: any }  
        }) => {
            console.log(joinToken, x)
            registerCustomer(x, joinToken).then((result: { success: boolean, data: any } ) => {
                if (result.success) {
                    window.localStorage.setItem('loginComplete', 'need scheme process');
                    goComplete(result.data.item.accessToken, result.data.item.refreshToken);
                } else {
                    alert(result.data.msg ? result.data.msg : '에러가 발생했습니다. 잠시 후 다시 시도해주세요');
                }
            });
        }
    });

    const dataField = signFieldAll.refs;

	return (
		<>
            <div className="contents-wrap">
                <WrapTitle>
                    <SwitchTransition mode="out-in">
                        <CSSTransition nodeRef={animationTitleRef} key={Math.min(signUpStep, 3)}
                            timeout={{ enter: 300, exit: 300 }}
                            classNames={''} >
                            <h2 ref={animationTitleRef}>
                                {signUpStep === 1 && <AnimationTitleText>성별을<br />선택해주세요.</AnimationTitleText>}
                                {signUpStep === 2 && <AnimationTitleText>이름을<br />입력해주세요.</AnimationTitleText>}
                                {(signUpStep >= 3) && <AnimationTitleText>전화번호를<br />인증해주세요.</AnimationTitleText>}
                            </h2>
                        </CSSTransition>
                    </SwitchTransition>
                </WrapTitle>
                <SwitchTransition mode="out-in">
                    <CSSTransition nodeRef={animationPhoneNumberRef} key={Math.min(signUpStep, 3)}
                        timeout={animationSpeed2} classNames={animationClass2}>
                        <div ref={animationPhoneNumberRef}>
                            {(signUpStep >= 3) && <DataSection title="">
                                <div className="form-wrap">
                                    <div className="form-block">
                                        <div className="form-title">
                                            <span className="title">전화번호</span>
                                        </div>
                                        <div className="form-content">
                                            <AuthCodeInput
                                                type={{ type: 'default', data: joinToken ? joinToken : ForSignUpData.personalInfo.loginId }}
                                                value={ForSignUpData.personalInfo.tel ? ForSignUpData.personalInfo.tel : ''}
                                                ref={authCodeInputRef}
                                                onVerify={signPhoneNumber.handleVerify}
                                                onDataFilled={signPhoneNumber.handleIsAllFilled}
                                                onMethodFilled={signPhoneNumber.handleIsMethodFilled}
                                                done={ForSignUpData.personalInfo.telVerified ? ForSignUpData.personalInfo.telVerified : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DataSection>}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
                <SwitchTransition mode="out-in">
                    <CSSTransition nodeRef={animationNameRef} key={Math.min(signUpStep, 2)}
                        timeout={animationSpeed2} classNames={animationClass2}>
                        <div ref={animationNameRef}>
                            {(signUpStep >= 2) && <DataSection title="">
                                <div className="form-wrap">
                                    <div className="form-block">
                                        <div className="form-title">
                                            <span className="title">이름</span>
                                        </div>
                                        <div className="form-content">
                                            <InputTypeBox type="text"
                                                placeholder="김웨딩"
                                                value={signName.value}
                                                ref={signNameRef}
                                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                                    signValueSetter(signName.setValue)(e);
                                                    if (signNameTimer.current) {
                                                        clearTimeout(signNameTimer.current);
                                                    }
                                                    signNameTimer.current = setTimeout(() => {
                                                        signNameTriggerHandler();
                                                    }, 3000);
                                                }}
                                                onBlur={signNameTriggerHandler}
                                                onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => { e.key === 'Enter' && updateSignUpStep() }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DataSection>}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
                <div>
                    <DataSection title="">
                        <div className="form-wrap">
                            <div className="form-block">
                                <div className="form-title">
                                    <span className="title">본인 선택</span>
                                </div>
                                <div className="form-content">
                                    <ul className="selectable-list-wrap2 items-2">
                                        <li onClick={()=> signSex.setValue('1')}>
                                            <div className={signSex.value === '1' ? 'on' : ''} >신부</div>
                                        </li>
                                        <li onClick={()=> signSex.setValue('2')}>
                                            <div className={signSex.value === '2' ? 'on' : ''}>신랑</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DataSection>
                </div>
            </div>
            <SwitchTransition mode="out-in">
                <CSSTransition nodeRef={animationButtonRef} key={Math.min(signUpStep, 3)}
                    timeout={animationSpeed1} classNames={animationClass1}>
                    <div ref={animationButtonRef}>
                    {(signUpStep >= 3) &&
                    <div className="actions-wrap">
                        <div className="btn-wrap">
                            <button
                                className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                                disabled={! formatValid}
                                onClick={handleNext}>
                                다음
                            </button>
                        </div>
                    </div>
                    }
                    </div>
                </CSSTransition>
            </SwitchTransition>
            {modalShow && <Agreement
                isShow={modalShow}
                agreeStatus={agreeStatus}
                setAgreeStatus={setAgreeStatus}
                showModal={setModalShow}
                onCompleted={handleComplete} />}
        </>
	);
};

const SignUpStep2 = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={headerView ? <AppHeader type='stack' title="" actionItems={[]}/> : null}
            contentsComponent={<Contents />}
        />
    );
}

export default SignUpStep2;