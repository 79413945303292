import axios from 'axios'
import { useState, useEffect } from 'react';
import  { Navigate } from 'react-router-dom'
import { ComponentItem } from '../components/parser/Parser';
import { BASE_URL, isExternalUrl, HOSTNAME } from './../utils/index';

const RedirectPage = () => {
	const [isCall, setIsCall] = useState(false);
	useEffect(() => {
		// axios.get(BASE_URL + '/v3/home/component').then((res: any) => {
		// 	const d: { item: ComponentItem[]; result: any; total: any } = res.data;
		// 	const goalItem = d.item.filter(item => item.type === 'goalButton');
		// 	let cData:any = [];
		// 	if (goalItem.length > 0) {
		// 		// goalItem
		// 		goalItem.map(item => cData.push(...JSON.parse(item.jsonStr)));
		// 		console.log(cData);
		// 	}
		// 	const storeData = cData.filter((item: any) => item.title.indexOf('스토어') !== -1);
		// 	if (storeData.length > 0) {
		// 		const str = storeData[0].buttonLink as string;
		// 		window.location.replace(str!);
		// 	} else {
		// 		setIsCall(true);
		// 	}
		// });
		const link = `h2mwbell://webview?url=${HOSTNAME}/product/4?appHeader=hide`;
		window.location.replace(link);
	}, []);
	return (
		<>
		{isCall &&
			<Navigate to='/home' />
		}
		</>
	)
}

export default RedirectPage;