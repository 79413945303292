import { Route, Routes } from "react-router-dom";
import RealTimeReservation from "@src/pages/booking/realtimeBooking/RealTimeReservation";
import RealTimeEditBottomFixedModal from "@src/pages/booking/realtimeBooking/reservation/RealTimeEditBottomFixedModal";
import RealTimeStepCutomerInfo from "@src/pages/booking/realtimeBooking/reservation/RealTimeStepCutomerInfo";
import RealTimeStepHopeDate from "@src/pages/booking/realtimeBooking/reservation/RealTimeStepHopeDate";
import RealTimeStepPayment from "@src/pages/booking/realtimeBooking/reservation/RealTimeStepPayment";
import RealTimeStepVisitDate from "@src/pages/booking/realtimeBooking/reservation/RealTimeStepVisitDate";
import RealTimeStepVisitor from "@src/pages/booking/realtimeBooking/reservation/RealTimeStepVisitor";
import RealTimePolicyModal from "@src/pages/booking/realtimeBooking/RealTimePolicyModal";

const RealtimeBookingRoute = () => {

 
    return (
        <Routes>
            <Route path="visit-date" element={
                  <RealTimeReservation>
                    <RealTimeStepVisitDate />
                  </RealTimeReservation>
            } />
            <Route
                path="visitor"
                element={
                    <RealTimeReservation>
                        <RealTimeStepVisitor />
                    </RealTimeReservation>
                }
            />
            <Route
                path="hope-date"
                element={
                    <RealTimeReservation>
                        <RealTimeStepHopeDate />
                    </RealTimeReservation>
                }
            />
            <Route
                path="customer-info"
                element={
                    <RealTimeReservation>
                        <RealTimeStepCutomerInfo />
                    </RealTimeReservation>
                }
            />
            <Route path="payment" element={<RealTimeStepPayment />}/>
            
            <Route
                path="/edit/visitor"
                element={
                    <RealTimeEditBottomFixedModal>
                        <RealTimeStepVisitor />  
                    </RealTimeEditBottomFixedModal>
                }
            />
            <Route
                path="/edit/hope-date"
                element={
                    <RealTimeEditBottomFixedModal>
                        <RealTimeStepHopeDate />
                    </RealTimeEditBottomFixedModal>
                }
            />
            <Route
                path="/edit/customer-info"
                element={
                    <RealTimeEditBottomFixedModal>
                        <RealTimeStepCutomerInfo />
                    </RealTimeEditBottomFixedModal>
                }
            />
			<Route path="/edit/visit-date" element={
                <RealTimeEditBottomFixedModal>
                	<RealTimeStepVisitDate />
                </RealTimeEditBottomFixedModal>
            } />

            <Route path="/policy" element={<RealTimePolicyModal/>} />
           
            
        </Routes>
    );
};

export default RealtimeBookingRoute;
