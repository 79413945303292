import { useState, useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import { PieChart } from 'react-minimal-pie-chart';
import PageSubTitle from '../../../components/common/pageTitle/PageSubTitle';
import { IResultData, getWGfitShare } from '@src/api/recommend/recommend';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import { useNavigate } from 'react-router-dom';
import { HOSTNAME } from '@src/utils';
const COLORS: {[key in string]: string} = {
	'화려': '#0FC9BB',
	'심플': '#3B6AE3',
	'유니크': '#FF9D00'
}
const Result = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const uuid = query.get('uuid');
	const isShare = query.get('isShare');
	const [resultData, setResultData] = useState<IResultData|null>(null);
	const [partnerData, setPartnerData] = useState<{[key in string]: any}|null>(null);
	const [chartData, setChartData] = useState<{[key in string]: any}|null>(null);

	const shareMsg = `웨딩북 예물 AI추천 검사 결과 도착%21%0A%0A
내 예물 스타일은 이렇고%2C 이런 업체가 잘 어울린대%7E%0A%0A
* 검사결과 보러가기 - ${HOSTNAME}/recommend/weddingGift/result%3FisShare%3D1%26uuid%3D${uuid}`;
	const getUserStyle = () => {
		if (!chartData) return '';
		let max = 0;
		let maxStyle = '';
		for (let k in chartData) {
			if (Math.floor(chartData[k] * 100) > max) {
				max = Math.floor(chartData[k] * 100);
				maxStyle = k;
			}
		}
		for (let k in chartData) {
			if (maxStyle === k) continue;
			if (max === Math.floor(chartData[k] * 100)) {
				return '복합';
			}
		}
		return maxStyle;
	}
	const makeChartData = () => {
		if (!chartData) return [];
		const result = [];
		for (let k in chartData) {
			result.push({
				color: COLORS[k],
				title: k,
				value: Math.floor(+chartData[k] * 100)
			});
		}
		return result;
	}
	const getRecommendPercent = () => {
		if (!chartData) return '';
		let max = 0;
		for (let k in chartData) {
			if (Math.floor(chartData[k] * 100) > max) {
				max = Math.floor(chartData[k] * 100);
			}
		}
		return max * 0.3 + 70;
	}
	useEffect(() => {
		if (!uuid) return;
		getWGfitShare(uuid).then(res => {
			const data = res.data.data;
			setResultData(data.shareInfo);
			setChartData(JSON.parse(data.shareInfo.jsonStr));
			if (data.partner.item.length) setPartnerData(data.partner.item[0]);
		})
	}, [uuid]);
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="page-result">
				<PageTitle
					title={`${resultData?.nick}님의
예물 스타일 분석 결과`}
				/>
				<div className="common-wrap">
					<div className="result-card result-summary">
						<p className="summary-text">
							{resultData?.nick}님의 예물 스타일은
							<strong>{getUserStyle()}형이에요</strong>
						</p>
						<div className="result-chart-wrap">
							{chartData &&
							<PieChart
								style={{width: '100px', height: '100px'}}
								animate
								animationDuration={500}
								animationEasing="ease-out"
								data={makeChartData()}
								lineWidth={35}
								startAngle={90}
							/>
							}
							<ul className="chart-label-wrap">
								{chartData && Object.keys(chartData).sort((a, b) => +chartData[b] - +chartData[a] ).map(data => (
								<li>
									<div className="chart-color" style={{backgroundColor: COLORS[data]}}></div>
									<span className="chart-label">{data}</span>
									<span className="chart-value">{Math.floor(+chartData[data] * 100)}%</span>
								</li>
								))}
							</ul>
						</div>
					</div>
					<p className="result-card-noti">{resultData?.nick}님이 선택한 디자인과 액션 데이터를 바탕으로 최적의 업체를 추천해 드립니다. </p>
					
					<PageSubTitle title={`${resultData?.nick}님은 아래 업체와 
${getRecommendPercent()}% 잘 어울려요 😎`}/>
					<div className="result-card result-company">
						<div className="company-thumb" style={{backgroundImage: `url(${partnerData?.coverUrl})`}} />
						<div>
							<b className="company-title">{partnerData?.name}</b>
							<p>{resultData?.content}</p>
							<ul className="company-tag-list-wrap">
								{resultData?.tag.split(',').map((tag: any) => (
									<li>{tag.trim()}</li>
								))}
								{/* <li>심플</li>
								<li>5% 캐시백</li> */}
							</ul>
						</div>
					</div>
					<PageSubTitle title={`위 업체에 빠른 상담 신청하면
3만원 할인 쿠폰을 드려요 💸💸💸`}/>

					<ul className="result-noti-list-wrap">
						<li>쿠폰은 빠른 상담 신청 완료 후 웨딩북 앱 내 [마이웨딩 &gt; 1:1문의] 채팅으로 지급됩니다. </li>
						<li>쿠폰은 업체에 방문하여 상담 후 계약하실 때 제시해 주세요.</li>
						<li>빠른 상담 신청이 아닌 다른 경로를 통해 예약 시 쿠폰이 지급되지 않습니다.</li>
						<li>빠른 상담 신청 시 방문 일정 확정을 위해 업체에서 고객님께 해피콜을 드릴 예정입니다.</li>
					</ul>
					{isShare &&
					<div className="btn-wrap" style={{margin: '0 -20px'}}>
						<button onClick={() => {
							navigate(`/recommend/weddingGift/start`);
						}} className="btn btn-red btn-full btn-large">나도 해보기</button>
					</div>
					}
					{!isShare &&
					<div className="btn-wrap items-2">
						<div>
							<button onClick={() => {
								window.location.href = `h2mwbell://share?title=결과공유하기&msg=${shareMsg}`
							}} className="btn btn-light-red btn-full btn-large">결과 공유하기</button>
						</div>
						<div>
							<button onClick={() => {
								navigate(`/recommend/weddingGift/apply?shareUuid=${uuid}`);
							}} className="btn btn-red btn-full btn-large">
								빠른 상담 신청하기
								<span className="btn-tooltip">지금 신청하면 3만원 할인 쿠폰 지급! 🎁</span>
							</button>
						</div>
					</div>
					}
				</div>
			</div>
		</div>
	);
};

export default Result;