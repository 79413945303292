import './communityMoreModal.scss';
import { UserContext } from '@src/App';
import { blockCommunity, reportCommunity } from '@src/api/community/community';
import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Params {
	category: 1|2|3;
	communityUuid: string;
	customerUuid: string;
	clearData: () => void;
}
const CommunityMoreModal = ({category, communityUuid, customerUuid, clearData}: Params) => {
	const user = useContext(UserContext);
	const [isShowReportContent, setIsShowReportContent] = useState(false);
	const [content, setContent] = useState('');
	const {hash, pathname} = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		setIsShowReportContent(hash.indexOf('communityMoreModal') !== -1);
	}, [hash]);
	const openReportModal = () => {
		navigate(pathname + '#communityMoreModal');
	}
	const hideReport = () => {
		setContent('');
		navigate(-1)
	}
	const report = () => {
		reportCommunity({
			category: category,
			uuid: communityUuid,
			content: content
		}).then(_ => {
			alert('신고가 완료되었습니다.');
			clearData();
		});
	}
	const block = () => {
		if (user.me) {
			blockCommunity({
				myUuid: user.me.uuid,
				targetUuid: customerUuid	
			}).then(_ => {
				alert('차단 되었습니다.');
				clearData();
			});
		}
	}
	if (!user.me) return null;
	return (
		<>
		<div className="community-more-modal-wrap">
			<div className="overlay" onClick={clearData}></div>
			<div className="community-more-modal">
				<ul>
					<li onClick={() => openReportModal()}>게시글 신고</li>
					<li onClick={() => openReportModal()}>사용자 신고</li>
					<li onClick={block}>이 사용자 차단</li>
					<li onClick={block}>이 게시글 차단</li>
				</ul>
			</div>
		</div>
		{isShowReportContent &&
		<div className="community-report-content-wrap">
			<div className="report-top">
				<div className="report-top-left">
					<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0}} onClick={hideReport}>
						<i className="icon icon-arrow-back-black"></i>
					</button>
					<h2 className="report-title"></h2>
				</div>
				<div className="report-top-right">
					<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0}} onClick={report}>
						<i className="icon icon-checkmark-gray900"></i>
					</button>
				</div>
			</div>
			<textarea 
				className="textarea" 
				onChange={e => setContent(e.target.value)}
				value={content}
				placeholder={`신고하시는 이유를 적어주세요.
3회 이상 누적 신고를 받은 글은 운영자에 의해 가려질 수 있습니다.
해당 글의 작성자도 함께 신고되며 운영자에 의해 경고 조치될 수 있습니다.`}></textarea>
		</div>
		}
		</>
	);
};

export default CommunityMoreModal;