import './emptyArea.scss'

interface IEmptyAreaProps {
	title: string;
	subTitle: string;
	button?: {
		name: string;
		size?: 'small' | 'medium' | 'large';
		theme?: 'brown' | 'red';
		action?: () => void;
	}
}
const EmptyArea = ({title, subTitle, button}: IEmptyAreaProps) => {
	return (
		<div className="empty-area-wrap">
			<i className="empty-icon">😢</i>
			<b>{title}</b>
			<p>{subTitle}</p>
			<button className={`btn btn-${button.size} btn-${button.theme} btn-has-padding-x`} onClick={() => button.action()}>웨딩홀 검색하기</button>
		</div>
	);
};

export default EmptyArea;