import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useContext, } from "react";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from "dayjs";
import Calendar from "react-calendar";
import { useSwipeable } from "react-swipeable";
import { changeReservationDate, getCalendarSchedule, getDaySchedule, getMyReservationList, getVenueInfo } from "@src/api/realtimeBooking/realtimeBooking";
import {realtimeBooking, setDate, setTime, setVenueInfo} from '@src/@slice/realtimeBookingSlice';
import BookingTitle from "@src/components/booking/bookingTitle/BookingTitle";
import { ReactComponent as Prev } from "@src/assets/images/icon/chevronleft_g900.svg";
import { ReactComponent as Next } from "@src/assets/images/icon/chevronright_g900.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "@src/App";
import LoadingSpinner from "@src/components/common/loading/LoadingSpinner";

const RealTimeStepVisitDate = forwardRef(({updateIsDisabled}: { updateIsDisabled?: (value: boolean) => void },ref) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const state = location.state
    const data = useSelector(realtimeBooking).data;
    const partnerId = useParams().id;
    const user = useContext(UserContext);
    const now = dayjs();
    const currentTime = now.format('HH:mm');
    const calendar = useRef<HTMLInputElement | null>(null);

    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(''); // 초기화할 날짜
    const [endDate, setEndDate] = useState(''); 
    // const [cycle, setCycle] = useState(14); //예약 오픈 주기
    const [schedule, setSchedule] = useState({}); // 웨딩홀 슬롯
    const [timeList, setTimeList] = useState([]); //슬롯 날짜별 잔여 시간 리스트
    const [fullDateList, setFullDateList] = useState([]); //예약이 마감된 날짜 목록
    const [restDateList, setRestDateList] = useState([]); //웨딩홀 휴무일 날짜 목록
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(data.visitDate)); 
    const [selectedTime, setSelectedTime] = useState(data.visitTime.slice(0,-3) || ""); 
    const [myReservationList, setMyReservationList] = useState({}); // 회원 예약내역 확인
    const [activeStartDate, setActiveStartDate] = useState<Date | null>(new Date()); // 현재 활성화된 월

    const [consutingUsed, setConsultingUsed] = useState(false); // 비회원 링크 전달 시 실시간 방문 사용 여부 체크

    const isTimeValid = (date: string, time: string) => {
        const dateObj = dayjs(date);
        if (dateObj.isSame(now, 'day')) {
            return time > currentTime;
        }
        return true;
    };


    const getValidDates = (slots) => {
        const validDates = [];
    
        for (const date in slots) {
          const times = slots[date];
          let isValid = false;
      
          for (const time in times) {
            if (times[time] > 0 &&  isTimeValid(date, time) ) {
              isValid = true;
              break;
            }
          }
      
          if (isValid) {
            validDates.push(date)
          }
        }
      
        return validDates;
      };

    //웨딩홀 슬롯 조회 및 예약내역 조회
    const getWeddingOpenSchedule = () => {
        if(user.me){
            const params = {
                apiKey: 'UTQJ9IKlPgPzMex7zrNShw%2BWklZNIILWt1UB51w%2Bnx0%3D',
                uuid: user.me?.uuid
            };
            getCalendarSchedule(`${partnerId}`)
            .then(res => {
                const dateSlots = res.data.data.dateSlots;
                const validDates = getValidDates(dateSlots)

                setSchedule(dateSlots);
                if(validDates.length > 0){
                    let validDate = validDates[0];
                    const fullReservedDates = Object.keys(dateSlots).filter(date => validDates.includes(date) === false) //Object.keys(dateSlots).filter(date => Object.keys(dateSlots[date]).length > 0 && Object.values(dateSlots[date]).every((value) => value === 0));
                    let firstTime = Object.keys(dateSlots[validDate]).filter(t => isTimeValid(validDate, t) && dateSlots[validDate][t] !== 0)[0];
       
                    if (!firstTime) {
                        validDate = validDates.length > 1 ? validDates[1] : validDate;
                        firstTime = Object.keys(dateSlots[validDate]).filter(t => dateSlots[validDate][t] !== 0)[0];
                    }
                  
                    if(JSON.stringify(selectedDate) === 'null' && selectedTime === ''){
                        setSelectedDate(new Date(validDate));
                        setSelectedTime(firstTime);
                    }

                    const validTimeList = Object.keys(dateSlots[validDate]).filter(time => dateSlots[validDate][time] > 0)
                    setTimeList(validTimeList);
                    // setFullDateList(fullReservedDates); //예약 마감 날짜 
                    setRestDateList(fullReservedDates); //휴무 날짜 
                    setStartDate(res.data.data.startDate);
                    setEndDate(res.data.data.endDate);
                    // setCycle(res.data.data.reservationOpenDate); 
                    setLoading(false)
                    setActiveStartDate(new Date(validDate));
                }
            })

            getMyReservationList(params)
            .then(res => {
                const list = res.data.item;
                const result = {};
        
                list.forEach(item => {
                    const visitDate = item.visitDate;
                    const visitable = item.visitState === 'NOT_VISITED'
                    if(dayjs(visitDate).isAfter(now) && visitable){
                        if (!result[visitDate]) {
                            result[visitDate] = [];
                        }
                        result[visitDate].push(item);
                    }
                });
                setMyReservationList(result)
            })
        } else {
            getCalendarSchedule(`${partnerId}`)
            .then(res => {
                const dateSlots = res.data.data.dateSlots;
                const validDates = getValidDates(dateSlots)

                setSchedule(dateSlots);
                if(validDates.length > 0){
                    let validDate = validDates[0];
                    const fullReservedDates = Object.keys(dateSlots).filter(date => validDates.includes(date) === false) //Object.keys(dateSlots).filter(date => Object.keys(dateSlots[date]).length > 0 && Object.values(dateSlots[date]).every((value) => value === 0));
                    let firstTime = Object.keys(dateSlots[validDate]).filter(t => isTimeValid(validDate, t) && dateSlots[validDate][t] !== 0)[0];
       
                    console.log(validDate, firstTime)
                    if (!firstTime) {
                        validDate = validDates.length > 1 ? validDates[1] : validDate;
                        firstTime = Object.keys(dateSlots[validDate]).filter(t => dateSlots[validDate][t] !== 0)[0];
                    }
                  
                    if(JSON.stringify(selectedDate) === 'null' && selectedTime === ''){
                        setSelectedDate(new Date(validDate));
                        setSelectedTime(firstTime);
                    }

                    const validTimeList = Object.keys(dateSlots[validDate]).filter(time => dateSlots[validDate][time] > 0)
                    // setSchedule(dateSlots);
                    setTimeList(validTimeList);
                    // setFullDateList(fullReservedDates); //예약 마감 날짜 
                    setRestDateList(fullReservedDates); //휴무 마감 날짜 
                    setStartDate(res.data.data.startDate);
                    setEndDate(res.data.data.endDate);
                    // setCycle(res.data.data.reservationOpenDate); 
                    setLoading(false)
                    setActiveStartDate(new Date(validDate));
                }
            })
        }
    }                                                                                               

    useEffect(getWeddingOpenSchedule, []);

    // useEffect(()=>{
    //     getDaySchedule(`${partnerId}`,dayjs(selectedDate).format('YYYY-MM-DD'))
    //     .then(res=> console.log(res.data))
    // },[])

    useEffect(()=>{
        getVenueInfo(partnerId)
        .then(res =>  {
            setConsultingUsed(res.data.data.consultingSlotsDisplayed && res.data.data.consultingUsed )
            dispatch(setVenueInfo({ data: res.data.data }));
            sessionStorage.setItem('hallName', res.data.data.name); 
            if((res.data.data.consultingSlotsDisplayed && res.data.data.consultingUsed) === false){
                alert('실시간 예약을 사용하지 않는 웨딩홀입니다.')
                navigate('/home')
            }
        });
    },[])
    
    //오늘 클릭 시 날짜 초기화
    const handleClickToday = () => {
        const resetDate = getValidDates(schedule)[0]
        const resetTime = Object.keys(schedule[resetDate]).filter(time => schedule[resetDate][time] > 0)
        setActiveStartDate(new Date(resetDate));    
        setSelectedDate(new Date(resetDate));
        setTimeList(resetTime)
    }


    const handlers = useSwipeable({
        onSwipedLeft(_) {
            const btn = calendar.current?.getElementsByClassName("react-calendar__navigation__next-button")[0];
            if (btn) {
                (btn as HTMLButtonElement).click();
            }
        },
        onSwipedRight(_) {
            const btn = calendar.current?.getElementsByClassName("react-calendar__navigation__prev-button")[0];
            if (btn) {
                (btn as HTMLButtonElement).click();
            }
        },
    });

    useImperativeHandle(ref, () => ({
        handleButtonClick() {
            if(state){
                changeReservationDate(state.id, dayjs(selectedDate).format('YYYY-MM-DD'), selectedTime, user.me.uuid)
                .then(res => {
                    if(res.data.result === 1){
                        navigate(-1)
                    }
                })
                .catch(err => {
                    alert(err.response.data.msg)
                    navigate(-1)
                })
            }
            dispatch(setDate({date:dayjs(selectedDate).format('YYYY-MM-DD') }));
            dispatch(setTime({time:selectedTime }))
         
        }
    }));

    useEffect(()=>{
        if(updateIsDisabled){
            if(consutingUsed === false){
                updateIsDisabled(true);
            }

            if(!!selectedDate === false || !!selectedTime === false ){
                updateIsDisabled(true);
            }else {
                updateIsDisabled(false);
            }
        }
    },[selectedDate, selectedTime, updateIsDisabled])


    //여기부터 react-calender custom function
    const tileContent = ({ activeStartDate, date, view }) => {
        if (view === "month") {
            if (date.getDate() === now.date() && date.getMonth() === now.month()) {
                return <span className="custom-tile">오늘</span>;
            } 
            else {
                 //TODO: 마감 날짜 표시는 우선 무시 
                // const isFull =  fullDateList.includes(dayjs(date).format("YYYY-MM-DD"));
                // return <span style={{textDecoration: isFull ? "line-through" : ""}}>{date.getDate()}</span>;
                return <span >{date.getDate()}</span>;
            }
        }
        return null;
    };

    const tileDisabled = ({ date, view }) => {
        const day = dayjs(date).format("YYYY-MM-DD")
        if (view === "month") {
            return restDateList.indexOf(day) > -1
        }
        return false;
    };


    return (
            <div className="page-realtime-visit-date">
                <BookingTitle title={`방문 날짜를 선택해주세요.`} />
                {
                    loading ? 
                    <LoadingSpinner/>
                    :
                    <>
                    <div {...handlers}>
                    <Calendar
                        minDate={dayjs(startDate).toDate()}
                        maxDate={dayjs(endDate).toDate()}
                        showNeighboringMonth={false}
                        calendarType="US"
                        onClickDay={(date, event) => {
                            const targetDate = dayjs(date).format("YYYY-MM-DD");
                            setSelectedDate(date);
                            setTimeList(Object.keys(schedule[targetDate]).filter(time => schedule[targetDate][time] > 0));
                            setSelectedTime('');
                        }}
                        tileClassName={({ activeStartDate, date, view }) => dayjs(selectedDate).format("YYYY-MM-DD") === dayjs(date).format("YYYY-MM-DD") ? "active" : null}
                        minDetail="month"
                        tileContent={tileContent}
                        formatDay={(_, date) => ""}
                        inputRef={calendar}
                        tileDisabled={tileDisabled}
                        activeStartDate={
                            activeStartDate === null ? undefined : activeStartDate
                          }
                        onActiveStartDateChange={({ activeStartDate }) =>{
                            const minDate = dayjs().startOf('month').toDate(); // 현재 달의 시작일
                            if (activeStartDate < minDate) {
                                setActiveStartDate(minDate);
                            } else {
                                setActiveStartDate(activeStartDate);
                            }
                        }}
                        nextLabel={<Next/>}
                        prevLabel={<Prev/>}
                        next2Label={null}
                        prev2Label={null}
                    />
                      <span className="today-button" onClick={handleClickToday}>오늘</span>
                </div>
                <div className="time-select-wrap">
                    <p>시간선택</p>
                    <div>
                        {timeList && timeList.map((time) => (
                            <button key={`select-box-list-${time}`}
                                className={`select-button ${timeList[time] === 0 || !isTimeValid(dayjs(selectedDate).format('YYYY-MM-DD'), time) ? "disabled" : selectedTime === time ? "selected" : ""}`}
                                onClick={() => setSelectedTime(time)}
                                disabled={timeList[time] <= 0 || !isTimeValid(dayjs(selectedDate).format('YYYY-MM-DD'), time)}
                            >
                                {time}
                            </button>
                        ))}
                    </div>
                </div>
                </>
                }
                              {!!user.me?.uuid && <div className="my-reserve-list-wrap">
                    <p>내 상담예약내역</p>
                    <div className="list-wrap">
                        {Object.keys(myReservationList).length > 0 ?
                        <div className="list">
                            {Object.keys(myReservationList).sort().map(date => 
                            <ReservationCard date={date} list={myReservationList[date]}/>
                            )}
                        </div>
                        :
                        <div className="list">
                            <p style={{display:'block',textAlign:'center'}}>등록된 상담내역이 없습니다.</p>
                        </div>
                        }
                    </div>
                </div>}
            </div>
    );
});

export default RealTimeStepVisitDate;

const ReservationCard = ({date, list}) => {
    const filteredList = list.filter(schedule => schedule.visitState === 'NOT_VISITED')

    return (
        <div className="reservation-info">
            {filteredList.length > 0 && <h2>{dayjs(date).format('YYYY.MM.DD(ddd)')}</h2>}
            {filteredList.map(reservation => 
            <p>
                <span>{reservation.hallName}</span>
                <span className="time">{reservation.visitTime.slice(0,-3)}</span>
            </p>
            )}
        </div>
    )
}