import { useState ,useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BottomFixedModal from "@src/components/common/bottomFixedModal/BottomFixedModal";
import Checkbox from "@src/components/common/checkbox/Checkbox";




const RealTimePolicyModal = ({readOnly = false}: {readOnly?: boolean}) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [isCheck,setIsCheck] = useState(location.state?.isAgree);

    const dividerRef1 = useRef(null);
    const dividerRef = useRef(null);

    useEffect(() => {
        const { hash } = location;
        if (hash === '#2') {
            dividerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(hash === '#1'){
            dividerRef1.current?.scrollIntoView({behavior: 'smooth' });
        }
    }, [location]);

    useEffect(()=>{

    })
    
    console.log(isCheck)
    return (
        <div className="realtime-policy-page" >
            <BottomFixedModal isShow={true} title="" hideModal={() => {
                if (!readOnly) {
                    navigate(`/booking/real-time/${params.id}/payment`, {state: {isAgree: isCheck}, replace : true});
                }
            }}>
                <div>
                <div className="realtime-policy-wrap" ref={dividerRef1}  style={{paddingTop: '22px'}}>
                    <h2>개인정보수집동의</h2>
                    <p>
                        <span>1. </span>
                        <span>
                            기본수집항목: [필수] 이름,(휴대)전화번호,희망예식정보
                        </span>
                    </p>
                    <p className="additional">※ 추가 수집하는 필수항목</p>
                    <p className="additional">
                        <span>ㆍ</span>
                        <span>
                            예약자와 실제 방문자가 다른 경우: 방문자의 이름 및
                            휴대전화번호,예비배우자의 이름, 예비배우자의
                            (휴대)전화번호
                        </span>
                    </p>
                    <p>
                        <span>2. </span>
                        <span>
                            수집 및 이용목적: 사업자회원과 예약이용자의 원활한
                            거래 진행, 고객상담, 불만처리 등 민원 처리, 분쟁조정
                            해결을 위한 기록보존
                        </span>
                    </p>
                    <p>
                        <span>3. </span>
                        <span>
                            보관기간: 회원탈퇴 시 지체없이 파기. 단, 관련 법령에
                            의하여 일정 기간 보관이 필요한 경우에는 해당 기간
                            동안 보관함
                        </span>
                    </p>
                    <p>
                        <span>4. </span>
                        <span>
                            동의 거부권 등에 대한 고지: 정보주체는 개인정보의
                            수집 및 이용 동의를 거부할 권리가 있으나, 이 경우
                            상품 및 서비스 예약이 제한될 수 있습니다. 그 밖의
                            내용은 웨딩북 개인정보 처리방침을 따릅니다.
                        </span>
                    </p>
                </div>
                <div className="divider" style={{backgroundColor:'#fff', margin:'9px 0'}} ref={dividerRef}/>
                <div className="realtime-policy-wrap" >
                    <h2>개인정보제공동의</h2>
                    <p className='bold' style={{fontSize: '15px'}}>1. 개인정보를 제공받는 자 : 웨딩홀</p>
                    <p className="additional">※ 추가 수집하는 필수항목</p>
                    <p className="additional">
                        <span>ㆍ</span>
                        <span>
                            예약자와 실제 방문자가 다른 경우: 방문자의 이름 및
                            휴대전화번호,예비배우자의 이름, 예비배우자의
                            (휴대)전화번호
                        </span>
                    </p>
                    <p>
                        <span>2. </span>
                        <span>
                        제공하는 기본 개인정보 항목: [필수] 예약자의 이름,
                        (휴대)전화번호, 희망예식정보
                        </span>
                        
                    </p>
                    <p>
                    <span>3. </span> 
                    <span>
                    개인정보를 제공받는 자의 이용목적 : 사업자회원과
                        예약이용자의 원활한 거래 진행, 서비스 분석과 통계에 따른
                        혜택 및 맞춤 서비스 제공, 민원처리 등 고객상담,
                        고객관리, 서비스 이용에 따른 설문조사 및 혜택 제공,
                        분쟁조정을 위한 기록보존
                        </span> 
                    </p>
                    <p>
                    <span>  4.</span>
                    <span>
                    개인정보를 제공받는 자의 개인정보 보유 및 이용기간 :
                        위 개인정보 이용목적 달성 시 까지 이용합니다. (단, 관련
                        법령에 따른 보관의무가 있는 경우에 그 기간동안 보관)
                        </span> 
                     
                    </p>
                    <p style={{paddingBottom: !readOnly ? '170px' : '90px'}}>
                    <span> 5. </span>
                    <span>
                    동의 거부권 등에 대한 고지 : 정보주체는 개인정보 제공
                        동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스
                        예약이 제한될 수 있습니다.
                        </span> 
                    </p>
                </div>
				{!readOnly &&
				<div className='noti-btn-wrap'  style={{display:'block'}}>
					<div className="agree-check-wrap">
						<Checkbox
							id={`agree-policy`}
							name="policy"
							value="개인정보수집 및 개인정보제공 동의를 모두 읽었으며 이에 동의합니다."
							setValue={() => {setIsCheck(!isCheck)}}
							checked={isCheck}
						/>
						<p>
							<span
								style={{ color: "#18b698", textDecoration: "underline", textDecorationColor: "#18b698"}}
								onClick={() => navigate('#1', { replace : true })}
							>
								개인정보수집
							</span>
							<span style={{ textDecoration: "underline" }}>
								{" "}
								및{" "}
							</span>
							<span
								style={{ color: "#18b698", textDecoration: "underline", textDecorationColor: "#18b698"}}
								onClick={() => navigate('#2', { replace : true} )}
							>
								개인정보제공{" "}
							</span>
							동의를 모두 읽었으며 이에 동의합니다.
						</p>
					</div>
					<button 
						className={`btn btn-full btn-red ${isCheck ? '' :'btn-disabled'}`} 
						disabled={!isCheck}
						onClick={() => navigate(`/booking/real-time/${params.id}/payment`, {state: {isAgree: isCheck}, replace : true})}>
						확인
					</button>
				</div>
				}
                </div>
            </BottomFixedModal>
        </div>
    );
};

export default RealTimePolicyModal;
