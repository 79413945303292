import { logEvent } from '@src/firebase';
import './inquiryConcierge.scss'

const InquiryConcierge = ({partnerTypeName, page, pageSub}: {partnerTypeName: string, page: string, pageSub?: string}) => {
	return (
		<section className="inquiry-concierge">
			<h2 className="only-sr">
				문의하기
			</h2>
			<em>
				{partnerTypeName} 궁금하다면?<br/>
				부담갖지 말고 언제든 문의하세요.
			</em>
			<a
				href="h2mwbell://chat"
				className="btn btn-full"
				role="button"
				onClick={() => {
					const obj = {
						page: page,
						pageSub: pageSub,
						component: 'inquiry_concierge',
						behavior: 'click'
					};
					if (pageSub === undefined) delete obj.pageSub;
					logEvent('component_behavior', obj);
				}}
			>
				<span>웨딩북 1:1 문의하기</span>
			</a>
			<span>
			운영시간: 평일 오전 10:00 ~ 오후 7:00
			</span>
		</section>
	);
}

export default InquiryConcierge;
