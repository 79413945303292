import { SetStateAction, useEffect, useState } from 'react';
import { Dispatch } from 'react';
import './fullFixedModal.scss';
import { getDiffFromNow } from '@src/utils/date';

// Props
interface Props {
	isShow: boolean;
	setIsShow: Dispatch<SetStateAction<boolean>>;
	overlayBgColor?: string;
	children?: React.ReactNode;
	keyName: string;
	cancelButtonEvent?: () => void;
	activeButtonEvent: () => void;
}

// 정보 업데이트 모달
const InfoUpdate = ({ isShow, overlayBgColor = 'transparent', setIsShow, children, keyName, cancelButtonEvent, activeButtonEvent }: Props) => {
	const [modalShow, setModalShow] = useState(isShow);
	useEffect(() => {
		setModalShow(isShow);
		if (isShow) {
			window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';
            document.documentElement.style.touchAction = 'none';
		} else {
			document.body.style.overflow = '';
			document.documentElement.style.overflow = '';
			document.body.style.touchAction = '';
			document.documentElement.style.touchAction = '';
		}
	}, [isShow]);
	useEffect(() => {
		const lastDeferredDate = localStorage.getItem(keyName);
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key !== keyName && key.includes('need-signup-additional')) {
				localStorage.removeItem(key);
			}
		}
		if (!lastDeferredDate) {
			setIsShow(true);
		} else {
			const lastDeferred = new Date(lastDeferredDate);
			const diff = getDiffFromNow(lastDeferred, 'days');
			if (diff >= 3) {
				localStorage.removeItem(keyName);
				setIsShow(true);
			}
		}
	}, []);
	return (
		<div className={`info-modal-wrap`} style={{display: modalShow ? 'block' : 'none'}}>
			<div className="overlay" style={{backgroundColor: overlayBgColor}} onClick={()=>{setIsShow(false);}}></div>
			<div className="info-modal">
				<div className="modal-content">
					<div className="info-box">
						{children}
						<div className="btn-wrap itmes-align btn-wrap-no-padding items-align-autofill">
							<button className="btn" style={{flexGrow: 1.06, color: '#000'}} onClick={() => {
								setIsShow(false);
								localStorage.setItem(keyName, new Date().toString());
							}}>다음에</button>
							<button className="btn bold btn-red" style={{flexGrow: 1.62}} 
								onClick={activeButtonEvent}>업데이트 하러 가기</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InfoUpdate;