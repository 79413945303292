import React, { useState, useEffect } from 'react';
import './areaSelect.scss';
// utils
import { BASE_URL } from '@src/utils';
// api
import { ServerResponse } from '@src/api/axios';
import axios from '@src/api/axios';

// Props
interface AreaSelectProps {
    hideModal: () => void;
    onCompleted: (region: { name: string, dname: string }) => void;
    defaultRegion?: { name: string, dname: string };
}

// 지역 데이터 타입
interface RegionData {
    rname: string;
    details: SubRegion[];
}

// 세부 지역 데이터 타입
interface SubRegion {
    name: string;
    dname: string;
}

// 서버 응답 타입
interface DetailedServerResponse extends ServerResponse {
    item: RegionData[];
}


// 지역 선택 컴포넌트
const AreaSelect = ({ hideModal, onCompleted, defaultRegion }: AreaSelectProps) => {
    
    // State
    const [buttonClick, setButtonClick] = useState(false);
    const [defaultValue, setDefaultValue] = useState({name: '', dname: ''} as {name: string, dname: string} );

    // 지역 데이터
    const [regionsData, setRegionsData] = useState<RegionData[]>([] as RegionData[]);
    const [selectedRegion1, setSelectedRegion1] = useState<RegionData>(null);
    const [selectedRegion2, setSelectedRegion2] = useState<SubRegion>(null);
    
    // 지역 클릭 이벤트 핸들러
    const handleRegionClick = (data:RegionData) => {
        setSelectedRegion1(data);
    };

    // 세부 지역 클릭 이벤트 핸들러
    const handleSubRegionClick = (data:SubRegion) => {
        setSelectedRegion2(data);
        setButtonClick(true);
    };

    // 버튼 클릭 이벤트 핸들러
    useEffect(() => {
        if (buttonClick) {
            hideModal();
            onCompleted({name: selectedRegion2.name, dname: selectedRegion2.dname})
            setButtonClick(false);
        }
    }, [buttonClick]);

    // 지역 데이터 가져오기
    useEffect(() => {
        axios.get<DetailedServerResponse>(`${BASE_URL}/v3/20240404/customer/region`)
            .then(response => {
                const data = response.data.item;
                setRegionsData(data);
                setSelectedRegion1(data[0]);
                // setSelectedRegion2(data[0].details[0]);
            })
    }, []);

    useEffect(() => {
        if (regionsData.length > 0 && defaultRegion && defaultRegion.name !== '' && defaultValue.name === '') {
            setDefaultValue(defaultRegion);
            const selectedRegion1 = regionsData.find(region => region.rname === defaultRegion.name);
            setSelectedRegion1(selectedRegion1);
            setSelectedRegion2(selectedRegion1.details.find(subRegion => subRegion.name === defaultRegion.dname));  
        }
    },[regionsData, defaultRegion]);

    return (
        <>
            {(regionsData.length > 0 && selectedRegion1) && (
                <div className="area-select-wrap">  
                    <ul className="step1">
                        {Object.values(regionsData).map(region => (
                            <li key={region.rname} className={selectedRegion1.rname === region.rname ? 'on' : ''}
                                onClick={() => handleRegionClick(region)}>
                                {region.rname}
                                {region.details.length > 1 && <i className={`icon ${selectedRegion1.rname === region.rname ? 'icon-chevron-right-18-gray900' : 'icon-chevron-right-18-gray'}`}></i>}
                            </li>
                        ))}
                    </ul>
                    <ul className="step2">
                        {Object.values(selectedRegion1.details).map(subRegion => (
                            <li key={subRegion.name} className={selectedRegion2 && (selectedRegion2.name === subRegion.name && selectedRegion2.dname === subRegion.dname) ? 'on' : ''}
                                onClick={()=> handleSubRegionClick(subRegion)}>
                                {subRegion.dname}
                                <i className='icon icon-checkmark-brown500'></i>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default AreaSelect;