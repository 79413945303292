export const HOSTNAME = process.env.REACT_APP_HOSTNAME;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export const PLATFORM_API_URL = process.env.REACT_APP_PLATFORM_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
export const SAMSUNG_EVENT_PARTNER_UUID = process.env.REACT_APP_SAMSUNG_EVENT_PARTNER_UUID;
export const SAMSUNG_EVENT_WEGAZINE_UUID = process.env.REACT_APP_SAMSUNG_EVENT_WEGAZINE_UUID;
export const APELGAMO_URL = process.env.REACT_APP_APELGAMO_URL;
export const CHAPEL_URL = process.env.REACT_APP_CHAPEL_URL;
export const LUVEL_URL = process.env.REACT_APP_LUVEL_URL;
export const KAKAO_LOGIN_APP_KEY = process.env.REACT_APP_KAKAO_LOGIN_APP_KEY;
export const NAVER_LOGIN_APP_KEY = process.env.REACT_APP_NAVER_LOGIN_APP_KEY;
export const APPLE_LOGIN_APP_KEY = process.env.REACT_APP_APPLE_LOGIN_APP_KEY;
export const LOGIN_REDIRECT_URI = process.env.REACT_APP_LOGIN_REDIRECT_URL;
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export const isApp = () => {};
export const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
export const getOs = () => {
  if (window.hasOwnProperty('wb_bridge')) {
    return 'android';
  }
  if (window.hasOwnProperty('webkiet')) {
    return 'ios';
  }
  return 'unknown';
};
export const isWebview = () => {
  if (window.hasOwnProperty('wb_bridge') || window.hasOwnProperty('webkit')) {
    return true;
  }
  return false;
};
export const isExternalUrl = (url: string) => {
  if (!url) return '';
  if (url.indexOf('h2mwbell://') >= 0) {
    return url;
  }
  let result = `h2mwbell://webview?url=${url}`;
  if (url.indexOf(window.location.host) !== -1) {
    result += url.indexOf('?') === -1 ? '?' : '&';
    result += 'hidetop=true';
  }
  return result;
};
export const addComma = (x: any) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};
export const numberToKorean = (num: number | string) => {
  num = +num;
  const inputNumber = num < 0 ? false : num;
  const unitWords = ['', '만', '억', '조', '경'];
  const splitUnit = 10000;
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = '';
  if (!inputNumber) return;
  for (let i = 0; i < splitCount; i++) {
    let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (var i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString = String(resultArray[i]) + unitWords[i] + resultString;
  }

  return resultString.replace(/000/gi, '천') + '원';
};

export const autoHypenPhone = (str: string) => {
  // input bind keyup event
  // 핸드폰 번호 자동으로 하이픈 처리
  if (str.trim() === '' || typeof str !== 'string') return '';
  str = str.replace(/[^0-9]/g, '');
  var tmp = '';
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 99);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 6);
    tmp += '-';
    tmp += str.substring(6, 99);
    return tmp;
  } else {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 7);
    tmp += '-';
    tmp += str.substring(7, 99);
    return tmp;
  }
};

export const chkPhoneTel = (str: string) => {
  // 핸드폰 형식 정규식
  var reg = /^(01[016789]{1}|070|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/;
  return reg.test(str);
};

export const chkEmail = (str: string) => {
  // 이메일 형식 정규식
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return reg.test(str);
};

export const chkPassword = (str: string) => {
  // 비밀번호 형식 정규식
  // const reg = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
  // return reg.test(str);
  let result = { length: false, alpha: false, number: false };
  if (str.length >= 6) {
    result.length = true;
  }
  if (str.match(/[a-zA-Z]/g)) {
    result.alpha = true;
  }
  if (str.match(/[0-9]/g)) {
    result.number = true;
  }
  return result;
};

export const chkReferralCode = (str: string) => {
  // 추천인 코드 형식 정규식
  // const reg = /^(?=.*[0-9])(?=.*[A-Z])[0-9A-Z]{6}$/;
  const reg = /^(?=.*[A-Z])[A-Z0-9]{6}$/;
  return reg.test(str);
};

// array
export const makeArray = (n: number) => {
  const arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(i);
  }
  return arr;
};
export const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};
