import './pageTab.scss';
import { useEffect, useRef } from 'react';

export interface ITab {
	tabId: number;
	tabTitle: string;
	needCheck?: boolean;
}
export interface PageTabprops {
	tab: ITab[];
	className?: string;
	currentTabId: number|null;
	onChange: (tabId: number) => void;
	isFull?: boolean;
}
const PageTab = ({tab, className, currentTabId, onChange, isFull = false}:PageTabprops) => {
	if (currentTabId === null) <></>;
	const wrap = useRef<HTMLUListElement>(null);
	const moveLeft = () => {
		if (wrap.current) {
			if (wrap.current.getElementsByClassName('on').length > 0) {
				const left = (wrap.current.getElementsByClassName('on')[0] as HTMLLIElement).offsetLeft;
				console.log(left);
				wrap.current.scroll(left > (window.innerWidth - 20) ? left : 0, 0);
			}
		}
	}
	useEffect(() => {
		moveLeft();
	}, []);
	return (
		<ul className={`page-tab ${className ? className : ''}`} ref={wrap} style={{justifyContent: isFull ? 'space-around' : 'flex-start'}}>
			{tab.map(item => (
			<li 
				className={`${currentTabId === item.tabId ? 'on' : ''} ${item.needCheck ? 'need-check' : ''}`}
				onClick={() => onChange(item.tabId)}
				key={`page-tab-${item.tabId}`}
			>
				<span>{item.tabTitle}</span>
			</li>
			))}
		</ul>
	);
};

export default PageTab;