import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SignUp,
    SignUpData,
    setPersonalInfo,
    setWeddingInfo,
    setExtraInfo,
    setAgreementInfo,
    SignUpState,
} from '@src/@slice/SignUpSlice';

/**
 * 회원 가입 시 개인정보/추가정보 입력 필드를 관리하는 커스텀 훅
 * @param signFields - 회원 가입 시 입력 필드
 * @param startCallBack - 시작 콜백 함수
 * @param endCallBack - 종료 콜백 함수
 * @returns ref - 회원 가입 시 입력 필드의 참조
 * - 마운트 시 회원 가입 시 입력 필드의 값 초기화
 * - Redux에 저장된 값이 있을 경우 입력 필드에 값 설정
 * - 언마운트 시 회원 가입 시 입력 필드의 값 Redux에 저장
*/

interface SignUpField {
    [key: string]: { value?: any, setValue?: (value: any) => void };
}
interface StartCallBack {
    (): void;
}
interface EndCallBack {
    (x?:any): void;
}

function useSignUpField(fieldType: keyof SignUpState['data'], setInfoAction: Function,
    signFields:SignUpField) {
    const dispatch = useDispatch();
    const redux = useSelector(SignUp);
    const reduxValues = redux.data;
    const ref = useRef({} as SignUpState['data'][typeof fieldType]);
    const signFieldsRef = useRef({});
    const activeStatus = Object.values(signFields).length !== 0;

    // signFields 값이 있을 경우 ref에 저장
    useEffect(() => {
        if (activeStatus) {
            signFieldsRef.current = signFields;
            Object.keys(signFieldsRef.current).forEach((field) => {
                ref.current[field] = signFieldsRef.current[field].value;
            });
        }
    }, [signFields, activeStatus]);

    // Redux에 저장된 값이 있을 경우 입력 필드에 값 설정
    useEffect(() => {
        if (activeStatus) {
            Object.keys(signFields).forEach((field) => {
                const value = reduxValues[fieldType][field];
                if (fieldType === 'personalInfo') {
                    if (value === null || value === undefined) {
                        signFields[field].setValue('');
                    } else {
                        signFields[field].setValue(value);
                    }
                } else if (fieldType === 'agreement') {
                    signFields[field].setValue(reduxValues[fieldType]);
                } else {
                    if (value) {
                        signFields[field].setValue(value);
                    }
                }
            });
        }
    }, [reduxValues[fieldType], activeStatus]);

    // 언마운트 시 입력 필드의 값 Redux에 저장
    const signUpDispatch = useCallback(async () => {
        if (activeStatus) {
            const data = Object.fromEntries(
                Object.keys(signFieldsRef.current).map(field => {
                    let value = ref.current[field];
                    // if (
                    //     value === "" ||
                    //     (Array.isArray(value) && value.length === 0) ||
                    //     (typeof value === 'object' && value !== null && Object.keys(value).length === 0)) {
                    //     value = null;
                    // }
                    if (value && value.length > 0) {
                        value = value.trim();
                    }
                    return [field, value];
                })
            );
            if (fieldType === 'agreement') { 
                dispatch(setInfoAction({
                    data: data.agreement
                }));
            } else {
                dispatch(setInfoAction({
                    data: data
                }));
            }
            return data;
        }
    }, [activeStatus]);

    return { ref, signUpDispatch };
}

export const useSignUpPersonalField = ({
  signFields
}: { 
  signFields?: SignUpField, 
} = {}) => {
  return useSignUpField('personalInfo', setPersonalInfo, signFields);
}

export const useSignUpWeddingField = ({
    signFields
}: {
    signFields?: SignUpField,
} = {}) => {
    return useSignUpField('weddingInfo', setWeddingInfo, signFields);
}

export const useSignUpAdditionalField = ({
    signFields
}: {
    signFields?: SignUpField,
} = {}) => {
    return useSignUpField('extraInfo', setExtraInfo, signFields);
}

export const useSignUpAgreementField = ({
    signFields
}: {
    signFields?: SignUpField,
} = {}) => {
    return useSignUpField('agreement', setAgreementInfo, signFields);
}

export const useSignUpAllField = ({
    signFields,
    startCallBack,
    endCallBack
} : {
    signFields?: { [ key:string ] : SignUpField },
    startCallBack?: StartCallBack,
    endCallBack?: EndCallBack
} = {}) => {
    
    const { personalInfo = {}, weddingInfo = {}, extraInfo = {}, agreement = {} } = signFields;
    const personalField = useSignUpPersonalField({ signFields: personalInfo });
    const weddingField = useSignUpWeddingField({ signFields: weddingInfo });
    const additionalField = useSignUpAdditionalField({ signFields: extraInfo });
    const agreementField = useSignUpAgreementField({ signFields: agreement });

    const [endCallBackStatus, setEndCallBackStatus] = useState(true);
    const ForSignUpData = useSelector(SignUpData);

    const signUpDispatch = useCallback(async (state: boolean) => {
        const result1 = await personalField.signUpDispatch();
        const result2 = await weddingField.signUpDispatch();
        const result3 = await additionalField.signUpDispatch();
        const result4 = await agreementField.signUpDispatch();
        const results = [
            { key: 'personalInfo', value: result1 },
            { key: 'weddingInfo', value: result2 },
            { key: 'extraInfo', value: result3 },
            { key: 'agreement', value: result4 },
        ];
        const totalResult = results.reduce((acc, { key, value }) => {
            if (key === 'agreement') {
                acc[key] = value ? { ...ForSignUpData[key], ...value[key] } : ForSignUpData[key];
            } else {
                acc[key] = value ? { ...ForSignUpData[key], ...value } : ForSignUpData[key];
            }
            return acc;
        }, {});
        if (endCallBackStatus && state) {
            endCallBack && endCallBack(totalResult);
            setEndCallBackStatus(false);
        }
    }, [ForSignUpData]);

    useEffect(() => {
        startCallBack && startCallBack();
    }, [ForSignUpData]);

    const [mount, setMount] = useState(false);
    
    useEffect(() => {
        // console.log('mount')
        setMount(true);
    }, []);

    useEffect(() => {
        if (mount) {
            return () => {
                // console.log('unmount');
                signUpDispatch(false);
            }
        }
    }, [mount]);

    const refs = {
        personalFieldRef: personalField.ref,
        weddingFieldRef: weddingField.ref,
        additionalFieldRef: additionalField.ref,
        agreementFieldRef: agreementField.ref
    };

    return { refs, signUpDispatch };
}