import {
	createSlice,
	PayloadAction
} from "@reduxjs/toolkit";
import { RootState } from "../store";

interface HistoryState {
	historyLen: number
}

const initialState = { historyLen: 0 } as HistoryState

const historySlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		increment(state) {
			state.historyLen++
		},
		decrement(state) {
			state.historyLen--
		},
		incrementByAmount(state, action: PayloadAction<number>) {
			state.historyLen += action.payload
		},
	},
})

export const { increment, decrement, incrementByAmount } = historySlice.actions;
export const historyLen = (state: RootState) => state.history.historyLen;
export default historySlice.reducer;

