import { useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import Slider from 'react-slick';
import { useWindowSize } from '@src/utils/hooks/useWindowSize';
import { useState, useContext } from 'react';
import { RECOMMEND_CASE_LIST, THONEYMOON_PLACE_NAME, TRECOMMEND_CASE_LIST } from '@src/utils/honeymoon';
import WaitPage from '../../../components/common/waitPage/WaitPage';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, isExternalUrl, HOSTNAME } from '@src/utils';
import { UserContext } from '@src/App';

type THONEYMOON_PLACE = {[key in THONEYMOON_PLACE_NAME]: {
	introduction: string;
	en: string;
	name: string;
	partnerUuid: string;
	language: string;
	entryRegulation: string;
	expense: string;
	timeDiff: string;
	flightTime: string;
	tourSpot: string;
	bgColor: string;
}}
const HONEYMOON_PLACE: THONEYMOON_PLACE = {
	'하와이': {
		introduction: '새롭고 짜릿하게 욜로 허니문!',
		en: 'hawaii',
		name: '하와이',
		partnerUuid: '6efdb241-dd85-11ed-a251-0ac98913430c',
		language: '영어, 하와이어',
		entryRegulation: 'ESTA 승인 시 90일 무비자 체류 가능',
		expense: '기본 5박 7일 or 6박 8일 / 600~700만원대',
		timeDiff: '한국보다 19시간 느림',
		flightTime: '약 9시간 소요',
		tourSpot: '와이키키 해변, 터틀비치, 다이아몬드 헤드 등',
		bgColor: '#1A3530'
	},
	'몰디브': {
		introduction: '프라이빗한 워터빌라에서 휴양하기',
		en: 'maldive',
		name: '몰디브',
		partnerUuid: '63a3a98d-dda9-11ed-a251-0ac98913430c',
		language: '영어, 디베히어',
		entryRegulation: '도착비자 발급 시 30일 체류 가능',
		expense: '기본 4박 6일 / 800~1000만원대',
		timeDiff: '한국보다 4시간 느림',
		flightTime: '경유 1회 이상, 최소 10시간 소요',
		tourSpot: '리조트, 스쿠버 다이빙, 스노쿨링 등',
		bgColor: '#1B2C73'
	},
	'유럽': {
		introduction: '화려함과 역사가 어우러진 관광지',
		en: 'europe',
		name: '유럽',
		partnerUuid: 'e32a4904-de57-11ed-a251-0ac98913430c',
		language: '영어, 프랑스어, 스페인어 등',
		entryRegulation: '나라마다 상이',
		expense: '600~800만원대',
		timeDiff: '한국보다 6~9시간 느림',
		flightTime: '약 13시간 소요',
		tourSpot: '런던, 파리, 바르셀로나 등',
		bgColor: '#2A4960'
	},
	'발리': {
		introduction: '럭셔리 풀빌라 속 힐링천국',
		en: 'bali',
		name: '발리',
		partnerUuid: 'f4e867ae-de57-11ed-a251-0ac98913430c',
		language: '인도네시아어, 발리어',
		entryRegulation: '도착비자 발급 시 30일 체류 가능',
		expense: '기본 4박 6일 or 5박 7일 / 500~600만원대',
		timeDiff: '한국보다 1시간 느림',
		flightTime: '약 7시간 소요',
		tourSpot: '우붓, 띠르따 강가, 바투르 화산 등',
		bgColor: '#035593'
	},
	'푸켓': {
		introduction: '럭셔리한 낮, 그 낮보다 뜨거운 밤',
		en: 'phuket',
		name: '푸켓',
		partnerUuid: '049904d0-de58-11ed-a251-0ac98913430c',
		language: '영어, 태국어',
		entryRegulation: '90일 무비자 체류 가능',
		expense: '기본 4박 6일 or 5박 7일 / 400~500만원대',
		timeDiff: '한국보다 2시간 느림',
		flightTime: '약 6시간 소요',
		tourSpot: '빠똥 비치, 나이한 비치, 프롬텝 곶 등',
		bgColor: '#79931B'
	},
	'칸쿤': {
		introduction: '현대와 고대 문명이 교차하는 휴양지',
		en: 'cancun',
		name: '칸쿤',
		partnerUuid: '11d0c3b2-de58-11ed-a251-0ac98913430c',
		language: '스페인어',
		entryRegulation: '90일 무비자 체류 가능',
		expense: '기본 5박 6일 / 800~1000만원대',
		timeDiff: '한국보다 14시간 느림',
		flightTime: '경유 1회 이상, 최소 17시간 소요',
		tourSpot: '플라야 델피네스, 마야 박물관, 라스 팔라파스 박물관 등',
		bgColor: '#E5737B'
	},
	'코사무이': {
		introduction: '쉼이 필요하다면 청량하고 여유롭게',
		en: 'koh-samui',
		name: '코사무이',
		partnerUuid: '1f65887b-de58-11ed-a251-0ac98913430c',
		language: '영어, 태국어',
		entryRegulation: '90일 무비자 체류 가능',
		expense: '기본 4박 6일 or 5박 7일 / 400~500만원 정도',
		timeDiff: '한국보다 2시간 느림',
		flightTime: '경유 1회 이상, 최소 12시간 소요',
		tourSpot: '보풋 피셔맨스 빌리지, 매남 비치, 라마이 비치 등',
		bgColor: '#825D45'
	},
	'모리셔스': {
		introduction: '오묘하고도 찬란한, 이색체험의 성지',
		en: 'mauritius',
		name: '모리셔스',
		partnerUuid: '2e4d3f9f-de58-11ed-a251-0ac98913430c',
		language: '영어',
		entryRegulation: '90일 무비자 체류 가능',
		expense: '700~900만원 정도',
		timeDiff: '한국보다 5시간 느림',
		flightTime: '경유 1회 이상, 최소 22시간 소요',
		tourSpot: '팜플레무스 식물원, 아이그레테 섬, 르 몬 비치 등',
		bgColor: '#0F5959'
	},
	'괌 & 사이판': {
		introduction: '사계절 내내 꽃피는 여름',
		en: 'saipan',
		name: '괌 & 사이판',
		partnerUuid: '3a41f828-de58-11ed-a251-0ac98913430c',
		language: '영어, 차모로어 등',
		entryRegulation: '괌 ESTA 승인 시 90일 체류 가능, 사이판 45일 무비자 체류 가능',
		expense: '기본 4박 5일 / 400~500만원대',
		timeDiff: '한국보다 1시간 빠름',
		flightTime: '약 4시간 소요',
		tourSpot: '투몬 비치, 사랑의 절벽, 마나가하 섬 등',
		bgColor: '#119585'
	}
}
// const PLACE_KEYS = Object.keys(HONEYMOON_PLACE) as Array<keyof typeof HONEYMOON_PLACE>;
const Result = () => {
	const navigate = useNavigate();
	const {width} = useWindowSize();
	const [isLoading, setIsLoading] = useState(true);
	const query = useUrlQueryString();
	const key = query.get('key');
	const user = useContext(UserContext);
	const [slideKey, setSlideKey] = useState<THONEYMOON_PLACE_NAME|''>('');
	const [recommendId, setRecommendId] = useState<number|null>(null);
	const SETTING = {
		infinite: true,
		speed: 500,
		centerMode: true,
		centerPadding: '37px',
		adaptiveHeight: true,
		autoplay: false,
		beforeChange: (_: number, newIndex: number) => {
			if (key === null) return;
			setSlideKey(RECOMMEND_CASE_LIST[key].result![newIndex]);
		}
	};
	const linkApply = () => {
		navigate(`/honeymoon/apply?inpath=RECOMMEND_HONEYMOON&caseKey=${key}&recommendId=${recommendId}`);
	}
	useEffect(() => {
		if (key === null) return;
		const result = RECOMMEND_CASE_LIST[key].result;
		if (Array.isArray(result)) {
			setSlideKey(result[0]);
		} else {
			navigate(`/recommend/honeymoon/not-found`, {replace: true});
		}
		postData(RECOMMEND_CASE_LIST[key]);
	}, [key]);
	type TEMP_KEY = keyof typeof RECOMMEND_CASE_LIST;
	const postData = (data: typeof RECOMMEND_CASE_LIST[TEMP_KEY]) => {
		const cause = data.cause;
		const tempBudget = cause.budget.replace(/[^0-9~]/g, '').split('~');
		const conceptMap = {
			'휴양': 'REST',
			'관광': 'TOURISM'
		}
		axios.post<{data: number}>(`${BASE_URL}/web/v1/honeymoon/recommend`, {
			period: cause.period,
			concept: [conceptMap[cause.concept]],
			minBudget: tempBudget[0] ? tempBudget[0] : '200',
			maxBudget: tempBudget[1] ? tempBudget[1] : '2000',
			destination: data.result ? data.result.join(',') : ''
		}).then(res => {
			setTimeout(() => {
				setIsLoading(false);
			}, 1500);
			console.log('res : ')
			if (res.data.data >= 0) {
				setRecommendId(res.data.data);
			}
		}).catch(_ => {
			alert('서버에서 결과를 받아올 수 없습니다.');
		});
	}
	if (width !== undefined && width < 360) SETTING.centerPadding = '27px';
	return (
		<div>
			<AppHeader 
				type="stack"
			/>
			{(isLoading || key === null) ?
			<WaitPage desc={`${user.me ? user.me.nick : '고객'}님의 취향을 분석하여
				가장 어울리는 신혼여행지를 고르고 있습니다.`}
			/> 
			: <div className="page-recommend-honeymoon-result">
				<h2>{`${user.me ? user.me.nick : '고객'}님의
				신혼여행지 추천 결과`}</h2>
				<Slider className="result-card-slider-wrap" {...SETTING}>
					{RECOMMEND_CASE_LIST[key].result!.map(key => (
						<div className="recommendation-honeymoon-card" key={`card-${HONEYMOON_PLACE[key].en}`}>
							<div>
								<div className={`card-top ${HONEYMOON_PLACE[key].en}`}></div>
								<div className="card-bottom" style={{backgroundColor: HONEYMOON_PLACE[key].bgColor}}>
									<em>{HONEYMOON_PLACE[key].introduction}</em>
									<b>{HONEYMOON_PLACE[key].name}</b>
								</div>
							</div>
						</div>
					))}
				</Slider>
				<div className="common-wrap">
					{slideKey !== '' &&
					<>
					<dl className="card-detail-info">
						<div>
							<dt>언어</dt>
							<dd>{HONEYMOON_PLACE[slideKey].language}</dd>
						</div>
						<div>
							<dt>입국규정</dt>
							<dd>{HONEYMOON_PLACE[slideKey].entryRegulation}</dd>
						</div>
						<div>
							<dt>예상비용</dt>
							<dd>{HONEYMOON_PLACE[slideKey].expense}</dd>
						</div>
						<div>
							<dt>시차</dt>
							<dd>{HONEYMOON_PLACE[slideKey].timeDiff}</dd>
						</div>
						<div>
							<dt>비행 시간</dt>
							<dd>{HONEYMOON_PLACE[slideKey].flightTime}</dd>
						</div>
						<div>
							<dt>추천관광지</dt>
							<dd>{HONEYMOON_PLACE[slideKey].tourSpot}</dd>
						</div>
					</dl>
					<button className="btn btn-gray btn-full btn-place-more" onClick={() => {
						window.location.href = user.me ? `h2mwbell://partner/${HONEYMOON_PLACE[slideKey].partnerUuid}` : `https://www.wdgbook.com/page/${HONEYMOON_PLACE[slideKey].partnerUuid}?dl=partner/${HONEYMOON_PLACE[slideKey].partnerUuid}`;
					}}>
						<span>{HONEYMOON_PLACE[slideKey].name}</span>에 대해 더 자세히 알아볼까요? 🔍
					</button>
					</>
					}
					<p className="recommend-notice">
						안내된 정보는 단순 참고용이며, 국내외 상황과 환율에 따라 변동될 수 있습니다. 자세한 내용은 하단 [견적 받아보기] 버튼을 눌러 견적 상담을 신청하시면 안내 받으실 수 있습니다. 
					</p>
					<div className="btn-wrap items-2">
						<div>
							<button className="btn btn-full btn-light-red" onClick={() => navigate(-1)}>다시 해보기</button>
						</div>
						<div>
							<button className="btn bold btn-full btn-red" onClick={linkApply}>
								견적 받아보기
								<span className="btn-tooltip">위 여행지로 바로 견적 받아보세요!💸</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	);
};

export default Result;