import { BASE_URL } from "@src/utils";
import axios from "../axios"
import QueryString from "qs";

export interface IPartner {
	address: string|null;
	address2: string|null;
	b2cMallCount: number;
	isLike: number;
	isInquire: number;
	popup?: {
		id: number;
		show: number;
		image: {content: ''};
		partnerId: number;
		popup: {
			benefit: string;
			title: string;
			content: string;
			buttonName: string;
			link: string;
			linkType: 'WEDDINGHALL'|'LINK';
			period: number;
		}
	};
	benefits: { 
		badge: string;
		content: string;
		iconUrl: string|null;
		linkUrl: string|null;
		title: string;
		type: number;
		uuid: string;
	}[];
	bizEmail: string;
	bizHour: string;
	bizName: string;
	blockCount: number;
	// bookingHidden: 0
	// bookingName: null;
	// bookingState: null
	// bookingUrl: null
	content: string;
	coverUrl: string;
	email: string;
	fax: string;
	galleryCount: number;
	// holidays: {}[]
	homepageUrl: string;
	id: number;
	logoUrl: string;
	name: string;
	orgTel: string;
	parking: string;
	profile: string;
	profileUrl: string|null;
	region: string|null;
	reviewCount: number;
	reviewTotalCount: number;
	reviewImageCount: number;
	sdmMallCount: number;
	store: {
		address: string;
		name: string;
		reviewCount: number;
		uuid: string;
	}[];
	tags: {
		json: string | null;
		name: string;
		type: number;
		typeName: string;
	}[];
	tel: string;
	type: number;
	typeName: string;
	uuid: string;
	buttonName: string|null;
	buttonType: 'BOOKING' | 'TEL' | 'BOOKINGREQ' | 'SDMMALL' | 'WDB_CHUNGDAM' | 'WDB_SDM' | 'WDB_CONCIERGE' | 'SDMB' |'CUSTOMIZE';
	buttonUrl: string|null;
	informReceiver: string;
}
export interface IPartnerImages {
	albumId: any;
	content: string|null;
	title: string|null;
	url: string;
}
export const getPartnerImages = (uuid: string, params: {fetchSize: number, fetchStart: number}) => {
	return axios.get<{item: IPartnerImages[]; total: number;}>(`${BASE_URL}/web/v3/230531/partner/${uuid}/image?category=0&fetchStart=${params.fetchStart}&fetchSize=${params.fetchSize}`);
}
export const getPartner = (uuid: string) => {
	return axios.get<{item: IPartner}>(`${BASE_URL}/web/v3/230531/partner/${uuid}`);
}
export const getPartnerDetail = (uuid: string, type: number) => {
	return axios.get<{item: any[]}>(`${BASE_URL}/web/v3/230531/partner/${uuid}/detail?type=${type}`);
}

export const postPartnerLike = (uuid: string, data: {partnerName: string; state: number}) => {
	return axios.post(`${BASE_URL}/web/v3/230531/partner/${uuid}/like`, QueryString.stringify(data));
}

// 문의 하기
export interface IInquiry {
	content: string;
	customerNick: string;
	setCustomerUuid: string;
	depth: number;
	regTsp: string;
	replyCnt: number;
	uuid: string;
}
export const getPartnerInquiry = (uuid: string) => {
	return axios.get<{item: IInquiry[]}>(`${BASE_URL}/web/v3/230531/partner/${uuid}/qna?fetchSize=200`);
}
export const postPartnerInquiry = (uuid: string, content: string) => {
	// QueryString.stringify({partnerUuid: partner.uuid}
	return axios.post(`${BASE_URL}/web/v3/230531/partner/${uuid}/qna`, QueryString.stringify({content: content}));
}



// 리뷰 작성
export const postPartnerReview = (data: any) => {
	return axios.post<{item: IPartner[]}>(`${BASE_URL}/web/v1/communityV2`, QueryString.stringify(data));
}
export const getPartnerReview = (data: any) => {
	console.log('here');
	console.log()
	return axios.get<{item: IPartner[]}>(`${BASE_URL}/web/v1/communityV2?${QueryString.stringify(data)}`);
}