import { useLocation } from 'react-router-dom';
import './partnerTagList.scss';
import { BASE_URL } from '../../../utils/index';
import { logEvent } from '@src/firebase';

interface IPartner {
	name: string;
	uuid: string;
}
interface IItem {
	backgroundColorEnd: string;
	backgroundColorStart: string;
	imgUrl: string;
	link: string;
	mainTitle: string;
	order: number;
	partners: IPartner[]
	subTitle: string;
	tag: string;
}
interface Props {
	id: number;
	page: string;
	pageSub?: string;
	items: IItem[];
}
const PartnerTagList = ({id, page, pageSub, items}: Props) => {
	const location = useLocation();
	const pageType = location.pathname.indexOf('home') > -1 ? 'home' : 'product';
	return (
		<div className="partner-tag-list-wrap">
			<ul>
				{items.map((item, idx) => (
				<li key={`partner-tag-${id}-${idx}`}>
					<a href={`/${pageType}/detail/${id}?idx=${idx}`} onClick={() => {
						const obj = {
							page: page,
							pageSub: pageSub,
							component: 'partenr_tag_list',
							behavior: 'click',
							content: item.tag
						};
						if (pageSub === undefined) delete obj.pageSub;
						logEvent('component_behavior', obj);
					}}>
						<div className={`tag-bg ${!item.imgUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${item.imgUrl})`}} />
						<span className="tag-text">{item.tag}</span>
					</a>
				</li>
				))}
			</ul>	
		</div>
	);
};

export default PartnerTagList;