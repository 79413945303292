import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    setDaysOfTheWeek,
    setYearMonths,
    setHopeTimes,
    realtimeBooking,
} from "@src/@slice/realtimeBookingSlice";
import BookingTitle from "@src/components/booking/bookingTitle/BookingTitle";
import Checkbox from "@src/components/common/checkbox/Checkbox";
import DataSection from "@src/components/form/dataSection/DataSection";
import { useNavigate } from "react-router-dom";

const tabList = ["날짜", "요일", "시간"];
const dayList = ["토요일", "일요일", "기타 공휴일"];
const timeList = ["오전", "점심", "오후", "저녁"];

const RealTimeStepHopeDate = forwardRef(({updateIsDisabled}:{ updateIsDisabled?: (value: boolean) => void }, ref) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const data = useSelector(realtimeBooking).data;
    const hallData = useSelector(realtimeBooking).hall;
    const containerRef = useRef(null);
    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const timeRef = useRef(null);
    const isScrolling = useRef(true);
  
    const [weddingOpenDate, setWeddingOpenDate] = useState({});
    const [tabIdx, setTabIdx] = useState(0);
    const [hopeMonthList, setHopeMonthList] = useState([]);
    const [hopeDay, setHopeDay] = useState([]);
    const [hopeTime, setHopeTime] = useState([]);

    useImperativeHandle(ref, () => ({
        handleButtonClick() {
            dispatch(setYearMonths({ date: hopeMonthList }));
            dispatch(setDaysOfTheWeek({ day: hopeDay }));
            dispatch(setHopeTimes({ time: hopeTime }));
        }
    }));

    const handleTabClick = (idx) => {
        setTabIdx(idx);
        isScrolling.current = false;
        const offset = 200;
        const container = window.location.pathname.includes('edit') ? containerRef.current : window;
        const scrollY = window.location.pathname.includes('edit') ? containerRef.current.scrollTop - 50: window.scrollY;

        switch (idx) {
            case 0:
                container.scrollTo({ top: 0, behavior: 'smooth' });
                break;
            case 1:
                if (dayRef.current) {
                    const top = dayRef.current.getBoundingClientRect().top + scrollY - offset;
                    container.scrollTo({ top: top, behavior: 'smooth' });
                }
                break;
            case 2:
                if (timeRef.current) {
                    const top = timeRef.current.getBoundingClientRect().top + scrollY - offset;
                    container.scrollTo({ top: top, behavior: 'smooth' });
                }
                break;
            default:
                container.scrollTo({ top: 0, behavior: 'smooth' });
        }
    
        setTimeout(() => {
            isScrolling.current = true;
        }, 1100); 
    };

    useEffect(() => {
        const container = window.location.pathname.includes('edit') ? containerRef.current : window;

        const handleScroll = () => {
            const scrollY = window.location.pathname.includes('edit') ? containerRef.current.scrollTop + 150 : window.scrollY;
            const dayTop = dayRef.current?.getBoundingClientRect().top;
            const timeTop = timeRef.current?.getBoundingClientRect().top;

          
            if(isScrolling.current){
                if (scrollY >= timeTop) {
                    if (tabIdx !== 2) {        
                        setTabIdx(2);
                    }
                } else if (scrollY >= dayTop) {
                    if (tabIdx !== 1) {
                        setTabIdx(1);
                    }
                } else {
                    if (tabIdx !== 0) {
                        setTabIdx(0);
                    }
                }
            }
            
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [tabIdx]);

    useEffect(()=>{
        if(data){
            setHopeMonthList(data.hopeWeddingYearMonths);
            setHopeDay(data.hopeWeddingDaysOfTheWeek);
            setHopeTime(data.hopeWeddingTimes);
        }
    },[data]);

    useEffect(()=>{
        if(updateIsDisabled){
            if(hopeMonthList.length === 0 || hopeDay.length === 0 || hopeTime.length === 0){
                updateIsDisabled(true);
            }else {
                updateIsDisabled(false);
            }
        }
    },[hopeMonthList, hopeDay, hopeTime, updateIsDisabled]);

    const getWeddingOpenDate = (date) => {
        const now = dayjs();
        const targetDate = dayjs(date);

        if (targetDate.year() < now.year() || (targetDate.year() === now.year() && targetDate.month() < now.month())) {
            alert('예식 오픈 일정이 올바르게 입력되지 않았습니다.');
            return;
        }

        const result = {};
        let current = now;

        while (current.isBefore(targetDate) || current.isSame(targetDate, "month")) {
            const year = current.year();
            const month = current.format("M");
            if (!result[year]) {
                result[year] = [];
            }
            result[year].push(month);
            current = current.add(1, "month");
        }
        setWeddingOpenDate(result);
    };

    useEffect(()=>getWeddingOpenDate(hallData.weddingOpenDate),[]);

    const clickDateBtn = (year, month) => {
        const date = `${year}-${month}`;
        if (hopeMonthList.length < 4) { 
            if (hopeMonthList.indexOf(date) > -1){
                setHopeMonthList(hopeMonthList.filter((d) => d !== date));
            } else{
                setHopeMonthList([...hopeMonthList, date]);
            }
        }else{
            setHopeMonthList(hopeMonthList.filter((d) => d !== date));
        }
    };

    const clickDayBtn = (day) => {
        if (hopeDay.indexOf(day) > -1){
            setHopeDay(hopeDay.filter((d) => d !== day));
        } else{
            setHopeDay([...hopeDay, day]);
        }
    };

    const clickTimeBtn = (time) => {
        if (hopeTime.indexOf(time) > -1){
            setHopeTime(hopeTime.filter((d) => d !== time));
        } else {
            setHopeTime([...hopeTime, time]);
        }
    };

    return (
        <>
            <div className="tabs-wrap-fixed">
                <BookingTitle title={`희망 예식일을 선택해주세요.`} />
                <div className="tab-btn-wrap">
                    {tabList.map((tab, idx) => 
                    <button
                        key={`tab-${idx}`}
                        className={tabIdx === idx ? "selected" : ""}
                        onClick={() => handleTabClick(idx)}
                    >
                        {tab}
                    </button>
                    )}
                </div>
            </div>
            <div className="page-realtime-hope-date" ref={containerRef}>
                <div className="month-select-wrap" ref={monthRef}>
                    <DataSection title="날짜를 모두 선택해주세요." typeStr="최대 4개">
                    <>
                        <div>
                            {Object.keys(weddingOpenDate) && Object.keys(weddingOpenDate).map((year) => 
                            <div key={`year-${year}`}>
                                <p className="year">{year}</p>
                                <div className={`${year}-btn-wrap`}>
                                    {weddingOpenDate[year].map(month => 
                                    <button
                                        key={`select-box-list-${year}-${month}`}
                                        className={`select-button ${hopeMonthList.indexOf(`${year}-${month}`) > -1? "selected": ""}`}
                                        onClick={() => clickDateBtn(year,month)}
                                    >
                                        {month}월
                                    </button>)}
                                </div>
                            </div>
                            )}
                        </div>
                        <ul className="box-list-wrap">
                            {hopeMonthList.map((item, idx) => 
                            <li key={`select-box-list-${idx}`}>
                                {`${idx + 1}순위 : ${item.split("-")[0]}년 ${item.split("-")[1]}월`}
                                <button className="btn btn-auto-height btn-tight btn-transparent" onClick={()=>clickDateBtn(item.split('-')[0],item.split('-')[1])}>
                                    <i className="icon icon-x-brown-18" />
                                </button>
                            </li>
                            )}
                        </ul>
                    </>
                    </DataSection>
                </div>
                <div className="divider" />
                <div className="day-select-wrap" ref={dayRef}>
                    <DataSection title="요일을 모두 선택해주세요.">
                        <>
                            {dayList.map((day, idx) => 
                            <li key={`checkbox-day-${idx}`}>
                                <Checkbox
                                    id={`day-${idx}`}
                                    name="day"
                                    value={day}
                                    setValue={() => clickDayBtn(day)}
                                    checked={hopeDay.indexOf(dayList[idx]) > -1}/>
                                <label htmlFor={`day-${idx}`}>{day}</label>
                            </li>
                            )}
                            <ul className="box-list-wrap">
                                {hopeDay.map((day, idx) => 
                                <li key={`select-box-list-${idx}`}>
                                    {`${idx + 1}순위 : ${day}`}
                                    <button className="btn btn-auto-height btn-tight btn-transparent" onClick={()=>clickDayBtn(day)}>
                                        <i className="icon icon-x-brown-18" />
                                    </button>
                                </li>
                                )}
                            </ul>
                        </>
                    </DataSection>
                </div>
                <div className="divider" />
                <div className="time-select-wrap" ref={timeRef}>
                    <DataSection title="시간을 모두 선택해주세요.">
                        <>
                            {timeList.map((time, idx) => 
                            <li key={`checkbox-time-${idx}`}>
                                <Checkbox
                                    id={`time-${idx}`}
                                    name="time"
                                    value={time}
                                    setValue={() => clickTimeBtn(time)}
                                    checked={hopeTime.indexOf(time) > -1}/>
                                <label htmlFor={`time-${idx}`}>
                                    {time}
                                </label>
                            </li>
                            )}
                            <ul className="box-list-wrap">
                                {hopeTime.map((time, idx) => 
                                <li key={`select-box-list-${idx}`}>
                                    {`${idx + 1}순위 : ${time}`}
                                    <button className="btn btn-auto-height btn-tight btn-transparent" onClick={()=>clickTimeBtn(time)}>
                                        <i className="icon icon-x-brown-18" />
                                    </button>
                                </li>
                                )}
                            </ul>
                            <div style={{height:'55vh'}}/>
                        </>
                    </DataSection>
                </div>
            </div>
        </>
    );
});

export default RealTimeStepHopeDate;
