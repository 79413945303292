import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UserState {
    needCallAdditionalData : boolean;
    data: {
        [key: string]: any;
        "iss": string;
        "sub": string;
        "id": string;
        "name": string;
        "tel": string;
        "weddingTsp": string;
        "weddingTspType": string;
        "sex": string;
        "loginId": string;
        "nick": string;
        "imageUrl": string;
        "customerUuid": string;
        "tokenExpired": string;
        "exp": string;
        "additionalInformationRegistRequest"?: string;
    }
}

const initialState: UserState = {
    needCallAdditionalData: true,
    data: {
        "additionalInformationRegistRequest": "",
        "iss": "",
        "sub": "",
        "id": "",
        "name": "",
        "tel": "",
        "weddingTsp": "",
        "weddingTspType": "",
        "sex": "",
        "loginId": "",
        "nick": "",
        "imageUrl": "",
        "customerUuid": "",
        "tokenExpired": "",
        "exp": ""
    }
}

const UserSlice = createSlice({
	name: 'user',
	initialState,
    reducers: {
        setNeedCallAdditionalData(state, action: PayloadAction<{ boo: boolean }>) {
            state.needCallAdditionalData = action.payload.boo;
        },
        setResetData(state) {
            state.data = initialState.data;
        },
        setData(state, action: PayloadAction<{ data: any }>) {
            state.data = { ...state.data, ...action.payload.data };
        },
    }
});

export const { setData, setResetData, setNeedCallAdditionalData } = UserSlice.actions;
export const User = (state: RootState) => state.user; 
export const UserData = (state: RootState) => state.user.data;
export default UserSlice.reducer;

