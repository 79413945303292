import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

// 홀 추천
import HallStart from '@src/pages/recommend/hall/Start';
import HallStyle from '@src/pages/recommend/hall/Style';
import HallType from './../pages/recommend/hall/Type';
import HallRegion from './../pages/recommend/hall/Region';
import HallVisitor from './../pages/recommend/hall/Visitor';
import HallMealType from './../pages/recommend/hall/MealType';
import HallCost from './../pages/recommend/hall/Cost';
import HallHopeDate from './../pages/recommend/hall/HopeDate';
import HallEtcInfo from './../pages/recommend/hall/EtcInfo';
import HallCustomerInfo from './../pages/recommend/hall/CustomerInfo';
import HallComplete from './../pages/recommend/hall/Complete';
import HallResult from './../pages/recommend/hall/Result';

// 예물 추천
import WeddingGiftStart from '@src/pages/recommend/weddingGift/Start';
import WeddingGiftPriceRange from '../pages/recommend/weddingGift/PriceRange';
import WeddingGiftSelectGift from '@src/pages/recommend/weddingGift/SelectGift';
import WeddingGiftResult from '@src/pages/recommend/weddingGift/Result';
import WeddingGiftApply from '@src/pages/recommend/weddingGift/Apply';
import WeddingGiftComplete from './../pages/recommend/weddingGift/Complete';
import WeddingGiftUserData from '@src/pages/recommend/weddingGift/UserData';

// 신혼여행지 추천
import HoneymoonStart from '@src/pages/recommend/honeymoon/Start';
import HoneymoonResult from '@src/pages/recommend/honeymoon/Result';
import HoneymoonNotFound from '@src/pages/recommend/honeymoon/NotFound';

const RecommendRoute = () => {
	
	return (
		<Routes>
			{/* hall */}
			<Route path="/hall/start" element={<HallStart/>}/>
			<Route path="/hall/style" element={<HallStyle/>}/>
			<Route path="/hall/type" element={<HallType/>}/>
			<Route path="/hall/region" element={<HallRegion/>}/>
			<Route path="/hall/visitor" element={<HallVisitor/>}/>
			<Route path="/hall/meal-type" element={<HallMealType/>}/>
			<Route path="/hall/cost" element={<HallCost/>}/>
			<Route path="/hall/hope-date" element={<HallHopeDate/>}/>
			<Route path="/hall/etc-info" element={<HallEtcInfo/>}/>
			<Route path="/hall/customer-info" element={<HallCustomerInfo/>}/>
			<Route path="/hall/complete" element={<HallComplete/>}/>
			<Route path="/hall/:uuid" element={<HallResult/>}/>

			{/* wedding gift */}
			<Route path="/weddingGift/start" element={<WeddingGiftStart/>}/>
			<Route path="/weddingGift/price-range" element={<WeddingGiftPriceRange/>}/>
			<Route path="/weddingGift/select-gift" element={<WeddingGiftSelectGift/>}/>
			<Route path="/weddingGift/result" element={<WeddingGiftResult/>}/>
			<Route path="/weddingGift/apply" element={<WeddingGiftApply/>}/>
			<Route path="/weddingGift/complete" element={<WeddingGiftComplete/>}/>
			<Route path="/weddingGift/userData" element={<WeddingGiftUserData/>}/>

			<Route path="/honeymoon/start" element={<HoneymoonStart/>}/>
			<Route path="/honeymoon/result" element={<HoneymoonResult/>}/>
			<Route path="/honeymoon/not-found" element={<HoneymoonNotFound/>}/>
		</Routes>
	);
};

export default RecommendRoute;