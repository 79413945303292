import './companyOrder.scss';
import {useState, useEffect, useRef, useContext} from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../utils/index';
import update from 'react-addons-update';
import DualRangeSlider from '../../dualRangeSlider/DualRangeSlider';
import noImage from '../../../assets/images/icon/ic-no-image.png';
import { UserContext } from '../../../App';
import { logEvent } from '@src/firebase';
interface Props {
	id: number;
	page: string;
	pageSub?: string;
	categoryId?: string;
	showRating?: boolean;
	data: {
		name: string;
		uuid: string;
	}[];
}

interface IFilterItem {
	items: string[];
	k: string;
	ord: number;
	type: string;
	step?: number;
	unit?: string;
	v: string;
}
interface IFilter {
	name: string;
	filter: IFilterItem[];
	sort: {
		k: string;
		ord: number;
		v: string;
	}[];
	type: number;
}
interface IStyleFilterItem {
	id: number;
	name: string;
	partnerType: number
}
interface IFilterRes<T> {
	item: T[];
}

interface IRegion {
	count: number;
	name: string;
	type: number;
}
type FilterType = 'style' | 'filter' | 'region' | '';
const FETCH_SIZE = 30;

export const CompanyItem = ({partner, page, pageSub, parentComponent, showRating = true}: {partner: any, page: string, pageSub?: string, parentComponent: string; showRating?: boolean;}) => {
	const [isImageLoad, setIsImageLoad] = useState(false);
	const [isImageError, setIsImageError] = useState(false);
	useEffect(() => {
		const image = new Image();
		image.src = partner.coverUrl;
		image.onload = () => {
			setIsImageLoad(true);
		}
		image.onerror = () => {
			setIsImageError(true);
			setIsImageLoad(true);
		}
	}, [partner.coverUrl]);
	return (
		<li>
			
			<a href={`h2mwbell://partner/${partner.uuid}`} className="company-order-list-inner" onClick={() => {
				const obj = {
					page: page,
					pageSub: pageSub,
					component: parentComponent,
					behavior: 'click',
					content: partner.name
				}
				if (pageSub === undefined) delete obj.pageSub;
				logEvent('component_behavior', obj);
			}}>
				<div className={`company-bg ${partner.isLike ? 'like' : ''} ${isImageError ? 'no-image' : ''} ${isImageLoad ? 'load' : 'before-load'}`} style={{backgroundImage: `url(${partner.coverUrl})`}} />
				<div className="company-info-wrap">
					<span className="company-address">{partner.region}</span>
					<span className="company-name">{partner.name}</span>
					<div className="company-score-wrap">
						{showRating &&
						<div>
							<i className="icon icon-rate" />
							<span className="company-rate">{partner.rating ? (partner.rating / 20).toFixed(1) : 0}</span>
						</div>
						}
						<div>
							<i className="icon icon-comment" />
							<span className="company-comment">{partner.reviewCnt ? partner.reviewCnt : 0}</span>
						</div>
					</div>
					{partner.benefits && partner.benefits.length > 0 &&
					<div className="company-tags-wrap">
						{partner.benefits.map((benefit: any) => (
							<div className="badge badge-square badge-brown" key={`company-order-${benefit.uuid}`}>{benefit.badge}</div>
						))}
					</div>
					}
				</div>
			</a>
		</li>
	)
}
const CompanyOrder = ({id, page, pageSub, categoryId, data, showRating = true}: Props) => {
	const user = useContext(UserContext);
	const componentWrap = useRef<HTMLDivElement>(null);
	const filterBtns = useRef<HTMLDivElement>(null);
	const [total, setTotal] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);
	const [fetchIndex, setFetchIndex] = useState(0);
	const [fetching, setFetching] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(false);
	const [filterType, setFilterType] = useState<FilterType>('');
	// style filter
	const [styleFilter, setStyleFilter] = useState<IStyleFilterItem[]>([]);
	const [selectedStyle, setSelectedStyle] = useState<any[]>([]);
	// common filter
	const [filterData, setFilterData] = useState<IFilter|null>(null);
	const [selectedFilter, setSelectedFilter] = useState<{[key: string]: any}>({}); // use with region & common
	// region filter
	const [regionFilter, setRegionFilter] = useState<IFilterItem|null>(null);
	const [regionList, setRegionList] = useState<IRegion[]>([]);
	const [subRegionList, setSubRegionList] = useState<IRegion[]>([]);
	const [selectedRegion, setSelectedRegion] = useState<string|null>(null);
	const [selectedSubRegion, setSelectedSubRegion] = useState<string|null>(null);
	const [regionListType, setRegionListType] = useState<'region'|'subRegion'>('region');

	// partners 
	const [tempPartners, setTempParnters] = useState<any[]>([]);
	const [partners, setPartners] = useState<any[]>([]);

	// custom partners
	const [customPartners, setCustomPartners] = useState<any[]>([]);
	useEffect(() => {
		const partnerUuids = data.map(item => item.uuid).join();
		axios.get(`${BASE_URL}/v3/200107/partners?uuids=${partnerUuids}`).then((res: any) => {
			setCustomPartners(res.data.item);
		});
	}, [data]);

	useEffect(() => {
		if (categoryId !== undefined) {
			axios.get<IFilterRes<IStyleFilterItem>>(`${BASE_URL}/v1/partner/style/${categoryId}`).then(res => {
				setStyleFilter(res.data.item);
			});
			axios.get<IFilterRes<IFilter>>(`${BASE_URL}/v1/init/partner/filter`).then(res => {
				const selectedFilter = res.data.item.filter(data => data.type === +categoryId)[0];
				if (!selectedFilter) return; // no filter in this category
				setFilterData(selectedFilter);
				const initial: {[key in string]: any} = {};
				selectedFilter.filter.forEach(item => {
					const arr = item.v.split(',');
					arr.forEach(value => {
						initial[value] = [];
					});
				});
				setSelectedFilter(initial);
			});
			axios.get<{item: IRegion[]}>(`${BASE_URL}/v1/partner/region?type=${categoryId}&regionType=${1}`).then(res => {
				setRegionList(res.data.item);
			});
		}
	}, [categoryId]);
	useEffect(() => {
		if (!filterData) return;
		const regionFilter = filterData.filter.filter(data => data.type === 'region');
		if (regionFilter.length) {
			setRegionFilter(regionFilter[0]);
		}
	}, [filterData]);

	const oepnFilter = (type: FilterType) => {
		setFilterType(type)
		setIsShowFilter(true);
	}
	const toggleStyle = (id: number) => {
		if (id === -1 || styleFilter.length === selectedStyle.length + 1) {
			setSelectedStyle([]);
		} else if (selectedStyle.indexOf(id) === -1) {
			setSelectedStyle([...selectedStyle, id]);
			return;
		} else {
			setSelectedStyle(selectedStyle.filter(item => item !== id));
		}
	}
	const toggleFilterData = (target: string, name: string) => {
		const targetObject = selectedFilter[target];
		const obj: {[key: string]: any} = {};
		if (name === '') {
			// clear all 
			obj[target] = {$splice: [[0, targetObject.length]]}
		} else if (targetObject.indexOf(name) === -1) {
			obj[target] = {$push: [name]}
		} else {
			obj[target] = {$splice: [[targetObject.indexOf(name), 1]]}
		}
		const newData = update(selectedFilter, obj);
		setSelectedFilter(newData);
	}
	const setRangeData = (target: string, data: string) => {
		const obj: {[key: string]: any} = {};
		obj[target] = data === '' ? [] : [data];
		setSelectedFilter({
			...selectedFilter,
			...obj
		});
	}
	const selectRegion = (region: string) => {
		if (region.trim() === '전체') {
			setSelectedRegion(null);
			setSelectedSubRegion(null);
			return;
		}
		setSelectedRegion(region);
		setSelectedSubRegion(null);
		setRegionListType('subRegion');
	}
	const selectSubRegion = (region: string) => {
		if (region.trim() === '전체') {
			setSelectedSubRegion(null);
			return;
		}
		setSelectedSubRegion(region);
	}
	const resetFilter = () => {
		// style
		setSelectedStyle([]);
		// common filter
		const initial: {[key in string]: any} = {};
		Object.keys(selectedFilter).forEach(key => {
			initial[key] = [];
		});
		setSelectedFilter(initial);
		// region
		setSelectedRegion(null);
		setSelectedSubRegion(null);
		setRegionListType('region');
	}
	const applyFilter = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (componentWrap.current && componentWrap.current.offsetTop < scrollTop) {
			window.scrollTo(0, componentWrap.current.offsetTop);
		}
		setFetchIndex(0);
		setIsShowFilter(false);
		setPartners([...tempPartners]);
		setIsEmpty(tempPartners.length === 0);
	}
	useEffect(() => {
		// get sub region list
		if (categoryId !== undefined) {
			axios.get<{item: IRegion[]}>(`${BASE_URL}/v1/partner/region?type=${categoryId}&regionType=${2}&name=${selectedRegion}`).then(res => {
				setSubRegionList(res.data.item);
			});
		}
	}, [categoryId, selectedRegion]);
	useEffect(() => {
		if (!user.me) return;
		if (categoryId !== undefined) {
			const keys = Object.keys(selectedFilter);
			const tags = keys.map(key => {
				if (selectedFilter[key].length === 0) return '';
				return `${key}:${selectedFilter[key].join('%2B')}`; // %2B = +
			}).filter(tag => !!tag);
			const region = (selectedRegion ? selectedRegion : '') + (selectedSubRegion ? ' ' + selectedSubRegion : '');
			axios.get<{item: any[]; total: number}>(`${BASE_URL}/v3/200107/partner?uuid=${user.me.uuid}&type=${categoryId}&style=${selectedStyle.join(',')}&tags=${tags}&region=${region}&sort=1`).then(res => {
				setTotal(res.data.total);
				setTempParnters(res.data.item);
			});
		}
	}, [user.me, categoryId, selectedStyle, selectedFilter, selectedRegion, selectedSubRegion]);
	useEffect(() => {
		if (!user.me) return;
		setFetching(true);
		const keys = Object.keys(selectedFilter);
		const tags = keys.map(key => {
			if (selectedFilter[key].length === 0) return '';
			return `${key}:${selectedFilter[key].join('%2B')}`; // %2B = +
		}).filter(tag => !!tag);
		const region = (selectedRegion ? selectedRegion : '') + (selectedSubRegion ? ' ' + selectedSubRegion : '');
		if (categoryId !== undefined) {
			axios.get<{item: any[]; total: number}>(`${BASE_URL}/v3/200107/partner?uuid=${user.me.uuid}&type=${categoryId}&style=${selectedStyle.join(',')}&tags=${tags}&region=${region}&fetchStart=${FETCH_SIZE * fetchIndex}&fetchSize=${FETCH_SIZE}&sort=1`).then(res => {
				setPartners(update(partners, {$push: res.data.item}));
				setFetching(false);
			}).catch(err => {
				setFetching(false);
			})
		}
		const onScroll = () => {
			if (categoryId === undefined) return;
			const scrollHeight = document.documentElement.scrollHeight;
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const clientHeight = document.documentElement.clientHeight;
			if (scrollTop + clientHeight >= scrollHeight && fetching === false) {
				setFetchIndex(fetchIndex+1);
			}
		}
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	}, [user.me, fetchIndex]);
	useEffect(() => {
		const onScroll = () => {
			const rect = filterBtns.current?.getClientRects()[0];
			const wrapRect = componentWrap.current?.getClientRects()[0];
			const appHeader = document.getElementById('AppHeaderWrap');
			if (appHeader && filterBtns.current) {
				// app header transformY 값 만큼 위치 조정
				const matrix = new WebKitCSSMatrix(window.getComputedStyle(appHeader).transform);
				filterBtns.current.style.top = (54 + matrix.m42) + 'px';
			}
			if (!rect || !wrapRect) return;
			// 현 화면 기준 top 값 비교하여 fixed 여부 결정
			if (wrapRect.top > 0) {
				filterBtns.current.classList.remove('fixed')
				return;
			}
			if (rect.top < 0) {
				filterBtns.current.classList.add('fixed')
			}
		}
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	}, []);
	return (
		<div ref={componentWrap}>
			<div className="company-order-filter" ref={filterBtns}>
				{styleFilter.length > 0 &&
				<button className={`btn btn-auto-width btn-small ${selectedStyle.length > 0 ? 'btn-line-dark' : 'btn-line-gray'}`} onClick={() => oepnFilter('style')}>
					모든 스타일
					<i className="icon icon-chevron-down"></i>
				</button>
				}
				{filterData !== null && 
				(filterData.filter.filter(data => data.type !== 'region').length > 0 &&
				<button className="btn btn-auto-width btn-small btn-line-gray" onClick={() => oepnFilter('filter')}>
					필터
					<i className="icon icon-chevron-down"></i>
				</button>)
				}
				{regionFilter !== null &&
				<button className={`btn btn-auto-width btn-small ${selectedRegion ? 'btn-line-dark' : 'btn-line-gray'}`} onClick={() => oepnFilter('region')}>
					{(!selectedRegion && !selectedSubRegion) 
					? '모든 지역'
					: `${(selectedRegion ? selectedRegion : '')} ${(selectedSubRegion ? ' ' + selectedSubRegion : '')}`
					}
					
					<i className="icon icon-chevron-down"></i>
				</button>
				}
			</div>
			<ul className="company-list-wrap">
				{(
					customPartners.length > 0 && // has custom partenr
					selectedStyle.length === 0 &&  // no selected style
					!selectedRegion && !selectedSubRegion) &&  // no selected region
					(filterData === null || (filterData !== null && filterData.filter.filter(data => data.type !== 'region').length > 0)) && // no common filter
				customPartners.map((partner, idx) => (
				<CompanyItem 
					page={page}
					partner={partner}
					parentComponent="company_order"
					showRating={showRating}
					key={`company-order-${id}-${partner.uuid}-${idx}`}
				/>
				))
				}
				{partners.map((partner, idx) => (
				<CompanyItem 
					page={page}
					partner={partner}
					parentComponent="company_order"
					showRating={showRating}
					key={`company-order-${id}-${partner.uuid}-${idx}`}
				/>
				))}
			</ul>
			{isEmpty &&
			<div className="no-company-wrap">
				<img src={noImage} width={48} alt="업체 없음" />
				<p>조건에 맞는 업체가 없습니다.</p>
			</div>
			}

			<div className="filter-wrap" style={{display: isShowFilter ? 'block' : 'none'}}>
				<div className="overlay" onClick={() => setIsShowFilter(false)} />
				<div className="filter-inner">
					<ul className="filter-type-list-wrap">
						{styleFilter.length > 0 && 
						<li className={filterType === 'style' ? 'on' : ''} onClick={() => setFilterType('style')}>스타일</li>
						}
						{(filterData !== null && filterData.filter.filter(data => data.type !== 'region').length > 0) &&
						<li className={filterType === 'filter' ? 'on' : ''} onClick={() => setFilterType('filter')}>필터</li>
						}
						{regionFilter !== null &&
						<li className={filterType === 'region' ? 'on' : ''} onClick={() => setFilterType('region')}>지역</li>
						}
					</ul>
					<div className="filter-contents-wrap">
						{filterType === 'style' && 
						<div className="filter-content">
							<span className="content-title">스타일태그</span>
							<ul className="badge-list-wrap">
								<li
									className={selectedStyle.length === 0 ? 'selected' : ''}
									onClick={() => toggleStyle(-1)}
								>
									<span>모든 스타일</span>
								</li>
								{styleFilter.map(item => (
									<li 
										className={selectedStyle.indexOf(item.id) !== -1 ? 'selected' : ''}
										onClick={() => toggleStyle(item.id)}
										key={`company-order-${id}-style-tag-${item.id}`}
									>
										<span>#{item.name}</span>
									</li>
								))}
							</ul>
						</div>
						}
						{filterType === 'filter' &&
						filterData?.filter.map(data => {
							if (data.type === 'region') return null; // region filter에서 따로 함
							return (
								<>
								{data.type === 'checkbox' &&
								<div className="filter-content">
									<span className="content-title">{data.k}</span>
									<ul className="badge-list-wrap">
										<li 
											className={selectedFilter[data.v].length === 0 ? 'selected' : ''}
											onClick={() => toggleFilterData(data.v, '')}
										>
											<span>모든 {data.k}</span>
										</li>
										{data.items.map((item, idx) => (
											<li 
												className={selectedFilter[data.v].indexOf(item) !== -1 ? 'selected' : ''}
												onClick={() => toggleFilterData(data.v, item as string)}
												key={`company-order-${id}-${data.v}-${idx}`}
											>
												<span>#{item}</span>
											</li>
										))}
									</ul>
								</div>
								}
								{data.type === 'range' &&
								<div className="filter-content">
									<span className="content-title">{data.k}</span>
									<DualRangeSlider 
										title={data.k}
										showText={true}
										width={window.innerWidth - 48}
										min={+data.items[0]}
										max={+data.items[1]}
										step={data.step!}
										unit={data.unit!}
										onChangeMin={min => {
											if (min > 0) {
												setRangeData(data.v.split(',')[0], `>=${min}`);
											} else {
												setRangeData(data.v.split(',')[0], '');
											}
										}}
										onChangeMax={max => {
											if (max <= +data.items[1]) {
												setRangeData(data.v.split(',')[1], `<=${max}`);
											} else {
												setRangeData(data.v.split(',')[1], '');
											}
										}}
									/>
								</div>
								}
								</>
							)
						})
						}
						{filterType === 'region' &&
						<div className="filter-content">
							<div className="content-title region-title">
								<span 
									className="city"
									onClick={() => setRegionListType('region')}
								>
									{selectedRegion ? selectedRegion : '지역전체'}
								</span>
								<i className="icon icon-chevron-right-18-gray700" />
								{/* <span className="spliter">&gt;</span> */}
								<span 
									className={`city-detail ${selectedSubRegion ? 'on' : ''}`}
									onClick={() => selectedRegion && setRegionListType('subRegion')}
								>
									{selectedSubRegion ? selectedSubRegion : '상세'}
								</span>
							</div>
							<ul className="btn-list-wrap">
								{regionListType === 'region' &&
								regionList.map((region, idx) => (
									<li
										className={`${(region.name === selectedRegion || (region.name === '전체' && selectedRegion === null)) ? 'selected' : ''}`}
										onClick={() => selectRegion(region.name)}
										key={`company-order-${id}-region-${idx}`}
									>
										<span>{region.name}</span>
									</li>
								))
								}
								{regionListType === 'subRegion' &&
								subRegionList.map((region, idx) => (
									<li 
										className={`${(region.name === selectedSubRegion || (region.name === '전체' && selectedSubRegion === null)) ? 'selected' : ''}`}
										onClick={() => selectSubRegion(region.name)}
										key={`company-order-${id}-region-detail-${idx}`}
									>
										<span>{region.name}</span>
									</li>
								))
								}
								
								{/* {data.items.map(item => (
									<li 
										className={selectedFilter[data.v].indexOf(item) !== -1 ? 'selected' : ''}
										onClick={() => toggleFilterData(data.v, item)}
									>
										#{item}
									</li>
								))} */}
							</ul>
						</div>
						}
					</div>
					<div className="filter-btns-wrap">
						<button className="btn btn-square btn-large btn-light-gray btn-init" onClick={resetFilter}>초기화</button>
						<button className="btn bold btn-square btn-large btn-red btn-apply" onClick={applyFilter}>
							적용
							{total > 0 &&
							'(' + total + '개 업체 보기)'
							}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyOrder;