import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import Stepper from '@src/components/realtimeBooking/Stepper';
import { increment } from '@src/@slice/historySlice';
import RealTimeStopReservation from './RealTimeStopReservation';


const steps = ['visit-date','visitor','hope-date','customer-info','payment']

const RealTimeReservation = ({children}) => {
    const dispatch = useDispatch();

    const params = useParams();
    const step = steps.indexOf(params['*']);
    const childRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true); // 버튼 상태 - true 비활성화, false 활성화
    const [showStopModal, setShowStopModal] = useState(false);
    useEffect(() => {
        dispatch(increment());
        setIsDisabled(false);
    }, [step]);


    const handleNextButtonClick = () => {
        if (childRef.current) {
            childRef.current.handleButtonClick();
        }
    };

    const updateIsDisabled = (bool) => setIsDisabled(bool);

    
    return (
        <div className='layout-realtime-reservation'>
            <AppHeader type="stack" 
                actionItems={[{name:'stop', callback: ()=>{ setShowStopModal(true) }}]} 
                scrollHide={false}/>
            <Stepper steps={4} currentStep={step+1} />
            <div style={{padding:'20px'}}>
                {React.cloneElement(children, { ref: childRef,  updateIsDisabled })}
            </div>
            <Link to={`/booking/real-time/${params.id}/${steps[step+1]}`} className='next-btn'>
                <button 
                    className={`btn btn-full btn-red ${isDisabled === true ? 'btn-disabled' :''}`} 
                    onClick={handleNextButtonClick} 
                    disabled={isDisabled === true} 
                >
                    다음
                </button>
            </Link>
            {showStopModal && 
            <RealTimeStopReservation showStopModal={showStopModal} setShowStopModal={setShowStopModal}/>}
        </div>
    );
};

export default RealTimeReservation;