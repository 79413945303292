import './formTitle.scss';

interface FormTitleProps {
	title: string;
	subText?: string;
}
const FormTitle = ({title, subText}: FormTitleProps) => {
	return (
		<div className="form-title-wrap">
			<h2>{title}</h2>
			{subText && 
			<p className="form-title-subtext">{subText}</p>
			}
		</div>
	);
};

export default FormTitle;