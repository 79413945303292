import {intros} from "./data";
import IntroItem from "../../components/chungdam/IntroItem";
import AppHeader from "../../components/common/appHeader/AppHeader";

export default () => {

    const instagramUrl = 'h2mwbell://webview?url=https://www.instagram.com/weddingbook.chungdam'

    const reservation = () => window.location.assign('h2mwbell://aia/booking');

    return (
        <>
            <AppHeader type="stack" actionItems={[]}/>
            <div className={'chungdam-wrap'}>
                <div className={'content-box'}>
                    <h1>결혼준비의 시작<br/>웨딩북 청담</h1>
                    <p>
                        탁월한 웨딩 상담과 합리적인 가격의 스드메 계약이 가능한 공간입니다. 커다란 지출로 이어지는 구매 결정 전,
                        결혼 준비에 관한 모든 품목을 직접 확인하고 자유롭게 체험할 수 있습니다.
                    </p>
                    {intros.map((item, index) => (
                        <IntroItem introItem={item} key={index}/>
                    ))}
                </div>
                <div className={'instagram-wrapper'}>
                    <p>인스타그램에서 매일 매일 업데이트 되는 <br/>웨딩북 청담을 만나보세요.</p>
                    <a href={instagramUrl}>@weddingbook.chungdam</a>
                </div>
                <div className={'fixed-btn-wrapper'}>
                    <button className={'btn btn-red btn-large btn-full bold'} onClick={reservation}>
                        예약하러 가기
                    </button>
                </div>
            </div>
        </>
    )
}
