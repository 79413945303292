// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent as le, EventParams } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAe-2mxBjR5pjNKYLqb9WlJNK1HppTqISw",
	authDomain: "project-978483068363623503.firebaseapp.com",
	databaseURL: "https://project-978483068363623503.firebaseio.com",
	projectId: "project-978483068363623503",
	storageBucket: "project-978483068363623503.appspot.com",
	messagingSenderId: "234870577415",
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: "G-K14MLZKQ2X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const logEvent = (eventName: string, data: EventParams) => {
	le(analytics, eventName, data);
}
export {
	logEvent
} 