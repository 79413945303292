import { useEffect } from 'react';
import StepComplete from '../../../components/common/stepComplete/StepComplete';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import { UserContext } from './../../../App';
import { useDispatch } from 'react-redux';
import { setIsComplete } from '@src/@slice/recommendHallSlice';

const Complete = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsComplete({bool: true}));
	}, []);
	const linkChat = () => {
		window.location.href = 'h2mwbell://chat';
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<StepComplete 
				title={`짝짝짝!
				빠른 상담 신청이
				접수되었습니다.`}
				text={`고객님께서 신청하신 내용은 업체에서 확인 후
				상담 일정 확정을 위해 연락드릴 예정입니다.`}
			/>
			<UserContext.Consumer>
				{user => (
					<>
					<div className="step-complete-noti" style={{marginTop: -20}}>
						<div>
							<span>💍</span>
							<p>
							빠른 상담예약 신청 고객 대상 예물 3만원 할인 쿠폰은
								<div style={{whiteSpace: 'pre-line'}}>
								{user.me 
								? '[마이웨딩 > 1:1문의] 채팅창으로 지급될 예정입니다.'
								: `웨딩북 앱 내 채팅창으로 지급될 예정입니다.
								문자로 발송되는 링크를 통해
								웨딩북 앱을 다운로드해주세요.`
								}
								</div>
							</p>
						</div>
					</div>
					<button className="btn btn-red btn-step-complete" onClick={() => window.location.href = 'h2mwbell://webview/close'}>확인</button>
					</>
				)}
			</UserContext.Consumer>
		</div>
	);
};

export default Complete;