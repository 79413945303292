import * as React from 'react';
import BottomFixedModal from './../../common/bottomFixedModal/BottomFixedModal';
import Checkbox from './../../common/checkbox/Checkbox';
import { isExternalUrl } from '@src/utils';
import { isWebview } from './../../../utils/index';

interface Props {
	agreement: boolean;
	setAgreement: React.Dispatch<React.SetStateAction<boolean>>;
	isShowModal: boolean;
	setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	onApply: () => void;
}
const AgreementModal = ({agreement, setAgreement, isShowModal, setIsShowModal, onApply}:Props) => {
	return (
		<BottomFixedModal
			isShow={isShowModal}
			title="이용 동의"
			hideModal={() => {
				console.log('hide modal');
				setIsShowModal(false);
			}}
		>
			<ul className="agreement-text-wrap">
				<li>혜택 적용을 위해 해당 웨딩홀에 웨딩북 고객으로 등록되며, 이를 통해 웨딩홀 캐시백 혜택을 받을 수 있습니다.</li>
				<li>잔여타임 안내 등 서비스가 진행된 후에는 이력 삭제가 불가합니다.</li>
			</ul>
			<div className="agreement-check-wrap">
				<Checkbox 
					id="agreement"
					name="agreement"
					value="agreement"
					setValue={() => setAgreement(!agreement)}
					checked={agreement}
				/>
				
				<label className="agreement-label">
					{isWebview()
					? <>
						<a href={isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80')}>개인정보 수집</a> 및 <a href={isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80')}>제3자 활용</a>을 모두 읽었으며 이에 동의합니다.
					</>
					: <>
						<a href="https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80" target="_blank">개인정보 수집</a> 및 <a href="https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80" target="_blank">제3자 활용</a>을 모두 읽었으며 이에 동의합니다.
					</>
					}
					
				</label>
			</div>
			<div style={{padding: '20px 0'}}>
				<button disabled={!agreement} className={`btn btn-large btn-full btn-red ${!agreement ? 'btn-disabled' : ''}`} onClick={() => onApply()}>서비스 신청하기</button>
			</div>
		</BottomFixedModal>
	);
};

export default AgreementModal;