import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import NotiBox from '../../../components/common/notiBox/notiBox';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';
import { setExpectedPerson } from '@src/@slice/recommendHallSlice';

const visitorItems = ['~150', '150~200', '200~250', '250~300', '300~'];
const Visitor = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {hallData, dispatch} = useRecommendHall();
	useEffect(() => {
		const expectedPerson = hallData.expectedPerson;
		setValidate((expectedPerson.min !== null || expectedPerson.max !== null))
	}, [hallData.expectedPerson]);
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === '' || hallData.weddingRegions.length === 0
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	const goNext = () => {
		navigate('/recommend/hall/meal-type');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`예상 하객 인원수를
						선택해 주세요.`}
						type="중복 선택 불가"
					/>
					<div className="common-wrap">
						<div style={{height: 20}} />
						<ul className="selectable-list-wrap items-3">
							{visitorItems.map(item => {
								const splitVal = item.split('~');
								const val0 = splitVal[0];
								const val1 = splitVal[1];
								const min = hallData.expectedPerson.min ? hallData.expectedPerson.min : '';
								const max = hallData.expectedPerson.max ? hallData.expectedPerson.max : '';
								return (
									<li key={`visitor-item-${item}`}>
										<div 
											className={`${val0}~${val1}` === `${min}~${max}` ? 'on' : ''}
											onClick={() => dispatch(setExpectedPerson({
												min: val0 ? +val0 : null,
												max: val1 ? +val1 : null
											}))}
										>
											{(val0 && val1) &&
												<>
												{val0 ? `${val0}명` : ''} ~ {val1 ? `${val1}명` : ''}
												</>
											}
											{val0 === '' &&
											<>{val1} 미만</>
											}
											{val1 === '' &&
											<>{val0} 이상</>
											}
										</div>
									</li>
								)
							})}
						</ul>
					</div>

					<NotiBox>
						<p>
						하객 인원수 선택 시 참고해 주세요.
						<ul>
							<li>
								예식을 가장 많이 진행하는 강남구, 서초구의 웨딩홀의 토요일 점심 예식은 대부분 250명~300명 이상 보증해 주셔야 상담이 가능합니다.
							</li>
							<li>
								예식 날짜와 시간대에 따라 보증인원이 달라집니다.
							</li>
						</ul>
						</p>
					</NotiBox>
				</div>
				<div>
					<LinkWegazine
						title="하객 인원수에 대해 잘 모르겠다면?"
						link="https://www.weddingbook.com/wegazine/116419fe-6dfc-11ec-b0a5-0aea5320e7f2?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Visitor;