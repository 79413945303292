import { Fragment } from 'react';

const DetailQnA = ({data, idx}: any) => {
	var qnas = data.qnas;
	return <>
    <h3 className="product-sub-title type3">
        <span>자주 묻는 질문</span>
    </h3>
    {qnas !== null && qnas.length > 0 &&
    <dl className="product-cashback-faq" style={{marginBottom: data.bottomMargin}}>
        {qnas.map((qna: any, QIdx: number) => (
        <Fragment key={`${idx}-qna-${QIdx}`}>
        <dt className="colored">
            <span className="question-number">Q {QIdx+1}.</span>
            <div>{qna.q}</div>
        </dt>
        <dd>
            <span className="answer-number">A {QIdx+1}.</span>
            <div>{qna.a}</div>
        </dd>
        </Fragment>
        ))}
    </dl>
    }
    </>;
};

export default DetailQnA;