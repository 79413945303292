import './productCuration.scss';
/**COMMON */
import ChevronRightSvg from '../../../stories/assets/ic_chevronright.svg';
import { isExternalUrl, addComma, HOSTNAME } from '../../../utils';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import { logEvent } from '@src/firebase';

type CurationItemProps = {
	page: string;
	pageSub?: string;
	productNo: string;
	partnerUuid: string;
	imgUrl?: string;
	partnerName: string;
	title: string;
	salePrice: number;
	originPrice: number;
	dcRate: number;
	isHorizontal?: boolean;
	isSalePrice: boolean;
	isDiscount: boolean;
	isPartner: boolean;
	isListPrice: boolean;
};

type BannerCurationProps = {
	imgUrl?: string;
};
const CurationItem = ({
	page,
	pageSub,
	productNo,
	partnerUuid,
	imgUrl,
	partnerName,
	title,
	salePrice,
	originPrice,
	dcRate,
	isHorizontal,
	isSalePrice,
	isDiscount,
	isPartner,
	isListPrice,
}: CurationItemProps) => {
	const clickItem = (title: string) => {
		if (!isPartner) {
			const obj = {
				page: page,
				pageSub: pageSub,
				component: 'product_curation',
				behavior: 'click',
				action: 'to_mall',
				title: title
			}
			if (pageSub === undefined) delete obj.pageSub;
			logEvent('component_behavior', obj);
			window.location.href = `h2mwbell://product/mall/${productNo}`;
		}
	}
	const clickBg = (title: string) => {
		if (isPartner) {
			const obj = {
				page: page,
				pageSub: pageSub,
				component: 'product_curation',
				behavior: 'click',
				action: 'to_mall',
				title: title
			}
			if (pageSub === undefined) delete obj.pageSub;
			logEvent('component_behavior', obj);
			window.location.href = `h2mwbell://product/mall/${productNo}`;
		}
	}
	return (
		<div className="curation-item">
			<div className="item-inner" onClick={() => clickItem(title)}>
				<div className={`row-item-bg ${!imgUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${imgUrl})`}} onClick={() => clickBg(title)} />
				<div className="row-item-info-wrap">
					{!isPartner &&
					<span className="item-partner">{partnerName}</span>	
					}
					<span className="item-title">{title}</span>
					<div className="item-price-wrap">
						{(isDiscount && dcRate !== 0) &&
						<span className="dc-rate">{Math.floor(dcRate * 100)}%</span>
						}
						{isSalePrice &&
						<span className="sale-price">{addComma(salePrice > 0 ? salePrice : originPrice)}원</span>
						}
						{(isListPrice && !isHorizontal && dcRate !== 0) &&
						<span className="origin-price">{addComma(originPrice)}원</span>
						}
					</div>
					{isPartner && (
						<a href={`h2mwbell://partner/${partnerUuid}`} className="item-link-partner" onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'product_curation',
								behavior: 'click',
								action: 'to_partner',
								title: '바로가기'
							}
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
						}}>
							<span>{partnerName}</span>
							<img src={ChevronRightSvg} alt="바로가기" />
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

const BannerCuration = ({
	imgUrl
}: BannerCurationProps) => {
	return (
		<div className="curation-banner-wrap">
			<div className={`banner-bg ${!imgUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${imgUrl})`}} />
		</div>
	);
};

type Product = {
	productNo: string;
	partnerUuid: string;
	partnerId: number;
	partnerName: string;
	productId: number;
	productName: string;
	productDcRate: number;
	productPrice: number;
	productDcPrice: number;
	productTitle: string;
	productThumbnail: string;
};

type IData = {
	direction: string;
	display: {
		partnerName: boolean;
		discountRate: boolean;
		salePrice: boolean;
		listPrice: boolean;
	};
	listType: string;
	mainImageUrl: null;
	moreViewButton: { partnerName: string; name: string; link: string };
	products: Product[];
	mainTitle: string;
	subTitle: string;
	titleType: number;
	title: ITitle;
};

interface ProductCurationProps {
	id: number;
	page: string;
	pageSub?: string;
	data: IData;
}

export function ProductCuration({ id, page, pageSub, data }: ProductCurationProps) {
	let isHorizontal = true;
	if (data.listType === 'NO_MAIN') {
		if (data.direction === 'VERTICAL') {
			isHorizontal = false;
		}
	}
	return (
		<section className="product-curation-wrap">
			{data.listType === 'WITH_MAIN' &&
			<BannerCuration imgUrl={data?.mainImageUrl!} />
			}
			<SectionTitle title={data.title}/>
			{/* <div className="curation-title-wrap">
				<span className="curation-title">{data.mainTitle}</span>
				<p className="curation-description">{data.subTitle}</p>
			</div> */}
			<div className={`product-curation-list-wrap ${!isHorizontal ? 'vertical' : ''}`}>
				{data?.products.map((item, idx) => {
					return (
						<CurationItem
							page={page}
							pageSub={pageSub}
							productNo={item.productNo}
							partnerUuid={item.partnerUuid}
							imgUrl={item.productThumbnail}
							isHorizontal={isHorizontal}
							partnerName={item.partnerName}
							title={item.productTitle}
							salePrice={item.productDcPrice}
							originPrice={item.productPrice}
							dcRate={item.productDcRate}
							isListPrice={data?.display.listPrice}
							isSalePrice={data?.display.salePrice}
							isDiscount={data?.display.discountRate}
							isPartner={data?.display.partnerName}
							key={`product-curation-${id}-${idx}`}
						/>
					);
				})}
			</div>
			{data.moreViewButton.link &&
			<div className="more-button-wrap">
				<button className="btn">
					<a href={isExternalUrl(data.moreViewButton.link)}>
						<span>{data.moreViewButton.partnerName}</span> {data.moreViewButton.name}
					</a>
				</button>
			</div>
			}
			
		</section>
	);
}
