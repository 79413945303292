import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface HoneymoonApplyState {
	isComplete: boolean;
}

const initialState = {
	isComplete: false
}  as HoneymoonApplyState;

const honeymoonApplyState = createSlice({
	name: 'recommend',
	initialState,
	reducers: {
		setIsComplete(state, action: PayloadAction<{bool: boolean}>) {
			state.isComplete = action.payload.bool;
		}
	}
});


export const { 
	setIsComplete,
 } = honeymoonApplyState.actions;
export const isComplete = (state: RootState) => state.honeymoonApplyState.isComplete;
export default honeymoonApplyState.reducer;

