import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { setHallStyleTypes } from '@src/@slice/recommendHallSlice';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';
import Blank from '../../../components/common/blank/Blank';

const styleItems = ['일반 컨벤션', '호텔 예식', '하우스', '채플', '야외', '한옥'];
const Style = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {dispatch, hallData} = useRecommendHall();
	useEffect(() => {
		setValidate(hallData.hallStyleTypes.length > 0);
	}, [hallData.hallStyleTypes]);
	const goNext = () => {
		navigate('/recommend/hall/type');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`선호하는 웨딩홀 스타일을 
						모두 선택해 주세요.`}
						type="중복 선택 가능"
					/>
					<div className="common-wrap">
						<div style={{height: 20}} />
						<ul className="selectable-list-wrap items-3">
							{styleItems.map(item => (
							<li key={`style-item-${item}`}>
								<div 
									className={hallData.hallStyleTypes.indexOf(item) > -1 ? 'on' : ''}
									onClick={() => dispatch(setHallStyleTypes({type: item}))}
								>
									{item}
								</div>
							</li>	
							))}
						</ul>
					</div>
				</div>
				<div>
					<LinkWegazine 
						title="웨딩홀 스타일에 대해 잘 모르겠다면?"
						link="https://www.weddingbook.com/wegazine/e162b829-8648-11e8-bc23-0ab211c9f708?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Style;