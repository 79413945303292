import { Dispatch, SetStateAction } from 'react';

interface CheckBoxProps {
	id: string;
	size?: 'small' | 'medium' | 'large';
	name: string;
	value: string;
	setValue: Dispatch<SetStateAction<string>> | ((val:string) => void);
	checked: boolean;
}
const Checkbox = ({id, size = 'medium', name, value, setValue, checked}: CheckBoxProps) => {
	return (
		<div className={`input-checkbox-wrap ${size}`}>
			<input id={id} type="checkbox" name={name} className="input-checkbox only-sr" value={value} checked={checked} onChange={() => setValue(value)} />
			<label htmlFor={id}></label>
		</div>
	)
}

export default Checkbox;
