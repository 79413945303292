import { useState, useEffect, useContext } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTab, {ITab} from './../../../components/home/pageTab/PageTab';
import { answerReservation, getReservationList } from './../../../api/booking/booking';
import { UserContext } from './../../../App';
import { useNavigate } from 'react-router-dom';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import axios from '@src/api/axios';
import { BASE_URL, PORTAL_URL, isExternalUrl } from '@src/utils';
import SwipeBannerComponent from './../../../components/home/swipeBanner/SwipeBanner';
import IconNew from '@src/assets/images/icon/ic-new-red@3x.png';
import moment from 'moment';
import 'moment/locale/ko';
import Modal from '@src/components/common/modal/Modal';
import { HOSTNAME } from '../../../utils/index';
moment.locale('ko'); // 자주 쓰면 전역 모멘트 설정 해두자

type STATE_MAP = {
	name?: string;
	showName?: string;
	color?: string;
}
const CONFIRM_STATE_MAP: {[key in number]: STATE_MAP} = {
	0: {name: '대기', showName: '확인중', color: '#18B698'},
	1: {name: '승인'},
	2: {name: '반려', showName: '취소', color: '#9D7D54'},
	3: {name: '취소', showName: '취소', color: '#9D7D54'},
	9: {name: '예약 보류'},
	10: {name: '인폼삭제', showName: '취소', color: '#9D7D54'},
	11: {name: '일정취소', showName: '취소', color: '#9D7D54'},
	12: {name: '임시예약'},
	13: {name: '주단위', showName: '예약대기', color: '#18B698'},
	14: {name: '미오픈', showName: '예약대기', color: '#18B698'},
	16: {name: '예약 보류(주단위 마감)'},
	17: {name: '확인 완료', showName: '확인중', color: '#18B698'},
	18: {name: '취소사유 확인중', showName: '취소 사유 확인 중', color: '#F53F29'},
	19: {name: '취소', showName: '취소', color: '#9D7D54'},
}
interface IHallList {
	item: any;
	currentTabId: number;
	currentSubTabId: number;
	prepareData: () => void;
	showAnswerModal: () => void;
}
const HallList = ({item, currentTabId, currentSubTabId, prepareData, showAnswerModal}: IHallList) => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [answer, setAnswer] = useState(''); // 컴포넌트 분리 필요 
	const [isLoading, setIsLoading] = useState(false);

	const getStateInfo = (item: any) => {
		let state: number = item.confirmState;
		if (state === null) state = 0;
		const data = CONFIRM_STATE_MAP[state];
		if (!data) return;
		if (data.showName) {
			return data;
		} else {
			const result: STATE_MAP = {};
			if (state === 1) {
				result.color = '#9D7D54';
				result.showName = item.isGoneVisitTsp ? '방문 완료' : '예약 완료';
			}
			if (state === 9 || state === 12 || state === 16) {
				// 답변 완료, 필요로 나뉨
				if (item.customerGuideComment !== null) {
					result.showName = '답변 완료';
					result.color = '#9D7D54';
				} else {
					result.showName = '답변 필요';
					result.color = '#F53F29'
				}
			}
			return result;
		}
	}
	const postAnswer = (uuid: string) => {
		if (!user.me) return;
		if (isLoading) return;
		if (answer.trim() === '') {
			showAnswerModal();
			return;
		}
		setIsLoading(true);
		answerReservation({
			uuid: uuid,
			content: answer,
			customerUuid: user.me.uuid
		}).then(_ => {
			prepareData();
			setIsLoading(false);
		}).catch(_ => {
			setIsLoading(false);
		})
	}
	return (
		<li>
			<div className="hall-info-wrap">
				<div className="hall-thumb" style={{backgroundImage: `url(${item.receiverCoverUrl})`}}/>
				<div>
					<span className="hall-name">{item.receiverName}</span>
					<div>
						<span className={`status`} style={{color: getStateInfo(item)?.color}}>{getStateInfo(item)?.showName}</span>
						{/* WP-9 이슈로 인해 삭제
						{currentTabId === 1 && 
						<span className="date-info">{moment(item.regTsp).format('YYYY-MM-DD(dd)')}</span>
						} */}
						{(currentTabId === 2 || currentTabId === 3) && 
						<span className="date-info">{moment(item.visitTsp).format('YYYY-MM-DD(dd) HH:mm')}</span>
						}
					</div>
				</div>
			</div>
			{(item.guideComment && ![null, 0, 3, 17].includes(item.confirmState)) &&
			<div className="hall-answer-wrap">
				<p>{item.guideComment}</p>
				{(currentTabId === 1 && currentSubTabId === 2) &&
				<>
				{item.customerGuideComment
				? <div className="hall-my-answer">
					<span>내 답변</span>
					<p>{item.customerGuideComment}</p>
				</div>
				: <input
					type="text" className="input-text input-full input-has-line" 
					style={{marginTop: 12}}
					placeholder="답변을 입력해주세요."
					value={answer}
					onChange={e => setAnswer(e.target.value)}
				/>
				}
				</>
				}
			</div>
			}
			{currentTabId === 1 &&
			<>
				{currentSubTabId === 1 &&
				<button className="btn btn-full btn-light-gray" onClick={() => navigate(`/booking/hall/cancel/${item.uuid}?partnerName=${item.receiverName}`)}>취소하기</button>
				}
				{(currentSubTabId === 2 && item.customerGuideComment === null) &&
				<button className="btn btn-full btn-light-gray" onClick={() => postAnswer(item.uuid)}>
					답변하기
				</button>
				}
				{currentSubTabId === 3 &&
				<button className="btn btn-full btn-light-gray" onClick={() => {
					window.location.href = 'h2mwbell://chat';
				}}>문의하기</button>
				}
			</>
			}
			{currentTabId === 2 &&
			<div className="btn-wrap items-2" style={{paddingBottom: 0}}>
				<div>
				<button className="btn btn-full btn-light-gray" onClick={() => navigate(`/booking/hall/cancel/${item.uuid}?partnerName=${item.receiverName}`)}>예약 취소하기</button>
				</div>
				<div>
				<button className="btn btn-full btn-light-gray" onClick={() => navigate(`/booking/hall/change/${item.uuid}?partnerName=${item.receiverName}`)}>일정 변경하기</button>
				</div>
			</div>
			}
			{currentTabId === 3 &&
			<button className="btn btn-full btn-light-gray" onClick={() => {
				window.location.href = `h2mwbell://webview?url=${PORTAL_URL}/app/cashback`;
			}}>캐시백 신청하기</button>
			}
		</li>
	)
}

const EMPTY_MESSAGE: {[key in number]: string | {[key in number]: string}} = {
	1: {
		1: '확인 중인 웨딩홀이 없습니다.',
		2: '답변 필요한 웨딩홀이 없습니다.',
		3: '예약 대기 중인 웨딩홀이 없습니다.'
	},
	2: '방문 예정인 웨딩홀이 없습니다.',
	3: '방문 완료한 웨딩홀이 없습니다.',
	4: '취소한 웨딩홀이 없습니다.',
}
const Management = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [tab, setTab] = useState<ITab[]>([
		{tabId: 1, tabTitle: '접수중'}, // state: subTab
		{tabId: 2, tabTitle: '방문예정'}, // state : 1, 18
		{tabId: 3, tabTitle: '방문완료'}, // state : 1 (방문일 지난것)
		{tabId: 4, tabTitle: '취소'} // state : 10, 11, 2, 3
	]);
	// const [subTab, setSubTab] = useState([
	// 	{id: 1, title: '확인 중'}, // state : 0, 17
	// 	{id: 2, title: '답변필요'}, // state : 9, 16, 12
	// 	{id: 3, title: '예약대기'} // state : 13, 14
	// ]);
	const query = useUrlQueryString();
	const [currentTabId, setCurrentTabId] = useState<number>(+query.get('tabId')! || 1);
	const [currentSubTabId, setCurrentSubTabId] = useState<number>(+query.get('subTabId')! || 1);
	const [allList, setAllList] = useState<any[]>([]);
	const [waitingList, setWaitingList] = useState<{[key in string]: any[]}>({
		1: [],
		2: [],
		3: []
	});
	const [visitList, setVisitList] = useState<any[]>([]);
	const [cancelList, setCancelList] = useState<any[]>([]);
	const [bannerData, setBannerData] = useState<any>(null);
	const [answerModal, setAnswerModal] = useState(false);
	const [hideNew, setHideNew] = useState({
		visitList: +query.get('tabId')! === 2 ? true : false,
		waitingList: (+query.get('tabId')! === 1 && +query.get('subTabId')! === 3) ? true : false
	});
	const getCurrentList = () => {
		switch (currentTabId) {
			case 1:
				return waitingList[currentSubTabId];
			case 2:
				return visitList.filter(item => item.isGoneVisitTsp === false);
			case 3:
				return visitList.filter(item => item.isGoneVisitTsp === true);
			case 4:
				return cancelList
		}
	}
	const getListCount = (tabId: number) => {
		switch (tabId) {
			case 1:
				return waitingList[1].length + waitingList[2].length + waitingList[3].length;
			case 2:
				return visitList.filter(item => item.isGoneVisitTsp === false).length;
			case 3:
				return visitList.filter(item => item.isGoneVisitTsp === true).length;
			case 4:
				return cancelList.length;
		}
	}
	const getNeedAnswerList = () => {
		return waitingList[2].filter(list => list.guideComment !== null && list.customerGuideComment === null);
	}
	const prepareData = async () => {
		if (user.me) {
			const {data} = await getReservationList(user.me.uuid);
			const list = data.data;
			setWaitingList(_ => {
				return {
					1: list.filter(item => item.confirmState === 0 || item.confirmState === null || item.confirmState === 17),
					2: list.filter(item => item.confirmState === 9 || item.confirmState === 12 || item.confirmState === 16),
					3: list.filter(item => item.confirmState === 13 || item.confirmState === 14)
				};
			});
			const vList = [...list.filter(item => item.confirmState === 18).sort((a, b) => moment(a.visitTsp).valueOf() - moment(b.visitTsp).valueOf()), ...list.filter(item => item.confirmState === 1)];
			if (vList) setVisitList(_ => vList);
			setCancelList(_ => list.filter(item => item.confirmState === 2 || item.confirmState === 3 || item.confirmState === 10 || item.confirmState === 11 || item.confirmState === 19));
			setAllList(_ => list);
		}
	};
	useEffect(() => {
		axios.get<{data: any}>(`${BASE_URL}/web/v1/weddinghall/reservation/component`)
			.then(res => {
				const data = res.data.data;
				if (data) {
					console.log(JSON.parse(data.jsonStr));
					setBannerData(JSON.parse(data.jsonStr));
				}
			});
	}, []);
	useEffect(() => {
		prepareData();
	}, [user]);
	useEffect(() => {
		setTab(_ => [
			{tabId: 1, tabTitle: '접수중 ' + getListCount(1)},
			{tabId: 2, tabTitle: '방문예정 ' + getListCount(2), needCheck: (visitList.filter(item => item.isGoneVisitTsp === false && item.isNew).length > 0 && !hideNew.visitList)},
			{tabId: 3, tabTitle: '방문완료 ' + getListCount(3)},
			{tabId: 4, tabTitle: '취소 ' + getListCount(4)}
		]);
	}, [allList, hideNew])
	useEffect(() => {
		const appHeader = query.get('appHeader');
		navigate(`/booking/hall/management?tabId=${currentTabId}&subTabId=${currentSubTabId}&appHeader=${appHeader ? appHeader : 'show'}`, {replace: true});
	}, [currentTabId, currentSubTabId]);
	useEffect(() => {
		navigate('/booking/management/list', {replace: true});
	}, []);
	return (
		<>
		<AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} />
		<div className="page-booking-hall-management">
			<div className="hall-management-title-wrap" >
				<h2>웨딩홀 예약관리</h2>
				<button className="btn bold btn-round btn-light-gray" onClick={() => window.location.href = isExternalUrl(`${HOSTNAME}/booking/hall`)}>예약 신청</button>
			</div>
			<PageTab 
				tab={tab}
				currentTabId={currentTabId}
				onChange={tabId => {
					if (currentTabId !== tabId) {
						setCurrentTabId(tabId)
					}
					if (tabId === 2) {
						setHideNew({...hideNew, visitList: true});
						axios.post(`${BASE_URL}/web/v1/weddinghall/reservation/schedule/openTsp`);
					}
				}}
				key={`page-tab-${'1'}`}
			/>
			{currentTabId === 1 &&
			<>
			{bannerData &&
			<>
			<SwipeBannerComponent
				id={999}
				page={'HallReservationManagement'}
				title={bannerData.title}
				isCustomWidth={bannerData.isCustomWidth}
				items={bannerData.item}
				top={bannerData.top}
				bottom={bannerData.bottom}
				rolling={bannerData.rolling}
			/>
			<div style={{height: '12px'}}></div>
			</>
			}
			<div className="waiting-filter-wrap">
				<button className={`btn btn-auto-width btn-small ${currentSubTabId === 1 ? 'btn-line-dark' : 'btn-line-gray'}`} onClick={() => setCurrentSubTabId(1)}>
					확인 중 {waitingList[1].length}
				</button>
				<button className={`btn btn-auto-width btn-small ${currentSubTabId === 2 ? 'btn-line-dark' : 'btn-line-gray'}`} onClick={() => setCurrentSubTabId(2)}>
					답변필요 {waitingList[2].length}
					{getNeedAnswerList().length > 0 && <img src={IconNew} alt="New" width={11} style={{marginLeft: 10}} />}
				</button>
				<button className={`btn btn-auto-width btn-small ${currentSubTabId === 3 ? 'btn-line-dark' : 'btn-line-gray'}`} onClick={() => {
					setCurrentSubTabId(3);
					setHideNew({
						...hideNew,
						waitingList: true
					});
					axios.post(`${BASE_URL}/web/v1/weddinghall/reservation/wait/openTsp`);
				}}>
					예약대기 {waitingList[3].length}
					{(
						waitingList[3].filter(list => list.isNew).length > 0 && !hideNew.waitingList
					) && <span className="new-dot"></span>}
				</button>
			</div>
			<div className="waiting-notice-wrap">
				{currentSubTabId === 1 && <p>웨딩홀 매니저가 고객님의 예약을 확인중입니다. 🤗</p>}
				{currentSubTabId === 2 && <p>🤔 자주 묻는 질문 <a href="h2mwbell://wegazine/9a5579d5-1de9-11ed-ab0f-0a8156bf2d4c">왜 답변이 필요한가요? </a></p>}
				{currentSubTabId === 3 && <p>🤔 자주 묻는 질문 <a href="h2mwbell://wegazine/a6cc9e1c-1de9-11ed-ab0f-0a8156bf2d4c">왜 예약이 대기중인가요?</a></p>}
			</div>
			</>
			}
			{getListCount(currentTabId) === 0
			? <p className="hall-empty-message">
				{typeof EMPTY_MESSAGE[currentTabId] === 'string' ? EMPTY_MESSAGE[currentTabId] : EMPTY_MESSAGE[currentTabId][currentSubTabId]}
			</p>
			: <ul className="hall-list-wrap">
				{getCurrentList()?.map(item => (
					<HallList 
						item={item}
						currentTabId={currentTabId}
						currentSubTabId={currentSubTabId}
						prepareData={() => prepareData()}
						showAnswerModal={() => setAnswerModal(true)} key={`hall-list-${item.uuid}`}
					/>
				))}
			</ul>
			}
			{getNeedAnswerList().length > 0 &&
			<div className="need-answer-popup-wrap" onClick={() => {
				setCurrentTabId(1);
				setCurrentSubTabId(2);
			}}>
				<div>
					<i className="ttnorms-bold">N</i>
					답변이 필요한 예약이 {getNeedAnswerList().length}개 있습니다.
				</div>
				<span className="bold">보기</span>
			</div>
			}
		</div>
		<Modal
			isShow={answerModal}
			text={`답변 내용을 입력해주세요.`}
			onClose={() => {
				setAnswerModal(false);
			}}
		/>
		</>
	);
};

export default Management;