import './userReviews.scss';
import { useEffect, useRef } from 'react';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import { logEvent } from '@src/firebase';
import { isExternalUrl, HOSTNAME } from '@src/utils';

interface IProfile {
	nick: string;
	profileUrl: string;
	partnerText: string;
	weddingMonth: string;
}
interface ICard {
	content: string;
	isCard: number;
	link: string;
	coverUrl: string;
	partnerType: number;
	partnerUuid: string;
	title: string;
	type: number;
}
interface IReview {
	nick: string;
	profileUrl: string;
	partnerText: string;
	content: string;
	weddingMonth: string;
	card?: ICard;
}
interface UserReviewsProps extends ShowAllListProps {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	reviews: IReview[];
}
interface ReviewProps {
	user: IProfile;
	page: string;
	pageSub?: string;
	product?: ICard|null;
	content: string;
}
interface ShowAllListProps {
	page: string;
	pageSub?: string;
	reviewType: 'partnerUuid' | 'partnerType';
	partnerType: string | undefined;
	partnerUuid: string | undefined;
}
const ShowAllList = ({page, pageSub, reviewType, partnerType, partnerUuid}: ShowAllListProps) => {
	const list = useRef<HTMLLIElement>(null);
	useEffect(() => {
		if (list.current) {
			const li = list.current;
			if (li.previousElementSibling) {
				li.style.height = (li.previousElementSibling as HTMLLIElement).offsetHeight + 'px'; // 2 = border width: ;
			}
		}
	}, []);
	return (
		<li style={{padding: 0, maxWidth: 160}} ref={list}>
			<a href={reviewType === 'partnerUuid' ? `h2mwbell://partner/${partnerUuid}` : `h2mwbell://partner?categoryType=${partnerType}`} className="show-all-list" role="button" onClick={() => {
				const obj = {
					page: page,
					pageSub: pageSub,
					component: 'user_review',
					behavior: 'click',
					content: 'show_all'
				};
				if (pageSub === undefined) delete obj.pageSub;
				logEvent('component_behavior', obj);
			}}>
				<div className="icon-wrap">
					<i className="icon icon-swipe-more" />
				</div>
				<span>전체보기</span>
			</a>
		</li>
	)
}
const UserProfile = ({nick, profileUrl, partnerText, weddingMonth}: IProfile) => {
	return (
		<div className="user-profile-wrap">
			<i className="icon" style={
				{
					backgroundImage: `url(${profileUrl})`,
					backgroundSize: '44px', minWidth: '44px', width: '44px', height: '44px'
				}
			} />
			<div>
				<span className="user-nick">{nick}</span>
				<div>
					<span className="user-wedding-month">{weddingMonth} </span>
					<span className="user-wedding-info">{partnerText}</span>
				</div>
			</div>
		</div>
	)
}
const Review = ({user, page, pageSub, product = null, content}: ReviewProps) => {
	return (
		<li>
			<UserProfile nick={user.nick} partnerText={user.partnerText} weddingMonth={user.weddingMonth} profileUrl={user.profileUrl} />
			{product !== null &&
			<a href={`h2mwbell://partner/${product.partnerUuid}`} className="product-info" onClick={() => {
				const obj = {
					page: page,
					pageSub: pageSub,
					component: 'user_review',
					behavior: 'click'
				};
				if (pageSub === undefined) delete obj.pageSub;
				logEvent('component_behavior', obj);
			}}>
				<i className="icon" style={
					{
						backgroundImage: `url(${product.coverUrl})`,
						backgroundSize: '32px', width: '32px', height: '32px'
					}
				} />
				<span className="product-name">{product.content}</span>
			</a>
			}
			<p className="review-contents">
			{content}
			</p>
		</li>
	)
}
function UserReviews({id, page, pageSub, title, reviews, reviewType, partnerUuid, partnerType}: UserReviewsProps) {
	return (
		<section className="user-reviews-wrap">
			<SectionTitle 
				title={title}
			/>
			<ul className="review-list-wrap">
				{reviews.map((review, idx) => (
					<Review user={{
						nick: review.nick,
						profileUrl: review.profileUrl,
						partnerText: review.partnerText,
						weddingMonth: review.weddingMonth
					}} page={page} pageSub={pageSub} product={review.card ? review.card : null} content={review.content} key={`user-review-${id}-${idx}`} />
				))}
				<ShowAllList 
					page={page}
					pageSub={pageSub}
					reviewType={reviewType}
					partnerUuid={partnerUuid}
					partnerType={partnerType}
				/>
			</ul>
		</section>
	);
}

export default UserReviews;