import { useDispatch, useSelector } from 'react-redux';
import { bookingHomeAppliance } from '@src/@slice/bookingHomeApplianceSlicec';

export const useBookingHomeAppliance = () => {
	const homeAppliance = useSelector(bookingHomeAppliance);
	const homeApplianceData = homeAppliance.data;
	const isComplete = homeAppliance.isComplete;
	const dispatch = useDispatch();

	return {
		homeApplianceData,
		isComplete,
		dispatch
	}
}