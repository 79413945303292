import { useDispatch, useSelector } from 'react-redux';
import { recommendHall, recommendHallData } from '@src/@slice/recommendHallSlice';

export const useRecommendHall = () => {
	const hallData = useSelector(recommendHallData);
	const isComplete = useSelector(recommendHall).isComplete;
	const dispatch = useDispatch();

	return {
		hallData,
		isComplete,
		dispatch
	}
}