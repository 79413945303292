import { logEvent } from '@src/firebase';
import { isExternalUrl, PORTAL_URL } from '../../../utils';
import './sdmContract.scss';

interface SDMContractProps {
	page: string;
	pageSub?: string;
}
function SDMContract({page, pageSub}: SDMContractProps) {
	return (
		<section className="sdm-contract-wrap">
			<div className="sdm-contract-top">
				<b className="sdm-contract-title">스드메 계약 완료 🎉</b>
				<p className="sdm-contract-companys">계약 후 프로세스를 확인해주세요</p>
				<a href="h2mwbell://plot/2" className="link-preparation-order" onClick={() => {
					const obj = {
						page: page,
						pageSub: pageSub,
						component: 'sdm_contract',
						behavior: 'click',
						content: '준비 순서'
					};
					if (pageSub === undefined) delete obj.pageSub;
					logEvent('component_behavior', obj);
				}}>준비 순서</a>
			</div>
			<button className="btn btn-large btn-full btn-white btn-link-sdm" onClick={() => {
				const obj = {
					page: page,
					pageSub: pageSub,
					component: 'sdm_contract',
					behavior: 'click',
					content: '결제하기'
				};
				if (pageSub === undefined) delete obj.pageSub;
				logEvent('component_behavior', obj);
				window.location.href = isExternalUrl(`${PORTAL_URL}/app/sdmmall`);
			}}>중도금/잔금 결제하기</button>
			<div className="sdm-more">
				<p>
					더 필요한 상품이 있다면 <br/>
					추가로 상품을 구매할 수 있어요! 🤫  
				</p>
				<a href={isExternalUrl(`${PORTAL_URL}/app/sdmmall?redirect=product/additional`)} onClick={() => {
					const obj = {
						page: page,
						pageSub: pageSub,
						component: 'sdm_contract',
						behavior: 'click',
						content: '추가 상품보기'
					};
					if (pageSub === undefined) delete obj.pageSub;
					logEvent('component_behavior', obj);
				}}>추가 상품보기 {`>`}</a>
			</div>
		</section>
	);
}

export default SDMContract;