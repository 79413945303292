import './searchHeader.scss';
import { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeSearchKeyword } from '@src/api/search/search';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';

import xCircle from '@src/assets/images/common/x-circle-gray@3x.png';
import { UserContext } from '@src/App';
import { historyLen } from '@src/@slice/historySlice';
import { useDispatch, useSelector } from 'react-redux';

const SearchHeader = () => {
	const query = useUrlQueryString();
	const productId = query.get('productId');
	const searchKeyword = query.get('searchKeyword');
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState(searchKeyword || '');
	const user = useContext(UserContext);
	const historyLength = useSelector(historyLen);
	const goBack = () => {
		if (historyLength >= 1 || user.me === null) {
			navigate(-1);
		} else {
			window.location.href = 'h2mwbell://webview/close';
		}
	}

	// 자동 완성용 코드
	// const [searchItems, setSearchItems] = useState<any[]>([]);
	// const makeBoldStr = useCallback((str: string) => {
	// 	const regex = new RegExp(keyword, "g");
	// 	return str.replace(regex, "<b>" + keyword + "</b>");
	// }, [keyword]);
	// let timer: any;
	// const _debounceSearch = (keyword: string) => {
	// 	if (keyword.trim() === '') {
	// 		setKeyword(keyword);
	// 		setSearchItems([]);
	// 		return;
	// 	}
	// 	if (timer) {
	// 		clearTimeout(timer);
	// 	}
	// 	timer = setTimeout(async () => {
	// 		const data = await changeSearchKeyword(keyword);
	// 		setKeyword(keyword);
	// 		setSearchItems(data.data.item.items);
	// 	}, 750);
	// }
	return (
		<>
		<div className="search-header-wrap">
			<button 
				className="btn btn-icon btn-transparent btn-tight btn-auto-height" 
				style={{padding: 0}} 
				onClick={goBack}
			>
				<i className="icon icon-arrow-back-black" />
			</button>
			<div className="input-search-wrap">
				<input 
					type="search" 
					className="input-text search-area"
					value={keyword}
					placeholder={'브랜드, 업체를 검색해보세요.'}
					onKeyPress={e => {
						if (e.key.toLowerCase() === 'enter' && keyword.trim() !== '') {
							navigate(`/search/result?searchKeyword=${keyword}${productId ? `&productId=${productId}` : ''}`);
						}
					}}
					onChange={e => {
						setKeyword(e.target.value);
					}}
				/>
				<img 
					src={xCircle}
					className={`${keyword.length > 0 ? 'show' : ''}`}
					width="24" height="24"
					onClick={() => setKeyword('')}
					alt="검색어 삭제"
				/>
			</div>
			<button 
				className="btn btn-icon btn-transparent btn-tight btn-auto-height" 
				style={{padding: 0}} 
				onClick={() => {
					if (keyword.trim() !== '') {
						navigate(`/search/result?searchKeyword=${keyword}${productId ? `&productId=${productId}` : ''}`)
					}
				}}
			>
				<i className="icon icon-search-black" />
			</button>
		</div>
		{/* 자동 완성이지만 안하는거였네? */}
		{/* {(searchItems.length > 0 && keyword.trim() !== '') && 
		<div className="search-auto-complete-wrap">
			{keyword}
			<ul className="search-item-list-wrap">
				{searchItems.filter(item => item.coverUrl).map(item => (
				<li>
					<div className="cover-img"></div>
					<div>
						<div dangerouslySetInnerHTML={{__html: makeBoldStr(item.name)}}></div>
						<span>웨딩홀</span>
					</div>
				</li>
				))}
			</ul>
			<ul className="search-item-list-wrap no-cover-img">
				{searchItems.filter(item => item.coverUrl === null).map(item => (
				<li dangerouslySetInnerHTML={{__html: makeBoldStr(item.name)}}></li>
				))}
			</ul>
		</div>
		} */}
		</>
	);
};

export default SearchHeader;