import './shortcutMenu.scss';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import { isExternalUrl } from '../../../utils';
import { logEvent } from '@src/firebase';

interface ShortcutMenuItem {
	menuLink: string;
	menuName: string;
}
interface ShortcutMenuProps {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	items: ShortcutMenuItem[];
}
function ShortcutMenu({id, page, pageSub, title, items}: ShortcutMenuProps) {
	return (
		<section className="shortcut-menu-wrap">
			<SectionTitle 
				title={title}
			/>
			<ul className="shortcut-menu-item-wrap">
				{items.map((item, idx) => (
				<li key={`shortcut-${id}-${idx}`}>
					<a href={isExternalUrl(item.menuLink)} onClick={() => {
						const obj = {
							page: page,
							pageSub: pageSub,
							component: 'shortcut_menu',
							behavior: 'click',
							content: item.menuName
						};
						if (pageSub === undefined) delete obj.pageSub;
						logEvent('component_behavior', obj);
					}}>{item.menuName}</a>
				</li>
				))}
			</ul>
		</section>
	);
}

export default ShortcutMenu;