import React from "react";
import "./stepper.scss";

const Stepper = ({ steps=4, currentStep }) => {
    const stepPercentage = (currentStep / steps) * 100;

    return (
        <div className="stepper">
            <div className="step-line-container">
                <div
                    className="step-line"
                    style={{ width: `${stepPercentage}%` }}
                ></div>
            </div>
        </div>
    );
};

export default Stepper;
