import { useState } from 'react';
import { chkEmail, chkPassword, chkReferralCode } from '..';
import axios from '@src/api/axios';
import { BASE_URL } from '@src/utils/index';
import { ServerResponse } from '@src/api/axios';
import { useUserActions } from './useUserActions';

// 이메일 유효성 검사
export const useEmailValidation = () => {

    let disabled = false;

    // 이메일 중복 확인 (이메일 데이터 확인)
    const chkIsEmailUsed = async (email: string): Promise<any> => {
        if(disabled) return false;
        disabled = (true);
        const result = await new Promise<any>((resolve) => {
            axios.get<ServerResponse>(`${BASE_URL}/v3/20240404/customer/check/id?loginId=${encodeURIComponent(email)}`)
                .then(response => {
                    if (response.data.result === 0) {
                        resolve({ result: 'error', type: 'dataValid', label: 'Duplicate Entry', message: response.data.msg || '이미 등록된 이메일입니다.' });
                        //이미 등록된 이메일입니다.
                    } else if (response.data.result === 1) {
                        resolve({ result: 'success', type: 'dataValid', label: 'Valid Input', message: response.data.msg || '사용 가능한 이메일입니다.' })
                        //사용 가능한 이메일입니다.
                    }
                }).catch(e => {
                    console.log(e);
                    resolve({ result: 'error', type: 'dataValid', label: 'Server Error', message: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.' });
                })
        });
        setTimeout(() => {
            disabled = (false);
        },1000);
        return result;
    }

    // 이메일 유효성 검사 (이메일 형식 확인)
    const validate = (value: string) => {
        if (! value) {
            return {result: 'error', type: 'formatValid', label: 'Missing Input', message: '이메일을 입력해주세요.'};
        } else if (!chkEmail(value)) {
            return {result: 'error', type: 'formatValid', label: 'Invalid Format', message: '올바른 이메일을 입력해주세요.'};
        } else {
            return {result: 'success', type: 'formatValid', label: 'Valid Format', message: ''};
        }
    }

    // 비밀번호 찾기 시 이메일 확인
    const findPassword = async (email: string) => {
        if(disabled) return false;
        disabled = (true);
        const result = await new Promise<any>((resolve) => {
            axios.get<ServerResponse>(`${BASE_URL}/v3/20240404/customer/find/loginPwd?loginId=${encodeURIComponent(email)}`)
                .then(response => {
                    if (response.data.result === 0) {
                        resolve({ result: 'error', type: 'dataValid', label: 'Unregistered Account', message: response.data.msg, item: response.data.item});
                    } else if (response.data.result === 1) {
                        resolve({ result: 'success', type: 'dataValid', label: 'Registered Account', message: response.data.msg, item: response.data.item});
                    }
                }).catch(e => {
                    console.log(e);
                    resolve({ result: 'error', type: 'dataValid', label: 'Server Error', message: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.' });
                });
        });
        setTimeout(() => {
            disabled = (false);
        },1000);
        return result;
    }

    return {
        chkIsEmailUsed,
        validate,
        findPassword
    };
}

// 비밀번호 유효성 검사
export const usePasswordValidation = () => {

    const { loginAction } = useUserActions();
    let disabled = false;

    // 로그인 처리 (비밀번호 데이터 확인)
    const verifyPassword = async (email: string, password: string): Promise<any> => {
        if(disabled) return false;
        disabled = (true);
        const result = await new Promise<any>((resolve) => {
            loginAction(email, password).then((response:ServerResponse) => {
                if (response.result === 0) {
                    resolve({ result: 'error', type: 'dataValid', label: 'InValid Input', message: response.msg })
                } else if (response.result === 1) {
                    resolve({ result: 'success', type: 'dataValid', label: 'Valid Input', message: '' })
                }
            }).catch(e => {
                console.log(e);
                resolve({ result: 'error', type: 'dataValid', label: 'Server Error', message: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.' })
            })
        });
        setTimeout(() => {
            disabled = (false);
        },1000);
        return result;
    }

    // 비밀번호 유효성 검사 (비밀번호 형식 확인)
    const validate = (value: string) => {
        const chkPasswordResult = chkPassword(value);
        if (Object.values(chkPasswordResult).every((v: boolean) => v)) {
            return { result: 'success', type: 'all', label: 'valid Format', message: '올바른 비밀번호입니다.' };
        } else {
            const typeMessage = `${!chkPasswordResult.alpha ? 'alpha/' : ''}${!chkPasswordResult.number ? 'number/' : ''}${!chkPasswordResult.length ? 'length/': ''}`.slice(0, -1);
            return { result: 'error', type: 'all', label: `Invalid Format (${typeMessage})`, message: '6자리 이상의 영문, 숫자 조합 비밀번호를 입력해주세요.' };
        }
    }

    return {
        verifyPassword,
        validate
    };
};

// 초대코드 유효성 검사
export const useReferralCodeValidation = () => {

    let disabled = false;
    
    // 등록된 초대코드인지 확인 (초대코드 데이터 확인)
    const verifyReferralCode = async (referralCode: string): Promise<any> => {
        if(disabled) return false;
        disabled = (true);
        const result = await new Promise<any>((resolve) => {
            axios.get<ServerResponse>(`${BASE_URL}/v3/20240404/customer/invitationCode/${referralCode}/available`)
                .then(response => {
                if (response.data.result === 0) {
                    resolve({ result: 'error', type: 'dataValid', label: 'Unregistered Account', message: '유효하지 않은 초대코드입니다. 다시 확인해 주세요.' });
                    // 유효하지 않은 초대코드입니다. 다시 확인해 주세요.
                } else if (response.data.result === 1) {
                    resolve({ result: 'success', type: 'dataValid', label: 'Registered Account', message: '사용 가능한 초대코드입니다.' })
                }
                resolve(response.data);
            }).catch(e => {
                console.log(e);
                resolve({ result: 'error', type: 'dataValid', label: 'Server Error', message: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.' })
            })
        });
        setTimeout(() => {
            disabled =(false);
        },1000);
        return result;
    }


    // 초대코드 유효성 검사 (초대코드 형식 확인)
    const validate = (value: string) => {
        if (value.length === 0) {
            return {result: 'error', type: 'formatValid', label: 'Missing Input', message: '초대코드를 입력해주세요'};
        } else if (!chkReferralCode(value)) {
            return {result: 'error', type: 'formatValid', label: 'Invalid Format', message: '올바르지 않은 초대 코드입니다. 다시 확인해주세요.'};
        } else {
            return {result: 'success', type: 'formatValid', label: '', message: ''};
        }
    }

    return {
        verifyReferralCode,
        validate
    };
};

// 닉네임 유효성 검사
export const useNickNameValidation = () => {

    let disabled = false;

    // 닉네임 중복 확인 (닉네임 데이터 확인)
    const chkIsNickNameUsed = async (nickname: string): Promise<any> => {
        if(disabled) return false;
        disabled = (true);
        const url = `${BASE_URL}/v3/20240404/customer/nick/available?nick=${encodeURIComponent(nickname)}`;
        const result = await new Promise<any>((resolve) => {
            axios.get<ServerResponse>(url, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.data.result === 0) {
                    resolve({ result: 'error', type: 'dataValid', label: 'Duplicate Entry', message: '이미 사용중인 닉네임입니다.' });
                } else {
                    resolve({ result: 'success', type: 'dataValid', label: 'Valid Input', message: '사용 가능한 닉네임입니다.' });
                }
            }).catch(e => {
                console.log(e.response.status);
                if (e.response.status !== 401) {
                   resolve({ result: 'error', type: 'dataValid', label: 'Server Error', message: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.' })
                } else {
                    resolve({ result: 'error', type: 'dataValid', label: 'Unauthorized', message: '로그인 후 이용해주세요.' });
                }
            })
        });
        setTimeout(() => {
            disabled = (false);
        },1000);
        return result;
    }

    return {
        chkIsNickNameUsed
    };

}

// 인증번호 유효성 검사
export const useAuthCall = () => {

    let disabled = false;
    let disabled2 = false;

    // 인증번호 전송 API 호출 함수
    const sendTel = async (tel: string, type: any = {type: '', data: ''}) => {
        if (disabled) return false;
        disabled = true;
        const data = type.type !== 'default' ? { tel: tel, type: type.type } : { tel: tel };
        if (type.data) {
            data['k'] = type.data;
        }
        const response = await axios.post<ServerResponse>(`${BASE_URL}/v3/20240404/auth/call?${new URLSearchParams(data).toString()}`, data);
        setTimeout(() => {
            disabled = false;
        },5000);
        return response;
    }

    // 인증번호 확인 API 호출 함수
    const confirmCode = async (tel: string, token: string, type: any) => {
        if (disabled2) return false;
        disabled2 = (true);
        const data = {
            tel: tel,
            token: token
        };
        let response:any;
        if (type.type === 'findLoginId') {
            response = await axios.get<ServerResponse>(`${BASE_URL}/v3/20240404/customer/find/loginId?${new URLSearchParams(data).toString()}`);
        } else if (type.type === 'default') {
            response = await axios.put<ServerResponse>(`${BASE_URL}/v3/20240404/auth/confirm?${new URLSearchParams(data).toString()}`, data);
        }
        setTimeout(() => {
            disabled2 = (false);
        },1000);
        return response;
    }

    return {
        sendTel,
        confirmCode,
        // confirmCodeForFindLoginId
    };
}
