import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
// import AppHeader from '@src/components/common/appHeader/AppHeader';
import { BASE_URL, chkPhoneTel } from '@src/utils';
import Modal from '@src/components/common/modal/Modal';
import { autoHypenPhone } from './../../utils/index';
import PageTitle from './../../components/common/pageTitle/PageTitle';
import Checkbox from './../../components/common/checkbox/Checkbox';
import { UserContext } from '@src/App';

const VipApply = () => {
	const user = useContext(UserContext);
	const [isAlready, setIsAlready] = useState(false);
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [agreement, setAgreement] = useState(false);
	const [address, setAddress] = useState({
		add1: '',
		add2: '',
		add3: ''
	});
	const [modalState, setModalState] = useState({
		alertModal: false
	});
	const [errorText, setErrorText] = useState('');
	const validate = () => {
		if (!agreement) {
			setErrorText('정보 제공에 동의해주세요');
			return false;
		}
		if (name.trim() === '') {
			setErrorText('이름을 입력해주세요');
			return false;
		}
		if (tel.trim() === '') {
			setErrorText('연락처를 입력해주세요');
			return false;
		}
		if (!chkPhoneTel(tel)) {
			setErrorText('연락처 양식을 확인해주세요');
			return false;
		}
		if (address.add1.trim() === '' || address.add2.trim() === '' || address.add3.trim() === '') {
			setErrorText('거주지를 입력해주세요');
			return false;
		}
		setErrorText('');
		return true;
	}
	const apply = () => {
		const appliable = validate();
		if (appliable) {
			const data = {
				name: name,
				tel: tel,
				address1: address.add1,
				address2: address.add2,
				address3: address.add3
			}
			console.log(data);
			axios.post(`${BASE_URL}/web/v1/vipApply`, data).then((res: any) => {
				if (res.data.msg) {
					setErrorText(res.data.msg);
				} else {
					setErrorText('신청이 완료되었습니다');
					setIsAlready(true);
				}
				setModalState({...modalState, alertModal: true});
			});
		} else {
			setModalState({...modalState, alertModal: true});
		}
	}
	useEffect(() => {
		axios.get(`${BASE_URL}/web/v1/vipApply`).then(res => {
			if (res.data === 1) {
				setIsAlready(true);
			}
		})
	}, []);
	useEffect(() => {
		console.log(user);
		if (user.me) {
			if (user.me.customerName) setName(user.me.customerName);
			setTel(user.me.tel);
		}
	}, [user])
	return (
		<>
		{/* <AppHeader title={''} type="product" actionItems={[
			{name: 'close'}
		]} /> */}
		<div className="page page-vip-apply">
			<PageTitle title="웨딩북 가입기념
시크릿 VIP 신청하기"/>
			<div className="form-wrap">
				<div className="form-block">
					<div className="form-title">
						<span className="title">이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-has-line input-full"
							placeholder="이름" 
							defaultValue={name}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">연락처</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-has-line input-full"
							placeholder="010-0000-0000"
							defaultValue={autoHypenPhone(tel)}
							value={autoHypenPhone(tel)}
							readOnly={true}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">현재 거주지 (동까지만 표시)</span>
					</div>
					<div className="form-content">
						<div className="input-flex-wrap flex3">
							<input 
								type="text"
								className="input-text input-has-line"
								placeholder="시/도"
								onChange={e => setAddress({...address, add1: e.target.value})}
							/>
							<input 
								type="text"
								className="input-text input-has-line"
								placeholder="시/구/군" 
								onChange={e => setAddress({...address, add2: e.target.value})}
							/>
							<input 
								type="text"
								className="input-text input-has-line"
								placeholder="읍/면/동" 
								onChange={e => setAddress({...address, add3: e.target.value})}
							/>
						</div>
						<p className="address-ex-text">예시) 서울시 강남구 청담동, 경기도 구리시 갈매동</p>
					</div>
				</div>
				<div className="agreement-wrap">
					<div>
						<Checkbox 
							id="agreement"
							name="agreement"
							value="agreement"
							setValue={() => setAgreement(!agreement)}
							checked={agreement}
						/>
						<label htmlFor="agreement">제3자 정보 제공 동의</label>
					</div>
					<p className="terms">{`
㈜웨딩북은 개인정보보호법에 의거 귀하의 개인정보를 아래와 같이 제3자 제공하는 것에 대해 동의를 받고자 합니다.
    1. 개인정보를 제공 받는 자 : 삼성전자판매 주식회사, (주)하이프라자
    2. 제공 항목 : (이름, 휴대폰 번호, 신청일, 예식일, 지역)
    3. 이용 목적 : 혼수 가전 쿠폰 증정 및 지역 맞춤형 혜택 안내
    4. 보유 및 이용 기간 : 본인 예식일까지

정보 전달을 위한 문자 및 유선 연락을 드릴 수 있습니다.
					`.trim()}</p>
				</div>
				<button className={`btn btn-full btn-large btn-red ${isAlready ? 'btn-disabled' : ''}`} disabled={isAlready} onClick={apply}>
					{isAlready ? '이미 신청하였습니다' : '신청하기'}
				</button>
			</div>
			<Modal 
				isShow={modalState.alertModal}
				text={errorText}
				onClose={() => setModalState({...modalState, alertModal: false})}
			/>
		</div>
		</>
	);
};

export default VipApply;