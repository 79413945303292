import { IPartner, IPartnerImages, getPartnerImages } from '@src/api/partner/partner';
import './partnerImageSlider.scss'
import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useWindowSize } from '@src/utils/hooks/useWindowSize';

interface IPartnerImageSlider {
	partner: IPartner;
}
let fetchStart = 0;
const fetchSize = 10;
const PartnerImageSlider = ({partner}: IPartnerImageSlider) => {
	const [total, setTotal] = useState(0);
	const [images, setImages] = useState<IPartnerImages[]>([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const {width} = useWindowSize();
	const getImages = () => {
		getPartnerImages(partner.uuid, {fetchStart: fetchStart, fetchSize: fetchSize}).then(res => {
			setImages([...images, ...res.data.item]);
			setTotal(res.data.total);
			fetchStart += fetchSize;
		});
	}
	useEffect(() => {
		getImages();
	}, []);
	const handlers = useSwipeable({
		onSwipedLeft(_) {
			if (currentIndex < total-1) {
				setCurrentIndex(currentIndex+1);
			}
			if (currentIndex > images.length * 0.8) {
				getImages();
			}
		},
		onSwipedRight(_) {
			if (currentIndex > 0) {
				setCurrentIndex(currentIndex-1);
			}
		},
	});
	const linkGallery = () => {
		window.location.href = 'h2mwbell://gallery/' + partner.uuid;
	}
	if (width === undefined) return null;
	return (
		<div className="partner-image-slider-wrap" onClick={linkGallery}>
			<ul {...handlers} style={{transform: `translateX(${width * currentIndex * -1}px)`}} className={`${total === 0 ? 'no-image' : ''}`}>
				{images.map((image, idx) => (
				<li style={{backgroundImage: `url(https://imgs.h2m.io/?url=${image.url}&w=1200)`}} key={`${partner.uuid}-images-${idx}`}></li>
				))}
			</ul>
			{total > 0 &&
			<div className="slider-count-wrap">
				<i className="icon icon-album" />
				<span>{currentIndex+1}/{total}</span>
			</div>
			}
		</div>
	);
};

export default PartnerImageSlider;