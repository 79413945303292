import { useState, useEffect, useContext } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { callCount as callCountData } from '@src/@slice/globalSlice';
import { UserContext } from '@src/App';
// api
import axios from './../../api/axios';
import { BASE_URL, addComma, isExternalUrl, HOSTNAME } from '@src/utils';
import storage from './../../api/storage';
// utils
import { linkLogin, linkSignup } from '@src/utils/login';
// components
import SwipeBannerComponent from '../../components/home/swipeBanner/SwipeBanner';
import { BlankArea } from './../../components/parser/Parser';
import { ITitle } from '@src/components/common/sectionTitle/SectionTitle';
import LoadingSpinner from '@src/components/common/loading/LoadingSpinner';
// analytics
import ReactGA from '@src/utils/ga';

interface ICouponData {
	total: number;
	lastRegTspStr: string | null;
}
interface IDetail {id: number, jsonStr: string};
interface IComponent {
	detail: IDetail[];
	pk: string;
	tabTitle: string | null;
	title: string;
	type: 'swipeBanner' | 'smallMenuContent';
}
interface INotice {
	content: string;
	detailTypes: any[];
	link: string;
	title: string;
}
type KeyName = 'mpCouponPushCheck' | 'mpPartnerChatCheck' | 'pushCheck';
const Mypage = () => {
	const user = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const callCount = useSelector(callCountData);
	const [point, setPoint] = useState<number>(0);
	const [couponData, setCouponData] = useState<ICouponData>({total: 0, lastRegTspStr: null});
	const [bookieUnreadCount, setBookieUnreadCount] = useState(0);
	const [partnerChatTsp, setPartnerChatTsp] = useState<string|null>(null);
	const [pushTsp, setPushTsp]= useState<string|null>(null);
	const [notice, setNotice] = useState<INotice[]>([]);
	const [swipeItem, setSwipeItem] = useState<{
		title: ITitle;
		bottom: string;
		isCustomWidth: boolean;
		item: any[];
		rolling: boolean;
		top: string;
	}>();
	const [menus, setMenus] = useState<IComponent[]>([]);
	const getMe = async () => {
		try {
			const {data} = await axios.get<{item:any}>(BASE_URL + '/v3/mypage/me');
			const item = data.item;
			const components: IComponent[] = item.components;
			setPoint(item.point);
			setCouponData(item.coupon);
			setBookieUnreadCount(item.bookieUnreadCount);
			setPartnerChatTsp(item.newPartnerChatTsp);
			setPushTsp(item.newPushTsp);
			setNotice(item.notice);
			if (components.filter(cp => cp.type === 'swipeBanner').length > 0) {
				setSwipeItem(JSON.parse(components.filter(cp => cp.type === 'swipeBanner')[0].detail[0].jsonStr));
			}
			setMenus(components.filter(cp => cp.type === 'smallMenuContent'))
		} catch (error) {
			console.log(error);
		}
	}
	const linkCouponList = () => {
		localStorage.setItem('mpCouponPushCheck', new Date().toString());
		if (user.me) {
			storage.setItem(user.me.uuid, 'mpCouponPushCheck', new Date().toString());
		}
	}
	const linkPushList = () => {
		if (user.me) {
			storage.setItem(user.me.uuid, 'pushCheck', new Date().toString());
		}
		window.location.href = 'h2mwbell://push';
	}
	const checkNewData = async (keyName: KeyName, tsp: string) => {
		const checkTime = await storage.getItem(user.me.uuid, keyName);
		if (checkTime !== false) {
			return (new Date(tsp) > new Date(checkTime));
		} else {
			return true;
		}
	}
	useEffect(() => {
		getMe().finally(() => setLoading(true));
		axios.get(BASE_URL + '/v3/mypage/coupon').catch(() => {});
	}, [callCount]);
	const [showCouponNew, setShowCouponNew] = useState(false);
	const [showPushNew, setShowPushNew] = useState(false);
	useEffect(() => {
		(async () => {
			if ((couponData.lastRegTspStr && (await checkNewData('mpCouponPushCheck', couponData.lastRegTspStr)))) {
				setShowCouponNew(true);
			} else {
				setShowCouponNew(false);
			}
		})();
	}, [couponData, callCount]);
	useEffect(() => {
		(async () => {
			if ((pushTsp && (await checkNewData('pushCheck', pushTsp)))) {
				setShowPushNew(true);
			} else {
				setShowPushNew(false);
			}
		})();
	}, [pushTsp, callCount]);
	return (
		<>
			{! loading ? <LoadingSpinner /> : null }
			<div className="mypage-wrap">
				<div className="mypage-top">
					<div className="link-my-info-wrap" style={{margin: '0 -8px'}}>
						<a href="h2mwbell://myInfo">
							<i className="icon icon-profile-setting"></i>
						</a>
					</div>
					{user.loggedin 
					? <div className="my-profile-wrap" role="button">
						<div className="profile-thumb" style={{backgroundImage: `url(${user.me ? user.me.profileUrl : ''})`}}></div>
						<div>
							{user.me &&
							<>
							<div className="user-nick">{user.me.nick}</div>
							<span className="user-grade">{user.me.gradeName}</span>
							</>
							}
						</div>
					</div>
					: <>
					<section className="mypage-login-wrap">
						<h3>😢 로그인이 필요해요.</h3>
						<p>로그인하고 맞춤 컨텐츠와 다양한 혜택을 받으세요.</p>
						<div className="btn-wrap items-2">
							<div>
								<button className="btn btn-full btn-large btn-light-red" onClick={() => {
									linkLogin();
								}}>로그인하기</button>
							</div>
							<div>
								<button className="btn bold btn-full btn-large btn-red" onClick={() => {
									linkSignup();
								}}>회원 가입하기</button>
							</div>
						</div>
					</section>
					<div style={{margin: '0 -20px 20px'}}><BlankArea /></div>
					</>
					}
					<div className="mypage-card">
						<div className="my-items-wrap">
							<a 
								href={isExternalUrl(HOSTNAME + '/mypage/my-point')}
								role="button"
							>
								<h3>내 포인트</h3>
								<b>{addComma(point)}P</b>
							</a>
							<a 
								href={isExternalUrl(HOSTNAME + '/mypage/coupon-list')}
								onClick={linkCouponList}
								role="button"
							>
								<h3 style={{position: 'relative',}}>
									내 쿠폰
								</h3>
								<b>
									{couponData.total}장
									{showCouponNew && <div className="new-circle" />}
								</b>
							</a>
						</div>
						{notice.length > 0 &&
						<div className="mypage-notice-wrap">
							{notice.map(item => (
							<a href={isExternalUrl(item.link)} className="mypage-notice-item" key={`notice-${item.title}`}>
								<span className="badge bold badge-red ttnorms-bold">{item.title}</span>
								<span className="notice-title">{item.content}</span>
							</a>
							))}
						</div>
						}
					</div>
				</div>
				<div className="mypage-goal-wrap">
					<ul className="goal-list-wrap">
						<li
							onClick={() => {
								ReactGA.event({
									category: 'Mypage',
									action: 'click',
									label: '1:1 문의'
								});
								window.location.href = 'h2mwbell://chat';
							}}
						>
							<div className="goal-icon concierge">
								{bookieUnreadCount > 0 &&
								<span className={`goal-badge ttnorms-bold bold ${bookieUnreadCount < 10 ? 'under-10' : ''}`}>{bookieUnreadCount > 99 ? '99+' : bookieUnreadCount}</span>
								}
							</div>
							<span className="goal-title">1:1 문의</span>
						</li>
						<li
							onClick={() => {
								ReactGA.event({
									category: 'Mypage',
									action: 'click',
									label: '업체 채팅'
								});
								window.location.href = 'h2mwbell://chat/partner';
							}}
						>
							<div className="goal-icon chatting">
								{partnerChatTsp !== null &&
								<span className="goal-badge ttnorms-bold bold">N</span>
								}
							</div>
							<span className="goal-title">업체 채팅</span>
						</li>
						<li
							onClick={() => {
								ReactGA.event({
									category: 'Mypage',
									action: 'click',
									label: '푸시 수신함'
								});
								linkPushList();
							}}
						>
							<div className="goal-icon push">
								{showPushNew &&
								<span className="goal-badge ttnorms-bold bold">N</span>
								}
							</div>
							<span className="goal-title">푸시수신함</span>
						</li>
						<li
							onClick={() => {
								ReactGA.event({
									category: 'Mypage',
									action: 'click',
									label: '구매내역'
								});
								window.location.href = 'h2mwbell://partner/purchase';
							}}
						>
							<div className="goal-icon shopping"></div>
							<span className="goal-title">구매내역</span>
						</li>
					</ul>
				</div>
				{swipeItem &&
				<>
				<div className="mypage-swipe-banner-wrap">
					<SwipeBannerComponent
						id={0}
						page={'mypage'}
						title={swipeItem.title}
						isCustomWidth={swipeItem.isCustomWidth}
						items={swipeItem.item}
						top={swipeItem.top}
						bottom={swipeItem.bottom}
						rolling={swipeItem.rolling}
					/>
				</div>
				</>
				}
				{user.loggedin && <BlankArea />}
				<div className="mypage-link-wrap">
					{menus.map(menu => (
					<section className="link-section" key={`link-menu-${menu.pk}`}>
						<h3>{menu.tabTitle}</h3>
						<ul>
							{menu.detail.map(item => (
							<LinkMenuList 
								menu={menu}
								item={item}
								key={`${menu.pk}-${item.id}`}
							/>
							))}
						</ul>
					</section>
					))}
				</div>
			</div>
		</>
	);
};
const LinkMenuList = ({menu, item}: {menu: IComponent, item: IDetail}) => {
	const jsonData = JSON.parse(item.jsonStr);
	return (
		<li id={`${menu.pk}-${item.id}`}>
			<a
				href={isExternalUrl(jsonData.link)} 
				onClick={() => {
					ReactGA.event({
						category: 'Mypage',
						action: 'click',
						label: jsonData.title
					});
				}}
			>
				{jsonData.title}
				{jsonData.badge_text &&
				<span className="badge badge-red ttnorms-bold" style={{backgroundColor: jsonData.badge_color_code, color: jsonData.badge_color }}>{jsonData.badge_text}</span>
				}
			</a>
		</li>
	)
}
export default Mypage;