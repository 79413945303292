import './vendorCuration.scss';
import { useEffect, useState, useRef } from 'react';
import {chunk} from 'lodash';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import CommentSvg from '../../../stories/assets/ic_comment.svg';
import RateSvg from '../../../stories/assets/ic_rate.svg';
import Slider from 'react-slick';
import axios from 'axios';
import { BASE_URL, isExternalUrl } from '../../../utils/index';
import TagList, { ITags } from '../tagList/TagList';
import { CompanyItem } from '../companyOrder/CompanyOrder';
import { logEvent } from '@src/firebase';
import { HOSTNAME } from '@src/utils';

type ItemProps = {
	page: string;
	pageSub?: string;
	partnerUuid: string;
	id: string;
	name?: string;
	imgUrl?: string;
	tags?: ITags;
	address?: string;
	typeName?: string;
	description?: string;
	isItemLocaltion?: number;
	isRating?: number;
	itemId?: string;
	partner?: string;
	tag?: any[];
	rating?: number;
	comment?: number;
	listType: number;
	ranking: number;
};

const CurationItem = ({
	page,
	pageSub,
	partnerUuid,
	id,
	imgUrl,
	isItemLocaltion,
	address,
	name,
	description,
	typeName,
	tags,
	isRating,
	rating,
	comment,
	listType,
	ranking
}: ItemProps) => {
	return (
		<a href={`h2mwbell://partner/${partnerUuid}`} className="row-curation" onClick={() => {
			const obj = {
				page: page,
				pageSub: pageSub,
				component: 'vendor_curation',
				behavior: 'click',
				content: name
			}
			if (pageSub === undefined) delete obj.pageSub;
			logEvent('component_behavior', obj);
		}}>
			<div
				className={`row-item-bg ${!imgUrl ? 'no-image' : ''}`}
				style={{ backgroundImage: `url(${`${imgUrl}`})` }}
			/>
			<div className={`row-item-info-wrap ${listType ? 'has-ranking' : ''}`}>
				{!!listType &&
				<span className="item-ranking">{ranking}</span>
				}
				{!!isItemLocaltion &&
				<span className="item-sub">{typeName ? `${typeName} · ` : ''}{address}</span>
				}
				<span className="item-name">{name}</span>
				{!!description && (
					<span className='item-description'>{description}</span>
				)}
				{!!isRating &&
				<div className="item-score-wrap">
					<div>
						<img style={{ width: 18, height: 18, marginTop: -2 }} src={RateSvg} alt="평점" />
						<span>{rating ? (rating/20).toFixed(1) : 0}</span>
					</div>
					<div>
						<img style={{ width: 18, height: 18, marginTop: -2 }} src={CommentSvg} alt="후기 수" />
						<span>{comment ? comment : 0}</span>
					</div>
				</div>
				}
				{tags && (
					<TagList id={id} tags={tags} />
				)}
			</div>
		</a>
	);
};

type IItem = {
	description: string;
	isItemLocaltion: number;
	isRating: number;
	itemId: string;
	partner: string;
	tag: ITags;
	rating?: number;
	comment?: number;
	// length?: number;
};

type ITab = {
	item: IItem[];
	tabButtonText?: string;
	tabButtonLink?: string;
	tabId: string;
	tabName: string;
};

type IDataCuration = {
	buttonLink?: string;
	buttonText?: string;
	buttonPartnerName?: string;
	isTab?: number;
	direction: 'VERTICAL' | 'HORIZONTAL';
	tab?: ITab[] | undefined;
	item?: IItem[] | undefined;
	title: ITitle;
	listType: number;
};

type CurationProps = {
	id: number;
	page: string;
	pageSub?: string;
	data: IDataCuration;
};

export default function VendorCuration({ id, page, pageSub,  data }: CurationProps) {
	const [tabActive, setTabActive] = useState<number>(0);
	const [allItem, setAllItem] = useState<any[]>([]);
	const [partners, setPartners] = useState<any[]|null>(null);
	const [moreButtonData, setMoreButtonData] = useState({
		url: data?.buttonLink!,
		name: data?.buttonText!
	});
	const slider = useRef(null);
	useEffect(() => {
		const allArr:any = [];
		if (data.isTab && data.tab) {
			const initialTab = data.tab[tabActive];
			data.tab?.forEach(tabItem => {
				allArr.push(...tabItem.item);
			});
			setMoreButtonData({
				url: initialTab.tabButtonLink ? initialTab.tabButtonLink : '',
				name: initialTab.tabButtonText ? initialTab.tabButtonText : ''
			});
		} else {
			data.item?.forEach(item => {
				allArr.push(item)
			})
		}
		setAllItem(allArr);
	}, []);
	useEffect(() => {
		if (data.tab === undefined) return;
		const initialTab = data.tab[tabActive];
		setMoreButtonData({
			url: initialTab.tabButtonLink ? initialTab.tabButtonLink : '',
			name: initialTab.tabButtonText ? initialTab.tabButtonText : ''
		});
	}, [data.tab, tabActive]);
	useEffect(() => {
		if (partners !== null || allItem.length === 0) return;
		const partnerUuids = allItem.map(item => item.itemId).join();
		axios.get(`${BASE_URL}/v3/200107/partners?uuids=${partnerUuids}`).then((res: any) => {
			setPartners(res.data.item);
		});
	}, [allItem, partners]);
	const SETTING = {
		infinite: true,
		speed: 500,
		centerMode: true,
		centerPadding: '14px'
	};
	return (
		<section className="vendor-curation-wrap">
			<SectionTitle
				title={data.title}
				link={data.direction === 'HORIZONTAL' ? moreButtonData : {}}
			/>
			{data.direction === 'HORIZONTAL' &&
			<>
			{data.isTab ? (
				<ul className="tabs-wrap">
					{data.tab?.map((item, idx) => {
						const active = tabActive === idx;
						return (
							<li
								className={`tab-item ${active ? 'on' : ''}`} 
								onClick={() => {
									if (slider.current) {
										(slider.current as any).slickGoTo(0);
									}
									setTabActive(idx);
								}}
								key={`vendor-curation-tab-${id}-${idx}`}
							>
								{item.tabName}
							</li>
						);
					})}
				</ul>
			) : <></>}
			<div>
				<Slider {...SETTING} ref={slider}>
					{data.tab &&
					chunk(data.tab[tabActive].item, 3).map((items, idx) => {
						return (
							<div className="slider-group-wrap" key={`vendor-curation-group-${id}-${idx}`}>
							{items.map((item, innerIdx) => {
								if (!partners) return null;
								const partner = partners.filter((partner: any) => partner.uuid === item.itemId)[0];
								if (!partner) return null;
								return (
									<CurationItem
										page={page}
										pageSub={pageSub}
										partnerUuid={partner.uuid}
										imgUrl={partner.coverUrl}
										name={item.partner}
										description={item.description}
										isItemLocaltion={item.isItemLocaltion}
										address={partner.address}
										typeName={partner.typeName}
										tags={item.tag}
										isRating={item.isRating}
										rating={partner.rating}
										comment={partner.reviewCnt}
										listType={+data.listType}
										ranking={idx * 3 + (innerIdx+1)}
										id={`vendor-curation-${id}-${item.itemId}`}
										key={`vendor-curation-${id}-${item.itemId}`}
									/>
								)
							})}
							</div>
						)
					})
					}
					{data.item &&
					chunk(data.item, 3).map((items, idx) => {
						return (
							<div className="slider-group-wrap" key={`vendor-curation-group-${id}-${idx}`}>
							{items.map((item, innerIdx) => {
								if (!partners) return null;
								const partner = partners.filter((partner: any) => partner.uuid === item.itemId)[0];
								if (!partner) return null;
								return (
									<CurationItem
										page={page}
										pageSub={pageSub}
										partnerUuid={partner.uuid}
										imgUrl={partner.coverUrl}
										name={item.partner}
										description={item.description}
										isItemLocaltion={item.isItemLocaltion}
										address={partner.address}
										typeName={partner.typeName}
										tags={item.tag}
										isRating={item.isRating}
										rating={partner.rating}
										comment={partner.reviewCnt}
										listType={+data.listType}
										ranking={idx * 3 + (innerIdx+1)}
										id={`vendor-curation-${id}-${item.itemId}`}
										key={`vendor-curation-${id}-${item.itemId}`}
									/>
								)
							})}
							</div>
						)
					})
					}
				</Slider>
			</div>
			</>
			}
			{(data.direction === 'VERTICAL' && data.item) &&
			<>
			<ul className="company-list-wrap">
			{data.item.map(item => {
				if (!partners) return null;
				const partner = partners.filter((partner: any) => partner.uuid === item.itemId)[0];
				if (!partner) return null;
				return (
					<CompanyItem 
						partner={partner}
						page={page}
						pageSub={pageSub}
						parentComponent="vendor_curation"
						key={`vendor-curation-${id}-${partner.uuid}`}
					/>
				)
			})}
			</ul>
			{data.buttonLink &&
			<div className="more-button-wrap">
				<button className="btn">
					<a href={isExternalUrl(data.buttonLink)}>
						<span>{data.buttonPartnerName}</span> {data.buttonText}
					</a>
				</button>
			</div>
			}
			</>
			}
		</section>
	);
}
