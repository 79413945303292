import axios from '@src/api/axios';
import { BASE_URL } from '@src/utils/index';
import { encode } from 'base-64';
import { ServerResponse } from '@src/api/axios';
import CryptoJS from 'crypto-js';
import jwt from 'jsonwebtoken';
import { ENCRYPTION_KEY } from '..';
import { User, UserData, setData as setUserData, setResetData as setUserDataReset, setNeedCallAdditionalData } from '@src/@slice/UserSlice';
import { SignUpData, setData as setSignUpData, setResetData as setSignUpDataReset } from '@src/@slice/SignUpSlice';
import { useDispatch, useSelector } from 'react-redux';

let needCallAdditionalData = true;


export const useUserActions = () => {
    const dispatch = useDispatch();
    const ForUser = useSelector(User);
    const ForSignUpData = useSelector(SignUpData);

    // 로그인 요청 함수
    const loginAction = async (email:string, password: string): Promise<any> => {
        const encodedPassword = encode(password);
        const data = {
            loginId: email,
            pwd: encodedPassword
        }
        const result = await new Promise<any>((resolve, reject) => {
            axios.post<ServerResponse>(`${BASE_URL}/v3/20240404/customer/login?${new URLSearchParams(data).toString()}`, data)
                .then(response => {
                    if (response.data.result === 1) {
                        getUserData(response.data.item.accessToken, response.data.item.refreshToken);
                    }
                    resolve(response.data);
                }).catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
        return result;
    }
    
    // 소셜 로그인 요청 함수
    const socialLoginAction = async (token: string): Promise<any> => {
        const data = {
            loginToken: token
        }
        const result = await new Promise<any>((resolve, reject) => {
            axios.post<ServerResponse>(`${BASE_URL}/v3/jwt/:loginToken?loginToken=${token}`, data)
                .then(response => {
                    if (response.data.result === 1) {
                        let data = getUserData(response.data.item.accessToken, response.data.item.refreshToken);
                        window.location.href = `h2mwbell://home?userUuid=${data.customerUuid}`;
                    }
                    resolve(response.data);
                }).catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
        return result;
    }

    // 사용자 정보 가져오기
    const getUserData = (accessToken: string, refreshToken?: string) => {
        if (! accessToken) return;
        localStorage.setItem('accessToken', accessToken);
        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
        }
        
        // AES 복호화
        const decryptAES = (encryptedData:string, key:string) => {
            const keyHex = CryptoJS.enc.Utf8.parse(key);
            const decrypted = CryptoJS.AES.decrypt(
                encryptedData,
                keyHex,
                {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                }
            );
            return decrypted.toString(CryptoJS.enc.Utf8);
        }

        // JWT 디코딩
        const decode = jwt.decode(accessToken);

        // 사용자 정보 객체 생성
        let userData: { [key: string]: any } = {} as { [key: string]: any };
        
        // 사용자 정보 객체에 값 할당
        for (let key in decode) {
            let value:string;
            try {
                value = decryptAES(decodeURIComponent(decode[key]), ENCRYPTION_KEY);
            } catch (e) {
                console.log('error');
                value = decode[key];
            }
            userData[key] = value;
        }

        const data = {
            data: {
                personalInfo: {
                    loginId: userData.loginId,
                    nick: userData.nick,
                    sex: userData.sex,
                    name: userData.name,
                    tel: userData.tel,
                },
            }
        }
        dispatch(setUserData({ data: userData }));
        dispatch(setSignUpData(data));

        return userData;
    }

    // 사용자 정보 리셋
    const resetUserData = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        dispatch(setUserDataReset());
        dispatch(setSignUpDataReset());
    }

    // 로그아웃 요청 함수
    const logoutAction = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');
        if (!refreshToken || !accessToken) {
            resetUserData();
            return;
        }
        const url = `${BASE_URL}/v3/20240404/customer/logout?refreshToken=${refreshToken}`;
        const headers = {
            'Authorization': `bearer ${accessToken}`
        };
        const data = {                       
            refreshToken: refreshToken
        };
        return axios.post(url, data, { headers }).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e);
            return e;
        }).finally(() => {
            resetUserData();
        });
    }

    // 회원 가입
    const registerCustomer = async (x: {
        personalInfo?: { [key: string]: any },
        weddingInfo?: { [key: string]: any },
        extraInfo?: { [key: string]: any },
        agreement?: { [key: string]: any }
    }, joinToken?:string) => {
        const { loginId: email, password: pwd, tel, sex, name: customerName } = x.personalInfo;
        const termsData = x.agreement;
        const termsArray = [];
        const encodedPwd = pwd ? encode(pwd) : null;
        const termsCondition = {
            'terms': 1,
            'privacyPolicy': 2,
            'marketing':3,
            'applianceBenefits':4,
            'over14':5
        };
        Object.keys(termsCondition).forEach((key) => {
            if(termsData[key] === true) {
                termsArray.push(termsCondition[key]);
            }
        });
        const termsAgreed = termsArray.join(',');
        const data = {
            email: email,
            pwd: encodedPwd,
            tel: tel,
            sex : sex,
            customerName : customerName,
            terms: termsAgreed
        }
        if (joinToken) {
            data['joinToken'] = joinToken;
        }
        // null 값 제거
        const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== null) {
                acc[key] = value;
            } 
            return acc;
        }, {});
        const url = `${BASE_URL}/v3/20240404/customer?${new URLSearchParams(filteredData).toString()}`;
        const result = await axios.post<ServerResponse>(url, filteredData).then((response) => {
            if (response.data.result === 0) {
                return { success: false, data: response.data };
            } else {
                // getUserData(response.data.item.accessToken, response.data.item.refreshToken);
                return { success: true, data: response.data };
            }
        }).catch((error) => {
            return { success: false, error: error.response };
        });
        return result;
    }

    // 회원정보 수정
    const modifyCustomer = async (x: {
        personalInfo?: { [key: string]: any },
        weddingInfo?: { [key: string]: any },
        extraInfo?: { [key: string]: any },
        agreement?: { [key: string]: any }
    }
    ) => {
        const { nick } = x.personalInfo;
        const { mateTel, mateName, region, weddinghallName, weddingTsp, weddingTspType } = x.weddingInfo;
        const { interestItems, hasPlanner, weddingbookPlanner, inPathId, invitationCode } = x.extraInfo;
        const data = {
            nick: nick,
            mateTel: mateTel,
            mateName: mateName,
            region: region,
            weddinghallName: weddinghallName,
            weddingTsp: weddingTsp,
            weddingTspType: weddingTspType,
            interestItems: interestItems,
            hasPlanner: hasPlanner,
            weddingbookPlanner: weddingbookPlanner,
            inPathId: inPathId,
            invitationCode: invitationCode,
        };
        // null 값 제거
        const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== null) {
                acc[key] = value;
            } 
            return acc;
        }, {});
        const url = `${BASE_URL}/v3/20240404/customer?${new URLSearchParams(filteredData).toString()}`;
        const headers = { 'Authorization': `bearer ${localStorage.getItem('accessToken')}` };
        const result = await axios.put<ServerResponse>(url, filteredData, { headers }).then((response) => {
            if(response.data.result === 0) {
                return { success: false, data: response.data };
            } else {
                getUserData(response.data.item.accessToken);
                dispatch(setNeedCallAdditionalData({ boo: true }));
                needCallAdditionalData = true;
                return { success: true, data: response.data };
            }
        }).catch((error) => {
            return { success: false, error: error.response };
        });
        return result;

    }

    // 추가정보 호출
    const getAdditionalInfo = async () => {
        if (ForUser.needCallAdditionalData === false) return;
        if (! needCallAdditionalData) return;
        dispatch(setNeedCallAdditionalData({ boo: false }));
        needCallAdditionalData = false;
        console.log('getAdditionalInfo');
        const url = `${BASE_URL}/v3/20240404/customer`;
        const headers = { 'Authorization': `bearer ${localStorage.getItem('accessToken')}` };
        const result = await axios.get<ServerResponse>(url, { headers }).then((response) => {
            const res = response.data;
            const data = {
                data: {
                    weddingInfo: {
                        // mateName: res.item.mateName ? res.item.mateName : null,
                        // mateTel: res.item.mateTel ? res.item.mateTel : null,
                        // weddingTsp: res.item.weddingTsp ? res.item.weddingTsp : null,
                        // weddingTspType: res.item.weddingTspType ? String(res.item.weddingTspType) : null,
                        // region: res.item.region ? res.item.region : null,
                        // weddinghallName: res.item.weddinghallName ? res.item.weddinghallName : null,
                        ...(res.item.mateName && { mateName: res.item.mateName }),
                        ...(res.item.mateTel && { mateTel: res.item.mateTel }),
                        ...(res.item.weddingTsp && { weddingTsp: res.item.weddingTsp }),
                        ...(res.item.weddingTspType && { weddingTspType: String(res.item.weddingTspType) }),
                        ...(res.item.region && { region: res.item.region }),
                        ...(res.item.weddinghallName && { weddinghallName: res.item.weddinghallName }),
                    },
                    extraInfo: {
                        interestItems: res.item.interestItems ? res.item.interestItems : null,
                        hasPlanner: res.item.hasPlanner ? String(res.item.hasPlanner) : null,
                        weddingbookPlanner: res.item.weddingbookPlanner ? res.item.weddingbookPlanner : null,
                        inPathId: res.item.inPathId ? res.item.inPathId : null,
                        invitationCode: res.item.invitationCode ? res.item.invitationCode : null,
                    },
                }
            }

            if (res.item.weddingTsp === null) {
                data.data.weddingInfo.region = null;
                data.data.weddingInfo.weddingTspType = null;
            }

            dispatch(setUserData({ data: res.item }));
            dispatch(setSignUpData(data))

            // if (res.item.additionalInformationRegistRequest === 'Y') {
            //     if(res.item.mateName === null) {
            //         dispatch(setCompleteStatus({ status: 'basicInfo-completed' }));
            //         dispatch(setStatus({ page : 'signUp' }));
            //     } else {
            //         dispatch(setCompleteStatus({ status: 'weddingInfo-completed' }));
            //         dispatch(setStatus({ page : 'modify' }));
            //     }
            // } else {
            //     dispatch(setCompleteStatus({ status: 'extraInfo-completed' }));
            //     dispatch(setStatus({ page : 'modify' }));
            // }
            return response.data;
        }).catch((error) => {
            console.log(error);
        });
        return result;
    };


    // 로그인 체크
    const checkLogin = () => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            return true;
        } else {
            return false;
        }
    }

    return {
        loginAction,
        socialLoginAction,
        getUserData,
        resetUserData,
        logoutAction,
        registerCustomer,
        modifyCustomer,
        getAdditionalInfo,
        checkLogin
    }
    
}