import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import SectionTitle from '@src/components/common/sectionTitle/SectionTitle';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { BASE_URL } from '@src/utils';
import useUrlQueryString from '../../utils/hooks/useUrlQueryString';
import Modal from '@src/components/common/modal/Modal';
import { HOSTNAME } from './../../utils/index';
import { logEvent } from '@src/firebase';
import PageTab, {ITab} from './../../components/home/pageTab/PageTab';

interface IGift {
	description: string;
	iconPath: string;
	id: number;
	name: string;
	regTsp: number;
	stampCount: number;
	state: number;
}
const StampApply = () => {
	const query = useUrlQueryString();
	const [stampCount, setStampCount] = useState<string|null>(null);
	const [title, setTitle] = useState('');
	const [gifts, setGifts] = useState<IGift[]>([]);
	const [stamps, setStamps] = useState([]);
	const [myGifts, setMyGifts] = useState<IGift[]>([]);
	const tab = useState<ITab[]>([
		{tabId: 3, tabTitle: '스탬프 3개 상품'},
		{tabId: 5, tabTitle: '스탬프 5개 상품'}
	])[0];
	const [selectedGiftId, setSelectedGiftId] = useState<number|null>(null);
	const [modalState, setModalState] = useState({
		checkReward: false,
		complete: false
	});
	// const [isShowModal, setIsShowModal] = useState(false);
	const [modalText, setModalText] = useState('');
	useEffect(() => {
		axios.get<{data: any}>(`${BASE_URL}/web/v3/stamps/me`).then(res => {
			const data = res.data.data;
			if (data.gift) setMyGifts(data.gift);
			if (data.stamp) setStamps(data.stamp);
		})
	}, []);
	useEffect(() => {
		const sCount = query.get('stampCount');
		setStampCount(sCount);
	}, [query]);
	useEffect(() => {
		if (stampCount) {
			logEvent('Stamp_Gift_Pageview', {Stamp_Count: +stampCount});
			console.log('stamp Count: ' , stampCount)
			setSelectedGiftId(null);
			axios.get(BASE_URL + `/web/v3/stamps/gifts?stampCount=${stampCount}`).then((res: any) => {
				setGifts(res.data.data);
			});
		}
	}, [stampCount]);
	const applyPresent = (count: number) => {
		return stamps.length >= count ? true : false;
	}
	const havePresent = (count: number) => {
		return myGifts.filter(gift => gift.stampCount === count).length > 0 ? true : false;
	}
	const validate = () => {
		if (stampCount === null) return false;
		if (selectedGiftId === null) return false;
		if (+stampCount === 3) {
			if (stamps.length < 3) return false;
			if (havePresent(3)) return false;
			return true;
		}
		if (+stampCount === 5) {
			if (stamps.length < 5) return false;
			if (havePresent(5)) return false;
			return true;
		}
		return false;
	}
	const controlCheckRewardModal = (bool: boolean) => {
		setModalState({...modalState, checkReward: bool});
	}
	const apply = () => {
		axios.post(BASE_URL + `/web/v3/stamps/gift`, {giftId: selectedGiftId?.toString()}).then((res: any) => {
			if (res.data.result > 0) {
				setModalText('신청이 완료되었습니다.');
				logEvent('Stamp_Gift_Apply', {Gift_Name: gifts.filter(gift => gift.id === selectedGiftId)[0].name});
			} else {
				setModalText('중복된 신청입니다.');
			}
			setModalState({
				checkReward: false,
				complete: true
			});
		});
	}
	return (
		<>
		<AppHeader title={title} type="product" actionItems={[
			{name: 'close'}
		]} />
		<div className="page page-wedding-stamp-apply">
			{/* <SectionTitle
				title={{
					mainTitle: '스탬프 3개 상품 선택하기',
					titleType: 'mainSub'
				}}
			/> */}
			<div>
				<h2 className="stamp-title">상품 선택하기</h2>
				{stampCount &&
				<div style={{margin: '0 -20px'}}>
				<PageTab
					tab={tab}
					currentTabId={+stampCount}
					onChange={tabId => {
						setStampCount(tabId.toString());
					}}
				/>
				</div>
				}
				<ul className="reward-list-wrap">
					{gifts.map(gift => (
					<li>
						<RadioButton 
							id={`gift-${gift.id}`}
							name="gift"
							value={`${gift.id}`}
							setValue={() => setSelectedGiftId(gift.id)}
							checked={gift.id === selectedGiftId}
						/>
						<label htmlFor={`gift-${gift.id}`}>{gift.name}</label>
					</li>
					))}
				</ul>
				<p className="noti-more-reward">
					더 많은 상품 준비중 💬 
				</p>
			</div>
			<div>
				<div className="noti-apply">
					<em>상품 신청 전 확인해 주세요</em>
					<ul>
						<li>
							신청일 기준 2개월 후 첫 영업일에 지급됩니다. <br/>
							예) 2월 15일 신청 시 4월 1일 지급 (주말, 공휴일일 경우 평일 지급)
						</li>
						<li>
							상품 수령 후 계약 취소로 인해 스탬프가 회수될 경우 상품도 회수됩니다.
						</li>
						<li>
							스탬프 이벤트는 2023년 12월 31일까지 진행될 예정이므로, 이벤트 종료일 전까지 신청을 완료해주세요. (이후 별도 신청 불가)
						</li>
					</ul>
				</div>
				{stampCount &&
				<button 
					className={`btn btn-red btn-full btn-large ${!validate() ? 'btn-disabled' : ''}`}
					onClick={() => controlCheckRewardModal(true)}
					disabled={!validate()}
				>
					선택한 상품 신청하기
				</button>
				}
				
			</div>
		</div>
		<Modal
			isShow={modalState.checkReward}
			text={`선택하신 상품은 ${gifts.find(gift => gift.id === selectedGiftId)?.name} 입니다. 신청하시겠습니까?`}
			buttons={[
				{
					name: '아니오',
					action: () => {
						controlCheckRewardModal(false);
					}
				},
				{
					name: '네',
					className: 'btn-red',
					action: () => {
						apply();
					}
				}
			]}
		/>
		<Modal
			isShow={modalState.complete}
			text={modalText}
			onClose={() => {
				setModalState({
					...modalState,
					complete: false
				});
				if (modalText.indexOf('완료') > -1) {
					window.location.href = `${HOSTNAME}/weddingStamp/apply/complete`;
				}
			}}
		/>
		</>
	);
};

export default StampApply;