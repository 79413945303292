import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect, createContext, useContext } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import FormTitle from './../../components/form/bookingTitle/FormTitle';
import DualRangeSlider from './../../components/dualRangeSlider/DualRangeSlider';
import Checkbox from './../../components/common/checkbox/Checkbox';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import NotiText from '@src/components/common/notiText/NotiText';
import { autoHypenPhone } from './../../utils/index';
import TextArea from '@src/components/common/textArea/TextArea';
import { isExternalUrl, BASE_URL } from '@src/utils';
import axios from 'axios';
import moment from 'moment'
import { ko } from 'date-fns/esm/locale';
import DatePicker, {registerLocale} from 'react-datepicker';
import xCircle from '@src/assets/images/common/x-circle-gray@3x.png';
import { UserContext } from '@src/App';
import useUrlQueryString from '../../utils/hooks/useUrlQueryString';
import { useSelector } from 'react-redux';
import { isComplete } from './../../@slice/honeymoonApplySlice';
import emojiStrip from 'emoji-strip';
import { RECOMMEND_CASE_LIST } from '@src/utils/honeymoon';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import AppHeader from '@src/components/common/appHeader/AppHeader';

const CONCEPTS = [{name: '휴양', value: 'REST'}, {name: '액티비티', value: 'ACTIVITY'}, {name: '관광', value: 'TOURISM'}, {name: '쇼핑', value: 'SHOPPING'}, {name: '잘 모르겠어요', value: 'ETC'}];
type PartnerItem = {
	partnerUuid: string;
	partnerName: string
}
export const ApplyDataContext = createContext<any>(null);
const Apply1 = () => {
	const {
		inpath,
		selectedConcept, setSelectedConcept,
		selectedPartner, setSelectedPartner,
		hopePlace, setHopePlace,
		noBudget, setNoBudget,
		budget, setBudget,
		defaultBudget,
		hopeDate, setHopeDate,
		isTravelAgency, setIsTravelAgency,
		partnerList, setPartnerList,
		recommendId
	} = useContext(ApplyDataContext);
	const navigate = useNavigate();
	const validate = () => {
		if (selectedConcept.length === 0) return false;
		if (hopePlace.trim() === '') return false;
		if (hopeDate === null) return false;
		if (isTravelAgency && selectedPartner.length === 0) return false; 
		return true;
	}
	useEffect(() => {
		axios.get<{items: PartnerItem[]}>(`${BASE_URL}/web/v1/honeymoon/estimate/partners`).then(res => {
			if (res.data) {
				setPartnerList(res.data.items);
			}
		});
		document.querySelectorAll('.range-slider__thumb').forEach(node => {
			if (node.childNodes.length === 0) {
				node.appendChild(document.createElement('span'));
			}
		})
	}, []);
	return (
		<>
		<FormTitle 
			title={`신혼여행 빠른 견적 받기`}
			subText={`신혼여행의 모든 것! 간편하게 견적을 받아보세요.
			여행지 추천부터 견적까지 모든 궁금증을 해소해드릴게요.`}
		/>
		<div className="page-honeymoon-apply">
			<div className="form-wrap">
				<div className="form-block">
					<div className="form-title">
						<span className="title">신혼여행 컨셉</span>
						<div className="necessary"><span>*</span>중복 선택 가능</div>
					</div>
					<div className="form-content">
						<ul className="selectable-list-wrap items-3">
							{CONCEPTS.map(concept => (
								<li key={`concept-${concept.value}`}>
									<div
										className={selectedConcept.indexOf(concept.value) > -1 ? 'on' : ''}
										onClick={() => {
											if (concept.value === 'ETC') {
												setSelectedConcept([concept.value]);
												return;
											}
											if (selectedConcept.indexOf(concept.value) > -1) {
												setSelectedConcept(selectedConcept.filter((con: string) => con !== concept.value && con !== 'ETC'));
											} else {
												setSelectedConcept([...selectedConcept.filter((con: string) => con !== 'ETC'), concept.value]);
											}
										}}
									>
										{concept.name}
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div style={{height: 32}}></div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">희망 여행지</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-full input-has-line"
							placeholder="예) 하와이, 괌, 유럽" 
							value={hopePlace}
							onChange={e => {
								setHopePlace(emojiStrip(e.target.value));
							}}
						/>
						<ul className="noti-list-wrap" style={{marginTop: -24}}>
							<li className="noti-list">희망 여행지가 미정일 경우 “미정”으로 적어주세요.</li>
							<li className="noti-list">여러 여행지를 고민중이라면 모두 적어주세요.</li>
						</ul>
					</div>
				</div>
				<div style={{height: 32}}></div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">신혼여행 예산 (2인 기준)</span>
					</div>
					<div className="form-content">
						<span className="range-price-text">
							{budget[0]}만원 ~ {budget[1]}만원
						</span>
						{!noBudget &&
						<div className="price-range-wrap" style={{display: 'flex'}}>
							<span className="range-price range-min-price">200만원</span> 
							<div>
							<RangeSlider 
								className="dual-range-slider"
								min={200}
								max={2000}
								step={200}
								value={budget}
								onInput={(data: number[]) => {
									setBudget(data);
								}}
							/>
							{/* <DualRangeSlider 
								title={'test'}
								showText={false}
								width={window.innerWidth - 120}
								min={200}
								defaultMin={defaultBudget.min}
								max={1800}
								defaultMax={defaultBudget.max}
								step={200}
								unit={'만원'}
								onChangeMin={min => {
									setNoBudget(false);
									setBudget({...budget, min: min});
								}}
								onChangeMax={max => {
									setNoBudget(false);
									setBudget({...budget, max: max});
								}}
							/> */}
							</div>
							<span className="range-price range-max-price">2000만원</span> 
						</div>
						}
						<div style={{marginTop: 12}}>
							<Checkbox
								id="noBudget"
								size="small"
								name="noBudget"
								value={'10'}
								checked={noBudget}
								setValue={() => {
									if (!noBudget) {
										setBudget([0, 0]);
									} else {
										setBudget([200, 2000]);
									}
									setNoBudget(!noBudget)
								}}
							/>
							<label htmlFor="noBudget">예산을 정하지 못했어요.</label>
						</div>
					</div>
				</div>
				<div style={{height: 32}}></div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">여행 출발 희망일</span>
					</div>
					<div className="form-content">
						<DatePicker 
							selected={hopeDate} 
							customInput={<input 
								type="text"
								className="input-text input-full input-has-line"
							/>}
							onChange={(date:Date) => setHopeDate(date)} 
							placeholderText="날짜를 선택하세요"
							dateFormat="yyyy.MM.dd"
							onChangeRaw={e => e.preventDefault()}
							onFocus={e => e.target.blur()}
							disabledKeyboardNavigation
							locale={ko}
						/>
						<ul className="noti-list-wrap" style={{marginTop: -24}}>
							<li className="noti-list no-style">상품 안내 가능 시기보다 여행 출발일이 많이 남았을 경우 상품 안내가 불가할 수 있으며, 해당 시기 이내 신청 시 다양한 상품 안내가 가능합니다.</li>
							<li className="noti-list green">상품 안내 가능 시기: 여행 출발일까지 8개월 이내</li>
						</ul>
					</div>
				</div>
				{!recommendId &&
				<>
				<div style={{height: 32}}></div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">상담 희망하는 여행사</span>
					</div>
					<div className="form-content">
						<div className="travel-radio-wrap">
							<RadioButton 
								id={`travelAgency1`}
								name="travelAgency"
								value={'미지정'}
								setValue={() => setIsTravelAgency(false)}
								checked={!isTravelAgency}
							/>
							<label htmlFor="travelAgency1" style={{marginRight: 16}}>미지정</label>

							<RadioButton 
								id={`travelAgency2`}
								name="travelAgency"
								value={'지정'}
								setValue={() => setIsTravelAgency(true)}
								checked={isTravelAgency}
							/>
							<label htmlFor="travelAgency2">지정</label>
						</div>
						{isTravelAgency &&
						<>
						<select
							className={`select select-full ${(false) ? 'select-gray' : ''}`}
							value={'-1'}
							onChange={e => {
								const val = e.target.value;
								if (selectedPartner.indexOf(val) > -1) {
									setSelectedPartner(selectedPartner.filter((partner: string) => partner !== val));
								} else {
									if (selectedPartner.length === 2) return;
									setSelectedPartner([...selectedPartner, val]);
								}
							}}
						>
							<option value="-1">업체를 선택해 주세요</option>
							{partnerList.map((partner: PartnerItem) => (
								<option value={partner.partnerUuid} key={`partenr-${partner.partnerUuid}`}>{partner.partnerName}</option>
							))}
						</select>
						</>
						}
						
						<NotiText
							text={isTravelAgency ? '* 제휴업체 중 최대 2개 업체까지 선택 가능합니다.' : '*  미지정 선택 시 고객님께 가장 적합한 2개 업체를 연결해 드립니다.'}
							style={{margin: '0 -20px'}}
						/>
						{isTravelAgency &&
						<ul className="travel-agency-list-wrap">
							{selectedPartner.map((partnerUuid: string) => (
								<li key={`sel-${partnerUuid}`}>
									{partnerList.filter((list: PartnerItem) => list.partnerUuid === partnerUuid)[0].partnerName}
									<img 
										src={xCircle}
										width="18" height="18"
										onClick={() => {
											setSelectedPartner(selectedPartner.filter((partner: string) => partner !== partnerUuid));
										}}
										alt="삭제" />
								</li>
							))}
						</ul>
						}
						
					</div>
				</div>
				</>
				}
				<div style={{height: 32}}></div>
				<button 
					className={`btn btn-full btn-large btn-red ${!validate() ? 'btn-disabled' : ''}`}
					disabled={!validate()}
					onClick={() => {
						navigate(`/honeymoon/apply?inpath=${inpath ? inpath : 'APPLICATION'}&recommendId=${recommendId ? recommendId : ''}#apply2`);
					}}
				>다음</button>
			</div>
		</div>
		</>
	)
}
const VISIT_TIMES = [
	'아무 때나',
	'10:00 ~ 12:00',
	'12:00 ~ 13:00',
	'13:00 ~ 19:00'
]
const removeEmojis = (text: string) => {
    if (!text) {
        return '';
    }
    return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}
const Apply2 = () => {
	const {
		selectedConcept, 
		selectedPartner, 
		hopePlace, 
		noBudget, 
		budget, 
		hopeDate, 
		isTravelAgency, 
		inpath,
		name, setName,
		plannerName, setPlannerName,
		tel, setTel,
		visitTime, setVisitTime,
		inquiry, setInquiry,
		recommendId
	} = useContext(ApplyDataContext);
	const [isApp, setIsApp] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const validate = () => {
		if (name.trim() === '') return false;
		if (tel.trim() === '') return false;
		if (visitTime === null) return false;
		if (!agreement) return false
		return true;
	}
	useEffect(() => {
		if (user.me) {
			setIsApp(true);
			setTel(user.me.tel);
		}
	}, []);
	const applyHoneymoon = () => {
		if (isLoading) return;
		setIsLoading(true);
		const params: any = {
			inpath: inpath ? inpath : 'APPLICATION',
			concept: selectedConcept,
			destination: hopePlace,
			name,
			plannerName,
			tel,
			minBudget: budget[0],
			maxBudget: budget[1],
			startDt: moment(hopeDate).format('YYYY.MM.DD'),
			times: visitTime,
			content: inquiry ? inquiry : '',
			partnerUuids: isTravelAgency ? selectedPartner : [],
		}
		if (recommendId) {
			params.honeyMoonRecommendId = +recommendId;
		}
		axios.post(`${BASE_URL}/web/v1/honeymoon/estimate`, params)
			.then(res => {
				if (res.data) {
					navigate(`/honeymoon/apply/complete?inpath=${inpath ? inpath : 'APPLICATION'}`);
				}
				setIsLoading(false);
			}).catch(_ => {
				setIsLoading(false);
			});
	}
	return (
		<div>
			<AppHeader 
				type="stack"
			/>
			<div className="page-honeymoon-apply">
				<div className="form-wrap">
					<div className="form-block">
						<div className="form-title">
							<span className="title">이름</span>
						</div>
						<div className="form-content">
							<input 
								type="text"
								className="input-text input-has-line input-full"
								placeholder="이름" 
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">연락처</span>
						</div>
						<div className="form-content">
							<input 
								type="tel"
								className="input-text input-has-line input-full"
								placeholder="010-0000-0000"
								value={autoHypenPhone(tel)}
								onChange={e => setTel(autoHypenPhone(e.target.value))} 
								readOnly={user.me ? true : false}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">추천 플래너</span>
						</div>
						<div className="form-content">
							<input 
								type="plannerName"
								className="input-text input-has-line input-full"
								placeholder="플래너 이름"
								value={plannerName}
								onChange={e => setPlannerName(e.target.value)}
							/>
							<NotiText
								text="함께 결혼 준비를 진행 중인 웨딩북 플래너가 있다면 플래너의 이름을 입력해 주세요. 없다면 공란으로 신청하시면 됩니다."
								color="gray"
								style={{margin: '-32px -20px 32px'}}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">연락 가능 시간대</span>
						</div>
						<div className="radio-button-wrap multiline item2">
							{VISIT_TIMES.map(time => (
								<label className="radio-button" key={`visit-time-${time}`}>
									<input
										type="radio" className="only-sr"
										value={time}
										checked={visitTime === time}
										onChange={e => setVisitTime(time)}
									/>
									<span className="btn btn-small btn-border">{time}</span>
								</label>
							))}
						</div>
						<NotiText
							text="* 선택하신 연락 가능 시간대에 유선 연락 드릴 예정이며, 업체 사정에 따라 다른 시간에 연락을 드릴 수 있습니다."
							style={{margin: '0 -20px'}}
						/>
					</div>
					<div style={{height: 32}}></div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">문의 또는 상담받고 싶은 내용을 써주세요.</span>
							<div className="necessary"><span>*</span>선택 입력</div>
						</div>
						<div className="form-content">
							<TextArea
								className="textarea-has-line" 
								style={{height: 96}} 
								placeholder={`기타 궁금한 점이나 요청사항을 기입해주세요.
								(최대 50자)`}
								defaultValue={inquiry}
								value={inquiry}
								onChange={e => {							
									setInquiry(emojiStrip(e.target.value).slice(0, 50));
								}}
								maxLength={50}
							/>
						</div>
					</div>
					<div className="agreement-check-wrap" style={{marginBottom: 20}}>
						<Checkbox 
							id="agreement"
							name="agreement"
							value="agreement"
							setValue={() => setAgreement(!agreement)}
							checked={agreement}
						/>
						<label className="agreement-label" style={{marginLeft: 0}}>
							<a 
								href={isApp ? isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%97%AC%ED%96%89%EC%82%AC') : 'https://www.wdgbook.com/agreement?name=%EC%97%AC%ED%96%89%EC%82%AC'}
								target={!isApp ? '_blank' : '_self'}
							>
								개인정보 수집
							</a> 및&nbsp;
							<a 
								href={isApp ? isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%97%AC%ED%96%89%EC%82%AC') : 'https://www.wdgbook.com/agreement?name=%EC%97%AC%ED%96%89%EC%82%AC'}
								target={!isApp ? '_blank' : '_self'}
							>
								제3자 활용
							</a>을 모두 읽었으며 이에 동의합니다.
						</label>
					</div>
					<button
						className={`btn btn-full btn-large btn-red ${!validate() ? 'btn-disabled' : ''}`}
						disabled={!validate()}
						onClick={applyHoneymoon}
					>
						신청하기
					</button>
				</div>
			</div>
		</div>
		
	)
}

const HoneymoonApply = () => {
	const query = useUrlQueryString();
	const inpath = query.get('inpath');
	const caseKey = query.get('caseKey');
	const recommendId = query.get('recommendId');
	
	const [selectedConcept, setSelectedConcept] = useState<string[]>([]);
	const [selectedPartner, setSelectedPartner] = useState<string[]>([]);
	const [hopePlace, setHopePlace] = useState('');
	const [noBudget, setNoBudget] = useState(false);
	const [budget, setBudget] = useState([200, 2000]);
	const [defaultBudget, setDefaultBudget] = useState<{min: number|undefined, max: number|undefined}>({min: undefined, max: undefined});
	const [hopeDate, setHopeDate] = useState<Date|null>(null);
	const [isTravelAgency, setIsTravelAgency] = useState(false);
	const [partnerList, setPartnerList] = useState<PartnerItem[]>([]);
	const [name, setName] = useState('');
	const [plannerName, setPlannerName] = useState('');
	const [tel, setTel] = useState('');
	const [visitTime, setVisitTime] = useState<string|null>(null);
	const [inquiry, setInquiry] = useState('');
	const isApplyComplete = useSelector(isComplete);

	useEffect(() => {
		if (inpath === 'RECOMMEND_HONEYMOON' && caseKey) {
			const cause = RECOMMEND_CASE_LIST[caseKey].cause;
			const result = RECOMMEND_CASE_LIST[caseKey].result;
			setSelectedConcept( [CONCEPTS.filter(concept => concept.name === cause.concept)[0].value] );
			setHopePlace(result ? result.join(', ') : '');
			const tempBudget = cause.budget.replace(/[^0-9~]/g, '').split('~');
			if (tempBudget.length >= 2) {
				// setDefaultBudget({min: +tempBudget[0], max: +tempBudget[1]})
				setBudget([+tempBudget[0], +tempBudget[1]]);
			} else {
				// setDefaultBudget({...defaultBudget, min: });
				setBudget([+tempBudget, 2000]);
			}
		}
		if (isApplyComplete) {
			alert('이미 완료된 신청서 입니다.');
			window.location.href = 'h2mwbell://webview/close';
			window.location.href = `/honeymoon/apply?inpath=${inpath ? inpath : 'APPLICATION'}`;
		}
	}, []);
	return (
		<div>
			<ApplyDataContext.Provider value={{
				selectedConcept, setSelectedConcept,
				selectedPartner, setSelectedPartner,
				hopePlace, setHopePlace,
				noBudget, setNoBudget,
				budget, setBudget,
				defaultBudget,
				hopeDate, setHopeDate,
				isTravelAgency, setIsTravelAgency,
				partnerList, setPartnerList,
				name, setName,
				plannerName, setPlannerName,
				tel, setTel,
				visitTime, setVisitTime,
				inquiry, setInquiry,
				inpath,
				caseKey,
				recommendId
			}}>
				{window.location.hash === '#apply2' 
				? <Apply2 />
				: <Apply1 />
				}
			</ApplyDataContext.Provider>
			
			
			{/* <Route exact path={match.path} component={UserList} />
			<Route path={`${match.path}/:id`} component={UserDetail} /> */}
		</div>
	);
};

export default HoneymoonApply;