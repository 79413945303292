import './textArea.scss';
import { useRef } from 'react';
import * as React from 'react';

interface Props {
	className?: string;
	style?: React.CSSProperties,
	defaultValue?: string;
	value: string;
	placeholder?: string;
	maxLength?: number;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}
const TextArea = ({className, style, defaultValue, value, placeholder, maxLength, onChange, onKeyDown}: Props) => {
	const input = useRef<HTMLTextAreaElement>(null);
	const onFocus = () => {
		if (input.current) {
			input.current.focus();
		}
	}
	return (
		<div className="textarea-wrap">
			<textarea 
				className={`textarea ${className}`}
				style={style as React.CSSProperties}
				defaultValue={defaultValue}
				ref={input}
				onKeyDown={e => {
					onKeyDown && onKeyDown(e);
				}}
				onChange={e => {
					onChange(e);
				}}
				maxLength={maxLength}
			></textarea>
			{(placeholder && value.length === 0) &&
			<div className="placeholder" onClick={onFocus}>
				{placeholder}
			</div>
			}
		</div>
	);
};

export default TextArea;