import { BASE_URL } from "@src/utils";
import axios from "../axios"
import QueryString from "qs";

// community 
export interface ICommunityListItem {
	anonymousText: string|null
	answer: null|{
		authorNick: string;
		content: string;
		regTsp: string;
	};
	block: number;
	blockV2: number;
	card: {
		content: string;
		coverUrl: string;
		isCard: number;
		link: string;
		partnerType: number;
		partnerUuid: string;
		title: string;
		type: number;
		wegazineUuid: string|null
	}[];
	content: string;
	customerUuid: string;
	id: number;
	images: {url: string}[];
	isAnonymous: number;
	isCertification: number;
	isLike: number;
	likeCnt: number;
	// messagecards: null
	myCustomerPartnerId: number;
	nick: string;
	partnerText: string;
	platformReviewId: number;
	productNo: number|null;
	profileUrl: string;
	regTsp: string;
	region: string;
	replyCnt: number;
	reviews: {
		g: number;
		k: string;
		kname: string;
		v: string;
	}[];
	state: number;
	tags: { id: number; name: string; }[]
	type: number;
	uuid: string;
	weddingMonth: string;
}
export const getCommunityList = (params: any = {}) => {
	const p = new URLSearchParams(params).toString();
	return axios.get<{item: ICommunityListItem[]}>(`${BASE_URL}/web/v3/230531/community?category=4&${p}`);
}

interface IReportParam {
	category: 1|2|3;
	uuid: string;
	content: string;
}
export const reportCommunity = ({category, uuid, content}: IReportParam) => {
	return axios.post(`${BASE_URL}/web/v3/230531/community/black/${uuid}`, {
		category: category,
		content: content
	});
}
interface IBlockParam {
	myUuid: string;
	targetUuid: string;
}
export const blockCommunity = ({myUuid, targetUuid}: IBlockParam) => {
	return axios.post(`${BASE_URL}/web/v3/230531/community/ban`, {
		customerUuid: myUuid,
		banCustomerUuid: targetUuid
	});
}

export const likeReview = (communityUuid:string, type: number, state: number) => {
	return axios.post(`${BASE_URL}/web/v3/230531/community/${communityUuid}/like`, QueryString.stringify({
		category: 2,
		type: type,
		state: state
	}))
}