import {Route, Routes} from 'react-router-dom';
import HallIndex from '../pages/booking/hall';
import SelectHall from '../pages/booking/hall/SelectHall';
import StepHopeDate from '../pages/booking/hall/StepHopeDate';
import StepVisitor from './../pages/booking/hall/StepVisitor';
import StepVisitDate from '../pages/booking/hall/StepVisitDate';
import StepCustomerInfo from '../pages/booking/hall/StepCustomerInfo';
import Complete from '@src/pages/booking/hall/Complete';
import SelectBranch from '@src/pages/booking/homeAppliance/SelectBranch';
import HomeApplianceApply from '@src/pages/booking/homeAppliance/Apply';
import HomeApplianceComplete from '@src/pages/booking/homeAppliance/Complete';
const BookingRoute = () => {
	return (
		<Routes>
			<Route path="/hall" element={<HallIndex />}/>
			<Route path="/hall/select" element={<SelectHall />} />
			<Route path="/hall/hope-date" element={<StepHopeDate />} />
			<Route path="/hall/visitor" element={<StepVisitor />} />
			<Route path="/hall/visit-date" element={<StepVisitDate />} />
			<Route path="/hall/customer-info" element={<StepCustomerInfo />} />
			<Route path="/hall/complete" element={<Complete />} />

			<Route path="/homeAppliance/select-branch" element={<SelectBranch />} />
			<Route path="/homeAppliance/apply" element={<HomeApplianceApply />} />
			<Route path="/homeAppliance/complete" element={<HomeApplianceComplete />} />
		</Routes>
	);
};

export default BookingRoute;