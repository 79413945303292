import { useLocation } from 'react-router-dom';
import QueryString from 'qs';
import PartnerTagDetail from '../home/partnerTagList/PartnerTagDetail';


export type ComponentType =
	| 'rollingBanner'
	| 'textCard'
	| 'textButton'
	| 'goalButton'
	| 'weddingBookTv'
	| 'listCard'
	| 'iconImage'
	| 'shortCut'
	| 'vendorCuration'
	| 'partnerTagList'
	| 'wegazine'
	| 'review'
	| 'favorite'
	| 'inquiryConcierge'
	| 'sdmContract'
	| 'productList'
	| 'swipeBanner'
	| 'package'
	| 'companyOrder'
	| 'stamp';
export interface ComponentItem {
	id: number;
	jsonStr: string;
	type: ComponentType;
}
interface ParserProps {
	data: ComponentItem;
}

const DetailParser = ({ data }: ParserProps) => {
	const location = useLocation();
	const query = QueryString.parse(location.search, {ignoreQueryPrefix: true});
	
	const parser = () => {
		if (!data) return;
		const cData = JSON.parse(data.jsonStr); // component data
		if (!cData) return null;
		switch (data.type) {
			case 'partnerTagList':
				const idx = query.idx as string;
				if (Number.isNaN(parseInt(idx, 10))) return;
				const selData = (cData as any[])[+idx];
				return (
					<PartnerTagDetail id={data.id} data={selData} />
				);
			default:
				return null;
		}
	};
	return <>{data !== null ? parser() : <></>}</>;
};

export default DetailParser;
