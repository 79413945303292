import { getWGfitApply } from '@src/api/recommend/recommend';
import { useState, useEffect } from 'react';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import PageTitle from '@src/components/common/pageTitle/PageTitle';

interface IUserData {
	name: string,
	tel: string;
	wantPrice: string;
	times: string;
	regTsp: string;
	selectImgs: string[];
	attachImgs: string[];
}

const UserData = () => {
	const query = useUrlQueryString();
	const uuid = query.get('uuid');
	const [userData, setUserData] = useState<IUserData|null>(null);
	useEffect(() => {
		if (!uuid) return;
		(async () => {
			const {data} = await getWGfitApply(uuid);
			setUserData(data.data);
		})();
	}, [uuid]);

	if (!uuid) return null;
	return (
		<div className="page page-wgift-userdata">
			<div className="form-wrap common-wrap" style={{maxWidth: 640, margin: '0 auto'}}>
				{userData &&
				<>
				<div style={{margin: '20px -20px'}}>
				<PageTitle 
					title={`${userData.name}님이
					${userData.regTsp.substr(0, 10)}일에 신청하신
					예물 AI 추천 내역입니다.`}
				/>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-has-line input-full"
							placeholder="이름" 
							value={userData.name}
							readOnly={true}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">연락처</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-has-line input-full"
							placeholder="010-0000-0000"
							value={userData.tel}
							readOnly={true}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">예산대</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-has-line input-full"
							value={userData.wantPrice}
							readOnly={true}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">연락 가능 시간대</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-has-line input-full"
							value={userData.times}
							readOnly={true}
						/>
					</div>
				</div>
				<div style={{height: 32}}></div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">고객님이 선택한 이미지</span>
					</div>
					<div className="form-content">
						<div className="image-upload-wrap">
							<ul>
								{userData.selectImgs.map(img => (
									<li onClick={() => window.open(img)} style={{backgroundImage: `url(${img})`}}></li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">고객님이 첨부한 이미지</span>
					</div>
					<div className="form-content">
						<div className="image-upload-wrap">
							<ul>
								{userData.attachImgs.map(img => (
									<li onClick={() => window.open(img)} style={{backgroundImage: `url(${img})`}}></li>
								))}
							</ul>
						</div>
					</div>
				</div>
				</>
				}
			</div>
		</div>
	);
};

export default UserData;