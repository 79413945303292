import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import DataSection from '@src/components/form/dataSection/DataSection';
import TextArea from '@src/components/common/textArea/TextArea';
import { useRecommendHall } from '../../../utils/hooks/useRecommendHall';
import { setOtherInquiry, setTourHopedSchedule } from '@src/@slice/recommendHallSlice';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';
import { useWindowSize } from '../../../utils/hooks/useWindowSize';
const EtcInfo = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {hallData, dispatch} = useRecommendHall();
	const {width, height} = useWindowSize();
	useEffect(() => {
		setValidate(hallData.tourHopedSchedule !== '');
	}, [hallData.tourHopedSchedule]);
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === '' || hallData.weddingRegions.length === 0 ||
			(hallData.expectedPerson.min === null && hallData.expectedPerson.max === null) || hallData.meal.types.length === 0 ||
			(hallData.meal.minPrice === null && hallData.meal.maxPrice === null) ||
			hallData.hopeWedding.yearMonths.length === 0 || hallData.hopeWedding.days.length === 0 || hallData.hopeWedding.times.length === 0 || hallData.hopeWedding.alternativeSchedules.length === 0
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	useEffect(() => {
		if (height === undefined) return;
		const div = document.getElementsByClassName('layout-fix-button')[0] as HTMLDivElement;
		const btnWrap = document.getElementsByClassName('btn-wrap')[0] as HTMLDivElement;
		if (!div) return;
		if (!btnWrap) return;
		if (height < 500) {
			div.style.height = 'auto';
			btnWrap.style.position = 'static';
		} else {
			div.style.height = window.innerHeight - 156 + 'px';
			btnWrap.style.position = 'absolute';
		}
	}, [height]);
	const goNext = () => {
		navigate('/recommend/hall/customer-info');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle title={`기타 정보를 알려주세요.`} />
					<div style={{height: 20}} />
					<DataSection
						title="웨딩홀 투어 희망일을 적어주세요."
						typeStr="필수 입력 사항"
					>
						<TextArea
							style={{height: 96}} 
							placeholder={`예) 다음 주 주말 방문을 희망해요.
							웨딩홀 후보군이 추려지면 언제든 빨리 가고 싶어요.
							아직 잘 모르겠어요.`}
							defaultValue={hallData.tourHopedSchedule}
							value={hallData.tourHopedSchedule}
							onChange={e => {
								dispatch(setTourHopedSchedule({data: e.target.value}));
							}}
						/>
					</DataSection>

					<DataSection
						title={`웨딩홀을 알아보실 때 가장 중요하게
						생각하는 점 또는 궁금하신 점을
						적어주세요.`}
						typeStr="선택 입력 사항"
					>
						<TextArea
							style={{height: 96}} 
							placeholder={`예) 저렴한 시간대에 진행하고 싶어요.
							웨딩홀 투어는 언제 가는게 좋을지 궁금해요.`}
							defaultValue={hallData.otherInquiry}
							value={hallData.otherInquiry}
							onChange={e => {
								dispatch(setOtherInquiry({data: e.target.value}));
							}}
						/>
					</DataSection>
				</div>
				<div>
					<LinkWegazine
						title="웨딩홀 투어 및 예식 일정 오픈 관련 안내"
						link="https://www.weddingbook.com/wegazine/70f3647d-6df8-11ec-b0a5-0aea5320e7f2?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EtcInfo;