import './searchPartner.scss';
import {useState, useEffect, useContext} from 'react';
import { getHallList, IPartner } from './../../../api/booking/booking';
import { useDispatch } from 'react-redux';
import { setPartnerUuids } from '@src/@slice/bookingHallSlice';
import { UserContext } from '@src/App';

interface Props {
	selectedPartner: IPartner[];
	setSelectedPartner: (partners: IPartner[]) => void;
}
const SearchPartner = ({selectedPartner, setSelectedPartner}: Props) => {
	const [keyword, setKeyword] = useState('');
	const [list, setList] = useState<IPartner[]>([]);
	const [isShowList, setIsShowList] = useState(false);
	const dispatch = useDispatch();
	const user = useContext(UserContext);
	
	useEffect(() => {
		if (keyword.trim() === '') {
			setList([]);
			return;
		};
		getHallList({ keyword: keyword, customerUuid: user.me?.uuid}).then(res => {
			// if (res.data.item.length > 0) {
				
			// }
			setList(res.data.item);
		});
	}, [keyword]);
	useEffect(() => {
		const hideList = (e: any) => {
			if (!document.querySelector('.search-partner-wrap')?.contains(e.target)) {
				setIsShowList(false);
			}
		}
		document.addEventListener('click', hideList);
		return () => {
			document.removeEventListener('click', hideList);
		}
	}, []);
	const toggleItem = (item: IPartner) => {
		setIsShowList(false);
		if (selectedPartner.filter(p => p.partnerUuid === item.partnerUuid).length) {
			setSelectedPartner(selectedPartner.filter(p => p.partnerUuid !== item.partnerUuid));
		} else {
			if (selectedPartner.length >= 5) return;
			setSelectedPartner([...selectedPartner, item]);
		}
		dispatch(setPartnerUuids({uuid: item.partnerUuid}));
	}
	return (
		<div className="search-partner-wrap">
			<input
				type="text"
				className="input-text input-has-line input-full input-search"
				onChange={e => setKeyword(e.target.value)}
				onFocus={() => setIsShowList(true)}
				placeholder="업체명을 검색해 주세요."
			/>
			{(isShowList && list.length > 0) &&
			<div className="searched-list-wrap">
				<ul>
					{list.map(partner => (
					<li onClick={() => toggleItem(partner)}>
						<div className="partner-thumb" style={{backgroundImage: `url(${partner.coverUrl})`}} />
						<span className="partner-name">{partner.partnerName}</span>
					</li>
					))}
					
				</ul>
			</div>
			}
		</div>
	);
};

export default SearchPartner;