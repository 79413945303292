import { useState, useContext } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import DataSection from '@src/components/form/dataSection/DataSection';
import WaitPage from '../../../components/common/waitPage/WaitPage';
import { PERIOD_ITEMS, CONCEPT_ITEMS, BUDGET_ITEMS, RECOMMEND_CASE_LIST} from '@src/utils/honeymoon';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@src/App';

const CASE_KEYS = Object.keys(RECOMMEND_CASE_LIST);
const Start = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [selectedPeriod, setSelectedPeriod] = useState<typeof PERIOD_ITEMS[number]|''>('');
	const [selectedConcept, setSelectedConcept] = useState<typeof CONCEPT_ITEMS[number]|''>('');
	const [selectedBudget, setSelectedBudget] = useState<typeof BUDGET_ITEMS[number]|''>('');
	const applyRecommend = () => {
		if (selectedPeriod === '') {
			alert('여행 시기를 선택해주세요.');
			return;
		}
		if (selectedConcept === '') {
			alert('여행 컨셉을 선택해주세요.');
			return;
		}
		if (selectedBudget === '') {
			alert('예산을 선택해주세요.');
			return;
		}
		CASE_KEYS.map(key => {
			const cause = RECOMMEND_CASE_LIST[key].cause;
			if (cause.period === selectedPeriod && cause.concept === selectedConcept && cause.budget === selectedBudget) {
				navigate(`/recommend/honeymoon/result?key=${key}`);
			}
		});
	}
	return (
		<div>
			<AppHeader 
				type="stack"
			/>
			<div className="recommend-honeymoon-start-top">
				<div>
					<b>{`신혼여행 어디로 갈지 
					고민되시나요?`}</b>
					<p>{user.me ? user.me.nick : '고객'}님의 취향에 딱 맞는 여행지를 추천해 드릴게요.</p>
				</div>
			</div>
			<div className="recommend-honeymoon-poll">
				<div style={{height: 16}}/>
				<DataSection
					title="여행 시기 선택하기"
					desc={`신혼여행을 언제 떠날지 선택해 주세요. 
					각 여행지의 추천 여행 시기에 맞춰 추천해 드립니다.`}
				>
					<ul className="selectable-list-wrap items-2">
						{PERIOD_ITEMS.map((period, idx) => (
						<li 
							onClick={() => {
								setSelectedPeriod(period);
							}}
							key={`period-list-${idx}`}
						>
							<div className={selectedPeriod === period ? 'on' : ''}>{period}</div>
						</li>
						))}
					</ul>
				</DataSection>
				<div style={{height: 30}}/>
				<DataSection
					title="내 여행 컨셉 선택하기"
					desc={`두 가지 컨셉 중 중요하다고 생각하는 컨셉을 선택해 주세요.`}
				>
					<ul className="selectable-list-wrap items-2">
						{CONCEPT_ITEMS.map((concept, idx) => (
						<li 
							onClick={() => {
								setSelectedConcept(concept);
							}}
							key={`concept-list-${idx}`}
						>
							<div className={selectedConcept === concept ? 'on' : ''}>{concept}</div>
						</li>
						))}
					</ul>
				</DataSection>
				<div style={{height: 30}}/>
				<DataSection
					title="예산 선택하기"
					desc={`2인 기준 대략적인 예산을 선택해 주세요.`}
				>
					<ul className="selectable-list-wrap items-2">
						{BUDGET_ITEMS.map((budget, idx) => (
						<li 
							onClick={() => {
								setSelectedBudget(budget);
							}}
							key={`budget-list-${idx}`}
						>
							<div className={selectedBudget === budget ? 'on' : ''}>{budget}</div>
						</li>
						))}
					</ul>
				</DataSection>
				<div className="btn-wrap">
					<button 
						className="btn btn-full btn-large btn-red btn-recommend-honymoon"
						onClick={applyRecommend}
					>
						추천 요청하기
					</button>
				</div>
			</div>
			
		</div>
	);
};

export default Start;