import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import InquiryConcierge from './../../../components/home/inquiryConcierge/InquiryConcierge';
import NotiBox from './../../../components/common/notiBox/notiBox';
import { getRecommendHall } from '@src/api/recommend/recommend';
import dayjs from 'dayjs';
const Result = () => {
	const {uuid} = useParams<{uuid: string}>();
	const [sentTsp, setSentTsp] = useState(null);
	const [hallList, setHallList] = useState<any[]>([]);
	const tagFinder = (arr: any[], t: number) => {
		let tag = arr.find(e => e.type === t);
		return tag ? tag.name.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'xx';
	};
	useEffect(() => {
		getRecommendHall(uuid).then(res => {
			const item = res.data.item;
			setHallList(item.partners);
			setSentTsp(item.sentTsp); 
		});
	}, [uuid]);
	return (
		<div className="recommend-result-wrap">
			<div className="recommend-result-top">
				<h1 className="result-title">
					홀 <img width={56} src="https://firebasestorage.googleapis.com/v0/b/weddingbookpro.appspot.com/o/common%2FimgTxt%403x.png?alt=media&token=a676a922-394e-4e02-bf7e-ac181d93fd7b" alt="weddinghall" /><br/>
					추천리스트
				</h1>
				<p>
					{`고객님에게 추천드리는 웨딩홀 리스트입니다
					더 알아보고 싶은 웨딩홀은 하단의 버튼을 눌러주세요`}
				</p>
			</div>
			<div style={{marginTop: -80, paddingBottom: 32}}>
				<ul className="hall-list-wrap">
					{hallList.map(hall => (
					<li>
						<div className="hall-thumb" style={{backgroundImage: `url(${hall.coverUrl})`}}></div>
						<div className="hall-info-wrap">
							<b className="hall-name">{hall.name}</b>
							<dl>
								<div>
									<dt>지역</dt>
									{/* <dd>{`${tagFinder(hall.tags, 1)} ${tagFinder(hall.tags, 2)}`}</dd> */}
									<dd>{hall.region}</dd>
								</div>
								<div>
									<dt>식사 타입</dt>
									<dd>{`${tagFinder(hall.tags, 15)}예식, ${tagFinder(hall.tags, 4)}`}</dd>
								</div>
								<div>
									<dt>예식 시간</dt>
									<dd>{`${tagFinder(hall.tags, 13)}분`}</dd>
								</div>
								<div>
									<dt>메모</dt>
									<dd>{hall.memoContent}</dd>
								</div>
							</dl>
							<button className="btn bold btn-full btn-brown" onClick={() => window.location.href = `h2mwbell://partner/${hall.uuid}`}>자세히 보기</button>
						</div>
					</li>
					))}
				</ul>
				<NotiBox>
					{sentTsp &&
					<p>- 본 보고서는 {dayjs(sentTsp).format('YYYY-MM-DD HH:mm:ss')}에 작성되었으며, 이후시간 업체정보 및 가격이 달라질 수 있습니다.</p>
					}
					<p>- 웨딩홀에 직접 문의 또는 연락 시 웨딩북 혜택 대상에서 제외되므로 아래 문의하기 버튼을 눌러 문의주세요.</p>
				</NotiBox>
			</div>
			<InquiryConcierge page="recommend_hall_result" partnerTypeName="할인가, 잔여타입 등" />
		</div>
	);
};

export default Result;