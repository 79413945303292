import moment, {Moment} from "moment";
export const DAYS = ['일', '월', '화', '수', '목', '금', '토', '일'];

export const getDiffFromNow = (d: string|Date|Moment, unitOfTime: moment.unitOfTime.Diff | undefined)  => {
	const today = moment();
	let date;
	if (d instanceof moment) {
		date = d;
	} else {
		date = moment(d);
	}
	return today.diff(date, unitOfTime);
}
export const getDiffToString = (d: string|Date|Moment) => {
	const today = moment();
	let date;
	if (d instanceof moment) {
		date = d;
	} else {
		date = moment(d);
	}
	const diffDays = today.diff(date, 'days');
	if (diffDays < 1) {
		const diffHours = today.diff(date, 'hours');
		if (diffHours < 1) {
			return today.diff(date, 'minutes') + '분 전';
		} else {
			return diffHours + '시간 전';
		}
	}
	if (diffDays < 7) {
		return diffDays + '일 전';
	} else if (diffDays < 31) {
		return today.diff(date, 'weeks') + '주 전';
	} else if (diffDays < 365) {
		return today.diff(date, 'months') + '개월 전';
	} else {
		return today.diff(date, 'years') + '년 전';
	}
}
export const getDiffFromToday = (d: string|Date|Moment, unitOfTime: moment.unitOfTime.Diff | undefined)  => {
	if (!d) return 0;
	const today = moment().startOf('day');
	let date;
    if (typeof d === 'string' || d instanceof Date) {
		date = moment(d).startOf('day');
	} else {
		date = d.startOf('day');
	}
    return today.diff(date, unitOfTime);
}