import {IFaq} from "../../types/interface/Chungdam";
import {useState} from "react";
import DropDownUpArrow from "../../assets/images/chungdam/icDropdownUpArrow@3x.png";
import DropDownDownArrow from "../../assets/images/chungdam/icDropdownDownArrow@3x.png";

type FaqItemProps = {
    faq: IFaq
}

const FaqItem = (props: FaqItemProps) => {
    const {faq} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openOrClose = () => setIsOpen(!isOpen);

    return (
        <div>
            <div className={'faq-title'} onClick={openOrClose}>
                <p className={'golden-title'}>
                    Q.
                    <span className={'item-title'}>{faq.question}</span>
                </p>
                <img alt={'+'} src={isOpen ? DropDownUpArrow : DropDownDownArrow} width={20}/>
            </div>
            {isOpen && (
                <div className={'content'}>
                    <p>{faq.answer}</p>
                </div>
            )}
        </div>
    )
}

export default FaqItem;
