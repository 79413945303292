import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import {setCustomerUuid, setVenueInfo} from '@src/@slice/realtimeBookingSlice';
import BottomFixedModal from "@src/components/common/bottomFixedModal/BottomFixedModal";
import IconNotice from "@assets/images/icon/ic_inforcircle_nor_24.svg";
import IconX from "../../../assets/images/icon/ic-x-g900.svg";
import { getVenueInfo } from "../../../api/realtimeBooking/realtimeBooking";
import { UserContext } from "@src/App";
import {ReactComponent as CheckIcon} from "@assets/images/icon/checkmark_p500.svg";

const RealTimeBottomFixedModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const partnerId = params.id;
    const modalRef = useRef<HTMLDivElement>(null);
    const user = useContext(UserContext);

    const [uuid, setUuid] = useState<string | null>(null)
    const [partnerInfo, setPartnerInfo] = useState(null)
    const [isShowModal] = useState(true);
    const [isShowDepositInfo, setIsShowDepositInfo] = useState(false);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)){
            setIsShowDepositInfo(false);
        }
    };

    useEffect(() => {
        if (user.me?.uuid) {
            setUuid(user.me.uuid);
        }
    }, [user.me]);

    useEffect(() => {
        if (isShowDepositInfo) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isShowDepositInfo]);

    useEffect(() => {
        getVenueInfo(partnerId)
        .then((res) => {
            setPartnerInfo(res.data.data);
            dispatch(setVenueInfo({ data: res.data.data }));
            sessionStorage.setItem('hallName', res.data.data.name);
        });

        if (uuid) {
            dispatch(setCustomerUuid({ uuid }));
            sessionStorage.setItem('uuid', uuid);
        }

    }, [dispatch, partnerId, uuid]);

    return (
        <div className="page-noti">
            <BottomFixedModal isShow={isShowModal} title="" hideModal={() => window.location.href = 'h2mwbell://webview/close'}>
                <div className="booking-real-time-noti-wrap">
                    <div className="noti-title">
                        <p>🤗</p>
                        <h2>방문상담 예약 전 확인해주세요!</h2>
                    </div>
                    {partnerInfo &&
                    <div className="noti-info">
                        <div>
                            <p>웨딩홀</p>
                            <p>
                                보증금
                                <img src={IconNotice} alt="" onClick={() => setIsShowDepositInfo(true)}/>
                            </p>
                        </div>
                        <div>
                            <p>{partnerInfo.name}</p>
                            <p className="price">{partnerInfo.deposit?.toLocaleString('ko-KR') || 0}원</p>
                        </div>
                    </div>}
                    <div className="time-info">
                        <h4 className="title"><CheckIcon/>방문상담 시간안내</h4>
                        <ul className="info-subscribe">
                            <li> 웨딩홀 상담에는 40~60분 정도 소요됩니다.</li>
                            <li>
                                 여러 웨딩홀을 예약하는 경우, 웨딩홀 이동 거리와 대기시간 등을 고려하여 2시간 간격으로 예약해 주시면 가장 좋습니다.
                            </li>
                            <li>
                                 평일 방문 시 홀 내부를 보실 수 없거나 홀 세팅을 보실 수 없는 경우가 있어 주말 방문을 추천드립니다.
                            </li>
                        </ul>
                    </div>
                    <div className="noti-btn-wrap">
                        <button className="btn btn-full btn-light-red" 
                            onClick={() => {
                                window.location.href = 'h2mwbell://webview/close';
                            }}>
                            취소
                        </button>
                        <button
                            // className={`btn btn-full btn-red ${partnerInfo.consultingUsed ? 'btn-disabled':''}`}
                            className={`btn btn-full btn-red`}
                            onClick={() => navigate(`/booking/real-time/${partnerId}/visit-date`)}
                            // disabled={partnerInfo.consultingUsed}
                        >
                            확인
                        </button>
                    </div>
                    {isShowDepositInfo && 
                    <div className="deposit-noti-popup" ref={modalRef}>
                        <div>
                            <h2>보증금 안내사항</h2>
                            <img src={IconX} alt="" onClick={() => setIsShowDepositInfo(false)}/>
                        </div>
                        <ul className="deposit-text-wrap">
                            <li>
                                예약 시 상담 보증금 {partnerInfo.deposit.toLocaleString()}원 결제가
                                필요합니다. 상담 보증금은 <strong>예약된 일정에
                                방문하시면 전액 환불</strong>됩니다.
                            </li>
                            <li>
                                예정 방문일로부터 5일 전까지 1회에 한하여
                                변경 및 취소가 가능합니다.
                            </li>
                            <li>
                                예약된 시간에 10분 이상 늦으실 경우 예약은
                                취소되며, 보증금 환불이 불가합니다.
                            </li>
                        </ul>
                    </div>}
                </div>
            </BottomFixedModal>
        </div>
    );
};

export default RealTimeBottomFixedModal;
