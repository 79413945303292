import './tagList.scss';

export interface ITag {
	tagId: string;
	tagName: string;
}
export interface ITags {
	red: ITag[],
	green: ITag[],
	gray: ITag[],
	brown: ITag[]
}
const TagList = ({id, tags}: {id: string, tags: ITags}) => {
	const keys = (Object.keys(tags) as Array<keyof typeof tags>);

	return (
		<div className="tags-wrap">
			{keys.map(key => {
				const tagList = tags[key];
				return tagList.map((tag, idx) => (
					<span className={`tag tag-${key}`} key={`tag-list-${idx}`}>{tag.tagName}</span>
				))
			})}
		</div>
	);
};

export default TagList;