import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import BookingTitle from '../../../components/booking/bookingTitle/BookingTitle';
import dayjs from 'dayjs';
import NotiBox from './../../../components/common/notiBox/notiBox';
import DataSection from '@src/components/form/dataSection/DataSection';
import Checkbox from '../../../components/common/checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { bookingHallData, setYearMonths, setTime, setDays, setAlternativeSchedules, setAdditionalInquiry } from '@src/@slice/bookingHallSlice';
import RedBoxList from './../../../components/booking/redBoxList/RedBoxList';
import { historyLen } from '@src/@slice/historySlice';
import TextArea from './../../../components/common/textArea/TextArea';

const MonthSelectArea = () => {
	const LOOP_COUNT = 19; // 18 months + 1 (current)
	const now = dayjs();
	const [currentYearCount, setCurrentYearCount] = useState(0);
	const [nextYearCount, setNextYearCount] = useState(0);
	const [yearAfterNext, setYearAfterNext] = useState(0)
	const hallData = useSelector(bookingHallData);
	const dispatch = useDispatch();
	useEffect(() => {
		setCurrentYearCount(13 - (now.get('month') + 1));
	}, []);
	useEffect(() => {
		let yc = LOOP_COUNT - currentYearCount;
		setNextYearCount(yc > 12 ? 12 : yc);
		// 12보다 큰 경우는 다다음년도도 존재하는 케이스
		if (yc > 12 && currentYearCount > 0) setYearAfterNext(yc - 12);
	}, [currentYearCount]);
	return (
		<>
		<div className="month-selector-wrap">
			<span className="year">{now.get('year')}년</span>
			<ul className="selectable-list-wrap items-3">
				{Array.from(Array(currentYearCount).keys()).reverse().map(i => (
					<li key={`select-month-${now.get('year')}-${12 - i}`}>
						<div
							className={hallData.hopeWedding.yearMonths.indexOf(`${now.get('year')}년 ${12 - i}월`) > -1 ? 'on' : ''}
							onClick={() => dispatch(setYearMonths({date: `${now.get('year')}년 ${12 - i}월`}))}
						>
							{12 - i}월
						</div>
					</li>
				))
				}
			</ul>
		</div>

		<div className="month-selector-wrap">
			<span className="year">{now.get('year') + 1}년</span>
			<ul className="selectable-list-wrap items-3">
				{Array.from(Array(nextYearCount).keys()).map(i => (
					<li key={`select-month-${now.get('year') + 1}-${i + 1}`}>
						<div
							className={hallData.hopeWedding.yearMonths.indexOf(`${now.get('year') + 1}년 ${i + 1}월`) > -1 ? 'on' : ''}
							onClick={() => dispatch(setYearMonths({date: `${now.get('year') + 1}년 ${i + 1}월`}))}
						>
							{i + 1}월
						</div>
					</li>
				))
				}
			</ul>
		</div>
		{yearAfterNext > 0 &&
		<div className="month-selector-wrap">
			<span className="year">{now.get('year') + 2}년</span>
			<ul className="selectable-list-wrap items-3">
				{Array.from(Array(yearAfterNext).keys()).map(i => (
					<li key={`select-month-${now.get('year') + 2}-${i + 1}`}>
						<div
							className={hallData.hopeWedding.yearMonths.indexOf(`${now.get('year') + 2}년 ${i + 1}월`) > -1 ? 'on' : ''}
							onClick={() => dispatch(setYearMonths({date: `${now.get('year') + 2}년 ${i + 1}월`}))}
						>
							{i + 1}월
						</div>
					</li>
				))
				}
			</ul>
		</div>
		}
		</>
	)
}

const strData = {
	days: ['토요일', '일요일', '평일'],
	// times: ['12시 ~ 14시', '11시 or 15시', '16시 이후'],
	times: ['11시', '12시 ~  14시', '15시 ~ 16시', '17시 이후'],
	alternativeSchedules: ['아니요, 다른 웨딩홀을 보겠습니다.', '네, 요일 변경도 괜찮습니다.', '네, 시간대 변경도 괜찮습니다.', '네, 3개월 이상 예식 일정 조정도 괜찮습니다.']
};
const StepHopeDate = () => {
	const historyLength = useSelector(historyLen);
	const hallData = useSelector(bookingHallData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [validate, setValidate] = useState(false);
	useEffect(() => {
		if (
			hallData.hopeWedding.yearMonths.length === 0 ||
			hallData.hopeWedding.days.length === 0 ||
			hallData.hopeWedding.times.length === 0 ||
			hallData.hopeWedding.alternativeSchedules.length === 0
			) {
			setValidate(false);
		} else {
			setValidate(true);
		}
	}, [hallData.hopeWedding]);
	const goNext = () => {
		navigate('/booking/hall/visitor');
	}
	return (
		<>
		{/* <AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} /> */}
		<div>
			<BookingTitle 
				title={`희망 예식 일정을 
				알려주세요.`}
			/>
			<DataSection
				title="희망 예식월을 선택해 주세요."
				typeStr="필수 입력 사항"
			>
				<MonthSelectArea />
			</DataSection>
			<RedBoxList 
				items={hallData.hopeWedding.yearMonths.map((item, idx) => (
					{origin: item, data: `${idx + 1}순위: ${item}`}
				))}
				removeItem={item => dispatch(setYearMonths({date: item}))}
			/>
			<NotiBox>
				<p>웨딩홀은 방문 상담 전 예식 일정 전체를 안내해 주지 않으므로, 1순위 예식 일정을 우선적으로 안내 예정이며 추가 일정은 방문 상담 시 안내받으실 수 있습니다.</p>
			</NotiBox>
			<DataSection
				title="희망 예식 요일을 선택해 주세요."
				typeStr="중복 선택 가능"
			>
				<ul className="selectable-list-wrap items-3">
					{strData.days.map((day, idx) => (
					<li key={`hopewedding-days-${idx}`}>
						<div
							className={hallData.hopeWedding.days.indexOf(day) > -1 ? 'on' : ''}
							onClick={() => dispatch(setDays({day: day}))}
						>
							{day}
						</div>
					</li>
					))}
				</ul>
			</DataSection>
			<RedBoxList 
				items={hallData.hopeWedding.days.map((item, idx) => ({
					origin: item,
					data: `${idx + 1}순위: ${item}`
				}))}
				removeItem={item => dispatch(setDays({day: item}))}
			/>
			<DataSection
				title="희망 예식 시간을 선택해 주세요."
				typeStr="필수 입력 사항"
			>
				<ul className="checkbox-list-wrap">
					{strData.times.map((time, idx) => (
					<li key={`hopewedding-items-${idx}`}>
						<Checkbox 
							id={`time-${idx}`}
							name="time"
							value={time}
							setValue={() => dispatch(setTime({time: time}))}
							checked={hallData.hopeWedding.times.indexOf(time) > -1}
						/>
						<label htmlFor={`time-${idx}`}>{time}</label>
					</li>
					))}
				
				</ul>
			</DataSection>
			<RedBoxList 
				items={hallData.hopeWedding.times.map((item, idx) => ({
					origin: item, data: `${idx + 1}순위: ${item}`
				}))}
				removeItem={item => dispatch(setTime({time: item}))}
			/>
			<DataSection 
				title={`월/요일/시간 중 
				어떤 조건이 제일 중요하신가요?`} 
				typeStr="선택 입력 사항"
			>
				<TextArea
					className="textarea" 
					style={{height: 96}} 
					placeholder={`예) 시간대가 가장 중요합니다.
					11~12월이면 시간이나 요일은 조정 가능합니다.
					지방 하객이 있어서 토요일 점심 예식만 가능합니다.`}
					defaultValue={hallData.hopeWedding.additionalInquiry}
					value={hallData.hopeWedding.additionalInquiry}
					onChange={e => {
						dispatch(setAdditionalInquiry({key: 'hopeWedding', data: e.target.value}));
					}}
				/>
				{/* <textarea
					className="textarea" 
					style={{height: 96}} 
					placeholder={`예) 0월 0일, 0월 0일 예식만 원해요.\r\n0시 예식만 원해요.\r\n오전 or 저녁 예식만 원해요.`}
					defaultValue={hallData.hopeWedding.additionalInquiry}
					onChange={e => {
						dispatch(setAdditionalInquiry({key: 'hopeWedding', data: e.target.value}));
					}}
				/> */}
			</DataSection>

			<DataSection
				title={`예식 일정이 마감된 경우, 
				다른 일정으로 안내해 드릴까요? `}
				typeStr="중복 선택 가능"
			>
				<ul className="checkbox-list-wrap">
					{strData.alternativeSchedules.map((schedule, idx) => (
					<li key={`hopewedding-alternative-${idx}`}>
						<Checkbox 
							id={`schedule-${idx}`}
							name="schedule"
							value={schedule}
							setValue={() => dispatch(setAlternativeSchedules({schedule : schedule}))}
							checked={hallData.hopeWedding.alternativeSchedules.indexOf(schedule) > -1}
						/>
						<label htmlFor={`schedule-${idx}`}>{schedule}</label>
					</li>
					))}
				</ul>
			</DataSection>
			<div className="btn-wrap">
				<button disabled={!validate} className={`btn bold btn-large btn-full btn-red ${!validate ? 'btn-disabled' : ''}`} onClick={goNext}>다음</button>
				{/* <Link to="/booking/hall/visitor" className="btn bold btn-large btn-full btn-red">다음</Link> */}
			</div>
		</div>
		</>
	);
};

export default StepHopeDate;