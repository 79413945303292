import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setCustomer } from "./bookingHallSlice";

export interface Hall {
    partnerId: number;
    name: string;
    storedToConsulting: boolean;
    deposit: number | null;
    weddingOpenDate: string | null;
    reservationOpenDate: number | null;
    minPersonnel: number | null;
    sugPersonnel: number | null;
    reservationNotice: string | null;
    consultingSlotsDisplayed?: boolean;
    consultingUsed?: boolean; 
}


export interface realtimeBookingHallState {
    hall:Hall,
    data:{
        partnerId: number;
        visitDate: string;
        visitTime: string;
        expectedMinPersonnel: number;
        expectedMaxPersonnel: number;
        hopeWeddingYearMonths: string[];
        hopeWeddingDaysOfTheWeek: string[];
        hopeWeddingTimes: string[];
        groomName: string;
        groomTel: string;
        brideName: string;
        brideTel: string;
        requestedByPlanner: boolean;
        plannerName?: string;
        plannerTel?: string;
        plannerConsultingPartnerName?: string;
        additionalInquiry: string;
        paymentAmount: number;
    },
    user:{
        uuid?: string;
    }
}

const initialState = {
    hall:{
        partnerId: 0,
        name: "",
        storedToConsulting: false,
        deposit: 0,
        weddingOpenDate: "",
        reservationOpenDate: 14,
        minPersonnel: 0,
        sugPersonnel: 0,
        reservationNotice: "",
    },
    data:{
        partnerId: 0,
        visitDate: "",
        visitTime: "",
        expectedMinPersonnel: 0,
        expectedMaxPersonnel: 0,
        hopeWeddingYearMonths: [],
        hopeWeddingDaysOfTheWeek: [],
        hopeWeddingTimes: [],
        groomName: "",
        groomTel: "",
        brideName: "",
        brideTel: "",
        requestedByPlanner: false,
        additionalInquiry: "",
        paymentAmount: 0,
    },
    user:{
        uuid: ''
    }
} as realtimeBookingHallState;

const realtimeBookingSlice = createSlice({
    name: "realtime-booking",
    initialState,
    reducers: {
        setVenueInfo(state, action: PayloadAction<{ data: Hall }>){
            const { data } = action.payload;
            state.hall = data;
            state.data.paymentAmount = data.deposit;
            state.data.partnerId = data.partnerId;
        },
        setDate(state, action: PayloadAction<{ date: string }>) {
            const { date } = action.payload;
            state.data.visitDate = date;
        },
        setTime(state, action: PayloadAction<{ time: string }>) {
            const { time } = action.payload;
            state.data.visitTime = time.length > 5 ? time : time + ':00';
        },
        setVisitor(state, action: PayloadAction<{ visitor: number }>) {
            const { visitor } = action.payload;
            if (visitor < 300) {
                state.data.expectedMinPersonnel = visitor;
                state.data.expectedMaxPersonnel = visitor + 50 >= 300 ? 300 : visitor + 50;
            } else {
                state.data.expectedMinPersonnel = 300;
                state.data.expectedMaxPersonnel = 0;
            }
        },
        setYearMonths(state, action: PayloadAction<{ date: string[] }>) {
            const { date } = action.payload;
            state.data.hopeWeddingYearMonths = date;
    
        },
        setDaysOfTheWeek(state, action: PayloadAction<{ day: string[] }>) {
            const { day } = action.payload;
            state.data.hopeWeddingDaysOfTheWeek = day;

        },
        setHopeTimes(state, action: PayloadAction<{ time: string[] }>) {
            const { time } = action.payload;
            state.data.hopeWeddingTimes = time;

        },
        setGroomName(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            state.data.groomName = name;
        },
        setBrideName(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            state.data.brideName = name;
            console.log(state.data.brideName)
        },
        setGroomTel(state, action: PayloadAction<{ tel: string }>) {
            const { tel } = action.payload;
            state.data.groomTel = tel;
        },
        setBrideTel(state, action: PayloadAction<{ tel: string }>) {
            const { tel } = action.payload;
            state.data.brideTel= tel;
        },
        setRequestedByPlanner(state, action: PayloadAction<{ check: boolean }>){
            const { check } = action.payload;
            state.data.requestedByPlanner = check
        },
        setPlanerName(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            state.data.plannerName = name;
        },
        setPlanerTel(state, action: PayloadAction<{ tel: string }>) {
            const { tel } = action.payload;
            state.data.plannerTel= tel;
        },
        setPlanerCompany(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            state.data.plannerConsultingPartnerName = name;
        },
        setAdditionalInquiry(state, action: PayloadAction<{ body: string }>) {
            const { body } = action.payload;
            state.data.additionalInquiry = body;
        },
        setCustomerUuid(state, action: PayloadAction<{ uuid: string }>) {
            const { uuid } = action.payload;
            state.user.uuid = uuid;
        },
    },
});

export const {
    setVenueInfo, setDate,setTime,setVisitor,setYearMonths,setDaysOfTheWeek,setHopeTimes,
    setGroomName,setBrideName, setGroomTel,setBrideTel,setRequestedByPlanner,
    setPlanerName,setPlanerTel,setPlanerCompany, setAdditionalInquiry, setCustomerUuid
} = realtimeBookingSlice.actions;
export const realtimeBooking = (state: RootState) => state.realtimeBooking;
export default realtimeBookingSlice.reducer;
