import axios from 'axios';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { BASE_URL } from './../../utils/index';
import DetailParser, {ComponentItem} from './../../components/parser/DetailParser';

const ProductComponentDetail = () => {
	const { componentId } = useParams<{componentId: string}>();
	const location = useLocation();
	const pageType = location.pathname.indexOf('home') > -1 ? 'home' : 'product'
	const [data, setData] = useState<ComponentItem|null>(null);
	useEffect(() => {
		axios.get<{item: ComponentItem}>(`${BASE_URL}/v3/${pageType}/component/${componentId}/info`).then(res => {
			setData(res.data.item);
		})
	}, [])
	return (
		<>
			{data &&
			<DetailParser data={data}/>
			}
		</>
	);
};

export default ProductComponentDetail;