import { logEvent } from '@src/firebase';
import { useState, useEffect } from 'react';
import axios from '../api/axios';
import Logo from '../assets/images/footer/img-footer-logo.png';
import AppHeader from '../components/common/appHeader/AppHeader';
import Parser, { ComponentItem } from '../components/parser/Parser';
import { BASE_URL, isExternalUrl } from './../utils/index';
import { useDispatch } from 'react-redux';
import { setNeedLogin } from '@src/@slice/globalSlice'; 
// router
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '@src/utils/hooks/useUserActions';


const Home = () => {
	const [data, setData] = useState<ComponentItem[] | null>(null);
	const dispatch = useDispatch();

	const navigate = useNavigate();
	// location
	const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
	const loginToken = queryParams.get('loginToken') || '';
	const { socialLoginAction } = useUserActions();
	let beforeLoginToken:string = '';

	useEffect(() => {
		if (loginToken) {
			if(beforeLoginToken === loginToken) return;
			else beforeLoginToken = loginToken;
			socialLoginAction(loginToken);
			queryParams.delete('loginToken');
			const search = queryParams.toString() ? '?' + queryParams.toString() : '';
			navigate(location.pathname + search);
		}
	}, [loginToken]);
	
	useEffect(() => {
		if (!loginToken) {
			dispatch(setNeedLogin({bool: true}));
		} else {
			dispatch(setNeedLogin({bool: false}));
		}
		return () => {
			dispatch(setNeedLogin({bool: false}))
		}
	}, []);
	useEffect(() => {
		logEvent('page_behavior', {
			page: 'home',
			behavior: 'page_view'
		});
		axios.get(BASE_URL + '/v3/home/component').then((res: any) => {
			const d: { item: ComponentItem[]; result: any; total: any } = res.data;
			setData(d.item);
		}).catch(err => {
			console.log(err);
		});
	}, []);
	return (
		<>
		<AppHeader type="main" actionItems={[
			{name: 'search'},
			{name: 'chat'},
			{name: 'push'}
		]} />
		<div className="page page-home">
			{data && <Parser data={data} page={'home'} />}
            {/* <p onClick={()=>navigate('/booking/real-time/management/list')}>실시간 방문 예약내역 확인</p> */}
		</div>
		{data &&
			<footer className="footer">
				<img src={Logo} width="140" alt="wedding book" />
				<p className="wb-info">
					(주)웨딩북<br/>
					대표자 : 주상돈<br/>
					주소 : 서울특별시 강남구 선릉로 145길 11 2층<br/>
					전화번호 : 1566-9562<br/>
					전자우편주소 : info@weddingbook.com<br/>
					사업자등록번호 : 220-88-77848 <br/>
					통신판매업신고 : 제 2014-서울강남-03451호
				</p>
				<ul>
					<li>
						<a href={isExternalUrl(`https://docs.google.com/forms/d/e/1FAIpQLSfa2j3QJJLsTCJAUsWY1Nqd2Erwp-rzztYvGguvwLeKTi7vSA/viewform`)}>제휴문의</a>
					</li>
					<li>
						<a href={isExternalUrl(`https://wdgbook.com/app/info/cpinfo.html`)}>사업자정보 확인</a>
					</li>
					<li>
						<a href={isExternalUrl(`https://wdgbook.com/app/info/service.html`)}>이용약관</a>
					</li>
					<li>
						<a href={isExternalUrl(`https://wdgbook.com/app/info/privacy.html`)}>개인정보 처리방침</a>
					</li>
				</ul>
				<p>
					웨딩북은 통신판매중개자이며 통신판매의 당사자가 아니며<br />
					상품거래정보 및 거래에 대한 책임은 각 판매자에게 있습니다.
				</p>
			</footer>
		}
		</>
	)
};

export default Home;
