import { useState } from 'react';
const PRODUCT_MAX = 4;

const DetailProduct = ({data, partnerName, idx}: any) => {
	const [isShowMore, setIsShowMore] = useState(false);
	var products = data.products;
	
	return (
		<div className="company-product-product-items" style={{marginBottom: data.bottomMargin}}>
			<h3 className="product-sub-title type2">{partnerName} 인기상품</h3>
			{(products !== null && products.length > 0) &&
			<ul className="clearfix">
				{products.map((product: any, pIdx: number) => (
				<li style={{display: ((pIdx+1) <= 4 || isShowMore) ? 'block' : 'none'}} key={`${idx}-product-${pIdx}`}>
					<div className="item-img" style={{backgroundImage: `url(https://imgs.h2m.io/?url=${product.imgUrl}&w=1200)`, filter: `progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\'https://imgs.h2m.io/?url=${product.imgUrl}&w=1200\',sizingMethod=\'scale\')` }}></div>
					<div className="item-info">
						<h4 className="big">{product.title}</h4>
						<p className="item-price">
						{(product.salePrice !== null && product.salePrice !== '') 
						? <>{product.salePrice} <s>{product.originPrice}</s></>
						: product.originPrice
						}
						</p>
						<div>
							{(product.rewardN1Title !== null && product.rewardN1Title !== '') &&
							<>
							<span className="badge" style={{backgroundColor: `#${product.rewardN1Color}`}}>{product.rewardN1Title}</span>
							<span className="bold">{product.rewardN1Content}</span>
							</>
							}
							{(product.rewardN2Title !== null && product.rewardN2Title !== '') &&
							<>
							<span className="badge" style={{backgroundColor: `#${product.rewardN2Color}`}}>{product.rewardN2Title}</span>
							<span className="bold">{product.rewardN2Content}</span>
							</>
							}
						</div>
					</div>
				</li>
				))}
			</ul>
			}
			{products.length > PRODUCT_MAX && 
			<button className="btn btn-large btn-full btn-product" onClick={() => setIsShowMore(true)}>
				인기상품 더보기
				<span className="icon icon-dropdown" ></span>
			</button>
			}
		</div>
	);
};

export default DetailProduct;