import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
// utils
import { autoHypenPhone, chkPhoneTel } from '@src/utils';
// hooks
import { useAuthCall } from '@src/utils/hooks/useSignValidation';
// components
import Blank from '../blank/Blank';
import InputTypeBox from '../inputTypeBox/InputTypeBox';

// Props
interface Props {
    timerSeconds?: number;
	defaultValue?: string;
    value: string;
    done: boolean;
    type?: {type?: string, data?: any};
    onVerify?: (isVerified: boolean, phoneNumber?:string) => void;
    onDataFilled?: (isFilled: boolean, phoneNumber?:string) => void;
    onMethodFilled?: ( phoneNumber:string) => void;
}

// 초기값
const INITIAL_TIMER_SECONDS = 180; //인증코드 만료시간
const PHONE_NUMBER_LENGTH = 13; //휴대전화번호 길이
const VERIFICATION_CODE_LENGTH = 4; //인증코드 길이

// 인증코드 메세지
const VERIFICATION_NOT_MATCHED = "인증번호가 잘못되었습니다."; //인증번호 불일치
const VERIFICATION_EXPIRED = "인증시간이 만료되었습니다."; //인증시간 만료
const VERIFICATION_PASS = "인증이 완료되었습니다."; //인증성공
const VERIFICATION_NOT_FILLED = "인증번호를 입력해주세요"; //인증번호 미입력

// 인증방법 메세지
const METHOD_ALREADY_VERIFIED = "이미 인증된 번호입니다."; //이미 인증된 번호
const METHOD_INVALID = "올바른 휴대전화 번호를 입력해주세요."; //잘못된 휴대전화 번호
const METHOD_SERVER_ERROR = "잠시 후 다시 시도해 주세요."; //서버 오류
const METHOD_TRY_LIMIT_ERROR = "잠시 후 다시 시도해 주세요."; //인증 시도 횟수 초과
const METHOD_PASS = ""; //사용 가능한 경우 

// 타이머 훅
function useTimer(initialSeconds: number) {
	const [timer, setTimer] = useState(initialSeconds);
    const [isActive, setIsActive] = useState(false);
    const [sendTime, setSendTime] = useState(Date.now());
 
    // 타이머 함수 - isActive가 true일 때 1초마다 timer를 1씩 감소시키고 false일 때 clearInterval 실행
	useEffect(() => {
		let interval: NodeJS.Timeout;
		if (isActive) {
			interval = setInterval(() => {
				setTimer((prevTimer: number) => (prevTimer > 0 ? prevTimer - 1 : 0));
			}, 1000);
        }
        return () => clearInterval(interval);
    }, [isActive]);
    
    // 타이머와 실제 시간 비교 후 업데이트
    useEffect(() => {
        const now = Date.now();
        const elapsedSeconds = Math.floor((now - sendTime) / 1000);
        if (elapsedSeconds !== timer && initialSeconds - elapsedSeconds >= 0) {
            setTimer(initialSeconds - elapsedSeconds);
        }
    }, [timer]);
 
    // 타이머 초기화 함수
	const resetTimer = () => {
		setTimer(initialSeconds);
		setIsActive(true);
	};
 
    // 타이머 중지 함수
	const stopTimer = () => {
		setIsActive(false);
	};
 
	return { timer, setSendTime, resetTimer, stopTimer };
}

/**
 * check list
 * - 타이머 : javascript setInterval 사용 중
 * (사용자가 앱을 백그라운드로 보내고 다시 돌아왔을 때 이슈 발생 가능성 있음)
 */

const AuthCodeInput = forwardRef(({ timerSeconds = INITIAL_TIMER_SECONDS,
    defaultValue, value, done, type = { type: 'default', data: '' },
    onVerify, onDataFilled, onMethodFilled }: Props, ref) => {
    
    // 인증코드 API
    const { sendTel, confirmCode } = useAuthCall();
	
    // 인증코드 확인 함수를 부모 컴포넌트에서 사용할 수 있도록 함
    useImperativeHandle(ref, () => ({
        verifyCode: handleVerifyCodeHandler,
    }));
    
    // 타이머
    const { timer, setSendTime, resetTimer, stopTimer } = useTimer(timerSeconds);
    let tryLimitTimer = false;
    
    // 인증방법 input 
    const [methodValue, setMethodValue] = useState(value || defaultValue || '');

    // 인증코드 input
    const [verificationCodeValue, setVerificationCodeValue] = useState('');
    const verificationCodeRef = useRef<HTMLInputElement>(null);

    // 인증방법 입력값별 저장
    const [cacheVerifiedNumbers, setCacheVerifiedNumbers] = useState({});

    // 인증방법 상태
    const initialMethodStatus = (done) ? {
        canUse: true,
        isFilled: true,
        message: METHOD_PASS,
    } : (value.length > 0 && ! done) ? { 
        canUse: '',
        isFilled: true,
        message: "",
    } : {
        canUse: '',
        isFilled: false,
        message: "",
    };
    const [methodStatus, setMethodStatus] = useState(initialMethodStatus);

    // 인증상태
    const initialVerificationStatus = (done) ? {
        sent: true,
        verified: true,
        message: VERIFICATION_PASS,
    } : {
        sent: false,
        verified: false,
        message: "",
    };
    const [verificationStatus, setVerificationStatus] = useState(initialVerificationStatus);
    const resetVerificationStatus = (errorMessage?:string) => {
        setVerificationStatus({
            sent: false,
            verified: false,
            message: errorMessage ? errorMessage : "",
        });
    }

    // 타이머 함수
	const formatTime = (time:number) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };
    const [formatTimer, setFormatTimer] = useState(formatTime(INITIAL_TIMER_SECONDS));
    useEffect(() => {
        setFormatTimer(formatTime(timer));
    }, [timer]);

    const codeInputFocus = () => {
        verificationCodeRef.current?.focus();
    };

    // 인증코드 발송 여부에 따른 타이머 및 인증번호 초기화
	useEffect(() => {
        if (verificationStatus.sent) {
            codeInputFocus();
			resetTimer();
        }
	}, [verificationStatus.sent]);
 
    // 인증코드 확인에 따른 타이머 중지
	useEffect(() => {
		if (verificationStatus.verified) {
            stopTimer();
            onVerify(true, methodValue);
        }
	}, [verificationStatus.verified]);

    // 타이머가 0이 되었을 때 인증코드 확인
    useEffect(() => {
        if (timer === 0 && verificationStatus.verified === false && verificationStatus.sent) {
            setVerificationStatus({
                sent: true,
                verified: false,
                message: VERIFICATION_EXPIRED,
            });
            setVerificationCodeValue("");
        }
    }, [timer]);


    // 인증방법 검증(사용할 수 있는 경우인지 ex - 이미 인증된 번호인지)
    const chkMethodUse = (val:string) => {
        if (cacheVerifiedNumbers[val]) {
            setMethodStatus({
                canUse: cacheVerifiedNumbers[val].canUse,
                isFilled: cacheVerifiedNumbers[val].isFilled,
                message: cacheVerifiedNumbers[val].message,
            });
            resetVerificationStatus(cacheVerifiedNumbers[val].message);
            return cacheVerifiedNumbers[val].canUse;
        } else {
            return true;
        }
    }

    // 인증코드 발송 버튼 클릭
    const handlerVerificationButtonClick = async () => {
        if (!chkPhoneTel(methodValue)) {
            setMethodStatus({
                ...methodStatus,
                canUse: false,
                message: METHOD_INVALID,
            })
            resetVerificationStatus();
        } else {
            if (chkMethodUse(methodValue)) {
                AxiosAuthCall(); 
            }
        }
    }

    
    // 인증번호 전송 API 호출 함수
    const AxiosAuthCall = async () => {
        if (tryLimitTimer) return;
        sendTel(methodValue, type) 
            .then(response => {
                if (response) {
                    resendVerification();
                    if (response.data.result === 1) {
                        setSendTime(Date.now());
                        setVerificationStatus({
                            ...verificationStatus,
                            sent: true,
                        });
                    } else {
                        stopTimer(); // 에러 발생, 타이머 중지
                        verificationCodeRef.current?.blur();
                        if (response.data.msg === METHOD_TRY_LIMIT_ERROR) {
                            resetVerificationStatus(response.data.msg);
                            tryLimitTimer = true;
                            setTimeout(() => {
                                tryLimitTimer = false;
                            }, 10000);
                        } else {
                            console.log(response.data.msg);
                            setMethodStatus({
                                ...methodStatus,
                                canUse: false,
                                message: response.data.msg,
                            });
                            setCacheVerifiedNumbers({
                                ...cacheVerifiedNumbers,
                                [methodValue]: {
                                    canUse: false,
                                    isFilled: true,
                                    message: response.data.msg,
                                },
                            })
                            resetVerificationStatus(response.data.msg);
                        }
                    }
                }
            }).catch(e => {
                console.log(e);
                setMethodStatus({
                    ...methodStatus,
                    canUse: false,
                    message: e.response.data.msg || METHOD_SERVER_ERROR,
                });
                resetVerificationStatus(e.response.data.msg || METHOD_SERVER_ERROR);
            });
    }

    // 인증번호 확인 API 호출 함수ß
    const AxiosAuthConfirm = async () => {
        return confirmCode(methodValue, verificationCodeValue, type)
    }

	// 인증번호 재전송 함수
    const resendVerification = () => {
		setVerificationStatus((prevStatus) => ({
			...prevStatus,
			verified: false,
			message: "",
		}));
        setVerificationCodeValue("");
        codeInputFocus();
        resetTimer();
    };
    
	// 인증번호 확인 함수
    const handleVerifyCodeHandler = async () => {
        if (verificationStatus.message === VERIFICATION_EXPIRED) {
            return Promise.resolve(false);
        } else {
            if (verificationStatus.verified) {
                return Promise.resolve(true);
            } else {
                return await AxiosAuthConfirm().then(res => {
                    if (res) {
                        if (res.data.result === 1) {
                            setVerificationStatus({
                                sent: true,
                                verified: true,
                                message: VERIFICATION_PASS,
                            });
                        } else {
                            setVerificationStatus({
                                sent: true,
                                verified: false,
                                message: res.data.msg || VERIFICATION_NOT_MATCHED,
                            });
                        }
                        return res;
                    }
                }).catch(e => { 
                    console.log(e);
                    setVerificationStatus({
                        sent: true,
                        verified: false,
                        message: e.response.data.msg || METHOD_SERVER_ERROR,
                    });
                    throw e;
                });
            }
        }
    };
    
    // 인증방법 입력 onChange 이벤트 핸들러
    const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let hypenPhoneNumber = autoHypenPhone(e.target.value);
        setMethodValue(hypenPhoneNumber);
        if (hypenPhoneNumber.length > 13) return;
        if(hypenPhoneNumber.length === 13) {
            setMethodStatus({
                canUse: '',
                isFilled: true,
                message: "",
            });
        } else {
            setMethodStatus({
                canUse: '',
                isFilled: false,
                message: "",
            });
        }
        resetVerificationStatus();
    }
 
	// 인증코드 입력 onChange 이벤트 핸들러
	const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numbersOnly = e.target.value.replace(/\D/g, "");
		if (numbersOnly.length <= VERIFICATION_CODE_LENGTH) {
			setVerificationCodeValue(numbersOnly);
        }
        setVerificationStatus({...verificationStatus, message: ""});
	};
 
    // 인증방법 입력값 충족 여부 변경될 때마다 상태 변경
    useEffect(() => {
        if(! methodStatus.isFilled) {
            stopTimer();
            resetVerificationStatus();
            setVerificationCodeValue('');
        } else {
            onMethodFilled(methodValue);
        }
    }, [methodStatus.isFilled]);


    useEffect(() => {
        if (methodStatus.isFilled && verificationCodeValue.length === 4 && verificationStatus.message === '') {
            onDataFilled(true, methodValue);
        } else {
            onDataFilled(false, methodValue);
        }
    }, [verificationCodeValue, methodStatus.isFilled]);

    // 버튼 컴포넌트
    const buttonComponent = useMemo(() => {
        return (
            <button className={`btn btn-black 
                ${(!methodStatus.isFilled || verificationStatus.verified) ? 'btn-disabled' : ''}`}
                onClick={handlerVerificationButtonClick}
                disabled={! methodStatus.isFilled || verificationStatus.verified}>
                {!verificationStatus.sent ? "인증번호 발송" : "재전송"}
            </button>
        );
    }, [methodStatus.isFilled, verificationStatus.sent, verificationStatus.verified]);

    // 아이콘 컴포넌트
    const iconComponent = useMemo(() => {
        return (
            <>
                <i className={`limit-time-text ${(timer === 0 && ! verificationStatus.verified && verificationStatus.sent) ? 'expired' : ''} ${verificationStatus.verified ? 'disabled' : ''}`}>{verificationStatus.verified ? '00:00' : formatTimer}</i>
            </>
        );
    }, [timer, formatTimer, verificationStatus.verified]);


    return (
        <>
            <>
                <InputTypeBox
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={methodValue}
                    onChange={handleMethodChange}
                    maxLength={PHONE_NUMBER_LENGTH}
                    placeholder={"휴대전화 번호"}
                    buttonComponent={buttonComponent}
                    disabled={verificationStatus.verified}
                />
                {(verificationStatus.sent || verificationStatus.message) && (<>
                    <Blank height={12} />
                    <InputTypeBox
                        ref={verificationCodeRef}
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={verificationCodeValue}
                        onChange={handleVerificationCodeChange}
                        maxLength={VERIFICATION_CODE_LENGTH}
                        placeholder={(verificationStatus.verified && methodStatus.isFilled) ? '' : VERIFICATION_NOT_FILLED}
                        iconComponent={iconComponent}
                        resultObject={{
                            result: verificationStatus.verified ? 'success' : 'error',
                            message: verificationStatus.message,
                            type: 'dataValid'
                        }}
                        showInfoMessage={{ success: false, error: true }}
                        showResultBorder={{ success: false, error: true }}
                        readOnly={! (timer > 0 && ! verificationStatus.verified && verificationStatus.sent)}
                        disabled={verificationStatus.verified}
                        // autoComplete="one-time-code" 임시 제거
                    />
                </>)}
            </>
        </>
	);
});

export default AuthCodeInput;