import './CompanyInfo.scss';
import { IChatChannel, IHallReservationListItem, IHallReservationByChannelUuidRes } from '@src/api/booking/booking';
import { CONFIRM_STATE_MAP } from '@src/utils/booking';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const today = dayjs();
const CompanyInfo = ({info, chatChannel}: {info: IHallReservationListItem|IHallReservationByChannelUuidRes|null; chatChannel?: IChatChannel}) => {
	if (!info || !info.confirm) return null;
	const CONFIRM_STATE = CONFIRM_STATE_MAP[info.confirm.confirmState];
	
	return (
		<div className="company-info-wrap">
			<div>
				<div className={`company-bg ${chatChannel?.unreadCnt > 0 ? 'unread' : ''}`} style={{backgroundImage: `url(${info.receiverInfo.coverUrl})`}}></div>
				<div>
					<div className="company-name-wrap">
						<span className="comapny-name">{info.receiverInfo.name}</span>
						{chatChannel?.unreadCnt > 0 && 
						<span className="unread-count">{chatChannel?.unreadCnt}</span>
						}
					</div>
					<div>
						<span className="confirm-state-alias">
							{info.confirm.confirmState === 1
							? (today.isAfter(info?.visitTsp) ? '방문완료' : '방문예정')
							: (CONFIRM_STATE && CONFIRM_STATE.textToCustomer) 
							}
						</span>
						{(info?.visitTsp && (CONFIRM_STATE?.name === '승인' || CONFIRM_STATE?.name === '임시예약')) &&
						<time className="time-info" dateTime="">{dayjs(info?.visitTsp).format('YYYY.MM.DD (ddd) HH시 mm분')}</time>
						}
					</div>
					{CONFIRM_STATE && <span className={`badge badge-square badge-state-alias badge-${CONFIRM_STATE.colorType}`}>{CONFIRM_STATE.nameForCustomer}</span>}
				</div>
			</div>
		</div>
	);
};

export default CompanyInfo;