import { KAKAO_LOGIN_APP_KEY, LOGIN_REDIRECT_URI } from '@src/utils/index';
import LoadingSpinner from '@src/components/common/loading/LoadingSpinner';
import { useEffect } from 'react';

const SignInUpKakao = () => {

    const Rest_api_key = KAKAO_LOGIN_APP_KEY //REST API KEY
    const redirect_uri = LOGIN_REDIRECT_URI //Redirect URI
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}?brand=KAKAO&response_type=code`;
    
    useEffect(() => {
       window.location.href = kakaoURL;
    }, []);

    return (
        <>
            <LoadingSpinner />
        </>
	);
};

export default SignInUpKakao;