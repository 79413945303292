import LinkPayHeader from '@src/assets/images/linkpay/linkpay-header.png';

const WebHeader = () => {
	return (
		<div className="web-header-wrap">
			<img src={LinkPayHeader} alt="WeddingBook" width={185} />
		</div>
	);
};

export default WebHeader;