import {useState, useEffect, useContext} from 'react';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import { getHallReservationList, IHallReservationListItem } from '../../../api/booking/booking';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import CompanyInfo from '../../../components/booking/management/CompanyInfo';
import EmptyArea from '@src/components/common/emptyArea/EmptyArea';
import { HOSTNAME, isExternalUrl } from '@src/utils';


const BookingManagementList = () => {
	const user = useContext(UserContext);
	const [list, setList] = useState<IHallReservationListItem[]|null>(null);
	const prepareData = async () => {
		if (user.me) {
			const {data} = await getHallReservationList(user.me.uuid);
			const list = data.item;
			setList(list);
		}
	};
	useEffect(() => {
		prepareData();
	}, [user]);
	return (
		<>
		<AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} />
		<div className="page-booking-management-list-wrap">
			<div className="management-title-wrap" >
				<h2>웨딩홀 문의내역</h2>
			</div>
			{list !== null &&
			<>
			{list.length === 0
			? <EmptyArea
				title="문의하신 웨딩홀이 없습니다."
				subTitle="마음에 드는 웨딩홀 안내 신청을 해보세요"
				button={{
					name: '웨딩홀 검색하기',
					size: 'small',
					theme: 'brown',
					action: () => {
						window.location.href = isExternalUrl(`${HOSTNAME}/booking/hall`);
					}
				}}
			/>
			: <ul className="management-list-wrap">
				{list.map(info => (
				<li key={`list-channel-id-${info.chatChannel.uuid}`}>
					<Link to={`/booking/management/chat?channelUuid=${info.chatChannel.uuid}`}>
						<CompanyInfo info={info} chatChannel={info.chatChannel} />
					</Link>
				</li>
				))}
			</ul>
			}
			</>}
		</div>
		</>
	);
};

export default BookingManagementList;