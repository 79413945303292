import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
import { UserData } from "@src/@slice/UserSlice";
// router
import { useNavigate, useLocation } from "react-router-dom";
// hooks
import useSignInputEvent from "@src/utils/hooks/useSignInputEvent";
import { useCustomInput, useSignEmail } from "@src/utils/hooks/useSignInput";
import { useSignValidation } from "@src/utils/hooks/useSignInput";
import { usePasswordValidation } from "@src/utils/hooks/useSignValidation";
// components
import Wrap from "@src/components/common/wrap/Wrap";
import AppHeader from "../../../components/common/appHeader/AppHeader";
import InputTypeBox from "@src/components/common/inputTypeBox/InputTypeBox";
import DataSection from "@src/components/form/dataSection/DataSection";
import { PasswordPlaceholder } from "@src/components/common/inputTypeBox/PasswordInfo";
// analytics
import { logEvent } from "@src/firebase";

// 이메일 로그인 페이지
const Contents = () => {
  // history
  const navigate = useNavigate();

  // redux
  const ForUserData = useSelector(UserData);

  // uuid
  const [userUuid, setUserUuid] = useState("");
  useEffect(() => {
    setUserUuid(ForUserData.customerUuid);
  }, [ForUserData]);

  // location
  const location = useLocation();
  const { loginId } = location.state || {};

  // location state 변경 시 이메일 변경
  useEffect(() => {
    if (loginId) signEmail.setValue(loginId);
  }, [loginId]);

  // input Event
  const { signValueSetter, signEnterKeyEvent, signDataValidate } = useSignInputEvent();

  // keydown 이벤트 핸들러
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    signEnterKeyEvent(e, formatValid, handleNext);
  };

  // 다음 단계 이동 트리거
  const [nextTrigger, setNextTrigger] = useState(false);

  // 이메일
  const signEmail = useSignEmail();

  const { verifyPassword } = usePasswordValidation();

  // 비밀번호 데이터 확인
  const passwordDataErrorCheck = async (pwd: string, email?: string) => {
    let result = await verifyPassword(email, pwd); // 로그인 처리
    signPassword.setResultObject(result);
  };

  // 비밀번호
  const signPassword = useCustomInput({
    dataErrorCheck: signDataValidate,
    dataCheckMoment: "custom",
  });

  // 페이지 입력값 유효성 확인
  const formatValid = useSignValidation([signEmail.formatValid, signPassword.formatValid]);
  const dataValid = useSignValidation([signEmail.dataValid, signPassword.dataValid]);

  // 다음 단계 이동 핸들러 (로그인)
  const handleNext = async () => {
    // 로그인 처리
    passwordDataErrorCheck(signPassword.value, signEmail.value);
  };

  // 로그인 정보 확인 -> 다음 단계 트리거
  useEffect(() => {
    if (signEmail.dataValid && signPassword.dataValid) {
      setNextTrigger(true);
    }
  }, [signEmail.dataValid, signPassword.dataValid]);

  // 다음 단계 트리거 -> 형식/데이터 유효성 확인
  useEffect(() => {
    if (nextTrigger) {
      if (formatValid && dataValid) {
        goNext(); // 다음 단계로 이동
      }
    }
  }, [nextTrigger, formatValid, dataValid]); //다음 버튼 클릭 시 유효성 확인

  // 형식/데이터 유효성 확인 -> 다음 단계로 이동
  const goNext = () => {
    window.location.href = `h2mwbell://home?userUuid=${userUuid}`;
  };

  return (
    <>
      <div className="contents-wrap">
        <DataSection title="">
          <div className="form-wrap">
            <div className="form-block">
              <div className="form-title">
                <span className="title">이메일</span>
              </div>
              <div className="form-content">
                <InputTypeBox
                  type="email"
                  placeholder="이메일"
                  value={signEmail.value}
                  inputMode="email"
                  onChange={signValueSetter(signEmail.setValue)}
                  onKeyDown={handleKeyDown}
                  resultObject={signEmail.resultObject}
                  showResultBorder={{ error: true, success: false }}
                  showInfoMessage={{ error: true, success: false }}
                />
              </div>
            </div>
            <div className="form-block">
              <div className="form-title">
                <span className="title">비밀번호</span>
              </div>
              <div className="form-content">
                <InputTypeBox
                  type="password"
                  placeholder={PasswordPlaceholder}
                  value={signPassword.value}
                  onChange={signValueSetter(signPassword.setValue)}
                  onKeyDown={handleKeyDown}
                  resultObject={signPassword.resultObject}
                  showResultBorder={{ error: true, success: false }}
                  showInfoMessage={{ error: true, success: false }}
                />
              </div>
            </div>
          </div>
        </DataSection>
      </div>
      <div className="actions-wrap">
        <div className="btn-wrap">
          <button
            className={`btn btn-large btn-red btn-full ${!formatValid ? "btn-disabled" : "bold"}`}
            disabled={!formatValid}
            onClick={handleNext}
          >
            로그인
          </button>
        </div>
        <ul className="piped-links piped-links-bottom piped-links-right">
          <li>
            <button
              onClick={() => {
                navigate("/find-id");
              }}
            >
              아이디 찾기
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                navigate("/find-pw");
              }}
            >
              비밀번호 찾기
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

// 이메일 로그인 페이지
const SignInEmail = () => {
  return (
    <Wrap
      className="sign-in-up-wrap"
      accessType="all"
      fullHeight={true}
      headerComponent={<AppHeader type="stack" title="로그인" actionItems={[]} />}
      contentsComponent={<Contents />}
    />
  );
};

export default SignInEmail;
