// 광고 관련 api
import axios from './axios';
import { BASE_URL } from '@src/utils';
import moment from 'moment';

const storage = {
	setItem: async (uuid: string, key: string, value: string, expTsp?: number) => {
		if (expTsp === undefined) expTsp = moment().add(1, 'y').valueOf(); // 기본 만기일 없음으로.
		await axios.post(`${BASE_URL}/web/v3/customer/data`, {
			uuid, key, value, expTsp
		});
		return;
	},
	getItem: async (uuid: string, key: string) => {
		const {data} = await axios.get<{
			expTsp: number;
			regTsp: number;
			key: string;
			value: string;
			uuid: string;
		}>(`${BASE_URL}/web/v3/customer/data/${uuid}/${key}`);
		if (data) {
			return data.value;
		}
		return false;
	}
}
export default storage;