import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, getOs, GA_TRACKING_ID } from "./utils/index";
import { useWindowSize } from "./utils/hooks/useWindowSize";
import ReactGA from "react-ga4";
import dayjs from "dayjs";
import "dayjs/locale/ko";
// import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { useUserActions } from "./utils/hooks/useUserActions";
import { UserData, User } from "./@slice/UserSlice";
import { setNeedLogin } from "@src/@slice/globalSlice";

import Home from "./pages/home";
import Product from "./pages/product";
import HomePreview from "./pages/preview/home";

import ProductPreview from "./pages/preview/product";
import ProductComponentDetail from "./pages/detail/product";
import HomeComponentDetail from "./pages/detail/home";
import axios from "axios";
import MyInterest from "./pages/MyInterest";
import RedirectPage from "./pages/Redirect";
import ChungdamPrograms from "./pages/chungdam/ChungdamPrograms";
import ChungdamIntro from "./pages/chungdam/ChungdamIntro";
import StampApply from "./pages/weddingStamp/Apply";
import StampPartners from "./pages/weddingStamp/Partners";
import StampApplyComplete from "./pages/weddingStamp/ApplyComplete";

// search
import Search from "./pages/search/Search";
import SearchResult from "./pages/search/Result";

// mypages
import Mypage from "./pages/mypage/Mypage";
import MyPoint from "./pages/mypage/MyPoint";
import MyBenefits from "./pages/mypage/MyBenefits";

// coupons
import CouponList from "./pages/mypage/CouponList";

// common app pages
import EarnPoint from "./pages/mypage/EarnPoint";

// signInUp
import SignInSocial from "./pages/signInUp/signIn/Social";
import SignInEmail from "./pages/signInUp/signIn/Email";
import SignUpStep1 from "./pages/signInUp/signUp/Step1";
import SignUpStep2 from "./pages/signInUp/signUp/Step2";
import SignUpComplete from "./pages/signInUp/signUp/Complete";
import SignUpAdditional1 from "./pages/signInUp/signUp/Additional1";
import SignUpAdditional2 from "./pages/signInUp/signUp/Additional2";
import FindID from "./pages/signInUp/findID/FindID";
import FindIDResult from "./pages/signInUp/findID/FindIDResult";
import FindPW from "./pages/signInUp/findPW/FindPW";
import FindPWResult from "./pages/signInUp/findPW/FindPWResult";
import SignInUpApple from "./pages/signInUp/signIn/AppleLogin";
import SignInUpNaver from "./pages/signInUp/signIn/NaverLogin";
import SignInUpKakao from "./pages/signInUp/signIn/KakaoLogin";

// routes
import HallManagement from "./pages/booking/hall/Management";
import BookingRoute from "./routes/Booking";
import RecommendRoute from "./routes/Recommend";
import {
  callCount as callCountData,
  increment,
  needLogin as needLoginData,
} from "./@slice/globalSlice";
import VipApply from "./pages/vip/Apply";
import useUrlQueryString from "./utils/hooks/useUrlQueryString";
import ApplyEletronics from "./pages/weddingStamp/ApplyEletronics";
import HoneymoonApply from "./pages/honeymoon/Apply";
import HoneymoonComplete from "./pages/honeymoon/Complete";
import HallCancel from "./pages/booking/hall/Cancel";
import HallChange from "./pages/booking/hall/Change";
import PartnerDetail from "./pages/partner/PartnerDetail";
import PartnerWriteReview from "./pages/partner/WriteReview";
import BlindReviews from "./pages/partner/BlindReviews";
import SDMPackageApply from "./pages/sdmPackage/Apply";
import SDMPackageApplyComplete from "./pages/sdmPackage/Complete";
import NeedLogin from "./components/common/needLogin/NeedLogin";
import CategoryList from "./pages/category/List";
import UmomentRoute from "./routes/Umoment";
import LinkPay from "./pages/linkPay/LinkPay";
import LinkPaySuccess from "./pages/linkPay/LinkPaySuccess";
import LinkPayFail from "./pages/linkPay/LinkPayFail";
import LinkPayPayInfo from "./pages/linkPay/LinkPayPayInfo";
import Test from "./pages/test/test";

import BookingManagementList from "./pages/booking/management/List";
import BookingManagementChat from "./pages/booking/management/Chat";
import BookingRealTimeManagementList from "./pages/booking/realTime/management/List";
import BookingRealTimeManagementDetail from "./pages/booking/realTime/management/Detail";

//real-time routes
import RealTimeBottomFixedModal from "./pages/booking/realtimeBooking/RealTimeBottomFixedModal";
import RealtimeBookingRoute from "./routes/RealtimeBooking";
import RealTimeTossPay from "./pages/booking/realtimeBooking/RealTimeTossPay";
import PaymentSuccess from "./pages/booking/realtimeBooking/PaymentSuccess";
import PaymentFail from "./pages/booking/realtimeBooking/PaymentFail";
import RealTimeStopReservation from "./pages/booking/realtimeBooking/RealTimeStopReservation";

declare global {
  interface Window {
    logoutAction: any;
  }
}

// react 18버전 이상 필요
// const queryClient = new QueryClient();
const initialData = {
  updated: false,
  loggedin: false,
  dDay: 0,
  me: null,
  bookieUnreadCount: 0,
  newPushTsp: new Date(0).toDateString(),
};
export const UserContext = createContext<IMe>(initialData);
interface IMe {
  updated: boolean;
  loggedin: boolean;
  dDay: number;
  me: any;
  bookieUnreadCount: number;
  newPushTsp: string;
}
function ScrollToTop() {
  const history = createBrowserHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  return null;
}

function UILayoutFix({ children }: { children: any }) {
  const location = useLocation();
  useEffect(() => {
    const div = document.getElementsByClassName("layout-fix-button")[0] as HTMLDivElement;
    if (!div) return;
    if (div.classList.contains("with-app-header")) {
      div.style.height = window.innerHeight - 156 + "px";
    } else {
      div.style.height = window.innerHeight - 100 + "px";
    }
  }, [location]);
  return children;
}
function QueryListener({ children }: { children: any }) {
  const query = useUrlQueryString();
  useEffect(() => {
    if (query.get("appHeader") === "hide") {
      document.getElementsByTagName("html")[0].classList.add("hide-app-header");
    } else {
      document.getElementsByTagName("html")[0].classList.remove("hide-app-header");
    }
  }, [query]);
  return children;
}
function RouteTracker({ children }: { children: any }) {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!GA_TRACKING_ID) return;
    ReactGA.initialize(GA_TRACKING_ID);
    setInitialized(true);
  }, []);
  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialized, location]);
  return children;
}

// dayjs global 한글 설정
dayjs.locale("ko");
function App() {
  // const [callCount, setCallCount] = useState(0);
  const [userInfo, setUserInfo] = useState<IMe>(initialData);
  const callCount = useSelector(callCountData);
  const needLogin = useSelector(needLoginData);
  const dispatch = useDispatch();
  const ForUserData = useSelector(UserData);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || "");
  const [loginComplete, setLoginComplete] = useState(localStorage.getItem("loginComplete") || "");

  (window as any).refreshMe = () => {
    dispatch(increment()); // for webview bridge
  };
  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add(getOs());
  }, []);
  useEffect(() => {
    axios
      .get<{ item: IMe }>(`${BASE_URL}/v3/home/me`)
      .then((res) => {
        console.log(res.data);
        setUserInfo(Object.assign({}, res.data.item, { updated: true, loggedin: true }));
      })
      .catch((err) => {
        setUserInfo(Object.assign({}, initialData, { updated: true })); // for refresh
      })
      .finally(() => {
        setAccessToken(localStorage.getItem("accessToken") || "");
        setLoginComplete(localStorage.getItem("loginComplete") || "");
      });
  }, [callCount]);
  const { width, height } = useWindowSize();
  useEffect(() => {
    if (window !== undefined && height !== undefined && window.visualViewport) {
      const MIN_KEYBOARD_HEIGHT = 200; // 키보드 높이로 예상되는 최소값..(혹시나 다른이유로 visualViewport 높이가 더 작아 질 수 있으니)
      const isKeyboardOpen =
        window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
      const div = document.getElementsByClassName("layout-fix-button")[0] as HTMLDivElement;
      if (!div) return;
      if (isKeyboardOpen) {
        div.classList.add("show-keyboard");
      } else {
        div.classList.remove("show-keyboard");
      }
    }
  }, [width, height]);
  // 회원 정보 가져오기
  const { getUserData, logoutAction, resetUserData } = useUserActions();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.logoutAction = logoutAction;
  }, []);
  useEffect(() => {
    if (accessToken) {
      getUserData(accessToken);
    }
  }, [accessToken]);
  useEffect(() => {
    const locationCheck = window.location.pathname === "/sign-in/social";
    if (
      locationCheck &&
      ForUserData.customerUuid &&
      loginComplete === "need scheme process" &&
      userInfo.me === null
    ) {
      localStorage.removeItem("loginComplete");
      window.location.href = `h2mwbell://home?userUuid=${ForUserData.customerUuid}`;
    }
    if (window.location.pathname === "/home") {
      localStorage.removeItem("loginComplete");
    }
  }, [loginComplete, ForUserData, userInfo]);

  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      {/* <QueryClientProvider client={queryClient}> */}
      <UserContext.Provider value={userInfo}>
        <BrowserRouter>
          <ScrollToTop />
          <RouteTracker>
            <QueryListener>
              <UILayoutFix>
                <Routes>
                  <Route path="/test" element={<Test />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/home/preview" element={<HomePreview />} />
                  <Route path="/home/detail/:componentId" element={<HomeComponentDetail />} />

                  <Route path="/product/:productId" element={<Product />} />
                  <Route path="/product/:productId/preview" element={<ProductPreview />} />
                  <Route path="/product/detail/:componentId" element={<ProductComponentDetail />} />

                  {/* 검색화면 */}
                  <Route path="/search" element={<Search />} />
                  <Route path="/search/result" element={<SearchResult />} />

                  {/* 업체 상세 */}
                  <Route path="/partner/:uuid" element={<PartnerDetail />} />
                  <Route path="/partner/:uuid/blind-reviews" element={<BlindReviews />} />
                  <Route path="/partner/:uuid/write-review" element={<PartnerWriteReview />} />

                  {/* 업체 카테고리 */}
                  <Route path="/category/:id" element={<CategoryList />} />

                  {/* mypage */}
                  <Route path="/mypage" element={<Mypage />} />
                  <Route path="/mypage/my-point" element={<MyPoint />} />
                  <Route path="/mypage/my-benefits" element={<MyBenefits />} />
                  <Route path="/mypage/coupon-list" element={<CouponList />} />

                  <Route path="/how-to-earn-point" element={<EarnPoint />} />

                  <Route path="/my-interest/:dataType" element={<MyInterest />} />

                  <Route path="/chungdam/intro" element={<ChungdamIntro />} />
                  <Route path="/chungdam/programs" element={<ChungdamPrograms />} />

                  {/* 실시간 예약 관리 */}
                  <Route
                    path="/booking/real-time/management/list"
                    element={<BookingRealTimeManagementList />}
                  />
                  <Route
                    path="/booking/real-time/management/detail/:id"
                    element={<BookingRealTimeManagementDetail />}
                  />

                  {/* 실시간 예약 관리 */}
                  <Route
                    path="/booking/real-time/:id/noti"
                    element={<RealTimeBottomFixedModal />}
                  />
                  <Route path="/booking/real-time/:id/*" element={<RealtimeBookingRoute />} />
                  <Route path="/booking/real-time/tosspay/:uuid" element={<RealTimeTossPay />} />
                  <Route path="/booking/real-time/tosspay/success" element={<PaymentSuccess />} />
                  <Route path="/booking/real-time/tosspay/fail" element={<PaymentFail />} />
                  {/* <Route path="/booking/real-time/stop-reservation" element={<RealTimeStopReservation/>} /> */}

                  {/* 실시간 예약 관리 */}
                  {/* <Route path="/booking/real-time/:id/noti" element={<RealTimeBottomFixedModal/>}/>
                    <Route path="/booking/real-time/:id/*" element={<RealtimeBookingRoute/>} />
                    <Route path='/booking/real-time/tosspay/:uuid' element={<RealTimeTossPay />} />
                    <Route path="/booking/real-time/tosspay/success" element={<PaymentSuccess/>} />
					<Route path="/booking/real-time/tosspay/fail" element={<PaymentFail/>} />
                    <Route path="/booking/real-time/stop-reservation" element={<RealTimeStopReservation/>} /> */}

                  {/* 예약관리 */}
                  <Route path="/booking/management/list" element={<BookingManagementList />} />
                  <Route path="/booking/management/chat" element={<BookingManagementChat />} />

                  <Route path="/booking/hall/management" element={<HallManagement />} />
                  <Route path="/booking/hall/cancel/:uuid" element={<HallCancel />} />
                  <Route path="/booking/hall/change/:uuid" element={<HallChange />} />
                  <Route path="/booking/*" element={<BookingRoute />} />

                  <Route path="/recommend/*" element={<RecommendRoute />} />
                  {/* <Route path="" />
					<RecommendRoute />
					<BookingRoute /> */}

                  {/* wedding stamp */}
                  <Route path="/weddingStamp/partners" element={<StampPartners />} />
                  <Route path="/weddingStamp/apply" element={<StampApply />} />
                  <Route path="/weddingStamp/apply/complete" element={<StampApplyComplete />} />
                  <Route path="/weddingStamp/eletronics/apply" element={<ApplyEletronics />} />

                  {/* honeymoon apply */}
                  <Route path="/honeymoon/apply" element={<HoneymoonApply />} />
                  <Route path="/honeymoon/apply/complete" element={<HoneymoonComplete />} />

                  {/* sdm packge apply */}
                  <Route path="/sdm-package/apply" element={<SDMPackageApply />} />
                  <Route path="/sdm-package/apply/complete" element={<SDMPackageApplyComplete />} />

                  {/*  */}
                  <Route path="/redirect" element={<RedirectPage />} />

                  {/* VIP */}
                  <Route path="/vip/apply" element={<VipApply />} />

                  {/* 유모멘트 */}
                  <Route path="/umoment/*" element={<UmomentRoute />} />

                  {/* 링크 페이 */}
                  <Route path="/linkPay/:uuid" element={<LinkPay />} />
                  <Route path="/linkPay/:uuid/info" element={<LinkPayPayInfo />} />
                  <Route path="/linkPay/payment/success" element={<LinkPaySuccess />} />
                  <Route path="/linkPay/payment/fail" element={<LinkPayFail />} />

                  {/* 로그인, 회원가입 */}
                  <Route path="/sign-in/social" element={<SignInSocial />} />
                  <Route path="/sign-in/email" element={<SignInEmail />} />
                  <Route path="/sign-up/1" element={<SignUpStep1 />} />
                  <Route path="/sign-up/2" element={<SignUpStep2 />} />
                  <Route path="/sign-up/complete" element={<SignUpComplete />} />
                  <Route path="/sign-additional/1" element={<SignUpAdditional1 />} />
                  <Route path="/sign-additional/2" element={<SignUpAdditional2 />} />
                  <Route path="/find-id" element={<FindID />} />
                  <Route path="/find-id/complete" element={<FindIDResult />} />
                  <Route path="/find-pw" element={<FindPW />} />
                  <Route path="/find-pw/complete" element={<FindPWResult />} />
                  <Route path="/social/apple" element={<SignInUpApple />} />
                  <Route path="/social/naver" element={<SignInUpNaver />} />
                  <Route path="/social/kakao" element={<SignInUpKakao />} />
                </Routes>
              </UILayoutFix>
            </QueryListener>
          </RouteTracker>
          {userInfo.updated && !userInfo.loggedin && needLogin && <NeedLogin />}
        </BrowserRouter>
      </UserContext.Provider>
      {/* </QueryClientProvider> */}
    </div>
  );
}

export default App;
