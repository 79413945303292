import './listItemThumb.scss';

type Params = {
	thumbUrl: string;
	height?: number;
	ratio?: number;
	isLike: number;
}
const ListItemThumb = ({thumbUrl, height, ratio = 0, isLike}: Params) => {
	return (
		<div className={`list-item-thumb ${isLike ? 'like' : ''}`}
			style={{
				backgroundImage: `url(${thumbUrl})`,
				height: height ? height : 'auto',
				paddingBottom: ratio + '%'
			}}
		></div>
	);
};

export default ListItemThumb;