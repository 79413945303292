import './notiText.scss';
interface NotiTextProps {
	text: string;
	color?: 'green' | 'gray';
	style?: React.CSSProperties;
}
const NotiText = ({text, color = 'green', style}: NotiTextProps) => {
	return (
		<p className={`noti-text ${color}`} style={style ? style : {}}>
			{text}
		</p>
	);
};

export default NotiText;