import { useContext, useState } from 'react';
import { IPartner, postPartnerLike } from '@src/api/partner/partner';
import { UserContext } from '@src/App';
import { isExternalUrl, PORTAL_URL, HOSTNAME, SAMSUNG_EVENT_PARTNER_UUID } from '@src/utils';
import icLike from '../../assets/images/partner/morePopup/ic-like@3x.png';
import icSdmmall from '../../assets/images/partner/morePopup/ic-sdmmall@3x.png';
import icStyling from '../../assets/images/partner/morePopup/ic-styling@3x.png';
import Modal from '@src/components/common/modal/Modal';
import Dialog from '../common/dialog/Dialog';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import { useNavigate } from 'react-router-dom';

const PartnerDetailBottomButtons = ({partner, useRealtimeBooking}: {partner: IPartner, useRealtimeBooking: boolean}) => {
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const [storeListPopupState, setStoreListPopupState] = useState(false);
	const [selectedStore, setSelectedStore] = useState<string|null>(null);
	const [morePopupState, setMorePopupState] = useState(false);
	const [likeDoneModalState, setLikeDoneModalState] = useState(false);
	const handleBooking = () => {
		if (!user.me || !user.me.tel) {
			alert('번호인증된 회원만 예약이 가능합니다.');
			return;
		}
		if (partner.store.length > 1) {
			setStoreListPopupState(true);
		} else {
			if (partner.type === 2) {
                if(useRealtimeBooking){
                    // 실시간 예약 보증금 안내 
                    window.location.href = isExternalUrl(`${HOSTNAME}/booking/real-time/${partner.id}/noti`);
                }else{
				    // 웨딩홀
				    window.location.href = isExternalUrl(`${HOSTNAME}/booking/hall?wbTitle=%20&partnerUuids=${partner.uuid};`);
                }
			} else {
				linkBooking(partner.uuid);
			}
		}
	}
	const linkBooking = (partnerUuid: string) => {
		window.location.href = `h2mwbell://partner/booking/${partnerUuid}`
	}
	const linkSdmMall = () => {
		setMorePopupState(false);
		window.location.href = isExternalUrl(`${PORTAL_URL}/app/sdmmall`);
	}
	const bookingChungdam = () => {
		// h2mwbell://aia/booking	
		window.location.href = 'h2mwbell://aia/booking';
	}
	const linkConcierge = () => {
		window.location.href = isExternalUrl(`${PORTAL_URL}/app/concierge/hall/home?utm_source=PartnerDetail&utm_medium=btn&utm_campaign=HallConcierge`);
	}
	const linkCustomizeButton = () => {
		if (partner.buttonUrl) window.location.href = isExternalUrl(partner.buttonUrl);
	}
	const linkStyling = () => {
		setMorePopupState(false);
		window.location.href = isExternalUrl(`${PORTAL_URL}/app/sdm-consult-apply-form/dist/index.html?utm_source=PARTNER_DETAIL`);
	}
	const actionLike = () => {
		postPartnerLike(partner.uuid, {
			partnerName: partner.name,
			state: 1
		}).then(_ => {
			setLikeDoneModalState(true);
			setMorePopupState(false);
		}).catch(_ => {
			alert('서버 오류로 인해 관심업체 등록이 실패했습니다.');
		});
		
	}
	const showMorePopup = () => {
		setMorePopupState(true);
	}
	
	const Button = () => {
		switch (partner.buttonType) {
			case 'BOOKING':
				return <button className="btn btn-half btn-red bold" onClick={handleBooking}>예약하기</button>
			case 'TEL':
				return <a href={`tel:${partner.tel}`} className="btn btn-half btn-red bold">전화걸기</a>
			// case 'BOOKINGREQ':
			// 	return <button className="btn btn-half btn-red bold">제휴요청</button>
			case 'SDMMALL':
			case 'WDB_SDM':
				return <button className="btn btn-half btn-red bold" onClick={linkSdmMall}>구매하기</button>
			case 'WDB_CHUNGDAM':
				return <button className="btn btn-half btn-red bold" onClick={bookingChungdam}>예약하기</button>
			case 'WDB_CONCIERGE':
				return <button className="btn btn-half btn-red bold" onClick={linkConcierge}>섭외 요청하기</button>
			case 'SDMB':
				return <button className="btn btn-half btn-red bold" onClick={showMorePopup}>더 알아보기</button>
			case 'CUSTOMIZE':
				return <button className="btn btn-half btn-red bold" onClick={linkCustomizeButton}>{partner.buttonName}</button>
		}
		return <button className="btn btn-half btn-red bold"></button>
	}

	return (
		<div>
			<div className="partner-detail-button-wrap">
			<button 
				className="btn btn-half btn-light-red"
				onClick={() => {
					if (partner.uuid === SAMSUNG_EVENT_PARTNER_UUID) {
						navigate(`/partner/${partner.uuid}/write-review`);
					} else {
						window.location.href = `h2mwbell://community/review/write?type=${partner?.type}&name=${partner?.name}`
					}
				}}
			>
				{partner.uuid === SAMSUNG_EVENT_PARTNER_UUID ? '이벤트 참여하기' : '후기쓰기'}	
			</button>
			<Button />
			</div>
			<div className={`partner-action-more-wrap ${morePopupState ? 'show' : ''}`}>
				<div className="overlay" onClick={() => setMorePopupState(false)}></div>
				<ul className="partner-action-more-inner">
					<li>
						<button className="btn btn-full btn-transparent btn-auto-height btn-auto-width" onClick={actionLike}>
							<img src={icLike} alt="업체 좋아요" width={24} height={24} />관심 업체 담기
						</button>
					</li>
					{partner.sdmMallCount > 0 &&
					<li>
						<button className="btn btn-full btn-transparent btn-auto-height btn-auto-width" onClick={linkSdmMall}>
							<img src={icSdmmall} alt="스드메몰" width={24} height={24} />
							견적 내보기
						</button>
					</li>
					}
					<li>
						<button className="btn btn-full btn-transparent btn-auto-height btn-auto-width" onClick={linkStyling}>
							<img src={icStyling} alt="스타일링" width={24} height={24} />전문가에게 스타일링 받아보기
						</button>
					</li>
				</ul>
			</div>
			<Modal
				isShow={likeDoneModalState}
				overlay={true}
				text={`관심업체 등록이 완료되었습니다.
				내가 등록한 관심업체는
				우측 상단 하트를 누르시면
				확인할 수 있어요.`}
				onClose={() => setLikeDoneModalState(false)}
			/>
			<Dialog 
				isShow={storeListPopupState}
				title="지점선택"
				closeDialog={() => setStoreListPopupState(false)}
				actionDialog={() => {
					if (selectedStore === null) {
						alert('지점을 선택해주세요.');
						return;
					} else {
						if (selectedStore) linkBooking(selectedStore);
						setStoreListPopupState(false);
					}
				}}
			>
				<ul className="select-store-list-wrap">
					{partner.store.map(st => (
					<li key={`select-store-list-${st.uuid}`}>
						<RadioButton 
							id={`st-${st.uuid}`}
							name="store"
							value={st.uuid}
							checked={selectedStore === st.uuid}
							setValue={() => setSelectedStore(st.uuid)}
						/>
						<label htmlFor={`st-${st.uuid}`}>{st.name}</label>
					</li>
					))}
				</ul>
			</Dialog>
		</div>
	);
};

export default PartnerDetailBottomButtons;