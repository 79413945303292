import { useState, useEffect } from 'react';
import BookingTitle from './../../../components/booking/bookingTitle/BookingTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import { useNavigate } from 'react-router-dom';
import RadioButton from './../../../components/common/radioButton/RadioButton';
import NotiBox from './../../../components/common/notiBox/notiBox';
import { useDispatch, useSelector } from 'react-redux';
import { bookingHallData, setAdjustable, setExpectedPerson } from '@src/@slice/bookingHallSlice';

const visitorData = ['0~0', '~100', '100~150', '150~200', '200~250', '250~300', '300~350', '350~'];
const adjustableData = [0, 50, 100, 150];
const StepVisitor = () => {
	const expectedPerson = useSelector(bookingHallData).expectedPerson;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const goNext = () => {
		navigate('/booking/hall/visit-date');
	}
	useEffect(() => {
		if (expectedPerson.min === 0 && expectedPerson.max === 0) {
			setValidate(false);
		} else {
			setValidate(true);
		}
	}, [expectedPerson]);
	return (
		<div>
			{/* <AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} /> */}
			<BookingTitle 
				title={`예상 하객수를 
				알려주세요.`}
			/>
			<DataSection
				title="예상 하객수를 선택해 주세요."
				typeStr="중복 선택 불가"
			>
				<select className={`select select-full ${(expectedPerson.min === 0 && expectedPerson.max === 0) ? 'select-gray' : ''}`}
					onChange={e => {
						const splitVal = e.target.value.split('~');
						console.log(splitVal);
						console.log(splitVal[0] !== '' ? parseInt(splitVal[0], 10) : null);
						console.log(splitVal[1] !== '' ? parseInt(splitVal[1], 10) : null);
						dispatch(setExpectedPerson({
							min: splitVal[0] !== '' ? parseInt(splitVal[0], 10) : null,
							max: splitVal[1] !== '' ? parseInt(splitVal[1], 10) : null,
						}));
					}}
				>
					{visitorData.map(v => {
						const splitVal = v.split('~');
						const val0 = splitVal[0];
						const val1 = splitVal[1];
						const min = expectedPerson.min;
						const max = expectedPerson.max;
						console.log(v, ' / ', `${val0}~${val1}`);
						if (val0 === '0' && val1 === '0') {
							return <option value={v} selected={(v === `${min}~${max}`)}>하객수 선택</option>
						}
						if (val0 && val1) {
							return <option value={v} selected={(v === `${min}~${max}`)}>{val0}명 ~ {val1}명</option>
						}
						if (val0 !== '') return <option value={v} selected={v === `${min}~`}>{val0}명 이상</option>
						if (val1 !== '') return <option value={v} selected={v === `~${max}`}>{val1}명 이하</option>
					})}
				</select>
			</DataSection>
			<DataSection
				title="하객수 조정이 가능하신가요?"
				typeStr="중복 선택 불가"
			>
				<ul className="radio-list-wrap">
					{adjustableData.map(n => (
					<li key={`adjustable-${n}`}>
						<RadioButton 
							id={`adjustable-${n}`}
							name="adjustable"
							value={`${n}`}
							setValue={() => dispatch(setAdjustable({adjustable: n}))}
							checked={expectedPerson.adjustable === n}
						/>
						<label htmlFor={`adjustable-${n}`}>
							{n > 0
							? `+${n}명까지 조정 가능해요.`
							: '아니요, 보증인원 조정이 불가해요.'
							}
						</label>
					</li>
					))}
				</ul>
			</DataSection>
			<NotiBox>
				<p>웨딩홀에는 예식 날짜와 시간대별 최소로 보증해야 하는 하객 인원이 있습니다.<br/>예상 하객수에 따라 견적 안내 및 방문예약이 어려울 수 있으며, 이럴 경우 선택하신 조정 여부와 인원수를 참고하여 안내드립니다.</p>
			</NotiBox>
			<div className="btn-wrap">
				<button disabled={!validate} onClick={goNext} className={`btn bold btn-large btn-full btn-red ${!validate ? 'btn-disabled' : ''}`}>다음</button>
			</div>
		</div>
	);
};

export default StepVisitor;