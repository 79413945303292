import './searchWeddingHall.scss';
import { useState, useEffect, useRef } from 'react';
import SignInUpTitle from '../wrap/WrapTitle';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';
import { BASE_URL } from '@src/utils';
import { ServerResponse } from '@src/api/axios';
import axios from '@src/api/axios';

// Props
interface Props {
	hideModal: () => void;
	onCompleted: (value: any) => void;
	closeButton?: 'icon' | 'text' | JSX.Element;
}

// 웨딩홀 데이터 타입
interface HallData {
	uuid: string;
	bizName: string | null;
	name: string;
	type: number;
	typeName: string;
	csState: string | null;
	tel: string | null;
	address: string | null;
	address2: string | null;
	region: string;
	logoUrl: string | null;
	coverUrl: string | null;
	profile: string | null;
	profileUrl: string | null;
	reviewCnt: number | null;
	oldReviewCnt: number | null;
	isRegister: boolean | null;
	modTsp: string | null;
	rankUp: boolean | null;
	isNew: boolean | null;
	isLike: boolean | null;
	promotionInfo: string | null;
	promotionCnt: number | null;
	rating: number;
	memoContent: string | null;
	tags: string | null;
	benefits: string | null;
	styleFilter: string | null;
	regTsp: string | null;
}

// 초성만 있는지 확인
function containsOnlyInitialConsonants(text:string) {
  // 한글 초성 유니코드 범위: ㄱ(3131)-ㅎ(314E)
  return /^[\u3131-\u314E]+$/.test(text);
}

// 웨딩홀 검색 컴포넌트
const SearchWeddingHall = ({ hideModal, onCompleted, closeButton }: Props) => {

	// 키워드
	const [keyword, setKeyword] = useState('');

	// 검색된 키워드(직전)
	const [searchedKeyword, setSearchedKeyword] = useState('');

	// 검색된 리스트(검색결과)
	const [list, setList] = useState<HallData[]>([]);

	// 검색 중인지 여부
	const [isSearching, setIsSearching] = useState(false);

	// 타이핑 중인지 여부
	const [isTyping, setIsTyping] = useState(false);

	// 타이머
	const signNameTimer = useRef<NodeJS.Timeout | null>(null);

	// 키워드 참조
	const keywordRef = useRef(null);

	// 검색된 키워드 참조
	const searchedKeywordRef = useRef(null);

	// 웨딩홀 리스트 가져오기
	const handlerGetHallList = async (keyword: string) => {
		if (isSearching) return;
		setSearchedKeyword(keyword);
		const url = `${BASE_URL}/v3/20240404/partner/auto?type=2&name=${keyword}&fetchStart=0&fetchSize=30`;
		try {
			setIsSearching(true);
			const response = await axios.get<ServerResponse>(url);
			if(keywordRef.current.trim() === '') return;
			if (response.data.item.length > 0) {
				setList(response.data.item);
			} else {
				setList([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsSearching(false);
		}
	};

	// 키워드 변경 시
	useEffect(() => {
		setIsTyping(true);
		if (keyword.trim().length === 0) {
			setList([]);
		} 
		keywordRef.current = keyword;
	}, [keyword]);

	// 검색된 키워드 변경 시
	useEffect(() => {
		searchedKeywordRef.current = searchedKeyword;
	}, [searchedKeyword]);

	// 완료 처리 핸들러
	const handleCompleted = (value: any) => {
		onCompleted(value);
		hideModal();
	}

	// 값 변경 핸들러 (검색어 입력))
	const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value);
	}

	// 타이핑 중이면 clearTimeout, 타이핑이 멈추면 검색
	useEffect(() => {
		if (isTyping) {
			if (signNameTimer.current) {
				clearTimeout(signNameTimer.current);
			}
			signNameTimer.current = setTimeout(() => {
				setIsTyping(false);
			}, 500);
		} else {
			let searchKeyword = keywordRef.current.trim();
			let lastWord = searchKeyword.slice(-1); // 마지막 글자
			if (searchKeyword.length > 0) { // 검색어가 있는 경우
				if (!containsOnlyInitialConsonants(lastWord) // 초성만 있는지 확인
					&& searchedKeywordRef.current !== searchKeyword) { // 검색된 키워드와 다른 경우
					handlerGetHallList(searchKeyword); // 웨딩홀 리스트 가져오기
				} 
			} else {
				setList([]);
				setSearchedKeyword('');
			}
		}
	}, [isTyping]);

	return (
		<div className="search-wedding-hall"> 
			<SignInUpTitle>
				<h2>웨딩홀을<br />검색하세요.</h2>
			</SignInUpTitle>
			<InputTypeBox
				type="search"
				onChange={(e) => {
					handleChangeEvent(e)
				}}
				placeholder='업체명을 검색해 주세요.'
				value={keyword}
			/>
			<div className="searched-list-wrap">
				<ul>
					{list.map((partner, index) => (
					<li onClick={() => handleCompleted(partner.name)} key={index}>
						{partner.coverUrl &&
							<div className="partner-thumb">
								<img src={partner.coverUrl ? partner.coverUrl : ''}
									onError={(e) => { (e.target as HTMLImageElement).parentElement.classList.add('error'); }}
									alt={partner.name} />
							</div>}
						<div className="partner-details">
							<span className="partner-name">{partner.name}</span>
							<span className="partner-region">{partner.region}</span>
						</div>
					</li>
					))}
					{keyword.trim().length > 0 && <li onClick={()=>handleCompleted(keyword)} key={'custom'}>
						<div className="partner-details">
							<span className="partner-name">{keyword}</span>
						</div>
					</li>}
				</ul>
			</div>
		</div>
	);
};

export default SearchWeddingHall;