import { useEffect, useState } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { useParams } from 'react-router-dom';
import { getCommunityList } from '@src/api/community/community';
import { getDiffToString } from '@src/utils/date';

const BlindReviews = () => {
	const params: any = useParams();
	const [blindList, setBlindList] = useState<any[]>([]);
	const getBlindReviewList = async () => {
		const param: any = {
			partnerUuid: params.uuid,
			blockState: 1,
			fetchStart: 0,
			fetchSize: 999
		};
		const {data} = await getCommunityList(param);
		setBlindList(data.item);
	}
	useEffect(() => {
		getBlindReviewList();
	}, []);
	return (
		<div>
			<AppHeader 
				type="stack"
				title="블라인드 후기"
			/>
			<div className="blind-review-page">
				<section className="blind-review-policy-wrap">
					<h2>웨딩북의 클린 리뷰 정책</h2>
					<p>
						웨딩북은 예비신부 / 신랑님들이 직접 작성한 후기를 수집하여 투명하게 제공하는 것을 매우 중요하게 생각합니다.<br/>
						특히 작업성 후기를 필터링하기 위해 시스템 검수, 운영팀 검수를 꼼꼼하게 진행하고 있습니다.<br/>
						<br/>
						다만 사업자가 사업장 관련 컨텐츠의 삭제를 요청할 경우 정보통신법에 따라 그 즉시 삭제 또는 임시조치를 취해야 할 의무가 있습니다. 이에 따라 상세 내용은 공개할 수 없으나 블라인드로 분류된 후기의 유무, 후기수 등을 기준으로 업체 선택에 참고하여 주시기 바랍니다.
					</p>
				</section>
				<ul className="review-item-list-wrap blind">
					{blindList.map((list, idx) => (
					<li className="review-item" key={`blind-review-item-${list.id}-${idx}`}>
						<div className="review-item-header clearfix">	
							<div className="user-icon" style={{backgroundImage: `url(${list.profileUrl})`}}>
							</div>
							<div className="user-info-wrap">
								<div>
									<span className="user-nick">{list.nick}</span>
									{list.isCertification &&
									<span className="user-verified">
										인증
										<i className="icon icon-verified" style={{marginTop: -1}}></i>
									</span>	
									}
								</div>
								<span className="user-route small">
									{list.weddingMonth} {list.partnerText ? `/ ${list.partnerText}` : ''}
								</span>
							</div>
							<time className="user-writing-time" dateTime={list.regTsp}>
								{getDiffToString(list.regTsp)}
							</time>
						</div>
						<div className="blind-content-wrap">
							<em>사용자 요청에 따라 비공개되었습니다.</em>
							<span>(정보통신망 관렵법에 의거)</span>
						</div>
					</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default BlindReviews;