import { debounce } from "lodash";
import { useCallback } from 'react';

/**
 * - SignInUp 페이지에서 주로 사용하는 input 이벤트 핸들러
 */

const useSignInputEvent = () => {
    
    // value setter
    const signValueSetter = (
        valueSetter: React.Dispatch<React.SetStateAction<any>> 
    ) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            valueSetter(value);
        }
    }
        
    // 데이터 유효성 검사
    const signDataValidate = (
        dataValidate: (...values: any[]) => Promise<any>,
        resultObjectSetter?: React.Dispatch<React.SetStateAction<any>>,
    ) => {
        return (...values: any[]) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const resultObject = await dataValidate(...values);
                    if(resultObjectSetter) {
                        resultObjectSetter(resultObject);
                    }
                    resolve(resultObject);
                } catch (error) {
                    reject(error);
                }
            });
        }
    }
    
    // keydown 이벤트 핸들러
    const signEnterKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>, condition: boolean, action: () => void) => {
        if (e.key === 'Enter' && condition) {
            action();
        }
    }

    return {
        signValueSetter,
        signDataValidate,
        signEnterKeyEvent
    }
}



export default useSignInputEvent;