

import axios from '../axios';
import { BASE_URL } from './../../utils/index';
import QueryString from "qs";

// export interface IPartner {
// 	partnerId: number;
// 	partnerName: string;
// 	coverUrl: string;
// 	partnerUuid: string;
// }

export const getPartnerList = (partnerUuid: string) => {
	return axios.get<{item: any[]}>(`${BASE_URL}/web/v3/240119/homeApplianceInform/partners?partnerUuid=${partnerUuid}`);
}
export const getPartnerBookingTime = (partnerUuid: string, visitTsp: string) => {
	return axios.get<{item: {time: string}}>(`${BASE_URL}/web/v3/240123/partner/booking/time?partnerUuid=${partnerUuid}&visitTsp=${visitTsp}`);
}
interface IHomeApplianceInform {
	partnerUuid: string;
	name: string;
	tel: string;
	mateName: string;
	mateTel: string;
	infoAgree: 1 | 0;
	inpath?: string;
	visitTsp: string;
}

export const postHomeApplianceInform = (params: IHomeApplianceInform) => {
	return axios.post<{item: any[]}>(`${BASE_URL}/web/v3/240119/homeApplianceInform`, QueryString.stringify(params));
}