import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import { useNavigate } from 'react-router-dom';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import { setWeddingRegion } from '@src/@slice/recommendHallSlice';
import RedBoxList from '../../../components/booking/redBoxList/RedBoxList';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';

const region = {
	'서울': [
		'강남, 서초', '영등포, 구로', '종로, 용산, 중구', '강서, 양천, 마포, 은평, 서대문',
		'강동, 송파', '관악, 동작, 금천', '광진, 성동', '성북, 동대문, 중랑, 노원, 강북, 도봉'
	],
	'경기': [
		'수원, 용인', '부천', '성남', '광명, 안산, 안양, 의왕, 시흥',
		'고양, 파주', '의정부, 양주, 포천', '구리, 남양주, 하남, 광주', '평택, 이천'
	],
	'인천': [
		'인천 전체'
	],
	'주요지역': [
		'부산', '대전', '대구', '광주', '전라', '강원'
	]
}
type TMainRegion = keyof typeof region;
const Region = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const [selected, setSelected] = useState<string[]>([]);
	const [mainRegion, setMainRegion] = useState<TMainRegion>('서울');
	const [subRegion, setSubRegion] = useState('');
	const {hallData, dispatch} = useRecommendHall();
	useEffect(() => {
		if (mainRegion) {
			// setSubRegion(region[mainRegion][0]);
			setSubRegion('');
		}
	}, [mainRegion]);
	useEffect(() => {
		if (subRegion !== '') {
			if (selected.length < 2) {
				setSelected([...selected, `${mainRegion} - ${subRegion}`]);
				dispatch(setWeddingRegion({
					region: { mainRegion: mainRegion, subRegions: subRegion }
				}));
			}
			setSubRegion('');
		}
	}, [subRegion]);
	useEffect(() => {
		setValidate(hallData.weddingRegions.length > 0);
		setSelected(hallData.weddingRegions.map(region => `${region.mainRegion} - ${region.subRegions}`));
	}, [hallData.weddingRegions]);
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === ''
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	const goNext = () => {
		navigate('/recommend/hall/visitor');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`예식 지역을 선택해 주세요.`}
					/>
					<DataSection
						title="지역을 선택해 주세요"
						typeStr="최대 2개 선택"
					>
						<select className={`select select-full ${false ? 'select-gray' : ''}`}
							onChange={e => {
								setMainRegion(e.target.value as TMainRegion);
							}}
						>
							{Object.keys(region).map(region => (
								<option value={region} selected={region === mainRegion} key={`main-opt-${region}`}>{region}</option>
							))}
						</select>
					</DataSection>
					<DataSection
						title="상세 지역을 선택해주세요"
					>
						<select className={`select select-full ${subRegion === '' ? 'select-gray' : ''}`}
							onChange={e => {
								setSubRegion(e.target.value);
							}}
						>
							<option value="" selected={subRegion === ''}>상세 지역 선택</option>
							{region[mainRegion].map(region => (
								<option value={region} selected={region === subRegion} key={`sub-opt-${region}`}>{region}</option>
							))}
						</select>
					</DataSection>
					{/* {hallData.weddingRegions.map(region => (
						<p>{region.mainRegion} - {region.subRegions}</p>
					))} */}
					<RedBoxList
						items={selected.map(sel => ({
							origin: sel,
							data: sel
						}))}
						removeItem={item => {
							dispatch(setWeddingRegion({
								region: { mainRegion: item.split(' - ')[0], subRegions: item.split(' - ')[1] }
							}));
						}}
					/>
				</div>
				<div>
					<LinkWegazine
						title="지역을 선택하시기 전이라면?"
						link="https://www.weddingbook.com/wegazine/7407e022-8411-11e8-bc23-0ab211c9f708?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Region;