import './pageTitle.scss';

const PageSubTitle = ({title}: {title: string}) => {
	return (
		<div className="page-sub-title-wrap">
			<h2>{title}</h2>
		</div>
	);
};

export default PageSubTitle;