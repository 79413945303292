import './socialLogin.scss';
import { HOSTNAME } from '@src/utils';
import { logEvent } from '@src/firebase';

interface SocialLoginProps {
    latestLogin?: boolean;
}

// 카카오 로그인
const KakaoLogin = () => {
    logEvent('Start_Kakaotalk_v2', {});
    window.location.href = `h2mwbell://webview?url=${HOSTNAME}/social/kakao`;
}

export const SocialKakao = ({ latestLogin }:SocialLoginProps) => {
	return (
        <>
            <button className={`btn btn-icon btn-transparent btn-tight btn-auto-height`} onClick={KakaoLogin}>
                <i className={`icon icon-sign-kakao`}></i>
                {latestLogin && <span className="btn-tooltip-medium">최근 로그인했어요!</span> }
            </button> 
        </>
	);
};

const NaverLogin = () => {
    logEvent('Start_Naver_v2', {});
    window.location.href = `h2mwbell://webview?url=${HOSTNAME}/social/naver`;
}

// 네이버 로그인
export const SocialNaver = ({ latestLogin }:SocialLoginProps) => {
    return (
        <button className={`btn btn-icon btn-transparent btn-tight btn-auto-height`} onClick={NaverLogin}>
            <i className={`icon icon-sign-naver`}></i>
            {latestLogin && <span className="btn-tooltip-medium">최근 로그인했어요!</span> }
        </button>
    )
};

const AppleLogin = () => {
    logEvent('Start_Apple_v2', {});
    window.location.href = `h2mwbell://webview?url=${HOSTNAME}/social/apple`;
}

// 애플 로그인
export const SocialApple = ({ latestLogin }: SocialLoginProps) => {

    return (
        <button className={`btn btn-icon btn-transparent btn-tight btn-auto-height`}
            onClick={AppleLogin}>
            <i className={`icon icon-sign-apple`}></i>
            {latestLogin && <span className="btn-tooltip-medium">최근 로그인했어요!</span>}
        </button>
    );
}
