import React, { useEffect, useState } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { SignUpData } from '@src/@slice/SignUpSlice';
// router
import { useNavigate } from 'react-router-dom';
// hooks
import useSignInputEvent from '@src/utils/hooks/useSignInputEvent';
import { useSignEmail, useSignPassword, useSignValidation, useCustomInput } from '@src/utils/hooks/useSignInput';
import { useSignUpAllField } from '@src/utils/hooks/useSignUpField';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';
import DataSection from '@src/components/form/dataSection/DataSection';
import { PasswordInfo, PasswordPlaceholder } from '@src/components/common/inputTypeBox/PasswordInfo';
// analytics
import { logEvent } from '@src/firebase';

// 회원가입 Step1 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();
    
    // Redux
    const ForSignUpData = useSelector(SignUpData);

    // input Event
    const { signValueSetter, signEnterKeyEvent } = useSignInputEvent();

    // keydown 이벤트 핸들러
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        signEnterKeyEvent(e, formatValid, handleNext)
    }

    // 다음 단계 이동 트리거
    const [nextTrigger, setNextTrigger] = useState(false);

    // 이메일
    const signEmail = useSignEmail({
        val: ForSignUpData.personalInfo.loginId || '',
        data: ForSignUpData.personalInfo.loginIdVerified,
        format: ForSignUpData.personalInfo.loginIdVerified,
        dataValidCheckType: 'newAccount',
    })

    // 비밀번호 & 비밀번호 재입력
    const signPassword = useSignPassword({
        val: ForSignUpData.personalInfo.password || '',
        data: ForSignUpData.personalInfo.password ? true: false,
        format: ForSignUpData.personalInfo.password ? true: false,
    });
    const passwordCheckSuccess = { result: 'success', type: 'all', label: '', message: '비밀번호가 일치합니다.' };
    const passwordCheckError = { result: 'error', type: 'all', label: 'Invalid Input', message: '동일한 비밀번호를 입력해주세요.' };
    const signPasswordCheck = useCustomInput({
        val: ForSignUpData.personalInfo.confirmPassword || '',
        dataErrorCheck: (val: string) => {
            handlePasswordCompare(signPassword.value, val);
        }
    });

    // 비밀번호 비교 함수
    const handlePasswordCompare = (password: string, passwordCheck: string) => {
        let lengthCondition = passwordCheck.length > 0;
        if (lengthCondition) {
            if (password !== passwordCheck) {
                signPasswordCheck.setResultObject(passwordCheckError);
            } else {
                signPasswordCheck.setResultObject(passwordCheckSuccess) ;
            }
        } else {
            signPasswordCheck.setResultObject({ result: 'error', type: 'all', label: 'Invalid Input', message: '' });
        }
    }

    // 비밀번호, 비밀번호 확인란 값 변경 시 비교
    useEffect(() => {
        handlePasswordCompare(signPassword.value, signPasswordCheck.value);
    }, [signPassword.value, signPasswordCheck.value]); //비밀번호, 비밀번호 재입력 값 변경 시 비교

    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([signEmail.formatValid, signPassword.formatValid, signPasswordCheck.formatValid]);
    const dataValid = useSignValidation([signEmail.dataValid, signPassword.dataValid, signPasswordCheck.dataValid]);

    // 이메일 중복 확인
    const handlerDuplicateCheck = async () => {
        //이메일 유효성 확인
        if (!signEmail.dataValid) {
            await signEmail.emailDataErrorCheck(signEmail.value);
        }
    }

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        if (! signEmail.dataValid) {
            signEmail.setResultObject({ result: 'error', type: 'dataValid', label:'Duplicate Check', message: '중복 확인을 완료해 주세요.' });
        } else {
            setNextTrigger(true);
        }
    }

    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid) {
                goNext();
            } else {
                setNextTrigger(false);
            }
        }
    }, [nextTrigger, formatValid, dataValid]); 

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        logEvent('Signup_IDAndPW_v2', {})
        navigate('/sign-up/2');
    }

    // 마운트 시 리덕스 값 적용 / 언마운트 시 리덕스에 값 저장
    useSignUpAllField({
        signFields: {
            'personalInfo': {
                loginId: signEmail,
                loginIdVerified: {
                    value: signEmail.dataValid,
                    setValue: (value: boolean) => {
                        signEmail.setDataValid(value);
                    }
                },
                password: signPassword,
                confirmPassword: signPasswordCheck
            }
        },
    })
    
    return (
		<>
            <div className="contents-wrap">
                <DataSection title="">
                    <div className="form-wrap">
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">이메일</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="email"
                                    placeholder='이메일'
                                    inputMode='email'
                                    value={signEmail.value}
                                    onChange={signValueSetter(signEmail.setValue)}
                                    onKeyDown={handleKeyDown}
                                    resultObject={signEmail.resultObject}
                                    showInfoMessage={{ success: true, error: true }}
                                    showResultBorder={{ success: true, error: true }}
                                    buttonComponent={
                                        <button className={`btn btn-black ${(!signEmail.formatValid) ? 'btn-disabled' : ''}`}
                                            onClick={handlerDuplicateCheck}
                                            disabled={! signEmail.formatValid}>중복확인
                                        </button>
                                    }
                                />
                                
                            </div>
                        </div>
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">비밀번호</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="password"
                                    placeholder={PasswordPlaceholder}
                                    value={signPassword.value}
                                    onChange={signValueSetter(signPassword.setValue)}
                                    onKeyDown={handleKeyDown}
                                    infoComponent={<PasswordInfo resultObject={signPassword.resultObject} />}
                                    resultObject={signPassword.resultObject}
                                    showInfoMessage={{ success: false, error: true }}
                                    showResultBorder={{ success: true, error: true }}
                                />
                            </div>
                        </div>
                        <div className="form-block">
                            <div className="form-title">
                                <span className="title">비밀번호 재입력</span>
                            </div>
                            <div className="form-content">
                                <InputTypeBox type="password"
                                    placeholder={PasswordPlaceholder}
                                    value={signPasswordCheck.value}
                                    onChange={signValueSetter(signPasswordCheck.setValue)}
                                    onKeyDown={handleKeyDown}
                                    resultObject={signPasswordCheck.resultObject}
                                    showInfoMessage={{ success: true, error: true }}
                                    showResultBorder={{ success: true, error: true }}
                                />
                            </div>
                        </div>
                    </div>
                </DataSection>
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                        disabled={! formatValid}
                        onClick={handleNext}>다음</button>
                </div>
            </div>
		</>
	);
};

const SignUpStep1 = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="회원가입" />}
            contentsComponent={<Contents />}
        />
    );
};

export default SignUpStep1;