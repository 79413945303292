import { Fragment } from 'react';
import RollingBanner from '../home/rollingBanner/RollingBanner';
import TextButtonList from '../home/textButton/TextButtonList';
import GoalButtonList from '../home/goalButton/GoalButtonList';
import WeddingBookTV from '../home/WBTV/WeddingBookTV';
import ListCard from '../home/listCard/ListCard';
import IconList from '../home/iconList/IconList';
import ShortcutMenu from '../home/shortcutMenu/ShortcutMenu';
import InterestedCompany from '../home/myCompany/MyCompany';
import UserReviews from '../home/userReview/UserReviews';
import InquiryConcierge from '../home/inquiryConcierge/InquiryConcierge';
import SDMContract from '../home/sdmContract/SDMContract';
import SwipeBannerComponent from '../home/swipeBanner/SwipeBanner';
import PartnerTagList from '../home/partnerTagList/PartnerTagList';
import TextCard from '../home/textCard/TextCard';
import {
	ProductCuration,
} from '../home/productCuration/productCuration';
import VendorCuration from '../home/vendorCuration/VendorCuration';
import Wegazine from '../home/wegazine/Wegazine';
import Package from '../home/package/Package';
import CompanyOrder from '../home/companyOrder/CompanyOrder';
import WeddingStamp from './../home/weddingStamp/WeddingStamp';
import SwipeGoalButtonList from '../home/swipeGoalButton/SwipeGoalButtonList';

export type ComponentType =
	| 'rollingBanner'
	| 'textCard'
	| 'textButton'
	| 'goalButton'
	| 'swipeGoalButton'
	| 'weddingBookTv'
	| 'listCard'
	| 'iconImage'
	| 'shortCut'
	| 'vendorCuration'
	| 'partnerTagList'
	| 'wegazine'
	| 'review'
	| 'favorite'
	| 'inquiryConcierge'
	| 'sdmContract'
	| 'productList'
	| 'swipeBanner'
	| 'package'
	| 'companyOrder'
	| 'stamp';
export interface ComponentItem {
	id: number;
	jsonStr: string;
	order?: number;
	title?: string;
	type: ComponentType;
	tabId?: number;
}
interface ParserProps {
	data: ComponentItem[];
	page: string;
	pageSub?: string;
}
export const BlankArea = () => {
	return (
		<div className="blank-area" style={{height: 12, backgroundColor: 'rgb(242, 242, 242)'}} />
	)
}
const Parser = ({ data, page, pageSub }: ParserProps) => {
	const parser = () => {
		if (!data) return;
		// console.log(data);
		const t = data.map((d, idx) => {
			const cData = JSON.parse(d.jsonStr); // component data
			// console.log(d.type);
			// console.log(cData);
			if (!cData && d.jsonStr !== null) return null;
			switch (d.type) {
				case 'rollingBanner':
					// done
					return (
						<RollingBanner
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							items={cData.data}
							key={`${d.type}-${d.id}-${idx}`}
						/>
					);
				case 'swipeGoalButton':
					// done
					return (
						<GoalButtonList 
							id={d.id} 
							page={page}
							pageSub={pageSub}
							items={cData}
							key={`${d.type}-${d.id}-${idx}`}
						/>
					);
				case 'goalButton':
					// done
					return (
						<SwipeGoalButtonList 
							id={d.id} 
							page={page}
							pageSub={pageSub}
							items={cData}
							top={cData.top}
							bottom={cData.bottom}
							key={`${d.type}-${d.id}-${idx}`}
						/>
					);
				case 'swipeBanner':
					return (
						<SwipeBannerComponent
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							isCustomWidth={cData.isCustomWidth}
							items={cData.item}
							top={cData.top}
							bottom={cData.bottom}
							rolling={cData.rolling}
							key={`${d.type}-${d.id}-${idx}`}
						/>
					);	
				case 'partnerTagList':
					return (
						<PartnerTagList id={d.id} page={page} pageSub={pageSub} items={cData} key={`${d.type}-${d.id}-${idx}`} />
					)
				case 'textCard':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<TextCard
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							items={cData.data}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'textButton':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<TextButtonList
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							items={cData.data}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'weddingBookTv':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<WeddingBookTV
							id={d.id}
							page={page}
							pageSub={pageSub}
							items={cData.data}
							buttonLink={cData.buttonLink}
							buttonName={cData.buttonName}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'listCard':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<ListCard
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							items={cData.data}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'iconImage':
					// done 
					return (
                        <Fragment key={`component-${d.id}`}>
						<IconList id={d.id} page={page} pageSub={pageSub} items={cData} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'shortCut':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<ShortcutMenu
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							items={cData.data}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'vendorCuration':
					// return null;
					return (
                        <Fragment key={`component-${d.id}`}>
						<VendorCuration id={d.id} page={page} pageSub={pageSub} data={cData} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'wegazine':
					// done
					return (
                        <Fragment key={`component-${d.id}`}>
						<Wegazine id={d.id} page={page} pageSub={pageSub} data={cData} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'review':
					return (
                        <Fragment key={`component-${d.id}`}>
						<UserReviews
							id={d.id}
							page={page}
							pageSub={pageSub}
							title={cData.title}
							reviewType={cData.reviewType}
							partnerType={cData.partnerType}
							partnerUuid={cData.partnerUuid}
							reviews={cData.reviewItems}
							key={`${d.type}-${d.id}-${idx}`}
						/>
						<BlankArea />
						</Fragment>
                    );
				case 'favorite':
					// need user agent
					return (
                        <Fragment key={`component-${d.id}`}>
						<InterestedCompany id={d.id} page={page} pageSub={pageSub} partnerType={cData.partnerType} typeName={cData.partnerTypeName} key={`${d.type}-${d.id}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'inquiryConcierge':
					return (
                        <Fragment key={`component-${d.id}`}>
						<InquiryConcierge page={page} pageSub={pageSub} partnerTypeName={cData.partnerTypeName} key={`${d.type}-${d.id}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'sdmContract':
					// done
					if (cData.contract === '1') {
						return (
                            <Fragment key={`component-${d.id}`}>
							<SDMContract page={page} pageSub={pageSub} key={`${d.type}-${d.id}-${idx}`} />
							<BlankArea />
							</Fragment>
                        );
					}
					return null;
				case 'productList':
					return (
                        <Fragment key={`component-${d.id}`}>
						<ProductCuration id={d.id} page={page} pageSub={pageSub} data={cData} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'package':
					return (
                        <Fragment key={`component-${d.id}`}>
						<Package id={d.id} data={cData} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'companyOrder':
					// return null;
					return (
                        <Fragment key={`component-${d.id}`}>
						<CompanyOrder id={d.id} page={page} pageSub={pageSub} categoryId={cData.partnerType} data={cData.data} key={`${d.type}-${d.id}-${idx}`} />
						<BlankArea />
						</Fragment>
                    );
				case 'stamp':
					return (
                        <Fragment key={`component-${d.id}`}>
						<WeddingStamp id={d.id} gift={cData.item.gift} stamp={cData.item.stamp} />
						<BlankArea />
						</Fragment>
                    );
				default:
					return null;
			}
		});
		return t;
	};
	return <>{data !== null ? parser() : <></>}</>;
};

export default Parser;
