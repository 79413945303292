import { IMyReservationList } from "@src/types/realtimeBooking";
import axios from "../axios";
import { BASE_URL } from "./../../utils/index";

export const getVenueInfo = (partnerId: string ) => {
    return axios.get<{ data: any }>(
        `${BASE_URL}/web/v3/halls/consultings/partners/${partnerId}`
    );
};

export const getCalendarSchedule = (partnerId: string) => {
    return axios.get<{ data: any }>(
        `${BASE_URL}/web/v3/halls/consultings/partners/${partnerId}/slots`
    );
};

export const getDaySchedule = (partnerId: string, date) => {
    return axios.get<{ data: any }>(
        `${BASE_URL}/web/v3/halls/consultings/partners/${partnerId}/slots?visitDate=${date}`
    );
};

export const postReservation = (data, uuid) => {
    if(uuid !== ''){
        return axios.post<any>(`${BASE_URL}/web/v3/halls/consultings/reservations`,data, {
                params: {uuid: uuid}
            }
        );
    }else {
        return axios.post<any>(`${BASE_URL}/web/v3/halls/consultings/reservations`,data);
    };
};



//실시간 예약 내역 조회
export const getMyReservationList = (params: any={}) => {
    const p = new URLSearchParams(params).toString();
    return axios.get<IMyReservationList>(`${BASE_URL}/web/v3/halls/consultings/reservations?${p}`);
};


export const getMyReservationDetail = (id:string, params: any={}) => {
    const p = new URLSearchParams(params).toString();
    return axios.get<{ data: any }>(
        `${BASE_URL}/web/v3/halls/consultings/reservations/${id}?${p}`);
};

export const changeReservationDate = (id:string, date, time, uuid) => {
    const data = {
        'date': date,
        'time': `${time}:00`
    }
    console.log(uuid)
    return axios.put<{result: number}>(`${BASE_URL}/web/v3/halls/consultings/reservations/${id}/visits?uuid=${uuid}`, data);
};

export const cancelReservation = (id:string, uuid) => {
    return axios.put<{ data: any }>(`${BASE_URL}/web/v3/halls/consultings/reservations/${id}/refund?uuid=${uuid}`);
};