import { isExternalUrl } from '../../../utils';

export interface ITextButton {
	buttonText: string;
	buttonUrl: string;
	mainText: string;
	order: number;
	subText: string;
}
const TextButton = ({item, onClick}: {item: ITextButton, onClick: () => void}) => {
	return (
		<div className="text-button-wrap">
			<div>
				<b>{item.mainText}</b>
				<span>{item.subText}</span>
			</div>
			<a href={isExternalUrl(item.buttonUrl)} className="btn btn-light-red" onClick={onClick}>{item.buttonText}</a>
		</div>
	);
};

export default TextButton;