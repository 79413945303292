import './modal.scss';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IModalButton {
	name: string;
	className?: string;
	style?: React.CSSProperties;
	action: () => void;
}
interface IModal {
	modalId?: string;
	isShow: boolean;
	title?: string;
	overlay?: boolean;
	overlayValue?: number;
	theme?: 'brown';
	text?: string;
	buttons?: IModalButton[];
	onClose?: () => void;
}
const Modal = ({modalId, isShow, overlay = false, overlayValue = 0.08, theme, title, text, buttons, onClose}: IModal) => {
	const uuid = uuidv4();
	return (
		<div className="modal-wrap" style={{display: isShow ? 'block' : 'none'}}>
			{overlay && <div className="modal-overlay" style={{backgroundColor: `rgba(0, 0, 0, ${overlayValue})`}}></div>}
			<div className="modal">
				{title && <h2 className="modal-title">{title}</h2>}
				{text && <p className="modal-text">{text}</p>}
				{buttons 
				? <div className={`modal-btn-wrap btn-wrap ${buttons.length >= 2 ? 'items-2' : ''}`}>
					{buttons.map(button => (
						<div key={`${modalId ? modalId : uuid}-${button.name}`}>
							<button 
								style={button.style ? button.style : {}}
								className={`btn btn-full btn-large ${button.className}`}
								onClick={() => button.action()}
							>
								{button.name}
							</button>
						</div>
					))}
				</div>
				: <div className="modal-btn-wrap">
					<button className={`btn ${theme ? `btn-${theme}` : 'btn-red'} btn-large btn-full`} onClick={onClose}>확인</button>
				</div>
				}
				
			</div>
		</div>
	);
};

export default Modal;