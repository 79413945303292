import './textCard.scss';
import { useState } from 'react';
import SectionTitle, {ITitle} from '../../common/sectionTitle/SectionTitle';
import { isExternalUrl } from '../../../utils';
import { logEvent } from '@src/firebase';

interface TextCardPRrops {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	items: any[];
}
function TextCard({id, page, pageSub, title, items}: TextCardPRrops) {
	return (
		<section className="text-card-wrap">
			<SectionTitle 
				title={title}
			/>
			<ul className="card-list-wrap">
				{items.map((item, idx) => (
					<li key={`text-card-${id}-${idx}`}>
						<a href={isExternalUrl(item.link)} onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'text_card',
								behavior: 'click',
								content: item.content
							};
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
						}}>
							<i className="icon" style={
								{
									display: 'block',
									backgroundImage: `url(${item.imgUrl})`,
									backgroundSize: '40px', width: '40px', height: '40px'
								}
							}/>
							<h3>
								{item.title1}<br/>
								{item.title2}
							</h3>
							<p>{item.content}</p>
						</a>
					</li>
				))}
			</ul>
		</section>
	);
}

export default TextCard;