import './bottomFixedModal.scss';
import * as React from 'react';

interface Props {
	isTransition?: boolean;
	isShow: boolean;
	hideModal: () => void;
	title: string;
	hasCloseButton?: boolean;
	children: React.ReactNode
}
const BottomFixedModal = ({isTransition = false, isShow, hideModal, title, children, hasCloseButton = false}: Props) => {
	return (
		<div className={`bottom-fixed-modal-wrap ${isShow ? 'show' : ''}`}>
			<div className="overlay" onClick={() => hideModal()}></div>
			<div className={`bottom-fixed-modal ${isTransition ? 'has-transition' : ''} ${isShow ? 'do-transtion' : ''}`}>
				{hasCloseButton &&
				<div className="modal-close-button-wrap">
					<button className="btn btn-tight btn-no-padding btn-auto-height btn-transparent" onClick={hideModal}>
						<i className="icon icon-close-black"></i>
					</button>
				</div>
				}
				<h2 className="modal-title">{title}</h2>
				<div className="modal-content">
				{children}
				</div>
			</div>
		</div>
	);
};

export default BottomFixedModal;