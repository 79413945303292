import Axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { BASE_URL } from '@src/utils';

export interface IResData<T> {
	result?: number;
	data: T;
}

const axios = Axios.create();

// 요청 인터셉터
axios.interceptors.request.use((config: AxiosRequestConfig) => {
	// 서버에서 호출한 경우 추가 작업 없이 리턴
	// console.log(config.headers!);
  // config.headers.common['Content-Type'] = 'application/json';
  return config;
});

// 응답 인터셉터 에러 발생 시
const axiosInterceptorsError = () => {
  // 실패 시 로그인 페이지로 이동
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.alert('로그인이 필요하여 로그인 페이지로 이동합니다.');
  window.location.href = '/sign-in/social';
}

// 응답 인터셉터
axios.interceptors.response.use(
  response => {
    // 정상 응답을 그대로 반환  
    return response;
  },
  error => {
    // 응답 객체가 없거나 다른 오류인 경우 바로 거부
    if (!error.response) return Promise.reject(error);

    // 원래 요청 객체 저장
    const originalRequest = error.config;

    // 토근 만료 시
    if (error.response.status === 401 && !originalRequest._retry && error.response.data.msg === 'token_expired') {
      console.log('Token expired', originalRequest._retry);

      // 무한 재시도 방지를 위해 재시도 플래그 설정
      originalRequest._retry = true;

      // 리프레시 토큰과 액세스 토큰을 로컬 스토리지에서 가져옴
      const refreshToken = localStorage.getItem('refreshToken');
      const accessToken = localStorage.getItem('accessToken');

      // 토큰이 있는 경우
      if (refreshToken && accessToken) {

        // 토큰 갱신을 위한 요청 정보
        const url = `${BASE_URL}/v3/jwt/refresh?refreshToken=${refreshToken}`;
        const headers = { 'Authorization': `bearer ${accessToken}` };
        const data = { refreshToken: refreshToken };

        // 토큰 갱신 요청
        return axios.post<ServerResponse>(url, data, { headers })
          .then(response => {
            console.log('Token refreshed', response);
            // 새 토큰을 로컬 스토리지에 저장
            localStorage.setItem('accessToken', response.data.item.accessToken);
            localStorage.setItem('refreshToken', response.data.item.refreshToken);

            // 원래 요청의 헤더에 새 액세스 토큰 설정
            originalRequest.headers['Authorization'] = 'bearer ' + response.data.item.accessToken;

            // 원래 요청을 다시 전송
            return axios(originalRequest);
          })
          .catch(refreshError => {
            console.error("Token refresh failed", refreshError);
            // 실패 시 로그인 페이지로 이동
            axiosInterceptorsError();
            return Promise.reject(refreshError);
          });
      }
    }

    // "Unauthorized" 메시지 처리
    if (error.response.status === 401 && error.response.data.msg === 'Unauthorized' && !originalRequest._retryUnauthorized) {
      originalRequest._retryUnauthorized = true;
      console.log('Unauthorized');
      axiosInterceptorsError();
    }

    // 그 외의 경우, 오류를 그대로 거부
    return Promise.reject(error);
  }
);


export default axios;

// 서버 응답 타입 (기본)
export interface ServerResponse{
	item: any;
	result: number;
	msg?: string | null;
	total?: any;
}