import './linkWegazine.scss';
import { isWebview as iw, isExternalUrl } from '@src/utils';
interface Props {
	title: string;
	linkText?: string;
	link: string;
}
const LinkWegazine = ({title, linkText = '보러가기', link}: Props) => {
	const isWebview = iw();
	return (
		<div className="common-wrap">
			<div className="link-wegazine-wrap">
				<span className="link-title">{title}</span>
				<a href={isWebview ? isExternalUrl(link) : link} target={isWebview ? '_self' : '_blank'} rel="noreferrer" >
					{linkText}
					<i className="icon icon-next-arrow-nor-18-green"></i>
				</a>
			</div>
		</div>
	);
};

export default LinkWegazine;