import {IIntroItem} from "../../types/interface/Chungdam";

type IntroItemProps = {
    introItem: IIntroItem
}

export default (props: IntroItemProps) => {
    const {introItem} = props;

    return (
        <div className={'content-item'}>
            <div className={'intro-title-wrap'}>
                <h3 className={'golden-title'}>{introItem.miniTitle}</h3>
                <div className={'floor-wrap'}>
                    <h6>{introItem.floor}</h6>
                </div>
            </div>
            <h2 className={'title'}>{introItem.title}</h2>
            <div className={'image'} style={{backgroundImage: `url('${introItem.imageUrl}')`}}/>
            <p className={'content'}>{introItem.description}</p>
        </div>
    )
}
