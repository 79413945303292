import {useEffect, useState} from 'react';
import dayjs from "dayjs";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {setAdditionalInquiry, realtimeBooking} from '@src/@slice/realtimeBookingSlice';
import BookingTitle from "@src/components/booking/bookingTitle/BookingTitle";
import AppHeader from "@src/components/common/appHeader/AppHeader";
import IconCheck from "../../../../assets/images/icon/checkmark_p500.svg";
import Checkbox from "@src/components/common/checkbox/Checkbox";
import RealTimeStopReservation from '../RealTimeStopReservation';
import RealTimeEditBottomFixedModal from './RealTimeEditBottomFixedModal';
import RealTimeStepVisitor from './RealTimeStepVisitor';
import RealTimeStepHopeDate from './RealTimeStepHopeDate';
import RealTimeStepCutomerInfo from './RealTimeStepCutomerInfo';
import BottomFixedModal from '@src/components/common/bottomFixedModal/BottomFixedModal';


const days = ['일','월', '화','수','목','금','토'];

const RealTimeStepPayment = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const isCheck = location.state?.isAgree;
    const partnerId = params.id
    const reservationData = useSelector(realtimeBooking).data;
    const hallData = useSelector(realtimeBooking).hall;
    const hallName = sessionStorage.getItem('hallName')
    const dispatch = useDispatch();

    const [showStopModal, setShowStopModal] = useState(false); // 예약취소 모달
    const [showEditModal, setShowEditModal] = useState({
        show: false, 
        mode: null
    })

    const [isAgree, setIsAgree] = useState(false || isCheck)

    useEffect(()=>{
        
    })
   
    const generateOrderId = (len: number): string => {
        if (len < 6 || len > 64) {
            console.error("6~64 사이의 숫자를 파라미터로 전달해주세요.");
        }
    
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';
        let result = '';
        for (let i = 0; i < len; i++) {
            const randomIdx = Math.floor(Math.random() * characters.length);
            result += characters[randomIdx];
        }
        return result;
    }

   

    const sendReservation = () => {
        const tossOrderId = generateOrderId(24); // 토스 주문번호
        sessionStorage.setItem('reservationData',JSON.stringify(reservationData));
    
        if(hallData.deposit > 0){
            navigate(`/booking/real-time/tosspay/${tossOrderId}`);
        }else {
            navigate( `/booking/real-time/tosspay/success?name=${hallName}`)
        } 
    }

    const EditMode = () => {
        switch(showEditModal.mode){
            case 'visitor':
                return <RealTimeStepVisitor/>
            case 'hope-date':
                return <RealTimeStepHopeDate/>
            case 'customer-info':
                return <RealTimeStepCutomerInfo/>
            default:
                break
        }
    }

    return (
        <>
            <AppHeader 
                type="stack" 
                actionItems={[{name:'stop', callback: ()=>{ setShowStopModal(true) }}]} />
            {/* <div className={`page-realtime-payment ${showEditModal.mode ? 'hidden': ''}`}> */}
            <div className={`page-realtime-payment`}>
                <BookingTitle title={`정보 확인 후\n결제를 완료해주세요.`}/>
                <p>
                    {hallData.name}
                    <span className="stick">|</span>
                    {dayjs(reservationData.visitDate).format('YYYY.MM.DD')}{`(${days[dayjs(reservationData.visitDate).get('day')]}) `}{reservationData.visitTime.slice(0,-3)}
                </p>

                <div className="divider" />

                <div className="reservation-info-wrap">
                    <h3>예약정보</h3>
                    <div className="info-title">
                        <p className="bold">예상하객수</p>
                        {/* <p onClick={()=>setShowEditModal({show: true, mode: 'visitor'})}>수정</p> */}
                        <p onClick={()=>navigate(`/booking/real-time/${partnerId}/edit/visitor`, {state: {edit:'visitor' }})}>수정</p>
                    </div>
                    <table>
                        <thead />
                        <tbody>
                            <tr>
                                <td>예상 하객수</td>
                                {reservationData.expectedMinPersonnel >= 300 ?
                                <td>300명 이상</td>:
                                <td>{reservationData.expectedMinPersonnel}명 ~ {reservationData.expectedMaxPersonnel}명</td>
                                }
                            </tr>
                        </tbody>
                    </table>
                    <div className='divider-line'/>
                    <div className="info-title">
                        <p className="bold">희망예식일정</p>
                        {/* <p onClick={()=>{
                            setShowEditModal({show: true, mode: 'hope-date'})
                        }}>수정</p> */}
                        <p onClick={()=>navigate(`/booking/real-time/${partnerId}/edit/hope-date`, {state: {edit:'hope-date' }})}>수정</p>
                    </div>
                    <table>
                        <thead />
                        <tbody>
                            {reservationData.hopeWeddingYearMonths.map((date,idx) =>
                            <tr key={date}>
                                <td>{idx+1}순위</td>
                                <td>
                                    {date.split('-')[0]}년 {date.split('-')[1]}월  
                                    {reservationData.hopeWeddingDaysOfTheWeek[idx] && <span className="stick">|</span>}
                                    {reservationData.hopeWeddingDaysOfTheWeek[idx]}  
                                    {reservationData.hopeWeddingTimes[idx] && <span className="stick">|</span>}
                                    {reservationData.hopeWeddingTimes[idx]}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    <div className='divider-line'/>
                    <div className="info-title">
                        <p className="bold">예약자 정보</p>
                        {/* <p onClick={()=>setShowEditModal({show: true, mode:'customer-info'})}>수정</p> */}
                        <p onClick={()=>navigate(`/booking/real-time/${partnerId}/edit/customer-info`, {state: {edit:'customer-info'}})}>수정</p>
                    </div>
                    <table>
                        <thead />
                        <tbody>
                            <tr>
                                <td>신부</td>
                                <td>{reservationData.brideName}<span className="stick">|</span>{reservationData.brideTel}</td>
                            </tr>
                            <tr>
                                <td>신랑</td>
                                <td>{reservationData.groomName}<span className="stick">|</span>{reservationData.groomTel}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="divider" />

                <div className="comment-wrap">
                    <h3>추가문의</h3>
                    <textarea 
                        placeholder="상담 시 안내 받고싶은 내용을 입력해주세요."
                        onChange={(e)=>dispatch(setAdditionalInquiry({body: e.target.value}))}
                        value={reservationData.additionalInquiry}
                    />
                </div>

                <div className="divider" />
                
                <div className="payment-info-wrap">
                    <h3>결제할 금액</h3>
                    <div className="deposit-price">
                        <p className="deposit">
                            방문상담
                            <span className="deposit-badge">보증금</span>
                        </p>
                        <p className="price">{reservationData.paymentAmount.toLocaleString()}원</p>
                    </div>
                    <div className="notice-wrap">
                        <p className="bold">
                            <img src={IconCheck} alt="" />
                            방문상담 보증금 환불정책
                        </p>
                        <div className="subscribe">
                            <div className="refund-policy-wrap">
                                <p>
                                    <span>예정 방문일로부터 5일 전까지</span>
                                    <span>100% 환불</span>
                                </p>
                                <p>
                                    <span>예정 방문일 5일 이내</span>
                                    <span>환불 불가</span>
                                </p>
                        </div>
                        <div>
                            방문일의 예약된 시각보다 10분 초과하여 도착하는
                            경우 예약이 취소되며, 보증금 환불이 불가합니다.
                            <br />
                            <br />
                            예정 방문일로부터 5일 전까지 1회에 한하여 변경
                            및 취소가 가능합니다.
                        </div>
                    </div>
                </div>
                <div>
                    <div className="agree-check-wrap">
                        <Checkbox
                            id={`agree-policy`}
                            name="policy"
                            value="개인정보수집 및 개인정보제공 동의를 모두 읽었으며 이에 동의합니다."
                            setValue={() => setIsAgree(!isAgree)}
                            checked={isAgree}
                        />
                        <p>
                            <span
                                style={{color: "#18b698",textDecoration: "underline",textDecorationColor: "#18b698"}}
                                onClick={()=> navigate(`/booking/real-time/${partnerId}/policy#1`, {state:{isAgree: isAgree}})}
                            >
                                개인정보수집
                            </span>
                            <span style={{ textDecoration: "underline" }}>{' '}및{' '}</span>
                            <span 
                                style={{color: "#18b698",textDecoration: "underline",textDecorationColor: "#18b698"}}
                                onClick={()=> navigate(`/booking/real-time/${partnerId}/policy#2`, {state:{isAgree: isAgree}})}
                            >
                                개인정보제공{' '}
                            </span>
                            동의를 모두 읽었으며 이에 동의합니다.
                        </p>
                    </div>
                    <button 
                        className={`btn btn-full btn-red bold ${!isAgree? 'btn-disabled': ''}`} disabled={!isAgree}
                        onClick={sendReservation}
                    >
                        다음
                    </button>
                </div>
            </div>
            </div>
            {showStopModal && 
            <RealTimeStopReservation showStopModal={showStopModal} setShowStopModal={setShowStopModal}/>}
        </>
    );
};

export default RealTimeStepPayment;
