import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomFixedModal from "@src/components/common/bottomFixedModal/BottomFixedModal";


const RealTimeBottomFixedModal = ({showStopModal, setShowStopModal}) => {
    const navigate = useNavigate();
    const modalRef = useRef<HTMLDivElement>(null);

    const [isShowModal] = useState(true);
    const [isShowDepositInfo, setIsShowDepositInfo] = useState(false);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)){
            setIsShowDepositInfo(false);
        }
    };

    useEffect(() => {
        if (isShowDepositInfo) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isShowDepositInfo]);


    return (
        <div className="page-noti">
        <BottomFixedModal isShow={showStopModal} title="" hideModal={() => setShowStopModal(false)}>
            <div className="booking-real-time-noti-wrap">
                <div className="noti-title">
                    <h2>상담 예약 진행중 불편사항이 있었나요?</h2>
                </div>
                <div style={{marginTop:'10px',padding:'8px 0',color: '#737373',lineHeight: '22.5px'}}>
                    <p>웨딩홀 매니저와 1:1 상담을 진행해보세요.</p>
                    <p>※ 예약 취소 시 이전에 선택한 내용이 사라집니다.</p>
                </div>
                <div className="noti-btn-wrap">
                    <button className="btn btn-full btn-light-red" onClick={() => window.location.href = 'h2mwbell://chat'}>
                        1:1상담
                    </button>
                    <button
                        className="btn btn-full btn-red bold"
                        onClick={() => {
                            sessionStorage.removeItem('reservationData')
                            window.location.href = 'h2mwbell://webview/close';
                        }}
                    >
                        예약취소
                    </button>
                </div>
            </div>
        </BottomFixedModal>
        </div>
    );
};

export default RealTimeBottomFixedModal;
