import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

// type HallInPath = 'ESTIMATE' | 'VISIT' | 'PROMOTION' | 'ETC';
export interface RecommendWGiftState {
	isComplete: boolean;
	data: {
		customerUuid: string | null;
		inpath: string; // 'APPLICATION' | 'CHAT' | 'PRO' | 'WEB';
		origin: string | null;
		wantPrice: string;
		aiRecommendPartnerId: number[];
		name: string;
		tel: string;
		times: string;
	}
}

const initialState = {
	isComplete: false,
	data: {
		customerUuid: null,
		inpath: 'APPLICATION',
		origin: null,
		partnerUuid: null,
		name: '',
		tel: '',
		times: '',
		wantPrice: '',
		aiRecommendShareId: [],
		aiRecommendPartnerId: []
	}
}  as RecommendWGiftState;

const recommendWGiftSlice = createSlice({
	name: 'recommend',
	initialState,
	reducers: {
		setOrigin(state, action: PayloadAction<{origin: string}>) {
			state.data.origin = action.payload.origin;
		},
		setIsComplete(state, action: PayloadAction<{bool: boolean}>) {
			state.isComplete = action.payload.bool;
		},
		setInPath(state, action: PayloadAction<{inpath: string}>) {
			state.data.inpath = action.payload.inpath;
		},
		setCustomerUuid(state, action: PayloadAction<{uuid: string}>) {
			state.data.customerUuid = action.payload.uuid;
		},
		setData(state, action: PayloadAction<{data: any}>) {
			state.data = action.payload.data;
		},
		setWantPrice(state, action: PayloadAction<{price: string}>) {
			state.data.wantPrice = action.payload.price;
		},
		toggleAiRecommendPartnerIds(state, action: PayloadAction<{id: number}>) {
			const ids = state.data.aiRecommendPartnerId;
			const {id} = action.payload;
			if (ids.indexOf(id) > -1) {
				state.data.aiRecommendPartnerId = ids.filter(pId => pId !== id);
			} else {
				if (state.data.aiRecommendPartnerId.length >= 30) return;
				state.data.aiRecommendPartnerId.push(id);
			}
		},
		setName(state, action: PayloadAction<{name: string}>) {
			state.data.name = action.payload.name;
		},
		setTel(state, action: PayloadAction<{tel: string}>) {
			state.data.tel = action.payload.tel;
		},
		setTimes(state, action: PayloadAction<{times: string}>) {
			state.data.times = action.payload.times;
		}
	},
});


export const { 
	setOrigin, setIsComplete, setInPath, setCustomerUuid, setData, 
	setWantPrice, toggleAiRecommendPartnerIds,
	setName, setTel, setTimes
 } = recommendWGiftSlice.actions;
export const recommendWGift = (state: RootState) => state.recommendWGift;
export const recommendWGiftData = (state: RootState) => state.recommendWGift.data;
export default recommendWGiftSlice.reducer;

