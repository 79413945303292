import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

type HallEndPage = 'customer-info' | 'visit-date';
// type HallInPath = 'ESTIMATE' | 'VISIT' | 'PROMOTION' | 'ETC';
// type HallDataNotObjectKey = 'inpath' | 'partnerUuids';
// type HallDataObjectKey = 'hopeWedding' | 'expectedPerson' | 'hopeVisit' | 'customer';
// type HallDataKey = HallDataNotObjectKey | HallDataObjectKey;
// type HallDataNestedKey = 'yearMonths' | 'days' | 'times' | 'additionalInquiry' | 'alternativeSchedules' | 'alternativeSchedules' |
// 						'min' | 'max' | 'adjustable' | 'dates' | 'role' | 'name' | 'tel' | 'mateName' | 'mateTel';
export interface BookingHallState {
	origin: string | null;
	endPage: HallEndPage;
	isComplete: boolean;
	data: {
		inpath: string; // 'ESTIMATE' | 'VISIT' | 'PROMOTION' | 'ETC';
		hopeWedding: {
			yearMonths: string[];
			days: string[];
			times: string[];
			additionalInquiry: string;
			alternativeSchedules: string[];
		},
		expectedPerson: {
			min: number|null;
			max: number|null;
			adjustable: number;
		},
		partnerUuids: string[],
		hopeVisit: {
			additionalInquiry: string;
			dates: {date: string; order: number}[];
		},
		customer: {
			role: 'GROOM' | 'BRIDE' | '';
			name: string;
			tel: string;
			mateName: string;
			mateTel: string;
		}
	}
}

const initialState = {
	origin: null,
	endPage: 'customer-info',
	isComplete: false,
	data: {
		// customerId: 145705,
		inpath: 'ESTIMATE',
		hopeWedding: {
			yearMonths: [],
			days: [],
			times: [],
			additionalInquiry: '',
			alternativeSchedules: []
		},
		expectedPerson: {
			min: 0,
			max: 0,
			adjustable: 0
		},
		partnerUuids: [],
		hopeVisit: {
			additionalInquiry: '',
			dates: []
		},
		customer: {
			role: '',
			name: '',
			tel: '',
			mateName: '',
			mateTel: ''
		}
	}
} as BookingHallState

const bookingHallSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		setOrigin(state, action: PayloadAction<{origin: string}>) {
			state.origin = action.payload.origin;
		},
		setEndPage(state, action: PayloadAction<{page: HallEndPage}>) {
			state.endPage = action.payload.page;
		},
		setIsComplete(state, action: PayloadAction<{bool: boolean}>) {
			state.isComplete = action.payload.bool;
		},
		setInPath(state, action: PayloadAction<{inpath: string}>) {
			state.data.inpath = action.payload.inpath;
		},
		setData(state, action: PayloadAction<{data: any}>) {
			state.data = action.payload.data;
		},
		setPartnerUuids(state, action: PayloadAction<{uuid: string}>) {
			const {uuid} = action.payload;
			const uuids = state.data.partnerUuids;
			if (uuids.indexOf(uuid) > -1) {
				state.data.partnerUuids = uuids.filter(u => u !== uuid);
			} else {
				state.data.partnerUuids.push(uuid);
			}
		},
		setYearMonths(state, action: PayloadAction<{date: string}>) {
			const {date} = action.payload;
			const yearMonths = state.data.hopeWedding.yearMonths;
			console.log('date : ', date);
			if (yearMonths.indexOf(date) > -1) {
				state.data.hopeWedding.yearMonths = yearMonths.filter(d => d !== date);
			} else {
				state.data.hopeWedding.yearMonths.push(date);
			}
		},
		setDays(state, action: PayloadAction<{day: string}>) {
			const {day} = action.payload;
			const days = state.data.hopeWedding.days;
			if (days.indexOf(day) > -1) {
				state.data.hopeWedding.days = days.filter(d => d !== day);
			} else {
				state.data.hopeWedding.days.push(day);
			}
		},
		setTime(state, action: PayloadAction<{time: string}>) {
			const {time} = action.payload;
			const times = state.data.hopeWedding.times;
			if (times.indexOf(time) > -1) {
				state.data.hopeWedding.times = times.filter(t => t !== time);
			} else {
				state.data.hopeWedding.times.push(time);
			}
		},
		setAlternativeSchedules(state, action: PayloadAction<{schedule: string}>) {
			const {schedule} = action.payload;
			const alternativeSchedules = state.data.hopeWedding.alternativeSchedules;
			if (alternativeSchedules.indexOf(schedule) > -1) {
				state.data.hopeWedding.alternativeSchedules = alternativeSchedules.filter(s => s!== schedule);
			} else {
				state.data.hopeWedding.alternativeSchedules.push(schedule);
			}
		},
		setExpectedPerson(state, action: PayloadAction<{min: number|null; max: number|null}>) {
			const {min, max} = action.payload;
			state.data.expectedPerson.min = min;
			state.data.expectedPerson.max = max;
		},
		setAdjustable(state, action: PayloadAction<{adjustable: number}>) {
			state.data.expectedPerson.adjustable = action.payload.adjustable;
		},
		setDates(state, action: PayloadAction<{arr: {date: string; order: number;}[]}>) {
			state.data.hopeVisit.dates = action.payload.arr;
		},
		setAdditionalInquiry(state, action: PayloadAction<{key: 'hopeWedding' | 'hopeVisit'; data: string;}>) {
			console.log('booking here');
			const {key, data} = action.payload;
			state.data[key].additionalInquiry = data;
		},
		setCustomer(state, action: PayloadAction<{key: keyof typeof initialState.data.customer, data: string;}>) {
			const {key, data} = action.payload;
			if (key === 'role') {
				state.data.customer[key] = (data as 'GROOM' | 'BRIDE');
			} else {
				state.data.customer[key] = data;
			}
		}
	},
});


export const { setEndPage, setOrigin, setIsComplete, setInPath, setData, setPartnerUuids, setYearMonths, setDays, setTime, setAlternativeSchedules, setExpectedPerson, setAdjustable, setDates, setAdditionalInquiry, setCustomer } = bookingHallSlice.actions;
export const bookingHall = (state: RootState) => state.bookingHall;
export const bookingHallEndPage = (state: RootState) => state.bookingHall.endPage;
export const bookingHallData = (state: RootState) => state.bookingHall.data;
export default bookingHallSlice.reducer;

