import { useUserActions } from '@src/utils/hooks/useUserActions';
import './needLogin.scss';
import { linkLogin, linkSignup } from '@src/utils/login';

const NeedLogin = () => {

	const { resetUserData } = useUserActions();

	return (
		<div className="need-login-wrap">
			<i>😢</i>
			<b>로그인이 필요한 서비스입니다</b>
			<p>로그인하고 맞춤 컨텐츠와 다양한 혜택을 받으세요.</p>
			<div>
				<button className="btn bold btn-small brown500 btn-transparent" style={{ marginRight: 12 }}
					onClick={() => {
						resetUserData();
						linkLogin()
					}}>로그인하기</button>
				<button className="btn bold btn-small btn-brown" onClick={() => {
					resetUserData();
					linkSignup()
				}}>회원 가입하기</button>
			</div>
		</div>
	);
};

export default NeedLogin;