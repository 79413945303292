import axios from '../axios';
import { BASE_URL } from './../../utils/index';

// 홀 추천 start
export const postRecommendHall = (data: any) => {
	return axios.post(`${BASE_URL}/web/v1/weddinghall/recommendation/request`, data);
}
export const getRecommendHall = (uuid: string) => {
	return axios.get<{item: any}>(`${BASE_URL}/web/v1/weddinghall/recommendation/request/${uuid}`);
}
// 홀 추천 end

// ai 예물 추천 start
export interface IResultData {
	aiRecommendPartnerId: number;
	content: string;
	customerId: number | null;
	id: number;
	jsonStr: string;
	nick: string;
	partnerUuid: string;
	tag: string;
	selectImgs: string[];
	uuid: string;
}
export const getWGiftImages = () => {
	return axios.get<{data: any}>(`${BASE_URL}/web/v1/ai/recommend/image/8`);
}
export const getWGfitShare = (uuid: string) => {
	return axios.get<{data: any}>(`${BASE_URL}/web/v1/ai/recommend/share/${uuid}`);
}
export const getWGfitApply = (uuid: string) => {
	return axios.get<{data: any}>(`${BASE_URL}/web/v1/ai/recommend/apply/${uuid}`);
}
export const makeRecommendWGiftLink = (aiRecommendPartnerImageId: number[]) => {
	return axios.post(`${BASE_URL}/web/v1/ai/recommend/share`, {aiRecommendPartnerImageIds: aiRecommendPartnerImageId});
}
export const postRecommendWGift = (data: any) => {
	return axios.post(`${BASE_URL}/web/v1/ai/recommend/apply`, data);
}

// ai 예물 추천 end 