import { useState, useEffect, useContext, useRef } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTab from './../../components/home/pageTab/PageTab';
import axios from '@src/api/axios';
import { BASE_URL, addComma } from '@src/utils';
import { Link } from 'react-router-dom';
import ReactGA from '@src/utils/ga';
import { UserContext } from '@src/App';
import { useSwipeable } from 'react-swipeable';

let fetch_start = 0;
const FETCH_SIZE = 50;
interface IPointSummary {
	expSchedulePoint: number, total: number, usePoint: number;
}
const MyPoint = () => {
	const user = useContext(UserContext);
	const elTab = useRef<HTMLDivElement|null>(null);
	const tab = useState([
		{tabId: 0, tabTitle: '전체'},
		{tabId: 1, tabTitle: '적립'},
		{tabId: 2, tabTitle: '사용'},
		{tabId: 3, tabTitle: '소멸'}
	])[0];
	const [currentTabId, setCurrentTabId] = useState<number|null>(0);
	const [myPointSummary, setMyPointSummary] = useState<IPointSummary>({expSchedulePoint: 0, total: 0, usePoint: 0});
	const [list, setList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isEndList, setIsEndList] = useState(false); // 포인트 더보기 마지막 까지 도달 시
	const [isEmpty, setIsEmpty] = useState(false);
	const handlers = useSwipeable({
		onSwipedLeft: (data) => {
			if (data.absX > 25) {
				swipeList('left');
			}
		},
  		onSwipedRight:  (data) => {
			if (data.absX > 25) {
				swipeList('right');
			}
		}
	});
	const swipeList = (dir: 'left'|'right') => {
		const idx = tab.findIndex(data => data.tabId === currentTabId);
		// 스크롤 위치 초기화
		const sy = window.pageYOffset;
		if (elTab.current) {
			const tabY = elTab.current.offsetTop;
			if (sy > tabY) {
				window.scrollTo(0, tabY);
			}
		}
		// 탭 변경
		switch (dir) {
			case 'left':
				if (tab.length <= idx + 1) {
					setCurrentTabId(0);
				} else {
					setCurrentTabId(idx + 1);
				}
			break;
			case 'right':
				if (idx - 1 < 0) {
					setCurrentTabId(tab.length - 1);
				} else {
					setCurrentTabId(idx - 1);
				}
			break;
		}
	}
	const getPointList = async () => {
		if (isLoading) return;
		setIsLoading(true);
		axios.get<{item: any[]; total: number;}>(BASE_URL + '/v3/mypage/point', {params: {
			type: currentTabId,
			fetchStart: fetch_start,
			fetchSize: FETCH_SIZE
		}}).then(res => {
			setIsEmpty(res.data.total === 0);
			if (res.data.total < FETCH_SIZE) {
				setIsEndList(true);
			}
			if (res.data.item.length === 0 && res.data.total > 0) {
				alert('더 이상 내역이 존재하지 않습니다.');
				setIsEndList(true);
			} else {
				setList([...list, ...res.data.item]);
				if (res.data.total > FETCH_SIZE) {
					setIsEndList(false);
				}
				fetch_start += FETCH_SIZE;
			}
			setIsLoading(false);
		});
	}
	useEffect(() => {
		axios.get<{item: IPointSummary}>(BASE_URL + '/v3/mypage/point/summary').then(res => {
			setMyPointSummary(res.data.item);
		});
	}, []);
	useEffect(() => {
		// 탭 변환 시 마다
		fetch_start = 0;
		setList([]);
	}, [currentTabId]);
	useEffect(() => {
		if (list.length === 0) {
			getPointList();
		}
	}, [list]);
	return (
		<div className="my-point-wrap">
			<AppHeader type="stack" title="내 포인트" actionItems={[
				{name: 'close'}
			]} />
			<div className="my-summary-wrap">
				<span>{user.me ? user.me.nick : '회원'} 님의 보유 포인트</span>
				<b>{addComma(myPointSummary.total)}P</b>
				<p className="monthly-point-info">
					내가 사용한 포인트 {addComma(myPointSummary.usePoint)} P <br/>
					이번 달 소멸 예정 포인트 {addComma(Math.abs(myPointSummary.expSchedulePoint))} P
				</p>
				<Link 
					to={'/how-to-earn-point'}
					className="btn btn-full btn-link-earn-point"
					onClick={() => {
					ReactGA.event({
							category: 'My Point',
							action: 'click',
							label: '포인트 적립하는 방법 보러가기'
						})	
					}}
				>
					포인트 적립하는 방법 보러가기 🤗
				</Link>
				<p className="summary-notice">
					포인트의 유효기간은 적립일 기준 1년이며, 1년 내 미사용한 포인트 및 잘못 적립된 포인트는 자동 소멸됩니다.
				</p>
			</div>
			<div ref={elTab}>
			<PageTab 
				isFull={true}
				tab={tab}
				currentTabId={currentTabId}
				onChange={(id) => setCurrentTabId(id)}
			/>
			</div>
			
			{isEmpty && <p className="list-empty-text">내역이 없습니다.</p>}
			{!isEmpty &&
			<div {...handlers}>
				<ul className="my-benefit-list-wrap">
					{list.map((item, idx) => (
					<li key={item.regTsp + '-' + idx}>
						<b>{addComma(item.point)}P</b>
						<div>
							<span className="benefit-content">{item.content}</span>
							<span className="benefit-date">{item.regTsp.substr(0, 10)}</span>
						</div>
					</li>
					))}
				</ul>
			</div>
			}
			{(isLoading || list.length === 0) && <div style={{height: 1000}} />}
			{!isEndList &&
			<button className="btn btn-transparent btn-show-more" onClick={() => getPointList()}>더보기</button>
			}
		</div>
	);
};

export default MyPoint;