import { logEvent } from "@src/firebase";

const path = window.location.pathname;
let eventPrev = '';

switch (path) {
	case '/home': eventPrev = 'LookAround_Home_'; break;
	case '/product/planning': eventPrev = 'LookAround_WP_'; break;
	case '/product/store': eventPrev = 'LookAround_Store_'; break;
	case '/mypage': eventPrev = 'LookAround_MyWedding_'; break;
	default: eventPrev = 'LookAround_' + path + '_'; break;
}

export const linkLogin = () => {
	const obj = {
		path: window.location.pathname,
		component: 'NeedLogin',
		behavior: 'click',
		content: '로그인하기'
	}
	logEvent('component_behavior', obj);
	logEvent(eventPrev + 'Login', {});
	window.location.href = 'h2mwbell://login';
}
export const linkSignup = () => {
	const obj = {
		path: window.location.pathname,
		component: 'NeedLogin',
		behavior: 'click',
		content: '회원 가입하기'
	}
	logEvent('component_behavior', obj);
	logEvent(eventPrev + 'Signup', {});
	window.location.href = 'h2mwbell://signup';
}