import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@src/App';
import { useRecommendWGift } from '../../../utils/hooks/useRecommendWGift';
import { setName, setTel, setTimes } from '@src/@slice/recommendWGiftSlice';
import { isExternalUrl, autoHypenPhone, makeArray, chkPhoneTel } from '@src/utils';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import Checkbox from '@src/components/common/checkbox/Checkbox';
import NotiText from '@src/components/common/notiText/NotiText';
import { IResultData, getWGfitShare, postRecommendWGift } from '@src/api/recommend/recommend';
import { uploadImage } from '@src/api/common';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import Modal from '@src/components/common/modal/Modal';


const VISIT_TIMES = [
	'아무 때나',
	'10:00 ~ 12:00',
	'12:00 ~ 13:00',
	'13:00 ~ 19:00'
]
const Apply = () => {
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const shareUuid = query.get('shareUuid');
	const [loading, setLoading] = useState(false);
	const [isApp, setIsApp] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const {wGiftData, dispatch} = useRecommendWGift();
	const [partnerData, setPartnerData] = useState<{[key in string]: any}|null>(null);
	const [resultData, setResultData] = useState<IResultData|null>(null);
	const [uploadImages, setUploadImages] = useState<string[]>([]);
	const [showAlert, setShowAlert] = useState(false);
	const validate = () => {
		if (wGiftData.name.trim() === '') return false;
		if (wGiftData.tel.trim() === '') return false;
		if (wGiftData.times.trim() === '') return false;
		if (!agreement) return false;
		return true;
	}
	const removeImage = (url: string) => {
		setUploadImages(
			uploadImages.filter(image => image !== url)
		);
	}
	const onUploadImage = async (e: any) => {
		if (uploadImages.length >= 5) return;
		const inputs = document.getElementsByClassName('input-file');
		const input = inputs[uploadImages.length] as HTMLInputElement;
		const fileTypes = ['jpg', 'jpeg', 'png', 'bmp'];
		if (input.files && input.files[0]) {
			const file = input.files[0];
			const size = file.size;
			const maxSize = 30 * 1024 * 1024; // 30MB
			const extension = file.name.split('.').pop()?.toLowerCase();
			const isSuccess = fileTypes.indexOf(extension!) > -1;

			if( size > maxSize ) {
				return;
			}

			if( isSuccess ) {
				const formData = new FormData();
				formData.append('file', input.files[0]);
				const {data} = await uploadImage(formData);
				setUploadImages([...uploadImages, data.data]);
			} else {
				// ('업로드 불가능한 첨부파일 입니다.'
			}
		}
	}
	useEffect(() => {
		if (wGiftData.wantPrice === '' || wGiftData.aiRecommendPartnerId.length === 0) {
			alert('정상적인 접근이 아닙니다.');
			window.location.href = '/recommend/weddingGift/start';
		}
	}, []);
	useEffect(() => {
		if (user.me) {
			setIsApp(true);
			dispatch(setTel({tel: user.me.tel}));
		}
	}, [user]);
	useEffect(() => {
		if (!shareUuid) return;
		getWGfitShare(shareUuid).then(res => {
			const data = res.data.data;
			setResultData(data.shareInfo);
			if (data.partner.item.length) setPartnerData(data.partner.item[0]);
		})
	}, [shareUuid]);
	const apply = () => {
		if (!resultData) return;
		if (loading) return;
		setLoading(true);
		postRecommendWGift({
			partnerUuid: resultData.partnerUuid,
			name: wGiftData.name,
			tel: autoHypenPhone(wGiftData.tel),
			times: wGiftData.times,
			wantPrice: wGiftData.wantPrice,
			aiRecommendShareId: resultData.id,
			aiRecommendPartnerId: resultData.aiRecommendPartnerId,
			aiRecommendAttachImages: uploadImages
		}).then(res => {
			setLoading(false);
			if (res.data) navigate('/recommend/weddingGift/complete');
		}).catch(err => {
			setLoading(false);
			setShowAlert(true);
		})
	}
	return (
		<>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="page-wgift-apply">
				<PageTitle 
					title={`${partnerData?.name ? partnerData.name : ''}
					빠른 상담 신청하기`}
				/>
				<div className="form-wrap common-wrap">
					<div className="form-block">
						<div className="form-title">
							<span className="title">이름</span>
						</div>
						<div className="form-content">
							<input 
								type="text"
								className="input-text input-has-line input-full"
								placeholder="이름" 
								value={wGiftData.name}
								onChange={e => dispatch(setName({name: e.target.value}))}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">연락처</span>
						</div>
						<div className="form-content">
							<input 
								type="tel"
								className="input-text input-has-line input-full"
								placeholder="010-0000-0000"
								value={autoHypenPhone(wGiftData.tel)}
								onChange={e => dispatch(setTel({tel: e.target.value}))} 
								readOnly={isApp ? true : false}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">연락 가능 시간대</span>
						</div>
						<div className="radio-button-wrap multiline item2">
							{VISIT_TIMES.map(time => (
								<label className="radio-button" key={`visit-time-${time}`}>
									<input
										type="radio" className="only-sr"
										value={time}
										checked={wGiftData.times === time}
										onChange={e => dispatch(setTimes({times: time}))}
									/>
									<span className="btn btn-small btn-border">{time}</span>
								</label>
							))}
						</div>
						<NotiText
							text="* 선택하신 연락 가능 시간대에 유선 연락 드릴 예정이며, 업체 사정에 따라 다른 시간에 연락을 드릴 수 있습니다."
							style={{margin: '0 -20px'}}
						/>
					</div>
					<div style={{height: 32}}></div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">원하는 스타일이 있다면 사진을 첨부해 주세요.</span>
							<div className="necessary"><span>*</span>선택 사항</div>
						</div>
						<div className="form-content">
							{makeArray(uploadImages.length + 1).map(item => (
								<input id={`upload-${item}`} type="file" hidden accept="image/*" className="input-file only-sr" onChange={e => onUploadImage(e)} />
							))}
							<div className="image-upload-wrap">
								<label htmlFor={`upload-${uploadImages.length}`} className="upload-trigger">
									<span>{uploadImages.length}/5</span>
								</label>
								<ul>
									{uploadImages.map((image, idx) => (
										<li style={{backgroundImage: `url(${image})`}}>
											<span onClick={() => removeImage(image)}></span>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					<div className="agreement-check-wrap" style={{marginBottom: 20}}>
						<Checkbox 
							id="agreement"
							name="agreement"
							value="agreement"
							setValue={() => setAgreement(!agreement)}
							checked={agreement}
						/>
						<label className="agreement-label" style={{marginLeft: 0}}>
							<a 
								href={isApp ? isExternalUrl(`https://www.wdgbook.com/agreement?name=${partnerData?.name}`) : `https://www.wdgbook.com/agreement?name=${partnerData?.name}`}
								target={!isApp ? '_blank' : '_self'}
							>
								개인정보 수집
							</a>
							<span> 및 </span>
							<a 
								href={isApp ? isExternalUrl(`https://www.wdgbook.com/agreement?name=${partnerData?.name}`) : `https://www.wdgbook.com/agreement?name=${partnerData?.name}`}
								target={!isApp ? '_blank' : '_self'}
							>
								제3자 활용
							</a>을 모두 읽었으며 이에 동의합니다.
						</label>
					</div>
					<button
						className={`btn btn-full btn-large btn-red ${!validate() ? 'btn-disabled' : ''}`}
						disabled={!validate()}
						onClick={apply}
					>
						신청하기
					</button>
				</div>
			</div>
			<Modal 
				isShow={showAlert}
				text={'다시 시도해주세요.'}
				onClose={() => setShowAlert(false)}
			/>
		</>
		
	)
}

export default Apply;