import * as React from 'react';
import './swipeBanner.scss';
/**LIBRARY */
import Slider from 'react-slick';
import SectionTitle, {ITitle} from '../../common/sectionTitle/SectionTitle';
/**COMMON */
import { isExternalUrl } from '../../../utils';
import dayjs from 'dayjs';
import { logEvent } from '@src/firebase';
import { clickAdBanner } from '@src/api/ad';


type BannerProps = {
	imgUrl: string;
	page: string;
	pageSub?: string;
	text: string;
	link: string;
	width?: string;
	adCode: string;
	startDate: string;
	endDate: string;
};

type Props = {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	isCustomWidth: string | boolean;
	items: BannerProps[];
	top: string;
	rolling?: boolean;
	bottom: string;
};

const Banner = ({ imgUrl, page, pageSub, width, text, link, adCode, startDate, endDate }: BannerProps) => {
	const Content = () => (
		<>
		<div className='row-card-bg' onClick={() => {
			const obj = {
				page: page,
				pageSub: pageSub,
				component: 'swipe_banner',
				behavior: 'click',
				title: text
			};
			if (pageSub === undefined) delete obj.pageSub;
			logEvent('component_behavior', obj);
			if (adCode) {
				clickAdBanner(adCode);
			}
		}}>
			<img src={imgUrl} alt="swipe-image" />
		</div>
		{text &&
		<div className='row-card-info'>
			<div className='row-name-wrap'>
				<span className='row-name'>{text}</span>
			</div>
		</div>
		}
		</>
	)
	return (
		<>
		{link
			? <a className="row-card" href={link ? isExternalUrl(link) : '#none'} style={{width: width ? (width) : '100%'}}><Content /></a>
			: <div className="row-card" style={{width: width ? (width) : '100%'}}><Content /></div>
		}
		</>
	)
};

function SwipeBannerComponent({ id, page, pageSub, title, isCustomWidth, items, top, bottom, rolling }: Props) {
	const now = dayjs();
	const SETTING = {
		infinite: true,
		speed: 500,
		centerMode: true,
		centerPadding: '14px',
		variableWidth: false,
		adaptiveHeight: true,
		autoplay: false
	};
	if (+isCustomWidth) {
		SETTING.variableWidth = true;
	}
	if (rolling) {
		SETTING.autoplay= true;
	}
	const styles: React.CSSProperties = {};
	if (top) styles.marginTop = +top;
	if (bottom) styles.marginBottom = +bottom;
	
	return (
		<section className="swipe-banner-wrap" style={styles}>
			<SectionTitle title={title} />
			<div>
				<Slider {...SETTING}>
					{items.filter(item => {
						let startTime = null, endTime = null;
						if (item.hasOwnProperty('startDate') && item.startDate !== '') {
							startTime = dayjs(item.startDate);
						}
						if (item.hasOwnProperty('endDate') && item.endDate !== '') {
							endTime = dayjs(item.endDate);
						}
						if (((startTime === null && endTime === null) || (now?.isAfter(startTime) && now?.isBefore(endTime)))) return true;
						return false;
					}).map((item, index) => (
						<Banner 
							text={item.text}
							page={page}
							width={+isCustomWidth ? item.width + 'px' : '100%'}
							imgUrl={item.imgUrl}
							link={item.link}
							startDate={item.startDate}
							endDate={item.endDate}
							adCode={item.adCode}
							key={`swipe-banner-${id}-${index}`}
						/>
					))}
				</Slider>
			</div>
		</section>
	);
}

export default SwipeBannerComponent;
