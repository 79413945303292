import { useContext, useEffect, useState } from 'react';
import DataSection from '@src/components/form/dataSection/DataSection';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import Checkbox from '@src/components/common/checkbox/Checkbox';
import { UserContext } from '@src/App';
import axios from 'axios';
import { BASE_URL, autoHypenPhone } from '@src/utils';
import Modal from '@src/components/common/modal/Modal';
import QueryString from 'qs';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '@src/firebase';

type ModalData = {
	id: string;
	title: string;
	contents: {type: 'groupTitle'|'contentTitle'|'content'|'contentDepth', content: string}[];
};
const SERVICE_TERM: ModalData = {
	id: 'SERVICE_TERM',
	title: '이용약관',
	contents: [
		{type: 'contentTitle', content: '제 1조(목적)'},
		{type: 'content', content: '이 약관은 “유모멘트”가 제공하는 “회원” “서비스”를 이용함에 있어 “회원”과 “유모멘트”간의 의무 및 책임사항과 기타 제반 사항을 규정함을 목적으로 합니다.'},

		{type: 'contentTitle', content: '제 2조(정의)'},
		{type: 'content', content: '이 약관에서 사용하는 용어의 정의는 다음과 같습니다.'},
		{type: 'contentDepth', content: '1.“서비스” : “회원”이 이용할 수 있는 “유모멘트”의 시설물 및 홈페이지를 통한 제반 서비스를 의미합니다.'},
		{type: 'contentDepth', content: '2.“회원” : “유모멘트”의 “서비스”에 접속하여 이 약관에 동의하고 개인정보를 제공하여 “회원” 등록을 한 자로서, 홈페이지에서 제공하는 정보와 “서비스”를 이용하는 고객을 말합니다.'},
		{type: 'contentDepth', content: '3.“아이디”(ID) : “회원”의 식별과 “서비스” 이용을 위하여 “회원”이 정하고 “유모멘트”가 승인하는 문자와 숫자의 조합을 의미한다.'},
		{type: 'contentDepth', content: '4.“비밀번호”(PW) : “회원”이 부여 받은 “아이디”와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 또는 숫자의 조합을 의미한다.'},
		{type: 'contentDepth', content: '5.“회원”탈퇴 : “유모멘트” 또는 “회원”이 “서비스” 이용 이후 그 이용계약을 종료시키는 의사표시를 의미합니다.'},

		{type: 'contentTitle', content: '제 3조 (약관의 게시와 개정)'},
		{type: 'contentDepth', content: '1.유모멘트”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.'},
		{type: 'contentDepth', content: '2.“유모멘트”는 “약관의규제에관한법률”, “정보통신망이용촉진및정보보호등에관한법률(이하 “정보통신망법’)” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'},
		{type: 'contentDepth', content: '3.“유모멘트”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 일정기간 서비스 내 전자우편 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.'},
		{type: 'contentDepth', content: '4.“유모멘트”가 전항 후단에 따라 개정약관을 공지 또는 통지하면서 “회원”에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 “회원”이 명시적으로 거부의 의사표시를 하지 아니한 경우 “회원”이 개정약관에 동의한 것으로 봅니다.'},
		{type: 'contentDepth', content: '5.“회원”이 개정약관의 적용에 동의하지 않는 경우 “유모멘트”는 개정 약관의 내용을 적용할 수 없으며, 이 경우 “회원”은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 “유모멘트”는 이용계약을 해지할 수 있습니다.'},

		{type: 'contentTitle', content: '제 4조 (약관의 해석)'},
		{type: 'contentDepth', content: '1.“유모멘트”는 홈페이지의 이용 및 운영과 관련하여 별도의 운영정책을 둘 수 있습니다. “회원”은 약관과 운영정책의 내용을 수시로 확인하여 그 변경사항 등을 확인해야 합니다.'},
		{type: 'contentDepth', content: '2.“유모멘트”와 “회원” 간 별도로 약정한 내용 또는 운영정책 등이 있으면 그 약정의 내용이 본 약관에 우선합니다. 달리 정한 바가 없다면 본 약관에 정한 바가 우선합니다.'},

		{type: 'contentTitle', content: '제 5조 (이용계약 체결)'},
		{type: 'contentDepth', content: '1.이용계약은 “회원”이 되고자 하는 자(이하“가입신청자”)가 본인 인증 후 약관의 내용에 대하여 동의 후 “회원”으로 체결됩니다.'},
		{type: 'contentDepth', content: `2.”유모멘트”는 “가입신청자”의 신청에 대하여 “서비스”이용을 승낙함을 원칙으로 합니다. 다만, “유모멘트홈페이지”는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수있습니다.
    - 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 “유모멘트”의 회원 재가입 승낙을 얻은 경우에는 예외
    - 타인의 명의를 이용한 경우
    - 허위의 정보를 기재하거나, “유모멘트”가 제시하는 내용을 기재하지 않은 경우
    - 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
    - “유모멘트”는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
		`},
		{type: 'contentDepth', content: '3.제 2항에 따라 회원가입신청의 승낙을 아니하거나 유보한 경우, “유모멘트”는 원칙적으로 이를 가입신청자에게 알리도록 합니다.'},
		{type: 'contentDepth', content: '4.이용계약의 성립 시기는 “유모멘트”가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.'},

		{type: 'contentTitle', content: '제 6조 (“회원”정보의 변경)'},
		{type: 'contentDepth', content: '1.“회원”은 “회원”정보 수정화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 유모멘트 “회원”정보로부터 수집되는 성별은 수정이 불가능합니다.'},
		{type: 'contentDepth', content: '2.“회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 “유모멘트”에 대하여 그 변경사항을 알려야 합니다.'},
		{type: 'contentDepth', content: '3.제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “유모멘트”는 책임지지 않습니다.'},

		{type: 'contentTitle', content: '제 7조 (개인정보보호 의무)'},
		{type: 'contentDepth', content: '1.“유모멘트”는 “개인정보보호법” 등 관계 법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 “유모멘트”의 개인정보처리방침이 적용됩니다.'},

		{type: 'contentTitle', content: '제 8조 (“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무)'},
		{type: 'contentDepth', content: '1.“회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.'},
		{type: 'contentDepth', content: '2.“회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “유모멘트”에 통지하고 “유모멘트”의 안내에 따라야 합니다.'},
		{type: 'contentDepth', content: '3.제3항의 경우에 해당 “회원”이 “유모멘트”에 그 사실을 통지하지 않거나, 통지한 경우에도 “유모멘트”의 안내에 따르지 않아 발생한 불이익에 대하여 “유모멘트”는 책임지지 않습니다.'},

		{type: 'contentTitle', content: '제 9조 (“회원”에 대한 통지)'},
		{type: 'contentDepth', content: '1.“유모멘트”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소 등으로 할 수 있습니다.'},
		{type: 'contentDepth', content: '2.“유모멘트”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.'},

		{type: 'contentTitle', content: '제 10조 (“유모멘트”의 의무)'},
		{type: 'contentDepth', content: '1.“유모멘트”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.'},
		{type: 'contentDepth', content: '2.“유모멘트”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.'},
		{type: 'contentDepth', content: '3.“유모멘트” 는 “서비스”이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.'},
		{type: 'contentDepth', content: '“회원”이 제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여 “회원”에게 처리과정 및 결과를 전달합니다.'},

		{type: 'contentTitle', content: '제 11조 (“회원”의 의무)'},
		{type: 'contentDepth', content: `1.“회원”은 다음 행위를 하여서는 안 됩니다. 본조를 위반하는 “회원”의 행위에 대하여 “회사”는 경고, 퇴실, “회원”자격 박탈 등의 조치를 할 수 있습니다.
    - 가입신청 또는 가입정보 변경 시 허위내용의 등록
    - 타인의 정보를 도용하여 “유모멘트”를 이용
    - “유모멘트”가 게시한 정보의 변경
    - “유모멘트”의 이용규칙, 운영정책 위반
    - “유모멘트” 내 시설물, 장서, 가구 등의 훼손, 파손, 용도를 벗어난 이용
    - “유모멘트” 내 시설물 및 예식예약 정보 등에 대한 지적재산권 등 권리 침해
    - “유모멘트” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    - “유모멘트”의 동의없이 영리를 목적으로 정보를 사용하는 행위
    - 기타 “유모멘트”의 이용에 있어 불법적이거나 부당한 행위 및 공공질서, 미풍양속을 어기는 행위
		`},

		{type: 'contentTitle', content: '제 12조 (“서비스”의 제공 등)'},
		{type: 'contentDepth', content: `1.“유모멘트”는 “회원”에게 아래와 같은 “서비스”를 제공합니다.
    - 예식예약 견적 열람 서비스
    - 예식예약 검색 서비스
    - 상담예약 신청 서비스
    - 예식홀 소식 및 FAQ
		`},
		{type: 'contentDepth', content: '2.“유모멘트”의 이용시간, 이용 및 출입가능 연령, 출입방식 및 제공서비스 등은 홈페이지 및 시설물, 이용규칙, 운영정책 등에 안내한 바에 따릅니다. 단, 사전의 온라인 및 오프라인 공지 또는 부득이한 경우 사후 통지에 따라 “서비스” 제공 및 홈페이지 이용이 일시 중단 또는 변경될 수 있습니다.'},
		{type: 'contentDepth', content: '3.“회사”는 무료로 제공하는 “유모멘트”의 운영과 관련, “회사”의 정책에 따라 수정, 변경, 중단할 수 있습니다.'},

		{type: 'contentTitle', content: '제 13조 (정보의 제공 및 광고의 게재)'},
		{type: 'contentDepth', content: '1.“유모멘트”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 온·오프라인 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.'},
		{type: 'contentDepth', content: '2.“이용자(회원, 비회원 포함)”는 “유모멘트”가 제공하는 “서비스”와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.'},

		{type: 'contentTitle', content: '제 14조 (계약 해제, 해지 등)'},
		{type: 'contentDepth', content: '1.“회원”은 언제든지 “유모멘트” 홈페이지를 통해 이용계약 해지 신청을 할 수 있으며, “유모멘트”는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.'},
		{type: 'contentDepth', content: '2.“회원”이 탈퇴 요청을 할 경우 관련법 및 개인정보처리방침에 정한 바에 따라 탈퇴 즉시 “회원”을 식별할 수 있는 개인 데이터는 모두 소멸됩니다.'},
		{type: 'contentDepth', content: '3.단, 예식장 이용 이력은 삭제되지 않습니다.'},

		{type: 'contentTitle', content: '제 15조 (이용 제한)'},
		{type: 'contentDepth', content: '1.“유모멘트”는 “회원”이 이 약관 또는 홈페이지 운영정책 등을 위반한 경우, 경고, 일시정지, 영구이용정지 등으로 “서비스” 이용을 단계적으로 제한할 수 있습니다.'},
		{type: 'contentDepth', content: '2.본 조에 따라 “서비스” 이용을 제한하거나 계약을 해지하는 경우에는 “유모멘트”는 제9조 “회원”에 대한 통지에 따라 통지합니다.'},
		{type: 'contentDepth', content: '3.“회원”은 본 조에 따른 이용제한 등에 대해 “유모멘트”가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 “유모멘트”가 인정하는 경우 “유모멘트”는 즉시 “서비스”의 이용을 재개합니다.'},

		{type: 'contentTitle', content: '제 16조 (준거법 및 재판관할)'},
		{type: 'contentDepth', content: '1.“유모멘트”와 “회원”간 제기된 소송은 대한민국법을 준거법으로 합니다.'},
		{type: 'contentDepth', content: '2.“유모멘트”와 “회원”간 발생한 분쟁에 관한 소송은 민사소송법에 정한 바에 따릅니다.'},
		{type: 'contentDepth', content: '3.해외에 주소나 거소가 있는 “회원”의 경우 “유모멘트”와 “회원”간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할 법원으로 합니다.'},
	]
}

const PRIVACY_TERM: ModalData = {
	id: 'PRIVACY_TERM',
	title: '개인정보처리방침',
	contents: [
		{type: 'contentTitle', content: '개인정보 처리방침'},
		{type: 'content', content: '유모멘트 주식회사(이하 ‘회사’)는 정보주체의 권리보호를 위해 개인정보보호법 및 관련 법령이 정한 바를 준수하며, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 개인정보보호법 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립 공개합니다.'},
		{type: 'contentTitle', content: '1. 처리하는 개인정보 항목'},
		{type: 'content', content: '유모멘트에 회원가입을 하지 않아도 웨딩 베뉴 , 웨딩 본식상품, 연회, 등의 서비스를 회원과 동일하게 이용할 수 있습니다. 예식예약 또는 상담예약 등의 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입이 필요하며, 아래와 같이 필요한 개인정보를 수집합니다.'},

		{type: 'contentDepth', content: `기본 정보: 이름, 생년월일, 성별`},
		{type: 'contentDepth', content: '연락처 정보: 주소, 전화번호, 이메일 주소, 주소'},
		{type: 'contentDepth', content: '결제 정보: 신용카드 정보, 은행 계좌 정보'},
		{type: 'contentDepth', content: '웨딩 이벤트 관련 정보: 예식날짜, 장소, 선호사항'},
		{type: 'contentDepth', content: '그 밖에 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.'},
		{type: 'contentDepth', content: '- 유모멘트 방문 일시 등의 이용기록'},
		{type: 'contentDepth', content: '- 브라우저 종류 및 OS, 방문 기록(IP Address, 접속시간), 쿠키'},

		{type: 'contentTitle', content: '2. 개인정보의 처리목적'},
		{type: 'content', content: '회사는 다음의 목적으로 개인정보를 처리하고 있습니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법에서 정한 바에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 유모멘트는 회원관리, 서비스 향상 및 개선, 부정 이용의 방지 및 제재, 맞춤 서비스 제공을 위한 목적만으로 개인정보를 처리합니다.'},
		{type: 'contentDepth', content: '웨딩 이벤트 및 서비스 예약, 계획 및 관리'},
		{type: 'contentDepth', content: '고객 서비스 제공, 문의 응대 및 의사소통'},
		{type: 'contentDepth', content: '회원 가입의사 확인, 회원 자격 유지관리, 회원 서비스 제공에 따른 본인식별/인증'},
		{type: 'contentDepth', content: '결제 처리 및 청구'},
		{type: 'contentDepth', content: '이벤트 및 프로모션 정보 제공'},
		{type: 'contentDepth', content: '법적 의무 및 규정 준수(각종 고지, 통지, 고충처리 등)마케팅 및 고객 관리'},
		{type: 'contentDepth', content: '기타 관련된 목적 - 이와 관련된 해피콜, 우편, 이메일, SMS 발송, 앱푸쉬메시지'},
		
		{type: 'contentTitle', content: '3. 개인정보의 제 3자 제공'},
		{type: 'content', content: '회사는 개인정보를 “2. 개인정보의 처리목적”에서 고지한 범위내에서 이용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.'},
		{type: 'contentDepth', content: '- 정보주체가 사전에 동의한 경우'},

		{type: 'contentTitle', content: '4. 개인정보 처리의 위탁'},
		{type: 'content', content: '회사는 원활한 업무 처리를 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따른 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.'},
		{type: 'contentDepth', content: `회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
수탁 업체 :
유모멘트(주) 및 그 자회사가 운영하는 웨딩홀(아펠가모, 더채플, 루벨) 및 유모멘트의 계열사
유모멘트(주) 및 그 자회사의 웨딩 협력업체 102개 (사진, 동영상, 패키지, 폐백, 웨딩카, 연주, 주례, 사회, 호텔, 여행, 혼주메이크업 등)
협력업체 리스트 : 링크

위탁업무 내용 :
웨딩 이벤트 및 서비스 예약, 계획 및 관리
고객 서비스 제공, 문의 응대 및 의사소통
결제 처리 및 청구
이벤트 및 프로모션 정보 제공
법적 의무 및 규정 준수
마케팅 및 고객 관리
기타 관련된 목적 - 이와 관련된 해피콜, 우편, 이메일, SMS 발송, 앱푸쉬메시지
		`},
		
		{type: 'contentTitle', content: '5. 개인정보의 처리 및 보유기간'},
		{type: 'content', content: `유모멘트 회원 탈퇴 시 정보주체의 개인정보를 지체없이 파기합니다.
전자상거래 등에서 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한 기간 또는 다음 각 호의 기간 동안 회원정보를 보유할 수 있습니다.
		`},
		{type: 'contentDepth', content: '- 계약 또는 청약철회 등에 관한 기록 : 5년'},
		{type: 'contentDepth', content: '- 대금결제 및 재화 등의 공급에 관한 기록 : 5년'},
		{type: 'contentDepth', content: '- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년'},
		{type: 'contentDepth', content: '- 기타 정당한 절차에 따라 보유기간을 미리 회원에게 고지하거나 명시한 경우 : 고지한 보유 기간까지'},

		{type: 'contentTitle', content: '6. 개인정보 파기절차 및 방법'},
		{type: 'content', content: '개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기 방법은 다음과 같습니다.'},
		{type: 'contentDepth', content: '- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.'},
		{type: 'contentDepth', content: '- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.'},

		{type: 'contentTitle', content: '7. 정보주체의 권리,의무 및 그 행사방법'},
		{type: 'content', content: '정보주체는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 정보삭제 및 처리정지를 요청할 수도 있습니다. 정보삭제 또는 처리정지를 원하시는 경우 개인정보보호 책임자에게 연락하시면 지체 없이 조치하겠습니다.'},

		{type: 'contentTitle', content: '8. 개인정보의 안전성 확보조치'},
		{type: 'content', content: '회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 보호조치를 강구하고 있습니다.'},
		{type: 'contentDepth', content: `가. 비밀번호 암호화
홈페이지를 이용하면서 설정한 비밀번호는 암호화되어 저장 및 관리되고 있어 정보주체 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

나. 해킹 등에 대비한 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의해 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 정보주체의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.

다. 개인정보 처리 직원의 최소화 및 교육
회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 수행하며, 개인정보보호를 강조하고 있습니다.
		`},
		
		{type: 'contentTitle', content: '9. 개인정보보호 책임자 및 연락처'},
		{type: 'content', content: `개인정보보호 관련 민원을 개인정보보호 책임자에게 신고하실 수 있습니다. 회사는 정보주체의 신고사항에 대해 신속하게 충분한 답변을 드릴 것 입니다.
스마트예약센터 02-512-7011

기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래기관에 문의하시기 바랍니다.

개인정보침해신고센터 ( http://privacy.kisa.or.kr / 국번없이 118 )
대검찰청 사이버수사과 ( http://www.spo.go.kr / 국번없이 1301 )
경찰청 사이버안전국 ( http://cyberbureau.police.go.kr / 국번없이 182 )
		`},
		
		{type: 'contentTitle', content: '10. 기타'},
		{type: 'content', content: `본 홈페이지에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 “유모멘트 개인정보처리방침”이 적용되지 않음을 알려 드립니다.

- 공고 일자 : 2023년 11월 28일
- 시행 일자 : 2023년 12월 5일
		`},
	]
}

const MARKETING_TERM: ModalData = {
	id: 'MARKETING_TERM',
	title: '마케팅 활용 동의',
	contents: [
		{type: 'content', content: '[유모멘트] (이하 "회사"로 표기함)는 귀하의 개인정보를 존중하며, 웨딩 관련 마케팅 정보를 제공받을 것인지 여부에 대한 동의를 확인합니다.'},
		{type: 'contentTitle', content: '1. 마케팅 정보 수신 동의'},
		{type: 'content', content: '회사는 웨딩 관련 마케팅 정보, 프로모션 정보, 이벤트 정보, 할인 정보 및 업데이트를 귀하에게 제공하려고 합니다. 귀하는 아래의 동의사항에 동의하거나 거부할 수 있습니다.'},
		{type: 'contentTitle', content: '2. 수신 방법'},
		{type: 'content', content: `회사는 귀하에게 마케팅 정보를 다음과 같은 방법으로 전달할 수 있습니다.
  1. 앱푸쉬
  2. 이메일
  3. 문자 메시지
  4. 전화 통화
  5. 소셜 미디어 메시지
  6. 우편
		`},
		{type: 'contentTitle', content: '3. 동의 철회'},
		{type: 'content', content: `귀하가 언제든지 마케팅 정보 수신에 대한 동의를 철회할 수 있습니다. 동의 철회는 다음과 같은 방법으로 가능합니다:
  이메일을 통한 연락
  웹사이트에서 설정 변경
  전화로 회사에 직접 연락
		`},
		{type: 'contentTitle', content: '4. 개인정보 보호'},
		{type: 'content', content: `회사는 귀하의 개인정보를 안전하게 보호합니다. 개인정보는 귀하의 동의 없이 제 3자에게 제공되지 않으며, 관련 법률 및 규정을 준수합니다.`},
	]
}
const REG_ID = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}$/;
const REG_PW = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&~^])[A-Za-z\d$@$!%*#?&~^]{8,}$/;
const GENDER = [{id: 1, key: 'BRIDE'}, {id: 2, key: 'GROOM'}]
const Signup = () => {
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const [formData, setFormData] = useState<any>({
		role: '',
		name: '',
		mateName: '',
		mateTel: '',
		email: '',
		phone: '',
		id: '',
		password: '',
		checkPassword: ''
	});
	const [formErrorText, setFormErrorText] = useState({
		id: '',
		password: '',
		checkPassword: ''
	})
	const [selAllTerm, setSelAllTerm] = useState(false);
	const [terms, setTerms] = useState({
		service: false,
		privacy: false,
		marketing: false
	});
	const [currentModalData, setCurrentModalData] = useState<ModalData|null>(null);
	const [duplicateAlertState, setDuplicateAlertState] = useState(false);
	const [duplicateDone, setDuplicateDone] = useState(false);
	const [errorAlertState, setErrorAlertState] = useState(false);
	const [errorAlertText, setErrorAlertText] = useState('');

	const [successAlertState, setSuccessState] = useState(false);
	
	useEffect(() => {
		logEvent('umoment_page_behavior', {
			page: 'umoment_signup',
			behavior: 'page_view'
		});
	}, []);
	useEffect(() => {
		if (user.me) {
			const gender = GENDER.filter(gen => gen.id === user.me.sex);
			const role = gender.length ? gender[0].key : 'BRIDE';
			setFormData({...formData, name: user.me.customerName, mateName: user.me.mateName, mateTel: user.me.mateTel, role: role});
		}
	}, [user]);
	useEffect(() => {
		if (selAllTerm) {
			setTerms({service: true, privacy: true, marketing: true});
		}
	}, [selAllTerm]);
	useEffect(() => {
		if (!terms.service || !terms.privacy || !terms.marketing) setSelAllTerm(false);
		if (terms.service && terms.privacy && terms.marketing) setSelAllTerm(true);
	}, [terms]);

	const isDuplicateUserName = async (id: string) => {
		if (!REG_ID.test(id)) {
			setFormErrorText({...formErrorText, id: '영문, 숫자 조합 8~16자로 입력해주세요.'});
			return;
		}
		const {data} = await axios.get<{item: any}>(`${BASE_URL}/web/v3/specialHall/20231115/user/duplicate?username=${id}`);
		if (data.item.success) {
			setDuplicateAlertState(true);
			setDuplicateDone(true);
			setFormErrorText({...formErrorText, id: ''});
		} else {
			setFormErrorText({...formErrorText, id: '사용 불가능한 아이디입니다.'});
		}
	}
	const signup = async () => {
		const showAlert = (msg: string) => {
			setErrorAlertText(msg);
			setErrorAlertState(true);
		}
		if (!user.me) {
			showAlert('앱 사용자 정보를 찾을 수 없습니다.');
			return;
		}
		if (!duplicateDone) {
			showAlert('아이디 중복확인을 해주세요.');
			return;
		}
		if (formErrorText.id) {
			showAlert(formErrorText.id);
			return;
		}
		if (formErrorText.password) {
			showAlert(formErrorText.password);
			return;
		}
		if (formErrorText.checkPassword) {
			showAlert(formErrorText.checkPassword);
			return;
		}
		if (formData.mateName === '') {
			showAlert('배우자 이름을 입력해주세요.');
			return;
		}
		if (formData.mateTel === '') {
			showAlert('배우자 번호를 입력해주세요.');
			return;
		}
		if (!terms.service) {
			showAlert('이용약관에 동의해주세요.');
			return;
		}
		if (!terms.privacy) {
			showAlert('개인정보 처리 방침에 동의해주세요.');
			return;
		}
		
		await axios.post<{item: any}>(`${BASE_URL}/web/v3/specialHall/20231115/user`, QueryString.stringify({
			id: formData.id,
			sex: GENDER.filter(gen => gen.key === formData.role)[0].id,
			username: user.me.customerName.trim(),
			pwd: formData.password,
			mateName: formData.mateName,
			mateTel: formData.mateTel.replace(/-/gi, ''),
			agree1: terms.service ? 'Y' : 'N',
			agree2: terms.privacy ? 'Y' : 'N',
			mktAgree: terms.marketing ? 'Y' : 'N'
		})).then(_ => {
			setSuccessState(true);
			logEvent('umoment_signup', {
				loginId: formData.id 
			});
		});
	}
	return (
		<>
		<AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} />
		<div className="umoment-signup-page">
			<div className="signup-title-wrap">
				<h2>스마트 예약 가입하기</h2>
				<p>더채플, 아펠가모, 루벨 통합 예약 사이트 입니다.</p>
			</div>
			<div className="signup-form-wrap form-wrap">
				<div className="form-block">
					<div className="form-title">
						<span className="title">본인선택</span>
					</div>
					<div className="form-content">
						<ul className="my-role-list-wrap">
							<li className={formData.role === 'BRIDE' ? 'on' : ''} onClick={() => setFormData({...formData, role: 'BRIDE'})}>
								신부
							</li>
							<li className={formData.role === 'GROOM' ? 'on' : ''} onClick={() => setFormData({...formData, role: 'GROOM'})}>
								신랑
							</li>
						</ul>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-full input-has-line"
							placeholder="이름"
							onChange={e => setFormData({...formData, name: e.target.value})}
							value={formData.name}
							readOnly
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">아이디</span>
					</div>
					<div className="form-content">
						<div className="input-id-wrap">
							<input 
								type="text"
								className="input-text input-full input-has-line"
								placeholder="아이디"
								maxLength={16}
								onFocus={e => {
									if (e.target.value === '') {
										setFormErrorText({...formErrorText, id: '영문, 숫자 조합 8~16자로 입력해주세요.'});
									}
								}}
								onChange={e => {
									setFormData({...formData, id: e.target.value});
									setDuplicateDone(false);
									if (!REG_ID.test(e.target.value)) {
										setFormErrorText({...formErrorText, id: '영문, 숫자 조합 8~16자로 입력해주세요.'});
									} else {
										setFormErrorText({...formErrorText, id: ''});
									}
								}}
								value={formData.id}
							/>
							{formErrorText.id &&
							<p className="noti-error-text">{formErrorText.id}</p>
							}
							<button className={`btn btn-light-brown btn-check-id ${duplicateDone ? 'btn-disabled' : ''}`} disabled={duplicateDone} onClick={() => isDuplicateUserName(formData.id)}>중복{!duplicateDone ? '확인' : '체크'}</button>
						</div>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">비밀번호</span>
					</div>
					<div className="form-content">
						<input 
							type="password"
							className="input-text input-full input-has-line"
							placeholder="비밀번호" 
							defaultValue={''}
							maxLength={20}
							onFocus={e => {
								if (e.target.value === '') {
									setFormErrorText({...formErrorText, password: '영문, 숫자, 특수문자 조합 8자리 ~ 20자리 이내로 입력해주세요.'});
								}
							}}
							onChange={e => {
								setFormData({...formData, password: e.target.value});
								if (!REG_PW.test(e.target.value) || formData.password.length > 20) {
									setFormErrorText({...formErrorText, password: '영문, 숫자, 특수문자 조합 8자리 ~ 20자리 이내로 입력해주세요.'});
								} else {
									setFormErrorText({...formErrorText, password: ''});
								}
							}}
							onBlur={() => {
								if (!REG_PW.test(formData.password) || formData.password.length > 20) {
									setFormErrorText({...formErrorText, password: '영문, 숫자, 특수문자 조합 8자리 ~ 20자리 이내로 입력해주세요.'});
								} else {
									setFormErrorText({...formErrorText, password: ''});
								}
							}}
						/>
						{formErrorText.password &&
						<p className="noti-error-text">{formErrorText.password}</p>
						}
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">비밀번호 확인</span>
					</div>
					<div className="form-content">
						<input 
							type="password"
							className="input-text input-full input-has-line"
							placeholder="비밀번호 확인" 
							defaultValue={''}
							maxLength={20}
							onChange={e => setFormData({...formData, checkPassword: e.target.value})}
							onBlur={() => {
								if (formData.password !== formData.checkPassword && formData.checkPassword.length > 0) {
									setFormErrorText({...formErrorText, checkPassword: '비밀번호가 다릅니다.'});
								} else {
									setFormErrorText({...formErrorText, checkPassword: ''});
								}
							}}
						/>
						{formErrorText.checkPassword &&
						<p className="noti-error-text">{formErrorText.checkPassword}</p>
						}
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">배우자 이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-full input-has-line"
							placeholder="배우자 이름"
							onChange={e => setFormData({...formData, mateName: e.target.value})}
							value={formData.mateName}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">배우자 전화번호</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-full input-has-line"
							placeholder="배우자 전화번호"
							onChange={e => setFormData({...formData, mateTel: autoHypenPhone(e.target.value)})}
							value={formData.mateTel}
						/>
					</div>
				</div>
			</div>
			<div style={{height: 6, backgroundColor: '#f7f7f7'}}></div>
			<div className="terms-wrap">
				<div className="term-all-wrap">
					<Checkbox 
						id="allAgreement"
						name="allAgreement"
						value="allAgreement"
						setValue={() => {
							setSelAllTerm(!selAllTerm)
							if (!selAllTerm === false) {
								setTerms({service: false, privacy: false, marketing: false});
							}
						}}
						checked={selAllTerm}
					/>
					<label htmlFor="allAgreement">다음 약관에 모두 동의합니다.</label>
				</div>
				<ul className="term-list-wrap">
					<li>
						<Checkbox 
							id="serviceTerm"
							size="small"
							name="serviceTerm"
							value="serviceTerm"
							setValue={() => setTerms({...terms, service: !terms.service})}
							checked={terms.service}
						/>
						<label htmlFor="serviceTerm">이용약관</label>
						<i className="icon icon-chevron-right-18-gray" onClick={() => setCurrentModalData(SERVICE_TERM)}></i>
					</li>
					<li>
						<Checkbox 
							id="privacyTerm"
							size="small"
							name="privacyTerm"
							value="privacyTerm"
							setValue={() => setTerms({...terms, privacy: !terms.privacy})}
							checked={terms.privacy}
						/>
						<label htmlFor="privacyTerm">개인정보처리방침</label>
						<i className="icon icon-chevron-right-18-gray" onClick={() => setCurrentModalData(PRIVACY_TERM)}></i>
					</li>
					<li>
						<Checkbox 
							id="marketingTerm"
							size="small"
							name="marketingTerm"
							value="marketingTerm"
							setValue={() => setTerms({...terms, marketing: !terms.marketing})}
							checked={terms.marketing}
						/>
						<label htmlFor="marketingTerm">마케팅 수신동의 <span>(선택)</span></label>
						<i className="icon icon-chevron-right-18-gray" onClick={() => setCurrentModalData(MARKETING_TERM)}></i>
					</li>
				</ul>
			</div>
			<div className="signup-btn-wrap">
				<button className="btn btn-large btn-full btn-brown" onClick={signup}>유모멘트 가입하기</button>
			</div>
			{currentModalData &&
			<div className="term-modal-wrap">
				<div className="overlay"></div>
				<div className="term-modal-inner">
					<div className="term-content">
						<h2 className="term-title">{currentModalData.title}</h2>
						{currentModalData.contents.map((term, idx) => {
							if (term.type === 'groupTitle') {
								return <h3 key={`${currentModalData.id}-${idx}`}>{term.content}</h3>;
							}
							if (term.type === 'contentTitle') {
								return <h4 key={`${currentModalData.id}-${idx}`}>{term.content}</h4>;
							}
							if (term.type === 'content') {
								return <p key={`${currentModalData.id}-${idx}`}>{term.content}</p>;
							}
							if (term.type === 'contentDepth') {
								return <p key={`${currentModalData.id}-${idx}`} style={{paddingLeft: 16}}>{term.content}</p>;
							}
							// return '';
						})}
					</div>
					<div className="term-modal-botton">
						<button className="btn btn-auto-height btn-tight btn-transparent btn-term-modal" onClick={() => setCurrentModalData(null)}>확인</button>
					</div>
				</div>
			</div>
			}
			<Modal
				theme="brown"
				isShow={duplicateAlertState}
				text={'사용가능한 아이디입니다.'}
				onClose={() => {
					setDuplicateAlertState(false);
				}}
			/>
			<Modal
				theme="brown"
				isShow={errorAlertState}
				text={errorAlertText}
				onClose={() => {
					setErrorAlertState(false);
				}}
			/>
			<Modal
				theme="brown"
				isShow={successAlertState}
				text={'가입이 완료됐습니다.'}
				onClose={() => {
					setSuccessState(false);
					navigate('/umoment/landing/special-hall', {replace: true});
				}}
			/>
		</div>
		</>
	);
};
export default Signup;