import './partnerInquiry.scss';
import { useEffect, useState } from 'react';
import { IPartner, getPartnerInquiry, IInquiry } from '../../api/partner/partner';
import PartnerInquiryModal from './PartnerInquiryModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { isExternalUrl } from '@src/utils';
import ImgNoInquiry from '../../assets/images/partner/img-inquiry-disable@3x.png';

const CHAT_TYPES = [2, 3, 4 ,5, 12];
const PartnerInquiry = ({partner}: {partner: IPartner}) => {
	// 2, 3, 4, 5, 12 웨딩홀/스드메/스냅 일때만 컨시어지로 문의하기로 처리
	const [modalState, setModalState] = useState(false);
	// const param = useParams();
	const {hash, pathname} = useLocation();
	const [list, setList] = useState<IInquiry[]>([]);
	const navigate = useNavigate();
	const linkChat = () => {
		window.location.href = 'h2mwbell://chat';
	}
	const openInquiry = () => {
		navigate(pathname + '#inquiryModal');
	}
	useEffect(() => {
		if(!CHAT_TYPES.includes(partner.type)) {
			getPartnerInquiry(partner.uuid).then(res => {
				setList(res.data.item);
			});
		}
	}, []);
	useEffect(() => {
		setModalState(hash.indexOf('inquiryModal') !== -1);
	}, [hash]);
	return (
		<>
		{partner.isInquire > 0 
		? <div className="partner-inquiry-contents-wrap">
			{CHAT_TYPES.includes(partner.type)
			? <div className="partner-inquiry-wrap">
				<p>웨딩북 1:1 문의로 궁금한 점을 남겨주세요.</p>
				<button className="btn btn-line-brown btn-full" onClick={linkChat}>채팅하기</button>
			</div>
			: <>
			<div className="partner-inquiry-wrap">
				<p>예약 전에 궁금한 점을 문의하세요.</p>
				<button className="btn btn-line-brown btn-full" onClick={openInquiry}>문의하기</button>
			</div>
			<ul className="inquiry-list-wrap">
				{list.map(inquiry => (
				<li className={`depth${inquiry.depth}`}>
					<div className="user-info-wrap">
						<span className="nick">{inquiry.customerNick}</span>
						<span className="inquiry-reg-tsp">{inquiry.regTsp.substr(0, 10)}</span>
					</div>
					<p className="inquiry-content">{inquiry.content}</p>
				</li>
				))}
			</ul>
			{partner !== null &&
			<div style={{display: modalState ? 'block' : 'none'}}>
				<PartnerInquiryModal partnerUuid={partner.uuid} closeModal={() => navigate(-1)} />
			</div>
			}
			</>
			}
		</div>
		: <div className="partner-inquiry-contents-wrap no-inquiry">
			{/* <img src={ImgNoInquiry} alt="WeddingBook" width={39} /> */}
			<p className="no-inquiry-notice">
				실시간 문의 응대가 불가한 업체입니다.<br/>
				전화 문의 부탁드립니다.
			</p>
			<p className="link-biz-content">사업자인 경우 <a href={isExternalUrl('https://biz.wdgbook.com')}>웨딩북 비즈</a>에 가입해주세요.</p>
		</div>		
		}
		</>
	);
};

export default PartnerInquiry;