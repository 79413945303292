import { useState, useEffect } from 'react';
import { BASE_URL } from '../utils/index';
import axios from 'axios';
import PageTab, {ITab, PageTabprops} from '../components/home/pageTab/PageTab';
import { CompanyItem } from '../components/home/companyOrder/CompanyOrder';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import AppHeader from '../components/common/appHeader/AppHeader';
import { EmptyList } from '../components/home/myCompany/MyCompany';
import { logEvent } from '@src/firebase';

interface IPartnerType {
	cnt: number|null;
	content: string;
	imageUrl: string;
	isEvent: number;
	isRegion: number;
	isStreet: number;
	link: string;
	mandatory: number;
	name: string;
	type: number;
}

interface ITypeTab extends PageTabprops {
	dataType: 'like'|'recent';
}
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const TypeTab = ({dataType, tab, currentTabId, onChange}: ITypeTab) => {
	const [flag, setFlag] = useState(0);
	const forceUpdate = useForceUpdate();
	useEffect(() => {
		forceUpdate();
		setFlag(flag => flag + 1);
	}, [dataType])
	
	return (
		<PageTab
			tab={tab}
			currentTabId={currentTabId}
			onChange={tabId => {
				onChange(tabId);
			}}
		/>
	)
}
const MyInterest = () => {
	const navigate = useNavigate();
	const {dataType} = useParams<{dataType: 'like'|'recent'}>();
	const location = useLocation();
	const query = QueryString.parse(location.search, {ignoreQueryPrefix: true});
	const [tab, setTab] = useState<ITab[]>([]);
	const [currentTabId, setCurrentTabId] = useState<number|null>(null);
	const [partners, setPartners] = useState<any[]|null>(null);
	const queryToString = '?' + QueryString.stringify(query);
	useEffect(() => {
		logEvent('page_behavior', {
			page: 'my_interest',
			behavior: 'page_view'
		});
		axios.get<{item: IPartnerType[]}>(`${BASE_URL}/v1/partner/type?type=1`).then(res => {
			const tabItems = res.data.item.map(item => ({
				tabId: item.type,
				tabTitle: item.name
			}));
			setTab(tabItems);
			if (tabItems.length > 0) {
				const id = query.tabId ? +query.tabId : tabItems[0].tabId;
				if (tabItems.filter(item => item.tabId === id).length) {
					setCurrentTabId(id);
				} else {
					setCurrentTabId(tabItems[0].tabId);
				}
			}
		});
	}, []);
	useEffect(() => {
		if (currentTabId === null) return;
		if (dataType === 'like') {
			axios.get<{item: any[]}>(`${BASE_URL}/v3/component/200205/partner/my/like?fetchStart=0&fetchSize=999&type=${currentTabId}`).then(res => {
				setPartners(res.data.item);
			});
		}
		if (dataType === 'recent') {
			axios.get<{item: any[]}>(`${BASE_URL}/v3/component/200205/partner/my/recent?fetchStart=0&fetchSize=999&type=${currentTabId}`).then(res => {
				setPartners(res.data.item);
			});
		}
	}, [currentTabId, dataType]);
	return (
		<>
		<AppHeader
			type="stack"
			title=""
		/>
		<div className="page-like">
			<ul className="interest-tab-wrap">
				<li className={dataType === 'like' ? 'on' : ''} onClick={() => navigate('/my-interest/like' + queryToString, {replace: true})}>
					관심있는
				</li>
				<li className={dataType === 'recent' ? 'on' : ''} onClick={() => navigate('/my-interest/recent' + queryToString, {replace: true})}>
					최근 본
				</li>
			</ul>
			{(currentTabId !== null && dataType) &&
			<PageTab
				tab={tab}
				currentTabId={currentTabId}
				onChange={tabId => {
					if (currentTabId !== tabId) {
						setPartners(null);
						setCurrentTabId(tabId)
					}
				}}
				key={`page-tab-${dataType}`}
			/>
			}
			{partners !== null &&
			<>
			{partners.length > 0
			? <>
			<div style={{height: 8}}></div>
			<ul className="company-list-wrap">
				{partners.map((partner, idx) => (
				<CompanyItem 
					page="관심있는/최근 본"
					partner={partner}
					parentComponent="my_interest"
					key={`my-company-list-${partner.uuid}-${idx}`}
				/>
				))}
			</ul>
			</>
			: <>
			<EmptyList type={dataType} />
			<button 
				className="btn btn-large btn-white btn-round btn-with-icon btn-find" 
				onClick={() => window.location.href = `h2mwbell://partner?categoryType=${currentTabId}`}
			>
				{tab.filter(item => item.tabId === currentTabId).length > 0 &&
				<span>
					<span className="bold">{tab.filter(item => item.tabId === currentTabId)[0].tabTitle}</span> 찾기
				</span>
				}
				<i className="icon icon-next-arrow-circle-24" />
			</button>
			</>
			}
			</>
			}
		</div>
		</>
	);
};

export default MyInterest;