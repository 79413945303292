import { useState, useEffect } from 'react';
import SearchHeader from './../../components/search/SearchHeader';
import { ISearchData, getRecommendationKeyword, getSearchResult } from '@src/api/search/search';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { HOSTNAME, isExternalUrl } from '@src/utils';
import { Link } from 'react-router-dom';

const Result = () => {
	const query = useUrlQueryString();
	const productId = query.get('productId');
	const searchKeyword = query.get('searchKeyword');
	const [searchData, setSearchData] = useState<ISearchData[]>([]);
	const [recommendationKeywords, setRecommendationKeywords] = useState<string[]>([]);
	const [initialLoaded, setInitialLoaded] = useState(false);
	useEffect(() => {
		if (!searchKeyword) return;
		getSearchResult(searchKeyword).then(({data}) => {
			setSearchData(data.item.items);
			setInitialLoaded(true);
		});
	}, [searchKeyword]);
	useEffect(() => {
		getRecommendationKeyword(productId).then(res => {
			setRecommendationKeywords(res.data.item);
		});
	}, []);
	return (
		<div className="search-result-page">
			<div className="search-top-wrap">
				<SearchHeader />
			</div>
			{initialLoaded &&
			<>
			{searchData.length > 0
			? <div>
				<ul className="search-result-list-wrap">
					{searchData.map(data => {
						const address = data.address ? data.address.split(' ') : [];
						return (
							<li 
								onClick={() => {
									window.location.href = `h2mwbell://partner/${data.uuid}`;
								}}
								role="link"
								key={`search-result-${data.uuid}`}
							>
								<div className={`partner-bg ${!data.coverUrl ? 'no-image' : ''}`} style={{backgroundImage: `url(${data.coverUrl})`}}></div>
								<div className="partner-info-wrap">
									<span className="partner-address">{address.length >= 1 && address[0]} {address.length >= 2 && address[1]}</span>
									<span className="partner-name">{data.name}</span>
									<ul className="partner-tag-list-wrap">
										{data.benefits?.map((benefit, idx) => (
											<li key={`benefit-${data.uuid}-${idx}`}>{benefit.badge}</li>
										))}
									</ul>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
			: <div className="search-result-empty-wrap">
				<div>
					<p>
						<i>😢</i><br/>
						<span>‘{searchKeyword}’</span> 검색 결과가 없어요.<br/>
						다른 검색어로 검색해 보세요.
					</p>
					<button className="btn" onClick={() => window.location.href = isExternalUrl(`https://prd-wbapp-webview.h2m.io/product/79?appHeader=hide`)}>업체 추천 받아보기</button>
				</div>
				<div>
				<section className="search-section has-safe-area">
					<div className="search-section-title-wrap">
						<h3 className="search-section-title">추천 검색어</h3>
					</div>
					{recommendationKeywords.length > 0 &&
					<ul className="recommendation-search-list-wrap search-tag-list-wrap">
						{recommendationKeywords.map((keyword, kIdx) => (
						<li key={`recommendation-keyword-${kIdx}`}>
							<Link to={`/search/result?searchKeyword=${keyword}`}>
								{keyword}
							</Link>
						</li>
						))}
					</ul>
					}
				</section>
				</div>
			</div>
			}
			</>
			}
		</div>
	);
};

export default Result;