import React, { useContext, useEffect, useState } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Blank from '@src/components/common/blank/Blank';
import AccordionList from '@src/components/common/accordionList/AccordionList';
import BottomFixedModal from '@src/components/common/bottomFixedModal/BottomFixedModal';
import Modal from '@src/components/common/modal/Modal';
import GrayBox from './../../../../components/common/grayBox/GrayBox';
import markerImg from '@assets/images/icon/map/ic-map-marker@3x.png';
import icKakaoMap from '@assets/images/icon/map/ic-kakaomap@3x.png';
import icNaverMap from '@assets/images/icon/map/ic-navermap@3x.png';
import icTmap from '@assets/images/icon/map/ic-tmap@3x.png';
import { addComma, isExternalUrl } from '@src/utils';
import { UserContext } from '@src/App';
import { cancelReservation, getMyReservationDetail } from '@src/api/realtimeBooking/realtimeBooking';
import dayjs from 'dayjs';
import axios from 'axios'
import { setDate, setTime } from '@src/@slice/realtimeBookingSlice';
import { useDispatch } from 'react-redux';
import { KAKAO_LOGIN_APP_KEY } from '@src/utils/index';

interface IReservationInfoTable {
	style?: React.CSSProperties;
	size?: 'small' | 'regular';
	title?: string;
	infos: {
		title: string;
		value: string | JSX.Element;
		onClick?: () => void;
	}[];
	align?: 'left' | 'right';
	infoTitleWidth?: number;
}

const ReservationInfoTable = ({style = {}, size = 'regular', title, infos, align = 'right', infoTitleWidth = 92}: IReservationInfoTable) => {
    console.log(infos)
	return (
		<div className={`info-table-wrap ${size}`} style={style}>
			{title && 
			<b className="info-table-title">{title}</b>
			}
			<div className="info-table">
				{infos.map((info, idx) => (
				<div className="info-row" key={`info-table-${title}-${idx}`}>
					<span className="info-title">{info.title}</span>
					<span 
						className="info-value"
						style={{
							width: align === 'left' ? `calc(100% - ${infoTitleWidth}px)` : 'auto',
							textDecoration: info.onClick ? 'underline' : 'none'
						}}
						onClick={() => {
							info.onClick && info.onClick();
						}}
					>{info.value}</span>
				</div>
				))}
			</div>
		</div>
	)
}




const ManagementDetail = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
	const params = useParams();
    const orderId = params.id;
    const user = useContext(UserContext)
    
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>({}) 
	const [openedIds, setOpenedIds] = useState<string[]>(['4', '5']);
	const [modalState, setModalStgate] = useState({
		map: false,
		cancel: false,
        confirm: false
	})
    const [cancelComplete, setCancelComplete] = useState(false)

    // const [cancelDisabled, setCancelDisabled] = useState(false)
    const [location, setLocation] = useState({name:'', x: null,y: null})

	useEffect(() => {
        if(!loading){
            const kakao = (window as any).kakao;
            const container = document.getElementById('hallMap'); //지도를 담을 영역의 DOM 레퍼런스

            const hallName = data.hallName
            axios.get<any>(`https://dapi.kakao.com/v2/local/search/keyword.json?query=${hallName}`, {
            	headers: {
            		Authorization: `KakaoAK ${KAKAO_LOGIN_APP_KEY}`
            	}
            })
            .then(res => {
                const mapData = res.data.documents?.[0]
               
                if(mapData){
                    setLocation({name:hallName, x: mapData.x, y: mapData.y})
                    
                    let options = { //지도를 생성할 때 필요한 기본 옵션
                        center: new kakao.maps.LatLng(parseFloat(mapData.y),parseFloat(mapData.x)), //지도의 중심좌표.
                        level: 3 //지도의 레벨(확대, 축소 정도)
                    };
            
                    const map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
            
                    const imageSrc = markerImg, // 마커이미지의 주소입니다    
                        imageSize = new kakao.maps.Size(29, 43.45); // 마커이미지의 크기입니다
                        // imageOption = {offset: new kakao.maps.Point(0, 0)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                        
                    // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
                    const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize),
                        markerPosition = new kakao.maps.LatLng(parseFloat(mapData.y),parseFloat(mapData.x)); // 마커가 표시될 위치입니다
            
                    // 마커를 생성합니다
                    const marker = new kakao.maps.Marker({
                        position: markerPosition, 
                        image: markerImage // 마커이미지 설정 
                    });
                    marker.setMap(map);
                }
            });
        }
	}, [loading, data]);


    useEffect(()=> {
        if(user.me){
            const params = {
                apiKey:  'y1FVFlq3Bf0tY%2FpaZRr7%2Bw%2BWklZNIILWt1UB51w%2Bnx0%3D',
                uuid : user.me.uuid
            }
            getMyReservationDetail(orderId, params)
            .then(res => {
                setLoading(false)
                if(res.data.data !== null){
                    setData(res.data.data)                    
                }else { 
                    setData({})
                }
              
            })
        }
   
    },[user.me])


    const makeHopeWeddingSchedule = () => {
        return data.hopeWeddingYearMonths.map((date: string, idx: number) => {
            const yearMonth = dayjs(date).format('YYYY년 M월');
            const dayOfWeek = data.hopeWeddingDaysOfTheWeek[idx];
            const time = data.hopeWeddingTimes[idx];
            return {
                title: `${idx + 1}순위`,
                value: 
                    <>
                        {yearMonth}
                        {dayOfWeek && <><span className="stick"> | </span>{dayOfWeek}</>}
                        {time && <><span className="stick"> | </span>{time}</>}
                    </>
            };
        });
    };

    const makeCustomerInfo = () => {
        const dataArray = [
            { title: '신부', value: <>{data.brideName} <span className="stick"> | </span> {data.brideTel}</> },
            { title: '신랑', value: <>{data.groomName} <span className="stick"> | </span> {data.groomTel}</> },
          ];
          
          if (data.plannerName) {
            dataArray.push({ title: '플래너', value: <>{data.plannerName} <span className="stick"> | </span> {data.plannerTel}</> });
          }

          return dataArray
    }


    const clickChangeReservationBtn  = (e, partnerId, date, time, id) => {
        e.preventDefault();
        dispatch(setDate({date:date}))
        dispatch(setTime({time:time}))
        navigate(`/booking/real-time/${partnerId}/edit/visit-date`, {state: {edit: 'visit-date',type: 'COMPLETE_RESERVATION', id: id}})
    }

    const confirmCancelReservation = (id) =>{
        cancelReservation(id, user.me.uuid)
        .then(() => {
            setCancelComplete(true)
            setModalStgate({...modalState, cancel: false, confirm:true})
        })
        .catch(err => {
            alert(`${err.response.data?.code} : ${err.response.data?.msg}`)
            setModalStgate({...modalState, cancel: false,})
        })
    }

    const isWithin5Day = (visitDate) => {
        const today = dayjs();
        const visit = dayjs(visitDate);
        return !(visit.diff(today, 'day') >= 4);
    }


    const isAndroid = () => {
        const userAgent = navigator.userAgent 
        console.log(/android|Android/i.test(userAgent))
        return /android|Android/i.test(userAgent);
    }

    const goToPartnerDetail = (uuid = 'f18ce2b5-bab5-11eb-9bb0-0ab3aefe6e38') => {
        if(!!uuid){
            window.location.href = `h2mwbell://partner/${uuid}`
        }
    }

	return (
		<div>
			<AppHeader type="stack" actionItems={[{name:'close'}]}/>
            {!loading &&
			<div className="page-booking-real-time-detail">
                <>
				<section className="booking-summary-wrap">
					<h2 className="only-sr">예약 정보 요약</h2>
					<div className="company-name-wrap" onClick={()=>goToPartnerDetail(data.partnerUuid)}>
						<b>{data.hallName} </b> <span className="icon icon-chevron-right-18-gray900"></span>
					</div>
					<p>방문예정일   {data.visitDate.replaceAll('-', '.')}({dayjs(data.visitDate).format('ddd')}) {data.visitTime.slice(0, -3)}</p>
					<textarea 
						className="textarea textarea-has-line" 
						placeholder={data.reservationNotice} 
						readOnly={true}
					/>
					<div className="btn-wrap fill-full items-2">
						<div>
							<button className="btn btn-line-gray700 btn-full" onClick={() => window.location.href = `tel://${data.hallTel}`}>웨딩홀 전화</button>
						</div>
						<div>
							<button className="btn btn-line-gray700 btn-full" onClick={() => setModalStgate({...modalState, map: true})}>위치</button>
						</div>
					</div>
				</section>
				<Blank height={12} color="#f2f2f2" />
				<section className="booking-info-list-wrap">
					<AccordionList 
						openedIds={openedIds}
						setOpenedIds={ids => setOpenedIds(ids)}
						list={[
							{
								id: '1',
								title: '예약내역', 
								content: <>
								<ReservationInfoTable 
									title="예상하객수"
									infos={[
										{title: '예상하객수', value: data.expectedMinPersonnel >= 300 ? '300명 이상': `${data.expectedMinPersonnel}명 ~ ${data.expectedMaxPersonnel}명`}
									]}
								/>
								<ReservationInfoTable 
									title="희망예식일정"
									infos={makeHopeWeddingSchedule()}
								/>
								<ReservationInfoTable 
									title="예약자 정보"
									infos={makeCustomerInfo()}
								/>
								</>
							},
							{
								id: '2',
								title: '결제내역',
								content: <ReservationInfoTable 
									infos={[
										{title: '결제금액', value: `${addComma(data.paymentAmount)}원`},
										{title: '결제수단', value:  `${data.paymentCardName || '-'}`},
										{title: '결제일시', value: `${dayjs(data.paymentTsp).format('YYYY.MM.DD(ddd) HH:mm')}`},
									]}
								/>
							},
							{
								id: '3',
								title: '이용상태',
								content: <>
								<ReservationInfoTable 
									infos={[
										{title: '방문상태', value: `${data.visitStateAlias}`},
										{title: '날짜변경', value: `${data.changeableVisit ? '1회 가능':'불가'}`}
									]}
								/>
                                <GrayBox 
                                style={{marginTop: 12}}
                                title={data.refundable ? '환불가능' : '환불불가'}
                                text={data.refundable ? `예정 방문일로부터 5일전인
                                 	${dayjs(data.visitDate).subtract(5,'day').format('YYYY.MM.DD(ddd)')}까지 환불 가능` : '예정 방문일로부터 5일 이내는 환불이 불가능합니다.'}
                                />
                                <GrayBox 
								style={{marginTop: 10}}
									title={data.changeableVisit ? '날짜변경 1회 가능' : '날짜변경 불가'}
									text={data.changeableVisit ? data.dateChangedTsp === null ? `예정 방문일로부터 5일전인
										${dayjs(data.visitDate).subtract(5,'day').format('YYYY.MM.DD(ddd)')}까지 변경 가능` : 
                                        `${dayjs(data.dateChangedTsp).format('YYYY.MM.DD')}일에 1회 날짜 변경했습니다.
                                        날짜변경은 1회만 가능합니다.`
                                        :'예정 방문일로부터 5일 이내는 날짜변경이 불가능합니다.'
                                    }
								/>
								</>
							},
							{
								id: '5',
								title: '웨딩홀 정보',
								content: <>
									<div className="hall-map-wrap">
										<div id="hallMap" style={{width: '100%', height: 280, marginBottom:'8px'}}></div>
                                        <button 
											style={{marginTop: 32, border:'1px solid #191919', backgroundColor:'#fff', color: '#191919', margin: '12px auto'}}
											className="btn btn-full btn-large btn-line-gray900" 
											onClick={() => setModalStgate({...modalState, map: true})}
										>
											길찾기
										</button>
										<ReservationInfoTable 
											size="small"
											style={{marginTop: 8}}
											// title="예상하객수"
											infos={[
												    {title: '주소', value: data.hallAddress || '-'},
													{title: '운영시간', value: data.hallBizHour || '-'},
													{title: '주차', value: data.hallBizParking || '-'},
													{title: '전화번호', value: data.hallTel, onClick: () => window.location.href = `tel://${data.hallTel}` },
											]}
											align="left"
										/>
									
									</div>
								</>
							}
						]}
					/>
					<div className="btn-wrap items-2 flex-1-2">
						<div>
							<button 
                                className={`btn btn-full btn-large ${(isWithin5Day(data.visitDate) || data.refundable === false) || cancelComplete ? 'btn-disabled': ''}`}
                                onClick={() => setModalStgate({...modalState, cancel: true})}
                                disabled={(isWithin5Day(data.visitDate) || data.refundable === false) || cancelComplete}
                                >방문 취소</button>
						</div>
						<div>
							<button 
                                className={`btn btn-full btn-large ${data.changeableVisit === false ? 'btn-disabled': '' }` }
                                onClick={(e)=>clickChangeReservationBtn(e, data.partnerId, data.visitDate, data.visitTime, orderId)}
                                disabled={data.changeableVisit === false}
                            >날짜변경</button>
						</div>
					</div>
				</section>
                </>
			</div>
            }
			<BottomFixedModal 
				isShow={modalState.map}
				isTransition={true}
				hideModal={() => setModalStgate({...modalState, map: false})}
				title="웨딩홀 길찾기"
			>
				<ul className="link-to-map-list-wrap">
					<li onClick={() => {setModalStgate({...modalState, map: false})}}>
                        <a href={isAndroid()? 
                            `intent://route/car?dlat=${location.y}&dlng=${location.x}&dname=${location.name}&appname=com.example.myapp#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`
                            : 
                            `nmap://route/car?dlat=${location.y}&dlng=${location.x}&dname=${location.name}&appname=realtime`}>
                            <img src={icNaverMap} alt="네이버 지도" width={40} />
						    <span>네이버 지도</span>
                        </a>	
					</li>
					<li onClick={() => setModalStgate({...modalState, map: false})}>
                        <a href={isExternalUrl(`https://map.kakao.com/link/to/${location.name},${location.y},${location.x}`)}>
                            <img src={icKakaoMap} alt="카카오맵" width={40} />
						    <span>카카오맵</span>
                        </a>
						
					</li>
                    <li onClick={() => setModalStgate({...modalState, map: false})}>
                        <a href={isAndroid()? 
                            `intent://route?goalname=${location.name}&goalx=${location.x}&goaly=${location.y}#Intent;scheme=tmap;package=com.skt.tmap.ku;end`
                            : 
                            `tmap://route?goalname=${location.name}&goalx=${location.x}&goaly=${location.y}`}>
                            <img src={icTmap} alt="티맵" width={40} />
						    <span>TMAP</span>
                        </a>
					</li>
                   
					
				</ul>
			</BottomFixedModal>
			<Modal 
				isShow={modalState.cancel}
				overlay={true}
                overlayValue={0.5} 
				text={`예약된 일정을 취소하시겠습니까?
※ 예약 취소시 복구가 불가합니다`}
				buttons={[
					{name: '뒤로', className: 'btn-light-gray', action: () => setModalStgate({...modalState, cancel: false})},
					{name: '방문 취소', className: 'btn-red', action: () =>  confirmCancelReservation(orderId)}
				]}
			/>
            <Modal 
				isShow={modalState.confirm}
				overlay={true}
                overlayValue={0.5} 
				text='취소가 완료되었습니다.'
				buttons={[
					{
                        name: '확인', 
                        className: 'btn-red', 
                        action: () => {
                            // navigate(0)
                            setModalStgate({...modalState, confirm: false})
                        }
                    },
				]}
			/>
		</div>
	);
};

export default ManagementDetail;