import { useState, useEffect, useContext } from 'react';
import { UserContext } from '@src/App';
import { changeReservation } from '@src/api/booking/booking';
import { useParams } from 'react-router';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import Modal from '@src/components/common/modal/Modal';
import { useNavigate } from 'react-router-dom';
import TextArea from '@src/components/common/textArea/TextArea';
import NotiText from '@src/components/common/notiText/NotiText';

const Change = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const params = useParams<{uuid: string}>();
	const query = useUrlQueryString();
	const [content, setContent] = useState('');
	const partnerName = query.get('partnerName');
	const [isLoading, setIsLoading] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorExisitModal, setErrorExisitModal] = useState(false);
	const applyChange = () => {
		if (!user.me) return;
		if (isLoading) return;
		setIsLoading(true);
		changeReservation({
			customerUuid: user.me.uuid,
			uuid: params.uuid,
			content: content
		}).then(_ => {
			setSuccessModal(true);
			setIsLoading(false);
		}).catch(_ => {
			setErrorExisitModal(true);
			setIsLoading(false);
		});
	}
	return (
		<>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<div className="page page-booking-change layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`${partnerName}
						방문 일정 변경 요청하기`}
						style={{margin: '0 -20px'}}
					/>
					<p>변경 원하는 일정을 적어주세요.</p>
					
					<TextArea 
						className="textarea-has-line"
						style={{height: '100px', marginBottom: 0}}
						value={content}
						onChange={e => setContent(e.target.value)}
						placeholder={`예) 8월 23일 2시 이후 가능합니다.
						8월 6일, 7일, 13일, 14일 가능합니다.
						OO웨딩홀 다음 순서로 가고싶어요.`}
					/>
					<NotiText 
						text={`* 평일은 예식이 없으므로 웨딩홀 세팅이 되어있지 않아 평일보다 주말에 방문하시는 것을 추천드립니다.
						* 웨딩홀 상담은 보통 오전 11시 ~ 오후 5시까지 진행됩니다. `}
					/>
				</div>
			</div>
			<div className="btn-wrap">
				<button className="btn btn-full btn-large btn-red" onClick={applyChange}>일정 변경 요청하기</button>
			</div>
			<Modal
				isShow={successModal}
				text={`일정 변경 요청이 접수되었습니다.
				웨딩홀 매니저가 확인 후 
				[마이웨딩 > 1:1문의] 채팅으로 알려드릴게요!`}
				onClose={() => {
					setSuccessModal(false);
					navigate(-1);
					// history.replace('/booking/hall/management?tab=2');
				}}
			/>
			<Modal
				isShow={errorModal}
				text={`일정 변경을 실패하였습니다. [마이웨딩 > 1:1문의] 채팅을 통해 문의해주세요.`}
				onClose={() => {
					setErrorModal(false);
				}}
			/>
			<Modal
				isShow={errorExisitModal}
				text={`이미 변경 요청되었습니다.
				추가 문의는 채팅창에 남겨주세요.`}
				buttons={[
					{
						name: '1:1문의 채팅으로 이동',
						className: 'btn-red',
						action: () => {
							window.location.href = 'h2mwbell://chat';
							setErrorExisitModal(false);
						}
					}
				]}
			/>
		</>
	);
};

export default Change;