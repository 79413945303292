import { useState, useEffect } from 'react';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useRecommendHall } from './../../../utils/hooks/useRecommendHall';
import { setMealPrice } from '@src/@slice/recommendHallSlice';
import { numberToKorean } from './../../../utils/index';
import Blank from '../../../components/common/blank/Blank';
import LinkWegazine from '../../../components/common/linkWegazine/LinkWegazine';

const priceItems = ['~50000', '50000~60000', '60000~70000', '70000~90000', '90000~'];
const Cost = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {hallData, dispatch} = useRecommendHall();
	useEffect(() => {
		const meal = hallData.meal;
		setValidate((meal.minPrice !== null || meal.maxPrice !== null))
	}, [hallData.meal])
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === '' || hallData.weddingRegions.length === 0 ||
			(hallData.expectedPerson.min === null && hallData.expectedPerson.max === null) || hallData.meal.types.length === 0
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	const goNext = () => {
		navigate('/recommend/hall/hope-date');
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`1인당 식사 비용을
						선택해 주세요.`}
						type="중복 선택 불가"
					/>
					<div className="common-wrap">
						<div style={{height: 20}} />
						<ul className="selectable-list-wrap items-3">
						{priceItems.map(item => {
								const splitVal = item.split('~');
								const val0 = splitVal[0];
								const val1 = splitVal[1];
								const min = hallData.meal.minPrice ? hallData.meal.minPrice : '';
								const max = hallData.meal.maxPrice ? hallData.meal.maxPrice : '';
								return (
									<li key={`cost-item-${item}`}>
										<div 
											className={`${val0}~${val1}` === `${min}~${max}` ? 'on' : ''}
											onClick={() => dispatch(setMealPrice({
												min: val0 ? +val0 : null,
												max: val1 ? +val1 : null
											}))}
										>
											{(val0 && val1) &&
												<>
												{val0 ? `${numberToKorean(val0)}` : ''} ~ {val1 ? `${numberToKorean(val1)}` : ''}
												</>
											}
											{val0 === '' &&
											<>{numberToKorean(val1)} 미만</>
											}
											{val1 === '' &&
											<>{numberToKorean(val0)} 이상</>
											}
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<div>
					<LinkWegazine
						title="웨딩홀 예산에 대해 잘 모르겠다면?"
						link="https://www.weddingbook.com/wegazine/dc53ea96-6dfb-11ec-b0a5-0aea5320e7f2?wbTitle=%20"
					/>
					<Blank height={20} />
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cost;