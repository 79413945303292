import { useState, forwardRef, useImperativeHandle, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setBrideName,
    setBrideTel,
    setGroomName,
    setGroomTel,setRequestedByPlanner,
    setPlanerName,setPlanerTel,setPlanerCompany,
    realtimeBooking
} from "@src/@slice/realtimeBookingSlice";
import BookingTitle from "@src/components/booking/bookingTitle/BookingTitle";
import Checkbox from "@src/components/common/checkbox/Checkbox";
import { autoHypenPhone } from "@src/utils";
import {ReactComponent as ErrorIcon} from '@src/assets/images/icon/ic-error-red700.svg'
import { UserContext } from "@src/App";
import { useNavigate } from "react-router-dom";


const RealTimeStepCutomerInfo = forwardRef(({updateIsDisabled,setModal=()=>{}}: { updateIsDisabled?: (value: boolean) => void,setModal?:any }, ref) => {
    const navigate = useNavigate()
    const data = useSelector(realtimeBooking).data;
    const dispatch = useDispatch();
    const user = useContext(UserContext);
    const inputRefs = useRef([]);
    const planerRef = useRef(null)

    const [brideNameInfo, setBrideNameInfo] = useState('');
    const [bridePhone, setBridePhone] = useState('');
    const [groomNameInfo, setGroomNameInfo] = useState('');
    const [groomPhone, setGroomPhone] = useState('');
    const [isPlaner,setIsPlaner] = useState(false);
    const [planerNameInfo, setPlanerNameInfo] = useState('');
    const [planerPhone, setPlanerPhone] = useState('');
    const [planerCo, setPlanerCo] = useState('');
    const [formErrors, setFormErrors] = useState({});

    // const handleButtonClick = () =>{
    //     dispatch(setBrideName({name: brideNameInfo}));
    //     dispatch(setGroomName({name: groomNameInfo}));
    //     dispatch(setBrideTel({tel: bridePhone}));
    //     dispatch(setGroomTel({tel: groomPhone}));
    //     dispatch(setRequestedByPlanner({check: isPlaner}));
    //     if(isPlaner){
    //         dispatch(setPlanerName({name: planerNameInfo}));
    //         dispatch(setPlanerTel({tel: planerPhone}));
    //         dispatch(setPlanerCompany({name: planerCo}));
    //     }

    // }

    // useImperativeHandle(ref,handleButtonClick);

    useImperativeHandle(ref, () => ({
        handleButtonClick(){ 
            dispatch(setBrideName({name: brideNameInfo}));
            dispatch(setGroomName({name: groomNameInfo}));
            dispatch(setBrideTel({tel: bridePhone}));
            dispatch(setGroomTel({tel: groomPhone}));
            dispatch(setRequestedByPlanner({check: isPlaner}));
            if(isPlaner){
                dispatch(setPlanerName({name: planerNameInfo}));
                dispatch(setPlanerTel({tel: planerPhone}));
                dispatch(setPlanerCompany({name: planerCo}));
            }
        }
    }));

    useEffect(()=>{
        if(data){
            setBrideNameInfo(data.brideName);
            setBridePhone(data.brideTel);
            setGroomNameInfo(data.groomName);
            setGroomPhone(data.groomTel);
            setIsPlaner(data.requestedByPlanner);
            if(data.requestedByPlanner){
                setPlanerNameInfo(data.plannerName);
                setPlanerPhone(data.plannerTel);
                setPlanerCo(data.plannerConsultingPartnerName);
            }
        }
    },[])

    useEffect(() => {
        if (planerRef.current) {
            const top = planerRef.current.getBoundingClientRect().top - 10//+ window.scrollY - 100;
            const inputElement = planerRef.current.querySelector('input');
            window.scrollTo({ top: top, behavior: 'smooth' });
            if (inputElement) {
              inputElement.focus();
            }
        }
    }, [isPlaner]);

    useEffect(()=>{
        // formErrors 객체의 값 중 하나라도 false가 있는지 확인
        const hasError = Object.values(formErrors).some(error => !error);
        // updateIsDisabled(true);
        if(hasError){
            updateIsDisabled(true);
            return;
        }else {
            if(!!brideNameInfo && !!groomNameInfo && !!bridePhone && !!groomPhone && isPlaner === false && bridePhone !== groomPhone){
                updateIsDisabled(false);
                return 
            }else if(isPlaner){
                if(planerNameInfo && !!planerPhone && !!planerCo){
                    updateIsDisabled(false);
                    return
                }
            }
        }
        updateIsDisabled(true);
        // isDisabled()
    },[brideNameInfo, groomNameInfo, bridePhone, groomPhone, isPlaner, planerNameInfo, planerPhone, planerCo, updateIsDisabled,formErrors])


    const isDisabled = () => {
        const hasError = Object.values(formErrors).some(error => !error);
        updateIsDisabled(true);
        if(hasError){
            updateIsDisabled(true);
            return true;
        }else {
            if(!!brideNameInfo && !!groomNameInfo && !!bridePhone && !!groomPhone && isPlaner === false && bridePhone !== groomPhone){
                updateIsDisabled(false);
                return false;
            }else if(isPlaner){
                if(planerNameInfo && !!planerPhone && !!planerCo){
                    updateIsDisabled(false);
                    return false;
                }
            }
        }
        return true;
    }

    const isMobile = () => {
        const userAgent = navigator.userAgent 
        return /android|iPad|iPhone|iPod|webOS|BlackBerry|Android|Windows Phone/i.test(userAgent);
      };

    const isValidKoreanName = (name: string): boolean => {
        const koreanRegex = /^[가-힣]{2,}$/;
        const hasSpecialChar = /[^가-힣\s]/.test(name);
        const hasOnlyConsonantsOrVowels = /[ㄱ-ㅎㅏ-ㅣ]/.test(name);
    
        return koreanRegex.test(name) && !hasSpecialChar && !hasOnlyConsonantsOrVowels;
    };

    const isValidPhoneNumber = (phone: string): boolean => {
        const phoneRegex = /^\d{3}-\d{4}-\d{4}$/;
        return phoneRegex.test(phone);
    };
      
    const ErrorMsg = ({name}) => {
        const txt = name.includes('Name') ? '올바른 이름을 입력해주세요.' : bridePhone === groomPhone ? '동일한 전화번호입니다. 다른 전화번호를 입력해주세요.':'올바른 전화번호를 입력해주세요.';
        const isError = formErrors[name] === false;

        return (
            <>
                {isError && 
                <div className="error-txt-wrap">
                    <ErrorIcon/>
                    <p className="error-txt">{txt}</p>
                </div>
                }
            </>
        )
    }


    const handleKeyDown = (event, index, isValid) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (index < inputRefs.current.length - 1 && isValid) {
          inputRefs.current[index + 1].focus();
        }
      }
    };

    const handleBlur = (index, isValid) => {
        if (isMobile() && index < inputRefs.current.length - 1 && isValid) {
          setTimeout(() => {
            inputRefs.current[index + 1].focus();
          }, 0);
        }
      };
    

    return (
        <div className="page-realtime-customer-info">
            <BookingTitle title={`예약자 정보를 확인해주세요.`} />
            <div className="form-wrap">
                <div className="form-block">
                    <div>
                        <span className="title">신부 이름</span>
                    </div>
                    <div className="form-content">
                        <input
                            ref={(el) => (inputRefs.current[0] = el)}
                            autoFocus={data?.brideName === ''}
                            type="text"
                            className={`input-text input-full ${formErrors['brideNameInfo'] === false ? 'error':''}`}
                            name='brideNameInfo'
                            placeholder="신부 이름"
                            value={brideNameInfo}
                            onChange={(e) => {
                                setBrideNameInfo(e.target.value)
                                setFormErrors(prevErrors => ({...prevErrors,[e.target.name]: isValidKoreanName(e.target.value)}))
                            }}
                            onKeyDown={(e) => handleKeyDown(e, 0,formErrors['brideNameInfo'])}
                            onBlur={() => handleBlur(0, formErrors['brideNameInfo'])}
                        />
                        <ErrorMsg name='brideNameInfo'/>
                    </div>
                </div>
                    <div className="form-block">
                        <div>
                            <span className="title">신부 전화번호</span>
                        </div>
                        <div className="form-content">
                            <input
                                type="tel"
                                className={`input-text input-full ${formErrors['bridePhone'] === false ? 'error':''}`}
                                placeholder="신부 전화번호"
                                name='bridePhone'
                                value={bridePhone}
                                onChange={(e) => {
                                    setBridePhone(autoHypenPhone(e.target.value))
                                    setFormErrors((prevErrors) => ({ ...prevErrors,[e.target.name]: isValidPhoneNumber(autoHypenPhone(e.target.value)) && groomPhone !== e.target.value}))
                                }}
                                ref={(el) => (inputRefs.current[1] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 1,formErrors['bridePhone'])}
                                onBlur={() => handleBlur(1, formErrors['bridePhone'])}
                            />
                            <ErrorMsg name='bridePhone'/>
                        </div>
                    </div>
                    <div className="form-block">
                        <div>
                            <span className="title">신랑 이름</span>
                        </div>
                        <div className="form-content">
                            <input
                                type="text"
                                className={`input-text input-full ${formErrors['groomNameInfo'] === false ? 'error':''}`}
                                placeholder="신랑 이름"
                                name='groomNameInfo'
                                value={groomNameInfo}
                                onChange={(e) => {
                                    setGroomNameInfo(e.target.value)
                                    setFormErrors(prevErrors => ({...prevErrors,[e.target.name]: isValidKoreanName(e.target.value)}))
                                }}
                                ref={(el) => (inputRefs.current[2] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 2, formErrors['groomNameInfo'])}
                                onBlur={() => handleBlur(2, formErrors['groomNameInfo'])}
                            />
                            <ErrorMsg name='groomNameInfo'/>
                        </div>
                    </div>
                    <div className="form-block">
                        <div>
                            <span className="title">신랑 전화번호</span>
                        </div>
                        <div className="form-content">
                            <input
                                type="tel"
                                className={`input-text input-full ${formErrors['groomPhone'] === false ? 'error':''}`}
                                placeholder="신랑 전화번호"
                                name='groomPhone'
                                value={groomPhone}
                                onChange={(e) => {
                                    setGroomPhone(autoHypenPhone(e.target.value))
                                    setFormErrors((prevErrors) => ({...prevErrors,[e.target.name]: isValidPhoneNumber(autoHypenPhone(e.target.value)) && bridePhone !== autoHypenPhone(e.target.value)}))
                                }}
                                ref={(el) => (inputRefs.current[3] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 3,formErrors['groomPhone'])}
                                onBlur={() => handleBlur(3, formErrors['groomPhone'])}
                            />
                            <ErrorMsg name='groomPhone'/>
                        </div>
                    </div>
                    {user.me &&
                    <div className="form-block">
                        <div className="form-content">
                            <Checkbox
                                id="planer"
                                name="planer"
                                value="플래너입니다"
                                setValue={() => {setIsPlaner(!isPlaner)}}
                                checked={isPlaner}
                            />
                            <label htmlFor="planer">플래너입니다</label>
                        </div>
                    </div>}
                    {isPlaner && 
                    <>
                        <div className="form-block" ref={planerRef}>
                            <div>
                                <span className="title">플래너 이름</span>
                            </div>
                            <div className="form-content" >
                                <input
                                    type="text"
                                    className={`input-text input-full ${formErrors['planerNameInfo'] === false ? 'error':''}`}
                                    placeholder="플래너 이름"
                                    name='planerNameInfo'
                                    value={planerNameInfo}
                                    onChange={e => {
                                        setPlanerNameInfo(e.target.value)
                                        setFormErrors((prevErrors) => ({ ...prevErrors,[e.target.name]: isValidKoreanName(e.target.value)}))
                                    }}
                                    ref={(el) => (inputRefs.current[4] = el)}
                                    onKeyDown={(e) => handleKeyDown(e, 4, formErrors['planerNameInfo'])}
                                    onBlur={() => handleBlur(4, formErrors['planerNameInfo'])}
                                />
                                <ErrorMsg name='planerNameInfo'/>
                            </div>
                        </div>
                        <div className="form-block">
                            <div>
                                <span className="title">
                                    플래너 전화번호
                                </span>
                            </div>
                            <div className="form-content">
                                <input
                                    type="tel"
                                    className={`input-text input-full ${formErrors['planerPhone'] === false ? 'error':''}`}
                                    placeholder="플래너 전화번호"
                                    name="planerPhone"
                                    value={planerPhone}
                                    onChange={e => {
                                        setPlanerPhone(autoHypenPhone(e.target.value))
                                        setFormErrors((prevErrors) => ({...prevErrors,[e.target.name]: isValidPhoneNumber(autoHypenPhone(e.target.value))}))
                                    }}
                                    ref={(el) => (inputRefs.current[5] = el)}
                                    onKeyDown={(e) => handleKeyDown(e, 5, formErrors['planerPhone'])}
                                    onBlur={() => handleBlur(5, formErrors['planerPhone'])}
                                />
                                <ErrorMsg name='planerPhone'/>
                            </div>
                        </div>
                        <div className="form-block">
                            <div>
                                <span className="title">
                                    컨설팅 업체 이름
                                </span>
                            </div>
                            <div className="form-content">
                                <input
                                    type="text"
                                    className={`input-text input-full ${formErrors['planerCoName'] === false ? 'error':''}`}
                                    placeholder="컨설팅 업체 이름"
                                    name="planerCoName"
                                    value={planerCo}
                                    onChange={e => {
                                        setPlanerCo(e.target.value)
                                        setFormErrors((prevErrors) => ({ ...prevErrors,[e.target.name]: isValidKoreanName(e.target.value)}))
                                    }}
                                    ref={(el) => (inputRefs.current[6] = el)}
                                    onKeyDown={(e) => handleKeyDown(e, 6, formErrors['planerCoName'])}
                                    onBlur={() => handleBlur(6, formErrors['planerCoName'])}
                                />
                                <ErrorMsg name='planerCoName'/>
                            </div>
                            <div style={{height:'190px'}}/>
                        </div>
                    </>
                    }
                </div>
        </div> 
    );
});

export default RealTimeStepCutomerInfo;