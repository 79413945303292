import './dualRangeSlider.scss';
import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';

interface Props {
	title: string;
	showText: boolean;
	width: number;
	min: number;
	defaultMin?: number;
	max: number;
	defaultMax?: number;
	step: number;
	unit: string;
	onChangeMin: (min: number) => void;
	onChangeMax: (max: number) => void;
}
const DualRangeSlider = ({title, width, min, defaultMin, max, defaultMax, step, unit, showText, onChangeMin, onChangeMax}: Props) => {
	const sliderWidth = width;
	const [currentMin, setCurrentMin] = useState(defaultMin ? defaultMin : min);
	const [currentMax, setCurrentMax] = useState(max + step);
	useEffect(() => {
		console.log('@@@@@@here@@@@@@@@@@@');
		if (defaultMin) setCurrentMin(defaultMin);
		if (defaultMax) setCurrentMax(defaultMax + step);
	}, []);
	const [startPosition, setStartPosition] = useState<number|null>(null);
	const [startMin, setStartMin] = useState<number|null>(null);
	const [startMax, setStartMax] = useState<number|null>(null);
	let dMinL = null;
	let dMaxR = null;
	if (defaultMin !== undefined) {
		const percent = (defaultMin - step) / (max + step);
		dMinL = Math.floor(sliderWidth * percent);
	}
	if (defaultMax !== undefined) {
		const percent = ((defaultMax) / (max + step));
		dMaxR = Math.floor(sliderWidth - 30 - sliderWidth * percent);
	}
	// console.log(defaultMin);
	// console.log(dMinL, dMaxR);
	const [minLeft, setMinLeft] = useState(dMinL ? dMinL : 0);
	const [maxRight, setMaxRight] = useState(dMaxR ? dMaxR : 0);
	// console.log(defaultMin + ' / ' + defaultMax);
	// console.log('render range');
	// useEffect(() => {
		
	// }, []);

	const [touchTarget, setTouchTarget] = useState<'min'|'max'|null>(null);
	const setNull = () => {
		setStartPosition(null);
		setStartMin(null);
		setStartMax(null);
	}
	const touchStartMin = (e: React.TouchEvent|React.MouseEvent) => {
		e.preventDefault();
		let pageX = null;
		if ((e as React.TouchEvent).touches) {
			pageX = (e as React.TouchEvent).touches[0].pageX;
		} else {
			pageX = (e as React.MouseEvent).pageX
		}
		setStartPosition(pageX);
		setStartMin(minLeft);
		setTouchTarget('min');
	}
	const touchStartMax = (e: React.TouchEvent|React.MouseEvent) => {
		e.preventDefault();
		let pageX = null;
		if ((e as React.TouchEvent).touches) {
			pageX = (e as React.TouchEvent).touches[0].pageX;
		} else {
			pageX = (e as React.MouseEvent).pageX
		}
		setStartPosition(pageX);
		setStartMax(maxRight);
		setTouchTarget('max');
	}
	const mouseMove = useCallback((e) => {
		if (!touchTarget) return;
		if (!startPosition) return;
		const touchX = e.pageX;
		if (touchTarget === 'min' && startMin !== null) {
			let left = startMin + touchX - startPosition;
			if (left <= 0) left = 0;
			if (left >= sliderWidth - maxRight - 60) left = sliderWidth - maxRight - 60;  // 60: 2 circles
			if (left >= sliderWidth - 30) left = sliderWidth - 30;  // 30: circle
			const r = Math.round(
				((left / (sliderWidth - 60) * 100) / (100 / (max / step)))
			) * (max / step);
			console.log('hi1');
			if (step * (r/(max / step)) < min) {
				console.log('hi2');
				setCurrentMin(min)
			} else {
				console.log('hi3');
				setCurrentMin(step * (r/(max / step)));
			}
			setMinLeft(Math.floor(left));
		}
		if (touchTarget === 'max' && startMax !== null) {
			let right = startMax + startPosition - touchX;
			if (right <= 0) right = 0;
			if (right >= sliderWidth - minLeft - 60) right = sliderWidth - minLeft - 60; // 60: 2 circles
			if (right >= sliderWidth - 30) right = sliderWidth - 30; // 30: circle
			const r = Math.round(
				((right / (sliderWidth - 60) * 100) / (100 / (max / step)))
			) * (max / step);
			setCurrentMax((max + step) - step * (r/(max / step)))
			setMaxRight(Math.floor(right));
		}
	}, [touchTarget, startPosition, sliderWidth, minLeft, maxRight])
	useEffect(() => {
		console.log('change min : ', currentMin);
		onChangeMin(currentMin);
	}, [currentMin]);
	useEffect(() => {
		console.log('change max : ', currentMax);
		onChangeMax(currentMax);
	}, [currentMax]);

	
	useEffect(() => {
		const mouseup = () => {
			setNull();
		}
		document.addEventListener('mouseup', mouseup);
		return () => {
			document.removeEventListener('mouseup', mouseup);
		}
	}, [])
	useEffect(() => {
		document.addEventListener('mousemove', mouseMove);
		return () => {
			document.removeEventListener('mousemove', mouseMove);
		}
	}, [mouseMove])
	return (
		<>
		{showText &&
		<div className="range-text">
			{(min === currentMin && (max + step) === currentMax)
			? <span>모든 {title}</span>
			: <span>
				{currentMin}{unit}
				~
				{currentMax === (max + step) ? '제한없음' : currentMax + unit}
			</span>
			}
		</div>
		}
		test
		{minLeft}
		<div 
			className="range-slider-wrap"
			onTouchMove={(e: React.TouchEvent) => {
				if (!touchTarget) return;
				if (!startPosition) return;
				const touchX = e.touches[0].pageX;
				if (touchTarget === 'min' && startMin !== null) {
					let left = startMin + touchX - startPosition;
					if (left <= 0) left = 0;
					if (left >= sliderWidth - maxRight - 60) left = sliderWidth - maxRight - 60;  // 60: 2 circles
					if (left >= sliderWidth - 30) left = sliderWidth - 30;  // 30: circle
					const r = Math.round(
						((left / (sliderWidth - 60) * 100) / (100 / (max / step)))
					) * (max / step);
					if (step * (r/(max / step)) < min) {
						setCurrentMin(min)
					} else {
						setCurrentMin(step * (r/(max / step)));
					}
					setMinLeft(Math.floor(left));
				}
				if (touchTarget === 'max' && startMax !== null) {
					let right = startMax + startPosition - touchX;
					if (right <= 0) right = 0;
					if (right >= sliderWidth - minLeft - 60) right = sliderWidth - minLeft - 60; // 60: 2 circles
					if (right >= sliderWidth - 30) right = sliderWidth - 30; // 30: circle
					const r = Math.round(
						((right / (sliderWidth - 60) * 100) / (100 / (max / step)))
					) * (max / step);
					setCurrentMax((max + step) - step * (r/(max / step)))
					setMaxRight(Math.floor(right));
				}
			}}
			onMouseUp={() => {
				setNull();
			}}
			onTouchEnd={() => {
				setNull();
			}}
		>
			<div className="range-slider-inner">
				<div className="range-bar" style={{left: minLeft, right: maxRight}} />
				<div 
					className="range-circle range-min" 
					style={{left: minLeft + 'px'}}
					onTouchStart={touchStartMin}
					onMouseDown={touchStartMin}
				>
					<span /><span /><span />
				</div>
				<div
					className="range-circle range-max"
					style={{right: maxRight + 'px'}}
					onTouchStart={touchStartMax}
					onMouseDown={touchStartMax}
				>
					<span /><span /><span />
				</div>
			</div>
		</div>
		</>
	);
};

export default DualRangeSlider;
