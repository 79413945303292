import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useDispatch } from 'react-redux';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import { setInPath, setOrigin } from '@src/@slice/recommendHallSlice';

const Start = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const origin = query.get('origin');
	const inpath = query.get('inpath');
	const dispatch = useDispatch();
	const goNext = () => {
		navigate('/recommend/weddingGift/price-range');
	}
	useEffect(() => {
		if (inpath) {
			dispatch(setInPath({inpath: inpath.toUpperCase()}));
		}
		if (origin) {
			dispatch(setOrigin({origin: origin.substring(0, 10)}));
		}
	}, []);
	return (
		<>
		<AppHeader type="stack" actionItems={[
			{name: 'close'}
		]} />
		<div className="page page-wgift page-wgift-start">
			<PageTitle title={`나에게 맞는 예물 업체를 
찾고 계신가요?`}/>
			<p className="recommend-wgift-text">웨딩업계 최초 AI분석으로 내 취향에 꼭 맞는 예물 업체를 찾아보세요. 추천된 업체에 바로 상담 예약하고 계약하면 특별한 혜택까지 드려요.</p>
			<ul className="wgift-reward-list-wrap">
				{/* <li>
					<span className="reward-emoji">💁‍♀️</span>
					5% 캐시백
				</li> */}
				<li>
					<span className="reward-emoji">🏷️</span>
					3만원 할인 쿠폰
				</li>
				{/* <li>
					<span className="reward-emoji">💍</span>
					예물 웨딩스탬프
				</li> */}
				<li>
					<span className="reward-emoji">🎉</span>
					웨딩북 전용 <br/>
					업체 특별 혜택 제공
				</li>
			</ul>
			<div className="btn-wrap">
				<button onClick={goNext} className={`btn bold btn-large btn-red btn-full`}>다음</button>
			</div>
		</div>
		</>
	);
};

export default Start;