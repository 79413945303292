import './listItemTitle.scss';

type Params = {
	subTitle?: string;
	title: string;
}
const ListItemTitle = ({subTitle, title}: Params) => {
	return (
		<div className="list-item-title-wrap">
			<span className="list-item-sub-title">{subTitle}</span>
			<span className="list-item-title">{title}</span>
		</div>
	);
};

export default ListItemTitle;