import { useState, useEffect } from 'react';
import { useEmailValidation, usePasswordValidation } from './useSignValidation';
import { chkPhoneTel } from '..';
import { autoHypenPhone } from '..';

/**
 * - 이메일, 비밀번호, 핸드폰, 입력값이 있는 Input, Radio 타입 Input, 인증 Input, 유효성 검사
 * - SignInUp pages에서 주로 사용
 * - formatValid : 형식 검사 결과 (true: success, false: error)
 * - dataValid : 데이터 검사 결과 (true: success, false: error)
 * - resultObject : 결과 객체 (값에 따라 formatValid, dataValid가 변경됨)
 */

// 결과 객체에 따른 상태 변경 함수 연결 (formatValid, dataValid)
const signValidCondition = (resultObject: any, setDataValid: any, setFormatValid: any) => {
    if (Object.values(resultObject).length === 0) return;
    if (resultObject.result === '') return;
    if (resultObject.result === 'success') {
        if (resultObject.type === 'dataValid') {
            setDataValid(true);
        } else if(resultObject.type === 'formatValid'){
            setFormatValid(true);
        } else if(resultObject.type === 'all'){
            setDataValid(true);
            setFormatValid(true);
        }
    } else if(resultObject.result === 'error'){
        if (resultObject.type === 'dataValid') {
            setDataValid(false);
        } else if(resultObject.type === 'formatValid'){
            setFormatValid(false);
        } else if(resultObject.type === 'all'){
            setDataValid(false);
            setFormatValid(false);
        }
    } else {
        setDataValid(false);
        setFormatValid(false);
    }
}

// 이메일 Input
export const useSignEmail = (
    { val = '', data = false, format = false, dataValidCheckType = '' }
    : { val?: any, data?: boolean, format?: boolean, dataValidCheckType?: string}
    = {}
) => {

    const [value, setValue] = useState(val);
    const defaultResultObject = data ? { result: 'success', type: 'all' } : { result: 'error', type: 'all'} as { [key: string]: any };
    const [resultObject, setResultObject] = useState(defaultResultObject);
    const [formatValid, setFormatValid] = useState(format);
    const [dataValid, setDataValid] = useState(data);
    const { chkIsEmailUsed, validate: emailFormatValidate, findPassword } = useEmailValidation();

    // 이메일 데이터 확인
    const emailDataErrorCheck = async (value: string) => {
        const formatResult = emailFormatValidate(value);
        let result:any = {} as { [key: string]: string };
        if (formatResult.result === 'success') {
            switch (dataValidCheckType) {
                case 'existAccount':
                    const isNewAccount = await chkIsEmailUsed(value);
                    if (isNewAccount) {
                       result = isNewAccount.result === 'error' ?
                        { result: 'success', type: 'dataValid', label: 'Exist Account', message: '' }
                        : { result: 'error', type: 'dataValid', label: 'Not Exist Account', message: '등록되지 않은 아이디입니다. 이메일 주소를 다시 확인해주세요.' };
                    } else {
                        result = isNewAccount;
                    }
                    break;
                case 'newAccount':
                    result = await chkIsEmailUsed(value);
                    break;
                case 'findPassword':
                    result = await findPassword(value);
                    break;
                default:
                    result = {result: 'success', type: 'dataValid', label: 'Email Valid Input', message: ''};
                    break;
            }
            if (result) {
                setResultObject(result);
            }
            return result;
        } else {
            setResultObject(formatResult);
        }
    }

    // 이메일 값 변경 시
    useEffect(() => {
        setResultObject({ result: '', type: '', label: '', message: '' });
        setFormatValid(false);
        setDataValid(false);
        if (value.length !== 0) {
            let formatResult = emailFormatValidate(value);
            setResultObject(formatResult);
        } else {
            setFormatValid(false);
            setDataValid(false);
        }
    }, [value]);

    // 초기값 있을 경우 데이터 확인
    useEffect(() => {
        if(val) {
            emailDataErrorCheck(val);
        }
    }, [val]);

    // 결과 객체 변경 시 (formatValid, dataValid 변경)
    useEffect(() => {
        signValidCondition(resultObject, setDataValid, setFormatValid);
    }, [resultObject]);

    // dataValid 변경
    useEffect(() => {
        if (dataValidCheckType === '') { 
            setDataValid(formatValid);
        }
    }, [formatValid]);

    return {
        value, setValue,
        resultObject, setResultObject,
        emailFormatValidate, emailDataErrorCheck,
        formatValid, setFormatValid,
        dataValid, setDataValid,
    };
};

// 비밀번호 Input
export const useSignPassword = (
    { val = '', data = false, format = false, }
    : { val?: any, data?: boolean, format?: boolean}
    = {}
) => {
    const [value, setValue] = useState(val);
    const defaultResultObject = data ? { result: 'success', type: 'all' } : { result: 'error', type: 'all'} as { [key: string]: any };
    const [resultObject, setResultObject] = useState(defaultResultObject);
    const [formatValid, setFormatValid] = useState(format);
    const [dataValid, setDataValid] = useState(data);
    const { validate: passwordValidate } = usePasswordValidation();

    // 비밀번호 값 변경 시
    useEffect(() => {
        setResultObject({ result: '', type: '', label: '', message: '' });
        setFormatValid(false);
        setDataValid(false);
        if (value.length !== 0) {
            let result = passwordValidate(value);
            setResultObject(result);
        } else {
            setFormatValid(false);
            setDataValid(false);
        }
    }, [value]);

    // 결과 객체 변경 시 (formatValid, dataValid 변경)
    useEffect(() => {
        signValidCondition(resultObject, setDataValid, setFormatValid);
    }, [resultObject]);

    return {
        value, setValue,
        resultObject, setResultObject,
        passwordFormatValidate: passwordValidate,
        formatValid, setFormatValid,
        dataValid, setDataValid,
    };
};

// 핸드폰 Input - 별도 인증 확인 없는 경우 (있는 경우 useAuthInput 사용)
export const useSignPhone = (
    { val = '' } : { val?: any } = {}
) => {
    const [value, setValue] = useState(val);
    const [formatValid, setFormatValid] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const [resultObject, setResultObject] = useState({} as { [key: string]: string });
    const success = { result: 'success', type: 'all', label: 'SignPhone Valid Input', message: '' };
    const error = { result: 'error', type: 'all', label: 'SignPhone Invalid Input', message: '올바른 전화번호를 입력해주세요' };

    // 핸드폰 값 입력 시 '-' 자동 입력 / 13자 이상 입력 방지
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = autoHypenPhone(e.target.value);
        if(value.length > 13) return;
        return setValue(value);
    }

    // 핸드폰 값 변경 시
    useEffect(() => {
        setResultObject({ result: '', type: '', label: '', message: '' });
        setFormatValid(false);
        setDataValid(false);
        if (value.length !== 0) {
            if(chkPhoneTel(value)) {
                setResultObject(success);
            } else {
                setResultObject(error);
            }
        } else {
            setFormatValid(false);
            setDataValid(false);
        }
    }, [value]);

    // 결과 객체 변경 시 (formatValid, dataValid 변경)
    useEffect(() => {
        if (Object.keys(resultObject).length > 0) {
            signValidCondition(resultObject, setDataValid, setFormatValid);
        }
    }, [resultObject]);

    return {
        value, setValue,
        handleChange,
        formatValid, setFormatValid,
        dataValid, setDataValid,
        resultObject, setResultObject
    };
}


// 입력값이 있는 Input (입력값 필수)
export const useHasContentInput = (
    { val = ''} : {val?: string} = {}
) => {
    const [value, setValue] = useState(val);
    const [resultObject, setResultObject] = useState({} as { [key: string]: string });
    const [formatValid, setFormatValid] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const success = { result: 'success', type: 'all', label: 'HasContent Valid Input', message: '' };
 
    // 값 변경 시
    useEffect(() => {
        setResultObject({ result: '', type: '', label: '', message: '' });
        setFormatValid(false);
        setDataValid(false);
        if (value.length !== 0) {
            setResultObject(success);
        } else {
            setFormatValid(false);
            setDataValid(false);
        }
    }, [value]);

    // 결과 객체 변경 시 (formatValid, dataValid 변경)
    useEffect(() => {
        signValidCondition(resultObject, setDataValid, setFormatValid);
    }, [resultObject]);

    return {
        value, setValue,
        resultObject, setResultObject,
        formatValid, setFormatValid,
        dataValid, setDataValid
    };
}

// 커스텀 Input 
export const useCustomInput = (
    { val = '', dataErrorCheck = null, formatErrorCheck = null, dataCheckMoment = 'change'}
    : { val?: any, dataErrorCheck?: any, formatErrorCheck?: any, dataCheckMoment?: 'change' | 'custom' }
    = {}
) => {

    const [value, setValue] = useState<any>(val);
    let beforeValue;
    let formatValidCheck = false;
    const [resultObject, setResultObject] = useState<{[key: string]: string }>({});
    const [formatValid, setFormatValid] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const customDataErrorCheck = dataErrorCheck;

    // 값 변경 시
    useEffect(() => {
        setResultObject({ result: '', type: '', label: '', message: '' });
        if (formatErrorCheck) {
            formatErrorCheck(value);
        } else {
            setFormatValid(value ? true : false);
        }
        formatValidCheck = true;
    }, [value]);

    useEffect(() => {
        if (beforeValue !== value && formatValidCheck) {
            if (dataCheckMoment === 'change') {
                if (dataErrorCheck) {
                    dataErrorCheck(value);
                } else {
                    setDataValid(value ? true : false);
                }
            } else {
                if (value === val) {
                    dataErrorCheck ? dataErrorCheck(val) : setDataValid(val ? true : false);
                }
            }
        }
        beforeValue = value;
        formatValidCheck = false;
    }, [value, formatValid]);

    useEffect(() => {
        formatErrorCheck ? formatErrorCheck(val) : setFormatValid(val ? true : false);
    }, [val]);

    // 결과 객체 변경 시 (formatValid, dataValid 변경)
    useEffect(() => {
        signValidCondition(resultObject, setDataValid, setFormatValid);
    }, [resultObject]);

    return {
        value, setValue,
        resultObject, setResultObject,
        formatValid, setFormatValid,
        dataValid, setDataValid,
        customDataErrorCheck
    };

}

// Radio 타입 Input
export const useRadioInput = <T extends string | number>(
    { val = null  }: { val?: T | null  } = {}
) => {
    const [value, setValue] = useState<T | null>(val);
    const [isSelected, setIsSelected] = useState(false);

    // 값 변경 시
    useEffect(() => {
        setIsSelected(value !== null && value !== '');
    }, [value]);
    
    return {
        value, setValue,
        isSelected, setIsSelected
    };
}

// 인증 Input 
export const useAuthInput = (
    { val = { method: '', verified: false, isFilled: false } }
        : { val?: { method: string, verified: boolean, isFilled: boolean } } = {}
) => {
    const [methodValue, setMethodValue] = useState(val);
    const [saveValue, setSaveValue] = useState(val);
    
    // 인증번호 입력값이 변경될 때마다 저장
    useEffect(() => {
        const notVerified = !methodValue.verified;
        const verified = methodValue.verified;
        if (saveValue.method === '' && notVerified) {
            setSaveValue(methodValue);
        }
        if (verified) {
            setSaveValue(methodValue);
        }
    }, [methodValue]);

    //인증번호 입력값 업데이트
    const updateMethodValue = (newValues: { method?: string, isFilled?: boolean, verified?: boolean }) => {
        setMethodValue({ ...methodValue, ...newValues });
    }

    // 인증번호 확인
    const handleVerify = (isVerified: boolean) => {
        const newValues = { verified: isVerified };
        updateMethodValue(newValues);
        return new Promise<boolean>((resolve) => {
            if (isVerified) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    // 인증번호 입력값 확인 (인증방법 + 인증코드 둘 다 확인)
    const handleIsAllFilled = (isFilled: boolean, value:string) => {
        const newValues = isFilled ? { isFilled: true, method: value } : { isFilled: false, verified: false };
        updateMethodValue(newValues);
    }

    // 인증번호 입력값 확인 (인증방법만 확인)
    const handleIsMethodFilled = (value: string) => {
        updateMethodValue({ method: value });
    }

    return {
        methodValue, setMethodValue,
        saveValue, setSaveValue,
        handleVerify, handleIsAllFilled, handleIsMethodFilled
    };
}

// 유효성 검사 (모든 유효성 검사가 true일 때 true 반환)
export const useSignValidation = (validations: boolean[]) => {
    const [isValid, setIsValid] = useState(false);

    // console.log(validations);
    useEffect(() => {
        setIsValid(validations.every(Boolean));
    }, validations);

    return isValid;
};