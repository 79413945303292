import './listCard.scss';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import { isExternalUrl } from '../../../utils';
import { logEvent } from '@src/firebase';

interface ListCardProps {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	items: {
		arrow: boolean;
		order: number;
		text: string;
		textLink: string;
	}[];
}
function ListCard({id, page, pageSub, title, items}: ListCardProps) {
	return (
		<section className="list-card-wrap">
			<div className="list-card-inner">
				<SectionTitle title={title} />
				<ul className="list-card-ul">
					{items.map((item, idx) => (
					<li key={`list-card-${id}-${idx}`}>
						<a href={isExternalUrl(item.textLink)} onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'list-card',
								behavior: 'click',
								title: item.text
							};
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
						}}>
							{item.text}
						</a>
					</li>
					))}
				</ul>
			</div>
		</section>
	);
}

export default ListCard;