import { useEffect, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
import { UserData } from '@src/@slice/UserSlice';
// router
import { useNavigate, useLocation } from 'react-router-dom';
// hook
import { useUserActions } from '@src/utils/hooks/useUserActions';
// api
import { HOSTNAME, isExternalUrl } from '@src/utils/index';
// componets 
import Wrap from '@src/components/common/wrap/Wrap';
import WrapTitle from '@src/components/common/wrap/WrapTitle';
import Blank from '@src/components/common/blank/Blank';
// analytics
import { logEvent } from '@src/firebase';

// 회원가입 완료 페이지
const Contents = () => {

    // redux
    const ForUserData = useSelector(UserData);

    // history
    const navigate = useNavigate();

    // location
    const location = useLocation();
    const { accessToken, refreshToken } = location.state || {};

    // userData
    const { getUserData } = useUserActions();

    useEffect(() => {
        if(accessToken && refreshToken) {
            getUserData(accessToken, refreshToken);
        }
    }, [accessToken, refreshToken]);

    // uuid 
    const [userUuid, setUserUuid] = useState('');
    useEffect(() => {
        setUserUuid(ForUserData.customerUuid);
    }, [ForUserData]);

    // 다음 단계 이동 핸들러 
    const handleNext = () => {
        goNext();
    }

    // 다음 페이지로 이동
    const goNext = () => {
        if (userUuid) {
            logEvent('Signup_WriteProfile_v2', {})
            window.location.href = isExternalUrl(`${HOSTNAME}/sign-additional/1`);
        }
    }

    // 홈으로 이동
    const goHome = () => {
        if (userUuid) {
            logEvent('Signup_WriteProfileLater_v2', {});
            window.location.href = `h2mwbell://home?userUuid=${userUuid}`;
        }
    }

	return (
		<>
            <Blank height={56}/>
            <div className="contents-wrap">
                <WrapTitle title={<>
                    <h2>
                        짝짝짝! <br />
                        웨딩북의 회원이 되신 것을 <br />
                        진심으로 축하드립니다.
                    </h2>
                    <p>
                        이제 웨딩북에서 꿈꾸던 결혼식을 준비해보세요. <br />
                        결혼 준비 이야기를 시작해볼까요? 취향에 맞는 콘텐츠를 추천해드려요.
                    </p>
                </>} />
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button className={`btn bold btn-large btn-red btn-full`} onClick={handleNext}>시작하기</button>
                </div>
                <div className="underline-link-wrap underline-link-wrap-center" style={{marginTop: '20px'}}>
                    <button type="button" className="underline-link" onClick={goHome}>나중에 하기</button>
                </div>
            </div>
		</>
	);
};

const SignUpComplete = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            accessType='all'
            fullHeight={true}
            contentsComponent={<Contents />}
        />
    );
};

export default SignUpComplete;