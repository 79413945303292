import './dialog.scss';
import * as React from 'react';

interface Props {
	isShow: boolean;
	closeDialog: () => void;
	actionDialog: () => void;
	title: string;
	hasCloseButton?: boolean;
	children: React.ReactNode
}
const Dialog = ({isShow, closeDialog, actionDialog, title, children, hasCloseButton = false}: Props) => {
	return (
		<div className="dialog-wrap" style={{display: isShow ? 'block' : 'none'}}>
			<div className="overlay" onClick={closeDialog}></div>
			<div className="dialog-inner">
				<h2 className="dialog-title">{title}</h2>
				<div className="dialog-content">
				{children}
				</div>
				<div className="dialog-footer">
					<button 
						className="btn btn-color-red btn-transparent btn-auto-width btn-auto-height"
						onClick={closeDialog}
					>
						취소
					</button>
					<button 
						className="btn btn-color-red btn-transparent btn-auto-width btn-auto-height"
						onClick={actionDialog}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default Dialog;