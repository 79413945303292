import './bookingTitle.scss';

interface BookingTitleProps {
	title: string;
}
const BookingTitle = ({title}: BookingTitleProps) => {
	return (
		<div className="booking-title-wrap">
			<h2>{title}</h2>
		</div>
	);
};

export default BookingTitle;