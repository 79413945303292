import { useState, useEffect } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import { useRecommendWGift } from '../../../utils/hooks/useRecommendWGift';
import { setWantPrice } from '@src/@slice/recommendWGiftSlice';

const priceItems = ['예물에 관심이 크게 없어서 가성비 있게 준비하고 싶다.', '남들이 하는 만큼은 하려고 한다.', '예물이 중요하고 남들보다 예산을 더 쓸 의향이 있다.'];
const PriceRange = () => {
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);
	const {wGiftData, dispatch} = useRecommendWGift();

	const goNext = () => {
		navigate('/recommend/weddingGift/select-gift');
	}
	useEffect(() => {
		setValidate(wGiftData.wantPrice.trim() !== '')
	}, [wGiftData.wantPrice]);
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				
			]} />
			<div className="layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`예산대를 선택해 주세요.`}
						type="중복 선택 불가"
					/>
					<div className="common-wrap">
						<ul className="radio-list-wrap">
							{priceItems.map((item, idx) => (
							<li
								onClick={() => dispatch(setWantPrice({price: item}))}
								key={`price-${idx}`}
							>
								<RadioButton 
									id={`price-${idx}`}
									name="price"
									value={item}
									setValue={() => {}}
									checked={item === wGiftData.wantPrice}
								/>
								<label htmlFor={`price-${idx}`}>
									{item}
								</label>
							</li>
							))}
						</ul>
					</div>
				</div>
				<div>
					<div className="btn-wrap">
						<button onClick={goNext} className={`btn bold btn-large btn-red btn-full ${!validate ? 'btn-disabled' : ''}`} disabled={!validate}>다음</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceRange;