import './appHeader.scss';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../App';
import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {increment, decrement, historyLen} from '../../../@slice/historySlice';
import storage from '@src/api/storage';
import { HOSTNAME, isExternalUrl } from '@src/utils';
import { Action } from 'history';
import InfoUpdate from '@src/components/common/fullFixedModal/InfoUpdate';
import { UserData } from '@src/@slice/UserSlice';
import { logEvent } from '@src/firebase';

type TActionItem = 'search' | 'chat' | 'push' | 'like' | 'close' | 'check' | 'back' | 'stop';
interface AppHeaderProps {
	type: 'main' | 'product' | 'stack';
	title?: string;
	titleColor?: string;
	actionItems?: {name: TActionItem, callback?: () => void}[];
	iconColor?: 'black' | 'white';
	backgroundColor?: string;
	scrollHide?: boolean;
	linkItem?: {name: string, onClick: () => void};
}
interface IMe {
	dDay: number;
	me: any;
	bookieUnreadCount: number;
	newPushTsp: string;
}
const AppHeader = ({type, title, titleColor = '#000', actionItems, iconColor = 'black', backgroundColor = '#fff', scrollHide = true, linkItem}: AppHeaderProps) => {
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const navigationType = useNavigationType();
	const location = useLocation();
	const historyLength = useSelector(historyLen);
	const dispatch = useDispatch();
	const { productId } = useParams<{productId: string|undefined}>();
	useEffect(() => {
		switch (navigationType) {
			case Action.Push:
				dispatch(increment());
				break;
			case Action.Pop:
				if (historyLength > 0) dispatch(decrement());
				break;
		}
	}, [location]);
	const goBack = (user: IMe) => {
		if (historyLength >= 1 || user.me === null) {
			navigate(-1);
		} else {
			window.location.href = 'h2mwbell://webview/close';
		}
	}
	const linkSearch = () => {
		let link = isExternalUrl(`${HOSTNAME}/search`);
		if (window.location.pathname === `/product/${productId}`) {
			link = isExternalUrl(`${HOSTNAME}/search?productId=${productId}`);
		}
		window.location.href = link;
	}
	const linkLike = () => {
		let link = isExternalUrl(`${HOSTNAME}/my-interest/like`);
		window.location.href = link;
	}
	const checkPushTsp = async (pushTsp: string) => {
		if (!user.me) return true;
		const checkTime = await storage.getItem(user.me.uuid, 'pushCheck');
		if (checkTime !== false) {
			return (new Date(pushTsp) > new Date(checkTime));
		} else {
			return true
		}
	}
	useEffect(() => {
		if (!scrollHide) return;
		let lastScrollTop = 0;
		const wrap = document.getElementById('AppHeaderWrap');
		if (!wrap) return;
		let lastType = 'down';
		let dispatchTypePosition = 0;
		let postionBeforeDispatch = 0;
		window.addEventListener('scroll', () => {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollTop > lastScrollTop){
				// down
				if (lastType === 'up') {
					const matrix = new WebKitCSSMatrix(window.getComputedStyle(wrap).transform);
					dispatchTypePosition = scrollTop;
					postionBeforeDispatch = matrix.m42;
				}
				let v = -postionBeforeDispatch + (scrollTop - dispatchTypePosition);
				if (v > 56) v = 56;
				wrap.style.transform = `translateY(${-(v)}px)`
				lastType = 'down';
			} else {
				// up
				if (lastType === 'down') {
					const matrix = new WebKitCSSMatrix(window.getComputedStyle(wrap).transform);
					dispatchTypePosition = scrollTop;
					postionBeforeDispatch = matrix.m42;
				}
				let v = -postionBeforeDispatch - (dispatchTypePosition - scrollTop);
				if (v <= 0) v = 1;
				wrap.style.transform = `translateY(${-(v-1)}px)`
				lastType = 'up';
			}
			lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
		});
	}, [])
	const [showNewPush, setShowNewPush] = useState(false);
	useEffect(() => {
		(async () => {
			setShowNewPush(await checkPushTsp(user.newPushTsp));
		})();
		if ((user.me && user.me.weddingTsp)) {
			localStorage.removeItem(InfoUpdateKeyName);	
		} 
	}, [user]);
	const ForUserData = useSelector(UserData);
	const [showInfoUpdate, setShowInfoUpdate] = useState(false);
	const InfoUpdateKeyName = `need-signup-additional-${ForUserData.loginId}`;
	return (
		<>
			<div id="AppHeaderWrap" className={`app-header-wrap ${showInfoUpdate ? 'app-header-fixed' : ''}`} style={{backgroundColor: backgroundColor}}>
			<div className="app-header-inner">
				<div className={`header-left`}>
					{type === 'main'
					? <>
						{(user.me && user.me.weddingTsp)
						? <span onClick={()=>window.location.href = isExternalUrl(`${HOSTNAME}/sign-additional/2?type=Home_Dday_Complete`)} className="d-day">D{user.dDay > 0 ? '+' : '-'}{Math.abs(user.dDay)}</span>
						: (
							<>
								{/* <a href="h2mwbell://myInfo/schedule" className="d-day unknown">미정</a> */}
								<span className="d-day unknown" onClick={() => ! showInfoUpdate ? window.location.href = isExternalUrl(`${HOSTNAME}/sign-additional/2?type=Home_Dday_Complete`) : setShowInfoUpdate(false)}>미정</span>		
							</>				
						)
						}
					</>
					: <>
						{! linkItem && <button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0}} onClick={() => goBack(user)}>
							<i className={`icon icon-arrow-back-${iconColor}`}></i>
						</button>}
					</>
					}
					{title &&
					<h2 className="header-title" style={{color: titleColor}}>{title}</h2>
					}
				</div>
				{((actionItems && user.me !== null)) &&
				<div className="header-action-item-wrap">
				{
				actionItems.map((item) => (
					<div style={{display: 'inline-block'}} key={`app-header-actions-${item.name}`}>
						{(item.name === 'chat' && user.bookieUnreadCount > 0) && <div style={{ width: 6, height: 6, backgroundColor: '#F53F29', float: 'right', borderRadius: 100, position: 'absolute', marginLeft: 30, marginTop: 4}}/>}
						{(item.name === 'push' && showNewPush) && <div style={{ width: 6, height: 6, backgroundColor: '#F53F29', float: 'right', borderRadius: 100, position: 'absolute', marginLeft: 30, marginTop: 4}}/>}
						<button
							className="btn btn-icon btn-transparent btn-tight btn-auto-height"
							style={{padding: 0}}
							onClick={() => {
								switch(item.name) {
									case 'push':
										if (user.me) {
											storage.setItem(user.me.uuid, 'pushCheck', new Date().toString());
										}
										window.location.href = 'h2mwbell://push';
										break;
									case 'chat':
										window.location.href = 'h2mwbell://chat';
										break;
									case 'search':
										linkSearch();
										break;
									case 'like':
										linkLike();
										break;
									case 'close':
										window.location.href = 'h2mwbell://webview/close';
										break;
									case 'check':
										break;
                                    case 'stop':
                                        // window.location.href = `${window.location.origin}/booking/real-time/stop-reservation`;
                                        break;
								}
								if (item.callback) item.callback();
							}}
						>
						<i className={`icon icon-${item.name}-${iconColor}`}></i>
					</button>
				</div>
				))
				}
				</div>
				}
				{linkItem && <div className="app-header-link">
					<button onClick={linkItem.onClick}>{linkItem.name}</button>
				</div>}
			</div>
			{showInfoUpdate && <div className="app-header-overlay" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}} onClick={()=> setShowInfoUpdate(false)}></div>}
		</div>
		<div className="app-header-blank" style={{ height: 56 }} />
		{(type === 'main' && (user.me && ! user.me.weddingTsp)) && <InfoUpdate
			keyName={InfoUpdateKeyName} isShow={showInfoUpdate} setIsShow={setShowInfoUpdate}
			overlayBgColor="rgba(255, 255, 255, 0.9)"
			activeButtonEvent={() => {
				logEvent('Home_WriteProfile', {})
				window.location.href = isExternalUrl(`${HOSTNAME}/sign-additional/2?type=Home_WriteProfile_Complete`);
			}} 
			cancelButtonEvent={() => setShowInfoUpdate(false)}>
			<p>
				예식 정보를 업데이트 해보세요!🤗 <br />
				{user.me.nick ? user.me.nick : ForUserData.nick}님에게 맞는 콘텐츠를 추천해드려요.
			</p>
		</InfoUpdate>}
		</>
	);
};

export default AppHeader;
