import './iconList.scss';
import { isExternalUrl } from '../../../utils';
import { logEvent } from '@src/firebase';

interface IIconItem {
	badgeColor: string;
	badgeText: string;
	badgeTheme: string;
	iconLink: string;
	imgUrl: string;
	order: number;
	subText: string;
	titleText: string;
}
interface IconListProps {
	id: number;
	page: string;
	pageSub?: string;
	items: IIconItem[];
}
function IconList({id, page, pageSub, items}: IconListProps) {
	return (
		<div className="icon-list-wrap">
			<ul className="icon-list-ul">
				{items.map((item, idx) => (
				<li key={`icon-list-${id}-${idx}`}>
					<a href={isExternalUrl(item.iconLink)} onClick={() => {
						const obj = {
							page: page,
							pageSub: pageSub,
							component: 'icon-list',
							behavior: 'click',
							title: item.titleText
						};
						if (pageSub === undefined) delete obj.pageSub;
						logEvent('component_behavior', obj);
					}}>
						<i className="icon" style={{backgroundImage: `url(${item.imgUrl})`}}/>
						<div className="list-info-wrap">
							<div className="list-title-wrap">
								<h3 className="list-title">{item.titleText}</h3>
								{item.badgeText &&
								<span className={`badge badge-${item.badgeTheme}`}>{item.badgeText}</span>
								}
							</div>
							<p className="list-sub-title">{item.subText}</p>
						</div>
					</a>
				</li>
				))}
			</ul>
		</div>
	);
}

export default IconList;