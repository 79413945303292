type STATE_MAP = {
	name: string;
	nameForCustomer: string;
	textToCustomer: string;
	colorType: 'brown-line' | 'green-line' | 'gray-line' | 'gray';
}
export const CONFIRM_STATE_MAP: {[key in number]: STATE_MAP} = {
	0: {name: '대기', nameForCustomer: '접수완료', textToCustomer: '신청 접수 완료', colorType: 'brown-line'},
	1: {name: '승인', nameForCustomer: '예약완료', textToCustomer: '방문완료', colorType: 'gray-line'},
	2: {name: '반려', nameForCustomer: '취소완료', textToCustomer: '예약 진행 취소', colorType: 'gray'},
	3: {name: '취소', nameForCustomer: '취소완료', textToCustomer: '취소 완료', colorType: 'gray'},
	// 4: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	// 5: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	// 6: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	// 7: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	// 8: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	9: {name: '예약보류', nameForCustomer: '예약보류', textToCustomer: '안내 내용 확인 필요', colorType: 'green-line'},
	10: {name: '인폼삭제', nameForCustomer: '취소완료', textToCustomer: '취소 완료', colorType: 'gray'},
	11: {name: '일정취소', nameForCustomer: '취소중', textToCustomer: '취소 접수 완료', colorType: 'gray'},
	12: {name: '임시예약', nameForCustomer: '임시예약', textToCustomer: '보증금 입금 필요', colorType: 'gray-line'},
	13: {name: '주단위', nameForCustomer: '예약대기', textToCustomer: '예약 오픈일 대기중', colorType: 'green-line'},
	14: {name: '미오픈', nameForCustomer: '오픈대기', textToCustomer: '예식 오픈 대기중', colorType: 'green-line'},
	// 15: {name: '', nameForCustomer: '', textToCustomer: '', colorType: 'gray'},
	16: {name: '예약 보류(주단위 마감)', nameForCustomer: '예약보류', textToCustomer: '안내 내용 확인 필요', colorType: 'green-line'},
	17: {name: '확인 완료', nameForCustomer: '접수완료', textToCustomer: '접수완료', colorType: 'brown-line'},
	18: {name: '취소사유확인중', nameForCustomer: '취소보류', textToCustomer: '취소 사유 확인 필요', colorType: 'gray'},
	19: {name: '기존이력존재', nameForCustomer: '예약불가', textToCustomer: '예약 진행 불가', colorType: 'gray'},
	20: {name: '예약보류(예식조건)', nameForCustomer: '예약보류', textToCustomer: '예식 조건 확인 필요', colorType: 'green-line'},
	21: {name: '예약보류(방문일)', nameForCustomer: '예약보류', textToCustomer: '방문일 확인 필요', colorType: 'green-line'},
	22: {name: '예약보류(예식조건,방문일)', nameForCustomer: '예약보류', textToCustomer: '예식 조건, 방문일 확인 필요', colorType: 'green-line'},
	23: {name: '예약보류(확인후인폼)', nameForCustomer: '예약보류', textToCustomer: '안내 내용 확인 필요', colorType: 'green-line'},
	24: {name: '주단위대기', nameForCustomer: '예약보류', textToCustomer: '안내 내용 확인 필요', colorType: 'green-line'},
	25: {name: '주단위실패 ', nameForCustomer: '예약마감', textToCustomer: '추가 방문일 확인 필요', colorType: 'green-line'},
	26: {name: '오픈', nameForCustomer: '오픈', textToCustomer: '예식 일정 오픈', colorType: 'green-line'},
	27: {name: '고민중', nameForCustomer: '보류요청', textToCustomer: '보류요청', colorType: 'green-line'},
	28: {name: '담당자 컨택', nameForCustomer: '보류요청', textToCustomer: '보류요청', colorType: 'green-line'}
}