import { useState, useEffect, useContext } from 'react';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import DataSection from '@src/components/form/dataSection/DataSection';
import NotiBox from '../../../components/common/notiBox/notiBox';
import { useNavigate } from 'react-router-dom';
import { postReservation } from '@src/api/booking/booking';
import { UserContext } from './../../../App';
import { autoHypenPhone, isExternalUrl } from '@src/utils';
import { chkPhoneTel } from './../../../utils/index';
import PageTitle from './../../../components/common/pageTitle/PageTitle';
import { useRecommendHall } from '../../../utils/hooks/useRecommendHall';
import { postRecommendHall } from '../../../api/recommend/recommend';
import { setCustomer, setCustomerUuid } from '@src/@slice/recommendHallSlice';


const CustomerInfo = () => {
	const [validate, setValidate] = useState(true);
	const [loading, setLoading] = useState(false);
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const {hallData, dispatch, isComplete} = useRecommendHall();
	const customer = hallData.customer;
	const postRecommend = () => {
		if (isComplete) {
			alert('이미 신청된 양식입니다.');
			return;
		}
		if (loading) return;
		setLoading(true);
		postRecommendHall({
			...hallData
		}).then(res => {
			setLoading(false);
			navigate('/recommend/hall/complete');
		}).catch(err => {
			alert('error!');
			setLoading(false);
			console.log(err);
		});
	}
	useEffect(() => {
		if (
			hallData.hallStyleTypes.length === 0 || hallData.weddingType === '' || hallData.weddingRegions.length === 0 ||
			(hallData.expectedPerson.min === null && hallData.expectedPerson.max === null) || hallData.meal.types.length === 0 ||
			(hallData.meal.minPrice === null && hallData.meal.maxPrice === null) ||
			hallData.hopeWedding.yearMonths.length === 0 || hallData.hopeWedding.days.length === 0 || hallData.hopeWedding.times.length === 0 || hallData.hopeWedding.alternativeSchedules.length === 0 ||
			hallData.tourHopedSchedule === ''
		) {
			navigate('/recommend/hall/start', {replace: true});
		}
	}, []);
	useEffect(() => {
		const customer = hallData.customer;
		if (
			customer.tel.trim() === '' ||
			customer.name.trim() === '' ||
			customer.mateTel.trim() === '' ||
			customer.mateName.trim() === '' ||
			!chkPhoneTel(customer.tel) ||
			!chkPhoneTel(customer.mateTel)
		) {
			setValidate(false);
		} else {
			setValidate(true);
		}
	}, [hallData.customer]);
	useEffect(() => {
		if (isComplete) {
			window.location.href = 'h2mwbell://webview/close';
		}
		if (user.me) {
			dispatch(setCustomerUuid({uuid: user.me.uuid}));
			if (user.me.sex && customer.role === '') {
				dispatch(setCustomer({key: 'role', data: user.me.sex === 1 ? 'BRIDE' : 'GROOM'}));
			}
			if (user.me.customerName && customer.name === '') {
				dispatch(setCustomer({key: 'name', data: user.me.customerName}));
			}
			if (user.me.tel) {
				dispatch(setCustomer({key: 'tel', data: user.me.tel}));
			}
			if (user.me.mateTel && customer.mateTel === '') {
				dispatch(setCustomer({key: 'mateTel', data: user.me.mateTel}));
			}
			if (user.me.mateName && customer.mateName === '') {
				dispatch(setCustomer({key: 'mateName', data: user.me.mateName}));
			}
		}
	}, [user.me]);
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<PageTitle 
				title={`고객님의 정보를 
				알려주세요.`}
			/>
			<DataSection title="신청자와 배우자의 정보를 입력해 주세요.">
				<div className="form-wrap">
					<div className="form-block">
						<div className="form-title">
							<span className="title">본인선택</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<ul className="my-role-list-wrap">
								<li className={customer.role === 'BRIDE' ? 'on' : ''} onClick={() => dispatch(setCustomer({key: 'role', data: 'BRIDE'}))}>
									신부
								</li>
								<li className={customer.role === 'GROOM' ? 'on' : ''} onClick={() => dispatch(setCustomer({key: 'role', data: 'GROOM'}))}>
									신랑
								</li>
							</ul>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="text"
								className="input-text input-full"
								placeholder="이름" 
								defaultValue={customer.name}
								onChange={e => dispatch(setCustomer({key: 'name', data: e.target.value}))}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="tel"
								className="input-text input-full"
								placeholder="01012345678"
								defaultValue={autoHypenPhone(customer.tel)}
								value={autoHypenPhone(customer.tel)}
								readOnly={user.me !== null}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									dispatch(setCustomer({key: 'tel', data: tel}));
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && customer.tel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="text"
								className="input-text input-full"
								placeholder="예비 배우자 이름"
								defaultValue={customer.mateName}
								onChange={e => dispatch(setCustomer({key: 'mateName', data: e.target.value}))}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="tel" 
								className="input-text input-full" 
								placeholder="01012345678" 
								defaultValue={autoHypenPhone(customer.mateTel)}
								value={autoHypenPhone(customer.mateTel)}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									dispatch(setCustomer({key: 'mateTel', data: tel}));
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && customer.mateTel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
				</div>
			</DataSection>
			<NotiBox>
				<p>웨딩홀 혜택 적용을 위해 반드시 실명으로 적어주세요!</p>
			</NotiBox>
			<div style={{height: 20}}></div>
			<div className="btn-wrap">
				<button
					disabled={!validate} 
					className={`btn bold btn-large btn-full btn-red ${!validate ? 'btn-disabled' : ''}`} 
					onClick={postRecommend}
				>
					다음
				</button>
			</div>
		</div>
	);
};

export default CustomerInfo;