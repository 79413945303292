import './rollingBanner.scss';
import { useState } from 'react';
import Slider from 'react-slick';
import { isExternalUrl } from '../../../utils';
import SectionTitle, {ITitle} from '../../common/sectionTitle/SectionTitle';
import { logEvent } from '@src/firebase';
import { clickAdBanner } from '@src/api/ad';
interface RollingBannerProps {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle,
	items: {
		imgUrl: string;
		link: string;
		order: number;
		text: string;
		adCode: string;
	}[];
}
function RollingBanner({id, page, pageSub, title, items}: RollingBannerProps) {
	const [currentIndex, setCurrentIndex] = useState(1);
	const sliderOptions = {
		dots: false,
		arrows: false,
		autoplay: true,
		centerMode: true,
		centerPadding: '14px',
		afterChange: (current: number) => {
			setCurrentIndex(current+1);
		}
	}
	
	return (
		<div className="rolling-banner-wrap">
			<SectionTitle 
				title={title}
			/>
			<div className="rolling-banner">
				<Slider {...sliderOptions}>
					{items.map((item, idx) => (
					<div className="slider-item-wrap" key={`rolling-banner-${id}-${idx}`}>
						<a href={isExternalUrl(item.link)} className="slider-item" onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'rolling_banner',
								behavior: 'click',
								title: item.text
							};
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
							if (item.adCode) {
								clickAdBanner(item.adCode);
							}
						}}>
							<img src={item.imgUrl} alt={`rolling-banner-image-${idx}`} />
						</a>
					</div>
					))}
				</Slider>
				<span className="rolling-banner-navigation">
					{currentIndex}/{items.length}
				</span>
			</div>
		</div>
	);
}

export default RollingBanner;