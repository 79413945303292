import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomFixedModal from "@src/components/common/bottomFixedModal/BottomFixedModal";

const RealTimeEditBottomFixedModal = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const target = location.state?.edit;
    const editRef = useRef(null);
    
    const [isShowModal] = useState(true);
    const [isDisabled, setIsDisabled] = useState<boolean|null>(false); // 버튼 상태 - true 비활성화, false 활성화


    const handleChangeButtonClick = () => {
        if (editRef.current) {
            editRef.current.handleButtonClick();
			if (location.pathname.indexOf('/visit-date') === -1) {
				closeModal();
			}
        }
    };

    const closeModal = () => navigate(-1)

    const updateIsDisabled = (bool) => setIsDisabled(bool);

    return (  
        // <BottomFixedModal isShow={isShowModal} title="" hideModal={() => navigate(-1)}>
        <div className="edit-bottom-fixed-modal-wrap" style={{ display: isShowModal ? "block" : "none" }}>
            <div className="overlay" onClick={closeModal}></div>
            <div className="bottom-fixed-modal" style={{height: target === 'visitor' ? '388px' :'calc(100vh - 104px)'}}>
                <div className="modal-content"> 
                    {React.cloneElement(children, { ref: editRef,updateIsDisabled })}
                </div>
                <div className="btn-wrap">
                        <button className="btn btn-full btn-light-red" onClick={closeModal}>취소</button>
                        <button className={`btn btn-full btn-red ${isDisabled === true ? 'btn-disabled' :''}`} onClick={handleChangeButtonClick}   disabled={isDisabled === true} >변경</button>
                </div>
            </div>
        </div>
        //  </BottomFixedModal>
    );
};

export default RealTimeEditBottomFixedModal;
