import './textButton.scss';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import TextButton, {ITextButton} from './TextButton';
import { logEvent } from '@src/firebase';


interface Props {
	id: number;
	page: string;
	pageSub?: string;
	title: ITitle;
	items: ITextButton[];
}
const TextButtonList = ({id, page, pageSub, title, items}: Props) => {
	return (
		<div className="text-button-list-wrap">
			<SectionTitle title={title} />
			{items.map(item => (
				<TextButton item={item} key={`text-button-${id}-${item.order}`} onClick={() => {
					const obj = {
						page: page,
						pageSub: pageSub,
						component: 'text_button',
						behavior: 'click',
						title: `${item.mainText}-${item.subText}`
					};
					if (pageSub === undefined) delete obj.pageSub;
					logEvent('component_behavior', obj);
				}}/>
			))}
		</div>
	);
};

export default TextButtonList;