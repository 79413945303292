import YouTube, { YouTubeProps } from 'react-youtube';

const DetailVideo = ({data}: any) => {
	const onPlayerReady: YouTubeProps['onReady'] = (event) => {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	return (
		<div className="video-wrapper" style={{marginBottom: data.bottomMargin ? (data.bottomMargin + 'px') : 0, textAlign: 'center'}}>
			<YouTube 
				videoId={data.videoUrl}
				opts={{width: '640'}}
				onReady={onPlayerReady}
			/>
		</div>
	);
};

export default DetailVideo;