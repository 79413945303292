import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { useNavigate } from 'react-router-dom';
import AppHeader from '@src/components/common/appHeader/AppHeader';

const LinkPayFail = () => {
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const errMessage = query.get('message'); // 결제 에러 메세지
    const parterId = JSON.parse(sessionStorage.getItem('reservationData'))?.partnerId; // 일정 마감 시 필요한 파트너ID
    const tossOrderId = query.get('orderId'); // 토스에서 생성한 주문 번호
    
    const tryAgain = () => {
        if(errMessage === '상담 일정 마감'){
            sessionStorage.removeItem('reservationData');
            navigate(`/booking/real-time/${parterId}/visit-date`);
        }else {
            navigate(`/booking/real-time/tosspay/${tossOrderId}`, {replace: true});
        }
    }

	return (
		<>
		<AppHeader type="stack" actionItems={[{ name: "close" }]} />
		<div className="linkpay-result-page margin-top">
			<h2>
				앗.<br/>
				결제에 <br/>
				실패하였습니다.
			</h2>
			<p style={{fontSize : '15px'}}>
				결제도중 오류가 발생하여 결제가 취소 되었습니다.<br/>
				결제 정보와 결제 수단을 확인 후 다시 결제해주세요.
			</p>
			<div className="result-info-wrap" style={{marginTop:'29px'}}>
				<h3 style={{fontSize:'15px', color:'#505050'}}>실패 사유</h3>
				<ul style={{marginTop:'19px'}}>
                    <li style={{listStyle:'disc', marginLeft:'18px', color: '#737373'}}>
                        {errMessage}
                    </li>
                </ul>
			</div>
			<button className="btn bold btn-red btn-circle" onClick={tryAgain}>
                {/* {errMessage === '상담 일정 마감' ? '처음 부터': '다시 결제'} */}
                다시 예약
            </button>
		</div>
		</>
	);
};

export default LinkPayFail;