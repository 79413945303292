import axios from '../axios';
import { BASE_URL } from './../../utils/index';

export interface IPartner {
	partnerId: number;
	partnerName: string;
	coverUrl: string;
	partnerUuid: string;
}

export const getHallList = ({keyword, customerUuid}: {keyword: string; customerUuid?: string}) => {
	return axios.get<{item: IPartner[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/synonym?searchTxt=${keyword}${customerUuid ? `&uuid=${customerUuid}` : ''}`);
}
export const getReservation = (uuid: string) => {
	return axios.get<{item: any}>(`${BASE_URL}/web/v1/weddinghall/reservation/customer/${uuid}`);
}
export const postReservation = (data: any) => {
	return axios.post(`${BASE_URL}/web/v1/weddinghall/reservation`, data);
}
export const duplicateReservation = (partnerUuid: string, customerUuid: string) => {
	return axios.get<{result: number}>(`${BASE_URL}/web/v1/weddinghall/reservation/duplicate?partnerUuid=${partnerUuid}&uuid=${customerUuid}`);
}
export const getReservationList = (uuid: string) => {
	return axios.get<{data: any[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/list?uuid=${uuid}`);
}
export const cancelReservation = ({uuid, content, customerUuid}: {uuid: string, content: string, customerUuid: string}) => {
	return axios.post<{data: any[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/cancel/${uuid}?uuid=${customerUuid}&content=${content}`);
}
export const changeReservation = ({uuid, content, customerUuid}: {uuid: string, content: string, customerUuid: string}) => {
	return axios.post<{data: any[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/visitTsp/${uuid}?uuid=${customerUuid}&content=${content}`);
}
export const answerReservation = ({uuid, content, customerUuid}: {uuid: string, content: string, customerUuid: string}) => {
	return axios.post<{data: any[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/answer/${uuid}?uuid=${customerUuid}&content=${content}`);
}

// management 
interface IReceiverInfo {
	id: number; name: string; uuid: string; coverUrl: string;
}
interface IConfirm {
	confirmState: number; confirmStateAlias: string; confirmTsp: string; visitState: number|null; visitTsp: string|null;
}
export interface IChatChannel {
	id: number; lastChatId: string|null; unreadCnt: number; uuid: string;
}
export interface IChatListItem {
	id: number;
	b2cUserId: number;
	type: number;
	content: string;
	url: string|null;
	data: string|null;
	readState: number;
	regTsp: string;
}
export interface IHallReservationListItem {
	id: number;
	uuid: string;
	regTsp: string;
	visitName: string|null;
	visitTsp: string;
	chatChannel: IChatChannel;
	confirm: IConfirm | null;
	receiverInfo: IReceiverInfo;
}
export const getHallReservationList = (uuid: string) => {
	return axios.get<{item: IHallReservationListItem[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/list?uuid=${uuid}`);
}
export interface IHallReservationByChannelUuidRes {
	id: number;
	uuid: string;
	visitTsp: string;
	confirm: IConfirm | null;
	receiverInfo: IReceiverInfo;
}
export const getHallReservationByChannelUuid = (channelUuid: string, userUuid: string) => {
	return axios.get<{data: IHallReservationByChannelUuidRes}>(`${BASE_URL}/web/v1/weddinghall/reservation/channel/${channelUuid}?uuid=${userUuid}`)
}
export const HallReservationAPI = {
	getList: () => {

	},
	getItemByChannelUuid: () => {

	},
	getChat: (channelUuid: string, userUuid: string) => {
		return axios.get<{item: IChatListItem[]}>(`${BASE_URL}/web/v1/weddinghall/reservation/channel/${channelUuid}/chat?uuid=${userUuid}`)
	},
	postChat: (channelUuid: string, userUuid: string, data: {type: 1|2|3; url?: string; data?: string; content: string;}) => {
		return axios.post(`${BASE_URL}/web/v1/weddinghall/reservation/channel/${channelUuid}/chat?uuid=${userUuid}`, data);
	},
	test: () => {
		return axios.post('http://api.how2marry.com/auth/apikey', 'apiKey=H2M@WBELL', { 
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' } 
		});
	}
}