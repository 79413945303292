import { useState, useEffect, useRef } from 'react';
import PageTab, { ITab } from './../../components/home/pageTab/PageTab';
import SearchHeader from './../../components/search/SearchHeader';
import { ISearchLog, deleteAllSearchLog, deleteSearchLog, getRecommendationKeyword, getSearchLog } from '@src/api/search/search';
import { Link } from 'react-router-dom';
import { isExternalUrl, HOSTNAME } from '@src/utils';
import { IRecentlyCompany, getRecentlyShowCompany } from '@src/api/my';
import ListItemTitle from '../../components/common/listItem/ListItemTitle';
import ListItemTags from '../../components/common/listItem/ListItemTags';
import ListItemThumb from '../../components/common/listItem/ListItemThumb';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';

interface ICategory {
	title: string;
	items: {
		name: string;
		link: string;
	}[];
	keyName: string;
}
const Category = ({title, items, keyName}: ICategory) => {
	return (
		<section className="category-section">
			<h3 className="category-section-title">{title}</h3>
			<ul>
				{items.map((item, idx) => (
				<li key={`${keyName}-${idx}`} onClick={() => window.location.href = item.link}>
					{item.name}
				</li>
				))}
			</ul>
		</section>
	)
}

const categorys = {
	category1: {
		title: '결혼준비를 시작하는 품목',
		items: [
			{name: '🏛 웨딩홀', link: `${HOSTNAME}/category/2`},
			{name: '🎥 스튜디오', link: `${HOSTNAME}/category/3`},
			{name: '👗 드레스', link: `${HOSTNAME}/category/4`},
			{name: '💄 메이크업', link: `${HOSTNAME}/category/5`},
			{name: '📷 스냅', link: `${HOSTNAME}/category/12`},
			{name: '🎬 DVD', link: `${HOSTNAME}/category/13`},
		]
	},
	category2: {
		title: '웨딩촬영 전 준비해야하는 품목',
		items: [
			{name: '💍 예물', link: `${HOSTNAME}/category/8`},
			{name: '🤵 예복', link: `${HOSTNAME}/category/15`},
			{name: '🎀 한복', link: `${HOSTNAME}/category/6`},
			{name: '🏝 신혼여행', link: `${HOSTNAME}/category/19`},
			{name: '🗺 신혼여행지', link: `${HOSTNAME}/category/36`}
		]
	},
	category3: {
		title: '신혼집 입주를 위한 품목',
		items: [
			{name: '🖥 가전', link: `${HOSTNAME}/category/23`},
			{name: '🛋 가구', link: `${HOSTNAME}/category/20`},
			{name: '🎁 예단', link: `${HOSTNAME}/category/22`},
		]
	},
	category4: {
		title: '본식 전 준비해야하는 품목',
		items: [
			{name: '🙇 폐백', link: `${HOSTNAME}/category/14`},
			{name: '💐 부케', link: `${HOSTNAME}/category/16`},
			{name: '💌 청첩장', link: `${HOSTNAME}/category/21`},
			{name: '🚌 버스/셔틀', link: `${HOSTNAME}/category/30`},
			{name: '🛍 답례품', link: `${HOSTNAME}/category/28`}
		]
	},
	category5: {
		title: '기타 품목',
		items: [
			{name: '👒 패션', link: `${HOSTNAME}/category/32`},
			{name: '💅 뷰티', link: `${HOSTNAME}/category/29`}
		]
	}
}
type TCategorys = keyof typeof categorys;
const Search = () => {
	const query = useUrlQueryString();
	const productId = query.get('productId');
	const tab = useState<ITab[]>([{tabId: 1, tabTitle: '검색'}, {tabId: 2, tabTitle: '카테고리'}])[0];
	const [currentTabId, setCurrentTabId] = useState(1);
	const [isLoadingKeyword, setIsLoadingKeyword] = useState(false);
	const [isLoadingRecommendation, setIsLoadingRecommendation] = useState(false);
	const [keywordLogs, setKeywordLogs] = useState<ISearchLog[]>([]);
	const [recommendationKeywords, setRecommendationKeywords] = useState<string[]>([]);
	const [recentlyItems, setRecentlyItems] = useState<IRecentlyCompany[]>([]);
	const removeAllSearchLog = () => {
		deleteAllSearchLog();
		setKeywordLogs([]);
	}
	const removeSearchLog = (id: number) => {
		deleteSearchLog(id);
		setKeywordLogs(keywordLogs.filter(log => log.id !== id));
	}
	useEffect(() => {
		// 최근 검색 기록
		getSearchLog().then(res => {
			setKeywordLogs(res.data.item);
			setIsLoadingKeyword(true);
		});
		// 추천 검색어
		getRecommendationKeyword(productId).then(res => {
			setRecommendationKeywords(res.data.item);
			setIsLoadingRecommendation(true);
		});
		// 최근 본 업체
		getRecentlyShowCompany().then(res => {
			setRecentlyItems(res.data.item);
		})
	}, []);
	
	// const tooltip = useRef<HTMLSpanElement>(null)
	// useEffect(() => {
	// 	const handler = () => {
	// 		if (!tooltip.current) return;
	// 		const sy = window.scrollY || window.document.body.scrollTop || window.pageYOffset;
	// 		const body = document.body;
	// 		if (body.clientHeight + sy > body.scrollHeight * 0.9) {
	// 			tooltip.current.classList.add('show')
	// 		} else {
	// 			tooltip.current.classList.remove('show');
	// 		}
	// 	}
	// 	window.addEventListener('scroll', handler);
	// 	return () => {
	// 		window.removeEventListener('scroll', handler);
	// 	}
	// }, []);
	return (
		<div>
			<div className="search-top-wrap">
				<SearchHeader />
			</div>
			<PageTab 
				tab={tab}
				currentTabId={currentTabId}
				onChange={tabId => setCurrentTabId(tabId)}
			/>
			{currentTabId === 1 &&
			<>
			<section className="search-section">
				<div className="search-section-title-wrap">
					<h3 className="search-section-title">최근 검색어</h3>
					<span onClick={removeAllSearchLog} role="button">전체삭제</span>
				</div>
				{(keywordLogs.length === 0 && isLoadingKeyword) &&
				<p className="empty-serach-list">최근 검색 내역이 없습니다.</p>
				}
				<ul className="recently-search-list-wrap search-tag-list-wrap">
					{keywordLogs.map(log => (
					<li key={`my-recently-log-${log.id}`}>
						<Link to={`/search/result?searchKeyword=${log.content}${productId ? `&productId=${productId}` : ''}`}>
							{log.content}
						</Link>
						<i className="icon icon-x-gray500-18" onClick={() => removeSearchLog(log.id)}></i>
					</li>
					))}
				</ul>
			</section>
			<section className="search-section">
				<div className="search-section-title-wrap">
					<h3 className="search-section-title">추천 검색어</h3>
				</div>
				{(recommendationKeywords.length === 0 && isLoadingRecommendation) &&
				<p className="empty-serach-list">추천 검색어가 없습니다.</p>
				}
				{recommendationKeywords.length > 0 &&
				<ul className="recommendation-search-list-wrap search-tag-list-wrap">
					{recommendationKeywords.map((keyword, kIdx) => (
					<li key={`recommendation-keyword-${kIdx}`}>
						<Link to={`/search/result?searchKeyword=${keyword}${productId ? `&productId=${productId}` : ''}`}>
							{keyword}
						</Link>
					</li>
					))}
				</ul>
				}
			</section>
			<section className="search-section">
				<div className="search-section-title-wrap">
					<h3 className="search-section-title">최근 본 업체</h3>
					<span onClick={() => {
						window.location.href = isExternalUrl(`${HOSTNAME}/my-interest/recent`);
					}} role="button">전체보기</span>					
				</div>
				<ul className="recently-show-list-wrap">
					{recentlyItems.map(item => (
					<li>
						<a href={`h2mwbell://partner/${item.uuid}`}>
							<ListItemThumb thumbUrl={item.coverUrl} ratio={133.33} isLike={item.isLike} />
							<ListItemTitle title={item.name} subTitle={item.region ? item.region : '-'} />
							<ListItemTags tags={item.benefits.map(benefit => ({name: benefit.badge}))}/>
						</a>
					</li>
					))}
				</ul>
			</section>
			</>
			}
			{currentTabId === 2 &&
			<div className="category-section-wrap">
			{Object.keys(categorys).map(key => (
			<Category 
				title={categorys[key as TCategorys].title}
				items={categorys[key as TCategorys].items}
				keyName={`category-${key}`}
				key={`category-${key}`}
			/>
			))}
			<span 
				className="need-recommendation-tooltip show"  
				// ref={tooltip} 
				role="button"
				onClick={() => window.location.href = isExternalUrl('https://prd-wbapp-webview.h2m.io/product/79?appHeader=hide')}
			>업체 추천이 필요하신가요?</span>
			</div>
			}
		</div>
	);
};

export default Search;