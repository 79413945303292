import { CSSProperties } from 'react';
import './pageTitle.scss';

interface BookingTitleProps {
	title: string;
	type?: string;
	style?: CSSProperties;
}
const PageTitle = ({title, type, style}: BookingTitleProps) => {
	return (
		<div className="page-title-wrap" style={style ? style : {}}>
			<h2>{title}</h2>
			{type &&
			<div className="title-type"><span>*</span>{type}</div>
			}
		</div>
	);
};

export default PageTitle;