import './partnerInquiryModal.scss';
import { UserContext } from '@src/App';
import { blockCommunity } from '@src/api/community/community';
import { getPartnerInquiry, postPartnerInquiry } from '@src/api/partner/partner';
import { useContext, useState } from 'react';

interface Params {
	partnerUuid: string;
	closeModal: () => void;
}
const PartnerInquiryModal = ({partnerUuid, closeModal}: Params) => {
	const user = useContext(UserContext);
	const [content, setContent] = useState('');
	if (!user.me) return null;
	const closeInquiry = () => {
		setContent('');
		closeModal();
	}
	const sendInquiry = () => {
		if (content === '') {
			alert('문의 내용을 입력해주세요.');
			return;
		}
		postPartnerInquiry(partnerUuid, content).then(_ => {
			alert('등록 되었습니다.');
			closeInquiry();
		});
	}
	return (
		<>
		<div className="community-report-content-wrap">
			<div className="report-top">
				<div className="report-top-left">
					<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0}} onClick={closeInquiry}>
						<i className="icon icon-arrow-back-black"></i>
					</button>
					<h2 className="report-title">문의하기</h2>
				</div>
				<div className="report-top-right">
					<button className="btn btn-icon btn-transparent btn-tight btn-auto-height" style={{padding: 0}} onClick={sendInquiry}>
						<i className="icon icon-checkmark-gray900"></i>
					</button>
				</div>
			</div>
			<textarea 
				className="textarea" 
				onChange={e => setContent(e.target.value)}
				value={content}
				placeholder={`개인정보가 담긴 경우 개인정보 보호를 위해 삭제됩니다.
상세한 견적이 필요하다면 하단의 예약을 진행해 주세요.`}></textarea>
		</div>
		</>
	);
};

export default PartnerInquiryModal;