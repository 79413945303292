import { logEvent } from '@src/firebase';
import { isExternalUrl } from '../../../utils';
import './goalButton.scss';

interface Props {
	id: number;
	items: {
		buttonLink: string;
		imgUrl: string;
		order: number;
		title: string;
	}[];
	page: string;
	pageSub?: string;
}
function GoalButtonList({id, page, pageSub, items}: Props) {
	// if list length less than 3 = align left
	// else flex space-between
	return (
		<ul className="goal-button-list-wrap">
			{items.map((item, idx) => (
			<li key={`goal-list-${id}-${idx}-${item.title}`}>
				<a href={isExternalUrl(item.buttonLink)} onClick={() => {
					const obj = {
						page: page,
						pageSub: pageSub,
						component: 'goal_button',
						behavior: 'click',
						title: item.title
					};
					if (pageSub === undefined) delete obj.pageSub;
					logEvent('component_behavior', obj);
				}}>
					<div className="icon icon-goal" style={{backgroundImage: `url(${item.imgUrl})`}} />
					<span className="goal-name">{item.title}</span>
				</a>
			</li>
			))}
		</ul>
	);
}

export default GoalButtonList;