import React, { useState, useEffect } from 'react';
// router
import { useNavigate } from 'react-router-dom';
// hooks
import { useSignEmail } from '@src/utils/hooks/useSignInput';
import { useSignValidation } from '@src/utils/hooks/useSignInput';
import useSignInputEvent from '@src/utils/hooks/useSignInputEvent';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import WrapTitle from '@src/components/common/wrap/WrapTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import InputTypeBox from '@src/components/common/inputTypeBox/InputTypeBox';

interface FindPWProps {
    email: string;
}

// 비밀번호 찾기 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();

    // input Event
    const { signValueSetter, signEnterKeyEvent } = useSignInputEvent();

    // keydown 이벤트 핸들러
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		signEnterKeyEvent(e, formatValid, handleNext)
	}

    // 다음 단계 이동 트리거
    const [nextTrigger, setNextTrigger] = useState(false);

    // 이메일
    const signEmail = useSignEmail({dataValidCheckType: 'findPassword'});

    // 찾기 결과값
    const [findPWResult, setFindPWResult] = useState<FindPWProps>({email: ''});

    // 페이지 입력값 유효성 확인
    const formatValid = useSignValidation([signEmail.formatValid]);
    const dataValid = useSignValidation([signEmail.dataValid]);

    // 다음 단계 이동 핸들러 
    const handleNext = async () => {
        signEmail.emailDataErrorCheck(signEmail.value).then(res => {
            setFindPWResult(res.item);
        });
    }

    // 이메일 유효성 확인 -> 다음 단계 트리거
    useEffect(() => {
        if(signEmail.dataValid) {
            setNextTrigger(true);
        } 
    }, [signEmail.dataValid]);

    // 다음 단계 트리거 -> 형식/데이터 유효성 확인
    useEffect(() => {
        if (nextTrigger) {
            if (formatValid && dataValid && findPWResult.email !== '') {
                goNext(); // 다음 단계로 이동
            } 
        }
    }, [nextTrigger, formatValid, dataValid]); //다음 버튼 클릭 시 유효성 확인

    // 형식/데이터 유효성 확인 -> 다음 단계로 이동
    const goNext = () => {
        navigate('/find-pw/complete', { state: { loginId: signEmail.value, email: findPWResult.email } }) // 작성한 이메일 전달
    }
    
	return (
		<>
            <div className="contents-wrap">
                <WrapTitle>
                    <h2>비밀번호 찾기</h2>
                </WrapTitle>
                <DataSection title="">
					<div className="form-wrap">
						<div className="form-block">
							<div className="form-title">
								<span className="title">가입 시 입력한 이메일을 입력해주세요.</span>
							</div>
							<div className="form-content">
								<InputTypeBox type="email"
                                    placeholder='이메일'
                                    inputMode='email'
                                    value={signEmail.value}
                                    onChange={signValueSetter(signEmail.setValue)}
                                    onKeyDown={handleKeyDown}
                                    resultObject={signEmail.resultObject}
                                    showResultBorder={{ 'error': true, 'success': true }}
                                    showInfoMessage={{ 'error': true, 'success': true }}
                                />
							</div>
						</div>
						</div>
				</DataSection>
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button
                        className={`btn btn-large btn-red btn-full ${!formatValid ? 'btn-disabled' : 'bold'}`}
                        disabled={! formatValid}
                        onClick={handleNext}>
                        다음
                    </button>
                </div>
            </div>
		</>
	);
};

const FindPW = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="" />}
            contentsComponent={<Contents />}
        />
    );
};

export default FindPW;