import { useContext } from 'react';
import { isExternalUrl } from '../../../utils';
import './sectionTitle.scss';
import { UserContext } from '../../../App';

export interface ITitle {
	mainTitle?: string;
	subTitle?: string;
	subTitle1?: string;
	subTitle2?: string;
	titleType: 'mainSub' | 'subMain' | 'mainSubNotitle' | 'mainSubOnlyMain' | 'subMainWithNick'
}
interface Props {
	title: ITitle;
	link?: {
		url?: string;
		name?: string;
	};
}
function SectionTitle({title, link}: Props) {
	// const {mainTitle, subTitle, titleType} = title;
	const user = useContext(UserContext);
	if (!title) return <></>;
	const mainTitle = title.mainTitle || '';
	let subTitle = title.subTitle || '';
	const titleType = title.titleType || '';
	const isMainSub = titleType.indexOf('mainSub') !== -1;
	const isSubMain = titleType.indexOf('subMain') !== -1;
	const hasNick = titleType.indexOf('WithNick') !== -1;
	if (hasNick && user.me) {
		subTitle = `${title.subTitle1} ${user.me.nick} ${title.subTitle2}`;
	}
	return (
		<>
		{(!!mainTitle || !!subTitle) &&
		<div className={`section-title-wrap ${isMainSub ? 'main-sub' : ''} ${isSubMain ? 'sub-main' : ''}`}>
			{isMainSub &&
				<>
				{mainTitle &&
				<h2 className="s-title">{mainTitle}</h2>
				}
				{subTitle &&
				<span className="s-sub-title">{subTitle}</span>
				}
				</>
			}
			{isSubMain &&
				<>
				{subTitle &&
				<span className="s-sub-title">{subTitle}</span>
				}
				{mainTitle &&
				<h2 className="s-title">{mainTitle}</h2>
				}
				</>
			}
			{(link && link.url) &&
			<a href={isExternalUrl(link.url)} className="s-link">
				{link.name}
			</a>
			}
		</div>
		}
		</>
	);
}

export default SectionTitle;
