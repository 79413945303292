import { useContext, useEffect, useState } from 'react';
import TopImg from '../../assets/images/umoment/landing/specialHall/special-hall-top@3x.png';
import axios from 'axios';
import { APELGAMO_URL, BASE_URL, CHAPEL_URL, LUVEL_URL, isExternalUrl } from '@src/utils';
import { useNavigate } from 'react-router-dom';
import Modal from '@src/components/common/modal/Modal';
import { UserContext } from '@src/App';
import { logEvent } from '@src/firebase';

const SpecialPage = () => {
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const [isUmomentUser, setIsUmomentUser] = useState(true);
	const [alertState, setAlertState] = useState(false);
	const linkReservation = (brand: 'apelgamo'|'chapel'|'luvel') => {
		if (!user.me) {
			alert('앱 사용자 정보를 찾을 수 없습니다.');
			return;
		}
		if (!isUmomentUser) {
			navigate('/umoment/signup');
			return;
		}
		logEvent('umoment_link_reservation', {
			page: 'umoment_landing_special_hall',
			brand: brand
		})
		const tel = user.me.tel.replace(/-/gi, '');
		switch (brand) {
			case 'apelgamo':
				window.location.href = isExternalUrl(APELGAMO_URL + `/counsel/counselBizArea?hpNo=${tel}&name=${user.me.customerName}`)
			break;
			case 'chapel':
				window.location.href = isExternalUrl(CHAPEL_URL + `/counsel/counselBizArea?hpNo=${tel}&name=${user.me.customerName}`)
			break;
			case 'luvel':
				window.location.href = isExternalUrl(LUVEL_URL + `/counsel/counselBizArea?hpNo=${tel}&name=${user.me.customerName}`)
			break;
		}
	}
	const linkSignup = () => {
		axios.get<{item: any}>(`${BASE_URL}/web/v3/specialHall/20231115/user`).then(res => {
			console.log(res.data.item);
			if (res.data.item.success) {
				setAlertState(true);
			} else {
				navigate('/umoment/signup');
			}
		}).catch(err => {
			navigate('/umoment/signup');
		});
	}
	useEffect(() => {
		logEvent('umoment_page_behavior', {
			page: 'umoment_landing_special_hall',
			behavior: 'page_view'
		});
		axios.get<{item: any}>(`${BASE_URL}/web/v3/specialHall/20231115/user`).then(res => {
			console.log(res.data.item);
			if (res.data.item.success) {
				setIsUmomentUser(true);
			} else {
				setIsUmomentUser(false);
			}
		}).catch(_ => {
			setIsUmomentUser(false);
		})
	}, []);
	return (
		<div className="special-hall-page">
			<div className="special-hall-top">
				{/* <div className="top-title-wrap">
					<span>더채플 / 아펠가모 / 루벨 웨딩홀</span>
					<h2>웨딩홀 스마트예약 특별관</h2>
				</div> */}
				<img src={TopImg} />
				{/* <p className="top-text">
					웨딩홀 견적이 비밀은 아니잖아?!<br/>
					간편 가입하고, 잔여타임, 견적 알아보자!
				</p> */}
			</div>
			<ul className="hall-list-wrap">
				<li className="chapel">
					<h3><span>The Chapel</span></h3>
					<button className="btn btn-large btn-round btn-link-reservation" onClick={() => linkReservation('chapel')}>더채플 예약하러가기 <i className="icon icon-chevron-right-18-gray900"></i></button>
				</li>
				<li className="apelgamo">
					<h3><span>Apelgamo</span></h3>
					<button className="btn btn-large btn-round btn-link-reservation" onClick={() => linkReservation('apelgamo')}>아펠가모 예약하러가기 <i className="icon icon-chevron-right-18-gray900"></i></button>
				</li>
				<li className="luvel">
					<h3><span>Luvel</span></h3>
					<button className="btn btn-large btn-round btn-link-reservation" onClick={() => linkReservation('luvel')}>루벨 예약하러가기 <i className="icon icon-chevron-right-18-gray900"></i></button>
				</li>
			</ul>
			<div className="btn-wrap" style={{display: isUmomentUser ? 'none' : 'block'}}>
				<button className="btn btn-full btn-large btn-brown" onClick={linkSignup}>유모멘트 3초만에 가입하기</button>
			</div>
			<Modal
				theme="brown"
				isShow={alertState}
				text={'이미 가입 이력이 있는 사용자입니다.'}
				onClose={() => {
					setAlertState(false);
				}}
			/>
		</div>
	);
};

export default SpecialPage;