import './redBoxList.scss';

interface Props {
	perLine?: number;
	width?: number;
	items: {origin: string; data: string}[];
	removeItem: (item: string) => void;
}
const RedBoxList = ({perLine = 1, width = 55, items, removeItem}: Props) => {
	const generateStyles = () => {
		if (perLine === 1) {
			return {width: width + '%'};
		}
		if (perLine > 1) {
			return {display: 'flex-line', width: `calc(${(100 / perLine)}% - 28px)`}
		}
	}
	return (
		<ul className="red-box-list-wrap">
			{items.map(item => (
			<li key={`red-box-list-${item.origin}`} style={generateStyles()}>
				{item.data}
				<button className="btn btn-auto-height btn-tight btn-transparent" onClick={() => removeItem(item.origin)}>
					<i className="icon icon-x-red-18" />
				</button>
			</li>
			))}
		</ul>
	);
};

export default RedBoxList;