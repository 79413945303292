import { useState, useEffect, useContext } from 'react';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import BookingTitle from '../../../components/booking/bookingTitle/BookingTitle';
import DataSection from '@src/components/form/dataSection/DataSection';
import NotiBox from '../../../components/common/notiBox/notiBox';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bookingHall, bookingHallData, setCustomer } from '@src/@slice/bookingHallSlice';
import { postReservation } from '@src/api/booking/booking';
import { UserContext } from './../../../App';
import { autoHypenPhone, isExternalUrl } from '@src/utils';
import AgreementModal from './../../../components/booking/agreementModal/AgreementModal';
import { bookingHallEndPage } from './../../../@slice/bookingHallSlice';
import { chkPhoneTel } from './../../../utils/index';


const StepCustomerInfo = () => {
	const isComplete = useSelector(bookingHall).isComplete;
	const origin = useSelector(bookingHall).origin;
	const endPage = useSelector(bookingHallEndPage);
	const hallData = useSelector(bookingHallData);
	const dispatch = useDispatch();
	const customer = hallData.customer;
	const [isShowModal, setIsShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [validate, setValidate] = useState(false);
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const applyService = () => {
		if (loading) return;
		const customerUuid = user.me ? user.me.uuid : null;
		if (agreement) {
			setLoading(true);
			postReservation({
				customerUuid: customerUuid,
				origin: origin,
				...hallData
			}).then(res => {
				setLoading(false);
				navigate('/booking/hall/complete');
			}).catch(err => {
				setLoading(false);
			});
		}
	}
	const goNext = () => {
		if (endPage === 'customer-info') {
			setIsShowModal(true)
		} else {
			navigate('/booking/hall/hope-date');
		}
	}
	useEffect(() => {
		const customer = hallData.customer;
		if (
			customer.tel.trim() === '' ||
			customer.name.trim() === '' ||
			customer.mateTel.trim() === '' ||
			customer.mateName.trim() === '' ||
			!chkPhoneTel(customer.tel) ||
			!chkPhoneTel(customer.mateTel)
		) {
			setValidate(false);
		} else {
			setValidate(true);
		}
	}, [hallData.customer]);
	useEffect(() => {
		if (isComplete) {
			window.location.href = 'h2mwbell://webview/close';
		}
		if (user.me) {
			if (user.me.sex && customer.role === '') {
				dispatch(setCustomer({key: 'role', data: user.me.sex === 1 ? 'BRIDE' : 'GROOM'}));
			}
			if (user.me.customerName && customer.name === '') {
				dispatch(setCustomer({key: 'name', data: user.me.customerName}));
			}
			if (user.me.tel) {
				dispatch(setCustomer({key: 'tel', data: user.me.tel}));
			}
			if (user.me.mateTel && customer.mateTel === '') {
				dispatch(setCustomer({key: 'mateTel', data: user.me.mateTel}));
			}
			if (user.me.mateName && customer.mateName === '') {
				dispatch(setCustomer({key: 'mateName', data: user.me.mateName}));
			}
		}
	}, []);
	return (
		<div>
			{/* <AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} /> */}
			<BookingTitle 
				title={`고객님의 정보를 
				알려주세요.`}
			/>
			<DataSection title="신청자와 배우자의 정보를 입력해 주세요.">
				<div className="form-wrap">
					<div className="form-block">
						<div className="form-title">
							<span className="title">본인선택</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<ul className="my-role-list-wrap">
								<li className={customer.role === 'BRIDE' ? 'on' : ''} onClick={() => dispatch(setCustomer({key: 'role', data: 'BRIDE'}))}>
									신부
								</li>
								<li className={customer.role === 'GROOM' ? 'on' : ''} onClick={() => dispatch(setCustomer({key: 'role', data: 'GROOM'}))}>
									신랑
								</li>
							</ul>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="text"
								className="input-text input-full"
								placeholder="이름" 
								defaultValue={customer.name}
								onChange={e => dispatch(setCustomer({key: 'name', data: e.target.value}))}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="tel"
								className="input-text input-full"
								placeholder="01012345678"
								defaultValue={autoHypenPhone(customer.tel)}
								value={autoHypenPhone(customer.tel)}
								readOnly={user.me !== null}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									dispatch(setCustomer({key: 'tel', data: tel}));
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && customer.tel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 이름</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input
								type="text"
								className="input-text input-full"
								placeholder="예비 배우자 이름"
								defaultValue={customer.mateName}
								onChange={e => dispatch(setCustomer({key: 'mateName', data: e.target.value}))}
							/>
						</div>
					</div>
					<div className="form-block">
						<div className="form-title">
							<span className="title">예비 배우자 전화번호</span>
							<div className="necessary"><span>*</span>필수 입력 사항</div>
						</div>
						<div className="form-content">
							<input 
								type="tel" 
								className="input-text input-full" 
								placeholder="01012345678" 
								defaultValue={autoHypenPhone(customer.mateTel)}
								value={autoHypenPhone(customer.mateTel)}
								onChange={e => {
									const tel = autoHypenPhone(e.target.value);
									dispatch(setCustomer({key: 'mateTel', data: tel}));
								}}
								onKeyDown={e => {
									if (e.key.toLowerCase() !== 'backspace' && customer.mateTel.length >= 13) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
				</div>
			</DataSection>
			<NotiBox>
				<p>웨딩홀 혜택 적용을 위해 반드시 실명으로 적어주세요!</p>
			</NotiBox>
			<div style={{height: 20}}></div>
			<div className="btn-wrap">
				<button 
					disabled={!validate} 
					className={`btn bold btn-large btn-full btn-red ${!validate ? 'btn-disabled' : ''}`} 
					onClick={goNext}
				>
					다음
				</button>
			</div>
			<AgreementModal
				agreement={agreement}
				setAgreement={setAgreement}
				isShowModal={isShowModal}
				setIsShowModal={setIsShowModal}
				onApply={applyService}
			/>
			{/* <BottomFixedModal
				isShow={isShowModal}
				title="이용 동의"
				hideModal={() => setIsShowModal(false)}
			>
				<ul className="agreement-text-wrap">
					<li>혜택 적용을 위해 해당 웨딩홀에 웨딩북 고객으로 등록되며, 이를 통해 웨딩홀 캐시백 혜택을 받을 수 있습니다.</li>
					<li>잔여타임, 견적 안내 시에는 방문 일정 예약이 필수 진행되며, 예약 시간은 변경이 가능합니다.</li>
				</ul>
				<div className="agreement-check-wrap">
					<Checkbox 
						id="agreement"
						name="agreement"
						value="agreement"
						setValue={() => setAgreement(!agreement)}
						checked={agreement}
					/>
					
					<label className="agreement-label">
						<a href={isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80')}>개인정보 수집</a> 및 <a href={isExternalUrl('https://www.wdgbook.com/agreement?name=%EC%9B%A8%EB%94%A9%ED%99%80')}>제3자 활용</a>을 모두 읽었으며 이에 동의합니다.
					</label>
				</div>
				<div style={{padding: '20px 0'}}>
					<button disabled={!agreement} className={`btn btn-large btn-full btn-red ${!agreement ? 'btn-disabled' : ''}`} onClick={applyService}>서비스 신청하기</button>
				</div>
			</BottomFixedModal> */}
		</div>
	);
};

export default StepCustomerInfo;