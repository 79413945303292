import { format } from "date-fns";
import { useEffect, useState } from "react";

// 비밀번호 입력 정보
interface PasswordProps {
  resultObject?: { [key: string]: string };
}

// 비밀번호 정보 컴포넌트
export const PasswordInfo = ({ resultObject = { result: '', type: '', label: '', message: '' } }: PasswordProps) => {
  const { label: errorType = '', type: validType = '', result: formatResult = '' } = resultObject;
  const [formatResultType, setFormatResultType] = useState('');

  // 결과값 변경 시
  useEffect(() => {
    if (validType === 'formatValid' || validType === 'all') {
      if (formatResult === 'success') {
          setFormatResultType('success');
      } else if (formatResult === 'error') {
          setFormatResultType('error');
      } else {
          setFormatResultType('');
      }
    } else {
      setFormatResultType('');
    }
  }, [resultObject]);


  return (
    <b>
      <span className={(formatResultType === 'success') ? 'success' : ''}>
          {(formatResultType === 'success') ? <i className="icon icon-checkmark-green500"></i> : <i className="icon icon-dot-gray300"></i>}
          영문 포함
        </span>
        <span className={(formatResultType === 'success') ? 'success' : ''}>
          {(formatResultType === 'success') ? <i className="icon icon-checkmark-green500"></i> : <i className="icon icon-dot-gray300"></i>}
          숫자 포함
        </span>
        <span className={(formatResultType === 'success') ? 'success' : ''}>
          {(formatResultType === 'success') ? <i className="icon icon-checkmark-green500"></i> : <i className="icon icon-dot-gray300"></i>}
          6자리 이상
        </span>
    </b>
  );
}

export const PasswordPlaceholder = '영어, 숫자 조합 6자리 이상';
