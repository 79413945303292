import { useEffect } from 'react';
import StepComplete from '../../../components/common/stepComplete/StepComplete';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import { UserContext } from './../../../App';
import { useDispatch } from 'react-redux';
import { setIsComplete } from '@src/@slice/recommendHallSlice';

const Complete = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsComplete({bool: true}));
	}, []);
	const linkChat = () => {
		window.location.href = 'h2mwbell://chat';
	}
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<StepComplete 
				title={`짝짝짝!
				웨딩홀 추천 요청이
				접수되었습니다.`}
				text={`1:1 맞춤형 웨딩홀 추천 리스트를 작성하여
				순차적으로 발송될 예정이니 조금만 기다려 주세요.
				
				리스트 발송 후 카카오 알림톡으로 알려드릴 예정이며,
				웨딩북 앱 채팅창에서 확인하실 수 있습니다.`}
			/>
			<UserContext.Consumer>
				{user => (
					<>
					{user.me && <button className="btn btn-red btn-fixed-circle" onClick={linkChat}>채팅 확인</button>}
					</>
				)}
			</UserContext.Consumer>
			
		</div>
	);
};

export default Complete;