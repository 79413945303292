import { useState, useEffect, useContext } from 'react';
import { UserContext } from '@src/App';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setData, setEndPage, setInPath, setOrigin, setPartnerUuids } from '@src/@slice/bookingHallSlice';
import { duplicateReservation, getReservation } from './../../../api/booking/booking';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import Alert from './../../../components/common/alert/Alert';
import Modal from '@src/components/common/modal/Modal';

const Index = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const dispatch = useDispatch();
	const query = useUrlQueryString();
	const partnerUuids = query.get('partnerUuids');
	const origin = query.get('origin');
	const inpath = query.get('inpath');
	const [isDuplicate, setIsDuplicate] = useState(false);
	
	useEffect(() => {
		// init
		const uuidArr = partnerUuids ? partnerUuids.split(',') : [];
		if (user.me === null && !user.updated) return;
		if (user.me) {
			(async () => {
				let item: any = null;	
				if (uuidArr.length === 1) {
					const {data} = await duplicateReservation(partnerUuids, user.me.uuid);
					console.log(data.result);
					if (data.result === 409) {
						setIsDuplicate(true);
						return;
					}
				}
				getReservation(user.me.uuid).then(res => {
					const obj = res.data.item;
					item = res.data.item;
					if (obj !== null) {
						delete obj.uuid;
						delete obj.regTsp;
						delete obj.id;
						obj.hopeVisit = {
							additionalInquiry: '',
							dates: []
						};
						obj.partnerUuids = [];
						if (partnerUuids) {
							obj.partnerUuids = partnerUuids?.split(',');	
						}
						dispatch(setData({data: obj}));
					} else {
						if (partnerUuids) {
							partnerUuids.split(',').forEach(uuid => {
								dispatch(setPartnerUuids({uuid: uuid}));
							});
						}
					}
				}).finally(() => {
					if (inpath) {
						dispatch(setInPath({inpath: inpath}));
					}
					if (origin) {
						dispatch(setOrigin({origin: origin.substring(0, 10)}));
					}
					if (partnerUuids) {
						if (!inpath) {
							dispatch(setInPath({inpath: 'VISIT'}));
						}
						if (item) {
							dispatch(setEndPage({page: 'visit-date'}));
							navigate('/booking/hall/customer-info', {replace: true});	
						} else {
							navigate('/booking/hall/hope-date', {replace: true});	
						}
					} else {
						navigate('/booking/hall/select', {replace: true});
					}
				});
			})();
		} else {
			if (inpath) {
				dispatch(setInPath({inpath: inpath}));
			}
			if (origin) {
				dispatch(setOrigin({origin: origin.substring(0, 10)}));
			}
			if (partnerUuids) {
				if (!inpath) {
					dispatch(setInPath({inpath: 'VISIT'}));
				}
				partnerUuids.split(',').forEach(uuid => {
					dispatch(setPartnerUuids({uuid: uuid}));
				});
				navigate('/booking/hall/hope-date', {replace: true});	
			} else {
				navigate('/booking/hall/select', {replace: true});
			}
		}
	}, [user]);
	return (
		<div>
			<Modal
				isShow={isDuplicate}
				overlay={true}
				overlayValue={0.5}
				text={`이미 신청이 완료된 웨딩홀입니다.
				추가 요청은 웨딩홀 문의내역에서 말씀해주세요.`}
				buttons={[
					{
						name: '문의내역 바로가기',
						className: 'btn-red',
						action: () => {
							navigate('/booking/management/list', {replace: true});
						}
					}
				]}
			/>
		</div>
	);
};

export default Index;