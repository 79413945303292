import { APPLE_LOGIN_APP_KEY, LOGIN_REDIRECT_URI } from '@src/utils/index';
import LoadingSpinner from '@src/components/common/loading/LoadingSpinner';
import { useEffect } from 'react';
import { useRef } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useState } from 'react';

declare global {
  interface Window {
    AppleID: any; 
  }
}

const SignInUpApple = () => {

  const client_id = APPLE_LOGIN_APP_KEY //REST API KEY
  const redirect_uri = LOGIN_REDIRECT_URI //Redirect URI

  const timer = useRef<NodeJS.Timeout | null>(null);
  let count = useRef<number>(0);
  const [error, setError] = useState<string>('');
  let loadingTime = useRef<number>(0);

  const AppleLoginButton = () => {
    return (
      <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: APPLE_LOGIN_APP_KEY,
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: `${LOGIN_REDIRECT_URI}?brand=APPLE`, 
            /** State string that is returned with the apple response */
            state: 'state',
            /** Nonce */
            nonce: 'nonce',
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren="Continue with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(response) => console.log(response)} // default = undefined
            /** Called upon signin error */
            onError={(error) => console.error(error)} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            render={(props) => <button {...props} className={`btn btn-icon btn-transparent btn-tight btn-auto-height`}>
              <i className="icon icon-sign-apple"></i>
            </button>}
        />
    )
  }
  
  const appleLogin = () => {
    if (loadingTime.current === 1) {
      setError('Time out error.');
      return false;
    }
    if (count.current > 10) {
      setError('AppleID is not loaded yet.');
      return false;
    }
    try {
      if (window.AppleID && window.AppleID.auth) { 
        if (timer.current) {
            clearTimeout(timer.current);
        }
        window.AppleID.auth.init({
            clientId: client_id,
            scope: 'email',
            redirectURI: `${redirect_uri}?brand=APPLE`,
            state: 'state',
            nonce: 'nonce',
            usePopup: false,
          });
        window.AppleID.auth.signIn();
      } else {
        timer.current = setTimeout(appleLogin, 1000);
        count.current++;
      }
    } catch (error) {
      setError(error.message);
    }
  }

  useEffect(() => {
    appleLogin();
    setTimeout(() => {
      loadingTime.current = 1;
    }, 20000); // 20초
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);
  
  return (
    <>
      {error ?
        <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '20px',
        }}>
          <AppleLoginButton />
          <p>에러가 발생했습니다. 다시 시도해주세요.</p>
          {error && <p>에러 메세지 : {error}</p>}
      </div> : <LoadingSpinner />}
    </>
	);
};

export default SignInUpApple;