import { Fragment, useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import CompanyInfo from '../../../components/booking/management/CompanyInfo';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import useTextareaAutoHeight from '@src/utils/hooks/useTextareaAutoHeight';
import { IHallReservationByChannelUuidRes, getHallReservationByChannelUuid, HallReservationAPI, IChatListItem } from '@src/api/booking/booking';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { UserContext } from '@src/App';
import dayjs from 'dayjs';
import { isExternalUrl } from '@src/utils';
import useOnLoadedAllImages from './../../../utils/hooks/useOnLoadedAllImages';
import { useThrottle } from '@src/utils/hooks/useThrottle';

const BookingManagementChat = () => {
	const user = useContext(UserContext);
	const query = useUrlQueryString();
	const throttle = useThrottle();
	const channelUuid = query.get('channelUuid');
	const scrollRef = useRef<HTMLDivElement>();
	const isLoadedAllImages = useOnLoadedAllImages(scrollRef);
	const [chatData, setChatData] = useState('');
	const textareaRef = useTextareaAutoHeight(chatData);
	const [reservationData, setReservationData] = useState<IHallReservationByChannelUuidRes|null>(null);
	const [chatListMap, setChatListMap] = useState<{[key in string]: IChatListItem[]}>({});
	const getChatList = async (withAutoScroll: boolean = true) => {
		const {data: chatListData} = await HallReservationAPI.getChat(channelUuid, user.me.uuid);
		let imgHeight = 0;
		// 분으로 이차원 배열로 만들어서 쓰자.
		// UI단에서 column-reverse로 역으로 보여지게 했으니 reverse 사용
		const arr = chatListData.item.reverse().reduce((acc, current) => {
			const regTsp = current.regTsp.substring(0, current.regTsp.length-2);
			acc[regTsp] = acc[regTsp] || [];
			acc[regTsp].push(current);
			if (current.type === 2) {
				const size = current.data.split(',');
				if (size.length >= 2) imgHeight += +size[1];
			}
			return acc;
		}, {});
		setChatListMap(arr);
		if (withAutoScroll && isLoadedAllImages) scrollToBottom(imgHeight);
	}
	const prepareData = async () => {
		try {
			const {data: rsvData} = await getHallReservationByChannelUuid(channelUuid, user.me.uuid);
			setReservationData(rsvData.data);
			getChatList();
		} catch (e) {
			console.log(e);
		} finally {

		}
	}
	const sendMessage = () => {
		console.log('send')
		if (!user.me || chatData.length === 0) return;
		HallReservationAPI.postChat(channelUuid, user.me.uuid, {
			type: 1,
			content: chatData
		}).then(res => {
			console.log(res);
			setChatData('');
			getChatList();
		})
	}
	const scrollToBottom = (allImgHeight: number = 0) => {
        if (scrollRef.current) {
			scrollRef.current.scrollTo({top: scrollRef.current.scrollHeight, behavior: 'smooth'});
        }
    };
	useEffect(() => {
		if (!user.me) return;
		if (!channelUuid) return;
		prepareData(); 
	}, [user, channelUuid]);
	useEffect(() => {
		// polling chat
		if (!user.me) return;
		let polling = setInterval(async () => {
			await getChatList(false);
		}, 3000);
		return () => {
			clearInterval(polling);
		}
	}, [user.me]);
	return (
		<>
		<AppHeader type="stack" actionItems={[{name: 'close'}]} />
		<div className="page-booking-management-chat-wrap">
			<CompanyInfo info={reservationData} />
			<div className="chat-list-wrap" ref={scrollRef}>
				{Object.keys(chatListMap).map((key, keyIdx) => {
					const keys = Object.keys(chatListMap);
					return (
					<Fragment key={`chat-box-time-${key}`}>
					{chatListMap[key].map((chat, idx) => {
						const prevIdx = keyIdx - 1 >= 0 ? keyIdx-1 : 0;
						const regTsp = dayjs(chat.regTsp).set('second', 0);
						const prevRegTsp = dayjs(chatListMap[keys[prevIdx]][0]?.regTsp).set('second', 0);
						return (
							<div className={`chat-box-wrap ${chat.b2cUserId !== null ? 'user' : 'company'}`} key={`chat-box-${chat.b2cUserId}-${chat.id}`}>
								{(regTsp.set('h', 0).set('minutes', 0).format('YYYY-MM-DD') !== prevRegTsp.set('h', 0).set('minutes', 0).format('YYYY-MM-DD')) &&
								<span className="chat-date">{regTsp.format('YYYY년 MM월 DD일 dddd')}</span>
								}
								<div className="chat-box">
								{chat.type === 1 && chat.content.trim()}
								{chat.type === 2 && <img src={chat.url} alt="" /> }
								{chat.type === 3 &&
								<div>
									<p>{chat.content.trim()}</p>
									<button className="btn btn-line-dark btn-has-padding-x" onClick={() => window.location.href = isExternalUrl(chat.url)}>{chat.data}</button>
								</div>
								}
								</div>
								{idx === 0 &&
								<span className="chat-time">{regTsp.format('A hh:mm')}</span>
								}
							</div>
						)
					})}
					</Fragment>
					)	
				})}
			</div>
			<div className="input-chat-wrap">
				<textarea 
					className="textarea no-margin textarea-has-line"
					placeholder="추가 문의사항을 입력해주세요."
					rows={1}
					ref={textareaRef}
					value={chatData}
					onChange={e => setChatData(e.target.value)}
				></textarea>
				<button className="btn btn-red" onClick={throttle(sendMessage, 800)}>전송</button>
			</div>
		</div>
		</>
	);
};

export default BookingManagementChat;