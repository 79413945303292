import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { UserContext } from "@src/App";
import { TOSS_CLIENT_KEY } from "@src/utils/toss";

const RealTimeTossPay = () => {
    const user = useContext(UserContext);
    const params = useParams<{uuid: string}>();
    const data =  JSON.parse(sessionStorage.getItem('reservationData'));
    const hallName = sessionStorage.getItem('hallName')

    const [paymentWidget, setPaymentWidget] = useState<any>(null);

    useEffect(() => {
        (async () => {
            const customerKey = user.me ? user.me.uuid : ANONYMOUS;
            const paymentWidget = await loadPaymentWidget(TOSS_CLIENT_KEY,customerKey);

            paymentWidget.renderPaymentMethods("#paymentWidget",  data.paymentAmount,  { variantKey: "LINK-PAY-REALTIME" });
            setPaymentWidget(paymentWidget);
        })();
    }, []);


    const handlePaymentRequest = async () => {
        const reqData = {
            amount: data.paymentAmount,
            orderId: params.uuid,
            orderName: `${data.brideName}신부님의 결제내역`,
            customerName: user.me ? user.me.customerName : data.brideName,
            successUrl: `${window.location.origin}/booking/real-time/tosspay/success?name=${hallName}`,
            // failUrl: `${window.location.origin}/booking/real-time/tosspay/fail?uuid=${data.uuid}`,
            failUrl: `${window.location.origin}/booking/real-time/tosspay/fail?`,
        };

        paymentWidget.requestPayment(reqData)
        .catch(err => {
            alert(err.code)
            window.location.reload();
        })
      };

    return (
        <div>
            <div id="paymentWidget" />
            <div className="btn-wrap">
                <button className="btn bold btn-full btn-large btn-red"
                    onClick={handlePaymentRequest}>
                    결제하기
                </button>
            </div>
        </div>
    );
};

export default RealTimeTossPay;
