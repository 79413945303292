import { useEffect, useState } from 'react';
import {chunk} from 'lodash';
import SectionTitle, { ITitle } from '../../common/sectionTitle/SectionTitle';
import './wegazine.scss';
import Slider from 'react-slick';
import { logEvent } from '@src/firebase';
// import axios from 'axios';

type ItemProps = {
	uuid: string;
	page: string;
	pageSub?: string;
	imgUrl: string;
	title: string;
	subTitle?: string;
};

const WegazineItem = ({
	uuid,
	page,
	pageSub,
	imgUrl,
	title,
	subTitle
}: ItemProps) => {
	return (
		<a href={`h2mwbell://wegazine/${uuid}`} className="row-wegazine" onClick={() => {
			const obj = {
				page: page,
				pageSub: pageSub,
				component: 'wegazine',
				behavior: 'click',
				title: title
			};
			if (pageSub === undefined) delete obj.pageSub;
			logEvent('component_behavior', obj);
		}}>
			<div
				className={`row-item-bg ${!imgUrl ? 'no-image' : ''}`}
				style={{ backgroundImage: `url(${`${imgUrl}`})` }}
			/>
			<div className="row-item-info-wrap">
				<span className="item-sub">{subTitle}</span>
				<span className="item-name">{title}</span>
			</div>
		</a>
	);
};

type IItem = {
	imgUrl: string;
	subTitle: string;
	title: string;
	mainTag: string;
	uuid: string;
};

type IData = {
	buttonLink: string;
	buttonText: string;
	bottom: string;
	item: IItem[];
	title: ITitle
};

type Props = {
	id: number;
	page: string;
	pageSub?: string;
	data: IData;
};

export default function Wegazine({ id, page, pageSub, data }: Props) {
	const SETTING = {
		infinite: true,
		speed: 500,
		centerMode: true,
		centerPadding: '14px'
	};
	return (
		<section className="wegazine-wrap">
			<SectionTitle
				title={data.title}
				link={{ url: data?.buttonLink!, name: data?.buttonText! }}
			/>
			<div>
				<Slider {...SETTING}>
					{chunk(data?.item, 3).map((items, idx) => {
						return (
							<div className="slider-group-wrap" key={`wegazine-group-${id}-${idx}`}>
								{items.map(item => {
									return (
										<WegazineItem
											uuid={item.uuid}
											page={page}
											pageSub={pageSub}
											subTitle={item.subTitle}
											title={item.title}
											imgUrl={item.imgUrl}
											key={`wegazine-${id}-${idx}-list-${item.uuid}`}
										/>
									);
								})}
							</div>
						);
					})}
				</Slider>
			</div>
		</section>
	);
}