import { useCallback, useRef } from 'react';

export const useThrottle = () => {
	const isWaiting = useRef(false);

	return useCallback(
		(callback: (...arg: any) => void, delay: number) =>
			(...arg: any) => {
				//대기 상태라면 아무것도 하지 않고, 대기상태가 아니라면 아래 코드 실행
				if (!isWaiting.current) {
					callback(...arg);
					isWaiting.current = true;
					setTimeout(() => {
						isWaiting.current = false;
					}, delay);
				}
			},
		[],
	);
};