// 공통 컴포넌트로 분리 가능성 있음.
export type DataListItem = {key: string; value: string; keyClassName?: string; valueClassName?: string;};
const DataList = ({list}: {list: DataListItem[]}) => {
	return (
		<div className="data-list-wrap">
			{list.map(data => (
			<div key={`data-list-${data.key}`}>
				<span className={`data-list-key ${data.keyClassName || ''}`}>{data.key}</span>
				<span className={`data-list-value ${data.valueClassName || ''}`}>{data.value}</span>
			</div>
			))}
		</div>
	)
}

export default DataList;