import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

// 동의 항목 필수 여부 
export interface AgreementRequired {
    terms: boolean;
    privacyPolicy: boolean;
    marketing: boolean;
    applianceBenefits: boolean;
    over14: boolean;
}
export const agreementRequired: AgreementRequired = {
    terms: true,
    privacyPolicy: true,
    marketing: false,
    applianceBenefits: false,
    over14: true,
}

// 가입 경로 
type SignUpSource = ('지인 추천' | '앱스토어/플레이스토어 검색' | '네이버 검색' | '구글 검색' | '블로그' | '카페' | '유튜브' | '인스타그램' | '페이스북');
export const signUpSources: SignUpSource[] = [
    '지인 추천', '앱스토어/플레이스토어 검색', '네이버 검색', '구글 검색', '블로그', '카페', '유튜브', '인스타그램', '페이스북'
];

// 관심 항목
type InterestedItems = ('웨딩홀' | '스드메' | '신혼여행' | '예복' | '한복' | '예물' | '예단' | '가전' | '가구');
export const interestedItems: InterestedItems[] = [
    '웨딩홀', '스드메', '신혼여행', '예복', '한복', '예물', '예단', '가전', '가구'
];

// 개인정보
interface PersonalInfo {
    [key: string]: any;
    loginId: null | string;
    loginIdVerified: null | boolean;
    password: null | string;
    confirmPassword: null | string;
    sex: null | '2' | '1'; // 2 male 1 female
    name: null | string;
    tel: null | string;
    telVerified: null | boolean;
    nick: null | string;
}

type WeddingDateInfo =
    null 
    | { weddingDate: string; hopeWeddingDate?: never }
    | { weddingDate?: never; hopeWeddingDate: string };
  
type WeddingVenueInfo =
    null 
    | { weddingVenue: string; hopeWeddingVenue?: never }
    | { weddingVenue?: never; hopeWeddingVenue: string };

// 예식 정보
interface WeddingInfo {
    [key: string]: any;
    mateName: null | string;
    mateTel: null | string;
    weddingTsp: WeddingDateInfo;
    region: null | string;
    weddinghallName: WeddingVenueInfo;
}

// 추가정보
interface ExtralInfo {
    [key: string]: any;
    interestItems: null | InterestedItems[];
    hasPlanner: null | boolean;
    weddingbookPlanner: null | boolean;
    inPathId: null | SignUpSource;
    invitationCode: null | string;
}

// 회원가입 데이터
interface Data {
    [key: string]: any;
    personalInfo: PersonalInfo;
    agreement: {
        [K in keyof AgreementRequired]: boolean;
    };
    weddingInfo: WeddingInfo;
    extraInfo: ExtralInfo
}

// 회원가입 상태
export interface SignUpState {
    data: Data;
}

// 초기 상태
const initialState = {
    data: {
        personalInfo: {
            loginId: null,
            loginIdVerified: null,
            password: null,
            confirmPassword: null,
            sex: null,
            name: null,
            tel: null,
            telVerified: null,
            nick: null,
        },
        agreement: {
            terms: false,
            privacyPolicy: false,
            marketing: false,
            applianceBenefits: false,
            over14: false,
        },
        weddingInfo: {
            mateName: null,
            mateTel: null,
            weddingTsp: null,
            weddingTspType: null,
            region: null,
            weddinghallName: null,
        },
        extraInfo: {
            interestItems: null,
            hasPlanner: null,
            weddingbookPlanner: null,
            inPathId: null,
            invitationCode: null,
        },
    }
} as SignUpState

const SignUpSlice = createSlice({
	name: 'signUp',
	initialState,
    reducers: {
        setResetData(state) {
            state.data = initialState.data;
        },
        setData(state, action: PayloadAction<{ data: any }>) {
            for (let key in action.payload.data) {
                if (state.data.hasOwnProperty(key)) {
                    state.data[key] = { ...state.data[key], ...action.payload.data[key] };
                }
            }
        },
        setPersonalInfo(state, action: PayloadAction<{ data: any }>) {
            state.data.personalInfo = { ...state.data.personalInfo, ...action.payload.data };
        },
        setWeddingInfo(state, action: PayloadAction<{ data: any }>) {
            state.data.weddingInfo = { ...state.data.weddingInfo, ...action.payload.data };
        },
        setExtraInfo(state, action: PayloadAction<{ data: any }>) {
            state.data.extraInfo = { ...state.data.extraInfo, ...action.payload.data };
        },
        setAgreementInfo(state, action: PayloadAction<{ data: any }>) {
            state.data.agreement = { ...state.data.agreement, ...action.payload.data };
        },
    }
});

export const { 
    setData, setResetData,
    setPersonalInfo, setExtraInfo, setWeddingInfo, setAgreementInfo } = SignUpSlice.actions;
export const SignUp = (state: RootState) => state.signUp; // 회원가입 상태
export const SignUpData = (state: RootState) => state.signUp.data;
export default SignUpSlice.reducer;

