import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import Parser, {ComponentItem} from '../../components/parser/Parser';
import { BASE_URL } from '../../utils/index';
import AppHeader from '../../components/common/appHeader/AppHeader';
import PageTab, { ITab } from '../../components/home/pageTab/PageTab';

const ProductPreview = () => {
	const [title, setTitle] = useState('');
	const [allData, setAllData] = useState<ComponentItem[]|null>(null);
	const [data, setData] = useState<ComponentItem[]|null>(null);
	const [tab, setTab] = useState<ITab[]>([]);
	const [currentTabId, setCurrentTabId] = useState<number|null>(0);
	const { productId } = useParams<{productId: string}>();
	useEffect(() => {
		axios.get(BASE_URL + `/v3/product/component/${productId}/preview`).then((res: any) => {
			const d: {item: ComponentItem[], tab: ITab[], title: string} = res.data;
			setAllData(d.item);
			setTab(d.tab);
			setTitle(d.title);
			setCurrentTabId(d.tab.length > 0 ? d.tab[0].tabId : null);
		});
	}, [productId]);
	useEffect(() => {
		if (allData) {
			const filteredData = allData.filter(item => item.tabId === currentTabId);
			setData(filteredData);
		}
	}, [currentTabId])
	return (
		<>
		<AppHeader type="product" actionItems={[
			{name: 'search'},
			// {name: 'like'}
		]} />
		{tab.length > 0 &&
		<PageTab 
			tab={tab}
			currentTabId={currentTabId}
			onChange={tabId => setCurrentTabId(tabId)}
		/>
		}
		<div className="page page-product" style={{paddingBottom: 60}}>
			{data && <Parser data={data} page={`${title}-preview`} />}
		</div>
		</>
	);
};

export default ProductPreview;