import './index.scss';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// redux
import { Provider } from 'react-redux';
import { store } from './store';

// ga 
import ReactGA from 'react-ga4';

import reportWebVitals from './reportWebVitals';
import { GA_TRACKING_ID } from './utils';
console.log('GA_TRACKING_ID : ', GA_TRACKING_ID);
if (GA_TRACKING_ID) ReactGA.initialize(GA_TRACKING_ID);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</StrictMode>
);
// ReactDOM.render(
// 	<StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</StrictMode>,
// 	document.getElementById('root')
// );

reportWebVitals();
function isiOS() {
	return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
	// iPad on iOS 13 detection
	|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
if ('serviceWorker' in navigator) { // 서비스 워커 지원 확인
	if (isiOS()) {
		navigator.serviceWorker.getRegistrations().then(function (registrations) {
			for (const registration of registrations) {
				console.log('serviceWorker unregistered');
				registration.unregister();
			}
		});
	} else {
		window.addEventListener('load', function () { // 브라우저 로드가 완료되면
			navigator.serviceWorker.register('/sw.js'); // 서비스 워커 등록
		});
	}
}
