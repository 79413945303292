import './myCompany.scss';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL, isExternalUrl } from '../../../utils/index';
import { logEvent } from '@src/firebase';

type VisibleType = 'like' | 'recent';
interface InterestedCompanyProps {
	id: number;
	page: string;
	pageSub?: string;
	partnerType: string;
	typeName: string;
}
interface IItem {
	address: string | null;
	address2: string | null;
	coverUrl: string;
	name: string;
	uuid: string;
	region: string;
	tags: {
		0:{name: string},
		1:{name:string;}
	};
}
interface IRes {
	item: IItem[];
}
export const EmptyList = ({type}: {type: VisibleType}) => {
	return (
		<div className="empty-company">
			<p>
				<b>{type === 'like' ? '관심있는' : '최근 본'} 업체가 없어요.</b>
				{type === 'like' ? '마음에 드는 업체에 좋아요를 눌러보세요.' : '업체들을 둘러보세요.'}
			</p>
		</div>
	)
}
function InterestedCompany({id, page, pageSub, partnerType, typeName}: InterestedCompanyProps) {
	const ul = useRef<HTMLUListElement>(null);
	const [type, setType] = useState<VisibleType>('like');
	const [likeItems, setLikeItems] = useState<IItem[]>([]);
	const [recentlyItems, setRecentlyItems] = useState<IItem[]>([]);
	useEffect(() => {
		axios.get<IRes>(`${BASE_URL}/v3/component/200205/partner/my/like/${partnerType}?fetchStart=0&fetchSize=20`).then(res => {
			setLikeItems(res.data.item);
		});
		axios.get<IRes>(`${BASE_URL}/v3/component/200205/partner/my/recent/${partnerType}?fetchStart=0&fetchSize=20`).then(res => {
			setRecentlyItems(res.data.item);
		});
	}, []);
	useEffect(() => {
		if (ul.current) {
			ul.current.scrollTo(0, 0);
		}
	}, [type]);
	return (
		<section className="my-company-wrap">
			<div className="my-company-top">
				<ul className="my-company-type-list-wrap">
					<li className={type === 'like' ? 'on' : ''} onClick={() => setType('like')}>
						관심있는
					</li>
					<li className={type === 'recent' ? 'on' : ''} onClick={() => setType('recent')}>
						최근 본
					</li>
				</ul>
				<a href={isExternalUrl(`${window.location.origin}/my-interest/${type}?tabId=${partnerType}`)} className="link-all">전체보기</a>
			</div>
			<ul className="my-company-list-wrap" ref={ul}>
			{type === 'like' &&
				(likeItems.length > 0
				? likeItems.map((item, idx) => {
					return (
					<li key={`like-${item.uuid}-${idx}`}>
						<a href={`h2mwbell://partner/${item.uuid}`} onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'my_favorite_company',
								behavior: 'click',
								content: item.name
							};
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
						}}>
							<div className="company-thumb" style={{backgroundImage: `url(${item.coverUrl})`}}></div>
							<div className="company-info">
								<span className="company-position">{item.region ? item.region : ' '}</span>
								<b className="company-name">{item.name}</b>
							</div>
						</a>
					</li>
					);
				})
				: <EmptyList type="like" />
				)
			}
			{(type === 'recent' && recentlyItems !== null) &&
				(recentlyItems.length > 0
				? recentlyItems.map(item => {
					return (
					<li key={`like-${item.uuid}`}>
						<a href={`h2mwbell://partner/${item.uuid}`} onClick={() => {
							const obj = {
								page: page,
								pageSub: pageSub,
								component: 'my_recent_company',
								behavior: 'click',
								content: item.name
							};
							if (pageSub === undefined) delete obj.pageSub;
							logEvent('component_behavior', obj);
						}}>
							<div className="company-thumb" style={{backgroundImage: `url(${item.coverUrl})`}}></div>
							<div className="company-info">
								<span className="company-position">{item.region ? item.region : ' '}</span>
								<b className="company-name">{item.name}</b>
							</div>
						</a>
					</li>
					);
				})
				: <EmptyList type="recent" />
				)
			}
			</ul>
			{(
				(type === 'like' && likeItems.length > 0) ||
				(type === 'recent' && recentlyItems.length > 0)
			) &&
			<button 
				className="btn btn-large btn-white btn-round btn-with-icon btn-find" 
				onClick={() => window.location.href = `h2mwbell://partner?categoryType=${partnerType}`}
			>
				<span>
					<span className="bold">{typeName}</span> 찾기
				</span>
				<i className="icon icon-next-arrow-circle-24" />
			</button>
			}
		</section>
	);
}

export default InterestedCompany;