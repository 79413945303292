import './loadingSpinner.scss';


const LoadingSpinner = () => {
	return (
        <>
            <div className='loading-spinner'>
                <div className="spinner">
                    <span className=""></span>
                </div>
            </div>
        </>
	);
};

export default LoadingSpinner;