import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import Parser, {ComponentItem} from '../components/parser/Parser';
import { BASE_URL } from '../utils/index';
import AppHeader from '../components/common/appHeader/AppHeader';
import PageTab, { ITab } from '../components/home/pageTab/PageTab';
import { logEvent } from '@src/firebase';
import { useDispatch } from 'react-redux';
import { setNeedLogin } from '@src/@slice/globalSlice';

const Product = () => {
	const [title, setTitle] = useState('');
	const [tabTitle, setTabTitle] = useState('');
	const [allData, setAllData] = useState<ComponentItem[]|null>(null);
	const [data, setData] = useState<ComponentItem[]|null>(null);
	const [tab, setTab] = useState<ITab[]>([]);
	const [currentTabId, setCurrentTabId] = useState<number|null>(0);
	const { productId } = useParams<{productId: string}>();
	const getAlias = async () => {
		if (isNaN(Number(productId))) {
			const {data} = await axios.get<{item: number}>(BASE_URL + `/v3/alias/${productId}`);
			console.log(data);
			return data.item;
		}
		return productId;
	}
	const getData = async () => {
		const id = await getAlias();
		axios.get(BASE_URL + `/v3/product/component/${id}`).then((res: any) => {
			const d: {item: ComponentItem[], tab: ITab[], title: string} = res.data;
			setAllData(d.item);
			setTab(d.tab);
			setTitle(d.title);
			setCurrentTabId(d.tab.length > 0 ? d.tab[0].tabId : null);
		});
	}
	useEffect(() => {
		getData();
	}, [productId]);
	useEffect(() => {
		if (allData) {
			const filteredData = allData.filter(item => item.tabId === currentTabId);
			setData(filteredData);
		}
	}, [currentTabId]);
	useEffect(() => {
		if (tab.length > 0) {
			const filteredTab = tab.filter(t => t.tabId === currentTabId);
			if (filteredTab.length === 0) return;
			setTabTitle(filteredTab[0].tabTitle);
		}
	}, [tab, currentTabId]);
	useEffect(() => {
		// console.log(title + ' / ' + tabTitle);
		if (title === '' || tabTitle === '') return;
		logEvent('page_behavior', {
			page: title,
			pageSub: tabTitle,
			behavior: 'page_view'
		});
	}, [title, tabTitle]);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setNeedLogin({bool: true}));
		return () => {
			dispatch(setNeedLogin({bool: false}))
		}
	}, []);
	return (
		<>
		<AppHeader title={title} type="product" actionItems={[
			{name: 'search'},
			// {name: 'like'}
		]} />
		{tab.length > 0 &&
		<PageTab 
			tab={tab}
			currentTabId={currentTabId}
			onChange={tabId => setCurrentTabId(tabId)}
		/>
		}
		<div className="page page-product" style={{paddingBottom: 80}}>
			{data && <Parser data={data} page={title} pageSub={tabTitle} />}
		</div>
		</>
	);
};

export default Product;