import {IFaq, IIntroItem, IProgram} from "../../types/interface/Chungdam";
import CalendarIcon from '../../assets/images/chungdam/icCalendar@3x.png'
import ClockIcon from '../../assets/images/chungdam/icTimer@3x.png'
import PriceIcon from '../../assets/images/chungdam/icPrice@3x.png'
import MemoIcon from '../../assets/images/chungdam/icMemo@3x.png'
import DressAndBooks from '../../assets/images/chungdam/imgAiaMainDressnbooks@3x.png'
import Terminal from '../../assets/images/chungdam/imgAiaMainTerminal@3x.png'
import HonsuMarket from '../../assets/images/chungdam/honsu.png'
import Fitting from '../../assets/images/chungdam/imgAiaMainDressFitting@3x.png'
import SdmCuration from '../../assets/images/chungdam/imgAiaMainSdmCuration@3x.png'

export const intros: IIntroItem[] = [
    {
        miniTitle: 'Dress&Books',
        title: '웨딩상담 & 웨딩앨범 라이브러리 & 웨딩드레스',
        floor: 'B1F',
        imageUrl: DressAndBooks,
        description: '웨딩북 프로와 스드메 상담부터 결혼 전반에 대한 체크까지 궁금증과 막막함을 해소할 수 있는 공간입니다. \n' +
            '또한 다양한 업체의 샘플 화보와 50여벌의 신상 웨딩드레스를 한자리에서 만나볼 수 있습니다. \n' +
            '원하는 스타일에 맞춰 골라주는 큐레이션 패드를 이용해 스드메 화보를 살펴보세요.',
    },
    {
        miniTitle: 'Terminal',
        title: '웨딩홀 & 웨딩북몰 & 청첩장',
        floor: '1F',
        imageUrl: Terminal,
        description: '예비부부에게 요즘 핫한 인기 웨딩홀 리스트와 정보를 한눈에 확인해볼 수 있는 공간입니다. \n' +
            '또한 웨딩북몰에 입점 되어있는 업체의 샘플들과 청첩장 샘플도 만나볼 수 있습니다.',
    },
    {
        miniTitle: 'Apartment',
        title: '혼수마켓',
        floor: '2F',
        imageUrl: HonsuMarket,
        description: '예물, 예복, 한복, 가전, 가구, 허니문 등 파트너사의 상담을 진행하는 공간입니다. \n' +
            '함께하는 브랜드는 제품 퀄리티, 서비스 마인드, 분야 전문성 등에서 웨딩북이 신뢰하고 \n' +
            '업계 파트너로서의 경험과 소비자 시각을 종합해 웨딩북 MD팀이 선정한 대표 파트너사입니다. \n' +
            '웨딩북과 파트너사의 신뢰 관계를 바탕으로 최고 수준의 가격 혜택을 제공하고 있습니다. \n' +
            '혼수마켓에서 상담을 받아보시고 다양한 혜택을 만나보세요.',
    },
]

export const programs: IProgram[] = [
    {
        miniTitle: '01 Wedding Curating',
        title: '웨딩북 프로와 함께 하는 \n맞춤 웨딩 상담',
        imageUrl: SdmCuration,
        description: '웨딩북 프로는 스드메 스타일링에 특화된 결혼 준비 전문가입니다. 다년간의 플래너 경험을 바탕으로 생생한 업체 정보는 물론 개별 상황에 따른 스페셜 케어까지 탁월한 도움을 드리고 있습니다. 웨딩 상담을 통해 스드메뿐만 아니라 웨딩홀로 시작하는 전체 결혼준비 과정도 명쾌하게 알아갈 수 있습니다.',
        items: [
            {icon: ClockIcon, title: '60분'},
            {icon: CalendarIcon, title: '평일 13:00 ~ 21:00', colorLabel: '*라스트 상담 19:00'},
            {icon: '', title: '주말/공휴일 10:00 ~ 21:00', colorLabel: '*라스트 상담 19:00'},
            {icon: MemoIcon, title: '매주 월요일 휴무'},
        ]
    },
    {
        miniTitle: '02 Dress Fitting',
        title: '탁월한 선택을 도와주는 \n드레스 피팅',
        imageUrl: Fitting,
        description: '50여벌의 신상 드레스 중 2벌을 골라 입어볼 수 있는 프로그램입니다. \n드레스샵을 고르기 전 나의 취향과 내게 어울리는 스타일을 미리 점검해 볼 수 있습니다. \n드레스 투어 시 망설임 없이 선택할 수 있도록 상주하는 드레스 전문가가 나의 니즈를 섬세하게 파악해 드립니다. \n 자유로운 사진은 덤. \n*드레스 피팅은 계약자 혜택 서비스 입니다.',
        items: [
            {icon: ClockIcon, title: '45분'},
            {icon: CalendarIcon, title: '주말/공휴일 12:00 ~ 19:00', colorLabel: '*라스트 피팅 18:00'},
            {icon: PriceIcon, title: '20,000원'},
            {icon: MemoIcon, title: '피팅 가능 사이즈 44 ~ 66'},
        ]
    },
]

export const faqs: IFaq[] = [
    {
        question: '방문예약을 꼭 해야하나요?',
        answer: '네, 편안하고 쾌적한 경험을 위해 시간 당 방문인원을 20팀으로 제한하고 있습니다. 인원 확인을 위해 예약제로 운영하고 있으니 미리 예약 바랍니다.'
    },
    // {
    //     question: '이미 준비는 끝나가는데 가면 할 게 있나요?',
    //     answer: '네, 그럼요. 2층 아파트먼트는 신혼집을 모티브로 꾸민 공간으로 가전, 가구, 인테리어 소품 등을 보며 다양한 영감을 얻기에 적합합니다. 여행책과 콘텐츠를 보며 이미 결정한 신혼여행지에 대한 구체적인 상상과 계획을 해보는 데이트 공간으로도 좋습니다.'
    // },
    {
        question: '꼭 커플로 2명이 가야 하나요? 혼자 가도 되나요?',
        answer: '혼자 오셔도 괜찮습니다. 동반인은 1인까지만 가능하며 예비 배우자가 아닌 어머니, 자매, 친구 등 지인과 함께 오셔도 무방합니다.'
    },
    {
        question: '주차는 발렛 되나요?',
        answer: '주차공간은 있으나 발렛서비스는 제공하지 않습니다. 웨딩북 청담 주차장은 사전예약제로 운영중이오니 사전에 예약을 부탁드립니다. 희망 시간에 만석으로 예약이 불가할 경우 인근 \'언북초교 공영주차장\'을 이용하시기 바랍니다. (도보 5분거리, 5분당 300원)'
    },
    {
        question: '방문 가능한 요일, 시간이 어떻게 되나요?',
        answer: '평일은 오후 1시부터 밤 9시까지, 주말과 공휴일은 오전 10시부터 밤 9시까지 운영합니다. 마지막 방문 예약은 오후 8시까지 가능하며, 상시 운영하는 공간으로 매주 월요일만 휴무입니다.'
    },
]
