import React, {useState, useCallback, useEffect} from 'react';

interface Props {
	className?: string;
	type: string;
	value: any;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	buttonComponent?: React.ReactNode;
	iconComponent?: React.ReactNode;
	infoComponent?: React.ReactNode;
	showInfoMessage?: { 'error' : boolean, 'success' : boolean }; 
	showResultBorder?: { 'error' : boolean, 'success' : boolean }; 
	resultObject?: { [key: string]: string };
	[key: string]: any;
}

// input 타입 박스
const InputTypeBox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {

	// Props
	const {
		className, type, value,
		buttonComponent, iconComponent, infoComponent,
		readOnly,
		onChange, onBlur,
		resultObject = {result : '', type: '', message: ''},
		showResultBorder = { 'error': false, 'success': false },
		showInfoMessage = { 'error': false, 'success': false }, 
		...otherProps
	} = props;

	// type 
	const [inputType, setInputType] = useState(type);

	// 결과값 (에러, 성공)
	const [result, setResult] = useState({} as {[key:string]:string});
	const [resultTypeCheck, setResultTypeCheck] = useState('');

	// input 초기화 (x 버튼 클릭)
	const handleClearInput = useCallback((e:React.MouseEvent<Element>) => {
		let input = e.currentTarget.parentElement.querySelector('input');
		onChange && onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
		onBlur && onBlur({ target: input } as React.FocusEvent<HTMLInputElement>);
    }, [onChange, onBlur]);

	// input 타입 변경 (password <-> text) 
    const handleToggleInputType = useCallback(() => {
        setInputType(inputType === 'password' ? 'text' : 'password');
	}, [inputType]);

	// 결과값 변경 시
	useEffect(() => {
		if (resultObject.result !== result.result || resultObject.type !== result.type || resultObject.message !== result.message) {
			setResult(resultObject);
		}
	}, [resultObject]); 

	// 결과값에 따른 결과 타입 변경
	useEffect(() => {
		if (result.result === 'error') {
			setResultTypeCheck('error');
		} else if (result.result === 'success') {
			setResultTypeCheck('success');
		} else {
			setResultTypeCheck('');
		}
	}, [result]);

	// input focus 시 화면 이동 --> 안드로이드에서만 적용 (임시)
	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		let input = e.currentTarget;
		if (! /iPhone/.test(navigator.userAgent)) {
			setTimeout(() => {
				if (input.type !== 'readonly') {
					input.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
				}
			}, 300);
		} 
	}

	return (
		<div className="input-type-box-wrap">
			<div className="input-type-box-field">
				<div className="input-type-box">
					<input type={inputType} 
						ref={ref}
						className={`input-full input-text 
							${className} 
							${(showResultBorder[resultTypeCheck] && (resultTypeCheck && result.message)) ? resultTypeCheck : ''}`
						}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={(e) => handleFocus(e)}
						{...otherProps}
						readOnly={type === 'readonly' || readOnly}
						inputMode={type === 'readonly' ? 'none' : props.inputMode}
					/>
					{type === 'search' && <i className="icon icon-search-black"></i>}
					{(type === 'password' || type === 'search') && value !== '' && <i className="icon icon-x-circle-gray" onClick={handleClearInput}></i>}
					{type === 'password' && <i className={`icon ${inputType === 'password' ? 'icon-eye-on-gray900' : 'icon-eye-off-gray900'}`} onClick={handleToggleInputType}></i>}
					{iconComponent && <div className="input-type-box-icon">{iconComponent}</div>}
				</div>
				{buttonComponent && <div className="input-type-box-button">{buttonComponent}</div>}
			</div>
			{(infoComponent || Object.values(showInfoMessage).some((v)=>v)) && <>
				<div className='input-info-text'>
					{(showInfoMessage[resultTypeCheck] && result.message && resultTypeCheck === 'error') ? <>
						<b className={resultTypeCheck}>
							{resultTypeCheck === 'error' && <i className="icon icon-notice-red700"></i>}
							<span>{result.message}</span>
						</b>
					</> : infoComponent}
					{(showInfoMessage[resultTypeCheck] && result.message && resultTypeCheck === 'success') && <>
						<b className={resultTypeCheck}>
							{resultTypeCheck === 'success' && <i className='icon icon-checkmark-circle-green500'></i>}
							<span>{result.message}</span>
						</b>
					</>}
				</div> 
			</>}
		</div>
	);
});

export default InputTypeBox;