import { useContext, useEffect, useState } from 'react';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import TextareaAutosize from 'react-textarea-autosize';
import { uploadImage } from '@src/api/common';
import { SAMSUNG_EVENT_WEGAZINE_UUID, makeArray } from '@src/utils';
import { getPartnerReview, postPartnerReview } from '@src/api/partner/partner';
import { UserContext } from '@src/App';
import { useNavigate } from 'react-router-dom';

const WriteReview = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [content, setContent] = useState('');
	const [uploadImages, setUploadImages] = useState<string[]>([]);
	const removeImage = (url: string) => {
		setUploadImages(
			uploadImages.filter(image => image !== url)
		);
	}
	const onUploadImage = async (e: any) => {
		if (uploadImages.length >= 5) return;
		const inputs = document.getElementsByClassName('input-file');
		const input = inputs[uploadImages.length] as HTMLInputElement;
		const fileTypes = ['jpg', 'jpeg', 'png', 'bmp'];
		if (input.files && input.files[0]) {
			const file = input.files[0];
			const size = file.size;
			const maxSize = 30 * 1024 * 1024; // 30MB
			const extension = file.name.split('.').pop()?.toLowerCase();
			const isSuccess = fileTypes.indexOf(extension!) > -1;

			if( size > maxSize ) {
				return;
			}

			if( isSuccess ) {
				const formData = new FormData();
				formData.append('file', input.files[0]);
				const {data} = await uploadImage(formData);
				setUploadImages([...uploadImages, data.data]);
			} else {
				// ('업로드 불가능한 첨부파일 입니다.'
			}
		}
	}

	const postReview = () => {
		if (!user.me) return;
		const data: any = {
			type: 7,
			uuid: user.me.uuid,
			content: content,
			urls: uploadImages.join(','),
			wegazineUuid: SAMSUNG_EVENT_WEGAZINE_UUID
		}
		if (uploadImages.length) data.urls = uploadImages.join(',');
		postPartnerReview(data)
		.then(res => {
			alert('등록 되었습니다.');
			navigate(-1);
		}).catch(err => {
			alert('후기 등록에 실패했습니다.');
			navigate(-1);
		});
	}
	
	return (
		<div className="partner-write-review-page">
			<AppHeader type="stack" title="후기쓰기" actionItems={[
				{name: 'check', callback: () => {
					postReview();
				}}
			]} />
			<div className="review-wrap">
				<div className="review-body">
					<TextareaAutosize
						className="textarea"
						minRows={2}
						placeholder="갖고 싶은 삼성 신혼 가전과 함께 소문내기 이벤트에 참여한 게시글 URL을 작성해주세요."
						onChange={e => setContent(e.target.value)}
					/>
					<div className={`review-uploaded-images image-length-${uploadImages.length > 2 ? 'over3' : uploadImages.length}`}>
						<ul>
							{uploadImages.map((image, idx) => (
								<li style={{backgroundImage: `url(${image})`}}>
									<span onClick={() => removeImage(image)}></span>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="review-bottom">
					<label htmlFor={`upload-${uploadImages.length}`} className="btn btn-icon">
						<i className="icon icon-review-photo"></i>
					</label>
				</div>
			</div>
			{makeArray(uploadImages.length + 1).map(item => (
				<input id={`upload-${item}`} type="file" hidden accept="image/*" className="input-file only-sr" onChange={e => onUploadImage(e)} />
			))}
		</div>
	);
};

export default WriteReview;