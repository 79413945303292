import { useEffect, useContext } from 'react';
import StepComplete from './../../components/common/stepComplete/StepComplete';
import { setIsComplete } from '@src/@slice/recommendHallSlice';
import { useDispatch } from 'react-redux';
import useUrlQueryString from '../../utils/hooks/useUrlQueryString';
import AppHeader from '@src/components/common/appHeader/AppHeader';

const HoneymoonComplete = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsComplete({bool: true}));
	}, []);
	return (
		<div>
			<AppHeader 
				type="stack"
			/>
			<StepComplete 
				title={`짝짝짝!
				신혼여행 견적 요청이
				접수되었습니다.`}
				text={`고객님께서 신청하신 내용을 면밀히 분석하여
				가장 적합한 업체를 통해 최상의 견적을 안내드리겠습니다.`}
			/>
			<div className="step-complete-noti" style={{marginTop: -20}}>
				<div>
					<span>💫</span>
					<p>
						견적 상담은 평일 위주로 진행되고 있으며,<br/>
						주말에 신청하시면 대기 시간이 발생할 수 있는 점<br/>
						참고 부탁드립니다.<br/>
						업체가 견적요청을 접수하면 실제 방문과는 무관하게<br/>
						예약 일정이 00시로 생성됩니다.
					</p>
				</div>
			</div>
			<button className="btn btn-red btn-step-complete" onClick={() => window.location.href = 'h2mwbell://webview/close'}>확인</button>
		</div>
	);
};

export default HoneymoonComplete;