const DetailImage = ({data}: any) => {
	return (
		<div className="product-detail-image-wrap">
		{(data.link || data.link2)
		? <a href={data.link2 ? data.link2 : data.link}>
			<img src={`https://imgs.h2m.io/?url=${data.imgUrl}&w=1200`} style={{marginBottom: data.bottomMargin}} />
		</a>
		: <img src={`https://imgs.h2m.io/?url=${data.imgUrl}&w=1200`} style={{marginBottom: data.bottomMargin}} />
		}
		</div>
	);
};

export default DetailImage;