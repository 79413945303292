import AppHeader from '@src/components/common/appHeader/AppHeader';
import StepComplete from '../../../components/common/stepComplete/StepComplete';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<StepComplete 
				title={`🥲
				선택하신 조건에 맞는 
				신혼여행지를 찾지 못했어요.`}
				text={`여행 시기, 컨셉, 예산을 변경하여 다시 추천 받아 보세요.`}
			/>
			<button className="btn btn-red btn-fixed-circle" onClick={() => navigate(-1)}>다시<br/> 해보기</button>
		</div>
	);
};

export default NotFound;