import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import historySlice from './@slice/historySlice';
import bookingHallSlice from './@slice/bookingHallSlice';
import bookingHomeApplianceSlicec from './@slice/bookingHomeApplianceSlicec';
import recommendHallSlice from './@slice/recommendHallSlice';
import globalSlice from './@slice/globalSlice';
import honeymoonApplySlice from './@slice/honeymoonApplySlice';
import recommendWGiftSlice from './@slice/recommendWGiftSlice';
import SignUpSlice from './@slice/SignUpSlice';
import UserSlice from './@slice/UserSlice';
import realtimeBookingSlice from './@slice/realtimeBookingSlice';

export const store = configureStore({
	reducer: {
		history: historySlice,
		global: globalSlice,
		bookingHall: bookingHallSlice,
		bookingHomeAppliance: bookingHomeApplianceSlicec,
		recommendHall: recommendHallSlice,
		recommendWGift: recommendWGiftSlice,
		honeymoonApplyState: honeymoonApplySlice,
		signUp: SignUpSlice,
		user: UserSlice,
        realtimeBooking: realtimeBookingSlice
	},
	// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
	devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;