import './dataSection.scss';

interface Props {
	title?: string;
	desc?: string;
	typeStr?: string;
	children: JSX.Element;
}
const DataSection = ({title, desc, typeStr, children}: Props) => {
	return (
		<div className="data-section-wrap">
			{title &&
			<div className="data-section-top">
				<div className={`data-title-wrap ${desc ? 'has-desc' : ''}`}>
					<h2 className="data-title">{title}</h2>
					{typeStr &&
					<div className="section-type-str"><span>*</span>{typeStr}</div>
					}
				</div>
				{desc &&
				<p className="data-desc">{desc}</p>
				}
			</div>
			}
			<div className="data-content-wrap">
				{children}
			</div>
		</div>
	);
};

export default DataSection;