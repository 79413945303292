import React, { useEffect } from 'react';
// router
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Wrap from '@src/components/common/wrap/Wrap';
import AppHeader from '../../../components/common/appHeader/AppHeader';
import WrapTitle from '@src/components/common/wrap/WrapTitle';

// 아이디 찾기 결과 페이지
const Contents = () => {

    // history
    const navigate = useNavigate();

    // location
    // const location = useLocation<{ loginId: string, name: string, brandName: null | string, type: string }>();
    const location = useLocation();
    const { loginId, name, brandName } = location.state || {};

    // 다음 단계 이동 핸들러 
    const handleNext = () => {
        goNext();
    }
    
    // 다음 단계로 이동
    const goNext = () => {
        if (brandName) navigate('/sign-in/social', { state: { brandName: brandName } }); 
        else navigate('/sign-in/email', { state: { loginId: loginId } }); //이메일 로그인 페이지로 이동 (이메일 전달)
    }

	return (
        <>
            <div className="contents-wrap">
                <WrapTitle title={<>
                    <h2>
                        짝짝짝! <br />
                        {brandName ? '계정을 찾았어요!' : '이메일을 찾았어요!'}
                    </h2>
                    <div className="gray-bg-info-box">
                        {brandName ? <>
                            <span>{name? name : '고객'}님은 {brandName} 계정으로 가입되어 있습니다. <br />
                            {brandName} 계정으로 로그인 해주세요.</span>
                        </> : <>
                            <strong>{loginId}</strong>
                            <span>인증하신 번호로 가입하신 이메일입니다.</span>
                        </>}
                    </div>
                </>} />
            </div>
            <div className="actions-wrap">
                <div className="btn-wrap">
                    <button className={`btn bold btn-large btn-red btn-full`}
                        onClick={handleNext}>완료
                    </button>
                </div>
            </div>
        </>
	);
};

const FindIDResult = () => {
    return (
        <Wrap
            className='sign-in-up-wrap'
            fullHeight={true}
            headerComponent={<AppHeader type='stack' title="" />}
            contentsComponent={<Contents />}
        />
    );
};

export default FindIDResult;