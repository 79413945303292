import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface BookingHomeApplianceState {
	origin: string | null;
	isComplete: boolean;
	data: {
		inpath: string; // 'ESTIMATE' | 'VISIT' | 'PROMOTION' | 'ETC';
		branch: string;
		customer: {
			name: string;
			tel: string;
			mateName: string;
			mateTel: string;
		}
	}
}

const initialState = {
	origin: null,
	isComplete: false,
	data: {
		// customerId: 145705,
		inpath: 'PARTNER_DETAIL',
		customer: {
			name: '',
			tel: '',
			mateName: '',
			mateTel: ''
		}
	}
} as BookingHomeApplianceState

const bookingHomeApplianceSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		setOrigin(state, action: PayloadAction<{origin: string}>) {
			state.origin = action.payload.origin;
		},
		setIsComplete(state, action: PayloadAction<{bool: boolean}>) {
			state.isComplete = action.payload.bool;
		},
		setInPath(state, action: PayloadAction<{inpath: string}>) {
			state.data.inpath = action.payload.inpath;
		},
		setData(state, action: PayloadAction<{data: any}>) {
			state.data = action.payload.data;
		},
		setCustomer(state, action: PayloadAction<{key: keyof typeof initialState.data.customer, data: string;}>) {
			const {key, data} = action.payload;
			state.data.customer[key] = data;
		}
	},
});


export const { setOrigin, setIsComplete, setInPath, setData, setCustomer } = bookingHomeApplianceSlice.actions;
export const bookingHomeAppliance = (state: RootState) => state.bookingHomeAppliance;
export const bookingHomeApplianceData = (state: RootState) => state.bookingHomeAppliance.data;
export default bookingHomeApplianceSlice.reducer;

