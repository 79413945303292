import { useContext, useEffect } from 'react';
import StepComplete from './../../components/common/stepComplete/StepComplete';
import { UserContext } from '@src/App';
import AppHeader from './../../components/common/appHeader/AppHeader';
import { SDMPackageApplyContext } from './Apply';

const SDMPackageApplyComplete = () => {
	const context = useContext(SDMPackageApplyContext);
	useEffect(() => {
		context.isComplete = true
	}, []);
	return (
		<div>			
			<AppHeader type="stack" actionItems={[{name: 'close'}]} />
			<StepComplete 
				title={`짝짝짝!
				스드메 패키지 이벤트 신청이
				완료되었습니다.`}
				text={`신청해주신 내용은 담당 웨딩플래너가 확인 후
				업무 시간 이내 순차적으로 연락드리겠습니다.
				
				감사합니다.`}
			/>
			<UserContext.Consumer>
				{user => (
					<>
					{user.me && <button className="btn btn-red btn-fixed-circle" onClick={() => window.location.href = 'h2mwbell://webview/close'}>확인</button>}
					</>
				)}
			</UserContext.Consumer>
		</div>
	);
};

export default SDMPackageApplyComplete;