const DetailBenefit = ({data, idx}: any) => {
	const benefits = data.benefits;
	return (
		<div className="company-product-benefit-wrap" style={{marginBottom: data.bottomMargin}}>
			<h3 className="product-sub-title type1"><span>{data.title}</span></h3>'
			<ul className="clearfix">
				{(benefits !== null && benefits.length > 0) &&
					<>
					{benefits.map((benefit: any, bIdx: number) => (
					<li key={`${idx}-benefit-${bIdx}`}>
						<div className="item-img" style={{backgroundImage: `url(https://imgs.h2m.io/?url=${benefit.imgUrl}&w=1200)`, filter: `progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\'https://imgs.h2m.io/?url=${benefit.imgUrl}&w=1200\', sizingMethod=\'scale\')`}}></div>
						{benefit.caption}
					</li>
					))}
					</>
				}
			</ul>
		</div>
	);
};

export default DetailBenefit;