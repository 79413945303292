import { UserContext } from '@src/App';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { useState, useEffect, useContext } from 'react';

const Complete = () => {
	const [isApp, setIsApp] = useState(true);
	const user = useContext(UserContext);
	useEffect(() => {
		setIsApp(user.me ? true : false);
	}, [user]);
	const linkReservationList = () => {
		window.location.href = 'h2mwbell://booking';
	}
	return (
		<>
		{!isApp &&
		<AppHeader type="stack" title="웨딩북" actionItems={[]} />
		}
		<div className="home-appliance-complete">
			<span className="apply-status">승인 대기중</span>
			<h2>
				방문 예약 신청이<br/>
				접수되었습니다.
			</h2>
			<p>
				예약이 확정되면 알림톡을 통해 메시지가 발송됩니다.
				반드시 <span className="bold">예약 확정 후</span> 매장에 방문해주세요.
			</p>
			<div className="notice-wrap">
				<b>꼭 확인해주세요!</b>
				<ul>
					<li>당일 방문 신청 시 웨딩북 혜택 적용이 불가할 수 있습니다.</li>
					<li>방문 시 웨딩북 통해서 방문, 예약했음을 업체에 전달주셔야만 웨딩북 별도 혜택이 적용됩니다.</li>
					<li>타 루트를 통한 진행 시 웨딩북 혜택 적용이 불가할 수 있습니다.</li>
				</ul>
			</div>
			{isApp &&
			<button className="btn bold btn-red btn-link-reservation" onClick={linkReservationList}>예약 확인하러 가기</button>
			}

			{/* <div className="btn-wrap items-2">
				<div>
					<button className="btn btn-full btn-light-red">완료</button>
				</div>
				<div>
					<button className="btn btn-full btn-red">예약 확인하러 가기</button>
				</div>
			</div> */}
			{/* h2mwbell://booking */}
		</div>
		</>
	);
};

export default Complete;