import { useState, useEffect, useContext } from 'react';
import { UserContext } from '@src/App';
import { cancelReservation } from '@src/api/booking/booking';
import { useParams } from 'react-router';
import PageTitle from '@src/components/common/pageTitle/PageTitle';
import AppHeader from './../../../components/common/appHeader/AppHeader';
import RadioButton from '@src/components/common/radioButton/RadioButton';
import useUrlQueryString from '../../../utils/hooks/useUrlQueryString';
import Modal from '@src/components/common/modal/Modal';
import { useNavigate } from 'react-router-dom';

const CANCEL_REASON = ['다른 일정으로 재신청 예정이에요.', '희망 예식 조건과 맞지 않아요.', '다른 웨딩홀로 계약했어요.', '타사 웨딩플래너와 진행중이에요.', '기타 사유예요.']
const Cancel = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const params = useParams<{uuid: string}>();
	const query = useUrlQueryString();
	const [reason, setReason] = useState('');
	const [etcReason, setEtcReason] = useState('');
	const partnerName = query.get('partnerName');
	const [isLoading, setIsLoading] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [blankModal, setBlankModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorExisitModal, setErrorExisitModal] = useState(false);
	const cancel = () => {
		if (!user.me) return;
		if (isLoading) return;
		const isEtc = reason === CANCEL_REASON[CANCEL_REASON.length-1];
		if (isEtc && etcReason === '') {
			setBlankModal(true);
			return;
		}
		setIsLoading(true);
		cancelReservation({
			customerUuid: user.me.uuid,
			uuid: params.uuid,
			content: isEtc ? etcReason : reason
		}).then(_ => {
			setSuccessModal(true);
			setIsLoading(false);
		}).catch(_ => {
			setErrorExisitModal(true);
			setIsLoading(false);
		});
	}
	return (
		<>
			<AppHeader type="stack" actionItems={[
				{name: 'close'}
			]} />
			<div className="page page-booking-cancel layout-fix-button with-app-header">
				<div>
					<PageTitle
						title={`${partnerName}
						방문 일정 취소 요청하기`}
						style={{margin: '0 -20px'}}
					/>
					<p>취소 요청 사유를 선택해주세요.</p>
					<ul className="radio-list-wrap">
						{CANCEL_REASON.map((item, idx) => (
						<li
							onClick={() => setReason(item)}
							key={`price-${idx}`}
						>
							<RadioButton 
								id={`price-${idx}`}
								name="price"
								value={item}
								setValue={() => {}}
								checked={item === reason}
							/>
							<label htmlFor={`price-${idx}`}>
								{item}
							</label>
						</li>
						))}
					</ul>
					{reason === CANCEL_REASON[CANCEL_REASON.length-1] &&
					<input 
						type="text"
						className="input-text input-full input-has-line"
						placeholder="사유를 적어주세요."
						value={etcReason}
						onChange={e => setEtcReason(e.target.value)}
					/>
					}
				</div>
			</div>
			<div className="btn-wrap">
				<button className="btn btn-full btn-large btn-red" onClick={cancel}>일정 취소 요청하기</button>
			</div>
			<Modal
				isShow={successModal}
				text={`일정 취소 요청이 접수되었습니다.
				웨딩홀 매니저가 확인 후 
				[마이웨딩 > 1:1문의] 채팅으로 알려드릴게요!`}
				onClose={() => {
					setSuccessModal(false);
					navigate(-1);
					// history.replace('/booking/hall/management?tabId=2')
				}}
			/>
			<Modal
				isShow={errorModal}
				text={`일정 취소를 실패하였습니다. [마이웨딩 > 1:1문의] 채팅을 통해 문의해주세요.`}
				onClose={() => {
					setErrorModal(false);
				}}
			/>
			<Modal
				isShow={blankModal}
				text={`사유를 입력해주세요.`}
				onClose={() => {
					setBlankModal(false);
				}}
			/>
			<Modal
				isShow={errorExisitModal}
				text={`이미 취소 요청되었습니다.
				추가 문의는 채팅창에 남겨주세요.`}
				buttons={[
					{
						name: '1:1문의 채팅으로 이동',
						className: 'btn-red',
						action: () => {
							window.location.href = 'h2mwbell://chat';
							setErrorExisitModal(false);
						}
					}
				]}
			/>
		</>
	);
};

export default Cancel;