import { NAVER_LOGIN_APP_KEY, LOGIN_REDIRECT_URI } from '@src/utils/index';
import LoadingSpinner from '@src/components/common/loading/LoadingSpinner';
import { useEffect } from 'react';

const SignInUpNaver = () => {

    const client_id = NAVER_LOGIN_APP_KEY; //Client ID
    const redirect_uri = LOGIN_REDIRECT_URI //Redirect URI
    const naverURL = `https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}?brand=NAVER&state=123`;
    
    useEffect(() => {
        window.location.href = naverURL;
    }, []);

    return (
        <>
            <LoadingSpinner />
        </>
	);
};

export default SignInUpNaver;