import {faqs, programs} from "./data";
import ProgramItem from "../../components/chungdam/ProgramItem";
import FaqItem from "../../components/chungdam/FaqItem";
import AppHeader from "../../components/common/appHeader/AppHeader";

export default () => {

    const reservation = () => window.location.assign('h2mwbell://aia/booking');

    return (
        <>
            <AppHeader type="stack" actionItems={[]}/>
            <div className={'chungdam-wrap'}>
                <div className={'content-box'}>
                    <h1>
                        나를 위한 <br/>
                        맞춤 프로그램으로 <br/>
                        나만의 결혼 준비를 <br/>
                        시작해보세요.
                    </h1>
                    {programs.map((program, index) => (
                        <ProgramItem program={program} key={index}/>
                    ))}
                </div>
                <div className={'faq-box'}>
                    <h1>FAQ</h1>
                    {faqs.map((faq, index) => (
                        <FaqItem faq={faq} key={index}/>
                    ))}
                </div>
                <div className={'fixed-btn-wrapper'}>
                    <button className={'btn btn-red btn-large btn-full bold'} onClick={reservation}>
                        예약하러 가기
                    </button>
                </div>
            </div>
        </>
    )
}




