import {
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';

// type HallInPath = 'ESTIMATE' | 'VISIT' | 'PROMOTION' | 'ETC';
// type HallDataNotObjectKey = 'inpath' | 'partnerUuids';
// type HallDataObjectKey = 'hopeWedding' | 'expectedPerson' | 'hopeVisit' | 'customer';
type WeddingRegion = {
	mainRegion: string;
	subRegions: string;
}
export interface RecommendHallState {
	isComplete: boolean;
	data: {
		customerUuid: string | null;
		inpath: string; // 'APPLICATION' | 'CHAT' | 'PRO' | 'WEB';
		origin: string | null;
		hallStyleTypes: string[];
		weddingType: string;
		weddingRegions: WeddingRegion[],
		meal: {
			types: string[];
			minPrice: number|null;
			maxPrice: number|null;
		},
		hopeWedding: {
			yearMonths: string[];
			days: string[];
			times: string[];
			additionalInquiry: string;
			alternativeSchedules: string[];
		},
		tourHopedSchedule: string;
		otherInquiry: string;
		expectedPerson: {
			min: number|null;
			max: number|null;
		},
		customer: {
			role: 'GROOM' | 'BRIDE' | '';
			name: string;
			tel: string;
			mateName: string;
			mateTel: string;
		}
	}
}

const initialState = {
	isComplete: false,
	data: {
		customerUuid: null,
		inpath: 'APPLICATION',
		origin: null,
		hallStyleTypes: [], 
		weddingType: '',
		weddingRegions: [],
		expectedPerson: {
			min: null,
			max: null
		},
		meal: {
			types: [],
			minPrice: null,
			maxPrice: null
		},
		hopeWedding: {
			yearMonths: [],
			days: [],
			times: [],
			additionalInquiry: '',
			alternativeSchedules: []
		},
		tourHopedSchedule: '',
		otherInquiry: '',
		customer: {
			role: '',
			name: '',
			tel: '',
			mateName: '',
			mateTel: ''
		}
	}
}  as RecommendHallState;

const recommendHallSlice = createSlice({
	name: 'recommend',
	initialState,
	reducers: {
		setOrigin(state, action: PayloadAction<{origin: string}>) {
			state.data.origin = action.payload.origin;
		},
		setIsComplete(state, action: PayloadAction<{bool: boolean}>) {
			state.isComplete = action.payload.bool;
		},
		setInPath(state, action: PayloadAction<{inpath: string}>) {
			state.data.inpath = action.payload.inpath;
		},
		setCustomerUuid(state, action: PayloadAction<{uuid: string}>) {
			state.data.customerUuid = action.payload.uuid;
		},
		setData(state, action: PayloadAction<{data: any}>) {
			state.data = action.payload.data;
		},
		setHallStyleTypes(state, action: PayloadAction<{type: string}>) {
			const {type} = action.payload;
			const hallStyleType = state.data.hallStyleTypes;
			if (hallStyleType.indexOf(type) > -1) {
				state.data.hallStyleTypes = hallStyleType.filter(t => t !== type);
			} else {
				state.data.hallStyleTypes.push(type);
			}
		},
		setWeddingType(state, action: PayloadAction<{type: string}>) {
			state.data.weddingType = action.payload.type;
		},
		setWeddingRegion(state, action: PayloadAction<{region: WeddingRegion}>) {
			const {region} = action.payload;
			const regions = state.data.weddingRegions;
			const isExist = regions.filter(r => r.mainRegion === region.mainRegion && r.subRegions === region.subRegions).length;
			console.log(isExist)
			if (isExist) {
				state.data.weddingRegions = regions.filter(r => !(r.mainRegion === region.mainRegion && r.subRegions === region.subRegions));
			} else {
				state.data.weddingRegions.push(region);
			}
		},
		setMealType(state, action: PayloadAction<{type: string}>) {
			const {type} = action.payload;
			const types = state.data.meal.types;
			if (types.indexOf(type) > -1) {
				state.data.meal.types = types.filter(t => t !== type);
			} else {
				state.data.meal.types.push(type);
			}
		},
		setMealPrice(state, action: PayloadAction<{min:number|null, max:number|null}>) {
			const {min, max} = action.payload;
			state.data.meal.minPrice = min;
			state.data.meal.maxPrice = max;
		},
		setYearMonths(state, action: PayloadAction<{date: string}>) {
			const {date} = action.payload;
			const yearMonths = state.data.hopeWedding.yearMonths;
			console.log('date : ', date);
			if (yearMonths.indexOf(date) > -1) {
				state.data.hopeWedding.yearMonths = yearMonths.filter(d => d !== date);
			} else {
				state.data.hopeWedding.yearMonths.push(date);
			}
		},
		setDays(state, action: PayloadAction<{day: string}>) {
			const {day} = action.payload;
			const days = state.data.hopeWedding.days;
			if (days.indexOf(day) > -1) {
				state.data.hopeWedding.days = days.filter(d => d !== day);
			} else {
				state.data.hopeWedding.days.push(day);
			}
		},
		setTime(state, action: PayloadAction<{time: string}>) {
			const {time} = action.payload;
			const times = state.data.hopeWedding.times;
			if (times.indexOf(time) > -1) {
				state.data.hopeWedding.times = times.filter(t => t !== time);
			} else {
				state.data.hopeWedding.times.push(time);
			}
		},
		setAdditionalInquiry(state, action: PayloadAction<{data: string}>) {
			console.log('recommend here');
			state.data.hopeWedding.additionalInquiry = action.payload.data;
		},
		setAlternativeSchedules(state, action: PayloadAction<{schedule: string}>) {
			const {schedule} = action.payload;
			const alternativeSchedules = state.data.hopeWedding.alternativeSchedules;
			if (alternativeSchedules.indexOf(schedule) > -1) {
				state.data.hopeWedding.alternativeSchedules = alternativeSchedules.filter(s => s!== schedule);
			} else {
				state.data.hopeWedding.alternativeSchedules.push(schedule);
			}
		},
		setExpectedPerson(state, action: PayloadAction<{min: number|null; max: number|null}>) {
			const {min, max} = action.payload;
			state.data.expectedPerson.min = min;
			state.data.expectedPerson.max = max;
		},
		setTourHopedSchedule(state, action: PayloadAction<{data: string}>) {
			state.data.tourHopedSchedule = action.payload.data;
		},
		setOtherInquiry(state, action: PayloadAction<{data: string}>) {
			state.data.otherInquiry = action.payload.data;
		},
		setCustomer(state, action: PayloadAction<{key: keyof typeof initialState.data.customer, data: string;}>) {
			const {key, data} = action.payload;
			if (key === 'role') {
				state.data.customer[key] = (data as 'GROOM' | 'BRIDE');
			} else {
				state.data.customer[key] = data;
			}
		}
	},
});


export const { 
	setOrigin, setIsComplete, setInPath, setCustomerUuid, setData, 
	setHallStyleTypes, setWeddingType, setWeddingRegion, 
	setMealType, setMealPrice, setYearMonths, setDays, setTime, 
	setAdditionalInquiry, setAlternativeSchedules, setExpectedPerson, 
	setTourHopedSchedule, setOtherInquiry,setCustomer
 } = recommendHallSlice.actions;
export const recommendHall = (state: RootState) => state.recommendHall;
export const recommendHallData = (state: RootState) => state.recommendHall.data;
export default recommendHallSlice.reducer;

