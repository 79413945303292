
import { useEffect, useState } from 'react';
// redux
import { agreementRequired } from '@src/@slice/SignUpSlice';
// components
import Checkbox from '@src/components/common/checkbox/Checkbox';
import FullFixedModal from '@src/components/common/fullFixedModal/FullFixedModal';
// utils
import { isExternalUrl } from '@src/utils';

// Props Type
interface AgreementProps {
    isShow: boolean;
    agreeStatus: typeof agreementRequired;
    setAgreeStatus: React.Dispatch<React.SetStateAction<typeof agreementRequired>>;
    showModal: React.Dispatch<React.SetStateAction<boolean>>,
    onCompleted?: (agreeStatus: {[key:string]:any}) => void;
}

// 약관 동의 페이지
const Agreement = ({isShow, agreeStatus, setAgreeStatus, showModal, onCompleted}: AgreementProps) => {

    // State
    const [allChecked, setAllChecked] = useState(false);
    const [valid, setValid] = useState(false); 

    // 전체 항목 동의 토글 핸들러
    useEffect(() => {
        const allAgreed = Object.values(agreeStatus).every((value) => value === true);
        setAllChecked(allAgreed);
    }, [agreeStatus]);

    // 개별 항목 동의 토글 핸들러
    const handlerAgreementItem = (key: string) => {
        setAgreeStatus({...agreeStatus, [key]: !agreeStatus[key]});
    }

    // 필수 항목 동의 체크
    useEffect(() => {
        setValid(true);
        for (const key in agreementRequired) {
            if (agreementRequired[key as keyof typeof agreementRequired]
                && !agreeStatus[key as keyof typeof agreeStatus]) {
                setValid(false);
                return;
            }
        }
    }, [agreeStatus]);

    // '동의하고 가입하기' 버튼의 onClick 이벤트 핸들러
    const handleAgreeAndSignUp = () => {
        if (valid) {
            onCompleted(agreeStatus);
        }
    }

    return (
        <>
            <FullFixedModal
                autoHeight={true}
                isShow={isShow}
                hideModal={() => showModal(false)}
                overlayBgColor={'rgba(0, 0, 0, .5)'}
                title={<>웨딩북을 이용하려면 <br /> 동의가 필요해요</>}
                closeButton={'icon'}>
                <div>
                    <div className="agreement-all-agree">
                        <strong>
                            <Checkbox
                                id={`all-agree`}
                                name="all-agree"
                                value={allChecked.toString()}
                                setValue={() => {
                                    setAllChecked(!allChecked);
                                    setAgreeStatus(Object.keys(agreeStatus).reduce((acc, key) => {
                                        acc[key] = !allChecked;
                                        return acc;
                                    }, {} as typeof agreeStatus));
                                }}
                                checked={allChecked}
                            />
                            <label htmlFor={`all-agree`}>약관 전체 동의</label>
                        </strong>
                    </div>
                    <div className="agreement-list">
                        <ul>
                            <li>
                                <div className="agree-item">
                                    <Checkbox
                                        id={`over14`}
                                        name="over14"
                                        value={agreeStatus.over14.toString()}
                                        setValue={() => handlerAgreementItem('over14')}
                                        checked={agreeStatus.over14}
                                    />
                                    <label htmlFor={`over14`}>(필수) 14세 이상입니다</label>
                                </div>
                            </li>
                            <li>
                                <div className="agree-item">
                                    <Checkbox
                                        id={`terms`}
                                        name="terms"
                                        value={agreeStatus.terms.toString()}
                                        setValue={() => handlerAgreementItem('terms')}
                                        checked={agreeStatus.terms}
                                    />
                                    <label htmlFor={`terms`}>(필수) 서비스 이용약관 동의</label>
                                </div>
                                <button className="agree-detail-view-icon" onClick={() => window.location.href = isExternalUrl(`https://wdgbook.com/app/info/service.html?outgoing=true`)}>
                                    <i className="icon icon-chevron-right-18-gray"></i>
                                </button>
                            </li>
                            <li>
                                <div className="agree-item">
                                    <Checkbox
                                        id={`privacyPolicy`}
                                        name="privacyPolicy"
                                        value={agreeStatus.privacyPolicy.toString()}
                                        setValue={() => handlerAgreementItem('privacyPolicy')}
                                        checked={agreeStatus.privacyPolicy}
                                    />
                                    <label htmlFor={`privacyPolicy`}>(필수) 개인정보 수집 ·이용 동의</label>
                                </div>
                                <button className="agree-detail-view-icon" onClick={() => window.location.href = isExternalUrl(`https://wdgbook.com/app/info/privacy.html?outgoing=true`)}>
                                    <i className="icon icon-chevron-right-18-gray"></i>
                                </button>
                            </li>
                            <li>
                                <div className="agree-item">
                                    <Checkbox
                                        id={`marketing`}
                                        name="marketing"
                                        value={agreeStatus.marketing.toString()}
                                        setValue={() => handlerAgreementItem('marketing')}
                                        checked={agreeStatus.marketing}
                                    />
                                    <label htmlFor={`marketing`}>(선택) 마케팅 수신</label>
                                </div>
                                <button className="agree-detail-view-icon" onClick={() => window.location.href = isExternalUrl(`https://wdgbook.com/app/info/marketing.html?outgoing=true`)}>
                                    <i className="icon icon-chevron-right-18-gray"></i>
                                </button>
                            </li>
                            <li>
                                <div className="agree-item">
                                    <Checkbox
                                        id={`applianceBenefits`}
                                        name="applianceBenefits"
                                        value={agreeStatus.applianceBenefits.toString()}
                                        setValue={() => handlerAgreementItem('applianceBenefits')}
                                        checked={agreeStatus.applianceBenefits}
                                    />
                                    <label htmlFor={`applianceBenefits`}>(선택) 가전 혜택 알림 동의</label>
                                </div>
                                <button className="agree-detail-view-icon" onClick={() => window.location.href = isExternalUrl(`https://www.wdgbook.com/app/info/homeAppliance.html?outgoing=true`)}>
                                    <i className="icon icon-chevron-right-18-gray"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="btn-wrap">
                        <button className={`btn btn-large btn-red btn-full ${valid ? 'bold' : 'btn-disabled'}`}
                            disabled={valid ? false : true}
                            onClick={() => {
                                handleAgreeAndSignUp();
                            }}>동의하고 가입하기</button>
                    </div>
                </div>
            </FullFixedModal>
        </>
    )
}


export default Agreement;













