
import { useUserActions } from "@src/utils/hooks/useUserActions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './wrap.scss';

// Props
interface Props {
    fullHeight?: boolean,
    className?: string;
    headerComponent?: React.ReactNode;
    contentsComponent?: React.ReactNode;
    accessType?: 'visitor' | 'member' | 'all';
}

// 로그인/회원가입 전체 랩 컴포넌트
const Wrap = ({ fullHeight = true, className = '', headerComponent, contentsComponent, accessType = 'visitor' }: Props) => {
    
    const { checkLogin } = useUserActions();
    const navigate = useNavigate(); 
    const [height, setHeight] = useState(window.innerHeight);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (fullHeight) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';
            document.documentElement.style.touchAction = 'none';

            const scrollElement = document.querySelector('.sign-in-up-wrap') as HTMLElement;
            const handleVisualViewPortResize = () => {
                const currentVisualViewport = Number(window.visualViewport?.height)
                if (scrollElement) {
                    scrollElement.style.height = `${currentVisualViewport}px`
                    window.scrollTo(0, -56);
                    setTimeout(() => {
                        document.activeElement.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
                    }, 0);
                }
            }
            window.visualViewport.addEventListener('resize', handleVisualViewPortResize);
            window.visualViewport.addEventListener('scroll', handleVisualViewPortResize);
            return () => {
                document.body.style.overflow = '';
                document.documentElement.style.overflow = '';
                document.body.style.touchAction = '';
                document.documentElement.style.touchAction = '';
                window.visualViewport.removeEventListener('resize', handleVisualViewPortResize);
                window.visualViewport.removeEventListener('scroll', handleVisualViewPortResize);
            }
        } else {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';
            document.documentElement.style.touchAction = 'none';

            const resize = () => {
                setHeight(window.innerHeight);
            }

            window.visualViewport.addEventListener('resize', resize);
            window.visualViewport.addEventListener('scroll', resize);

            return () => {
                document.body.style.overflow = '';
                document.documentElement.style.overflow = '';
                document.body.style.touchAction = '';
                document.documentElement.style.touchAction = '';

                window.visualViewport.addEventListener('resize', resize);
                window.visualViewport.addEventListener('scroll', resize);
            }
        }
    }, [fullHeight]);

    useEffect(() => {
        let login = checkLogin();
        if (navigate) {
            if (accessType === 'visitor') {
                login ? navigate('/home') : setLoading(true);
            } else if (accessType === 'member') {
                ! login ? navigate('/sign-in/social') : setLoading(true);
            } else if (accessType === 'all') {
                setLoading(true);
            } 
        }
    }, [accessType, navigate])

    return  (
        <div className={`${fullHeight ? 'full-height-wrap' : 'auto-height-wrap'} ${className}`}
            style={{ height: (height) + 'px' }}
        >
            {loading && (<>
                {headerComponent && headerComponent}
                {contentsComponent && contentsComponent}
            </>)}
        </div>
    );
};

export default Wrap;