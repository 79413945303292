import { useEffect, useState } from "react";
// router
import { useLocation, useNavigate } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { UserData } from "@src/@slice/UserSlice";
import { setResetData } from "@src/@slice/SignUpSlice";
// utils
import { useUserActions } from "@src/utils/hooks/useUserActions";
// components
import Slider from "react-slick";
import Wrap from "@src/components/common/wrap/Wrap";
import AppHeader from "../../../components/common/appHeader/AppHeader";
import {
  SocialApple,
  SocialKakao,
  SocialNaver,
} from "@src/components/signInUp/socialLogin/SocialLogin";
// api
import axios from "@src/api/axios";
import { BASE_URL } from "../../../utils/index";
import { ServerResponse } from "@src/api/axios";
// analytics
import { logEvent } from "@src/firebase";

// 소셜 로그인 페이지

// 슬라이더 아이템 타입
type SlickSliderItem = {
  imgUrl: string;
  backgroundColor?: string;
  order: number;
};

// 서버 응답 타입
type ServerItem = {
  pk: string;
  type: string;
  title: string;
  tabTitle: string | null;
  detail: { id: number; jsonStr: string }[];
};

interface DetailedServerResponse extends ServerResponse {
  item: ServerItem[];
}

// Slick 슬라이더 옵션
const sliderOptions = {
  slidesToShow: 1,
  slidesPerRow: 1,
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
};

// 로그인 처리 필요 여부 체크
const loginComplete = localStorage.getItem("loginComplete") || "";

const Contents = () => {
  // redux
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error") || "";

  let alertShown = false;
  useEffect(() => {
    if (!alertShown && error) {
      alertShown = true;
      queryParams.delete("error");
      navigate(location.pathname + "?" + queryParams.toString());
      alert(error);
    }
  }, []);

  // API 호출 횟수
  const [callCount, setCallCount] = useState(0);
  let callCountCheck = false;

  // 슬라이더 아이템
  const [sliderItems, setSliderItems] = useState<SlickSliderItem[]>([]);

  // 유저 데이터
  const ForUserData = useSelector(UserData);

  // 로그아웃
  const { logoutAction, checkLogin } = useUserActions();

  // API 호출
  useEffect(() => {
    if (callCount === 0 && !callCountCheck) {
      callCountCheck = true;
      logEvent("Start_View_v2", {});
      dispatch(setResetData());
      axios
        .get<DetailedServerResponse>(`${BASE_URL}/v3/curation/JOIN/component`)
        .then((response) => {
          const items = response.data.item.flatMap(
            (item) => JSON.parse(item.detail[0].jsonStr).item as SlickSliderItem[],
          );
          setSliderItems(items);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setCallCount(callCount + 1);
        });
      if (checkLogin() && !loginComplete) {
        logoutAction();
      }
    }
  }, [callCount]);

  return (
    <>
      <div className="contents-wrap">
        <div className="slider-wrap">
          <Slider {...sliderOptions} infinite={sliderItems.length > 1 ? true : false}>
            {sliderItems.map((item, idx) => (
              <div className="slider-item-wrap" key={`rolling-banner-${idx}`}>
                <div className="slider-item">
                  <img src={item.imgUrl} alt={`rolling-banner-${idx}`} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="actions-wrap" style={{ marginTop: "115px" }}>
        <div className="btn-wrap itmes-align items-align-middle items-column-gap30">
          <SocialKakao latestLogin={false} />
          <SocialNaver latestLogin={false} />
          <SocialApple latestLogin={false} />
        </div>
        <ul className="piped-links piped-links-bottom2 piped-links-center">
          <li>
            <button
              onClick={() => {
                logEvent("Start_Signup_v2", {});
                navigate("/sign-up/1");
              }}
            >
              회원가입
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                logEvent("Start_Login_v2", {});
                navigate("/sign-in/email");
              }}
            >
              이메일 로그인
            </button>
          </li>
        </ul>
      </div>
      {/* <div className="sign-modal-wrap">
                <div className="overlay"></div>
                <div className="sign-modal-inner">
                    <div className="sign-content">
                        <p>이전에 동일한 계정이 카카오 아이디로 가입되어 있습니다. 카카오 로그인을 통해 로그인해주세요. </p>
                    </div>
                </div>
            </div> */}
    </>
  );
};

const SignInSocial = () => {
  return (
    <>
      <Wrap
        className="sign-in-up-wrap"
        accessType="all"
        fullHeight={false}
        headerComponent={
          loginComplete ? null : (
            <AppHeader
              type="stack"
              title=""
              linkItem={{
                name: "둘러보기",
                onClick: () => {
                  logEvent("Start_LookAround_v2", {});
                  window.location.href = `h2mwbell://home?skip=true`;
                },
              }}
            />
          )
        }
        contentsComponent={loginComplete ? null : <Contents />}
      />
    </>
  );
};

export default SignInSocial;
