import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Checkbox from '@src/components/common/checkbox/Checkbox';
import { autoHypenPhone, isExternalUrl, makeArray } from '@src/utils';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@src/App';
import moment, {Moment} from 'moment';
import { DAYS } from '@src/utils/date';
import NotiText from '@src/components/common/notiText/NotiText';
import useUrlQueryString from '@src/utils/hooks/useUrlQueryString';
import { getPartnerBookingTime, getPartnerList, postHomeApplianceInform } from '@src/api/booking/homeAppliance';
import { IPartner, getPartner } from '@src/api/partner/partner';
import { logEvent } from '@src/firebase';
import AppHeader from '@src/components/common/appHeader/AppHeader';
import { setInPath } from '@src/@slice/bookingHomeApplianceSlicec';
import { useBookingHomeAppliance } from './../../../utils/hooks/useBookingHomeAppliance';

const Apply = () => {
	const now = moment();
	const navigate = useNavigate();
	const query = useUrlQueryString();
	const user = useContext(UserContext);
	const {homeApplianceData, dispatch} = useBookingHomeAppliance();
	const [isApp, setIsApp] = useState(true);
	const [selectedPartner, setSelectedPartner] = useState<IPartner|null>(null);
	const [partnerList, setPartnerList] = useState<any[]>([]);
	const [selectedUuid, setSelectedUuid] = useState<string|null>(null);
	const [agreement, setAgreement] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Moment|null>(null);
	const [fixMonthData, setFixMonthData] = useState({year: moment().get('year'), month: moment().get('month')});
	const elTimeWrap = useRef(null);
	const selectAbleDate = useMemo(() => {
		const dateInfo: { [key in string]: {index: number, arr: Moment[]} } = {};
		const date = moment();
		const maxDate = moment().add(2, 'months');
		while (date.isBefore(maxDate, 'day')) {
			const m = date.get('month');
			if (!dateInfo[m]) dateInfo[m] = {index: Object.keys(dateInfo).length + 1, arr: []};
			dateInfo[m].arr.push(moment(date));
			date.add(1, 'day');
		}
		const lastM = date.get('month');
		if (!dateInfo[lastM]) dateInfo[lastM]  = {index: Object.keys(dateInfo).length + 1, arr: []};
		dateInfo[lastM].arr.push(moment(date));
		return dateInfo;
	}, []);
	const scrollChangePoint = (() => {
		const point: number[] = [];
		Object.keys(selectAbleDate).map((key, idx) => {
			const len = selectAbleDate[key].arr.length;
			let value = 40 + (40 + 8) * len;
			if (point[idx-1]) value += point[idx-1];
			point.push(value);
		});
		return point;
	})();
	const [passCount, setPassCount] = useState(1);
	const [selectedTime, setSelectedTime] = useState('');
	const [ignoreTimes, setIgnoreTimes] = useState<string[]>([]);
	const [formValue, setFormValue] = useState({
		name: '',
		tel: '',
		mateName: '',
		mateTel: ''
	});
	const validateCheck = () => {
		const fv = formValue;
		if (!selectedPartner || !selectedDate || !selectedTime || !agreement || fv.name.trim() === '' 
			|| fv.tel.trim() === '' || fv.mateName.trim() === '' || fv.mateTel.trim() === '') return false;
		return true;
	}
	const validateAlert = () => {
		const fv = formValue;
		if (!selectedPartner) {
			alert('지점이 선택되지 않았습니다.');
			return;
		}
		if (!selectedDate) {
			alert('날짜가 선택되지 않았습니다.');
			return;
		}
		if (!selectedTime) {
			alert('시간이 선택되지 않았습니다.');
			return;
		}
		if (fv.name.trim() === '') {
			alert('이름을 입력해주세요.');
			return;
		}
		if (fv.tel.trim() === '') {
			alert('연락처를 입력해주세요.');
			return;
		}
		if (fv.mateName.trim() === '') {
			alert('배우자 이름을 입력해주세요.');
			return;
		}
		if (fv.mateTel.trim() === '') {
			alert('배우자 연락처를 입력해주세요.');
			return;
		}
		if (!agreement) {
			alert('개인정보 및 제3자 활용 동의가 필요합니다.');
			return;
		}
	}
	const apply = () => {
		if (!validateCheck()) {
			validateAlert();
			return;
		}
		const fv = formValue;
		const param: any = {
			partnerUuid: selectedPartner!.uuid,
			name: fv.name,
			tel: fv.tel,
			mateName: fv.mateName,
			mateTel: fv.mateTel,
			infoAgree: agreement ? 1 : 0,
			visitTsp: selectedDate!.format('YYYY-MM-DD') + ' ' + selectedTime + ':00'
		};
		if (homeApplianceData.inpath) param.inpath = homeApplianceData.inpath;
		postHomeApplianceInform(param).then(_ => {
			logEvent('HomeAppliance_Apply', {});
			navigate('/booking/homeAppliance/complete');
		}).catch(err => {
			logEvent('HomeAppliance_Apply_Error', {
				loginId: user.me ? user.me.loginId : '비회원',
				error_msg: err.response.data.msg,
				error_code: err.response.data.code
			})
			alert(`예약에 실패했습니다. 다시 시도해주세요. \n${err.response.data.msg}`);
		});
	}
	useEffect(() => {
		if (selectAbleDate[now.get('month')]) setSelectedDate(selectAbleDate[now.get('month')].arr[0]);
	}, []);
	useEffect(() => {
		setIsApp(user.me ? true : false);
		if (user.me) setFormValue({...formValue, name: user.me.customerName, tel: user.me.tel});
	}, [user]);
	useEffect(() => {
		let selected: typeof selectAbleDate[string] | undefined;
		Object.keys(selectAbleDate).forEach(key => {
			if (selectAbleDate[key].index === passCount) selected = selectAbleDate[key];
		});
		if (selected) {
			setFixMonthData({ year: selected.arr[0].get('year'), month: selected.arr[0].get('month') });
		}
	}, [passCount]);
	useEffect(() => {
		if (!selectedUuid) return;
		getPartner(selectedUuid).then(res => setSelectedPartner(res.data.item));
		setSelectedDate(null);
	}, [selectedUuid])
	useEffect(() => {
		if (!selectedDate || !selectedUuid) return;
		getPartnerBookingTime(selectedUuid, (selectedDate.format('YYYY-MM-DD'))).then(res => {
			if (res.data.item && res.data.item.time) {
				setIgnoreTimes(res.data.item.time.split(','));
			}
			setSelectedTime('');
			if (elTimeWrap.current) elTimeWrap.current.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		});
	}, [selectedDate]);
	useEffect(() => {
		const uuid = query.get('uuid');
		const mainStoreUuid = query.get('mainStoreUuid');
		const inpath = query.get('inpath');
		if (uuid) {
			setSelectedUuid(uuid);
		}
		if (mainStoreUuid) {
			getPartnerList(mainStoreUuid).then(res => {
				if (res.data.item.length) {
					setPartnerList(res.data.item);
					setSelectedUuid(res.data.item[0].uuid);
				}
			});
		}
		if (inpath) {
			dispatch(setInPath({inpath: inpath}));
		}
	}, [query]);
	return (
		<>
		{!isApp &&
		<AppHeader type="stack" title="웨딩북" actionItems={[]} />
		}
		<div className="home-appliance-apply">
			<div className="form-wrap">
				<div className="form-block">
					<div className="form-title">
						<span className="title">방문 지점 {true ? '선택' : ''}</span>
					</div>
					<div className="form-content">
						{(query.has('uuid') && selectedPartner) &&
						<div className="branch-info-wrap">
							<div className="branch-bg" style={{backgroundImage: `url(${selectedPartner.coverUrl})`}} />
							<span>{selectedPartner.name}</span>
						</div>
						}
						{(query.has('mainStoreUuid') && partnerList.length > 0) &&
						<select 
							className={`select select-full select-branch`}
							style={{marginBottom: 30}}
							onChange={e => {
								setSelectedUuid(e.target.value);
							}}
						>
							{partnerList.map(partner => (
								<>
								<option value={partner.uuid} key={`partner-${partner.uuid}`}>{partner.name}</option>
								</>
							))}
						</select>
						}
					</div>
				</div>
				<div className="form-block" style={{marginBottom: 32}}>
					<div className="form-title">
						<span className="title">날짜 선택</span>
					</div>
					<div className="form-content">
						<div className="select-date-wrap">
							<div className="fixed-month-wrap">
								<div className="month-wrap" style={{marginRight: 0}}>
									<span className="year">{fixMonthData.year}</span>
									<span className="month">{fixMonthData.month + 1}</span>
								</div>
							</div>
							<div className="all-month-list-wrap" onScroll={e => {
								const sl = e.currentTarget.scrollLeft;
								let passCount = 1;
								scrollChangePoint.forEach((point, idx) => {
									if (sl > point) passCount++;
								});
								setPassCount(passCount);
							}}>
								{Object.keys(selectAbleDate).map((key, idx) => (
									<div className="month-list-wrap" key={`month-list-${key}`}>
										<div className="month-wrap">
											<span className="year">{selectAbleDate[key].arr[0].get('year')}</span>
											<span className="month">{selectAbleDate[key].arr[0].get('month') + 1}</span>
										</div>
									{selectAbleDate[key].arr.map(date => (
										<div className={`day-wrap ${date.isSame(selectedDate, 'day') ? 'on' : ''}`} onClick={() => setSelectedDate(date)} key={`day-list-${date.valueOf()}`}>
											<span className="day-name">{DAYS[date.get('day')]}</span>
											<span className="date">{date.get('date')}</span>
										</div>
									))}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="form-block" style={{marginBottom: 16}}>
					<div className="form-title">
						<span className="title">시간 선택</span>
					</div>
					<div className="form-content">
						<div className="select-time-wrap" ref={elTimeWrap}>
							{makeArray(19).map(i => {
								const d = moment().set('hour', 10).set('minute', 0);
								if (selectedDate) d.set({'year': selectedDate.get('year'), 'month': selectedDate.get('month'), 'date': selectedDate.get('date')})
								d.add(30 * i, 'minute');
								const timeStr = d.format('HH:mm');
								return (
								<span 
									className={`${selectedTime === timeStr ? 'on' : ''} ${(ignoreTimes.indexOf(timeStr) > -1 || d.isBefore(now) || selectedDate === null) ? 'disable' : ''}`} 
									onClick={() => {
										if (ignoreTimes.indexOf(timeStr) === -1) setSelectedTime(timeStr);
									}}  
									key={`time-${i}`}
								>
									{timeStr}
								</span>)
							})}
						</div>
						<NotiText text="*공휴일 및 백화점 지점의 경우 휴무일(영업시간) 확인 후 예약바랍니다." style={{margin: '0 -20px'}} />
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">예약자 이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-full input-has-line"
							placeholder="이름" 
							value={formValue.name}
							onChange={e => setFormValue({...formValue, name: e.target.value})}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">연락처</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-full input-has-line"
							placeholder="연락처" 
							value={autoHypenPhone(formValue.tel)}
							onChange={e => setFormValue({...formValue, tel: autoHypenPhone(e.target.value)})}
						/>
					</div>
					<p className="apply-customer-notice">🛎 계약 시 카드한도 등의 이유로 배우자 정보로 계약하는 경우에도 정상적인 혜택 적용 및 계약 확인을 위해 배우자 정보를 입력해주세요.  </p>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">배우자 이름</span>
					</div>
					<div className="form-content">
						<input 
							type="text"
							className="input-text input-full input-has-line"
							placeholder="배우자 이름" 
							value={formValue.mateName}
							onChange={e => setFormValue({...formValue, mateName: e.target.value})}
						/>
					</div>
				</div>
				<div className="form-block">
					<div className="form-title">
						<span className="title">배우자 연락처</span>
					</div>
					<div className="form-content">
						<input 
							type="tel"
							className="input-text input-full input-has-line"
							placeholder="배우자 연락처" 
							value={autoHypenPhone(formValue.mateTel)}
							onChange={e => setFormValue({...formValue, mateTel: autoHypenPhone(e.target.value)})}
						/>
					</div>
				</div>
				<div className="agreement-check-wrap" style={{marginBottom: 20}}>
					<Checkbox 
						id="agreement"
						name="agreement"
						value="agreement"
						setValue={() => setAgreement(!agreement)}
						checked={agreement}
					/>
					<label className="agreement-label" style={{marginLeft: 0}}>
						<a 
							href={isApp ? isExternalUrl('https://www.wdgbook.com/agreementHomeAppliance?name=' + selectedPartner?.informReceiver) : 'https://www.wdgbook.com/agreementHomeAppliance?name=' + selectedPartner?.informReceiver}
							target={!isApp ? '_blank' : '_self'}
							rel="noreferrer"
						>
							개인정보 수집
						</a> 및&nbsp;
						<a 
							href={isApp ? isExternalUrl('https://www.wdgbook.com/agreementHomeAppliance?name=' + selectedPartner?.informReceiver) : 'https://www.wdgbook.com/agreementHomeAppliance?name=' + selectedPartner?.informReceiver}
							target={!isApp ? '_blank' : '_self'}
							rel="noreferrer"
						>
							제3자 활용
						</a> 전체동의
					</label>
				</div>
				<div className="page-noti-wrap form-content">
					<ul className="noti-list-wrap">
						<li className="noti-list">당일 방문 신청 시 웨딩북 혜택 적용이 불가할 수 있습니다.</li>
						<li className="noti-list">방문 시 웨딩북 통해서 방문, 예약했음을 업체에 전달주셔야만 웨딩북 별도 혜택이 적용됩니다.</li>
						<li className="noti-list">타 루트를 통한 진행 시 웨딩북 혜택 적용이 불가할 수 있습니다.</li>
					</ul>
				</div>

				<button className="btn btn-large bold btn-red btn-full" onClick={apply}>확인</button>
			</div>
			
		</div>
		</>
	);
};

export default Apply;