import { Dispatch, SetStateAction } from 'react';

interface RadioProps {
	id: string;
	name: string;
	value: string;
	setValue: Dispatch<SetStateAction<string>> | ((val:string) => void);
	checked: boolean;
}
const RadioButton = ({id, name, value, setValue, checked}: RadioProps) => {
	return (
		<div className="input-radio-wrap">
			<input id={id} type="radio" name={name} className="input-radio only-sr" value={value} checked={checked} onChange={() => setValue(value)} />
			<label htmlFor={id}></label>
		</div>
	)
}

export default RadioButton;
