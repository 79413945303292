import { useState, useEffect } from 'react';
import axios from 'axios';
import Parser, {ComponentItem} from '../../components/parser/Parser';
import { BASE_URL } from './../../utils/index';
import AppHeader from '../../components/common/appHeader/AppHeader';
const HomePreview = () => {
	const [data, setData] = useState<ComponentItem[]|null>(null);
	useEffect(() => {
		axios.get(BASE_URL + '/v3/home/component/preview').then((res: any) => {
			const d: {item: ComponentItem[], result: any, total: any} = res.data;
			setData(d.item);
		});
	}, []);
	return (
		<>
		<AppHeader type="main" actionItems={[
			{name: 'search'},
			{name: 'chat'},
			{name: 'push'}
		]} />
		<div className="page page-home">
			{data && 
			<Parser data={data} page="home_preview" />
			}
		</div>
		</>
	);
};

export default HomePreview;