import { useState, useEffect } from 'react';
import AppHeader from './../../components/common/appHeader/AppHeader';
import axios from '@src/api/axios';
import moment from 'moment'
import { BASE_URL } from '@src/utils';

interface IGift {
	customerId: number;
	giftId: number;
	giftName: string;
	giftNote: string;
	id: number
	regTsp: number;
	stampCount: number;
	state: number;
}
const shareMessage = `웨딩북 '웨딩 스탬프' 초대장이 도착했어요.%0A%0A
%0A%0A
웨딩북에서 결혼 준비하고 캐시백 신청하면 스탬프를 찍어드려요.%0A%0A
스탬프를 모아서 최대 25만원 상당의 특별한 상품으로 교환해보세요!%0A%0A
%0A%0A
* 스탬프 보러가기 - https://url.wdgbook.com/h0me`
function StampApplyComplete() {
	const [gifts, setGifts] = useState<IGift[]>([]);
	useEffect(() => {
		axios.get(BASE_URL + `/web/v3/stamps/me`).then((res: any) => {
			console.log(res.data.data);
			setGifts(res.data.data.gift);
		});
	}, []);
	return (
		<>
		<AppHeader title={''} type="product" actionItems={[{name: 'close'}]} />
		<div className="page page-wedding-stamp-apply-complete">
			<div>
				<h2 className="stamp-title">상품 신청완료!</h2>
				<div className="gift-list-wrap">
				{gifts.map(gift => (
					<div>
						<span>{moment(gift.regTsp).format('YYYY.MM.DD')}</span>
						<p>{gift.giftName}</p>
					</div>
				))}
				</div>
			</div>
			<div className="btn-wrap items-2" style={{margin: '0 -20px'}}>
				<div>
				<button className="btn btn-gray btn-full btn-large" onClick={() => window.location.href = 'h2mwbell://webview/close'}>홈으로 돌아가기</button>
				</div>
				<div>
				<button className="btn btn-red btn-full btn-large" onClick={() => window.location.href = `h2mwbell://share?title=공유하기&msg=${shareMessage}` }>친구에게 알리기</button>
				</div>
			</div>
		</div>
		</>
	);
}

export default StampApplyComplete;